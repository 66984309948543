export function ViewAccessAttemptsController (scope, resourceFactory, routeParams, location) {
  scope.accessAttemptsPerPage = 15;
  scope.offset = 0;
  scope.searchText = '';
  scope.searchByOption = ['username', 'userId'];
  scope.searchByDefault = scope.searchByOption[0];

  scope.getResultsPage = function (pageNumber) {
    if (scope.searchText) {
      const startPosition = (pageNumber - 1) * scope.accessAttemptsPerPage;
      scope.accessAttempts = scope.totalAccessAttempts.slice(startPosition, startPosition + scope.accessAttemptsPerPage);
      return;
    }
    scope.offset = (pageNumber - 1) * scope.accessAttemptsPerPage;
    scope.searchTrackUserLogin();
  };

  scope.routeToTrackLoginLogs = function (userId) {
    location.path(`/accessattempts/${userId}`);
  };

  scope.searchTrackUserLogin = function () {
    let searchString = scope.searchText;
    searchString = searchString.replace(/(^"|"$)/g, '');

    const handleGetAccessAttemps = (data) => {
      const { pageItems: tracklogin, totalFilteredRecords } = data;

      scope.totalAccessAttempts = totalFilteredRecords;
      scope.tracklogin = tracklogin
        .map(
          ({ id, userId, username, firstname, lastname, success, type, deviceDetail, ipAddress, timestamp }) =>
            ({ id, userId, username, firstname, lastname, success, type, deviceDetail, ipAddress, timestamp })
        );
      const totalAccessAttemptsPerPage = scope.tracklogin.slice(0, scope.accessAttemptsPerPage);
      scope.accessAttempts = totalAccessAttemptsPerPage;
    };

    const params = {
      offset: scope.offset,
      limit: scope.accessAttemptsPerPage
    };
    params[scope.searchByDefault] = searchString || null;
    resourceFactory.accessAttemptResource.get(params, handleGetAccessAttemps);
  };

  scope.searchTrackUserLogin();
  scope.filterSearchBy = function () {
    scope.searchText = '';
    scope.searchTrackUserLogin();
  };
}
ViewAccessAttemptsController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location'];
