<template>
  <section class="card">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-sm-3">
          {{ $t("text.lastAchSubmmittedFilter") }}
        </div>
        <div class="col-sm-1">
          <b-form-input
            v-model="lastProcessingDay"
            type="number"
            min="1"
          />
        </div>
        <div class="col-sm-1">
          {{ $t("text.day") }}
        </div>
        <div class="col-sm-12 text-center">
          <b-button
            variant="primary"
            @click="getAchProcessingHistory"
          >
            {{ $t("label.button.search") }}
          </b-button>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-sm-12">
          <b-table
            :items="tableData"
            :fields="achProcessingHistoryTableFields"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import mixin from './mixin';

export default {
  name: 'AchProcessingHistory',
  mixins: [mixin],
  data () {
    return {
      isSameDayAch: this.$route.query?.paymentType === 'SAMEDAYACH',
      achProcessingHistoryTableFields: [
        { key: 'processingDate', label: this.$t('label.head.processingDate') },
        { key: 'filename', label: this.$t('label.head.fileName') },
        { key: 'numberOfCredits', label: this.$t('label.head.numberOfCredits') },
        { key: 'amountOfCredits', label: this.$t('label.head.amountOfCredits') },
        { key: 'numberOfDebits', label: this.$t('label.head.numberOfDebits') },
        { key: 'amountOfDebits', label: this.$t('label.head.amountOfDebits') }
      ]
    };
  },
  computed: {
    tableData () {
      return this.isSameDayAch ? this.sameDayAchProcessingHistoryTableData : this.achProcessingHistoryTableData;
    },
    achProcessingHistoryTableData () {
      return this.achProcessingHistoryList.map((item) => ({
        ...item,
        amountOfCredits: this.$n(item.amountOfCredits, { key: 'currency' }),
        amountOfDebits: this.$n(item.amountOfDebits, { key: 'currency' }),
        processingDate: this.$d(new Date(item.processingDate), 'long')
      }));
    },
    sameDayAchProcessingHistoryTableData () {
      return this.sameDayAchProcessingHistoryList.map((item) => ({
        ...item,
        amountOfCredits: this.$n(item.amountOfCredits, { key: 'currency' }),
        amountOfDebits: this.$n(item.amountOfDebits, { key: 'currency' }),
        processingDate: this.$d(new Date(item.processingDate), 'long')
      }));
    }
  },
  beforeMount () {
    this.getAchProcessingHistory();
  },
  methods: {
    async getAchProcessingHistory () {
      if (this.isSameDayAch) {
        this.getSameDayAchProcessingHistory();
      } else {
        this.getAllAchProcessingHistory();
      }
    }
  }
};
</script>
