<template>
  <div class="span gray-head ms-0">
    <span class="ms-2 fs-3">
      <strong>
        <i :class="`fas fa-stop ${generalStatusLookUp(item.status)}`" />
        <slot
          name="title"
          :item="item"
        >
          <span> {{ item.productName }} ({{ formatterPAN(item.primaryAccountNumber) }}) </span>
        </slot>
      </strong>
    </span>
  </div>
</template>

<script setup>
import { generalStatusLookUp } from '@/helper/statusLookUp';
import { formatPAN } from '@/helper/FormatPan';

const formatterPAN = formatPAN();
defineProps({
  item: {
    type: Object,
    default: () => {}
  }
});
</script>
