import angular from 'angular';
import { AccFreqPostingController } from './accounting/AccFreqPostingController';
import { JournalEntryController } from './accounting/JournalEntryController';
import { MainController } from './main/MainController';
import { NewLoanAccAppController } from './loanAccount/NewLoanAccAppController';
import { TaskController } from './TaskController';
import { TransfersController } from './transfers/TransfersController';
import { ViewTransferController } from './transfers/ViewTransferController';
import { SettlementsController } from './settlements/SettlementsController';
import { CreateSavingAccountController } from './savings/CreateSavingAccountController';
import { ViewClientController } from './clients/ViewClientController';
import { ViewEmployeeClientController } from './clients/ViewEmployeeClientController';
import { ViewEmployeeTransactionController } from './clients/ViewEmployeeTransactionController';
import { ClientDeceasedController } from './clients/ClientDeceasedController';
import { AccCoaController } from './accounting/AccCoaController';
import { AccCreateGLAccountController } from './accounting/AccCreateGLAccountController';
import { AccCreateRuleController } from './accounting/AccCreateRuleController';
import { AccEditGLAccountController } from './accounting/AccEditGLAccountController';
import { AccEditRuleController } from './accounting/AccEditRuleController';
import { AccountingClosureController } from './accounting/AccountingClosureController';
import { AccountingRuleController } from './accounting/AccountingRuleController';
import { AccViewGLAccountContoller } from './accounting/AccViewGLAccountContoller';
import { AddFinancialMappingController } from './accounting/AddFinancialMappingController';
import { BulkImportCOAController } from './accounting/BulkImportCOAController';
import { BulkImportJournalEntriesController } from './accounting/BulkImportJournalEntriesController';
import { DefineOpeningBalancesController } from './accounting/DefineOpeningBalancesController';
import { EditFinancialActivityMappingController } from './accounting/EditFinancialActivityMappingController';
import { FinancialActivityMappingsController } from './accounting/FinancialActivityMappingsController';
import { OperationalAccountMappingsController } from './accounting/OperationalAccountMappingsController';
import { CreateOperationalAccountMappingController } from './accounting/CreateOperationalAccountMappingController';
import { PeriodicAccrualAccountingController } from './accounting/PeriodicAccrualAccountingController';
import { CreateProvisoningEntriesController } from './accounting/provisioning/CreateProvisoningEntriesController';
import { ViewAllProvisoningEntriesController } from './accounting/provisioning/ViewAllProvisoningEntriesController';
import { ViewProvisioningEntryController } from './accounting/provisioning/ViewProvisioningEntryController';
import { ViewProvisioningJournalEntriesController } from './accounting/provisioning/ViewProvisioningJournalEntriesController';
import { ViewAccountingClosureController } from './accounting/ViewAccountingClosureController';
import { ViewAccRuleController } from './accounting/ViewAccRuleController';
import { ViewFinancialActivityController } from './accounting/ViewFinancialActivityController';
import { ViewTransactionController } from './accounting/ViewTransactionController';
import { CreateStandingInstructionController } from './accounttransfers/CreateStandingInstructionController';
import { EditStandingInstructionController } from './accounttransfers/EditStandingInstructionController';
import { ListStandingInstructionController } from './accounttransfers/ListStandingInstructionController';
import { ListTransactionsController } from './accounttransfers/ListTransactionsController';
import { MakeAccountInternalTransferController } from './accounttransfers/MakeAccountInternalTransferController';
import { MakeAccountExternalTransferController } from './accounttransfers/MakeAccountExternalTransferController';
import { IncomingWireTransactionController } from './accounttransfers/IncomingWireTransactionController';
import { CreateExternalWireTransferController } from './accounttransfers/CreateExternalWireTransferController';
import { EditExternalWireTransferController } from './accounttransfers/EditExternalWireTransferController';
import { StandingInstructionsHistoryController } from './accounttransfers/StandingInstructionsHistoryController';
import { ViewAccountTransferDetailsController } from './accounttransfers/ViewAccountTransferDetailsController';
import { ViewStandingInstructionController } from './accounttransfers/ViewStandingInstructionController';
import { AdHocQueryListController } from './adhocquery/AdHocQueryListController';
import { CreateAdHocQueryController } from './adhocquery/CreateAdHocQueryController';
import { EditAdHocQueryController } from './adhocquery/EditAdHocQueryController';
import { ViewAdHocQueryController } from './adhocquery/ViewAdHocQueryController';
import { EditCardLimitController } from './cards/EditCardLimitController';
import { EditCardSecuritiesController } from './cards/EditCardSecuritiesController';
import { ViewExternalCardDetailsController } from './externalcards/ViewExternalCardDetailsController';
import { BulkImportCentersController } from './centers/BulkImportCentersController';
import { AddFamilyMembersController } from './client/AddFamilyMembersController';
import { AddNewClientChargeController } from './client/AddNewClientChargeController';
import { ClientActionsController } from './client/ClientActionsController';
import { ClientChargesOverviewController } from './client/ClientChargesOverviewController';
import { ClientDocumentController } from './client/ClientDocumentController';
import { ClientScreenReportController } from './client/ClientScreenReportController';
import { CreateClientController } from './client/CreateClientController';
import { EditClientController } from './client/EditClientController';
import { EditFamilyMemberController } from './client/EditFamilyMemberController';
import { PayClientChargeController } from './client/PayClientChargeController';
import { TransactionClientController } from './client/TransactionClientController';
import { UploadClientIdentifierDocumentController } from './client/UploadClientIdentifierDocumentController';
import { ViewClientChargeController } from './client/ViewClientChargeController';
import { EditAddressController } from './configurations/EditAddressController';
import { EditConfigurationController } from './configurations/EditConfigurationController';
import { EditExternalServicesConfigurationController } from './configurations/EditExternalServicesConfigurationController';
import { EditTwoFactorConfigController } from './configurations/EditTwoFactorConfigController';
import { ExternalServicesController } from './configurations/ExternalServicesController';
import { GlobalConfigurationController } from './configurations/GlobalConfigurationController';
import { FieldConfigurationController } from './fieldconfigurations/FieldConfigurationController';
import { CreateFieldConfigurationController } from './fieldconfigurations/CreateFieldConfigurationController';
import { EditFieldConfigurationController } from './fieldconfigurations/EditFieldConfigurationController';
import { ViewFieldConfigurationController } from './fieldconfigurations/ViewFieldConfigurationController';
import { CreateFieldValidationController } from './fieldvalidations/CreateFieldValidationController';
import { EditFieldValidationController } from './fieldvalidations/EditFieldValidationController';
import { ViewExternalServicesController } from './configurations/ViewExternalServicesController';
import { ViewTwoFactorConfigController } from './configurations/ViewTwoFactorConfigController';
import { AddNewFixedDepositChargeController } from './deposits/fixed/AddNewFixedDepositChargeController';
import { BulkImportFixedDepositAccountsController } from './deposits/fixed/BulkImportFixedDepositAccountsController';
import { BulkImportFixedDepositTransactionsController } from './deposits/fixed/BulkImportFixedDepositTransactionsController';
import { CreateFixedDepositAccountController } from './deposits/fixed/CreateFixedDepositAccountController';
import { EditDepositAccountController } from './deposits/fixed/EditDepositAccountController';
import { FixedDepositAccountActionsController } from './deposits/fixed/FixedDepositAccountActionsController';
import { ViewFixedDepositAccountDetailsController } from './deposits/fixed/ViewFixedDepositAccountDetailsController';
import { ViewFixedDepositTransactionController } from './deposits/fixed/ViewFixedDepositTransactionController';
import { AddNewRecurringDepositChargeController } from './deposits/recurring/AddNewRecurringDepositChargeController';
import { BulkImportRecurringDepositController } from './deposits/recurring/BulkImportRecurringDepositController';
import { BulkImportRecurringDepositTransactionsController } from './deposits/recurring/BulkImportRecurringDepositTransactionsController';
import { CreateRecurringDepositAccountController } from './deposits/recurring/CreateRecurringDepositAccountController';
import { EditRecurringDepositAccountController } from './deposits/recurring/EditRecurringDepositAccountController';
import { RecurringDepositAccountActionsController } from './deposits/recurring/RecurringDepositAccountActionsController';
import { ViewRecurringDepositAccountDetailsController } from './deposits/recurring/ViewRecurringDepositAccountDetailsController';
import { ViewRecurringDepositTransactionController } from './deposits/recurring/ViewRecurringDepositTransactionController';
import { AddLoanChargeController } from './loanAccount/AddLoanChargeController';
import { AddLoanCollateralController } from './loanAccount/AddLoanCollateralController';
import { AdjustRepaymentSchedule } from './loanAccount/AdjustRepaymentSchedule';
import { ApproveLoanRescheduleRequestController } from './loanAccount/ApproveLoanRescheduleRequestController';
import { AssignLoanOfficerController } from './loanAccount/AssignLoanOfficerController';
import { BulkImportGuarantorController } from './loanAccount/BulkImportGuarantorController';
import { BulkImportLoanAccountsController } from './loanAccount/BulkImportLoanAccountsController';
import { BulkImportLoanRepaymentController } from './loanAccount/BulkImportLoanRepaymentController';
import { EditGuarantorController } from './loanAccount/EditGuarantorController';
import { EditLoanAccAppController } from './loanAccount/EditLoanAccAppController';
import { EditLoanChargeController } from './loanAccount/EditLoanChargeController';
import { EditLoanCollateralController } from './loanAccount/EditLoanCollateralController';
import { GuarantorController } from './loanAccount/GuarantorController';
import { ListGuarantorController } from './loanAccount/ListGuarantorController';
import { LoanAccountActionsController } from './loanAccount/LoanAccountActionsController';
import { LoanDocumentController } from './loanAccount/LoanDocumentController';
import { LoanForeclosureController } from './loanAccount/LoanForeclosureController';
import { LoanScreenReportController } from './loanAccount/LoanScreenReportController';
import { NewJLGLoanAccAppController } from './loanAccount/NewJLGLoanAccAppController';
import { PreviewLoanRepaymentScheduleController } from './loanAccount/PreviewLoanRepaymentScheduleController';
import { RejectLoanRescheduleRequestController } from './loanAccount/RejectLoanRescheduleRequestController';
import { RescheduleLoansRequestController } from './loanAccount/RescheduleLoansRequestController';
import { ViewLoanChargeController } from './loanAccount/ViewLoanChargeController';
import { ViewLoanCollateralController } from './loanAccount/ViewLoanCollateralController';
import { ViewLoanDetailsController } from './loanAccount/ViewLoanDetailsController';
import { ViewLoanTransactionController } from './loanAccount/ViewLoanTransactionController';
import { ViewRescheduleRequestController } from './loanAccount/ViewRescheduleRequestController';
import { AdHocQuerySearchController } from './main/AdHocQuerySearchController';
import { NavigationController } from './main/NavigationController';
import { ProfileController } from './main/ProfileController';
import { RichDashboard } from './main/RichDashboard';
import { SearchController } from './main/SearchController';
import { ViewCheckerinboxController } from './main/ViewCheckerinboxController';
import { ViewMakerCheckerTaskController } from './main/ViewMakerCheckerTaskController';
import { NotificationsController } from './notification/NotificationsController';
import { AddHolController } from './organization/AddHolController';
import { BulkImportEmployeeController } from './organization/BulkImportEmployeeController';
import { BulkImportOfficesController } from './organization/BulkImportOfficesController';
import { BulkLoanReassignmentController } from './organization/BulkLoanReassignmentController';
import { CashierFundsAllocationSettlementController } from './organization/cashmgmt/CashierFundsAllocationSettlementController';
import { CashierTransactionsController } from './organization/cashmgmt/CashierTransactionsController';
import { CreateCashierForTellerController } from './organization/cashmgmt/CreateCashierForTellerController';
import { CreateTellerController } from './organization/cashmgmt/CreateTellerController';
import { EditCashierController } from './organization/cashmgmt/EditCashierController';
import { EditTellerController } from './organization/cashmgmt/EditTellerController';
import { TellersController } from './organization/cashmgmt/TellersController';
import { ViewCashierController } from './organization/cashmgmt/ViewCashierController';
import { ViewCashiersForTellerController } from './organization/cashmgmt/ViewCashiersForTellerController';
import { ViewTellerController } from './organization/cashmgmt/ViewTellerController';
import { CreateEmployeeController } from './organization/CreateEmployeeController';
import { CreateOfficeController } from './organization/CreateOfficeController';
import { CreatePaymentTypeController } from './organization/CreatePaymentTypeController';
import { CurrencyConfigController } from './organization/CurrencyConfigController';
import { EditEmployeeController } from './organization/EditEmployeeController';
import { EditHolidayController } from './organization/EditHolidayController';
import { EditOfficeController } from './organization/EditOfficeController';
import { EditPasswordPreferencesController } from './organization/EditPasswordPreferencesController';
import { EditPaymentTypeController } from './organization/EditPaymentTypeController';
import { EditWorkingDaysController } from './organization/EditWorkingDaysController';
import { EmployeeController } from './organization/EmployeeController';
import { EntityDatatableChecksController } from './organization/entitydatatablechecks/EntityDatatableChecksController';
import { HolController } from './organization/HolController';
import { MakerCheckerController } from './organization/MakerCheckerController';
import { ManageFundsController } from './organization/ManageFundsController';
import { OfficesController } from './organization/OfficesController';
import { CreateProvisioningCriteriaController } from './organization/provisioning/CreateProvisioningCriteriaController';
import { EditProvisioningCriteriaController } from './organization/provisioning/EditProvisioningCriteriaController';
import { ViewAllProvisoningCriteriaController } from './organization/provisioning/ViewAllProvisoningCriteriaController';
import { ViewProvisioningCriteriaController } from './organization/provisioning/ViewProvisioningCriteriaController';
import { ViewEmployeeController } from './organization/ViewEmployeeController';
import { ViewHolController } from './organization/ViewHolController';
import { ViewOfficeController } from './organization/ViewOfficeController';
import { ViewPaymentTypeController } from './organization/ViewPaymentTypeController';
import { CreateAMLRuleGroupController } from './payments/CreateAMLRuleGroupController';
import { CreateAMLWatchmanRuleGroupController } from './payments/CreateAMLWatchmanRuleGroupController';
import { CreateLimitRuleController } from './payments/CreateLimitRuleController';
import { CreateNameMatchingController } from './payments/CreateNameMatchingController';
import { CreateNCLimitRuleController } from './payments/CreateNCLimitRuleController';
import { CreateNCPeriodLimitRuleController } from './payments/CreateNCPeriodLimitRuleController';
import { CreateNCRuleGroupController } from './payments/CreateNCRuleGroupController';
import { CreatePeriodLimitRuleController } from './payments/CreatePeriodLimitRuleController';
import { EditAMLNCRuleGroupController } from './payments/EditAMLNCRuleGroupController';
import { EditAMLRuleGroupController } from './payments/EditAMLRuleGroupController';
import { EditLimitRuleController } from './payments/EditLimitRuleController';
import { EditNCLimitRuleController } from './payments/EditNCLimitRuleController';
import { EditNCPeriodLimitRuleController } from './payments/EditNCPeriodLimitRuleController';
import { EditPeriodLimitRuleController } from './payments/EditPeriodLimitRuleController';
import { ViewAMLNCRuleGroupController } from './payments/ViewAMLNCRuleGroupController';
import { ViewWatchmanGroupController } from './payments/ViewWatchmanGroupController';
import { ViewAMLRuleGroupController } from './payments/ViewAMLRuleGroupController';
import { ViewNameMatchingController } from './payments/ViewNameMatchingController';
import { ViewRestrictedCountryController } from './payments/ViewRestrictedCountryController';
import { EditNameMatchingController } from './payments/EditNameMatchingController';
import { EditWatchmanRuleGroupController } from './payments/EditWatchmanRuleGroupController';
import { ViewAMLRuleGroupListController } from './payments/ViewAMLRuleGroupListController';
import { ViewLimitRuleController } from './payments/ViewLimitRuleController';
import { ViewNCLimitRuleController } from './payments/ViewNCLimitRuleController';
import { ViewNCPeriodLimitRuleController } from './payments/ViewNCPeriodLimitRuleController';
import { ViewPeriodLimitRuleController } from './payments/ViewPeriodLimitRuleController';
import { CardProductController } from './product/CardProductController';
import { ChargeController } from './product/ChargeController';
import { CreateCardProductController } from './product/CreateCardProductController';
import { CreateChargeController } from './product/CreateChargeController';
import { CreateFixedDepositProductController } from './product/CreateFixedDepositProductController';
import { CreateInterestRateChartController } from './product/CreateInterestRateChartController';
import { CreateLoanProductController } from './product/CreateLoanProductController';
import { CreateRecurringDepositProductController } from './product/CreateRecurringDepositProductController';
import { CreateSavingProductController } from './product/CreateSavingProductController';
import { CreateShareProductController } from './product/CreateShareProductController';
import { EditCardProductController } from './product/EditCardProductController';
import { EditChargeController } from './product/EditChargeController';
import { EditFixedDepositProductController } from './product/EditFixedDepositProductController';
import { EditInterestRateChartController } from './product/EditInterestRateChartController';
import { EditLoanProductController } from './product/EditLoanProductController';
import { EditRecurringDepositProductController } from './product/EditRecurringDepositProductController';
import { EditSavingProductController } from './product/EditSavingProductController';
import { EditShareProductController } from './product/EditShareProductController';
import { FixedDepositProductController } from './product/FixedDepositProductController';
import { CreateFloatingRateController } from './product/floatingrates/CreateFloatingRateController';
import { EditFloatingRateController } from './product/floatingrates/EditFloatingRateController';
import { FloatingRatesController } from './product/floatingrates/FloatingRatesController';
import { ViewFloatingRateController } from './product/floatingrates/ViewFloatingRateController';
import { InterestRateChartController } from './product/InterestRateChartController';
import { LoanProductController } from './product/LoanProductController';
import { AddProductMixController } from './product/productmix/AddProductMixController';
import { ProductMixController } from './product/productmix/ProductMixController';
import { ViewProductMixController } from './product/productmix/ViewProductMixController';
import { RecurringDepositProductController } from './product/RecurringDepositProductController';
import { ShareProductActionsController } from './product/ShareProductActionsController';
import { ShareProductController } from './product/ShareProductController';
import { ShareProductDividendController } from './product/ShareProductDividendController';
import { CreateTaxComponentController } from './product/tax/CreateTaxComponentController';
import { CreateTaxGroupController } from './product/tax/CreateTaxGroupController';
import { EditTaxComponentController } from './product/tax/EditTaxComponentController';
import { EditTaxGroupController } from './product/tax/EditTaxGroupController';
import { TaxComponentController } from './product/tax/TaxComponentController';
import { TaxGroupController } from './product/tax/TaxGroupController';
import { ViewTaxComponentController } from './product/tax/ViewTaxComponentController';
import { ViewTaxGroupController } from './product/tax/ViewTaxGroupController';
import { ViewCardProductController } from './product/ViewCardProductController';
import { ViewChargeController } from './product/ViewChargeController';
import { ViewFixedDepositProductController } from './product/ViewFixedDepositProductController';
import { ViewLoanProductController } from './product/ViewLoanProductController';
import { ViewRecurringDepositProductController } from './product/ViewRecurringDepositProductController';
import { ViewSavingProductController } from './product/ViewSavingProductController';
import { ViewShareProductController } from './product/ViewShareProductController';
import { ViewShareProductDividendController } from './product/ViewShareProductDividendController';
import { RunReportsController } from './reports/RunReportsController';
import { ViewReportsController } from './reports/ViewReportsController';
import { AddStopPaymentController } from './savings/AddStopPaymentController';
import { EditStopPaymentController } from './savings/EditStopPaymentController';
import { HoldAmountSavingsController } from './savings/HoldAmountSavingsController';
import { ReleaseAmountSavingsController } from './savings/ReleaseAmountSavingsController';
import { SettleAmountSavingsController } from './savings/SettleAmountSavingsController';
import { AssignSavingsOfficerController } from './savings/AssignSavingsOfficerController';
import { BulkImportSavingsAccountController } from './savings/BulkImportSavingsAccountController';
import { BulkImportSavingsAccountsTransactionsController } from './savings/BulkImportSavingsAccountsTransactionsController';
import { ClientTransferOptionsController } from './savings/ClientTransferOptionsController';
import { EditSavingAccountController } from './savings/EditSavingAccountController';
import { ListOnHoldTransactionController } from './savings/ListOnHoldTransactionController';
import { SavingAccountActionsController } from './savings/SavingAccountActionsController';
import { UnAssignSavingsOfficerController } from './savings/UnAssignSavingsOfficerController';
import { ViewSavingChargeController } from './savings/ViewSavingChargeController';
import { ViewSavingDetailsController } from './savings/ViewSavingDetailsController';
import { ViewStatementsAndDocumentsController } from './savings/ViewStatementsAndDocumentsController';
import { ViewSavingsTransactionController } from './savings/ViewSavingsTransactionController';
import { CreateSelfServiceUserController } from './selfservice/CreateSelfServiceUserController';
import { BulkImportShareAccountController } from './shares/BulkImportShareAccountController';
import { CreateShareAccountController } from './shares/CreateShareAccountController';
import { EditShareAccountController } from './shares/EditShareAccountController';
import { ShareAccountActionsController } from './shares/ShareAccountActionsController';
import { ViewShareAccountController } from './shares/ViewShareAccountController';
import { AccountNumberPreferencesController } from './system/AccountNumberPreferencesController';
import { AddAccountNumberPreferencesController } from './system/AddAccountNumberPreferencesController';
import { AuditController } from './system/AuditController';
import { CreateDataTableController } from './system/CreateDataTableController';
import { CreateDataTableWorkflowController } from './system/CreateDataTableWorkflowController';
import { CreateHookController } from './system/CreateHookController';
import { CreateReportController } from './system/CreateReportController';
import { DataTableController } from './system/DataTableController';
import { DataTableWorkflowController } from './system/DataTableWorkflowController';
import { DataTableEntryController } from './system/DataTableEntryController';
import { EditAccountNumberPreferencesController } from './system/EditAccountNumberPreferencesController';
import { EditDataTableController } from './system/EditDataTableController';
import { EditDataTableWorkflowController } from './system/EditDataTableWorkflowController';
import { EditHookController } from './system/EditHookController';
import { EditReportController } from './system/EditReportController';
import { EditSchedulerJobController } from './system/EditSchedulerJobController';
import { EntityToEntityMappingController } from './system/EntityToEntityMappingController';
import { HookController } from './system/HookController';
import { MakeDataTableEntryController } from './system/MakeDataTableEntryController';
import { ManageOTPProtectedOperationController } from './system/ManageOTPProtectedOperationController';
import { CreateOTPProtectedOperationController } from './system/CreateOTPProtectedOperationController';
import { EditOTPProtectedOperationController } from './system/EditOTPProtectedOperationController';
import { ReportsController } from './system/ReportsController';
import { SchedulerJobsController } from './system/SchedulerJobsController';
import { ViewAccountNumberPreferencesController } from './system/ViewAccountNumberPreferencesController';
import { ViewAuditController } from './system/ViewAuditController';
import { ViewAccessAttemptsController } from './system/ViewAccessAttemptsController';
import { ViewAccessAttemptLogsController } from './system/ViewAccessAttemptLogsController';
import { ViewCodeController } from './system/ViewCodeController';
import { ViewDataTableController } from './system/ViewDataTableController';
import { ViewDataTableWorkflowController } from './system/ViewDataTableWorkflowController';
import { ViewHookController } from './system/ViewHookController';
import { ViewReportController } from './system/ViewReportController';
import { ViewSchedulerJobController } from './system/ViewSchedulerJobController';
import { ViewSchedulerJobHistoryController } from './system/ViewSchedulerJobHistoryController';
import { SubscriberController } from './subscribers/SubscriberController';
import { ViewSubscriberController } from './subscribers/ViewSubscriberController';
import { CreateSubscriberController } from './subscribers/CreateSubscriberController';
import { EditSubscriberController } from './subscribers/EditSubscriberController';
import { CreateRecurringPaymentController } from './recurringpayments/CreateRecurringPaymentController';
import { EditRecurringPaymentController } from './recurringpayments/EditRecurringPaymentController';
import { RecurringPaymentController } from './recurringpayments/RecurringPaymentController';
import { ViewRecurringPaymentController } from './recurringpayments/ViewRecurringPaymentController';
import { BulkImportUsersController } from './user/BulkImportUsersController';
import { UserController } from './user/UserController';
import { UserFormController } from './user/UserFormController';
import { UserSettingController } from './user/UserSettingController';
import { EditRevolvingLoanAccAppController } from './loanAccount/EditRevolvingLoanAccAppController';
import { NewRevolvingLoanAccAppController } from './loanAccount/NewRevolvingLoanAccAppController';
import { CreateRevolvingLoanProductController } from './product/revolvingloan/CreateRevolvingLoanProductController';
import { EditRevolvingLoanProductController } from './product/revolvingloan/EditRevolvingLoanProductController';
import { RevolvingLoanController } from './product/revolvingloan/RevolvingLoanController';
import { ViewRevolvingLoanController } from './product/revolvingloan/ViewRevolvingLoanController';
import { ViewOperationalAccountMappingController } from './accounting/ViewOperationalAccountMappingController';
import { EditOperationalAccountMappingController } from './accounting/EditOperationalAccountMappingController';
import { ViewCardAuthorizationController } from './cards/ViewCardAuthorizationController';
import { MerchantChargeController } from './system/merchant/MerchantChargeController';
import { ViewMerchantChargeController } from './system/merchant/ViewMerchantChargeController';
import { CreateMerchantChargeController } from './system/merchant/CreateMerchantChargeController';
import { EditMerchantChargeController } from './system/merchant/EditMerchantChargeController';
import { ViewTransferLimitsController } from './system/ViewTransferLimitsController';
import { CreateTransferLimitsController } from './system/CreateTransferLimitsController';
import { UpdateTransferLimitsController } from './system/UpdateTransferLimitsController';
import { ClientAuditHistoryController } from './client/ClientAuditHistoryController';
import { CreateTransferClientLimitsController } from './system/CreateTransferClientLimitsController';
import { UpdateTransferClientLimitsController } from './system/UpdateTransferClientLimitsController';
import { ClientEditDocumentController } from './client/ClientEditDocumentController';
import { AboutController } from './main/AboutController';
import { ViewWireTransferController } from '@/angular-app/scripts/controllers/accounttransfers/ViewWireTransferController';
import { CreateCreditProductController } from './product/creditproducts/CreateCreditProductController';
import { CreateCreditAccountController } from './creditaccount/CreateCreditAccountController';
import { ViewCreditProductController } from './product/creditproducts/ViewCreditProductController';
import { EditCreditProductController } from './product/creditproducts/EditCreditProductController';
import { ViewCreditAccountController } from './creditaccount/ViewCreditAccountController';
import { EditCreditAccountController } from './creditaccount/EditCreditAccountController';
import { CreditAccountActionsController } from './creditaccount/CreditAccountActionsController';
import { ViewCreditCardTransactionController } from './creditaccount/ViewCreditCardTransactionController';
import { ViewCreditCardAuthorizationController } from './creditaccount/ViewCreditCardAuthorizationController';
import { ViewEmployeeAccountController } from './employees/ViewEmployeeAccountController';
import { ViewBillingCyclesAndStatementsController } from './creditaccount/ViewBillingCyclesAndStatementsController';
import { UserDocumentController } from './organization/UserDocumentController';
import { UserEditDocumentController } from './organization/UserEditDocumentController';
import { CreditAccountDocumentController } from './creditaccount/CreditAccountDocumentController';
import { PaymentController } from './payments/PaymentController';
import { ViewRuleGroupController } from './payments/ViewRuleGroupController';

export default angular
  .module('mbanq.controllers', [])
  .controller('AccFreqPostingController', AccFreqPostingController)
  .controller('JournalEntryController', JournalEntryController)
  .controller('MainController', MainController)
  .controller('NewLoanAccAppController', NewLoanAccAppController)
  .controller('TaskController', TaskController)
  .controller('TransfersController', TransfersController)
  .controller('ViewTransferController', ViewTransferController)
  .controller('SettlementsController', SettlementsController)
  .controller('ViewClientController', ViewClientController)
  .controller('ViewEmployeeClientController', ViewEmployeeClientController)
  .controller('ClientDeceasedController', ClientDeceasedController)
  .controller('AccCoaController', AccCoaController)
  .controller('AccCreateGLAccountController', AccCreateGLAccountController)
  .controller('AccCreateRuleController', AccCreateRuleController)
  .controller('AccEditGLAccountController', AccEditGLAccountController)
  .controller('AccEditRuleController', AccEditRuleController)
  .controller('AccountingClosureController', AccountingClosureController)
  .controller('AccountingRuleController', AccountingRuleController)
  .controller('AccViewGLAccountContoller', AccViewGLAccountContoller)
  .controller('AddFinancialMappingController', AddFinancialMappingController)
  .controller('BulkImportCOAController', BulkImportCOAController)
  .controller('BulkImportJournalEntriesController', BulkImportJournalEntriesController)
  .controller('DefineOpeningBalancesController', DefineOpeningBalancesController)
  .controller('EditFinancialActivityMappingController', EditFinancialActivityMappingController)
  .controller('FinancialActivityMappingsController', FinancialActivityMappingsController)
  .controller('OperationalAccountMappingsController', OperationalAccountMappingsController)
  .controller('CreateOperationalAccountMappingController', CreateOperationalAccountMappingController)
  .controller('PeriodicAccrualAccountingController', PeriodicAccrualAccountingController)
  .controller('CreateProvisoningEntriesController', CreateProvisoningEntriesController)
  .controller('ViewAllProvisoningEntriesController', ViewAllProvisoningEntriesController)
  .controller('ViewProvisioningEntryController', ViewProvisioningEntryController)
  .controller('ViewProvisioningJournalEntriesController', ViewProvisioningJournalEntriesController)
  .controller('ViewAccountingClosureController', ViewAccountingClosureController)
  .controller('ViewAccRuleController', ViewAccRuleController)
  .controller('ViewFinancialActivityController', ViewFinancialActivityController)
  .controller('ViewOperationalAccountMappingController', ViewOperationalAccountMappingController)
  .controller('ViewTransactionController', ViewTransactionController)
  .controller('CreateStandingInstructionController', CreateStandingInstructionController)
  .controller('EditStandingInstructionController', EditStandingInstructionController)
  .controller('ListStandingInstructionController', ListStandingInstructionController)
  .controller('ListTransactionsController', ListTransactionsController)
  .controller('MakeAccountInternalTransferController', MakeAccountInternalTransferController)
  .controller('MakeAccountExternalTransferController', MakeAccountExternalTransferController)
  .controller('IncomingWireTransactionController', IncomingWireTransactionController)
  .controller('CreateExternalWireTransferController', CreateExternalWireTransferController)
  .controller('EditExternalWireTransferController', EditExternalWireTransferController)
  .controller('ViewWireTransferController', ViewWireTransferController)
  .controller('StandingInstructionsHistoryController', StandingInstructionsHistoryController)
  .controller('ViewAccountTransferDetailsController', ViewAccountTransferDetailsController)
  .controller('ViewStandingInstructionController', ViewStandingInstructionController)
  .controller('AdHocQueryListController', AdHocQueryListController)
  .controller('CreateAdHocQueryController', CreateAdHocQueryController)
  .controller('EditAdHocQueryController', EditAdHocQueryController)
  .controller('ViewAdHocQueryController', ViewAdHocQueryController)
  .controller('EditCardLimitController', EditCardLimitController)
  .controller('EditCardSecuritiesController', EditCardSecuritiesController)
  .controller('ViewExternalCardDetailsController', ViewExternalCardDetailsController)
  .controller('BulkImportCentersController', BulkImportCentersController)
  .controller('AddFamilyMembersController', AddFamilyMembersController)
  .controller('AddNewClientChargeController', AddNewClientChargeController)
  .controller('ClientActionsController', ClientActionsController)
  .controller('ClientChargesOverviewController', ClientChargesOverviewController)
  .controller('ClientDocumentController', ClientDocumentController)
  .controller('ClientScreenReportController', ClientScreenReportController)
  .controller('CreateClientController', CreateClientController)
  .controller('EditClientController', EditClientController)
  .controller('EditFamilyMemberController', EditFamilyMemberController)
  .controller('PayClientChargeController', PayClientChargeController)
  .controller('TransactionClientController', TransactionClientController)
  .controller('UploadClientIdentifierDocumentController', UploadClientIdentifierDocumentController)
  .controller('ViewClientChargeController', ViewClientChargeController)
  .controller('EditAddressController', EditAddressController)
  .controller('EditConfigurationController', EditConfigurationController)
  .controller('EditExternalServicesConfigurationController', EditExternalServicesConfigurationController)
  .controller('EditTwoFactorConfigController', EditTwoFactorConfigController)
  .controller('ExternalServicesController', ExternalServicesController)
  .controller('GlobalConfigurationController', GlobalConfigurationController)
  .controller('FieldConfigurationController', FieldConfigurationController)
  .controller('CreateFieldConfigurationController', CreateFieldConfigurationController)
  .controller('EditFieldConfigurationController', EditFieldConfigurationController)
  .controller('ViewFieldConfigurationController', ViewFieldConfigurationController)
  .controller('CreateFieldValidationController', CreateFieldValidationController)
  .controller('EditFieldValidationController', EditFieldValidationController)
  .controller('ViewExternalServicesController', ViewExternalServicesController)
  .controller('ViewTwoFactorConfigController', ViewTwoFactorConfigController)
  .controller('AddNewFixedDepositChargeController', AddNewFixedDepositChargeController)
  .controller('BulkImportFixedDepositAccountsController', BulkImportFixedDepositAccountsController)
  .controller('BulkImportFixedDepositTransactionsController', BulkImportFixedDepositTransactionsController)
  .controller('CreateFixedDepositAccountController', CreateFixedDepositAccountController)
  .controller('EditDepositAccountController', EditDepositAccountController)
  .controller('FixedDepositAccountActionsController', FixedDepositAccountActionsController)
  .controller('ViewFixedDepositAccountDetailsController', ViewFixedDepositAccountDetailsController)
  .controller('ViewFixedDepositTransactionController', ViewFixedDepositTransactionController)
  .controller('AddNewRecurringDepositChargeController', AddNewRecurringDepositChargeController)
  .controller('BulkImportRecurringDepositController', BulkImportRecurringDepositController)
  .controller('BulkImportRecurringDepositTransactionsController', BulkImportRecurringDepositTransactionsController)
  .controller('CreateRecurringDepositAccountController', CreateRecurringDepositAccountController)
  .controller('EditRecurringDepositAccountController', EditRecurringDepositAccountController)
  .controller('RecurringDepositAccountActionsController', RecurringDepositAccountActionsController)
  .controller('ViewRecurringDepositAccountDetailsController', ViewRecurringDepositAccountDetailsController)
  .controller('ViewRecurringDepositTransactionController', ViewRecurringDepositTransactionController)
  .controller('AddLoanChargeController', AddLoanChargeController)
  .controller('AddLoanCollateralController', AddLoanCollateralController)
  .controller('AdjustRepaymentSchedule', AdjustRepaymentSchedule)
  .controller('ApproveLoanRescheduleRequestController', ApproveLoanRescheduleRequestController)
  .controller('AssignLoanOfficerController', AssignLoanOfficerController)
  .controller('BulkImportGuarantorController', BulkImportGuarantorController)
  .controller('BulkImportLoanAccountsController', BulkImportLoanAccountsController)
  .controller('BulkImportLoanRepaymentController', BulkImportLoanRepaymentController)
  .controller('EditGuarantorController', EditGuarantorController)
  .controller('EditLoanAccAppController', EditLoanAccAppController)
  .controller('EditLoanChargeController', EditLoanChargeController)
  .controller('EditLoanCollateralController', EditLoanCollateralController)
  .controller('GuarantorController', GuarantorController)
  .controller('ListGuarantorController', ListGuarantorController)
  .controller('LoanAccountActionsController', LoanAccountActionsController)
  .controller('LoanDocumentController', LoanDocumentController)
  .controller('LoanForeclosureController', LoanForeclosureController)
  .controller('LoanScreenReportController', LoanScreenReportController)
  .controller('NewJLGLoanAccAppController', NewJLGLoanAccAppController)
  .controller('PreviewLoanRepaymentScheduleController', PreviewLoanRepaymentScheduleController)
  .controller('RejectLoanRescheduleRequestController', RejectLoanRescheduleRequestController)
  .controller('RescheduleLoansRequestController', RescheduleLoansRequestController)
  .controller('ViewLoanChargeController', ViewLoanChargeController)
  .controller('ViewLoanCollateralController', ViewLoanCollateralController)
  .controller('ViewLoanDetailsController', ViewLoanDetailsController)
  .controller('ViewLoanTransactionController', ViewLoanTransactionController)
  .controller('ViewRescheduleRequestController', ViewRescheduleRequestController)
  .controller('AdHocQuerySearchController', AdHocQuerySearchController)
  .controller('NavigationController', NavigationController)
  .controller('ProfileController', ProfileController)
  .controller('RichDashboard', RichDashboard)
  .controller('SearchController', SearchController)
  .controller('ViewCheckerinboxController', ViewCheckerinboxController)
  .controller('ViewMakerCheckerTaskController', ViewMakerCheckerTaskController)
  .controller('NotificationsController', NotificationsController)
  .controller('AddHolController', AddHolController)
  .controller('BulkImportEmployeeController', BulkImportEmployeeController)
  .controller('BulkImportOfficesController', BulkImportOfficesController)
  .controller('BulkLoanReassignmentController', BulkLoanReassignmentController)
  .controller('CashierFundsAllocationSettlementController', CashierFundsAllocationSettlementController)
  .controller('CashierTransactionsController', CashierTransactionsController)
  .controller('CreateCashierForTellerController', CreateCashierForTellerController)
  .controller('CreateTellerController', CreateTellerController)
  .controller('EditCashierController', EditCashierController)
  .controller('EditTellerController', EditTellerController)
  .controller('TellersController', TellersController)
  .controller('ViewCashierController', ViewCashierController)
  .controller('ViewCashiersForTellerController', ViewCashiersForTellerController)
  .controller('ViewTellerController', ViewTellerController)
  .controller('CreateEmployeeController', CreateEmployeeController)
  .controller('CreateOfficeController', CreateOfficeController)
  .controller('CreatePaymentTypeController', CreatePaymentTypeController)
  .controller('CurrencyConfigController', CurrencyConfigController)
  .controller('EditEmployeeController', EditEmployeeController)
  .controller('EditHolidayController', EditHolidayController)
  .controller('EditOfficeController', EditOfficeController)
  .controller('EditPasswordPreferencesController', EditPasswordPreferencesController)
  .controller('EditPaymentTypeController', EditPaymentTypeController)
  .controller('EditWorkingDaysController', EditWorkingDaysController)
  .controller('EmployeeController', EmployeeController)
  .controller('EntityDatatableChecksController', EntityDatatableChecksController)
  .controller('HolController', HolController)
  .controller('MakerCheckerController', MakerCheckerController)
  .controller('ManageFundsController', ManageFundsController)
  .controller('OfficesController', OfficesController)
  .controller('CreateProvisioningCriteriaController', CreateProvisioningCriteriaController)
  .controller('EditProvisioningCriteriaController', EditProvisioningCriteriaController)
  .controller('ViewAllProvisoningCriteriaController', ViewAllProvisoningCriteriaController)
  .controller('ViewProvisioningCriteriaController', ViewProvisioningCriteriaController)
  .controller('ViewEmployeeController', ViewEmployeeController)
  .controller('ViewEmployeeTransactionController', ViewEmployeeTransactionController)
  .controller('ViewHolController', ViewHolController)
  .controller('ViewOfficeController', ViewOfficeController)
  .controller('ViewPaymentTypeController', ViewPaymentTypeController)
  .controller('CreateAMLRuleGroupController', CreateAMLRuleGroupController)
  .controller('CreateAMLWatchmanRuleGroupController', CreateAMLWatchmanRuleGroupController)
  .controller('CreateLimitRuleController', CreateLimitRuleController)
  .controller('CreateNCLimitRuleController', CreateNCLimitRuleController)
  .controller('CreateNCPeriodLimitRuleController', CreateNCPeriodLimitRuleController)
  .controller('CreateNCRuleGroupController', CreateNCRuleGroupController)
  .controller('CreateNameMatchingController', CreateNameMatchingController)
  .controller('CreatePeriodLimitRuleController', CreatePeriodLimitRuleController)
  .controller('EditAMLNCRuleGroupController', EditAMLNCRuleGroupController)
  .controller('EditAMLRuleGroupController', EditAMLRuleGroupController)
  .controller('PaymentController', PaymentController)
  .controller('EditLimitRuleController', EditLimitRuleController)
  .controller('EditNCLimitRuleController', EditNCLimitRuleController)
  .controller('EditNameMatchingController', EditNameMatchingController)
  .controller('EditWatchmanRuleGroupController', EditWatchmanRuleGroupController)
  .controller('EditNCPeriodLimitRuleController', EditNCPeriodLimitRuleController)
  .controller('EditPeriodLimitRuleController', EditPeriodLimitRuleController)
  .controller('ViewAMLNCRuleGroupController', ViewAMLNCRuleGroupController)
  .controller('ViewWatchmanGroupController', ViewWatchmanGroupController)
  .controller('ViewAMLRuleGroupController', ViewAMLRuleGroupController)
  .controller('ViewRuleGroupController', ViewRuleGroupController)
  .controller('ViewNameMatchingController', ViewNameMatchingController)
  .controller('ViewRestrictedCountryController', ViewRestrictedCountryController)
  .controller('ViewAMLRuleGroupListController', ViewAMLRuleGroupListController)
  .controller('ViewLimitRuleController', ViewLimitRuleController)
  .controller('ViewNCLimitRuleController', ViewNCLimitRuleController)
  .controller('ViewNCPeriodLimitRuleController', ViewNCPeriodLimitRuleController)
  .controller('ViewPeriodLimitRuleController', ViewPeriodLimitRuleController)
  .controller('CardProductController', CardProductController)
  .controller('ChargeController', ChargeController)
  .controller('CreateCardProductController', CreateCardProductController)
  .controller('CreateChargeController', CreateChargeController)
  .controller('CreateFixedDepositProductController', CreateFixedDepositProductController)
  .controller('CreateInterestRateChartController', CreateInterestRateChartController)
  .controller('CreateLoanProductController', CreateLoanProductController)
  .controller('CreateRecurringDepositProductController', CreateRecurringDepositProductController)
  .controller('CreateSavingProductController', CreateSavingProductController)
  .controller('CreateShareProductController', CreateShareProductController)
  .controller('EditCardProductController', EditCardProductController)
  .controller('EditChargeController', EditChargeController)
  .controller('EditFixedDepositProductController', EditFixedDepositProductController)
  .controller('EditInterestRateChartController', EditInterestRateChartController)
  .controller('EditLoanProductController', EditLoanProductController)
  .controller('EditRecurringDepositProductController', EditRecurringDepositProductController)
  .controller('EditSavingProductController', EditSavingProductController)
  .controller('EditShareProductController', EditShareProductController)
  .controller('FixedDepositProductController', FixedDepositProductController)
  .controller('CreateFloatingRateController', CreateFloatingRateController)
  .controller('EditFloatingRateController', EditFloatingRateController)
  .controller('FloatingRatesController', FloatingRatesController)
  .controller('ViewFloatingRateController', ViewFloatingRateController)
  .controller('InterestRateChartController', InterestRateChartController)
  .controller('LoanProductController', LoanProductController)
  .controller('AddProductMixController', AddProductMixController)
  .controller('ProductMixController', ProductMixController)
  .controller('ViewProductMixController', ViewProductMixController)
  .controller('RecurringDepositProductController', RecurringDepositProductController)
  .controller('ShareProductActionsController', ShareProductActionsController)
  .controller('ShareProductController', ShareProductController)
  .controller('ShareProductDividendController', ShareProductDividendController)
  .controller('CreateTaxComponentController', CreateTaxComponentController)
  .controller('CreateTaxGroupController', CreateTaxGroupController)
  .controller('EditTaxComponentController', EditTaxComponentController)
  .controller('EditTaxGroupController', EditTaxGroupController)
  .controller('TaxComponentController', TaxComponentController)
  .controller('TaxGroupController', TaxGroupController)
  .controller('ViewTaxComponentController', ViewTaxComponentController)
  .controller('ViewTaxGroupController', ViewTaxGroupController)
  .controller('ViewCardProductController', ViewCardProductController)
  .controller('ViewChargeController', ViewChargeController)
  .controller('ViewFixedDepositProductController', ViewFixedDepositProductController)
  .controller('ViewLoanProductController', ViewLoanProductController)
  .controller('ViewRecurringDepositProductController', ViewRecurringDepositProductController)
  .controller('ViewSavingProductController', ViewSavingProductController)
  .controller('ViewShareProductController', ViewShareProductController)
  .controller('ViewShareProductDividendController', ViewShareProductDividendController)
  .controller('RunReportsController', RunReportsController)
  .controller('ViewReportsController', ViewReportsController)
  .controller('AddStopPaymentController', AddStopPaymentController)
  .controller('EditStopPaymentController', EditStopPaymentController)
  .controller('HoldAmountSavingsController', HoldAmountSavingsController)
  .controller('ReleaseAmountSavingsController', ReleaseAmountSavingsController)
  .controller('SettleAmountSavingsController', SettleAmountSavingsController)
  .controller('AssignSavingsOfficerController', AssignSavingsOfficerController)
  .controller('BulkImportSavingsAccountController', BulkImportSavingsAccountController)
  .controller('BulkImportSavingsAccountsTransactionsController', BulkImportSavingsAccountsTransactionsController)
  .controller('ClientTransferOptionsController', ClientTransferOptionsController)
  .controller('CreateSavingAccountController', CreateSavingAccountController)
  .controller('EditSavingAccountController', EditSavingAccountController)
  .controller('ListOnHoldTransactionController', ListOnHoldTransactionController)
  .controller('SavingAccountActionsController', SavingAccountActionsController)
  .controller('UnAssignSavingsOfficerController', UnAssignSavingsOfficerController)
  .controller('ViewSavingChargeController', ViewSavingChargeController)
  .controller('ViewSavingDetailsController', ViewSavingDetailsController)
  .controller('ViewStatementsAndDocumentsController', ViewStatementsAndDocumentsController)
  .controller('ViewSavingsTransactionController', ViewSavingsTransactionController)
  .controller('CreateSelfServiceUserController', CreateSelfServiceUserController)
  .controller('BulkImportShareAccountController', BulkImportShareAccountController)
  .controller('CreateShareAccountController', CreateShareAccountController)
  .controller('EditShareAccountController', EditShareAccountController)
  .controller('ShareAccountActionsController', ShareAccountActionsController)
  .controller('ViewShareAccountController', ViewShareAccountController)
  .controller('AccountNumberPreferencesController', AccountNumberPreferencesController)
  .controller('AddAccountNumberPreferencesController', AddAccountNumberPreferencesController)
  .controller('AuditController', AuditController)
  .controller('CreateDataTableController', CreateDataTableController)
  .controller('CreateDataTableWorkflowController', CreateDataTableWorkflowController)
  .controller('CreateHookController', CreateHookController)
  .controller('CreateReportController', CreateReportController)
  .controller('DataTableController', DataTableController)
  .controller('DataTableWorkflowController', DataTableWorkflowController)
  .controller('DataTableEntryController', DataTableEntryController)
  .controller('EditAccountNumberPreferencesController', EditAccountNumberPreferencesController)
  .controller('EditDataTableController', EditDataTableController)
  .controller('EditDataTableWorkflowController', EditDataTableWorkflowController)
  .controller('EditHookController', EditHookController)
  .controller('EditReportController', EditReportController)
  .controller('EditSchedulerJobController', EditSchedulerJobController)
  .controller('EntityToEntityMappingController', EntityToEntityMappingController)
  .controller('HookController', HookController)
  .controller('MakeDataTableEntryController', MakeDataTableEntryController)
  .controller('ManageOTPProtectedOperationController', ManageOTPProtectedOperationController)
  .controller('CreateOTPProtectedOperationController', CreateOTPProtectedOperationController)
  .controller('EditOTPProtectedOperationController', EditOTPProtectedOperationController)
  .controller('ReportsController', ReportsController)
  .controller('SchedulerJobsController', SchedulerJobsController)
  .controller('ViewAccountNumberPreferencesController', ViewAccountNumberPreferencesController)
  .controller('ViewAuditController', ViewAuditController)
  .controller('ViewAccessAttemptsController', ViewAccessAttemptsController)
  .controller('ViewAccessAttemptLogsController', ViewAccessAttemptLogsController)
  .controller('ViewCodeController', ViewCodeController)
  .controller('ViewDataTableController', ViewDataTableController)
  .controller('ViewDataTableWorkflowController', ViewDataTableWorkflowController)
  .controller('ViewHookController', ViewHookController)
  .controller('ViewReportController', ViewReportController)
  .controller('ViewSchedulerJobController', ViewSchedulerJobController)
  .controller('ViewSchedulerJobHistoryController', ViewSchedulerJobHistoryController)
  .controller('SubscriberController', SubscriberController)
  .controller('ViewSubscriberController', ViewSubscriberController)
  .controller('CreateSubscriberController', CreateSubscriberController)
  .controller('EditSubscriberController', EditSubscriberController)
  .controller('CreateRecurringPaymentController', CreateRecurringPaymentController)
  .controller('EditRecurringPaymentController', EditRecurringPaymentController)
  .controller('RecurringPaymentController', RecurringPaymentController)
  .controller('ViewRecurringPaymentController', ViewRecurringPaymentController)
  .controller('BulkImportUsersController', BulkImportUsersController)
  .controller('UserController', UserController)
  .controller('UserFormController', UserFormController)
  .controller('UserSettingController', UserSettingController)
  .controller('EditRevolvingLoanAccAppController', EditRevolvingLoanAccAppController)
  .controller('NewRevolvingLoanAccAppController', NewRevolvingLoanAccAppController)
  .controller('CreateRevolvingLoanProductController', CreateRevolvingLoanProductController)
  .controller('EditRevolvingLoanProductController', EditRevolvingLoanProductController)
  .controller('RevolvingLoanController', RevolvingLoanController)
  .controller('ViewRevolvingLoanController', ViewRevolvingLoanController)
  .controller('EditOperationalAccountMappingController', EditOperationalAccountMappingController)
  .controller('ViewCardAuthorizationController', ViewCardAuthorizationController)
  .controller('MerchantChargeController', MerchantChargeController)
  .controller('ViewMerchantChargeController', ViewMerchantChargeController)
  .controller('CreateMerchantChargeController', CreateMerchantChargeController)
  .controller('EditMerchantChargeController', EditMerchantChargeController)
  .controller('ViewTransferLimitsController', ViewTransferLimitsController)
  .controller('CreateTransferLimitsController', CreateTransferLimitsController)
  .controller('UpdateTransferLimitsController', UpdateTransferLimitsController)
  .controller('ClientAuditHistoryController', ClientAuditHistoryController)
  .controller('CreateTransferClientLimitsController', CreateTransferClientLimitsController)
  .controller('UpdateTransferClientLimitsController', UpdateTransferClientLimitsController)
  .controller('ClientEditDocumentController', ClientEditDocumentController)
  .controller('AboutController', AboutController)
  .controller('CreateCreditProductController', CreateCreditProductController)
  .controller('CreateCreditAccountController', CreateCreditAccountController)
  .controller('ViewCreditProductController', ViewCreditProductController)
  .controller('EditCreditProductController', EditCreditProductController)
  .controller('ViewCreditAccountController', ViewCreditAccountController)
  .controller('EditCreditAccountController', EditCreditAccountController)
  .controller('CreditAccountActionsController', CreditAccountActionsController)
  .controller('ViewCreditCardAuthorizationController', ViewCreditCardAuthorizationController)
  .controller('ViewCreditCardTransactionController', ViewCreditCardTransactionController)
  .controller('ViewBillingCyclesAndStatementsController', ViewBillingCyclesAndStatementsController)
  .controller('ViewEmployeeAccountController', ViewEmployeeAccountController)
  .controller('UserDocumentController', UserDocumentController)
  .controller('UserEditDocumentController', UserEditDocumentController)
  .controller('CreditAccountDocumentController', CreditAccountDocumentController)
  .controller('PaymentController', PaymentController)
  .controller('ViewRuleGroupController', ViewRuleGroupController)
  .name;
