<template>
  <nav>
    <ul class="pagination mb-0">
      <li
        class="page-item"
        :class="{disabled: currentPage === 1}"
      >
        <a
          class="page-link"
          @click="prevPageClickHandler"
        >
          <span aria-hidden="true">&lsaquo;</span>
        </a>
      </li>
      <li
        class="page-item active"
        aria-current="page"
      >
        <a class="page-link">
          {{ currentPage }}
        </a>
      </li>
      <li
        class="page-item"
        :class="{disabled: disableNextPage || loading}"
      >
        <a
          class="page-link"
          @click="nextPageClickHandler"
        >
          <span aria-hidden="true">&rsaquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { ref, computed } from 'vue';
import { set, get } from '@vueuse/core';
import { useSharedStore } from '@/stores/shared';

const initialPage = 1;
const incrementDecrementBy = 1;
const sharedStore = useSharedStore();

const props = defineProps({
  currentItemsLength: {
    type: Number,
    required: true
  },
  entriesPerPage: {
    type: Number,
    required: true
  }
});

const emit = defineEmits([]);
const loading = computed(() => sharedStore.loading);

const currentPage = ref(initialPage);
const prevPageClickHandler = () => {
  set(currentPage, Math.max(initialPage, (get(currentPage) - incrementDecrementBy)));
  emit('input', currentPage);
};
const nextPageClickHandler = () => {
  set(currentPage, get(currentPage) + incrementDecrementBy);
  emit('input', currentPage);
};

const disableNextPage = computed(() => props.currentItemsLength < props.entriesPerPage);

</script>
