import 'angular';
// modules
import Vue from 'vue';

import './plugins/prototypes';
import './plugins/bootstrapVue';
import appModules from './angular-app/app.modules';

// routes
import './angular-app/app.routes';

// angularJS Translation
import './angular-app/app.locales';

import 'chosen-js/chosen.jquery.min';

// Style Import
import './angular-app/styles-dev/styles.scss';
import 'vue-select/dist/vue-select.css';
// import 'vue2-datepicker/index.css'
import { expiryTokenInterceptor } from './helper/expiryTokenInterceptor';
import { getTenantIdentifier } from './helper/getTenantIdentifier';
import '@/directives';

// Vue router
import App from './layouts/App.vue';
import { pinia } from './stores';
import router from './router';
import i18n from './plugins/i18n';
import VueNumberFormat from '@coders-tm/vue-number-format';
import { sanitize } from './plugins/sanitize';

Vue.use(VueNumberFormat);
Vue.use(sanitize, {
  allowedTags: false,
  allowedAttributes: false
});
// Vue config
Vue.config.productionTip = false;

// Initial Config for AngularApp
appModules.config(['$httpProvider', 'ResourceFactoryProvider', 'HttpServiceProvider', 'paginationTemplateProvider', 'IdleProvider', 'KeepaliveProvider', 'IDLE_DURATION', 'WARN_DURATION', 'KEEPALIVE_INTERVAL', ($httpProvider, ResourceFactoryProvider, HttpServiceProvider, paginationTemplateProvider, IdleProvider, KeepaliveProvider, IDLE_DURATION, WARN_DURATION, KEEPALIVE_INTERVAL) => {
  const baseApiUrl = process.env.VUE_APP_API_URL;
  const tenantIdentifier = getTenantIdentifier();

  $httpProvider.defaults.headers.common.TenantId = tenantIdentifier;

  ResourceFactoryProvider.setTenantIdenetifier(tenantIdentifier);
  ResourceFactoryProvider.setBaseUrl(baseApiUrl);

  HttpServiceProvider.addRequestInterceptor('demoUrl', (config) => ({ ...config, ...{ url: baseApiUrl + config.url } }));

  // Enable CORS! (see e.g. http://enable-cors.org/)
  $httpProvider.defaults.useXDomain = true;
  delete $httpProvider.defaults.headers.common['X-Requested-With'];

  // Set headers
  $httpProvider.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';

  // Token interceptors
  $httpProvider.interceptors.push(expiryTokenInterceptor);

  // IDLE Timeout settings
  IdleProvider.idle(IDLE_DURATION);
  IdleProvider.timeout(WARN_DURATION);
  KeepaliveProvider.interval(KEEPALIVE_INTERVAL);

  paginationTemplateProvider.setString(require('./angular-app/views/customDirPagination.tpl.html'));
}]);

const app = new Vue({
  pinia,
  router,
  i18n,
  render: h => h(App)
}).$mount('#vue-app');

export { app };
