import { paymentRailsValueExcludes } from '@/utils/dictionaries.js';
export function CreateTransferLimitsController ($scope, resourceFactory, location) {
  $scope.formData = {};
  $scope.transferTypes = ['DEBIT', 'CREDIT'];
  $scope.durationTypes = ['DAILY', 'WEEKLY', 'MONTHLY'];
  $scope.classifications = [];
  $scope.paymentTypeNames = paymentRailsValueExcludes(['ALL', 'SWIFT']);
  $scope.clientLegalFormOptions = [];
  $scope.clientClassificationsOptions = [];

  resourceFactory.transferLimitsTemplate.get(function (data) {
    $scope.clientLegalFormOptions = data.clientLegalFormOptions;
    $scope.classifications = data.clientClassificationOptions;
  });
  $scope.getAgingBuckets = function () {
    resourceFactory.agingBucketResource.get(function (data) {
      $scope.agingBuckets = data.pageItems;
    });
  };

  $scope.getAgingBuckets();

  $scope.updateLegalForm = function () {
    if (!$scope.formData.legalForm) {
      $scope.clientClassificationId = null;
    }
    $scope.clientClassificationsOptions = $scope.classifications.filter(function (data) {
      return $scope.formData.legalForm === 1
        ? data.codeName === 'ClientClassification-PERSON'
        : data.codeName === 'ClientClassification-ENTITY';
    });
  };

  $scope.submit = function () {
    if ($scope.formData.clientClassificationId) {
      delete $scope.formData.agingBucketId;
    }
    resourceFactory.transferLmitsResource.create($scope.formData, function () {
      location.path(`/payments/transfer-limits/`);
    });
  };
}

CreateTransferLimitsController.$inject = ['$scope', 'ResourceFactory', '$location'];
