import { paymentRailsValueExcludes } from '@/utils/dictionaries.js';
export function UpdateTransferLimitsController ($scope, resourceFactory, routeParams, location) {
  $scope.formData = {};
  $scope.transferTypes = ['DEBIT', 'CREDIT'];
  $scope.durationTypes = ['DAILY', 'WEEKLY', 'MONTHLY'];
  $scope.classifications = [];
  $scope.paymentTypeNames = paymentRailsValueExcludes(['ALL', 'SWIFT']);

  resourceFactory.codeValueResource.getAllCodeValues({ codeId: 17 }, function (data) {
    $scope.classifications = data;
  });

  resourceFactory.transferLmitsResource.get({ transferLimitsId: routeParams.id }, function (data) {
    $scope.formData = data;
  });

  $scope.submit = function () {
    resourceFactory.transferLmitsResource.update({ transferLimitsId: routeParams.id }, { ...$scope.formData, locale: 'en' }, function () {
      location.path(`/payments/transfer-limits/`);
    });
  };
}

UpdateTransferLimitsController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location'];
