<template>
  <span>
    <span v-if="isFxPay">
      {{ profile.identifier }} /
    </span>
    <span v-else>
      <span>{{ profile.routeCode }} / </span>
      <span v-if="profile.accountId">
        <a
          class="link-primary text-underline-hover"
          @click="redirect(profile.accountId)"
        >{{ profile.accountNumber }}</a> / </span>
      <span v-else>{{ profile.accountNumber }} / </span>
    </span>
    <span>{{ profile.name }}</span>
  </span>
</template>

<script>
export default {
  name: 'TxScreeningProfileTd',
  props: {
    profile: {
      type: Object,
      required: true
    }
  },
  computed: {
    isFxPay () {
      return this.profile.identifier.includes('FXPAY:');
    }
  },
  methods: {
    redirect (transferIdentifier) {
      const accNumber = transferIdentifier.toString().replace('ACCOUNT:', '');
      const accId = parseInt(accNumber, 10);
      location.assign(`#/viewsavingaccount/${accId}`);
    }
  }
};
</script>
