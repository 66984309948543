export function FieldConfigurationController (scope, resourceFactory, location) {
  this.$onInit = function () {
    scope.getResultsPage();
  };
  let searchText = '';
  scope.searchText = '';
  scope.rowPerPage = 15;
  scope.routeTo = function (id) {
    location.path(`/viewfieldconfiguration/${id}`);
  };

  scope.search = function () {
    searchText = scope.searchText;
    const pageNumber = 1;
    scope.getResultsPage(pageNumber, searchText);
  };

  scope.getResultsPage = function (pageNumber = 1, searchText) {
    const queryString = {
      offset: ((pageNumber - 1) * scope.rowPerPage),
      limit: scope.rowPerPage
    };
    if (searchText) queryString.entity = searchText;
    resourceFactory.fieldconfigurationResource.get({ ...queryString }, function (data) {
      scope.fieldconfigurations = data.pageItems;
      scope.totalFilteredRecords = data.totalFilteredRecords;
    });
  };
}

FieldConfigurationController.$inject = ['$scope', 'ResourceFactory', '$location'];
