export function CreateTellerController (scope, resourceFactory, location, dateFilter) {
  scope.offices = [];
  scope.tellerStatuses = [{ id: 300, code: '300', value: 'Active' }, { id: 400, code: '400', value: 'Inactive' }];
  scope.first = {};
  scope.first.date = new Date();
  scope.restrictDate = new Date();
  resourceFactory.officeResource.getAllOffices(function (data) {
    scope.offices = data;
    scope.formData = {
      officeId: scope.offices[0].id
    };
  });

  scope.submit = function () {
    this.formData.locale = scope.optlang.code;
    const reqDate = dateFilter(scope.first.date, scope.df);
    const endDate = dateFilter(scope.formData.endDate, scope.df);
    this.formData.dateFormat = scope.df;
    this.formData.startDate = reqDate;
    this.formData.endDate = endDate;
    resourceFactory.tellerResource.save(this.formData, function (data) {
      location.path(`/viewtellers/${data.resourceId}`);
    });
  };
}

CreateTellerController.$inject = ['$scope', 'ResourceFactory', '$location', 'dateFilter'];
