export function ViewStatementsAndDocumentsController (scope, routeParams, resourceFactory, http, $rootScope) {
  scope.accountId = routeParams.accountId;
  scope.amountOfYears = 6;
  scope.month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  scope.hosturl = $rootScope.hosturl;
  const getLastNYears = function (amount) {
    const today = new Date();
    const currentYear = today.getFullYear();
    const lastNYears = [(currentYear - amount).toString()];
    while (lastNYears[lastNYears.length - 1] < currentYear) {
      const lastYear = parseInt(lastNYears[lastNYears.length - 1], 10) + 1;
      lastNYears.push(lastYear.toString());
    }
    return lastNYears;
  };
  scope.dropDownYears = getLastNYears(scope.amountOfYears);
  scope.selectYear = scope.dropDownYears[scope.dropDownYears.length - 1];

  scope.getStatements = function (selectYear) {
    const createdAtFrom = `${selectYear}-01-01 00:00:00`;
    const createdAtTo = `${selectYear}-12-31 23:59:00`;
    resourceFactory.documentsAndStatements.get({ accountId: scope.accountId, createdAtFrom, createdAtTo, type: 'statement' },
      function (response) {
        scope.statements = response;
      }
    );
  };
  scope.getStatements(scope.selectYear);

  scope.downloadDocumentXhr = function (savingId, documentId, name) {
    const configObj = {
      method: 'GET',
      url: `${$rootScope.hostUrl}/v1/savings/${savingId}/documents/${documentId}/attachment`,
      responseType: 'arraybuffer'
    };

    http(configObj)
      .then(function onFulfilled (response) {
        const a = document.createElement('a');
        const windowUrl = window.URL || window.webkitURL;
        const blob = new Blob([response.data], { type: 'application/pdf', responseType: 'arraybuffer' });

        const url = windowUrl.createObjectURL(blob);
        a.href = url;
        a.download = name;
        a.click();
        windowUrl.revokeObjectURL(url);
      }).catch(function onRejection (errorResponse) {
        console.error(errorResponse);
      });
  };
}
ViewStatementsAndDocumentsController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$http', '$rootScope'];
