export function ViewReportController (scope, routeParams, resourceFactory, location, $uibModal) {
  resourceFactory.reportsResource.getReportDetails({ id: routeParams.id }, function (data) {
    scope.report = data;
    scope.noncoreReport = data.coreReport !== true;
  });
  scope.deletereport = function () {
    $uibModal.open({
      templateUrl: 'deletenoncorereport.html',
      controller: NoncoreReportDeleteCtrl
    });
  };
  const NoncoreReportDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.reportsResource.delete({ id: routeParams.id }, {}, function () {
        $uibModalInstance.close('delete');
        location.path('/reports');
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewReportController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location', '$uibModal'];
