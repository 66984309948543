export function EditHookController (scope, routeParams, resourceFactory, location) {
  let eventsResource = [];

  scope.hook = [];
  scope.hookId = routeParams.id;
  scope.eventActions = [];
  scope.inputEvent = { entity: '', action: '' };
  scope.events = [];
  scope.eventsToRemove = [];

  resourceFactory.webhooksResource.get({ eventAction: 'endpoints', eventActionId: routeParams.id }, (response) => {
    scope.hook = response;
    scope.events = response.subscriptions.map(subscription => ({ ...subscription, entity: subscription.type }));
  });

  resourceFactory.eventsResource.query(function (response) {
    eventsResource = response;
    scope.eventEntity = eventsResource.map(resource => resource.entity).sort((a, b) => a > b);
  });

  scope.onEntityChange = function (entity) {
    scope.inputEvent.action = '';
    scope.eventActions = eventsResource.find(resource => resource.entity === entity).actions.sort((a, b) => a > b);
  };

  scope.addEvent = function () {
    if (
      scope.inputEvent.entity &&
      scope.inputEvent.action &&
      !scope.events.find(e => e.entity === scope.inputEvent.entity && e.action === scope.inputEvent.action)
    ) {
      scope.events.push({ entity: scope.inputEvent.entity, action: scope.inputEvent.action });
    }

    scope.inputEvent = { entity: '', action: '' };
  };

  scope.deleteEvent = function (index) {
    scope.eventsToRemove.push(scope.events[index]);
    scope.events.splice(index, 1);
  };

  scope.submit = async function () {
    // Promisify it because resourceFactory somehow can't return promise
    const deleteSubscriptionWithPromise = subscription => {
      return new Promise(resolve => {
        resourceFactory.webhooksResource.delete({ eventAction: 'subscriptions', eventActionId: subscription.id }, function (data) {
          resolve(data);
        });
      });
    };

    const saveSubscriptionWithPromise = subscription => {
      return new Promise(resolve => {
        if (!subscription.id) {
          const subscriptionsData = {
            endpointId: routeParams.id,
            type: subscription.entity,
            action: subscription.action,
            active: true,
            deliveryRetryLimit: 3
          };
          resourceFactory.webhooksResource.save({ eventAction: 'subscriptions' }, subscriptionsData, function (data) {
            resolve(data);
          });
        }

        resolve();
      });
    };

    try {
      if (scope.eventsToRemove.length) {
        await Promise.all(scope.eventsToRemove.map(async subscription => {
          return deleteSubscriptionWithPromise(subscription);
        }));
      }

      if (scope.events.length) {
        await Promise.all(scope.events.map(async subscription => {
          return saveSubscriptionWithPromise(subscription);
        }));
      }
    } catch (error) {
      console.error({ error });
    } finally {
      const { name, url, secretKey } = scope.hook;
      resourceFactory.webhooksResource.update({ eventAction: 'endpoints', eventActionId: scope.hookId }, { name, url, secretKey }, (response) => {
        location.path(`/viewhook/${response.resourceId}`);
      });
    }
  };
}

EditHookController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
