export function EditAddressController ($scope, resourceFactory, routeParams) {
  $scope.formData = {};
  $scope.countryOptions = [];
  $scope.stateOptions = [];
  $scope.selectedAddressType = '';
  $scope.clients = {};
  $scope.addStatus = '';
  $scope.editable = false;

  $scope.clientId = routeParams.clientId;
  const addresstypid = routeParams.addrType;
  let stateProvinceIdOptions = [];

  $scope.primaryAccountId = routeParams.primaryAccountId;

  resourceFactory.clientResource.get({ clientId: $scope.clientId }, function (data) {
    $scope.displaySubAccountClientName = data.displayName;
  });

  resourceFactory.clientaddressFields.get(function (data) {
    $scope.countryOptions = sortedCountry(data.countryIdOptions);
    stateProvinceIdOptions = data.stateProvinceIdOptions;

    getClientAddress();
  });

  resourceFactory.addressFieldConfiguration.get({ entity: 'ADDRESS' }, function (response) {
    const { pageItems } = response;

    pageItems.forEach(({ fieldValidations }) => {
      fieldValidations.forEach(fieldValidation => {
        $scope[fieldValidation.field] = true;
      });
    });
  });

  $scope.back = function () {
    history.back();
  };

  function getClientAddress () {
    resourceFactory.clientAddress.query({ clientId: $scope.clientId, type: addresstypid }, function (data) {
      $scope.formData.addressId = data[0].addressId;
      $scope.editable = true;
      $scope.formData.addressLine1 = data[0].addressLine1;
      $scope.formData.addressLine2 = data[0].addressLine2;
      $scope.formData.addressLine3 = data[0].addressLine3;
      $scope.formData.city = data[0].city;
      $scope.formData.stateProvinceId = data[0].stateProvinceId;
      $scope.formData.countryId = data[0].countryId;
      $scope.formData.postalCode = data[0].postalCode;
      $scope.selectedAddressType = data[0].addressType;
      $scope.selectedState = stateProvinceIdOptions.find(stateOption => $scope.formData.stateProvinceId === stateOption.id);
      $scope.selectedCountry = $scope.countryOptions.find(countryOption => $scope.formData.countryId === countryOption.id);
      $scope.stateOptions = sortedStateByCountry();
    });
  }

  $scope.updateaddress = function () {
    $scope.formData.countryId = $scope.selectedCountry?.id || $scope.selectedCountry;
    $scope.formData.stateProvinceId = $scope.selectedState?.id || $scope.selectedState;

    resourceFactory.clientAddress.put({ clientId: $scope.clientId, type: addresstypid }, { ...$scope.formData }, function () {
      $scope.back();
    });
  };

  $scope.onCountryChange = function () {
    $scope.stateOptions = sortedStateByCountry();
  };

  function sortedCountry (_countries) {
    return _countries.sort(function (a, b) {
      return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
    });
  }

  function sortedStateByCountry () {
    const states = stateProvinceIdOptions.filter(function (_option) {
      const countryId = $scope.selectedCountry?.id || $scope.selectedCountry;
      return _option.parentId === countryId;
    });

    return states.sort(function (a, b) {
      return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
    });
  }
}

EditAddressController.$inject = ['$scope', 'ResourceFactory', '$routeParams'];
