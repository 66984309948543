// import ChartOfAccounts from '@/pages/chart-of-accounts.vue';
import { clientsRoutes } from '@/router/private/routes/clients';
import { templateRoutes } from '@/router/private/routes/template';
import { subscribersRoute } from '@/router/private/routes/subscribers';
import { employeeRoutes } from '@/router/private/routes/employees';
import { achProcessingRoutes } from '@/router/private/routes/ach-processing';
import { organizationRoutes } from '@/router/private/routes/organization';
import { paymentsRoutes } from '@/router/private/routes/payment';
import { savingAccountsRoutes } from '@/router/private/routes/saving-accounts';
import { transactionMonitoringRoutes } from '@/router/private/routes/transaction-monitoring';
import { holdAmountDetailsRoutes } from '@/router/private/routes/hold-amount-detail';
import { systemRoutes } from '@/router/private/routes/system';
import { productsRoutes } from '@/router/private/routes/products';
import { acocuntingRoutes } from '@/router/private/routes/accounting';
import { disputeManagementRoutes } from '@/router/private/routes/dispute-management';
import { usersRoutes } from '@/router/private/routes/users';
import { homeRoutes } from '@/router/private/routes/home';

const routes = [
  // { path: '/accounting-coa', component: ChartOfAccounts, name: 'ChartOfAccount' },
  ...clientsRoutes,
  ...employeeRoutes,
  ...templateRoutes,
  ...subscribersRoute,
  ...achProcessingRoutes,
  ...organizationRoutes,
  ...transactionMonitoringRoutes,
  ...holdAmountDetailsRoutes,
  ...systemRoutes,
  ...productsRoutes,
  ...savingAccountsRoutes,
  ...paymentsRoutes,
  ...acocuntingRoutes,
  ...disputeManagementRoutes,
  ...usersRoutes,
  ...homeRoutes
];

export default routes;
