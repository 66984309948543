export function CreateFieldValidationController (scope, routeParams, resourceFactory, location) {
  scope.formData = {};
  scope.formData.isMandatory = false;
  const fieldConfigurationId = routeParams.fieldConfigurationId;

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
  scope.submit = function () {
    resourceFactory.fieldvalidationResource.save({ fieldConfigurationId }, scope.formData, function () {
      location.path(`/viewfieldconfiguration/${fieldConfigurationId}`);
    }, ({ data: { errors } }) => {
      scope.errorMsg = errors[0].defaultUserMessage;
    });
  };
}

CreateFieldValidationController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
