import ProductList from '@/pages/products/index.vue';
import ClientClassificationLimits from '@/pages/products/client-classification-limits/index.vue';
import AddClientClassificationLimits from '@/pages/products/client-classification-limits/add.vue';
import EditClientClassificationLimits from '@/pages/products/client-classification-limits/edit.vue';
import ViewClientClassificationLimits from '@/pages/products/client-classification-limits/view.vue';
import SavingAccountsProducts from '@/pages/products/saving-accounts/index.vue';
import SavingsChargeClassification from '@/pages/products/savings-charge-classification/index.vue';
import CreditProducts from '@/pages/products/credit/index.vue';
import LoanProducts from '@/pages/products/loans/index.vue';

export const productsRoutes = [
  {
    path: '/products',
    name: 'ProductList',
    component: ProductList
  },
  {
    path: '/products/saving-accounts',
    name: 'SavingAccountsProducts',
    component: SavingAccountsProducts
  },
  {
    path: '/products/client-classification-limits',
    name: 'ClientClassificationLimits',
    component: ClientClassificationLimits
  },
  {
    path: '/products/client-classification-limits/:id(\\d+)',
    name: 'ViewClientClassificationLimits',
    component: ViewClientClassificationLimits
  },
  {
    path: '/products/client-classification-limits/add',
    name: 'AddClientClassificationLimits',
    component: AddClientClassificationLimits
  },
  {
    path: '/products/client-classification-limits/:id/edit',
    name: 'EditClientClassificationLimits',
    component: EditClientClassificationLimits
  },
  {
    path: '/products/savings-charge-classification',
    name: 'SavingsChargeClassification',
    component: SavingsChargeClassification
  },
  {
    path: '/products/credit',
    name: 'CreditProducts',
    component: CreditProducts
  },
  {
    path: '/products/loans',
    name: 'LoanProducts',
    component: LoanProducts
  }
];
