import { IdentifierFormat } from '../../../angular-app/scripts/filters/AccountIdentifierFormat';

export const findRecipientAndOriginator = (transfer) => {
  const getRecipient = {
    INTERNAL: { CREDIT: 'creditor', DEBIT: 'debtor' },
    IN: { CREDIT: 'creditor', DEBIT: 'debtor' },
    OUT: { CREDIT: 'creditor', DEBIT: 'debtor' }
  };
  const getOriginator = {
    INTERNAL: { CREDIT: 'debtor', DEBIT: 'creditor' },
    IN: { CREDIT: 'debtor', DEBIT: 'creditor' },
    OUT: { CREDIT: 'debtor', DEBIT: 'creditor' }
  };
  // some field are inOut some are inOrOut / inOut, not really consistent
  const recipient = transfer[getRecipient[transfer.inOrOut || transfer.inOut][transfer.type]];
  const rIdentifier = IdentifierFormat()(recipient.identifier);
  recipient.accountNumber = transfer[`${getRecipient[transfer.inOrOut || transfer.inOut][transfer.type]}AccountNumber`] || rIdentifier.accountNumber;
  recipient.accountId = transfer[`${getRecipient[transfer.inOrOut || transfer.inOut][transfer.type]}AccountId`];
  recipient.routeCode = rIdentifier.routeCode;
  recipient.name = transfer[`${getRecipient[transfer.inOrOut || transfer.inOut][transfer.type]}`]?.name;

  const originator = transfer[getOriginator[transfer.inOrOut || transfer.inOut][transfer.type]];
  const oIdentifier = IdentifierFormat()(originator.identifier);
  originator.accountNumber = transfer[`${getOriginator[transfer.inOrOut || transfer.inOut][transfer.type]}AccountNumber`] || oIdentifier.accountNumber;
  originator.accountId = transfer[`${getOriginator[transfer.inOrOut || transfer.inOut][transfer.type]}AccountId`];
  originator.routeCode = oIdentifier.routeCode;
  originator.name = transfer[`${getOriginator[transfer.inOrOut || transfer.inOut][transfer.type]}`]?.name;

  const displayAmountSymbol = transfer.currencyData?.displaySymbol || transfer.currency?.symbol || '';
  const displayAmount = `${transfer.amount} ${displayAmountSymbol}`;

  return { recipient, originator, displayAmount };
};
