export function ViewClientChargeController ($scope, resourceFactory, location, routeParams, route) {
  $scope.clientId = routeParams.clientId;

  resourceFactory.clientChargesResource.get({ clientId: routeParams.clientId, resourceType: routeParams.chargeId, associations: 'all' }, function (data) {
    $scope.charge = data;
  });

  $scope.undoTransaction = function (transactionId) {
    resourceFactory.clientTransactionResource.undoTransaction({ clientId: routeParams.clientId, transactionId }, function () {
      route.reload();
    });
  };

  $scope.waiveCharge = function () {
    resourceFactory.clientChargesResource.waive({ clientId: routeParams.clientId, resourceType: routeParams.chargeId }, function () {
      route.reload();
    });
  };

  $scope.deleteCharge = function () {
    resourceFactory.clientChargesResource.delete({ clientId: routeParams.clientId, resourceType: routeParams.chargeId, associations: 'all' }, function () {
      location.path(`/viewclient/${$scope.clientId}`);
    });
  };
}

ViewClientChargeController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams', '$route'];
