<template>
  <div
    class="form-group align-items-center pb-3 row"
  >
    <label
      v-if="label"
      class="control-label"
      :class="vertical ? 'mb-1' : 'text-end col-sm-4'"
      :for="id"
    >
      {{ label }}
      <span
        v-if="required"
        class="required"
      >*</span>
    </label>

    <div
      :class="{ 'col-sm-8': !vertical }"
      class="btn-group"
    >
      <input
        :id="id"
        ref="inputFile"
        type="file"
        :accept="accept"
        :class="['form-control', {'is-invalid': errorMessage}]"
        @input="updateInput"
      >
      <button
        v-if="!instantUpload"
        type="button"
        class="form-control btn btn-primary flex-shrink-0"
        style="flex-basis: 80px"
        :disabled="!file || loading"
        @click="uploadFile"
      >
        {{ $t('label.button.upload') }}
      </button>
    </div>
    <div
      v-if="errorMessage"
      class="text-danger"
      :class="{'offset-sm-4': !vertical}"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import formWrapperRegister from '@/components/shared/form-input/mixin.js';

export default {
  mixins: [formWrapperRegister],
  props: {
    value: {
      type: File,
      default: null
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      defualt: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: '*'
    },
    instantUpload: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isUploaded: false,
      file: null
    };
  },
  created: async function () {
    if (this.value) {
      await this.$nextTick();
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(this.value);
      this.$refs.inputFile.files = dataTransfer.files;
    }
  },
  methods: {
    updateInput (value) {
      this.validatorRunner();
      this.$emit('input', value.target.files[0]);
      this.file = value.target.files[0];
      if (this.instantUpload) {
        this.uploadFile();
      }
    },
    uploadFile () {
      this.$emit('upload', this.file);
    }
  }
};
</script>
