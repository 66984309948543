export function ViewRuleGroupController (scope, routeParams, resourceFactory) {
  let className = '';
  scope.load = function () {
    scope.formData = {};
    scope.isDisabled = true;
    scope.isBeautify = false;
    scope.keys = [];
    scope.errorMsg = '';
    scope.getRuleGroupList = function () {
      resourceFactory.newAmlGroupsResource.getOne({ id: routeParams.id }, function (data) {
        scope.ruleGroup = data;
        scope.formData.name = data.name;
        scope.formData.description = data.description;
        scope.formData.ruleSequence = data.ruleSequence;
        scope.formData.isActive = data.isActive;
        className = data.ruleConfig.class;
        delete data.ruleConfig.class;
        // scope.formData.ruleConfig = JSON.stringify(data.ruleConfig);

        scope.formData.ruleConfig = JSON.stringify(data.ruleConfig, null, 4);

        scope.uleConfig = JSON.stringify(scope.formData.ruleConfig);
        scope.parsedRuleConfig = JSON.parse(scope.formData.ruleConfig);
        scope.keys = Object.keys(scope.parsedRuleConfig);
      });
    };
    scope.getRuleGroupList();
  };
  scope.load();

  scope.isEditable = function () {
    scope.isDisabled = !scope.isDisabled;
  };

  scope.validationError = function () {
    let isError = false;
    scope.errorMsg = '';
    try {
      const tempKeys = Object.keys(JSON.parse(scope.formData.ruleConfig));
      const intersection = scope.keys.filter(x => !tempKeys.includes(x));
      if (intersection.length > 0) {
        scope.errorMsg = 'Rule config params [' + intersection + '] missing';
        return true;
      }
    } catch (e) {
      scope.errorMsg = 'Rule config not a valid json';
      isError = true;
    }
    return isError;
  };

  scope.submit = function () {
    const isError = scope.validationError();
    if (isError) {
      return;
    }
    scope.formData.ruleConfig = JSON.parse(scope.formData.ruleConfig);
    scope.formData.ruleConfig.class = className;
    resourceFactory.newAmlGroupsResource.update({ id: routeParams.id }, scope.formData, handleUpdateAmlRulesSuccess, handleResponseError);
    function handleUpdateAmlRulesSuccess () {
      scope.load();
    }
    function handleResponseError (error) {
      const { data } = error;
      scope.errorMsg = data.errors[0].defaultUserMessage;
    }
  };
}

ViewRuleGroupController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
