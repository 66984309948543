<template>
  <div class="toolbar">
    <h4><slot /></h4>
  </div>
</template>

<script>
export default {
  name: 'PageTitle'
};
</script>
