<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div>
          <b-table
            :items="rows"
            :fields="fields"
            :responsive="true"
            empty-text="no records to show"
            :show-empty="true"
          >
            <template #cell(date)="data">
              {{ dateStringToFormat(data.item.createdAt.date) }}
            </template>
          </b-table>
          <b-pagination
            v-if="totalRows > perPage"
            v-model="currentPage"
            class="justify-content-center"
            :total-rows="totalRows"
            :per-page="perPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { mapActions } from 'pinia';
import apiService from '@/services/apiService';
import { dateStringToFormat } from '@/helper/dateHelper';

export default {
  data () {
    return {
      clientId: this.$route.params?.clientId,
      perPage: 15,
      currentPage: 1,
      totalRows: 0,
      rows: [],
      fields: [
        { key: 'date', label: 'Date' },
        { key: 'paymentCode', label: 'Payment Code' },
        { key: 'accountNumber', label: 'Recipient Account No' },
        { key: 'name', label: 'Recipient Name' },
        { key: 'routingNumber', label: 'Routing Number' },
        { key: 'errorCode', label: 'Error Code' },
        { key: 'errorMessage', label: 'Error Message' },
        { key: 'correctDetails', label: 'Correct Details' },
        { key: 'isActive', label: 'Is Active' },
        { key: 'isManual', label: 'Is Manual' }
      ]
    };
  },
  watch: {
    currentPage () {
      this.onPageChange(this.currentPage);
    }
  },
  mounted () {
    this.fetchData();
  },
  async created () {
    const { data: { displayName } } = await apiService.clients.get(this.clientId);
    this.setBreadcrumbs([
      {
        text: this.$t('label.anchor.clients'),
        href: '#/clients'
      },
      {
        text: displayName,
        href: '#/viewclient/' + this.clientId
      },
      {
        text: this.$t('label.anchor.notification-of-change'),
        href: '#'
      }
    ]);
  },
  methods: {
    ...mapActions(useBreadcrumbsStore, ['setBreadcrumbs']),
    async fetchData () {
      const response = await apiService.clients.getAllNOC(this.clientId, {
        state: ['HOLD', 'ERROR'],
        offset: ((this.currentPage - 1) * this.perPage),
        sortOrder: 'DESC',
        limit: this.perPage
      });
      this.rows = response.data.pageItems;
      this.totalRows = response.data.totalFilteredRecords;
    },
    dateStringToFormat,
    onPageChange (page) {
      this.currentPage = page;
      this.fetchData();
    }
  }

};
</script>
