import Vue from 'vue';

// "v-sortable" directive
Vue.directive('sortable', {
  inserted (el) {
    const sortable = el.querySelectorAll('.sortable');
    sortable.forEach(el => {
      el.addEventListener('click', () => {
        const currentClass = el.classList.contains('asc') ? 'desc' : 'asc';
        sortable.forEach(el => el.classList.remove('asc', 'desc'));
        el.classList.add(currentClass);
      });
    });
  }
});
