export function RecurringDepositProductController (scope, resourceFactory, location) {
  scope.routeTo = function (id) {
    location.path(`/viewrecurringdepositproduct/${id}`);
  };

  if (!scope.searchCriteria.rdp) {
    scope.searchCriteria.rdp = null;
    scope.saveSC();
  }
  scope.filterText = scope.searchCriteria.rdp || '';

  scope.onFilter = function () {
    scope.searchCriteria.rdp = scope.filterText;
    scope.saveSC();
  };

  scope.RecurringDepositsPerPage = 15;
  resourceFactory.recurringDepositProductResource.getAllRecurringDepositProducts(function (data) {
    scope.depositproducts = data;
  });
}

RecurringDepositProductController.$inject = ['$scope', 'ResourceFactory', '$location'];
