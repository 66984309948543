export function AccountingClosureController (scope, resourceFactory, location, translate, routeParams, dateFilter) {
  scope.first = {};
  scope.formData = {};
  scope.first.date = new Date();
  scope.accountClosures = [];
  scope.restrictDate = new Date();
  resourceFactory.officeResource.getAllOffices(function (data) {
    scope.offices = data;
  });

  const params = {};
  if (routeParams.officeId !== undefined) {
    params.officeId = routeParams.officeId;
  }

  resourceFactory.accountingClosureResource.get(params, function (data) {
    scope.accountClosures = data;
  });

  scope.routeTo = function (id) {
    location.path(`/view_close_accounting/${id}`);
  };

  scope.submit = function () {
    const reqDate = dateFilter(scope.first.date, scope.df);
    this.formData.locale = scope.optlang.code;
    this.formData.dateFormat = scope.df;
    this.formData.closingDate = reqDate;
    resourceFactory.accountingClosureResource.save(this.formData, function (data) {
      location.path(`/view_close_accounting/${data.resourceId}`);
    });
  };

  scope.updateLastClosed = function (officeId) {
    resourceFactory.accountingClosureResource.get({ officeId }, function (data) {
      scope.accountClosures = data;
      scope.lastClosed = undefined;
      if (data.length > 0) {
        scope.lastClosed = data[0].closingDate;
      }
    });
  };
  scope.closedAccountingDetails = function (officeId) {
    resourceFactory.accountingClosureResource.get({ officeId }, function (data) {
      scope.accountClosures = data;
    });
  };
}

AccountingClosureController.$inject = ['$scope', 'ResourceFactory', '$location', '$translate', '$routeParams', 'dateFilter'];
