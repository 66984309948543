export function CreateFieldConfigurationController (scope, resourceFactory, location) {
  const clearInputFieldValidation = () => ({
    field: '',
    validationRegex: '',
    arrayMinSize: null,
    arrayMaxSize: null,
    errorMsg: '',
    isMandatory: false
  });
  scope.formData = {};
  scope.formData.isEnabled = true;
  scope.fieldValidations = [];
  scope.inputFieldValidation = clearInputFieldValidation();

  scope.addFieldValidation = function () {
    scope.isRequiredField = !scope.inputFieldValidation.field;
    scope.inputFieldValidation.field && scope.fieldValidations.push({ ...scope.inputFieldValidation });
    scope.inputFieldValidation = clearInputFieldValidation();
  };
  scope.deleteFieldValidation = function (index) {
    scope.fieldValidations.splice(index, 1);
  };
  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
  scope.submit = function () {
    scope.formData.fieldValidations = scope.fieldValidations;
    resourceFactory.fieldconfigurationResource.save({ ...scope.formData }, function (data) {
      location.path(`/viewfieldconfiguration/${data.resourceId}`);
    }, ({ data: { errors } }) => {
      scope.errorMsg = errors[0].defaultUserMessage;
    });
  };
}

CreateFieldConfigurationController.$inject = ['$scope', 'ResourceFactory', '$location'];
