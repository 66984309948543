export function EditExternalWireTransferController ($scope, resourceFactory, location, routeParams) {
  let stateProvinceIdOptions = [];
  $scope.transferDetails = {};
  $scope.formData = {};
  $scope.formData.creditor = {};
  $scope.formData.debtor = {};
  $scope.formData.paymentRailMetaData = {};
  $scope.isAnyInputAddressThreeBeneficiaryFiChange = false;
  $scope.isAnyInputAddressThreeIntermediaryFiChange = false;
  $scope.paymentRailMetaData = {};
  $scope.textAreaLineParamNames = ['addressLineOne', 'addressLineTwo', 'addressLineThree', 'addressLineFour', 'addressLineFive', 'addressLineSix'];
  $scope.lineParamNames = ['lineOne', 'lineTwo', 'lineThree', 'lineFour', 'lineFive', 'lineSix'];
  $scope.isIntermediaryFiFieldsRequired = false;
  $scope.isIntermediaryFiIdentifierAndBeneficiaryFiRequired = false;
  $scope.formErrors = {};
  $scope.countryOptions = [];
  $scope.beneficiaryStateOptions = [];
  $scope.beneficiaryFiStateOptions = [];
  $scope.intermediaryFiStateOptions = [];
  $scope.zipMaxLen = 5;
  $scope.transferId = routeParams.transferId;

  resourceFactory.clientaddressFields.get(function (clientaddress) {
    $scope.addressTypes = clientaddress.addressTypeIdOptions;
    $scope.countryOptions = sortedCountry(clientaddress.countryIdOptions);
    stateProvinceIdOptions = clientaddress.stateProvinceIdOptions;
  });

  $scope.textSplitMethod = function (paramNames, firstLineNumberOfChar, otherLinesNumberOfChar, value, totalNumberOfLine) {
    let stringLength = value.length;
    const data = {};
    let startIndex = 0;
    let endIndex = firstLineNumberOfChar;
    for (let i = 0; i < totalNumberOfLine && stringLength > 0; i++) {
      const lineLength = (i === 0) ? firstLineNumberOfChar : otherLinesNumberOfChar;
      if (stringLength <= lineLength) {
        data[paramNames[i]] = value.substring(startIndex, value.length);
        stringLength = 0;
      } else {
        data[paramNames[i]] = value.substring(startIndex, endIndex);
        stringLength -= lineLength;
        startIndex = endIndex;
        endIndex = otherLinesNumberOfChar + endIndex;
      }
    }
    return data;
  };

  $scope.textSplitAsArray = function (firstLineNumberOfChar, otherLinesNumberOfChar, value, totalNumberOfLine) {
    const data = [];
    if (value !== undefined && value.length > 0) {
      value = value.replace(/(\r\n|\n|\r)/gm, ' ');
      let length = value.length;
      let startIndex = 0;
      let endIndex = firstLineNumberOfChar;
      for (let i = 0; i < totalNumberOfLine && length > 0; i++) {
        const lineLength = (i === 0) ? firstLineNumberOfChar : otherLinesNumberOfChar;
        if (length <= lineLength) {
          data.push(value.substring(startIndex, value.length));
          length = 0;
        } else {
          data.push(value.substring(startIndex, endIndex));
          length -= lineLength;
          startIndex = endIndex;
          endIndex = otherLinesNumberOfChar + endIndex;
        }
      }
    }
    return data;
  };

  resourceFactory.clientAddress.query({ clientId: routeParams.clientId }, function (data) {
    $scope.originatorAddress = data;
    const primaryAddress = $scope.originatorAddress.find(a => a.addressType === 'PRIMARY');
    if (primaryAddress.minifiedAddress) {
      $scope.formData.originatorAddressLineOne = primaryAddress.minifiedAddress[0];
      $scope.formData.originatorAddressLineTwo = primaryAddress.minifiedAddress[1];
      $scope.formData.originatorAddressLineThree = primaryAddress.minifiedAddress[2];
    }
  });

  function getWireData () {
    // Check if both identifier and name are not present remove it from sending to CORE
    // We do this because for domesitc wire identifer and name are not mandatory
    // If we were to send an `agent` object with undefined identifier and name CORE will return an error

    const agent = !$scope.formData.beneficiaryFiIdentifier && !$scope.formData.beneficiaryFiName
      ? undefined
      : {
          identifier: $scope.formData.beneficiaryFiIdentifier,
          name: $scope.formData.beneficiaryFiName
        };

    const data = {
      type: 'CREDIT',
      dateFormat: $scope.df,
      paymentType: $scope.formData.paymentType,
      amount: $scope.formData.amount,
      reference: [],
      debtor: {
        identifier: `ACCOUNT:${$scope.formData.originatorIdentifier}`,
        name: $scope.formData.originatorName
      },
      creditor: {
        name: $scope.formData.beneficiaryName,
        agent,
        address: [] // Populate address details later
      },
      paymentRailMetaData: {
        businessFunctionCode: {
          businessFunctionCode: $scope.formData.businessFunctionCode,
          transactionTypeCode: $scope.formData.transactionType
        },
        receiverDepositoryInstitution: {
          receiverABANumber: $scope.formData.receiverAbaNum,
          receiverShortName: $scope.formData.receiverName
        },
        originator: {
          personal: {
            identificationCode: $scope.formData.originatorIdCode
          }
        },
        beneficiary: {
          personal: {
            identificationCode: $scope.formData.beneficiaryIdCode
          }
        },
        beneficiaryFI: {
          financialInstitution: {
            identificationCode: $scope.formData.beneficiaryFiIdCode
          }
        },
        beneficiaryIntermediaryFI: {
          financialInstitution: {}
        }
      }
    };

    if ($scope.formData.beneficiaryFiIdentifier) {
      data.creditor.identifier = `${$scope.formData.paymentType}://${$scope.formData.beneficiaryFiIdentifier}/${$scope.formData.beneficiaryIdentifier}`;
    } else {
      data.creditor.identifier = `${$scope.formData.paymentType}://${$scope.formData.receiverAbaNum}/${$scope.formData.beneficiaryIdentifier}`;
    }

    if ($scope.formData.OriginatorToBeneficiaryReference) {
      data.reference = $scope.textSplitAsArray(35, 35, $scope.formData.OriginatorToBeneficiaryReference, 4);
    }
    if ($scope.formData.fiToFiInformationReference) {
      data.paymentRailMetaData.fiAdditionalFiToFi = {};
      data.paymentRailMetaData.fiAdditionalFiToFi.additionalFiToFi = {};
      data.paymentRailMetaData.fiAdditionalFiToFi.additionalFiToFi = $scope.textSplitMethod($scope.lineParamNames, 35, 35, $scope.formData.fiToFiInformationReference, 6);
    }
    if ($scope.formData.beneficiaryAddressLineOne) {
      data.creditor.address.push($scope.formData.beneficiaryAddressLineOne);
    }
    if ($scope.formData.beneficiaryAddressLineTwo) {
      data.creditor.address.push($scope.formData.beneficiaryAddressLineTwo);
    }
    const beneficiaryAddressLineThree = constructAddressLine($scope.formData.beneficiaryCity, $scope.formData.beneficiaryCountry, $scope.formData.beneficiaryState, $scope.formData.beneficiaryPostalCode);
    data.creditor.address.push(beneficiaryAddressLineThree);
    if ($scope.formData.beneficiaryCountry) {
      data.creditor.country = $scope.countryOptions.find(country => country.id === $scope.formData.beneficiaryCountry).identifier;
    }
    if (typeof $scope.formData.beneficiaryState === 'number') {
      data.creditor.stateOrProvince = stateProvinceIdOptions.find(state => state.id === $scope.formData.beneficiaryState)?.identifier;
    } else {
      data.creditor.stateOrProvince = $scope.formData.beneficiaryState?.identifier;
    }
    data.creditor.city = $scope.formData.beneficiaryCity;
    data.creditor.postalCode = $scope.formData.beneficiaryPostalCode;
    if ($scope.formData.beneficiaryFiAddressLineOne) {
      data.creditor.agent.address = [];
      data.creditor.agent.address[0] = $scope.formData.beneficiaryFiAddressLineOne;
      if ($scope.formData.beneficiaryFiAddressLineTwo) {
        data.creditor.agent.address[1] = $scope.formData.beneficiaryFiAddressLineTwo;
      }
      const beneficiaryFiAddressLineThree = constructAddressLine($scope.formData.beneficiaryFiCity, $scope.formData.beneficiaryFiCountry, $scope.formData.beneficiaryFiState, $scope.formData.beneficiaryFiPostalCode);
      data.creditor.agent.address[2] = beneficiaryFiAddressLineThree;
      if ($scope.formData.beneficiaryFiCountry) {
        data.creditor.agent.country = $scope.countryOptions.find(country => country.id === $scope.formData.beneficiaryFiCountry).identifier;
      }
    }

    if ($scope.formData.wireType) {
      const typeSubType = $scope.formData.wireType.match(/.{1,2}/g);
      data.paymentRailMetaData.typeSubType = {
        typeCode: typeSubType[0],
        subTypeCode: typeSubType[1]
      };
    }

    if ($scope.formData.instructedAmountCurrencyCode && $scope.formData.instructedAmount) {
      data.paymentRailMetaData.instructedAmount = {
        currencyCode: $scope.formData.instructedAmountCurrencyCode,
        amount: $scope.formData.instructedAmount
      };
    }

    if ($scope.formData.exchangeRate) {
      data.paymentRailMetaData.exchangeRate = { exchangeRate: $scope.formData.exchangeRate };
    }

    if ($scope.formData.beneficiaryReference) {
      data.paymentRailMetaData.beneficiaryReference = { beneficiaryReference: $scope.formData.beneficiaryReference };
    }

    if ($scope.formData.senderReference) {
      data.paymentRailMetaData.senderReference = { senderReference: $scope.formData.senderReference };
    }

    if ($scope.formData.beneficiaryInformationReference) {
      data.creditor.forFurtherCredit = $scope.formData.beneficiaryInformationReference.replace(/(\r\n|\n|\r)/gm, ' ');
    }

    data.paymentRailMetaData.fiPaymentMethodToBeneficiary = {
      paymentMethod: $scope.formData.paymentMethod
    };

    if ($scope.formData.paymentMethodAdditionalInfomation) {
      data.paymentRailMetaData.fiPaymentMethodToBeneficiary.AdditionalInformation = $scope.formData.paymentMethodAdditionalInfomation;
    }

    if ($scope.formData.intermediaryFiCode) {
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.identificationCode = $scope.formData.intermediaryFiCode;
    }
    if ($scope.formData.intermediaryFiIdentifier) {
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.identifier = $scope.formData.intermediaryFiIdentifier;
    }
    if ($scope.formData.intermediaryFiIdentifier) {
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.name = $scope.formData.intermediaryFiName;
    }
    if ($scope.formData.intermediaryFiAddressLineOne) {
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.address = {};
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.address.addressLineOne = $scope.formData.intermediaryFiAddressLineOne;
    }
    if ($scope.formData.intermediaryFiAddressLineTwo) {
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.address.addressLineTwo = $scope.formData.intermediaryFiAddressLineTwo;
    }
    const intermediaryFiAddressLineThree = constructAddressLine($scope.formData.intermediaryFiCity, $scope.formData.intermediaryFiCountry, $scope.formData.intermediaryFiState, $scope.formData.intermediaryFiPostalCode);
    if (intermediaryFiAddressLineThree && data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.address?.addressLineThree) {
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.address.addressLineThree = intermediaryFiAddressLineThree;
    }
    return data;
  }

  const handleCountryDropDown = function (paymentType) {
    $scope.formData.beneficiaryCountry = ``; // re-set
    $scope.formData.beneficiaryFiCountry = ``;
    $scope.formData.intermediaryFiCountry = ``;
    if (paymentType && ['WIRE'].includes(paymentType)) {
      $scope.countries = [$scope.countryOptions.find(country => country.name === `United States`)];
      $scope.zipMaxLen = 5;
    } else {
      $scope.countries = [...$scope.countryOptions];
      $scope.zipMaxLen = 10;
    }
  };
  $scope.$watch('formData.paymentType', function (paymentType) {
    handleCountryDropDown(paymentType);
  });

  $scope.$watchGroup([
    'formData.beneficiaryFiCity',
    'formData.beneficiaryFiCountry',
    'formData.beneficiaryFiState',
    'formData.beneficiaryFiPostalCode'
  ], function (values) {
    $scope.isAnyInputAddressThreeBeneficiaryFiChange = values.some(value => !!value);
  });

  $scope.$watchGroup([
    'formData.intermediaryFiCity',
    'formData.intermediaryFiCountry',
    'formData.intermediaryFiState',
    'formData.intermediaryFiPostalCode'
  ], function (values) {
    $scope.isAnyInputAddressThreeIntermediaryFiChange = values.some(value => !!value);
  });

  $scope.$watch('formData.beneficiaryCountry', function (countryId) {
    if (countryId) { $scope.beneficiaryStateOptions = sortedStateByCountry(countryId); }
  });

  $scope.$watch('formData.beneficiaryFiCountry', function (countryId) {
    if (countryId) { $scope.beneficiaryFiStateOptions = sortedStateByCountry(countryId); }
  });

  $scope.$watch('formData.intermediaryFiCountry', function (countryId) {
    if (countryId) { $scope.intermediaryFiStateOptions = sortedStateByCountry(countryId); }
  });

  function constructAddressLine (city, countryCodeId, stateCodeId, postalCode) {
    let addressLine = ``;
    if (city) {
      addressLine += ` ${city}`;
    }
    if (typeof stateCodeId === 'number') {
      const selectedState = stateProvinceIdOptions.find(state => state.id === stateCodeId);
      addressLine += ` ${selectedState?.identifier}`;
    } else {
      addressLine += ` ${stateCodeId?.identifier}`;
    }
    if (postalCode) {
      addressLine += ` ${postalCode}`;
    }
    if (countryCodeId) {
      const selectedCountry = $scope.countryOptions.find(country => country.id === countryCodeId);
      addressLine += ` ${selectedCountry.identifier}`;
    }
    return addressLine.trim();
  }

  function sortedCountry (_countries) {
    return _countries.sort(function (a, b) {
      return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
    });
  }

  function sortedStateByCountry (countryId) {
    const states = stateProvinceIdOptions.filter(function (_option) {
      return _option.parentId === countryId;
    });

    return states.sort(function (a, b) {
      return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
    });
  }

  $scope.businessFuncCodes = [
    { code: 'CTR', label: 'CTR - Customer Transfer(Beneficiary is not bank)' }
    // { code :'BTR', label: 'BTR - Bank Transfer(Beneficiary is a bank)' }, // might require in future . Don't delete immediately
  ];

  $scope.wireTypes = [
    { typeSubTypeCode: '1000', typeSubTypeLabel: '1000 - Fund Transfer/ Basic Fund Transfer' }
    // { typeSubTypeCode : '1001', typeSubTypeLabel : '1001 - Fund Transfer/ Request for Reversal' }, // might require in future . Don't delete immediately
  ];

  $scope.currencyCodes = [
    { code: 'USD', label: 'USD' },
    { code: 'GBP', label: 'GBP' },
    { code: 'INR', label: 'INR' }
  ];

  $scope.IDCodes5000and4200 = [
    { code: 'B', label: 'B - SWIFT Bank Identifier Code(BIC)' },
    { code: 'C', label: 'C - CHIPS Participant' },
    { code: 'D', label: 'D - Demand Deposit Account(DDA) Number ' },
    { code: 'F', label: 'F - Fed Routing Number' },
    { code: 'T', label: 'T - SWIFT BIC or Bank Entity Identifier(BEI) and Account Number' },
    { code: 'U', label: 'U - CHIPS Identifier' },
    { code: '1', label: '1 - Passport Number' },
    { code: '2', label: '2 - Tax Identification Number' },
    { code: '3', label: '3 - Driver\'s License Number' },
    { code: '4', label: '4 - Alien Registration Number' },
    { code: '5', label: '5 - Corporate Identification' },
    { code: '9', label: '9 - Other Identification' }
  ];

  $scope.IDCodes5100and5200 = [
    { code: 'B', label: 'B - SWIFT Bank Identifier Code(BIC)' },
    { code: 'C', label: 'C - CHIPS Participant' },
    { code: 'D', label: 'D - Demand Deposit Account(DDA) Number ' },
    { code: 'F', label: 'F - Fed Routing Number' },
    { code: 'U', label: 'U - CHIPS Identifier' }
  ];

  $scope.intermediaryFiCodes = [
    { code: 'B', label: 'B - SWIFT Bank Identifier Code(BIC)' },
    { code: 'C', label: 'C - CHIPS Participant' },
    { code: 'D', label: 'D - Demand Deposit Account(DDA) Number ' },
    { code: 'F', label: 'F - Fed Routing Number' },
    { code: 'U', label: 'U - CHIPS Identifier' }
  ];

  $scope.detailsOfCharges = [
    { code: 'B', label: 'B - Beneficiary' },
    { code: 'S', label: 'S - Shared' }
  ];

  $scope.adviceCodes = [
    { code: 'LTR', label: 'LTR - Letter' },
    { code: 'PHN', label: 'PHN - Phone' },
    { code: 'TLX', label: 'TLX - Telex' },
    { code: 'WRE', label: 'WRE - Wire' },
    { code: 'HLD', label: 'HLD - Hold' }
  ];

  $scope.methodOfPayments = [
    { code: 'CHECK', label: 'CHECK' }
  ];

  // Custom names for payment types
  $scope.paymentTypeLabels = { WIRE: 'Domestic Wire', SWIFT: 'International Wire [SWIFT]' };

  $scope.onIntermediaryFiCodeSelect = function () {
    if ($scope.formData.intermediaryFiCode) {
      $scope.isIntermediaryFiFieldsRequired = true;
    } else {
      $scope.isIntermediaryFiFieldsRequired = false;
    }
  };
  $scope.onEnteringIntermediaryFiIdentifier = function () {
    if ($scope.formData.intermediaryFiIdentifier) {
      $scope.isIntermediaryFiIdentifierAndBeneficiaryFiRequired = true;
    } else {
      $scope.isIntermediaryFiIdentifierAndBeneficiaryFiRequired = false;
    }
  };

  $scope.onBeneficiaryFiCodeSelect = function () {
    $scope.isBeneficiaryFiIdentifierRequired = !!$scope.formData.beneficiaryFiIdCode;
  };

  $scope.onEnteringBeneficiaryFiIdentifier = function () {
    $scope.isBeneficiaryFiIdCodeRequired = !!$scope.formData.beneficiaryFiIdentifier;
  };

  const findCountryByIdentifier = function (identifier) {
    return $scope.countryOptions.find(country => country.identifier === identifier);
  };

  const findStatOrPrivinceByIdentifier = function (identifier, countryId) {
    return stateProvinceIdOptions.find(stateProvinceIdOption => stateProvinceIdOption.identifier === identifier && stateProvinceIdOption.parentId === countryId);
  };

  const splitAddressThree = function (text) {
    const addresses = ['country', 'postalCode', 'stateOrProvince', 'city'];
    const limitSpace = 3;
    const data = {};

    addresses.forEach((addresse, key) => {
      let lastSpace;
      if (key < limitSpace) lastSpace = text?.lastIndexOf(' ');
      data[addresses[key]] = text?.substring(lastSpace + 1);
      text = text?.substring(0, lastSpace);
    });
    return data;
  };

  $scope.textMergeMethod = function (paramNames, value) {
    let data = '';
    if (value !== undefined) {
      for (let i = 0; i < paramNames.length; i++) {
        if (value[paramNames[i]] !== undefined) {
          data = `${data + value[paramNames[i]]} `;
        } else {
          return data;
        }
      }
    }
    return data;
  };
  $scope.isDraft = false;
  resourceFactory.transferResource.get({ id: routeParams.transferId }, function (data) {
    $scope.transferDetails = data;

    resourceFactory.clientaddressFields.get(function () {
      const beneficiaryCountryId = findCountryByIdentifier(data.creditor.country)?.id;
      handleCountryDropDown($scope.transferDetails.paymentType, beneficiaryCountryId);
      $scope.formData.beneficiaryCity = data.creditor.city;
      $scope.formData.beneficiaryPostalCode = data.creditor.postalCode;
      $scope.formData.beneficiaryCountry = beneficiaryCountryId;
      $scope.formData.beneficiaryState = findStatOrPrivinceByIdentifier(data.creditor.stateOrProvince, beneficiaryCountryId);

      const beneficiaryFiAddressLineThree = splitAddressThree(data.creditor.agent?.address?.[2]);
      const beneficiaryFiCountryID = findCountryByIdentifier(beneficiaryFiAddressLineThree.country)?.id;
      $scope.formData.beneficiaryFiCity = beneficiaryFiAddressLineThree.city;
      $scope.formData.beneficiaryFiPostalCode = beneficiaryFiAddressLineThree.postalCode;
      $scope.formData.beneficiaryFiCountry = beneficiaryFiCountryID;
      $scope.formData.beneficiaryFiState = findStatOrPrivinceByIdentifier(beneficiaryFiAddressLineThree.stateOrProvince, beneficiaryFiCountryID);

      const beneficiaryIntermediaryFiAddressThree = data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution?.address?.addressLineThree;
      const beneficiaryIntermediaryFiAddressLineThree = splitAddressThree(beneficiaryIntermediaryFiAddressThree);
      const intermediaryFiCountryId = findCountryByIdentifier(beneficiaryIntermediaryFiAddressLineThree.country)?.id;
      $scope.formData.intermediaryFiCity = beneficiaryIntermediaryFiAddressLineThree.city;
      $scope.formData.intermediaryFiPostalCode = beneficiaryIntermediaryFiAddressLineThree.postalCode;
      $scope.formData.intermediaryFiCountry = intermediaryFiCountryId;
      $scope.formData.intermediaryFiState = findStatOrPrivinceByIdentifier(beneficiaryIntermediaryFiAddressLineThree.stateOrProvince, intermediaryFiCountryId);
    });

    $scope.isIncomingPostedFromBO = (data.inOrOut === 'IN' && !data.rawPaymentDetails);

    if (data.status === 'DRAFT') {
      $scope.isDraft = true;
      $scope.paymentRailMetaData = data.paymentRailMetaData;
    } else {
      $scope.paymentRailMetaData = (data.inOrOut === `IN`) ? data.paymentRailMetaData : data?.rawPaymentDetails?.fedWireMessage;
    }
    $scope.formData.paymentType = $scope.transferDetails.paymentType;
    $scope.getBasicDetails($scope.paymentRailMetaData);
    $scope.getOriginatorDetails();
    $scope.getBeneficiaryDetails();
    if ($scope.paymentRailMetaData.charges) {
      $scope.getCharges3700TagDetails($scope.paymentRailMetaData.charges);
    }
    $scope.getFinancialInstitutionToFinancialInstitutionInformation($scope.paymentRailMetaData);
    $scope.getPaymentNotification3620TagDetails($scope.paymentRailMetaData.paymentNotification);
  });

  $scope.getBasicDetails = function (paymentRailMetaData) {
    $scope.formData.amount = $scope.transferDetails.amount;
    if (paymentRailMetaData) {
      $scope.getBusinessCode3600TagDetails(paymentRailMetaData.businessFunctionCode);
      $scope.getWireType1510TagDetails(paymentRailMetaData.typeSubType);
      $scope.getReceiverDepositoryInformation3400TagDetails(paymentRailMetaData.receiverDepositoryInstitution);
      // 3320 tag details
      if (paymentRailMetaData.senderReference && paymentRailMetaData.senderReference.senderReference) {
        $scope.formData.senderReference = paymentRailMetaData.senderReference.senderReference;
      }
      $scope.getInstructedAmount3710TagDetails(paymentRailMetaData.instructedAmount);
      // 3720 tag details
      $scope.formData.exchangeRate = !$scope.isIncomingPostedFromBO ? paymentRailMetaData.exchangeRate?.exchangeRate?.replace(',', '.') : paymentRailMetaData?.exchangeRate?.exchangeRate;
      $scope.getSenderDepositoryInstitution3100TagDetails(paymentRailMetaData.senderDepositoryInstitution);
      $scope.getImadAndOmadDetails(paymentRailMetaData);
    }
  };

  $scope.getSenderDepositoryInstitution3100TagDetails = function (senderDepositoryInstitution) {
    if (senderDepositoryInstitution) {
      $scope.formData.senderAbaAndName = '';
      if (senderDepositoryInstitution.senderABANumber && senderDepositoryInstitution.senderABANumber.length > 0) {
        $scope.formData.senderAbaAndName = senderDepositoryInstitution.senderABANumber;
      }
      if (senderDepositoryInstitution.senderShortName && senderDepositoryInstitution.senderShortName.length > 0) {
        $scope.formData.senderAbaAndName = `${$scope.formData.senderAbaAndName}(${senderDepositoryInstitution.senderShortName})`;
      }
    }
  };

  $scope.getPaymentNotification3620TagDetails = function (paymentNotification) {
    if (paymentNotification) {
      if (paymentNotification.paymentNotificationIndicator) {
        $scope.isPaymentNotification = true;
        $scope.formData.paymentNotificationIndicator = paymentNotification.paymentNotificationIndicator;
      }
      if (paymentNotification.contactNotificationElectronicAddress) {
        $scope.formData.electronicAddress = paymentNotification.contactNotificationElectronicAddress;
      }
      if (paymentNotification.contactName) {
        $scope.formData.contactName = paymentNotification.contactName;
      }
      if (paymentNotification.contactPhoneNumber) {
        $scope.formData.contactPhoneNumber = paymentNotification.contactPhoneNumber;
      }
      if (paymentNotification.contactMobileNumber) {
        $scope.formData.contactMobileNumber = paymentNotification.contactMobileNumber;
      }
      if (paymentNotification.faxNumber) {
        $scope.formData.faxNumber = paymentNotification.faxNumber;
      }
      if (paymentNotification.endToEndIdentification) {
        $scope.formData.endToEndIdentification = paymentNotification.endToEndIdentification;
      }
    } else {
      $scope.isPaymentNotification = false;
    }
  };

  $scope.getBusinessCode3600TagDetails = function (businessFunctionCode) {
    if (businessFunctionCode) {
      // 3600 tag
      if (businessFunctionCode.businessFunctionCode) {
        $scope.formData.businessFunctionCode = businessFunctionCode.businessFunctionCode;
      }
      // 3600 tag
      if (businessFunctionCode.transactionTypeCode) {
        $scope.formData.transactionType = businessFunctionCode.transactionTypeCode;
      }
    }
  };

  $scope.getWireType1510TagDetails = function (typeSubType) {
    if (typeSubType) {
      if (typeSubType.typeCode && typeSubType.subTypeCode) {
        const typeCode = typeSubType.typeCode;
        const subTypeCode = typeSubType.subTypeCode;
        $scope.formData.wireType = typeCode + subTypeCode;
      }
    }
  };

  $scope.getReceiverDepositoryInformation3400TagDetails = function (receiverDepositoryInstitution) {
    if (receiverDepositoryInstitution) {
      if (receiverDepositoryInstitution.receiverABANumber) {
        $scope.formData.receiverAbaNum = receiverDepositoryInstitution.receiverABANumber;
      }
      if (receiverDepositoryInstitution.receiverShortName) {
        $scope.formData.receiverName = receiverDepositoryInstitution.receiverShortName;
      }
    }
  };

  $scope.getInstructedAmount3710TagDetails = function (instructedAmount) {
    if (instructedAmount) {
      if (instructedAmount.currencyCode) {
        $scope.formData.instructedAmountCurrencyCode = instructedAmount.currencyCode;
      }
      if (instructedAmount.amount) {
        $scope.formData.instructedAmount = instructedAmount.amount;
      }
    }
  };

  $scope.getFinancialInstitutionToFinancialInstitutionInformation = function (paymentRailMetaData) {
    if (paymentRailMetaData) {
      if (paymentRailMetaData.fiIntermediaryFI) {
        $scope.intermediaryFIInformation6200TagData(paymentRailMetaData.fiIntermediaryFI.fiToFI);
      }
      if (paymentRailMetaData.fiIntermediaryFIAdvice) {
        $scope.intermediaryFIAdvice6210TagData(paymentRailMetaData.fiIntermediaryFIAdvice);
      }
      if (paymentRailMetaData.fiBeneficiaryFI) {
        $scope.beneficiaryFIInformation6300TagData(paymentRailMetaData.fiBeneficiaryFI.fiToFI);
      }
      if (paymentRailMetaData.fiIntermediaryFIAdvice) {
        $scope.beneficiaryFIAdvice6310TagData(paymentRailMetaData.fiBeneficiaryFIAdvice);
      }
      if (paymentRailMetaData.fiBeneficiaryAdvice) {
        $scope.beneficiaryAdvice6410TagData(paymentRailMetaData.fiBeneficiaryAdvice);
      }
      if (paymentRailMetaData.fiReceiverFI) {
        $scope.receiverFIInformation6100TagData(paymentRailMetaData.fiReceiverFI.fiToFI);
      }
      if (paymentRailMetaData.fiPaymentMethodToBeneficiary) {
        $scope.methodOfPaymentToBeneficiary6420TagData(paymentRailMetaData.fiPaymentMethodToBeneficiary);
      }
    }
    // 6400 tag
    if ($scope.transferDetails.creditor.forFurtherCredit) {
      $scope.formData.beneficiaryInformationReference = $scope.transferDetails.creditor.forFurtherCredit;
    }
    if ($scope.transferDetails.debtor.forFurtherCredit) {
      $scope.formData.beneficiaryInformationReference = $scope.transferDetails.debtor.forFurtherCredit;
    }
  };

  $scope.intermediaryFIInformation6200TagData = function (fiToFI) {
    if (fiToFI) {
      $scope.formData.intermediaryFiInformationReference = $scope.textMergeMethod($scope.lineParamNames, fiToFI);
    }
  };

  $scope.methodOfPaymentToBeneficiary6420TagData = function (fiPaymentMethodToBeneficiary) {
    if (fiPaymentMethodToBeneficiary) {
      if (fiPaymentMethodToBeneficiary.paymentMethod && fiPaymentMethodToBeneficiary.paymentMethod.toUpperCase() === 'CHECK') { $scope.formData.paymentMethod = $scope.methodOfPayments[0].label; }

      $scope.formData.paymentMethodAdditionalInfomation = fiPaymentMethodToBeneficiary.Additional;
    }
  };

  $scope.receiverFIInformation6100TagData = function (fiToFI) {
    if (fiToFI) {
      $scope.formData.receiverFiInformation = $scope.textMergeMethod($scope.lineParamNames, fiToFI);
    }
  };

  $scope.intermediaryFIAdvice6210TagData = function (fiIntermediaryFIAdvice) {
    if (fiIntermediaryFIAdvice.advice) {
      if (fiIntermediaryFIAdvice.advice.adviceCode) {
        $scope.formData.intermediaryFiAdviceCode = fiIntermediaryFIAdvice.advice.adviceCode;
      }
      $scope.formData.intermediaryFiAdviceReference = $scope.textMergeMethod($scope.lineParamNames, fiIntermediaryFIAdvice.advice);
    }
  };

  $scope.beneficiaryFIInformation6300TagData = function (fiToFI) {
    if (fiToFI) {
      $scope.formData.beneficiaryFiInformationReference = $scope.textMergeMethod($scope.lineParamNames, fiToFI);
    }
  };

  $scope.beneficiaryFIAdvice6310TagData = function (fiBeneficiaryFIAdvice) {
    if (fiBeneficiaryFIAdvice.advice) {
      if (fiBeneficiaryFIAdvice.advice.adviceCode) {
        $scope.formData.beneficiaryFiAdviceCode = fiBeneficiaryFIAdvice.advice.adviceCode;
      }
      $scope.formData.beneficiaryFIAdvice = $scope.textMergeMethod($scope.lineParamNames, fiBeneficiaryFIAdvice.advice);
    }
  };

  $scope.beneficiaryAdvice6410TagData = function (fiBeneficiaryAdvice) {
    if (fiBeneficiaryAdvice.advice) {
      if (fiBeneficiaryAdvice.advice.adviceCode) {
        $scope.formData.beneficiaryAdviceCode = fiBeneficiaryAdvice.advice.adviceCode;
      }
      $scope.formData.beneficiaryAdviceCodeReference = $scope.textMergeMethod($scope.lineParamNames, fiBeneficiaryAdvice.advice);
    }
  };

  $scope.getCharges3700TagDetails = function (charges) {
    $scope.formData.detailsOfCharges = charges.chargeDetails;

    if (charges.sendersChargesOne) {
      $scope.formData.senderChargeOneCurrencyCode = charges.sendersChargesOne.substr(0, 3);
      $scope.formData.sendersChargeOneAmount = charges.sendersChargesOne.substr(3).replace(',', '.');
    }
    if (charges.sendersChargesTwo) {
      $scope.formData.senderChargeTwoCurrencyCode = charges.sendersChargesTwo.substr(0, 3);
      $scope.formData.sendersChargeTwoAmount = charges.sendersChargesTwo.substr(3).replace(',', '.');
    }
    if (charges.sendersChargesThree) {
      $scope.formData.senderChargeThreeCurrencyCode = charges.sendersChargesThree.substr(0, 3);
      $scope.formData.sendersChargeThreeAmount = charges.sendersChargesThree.substr(3).replace(',', '.');
    }
    if (charges.sendersChargesFour) {
      $scope.formData.senderChargeFourCurrencyCode = charges.sendersChargesFour.substr(0, 3);
      $scope.formData.sendersChargeFourAmount = charges.sendersChargesFour.substr(3).replace(',', '.');
    }
  };

  $scope.getBeneficiaryDetails = function () {
    $scope.beneficiary4200TagData($scope.transferDetails.creditor, $scope.transferDetails.rawPaymentDetails);
    $scope.beneficiaryFI4100TagData($scope.transferDetails.creditor, $scope.paymentRailMetaData.beneficiaryFI);

    if ($scope.paymentRailMetaData) {
      $scope.intermediaryFI4000TagData($scope.paymentRailMetaData.beneficiaryIntermediaryFI);
      $scope.fItoFIInformation6500TagData($scope.paymentRailMetaData.fiAdditionalFiToFi);
      if ($scope.paymentRailMetaData.beneficiaryReference && $scope.paymentRailMetaData.beneficiaryReference.beneficiaryReference) {
        $scope.formData.beneficiaryReference = $scope.paymentRailMetaData.beneficiaryReference.beneficiaryReference;
      }
    }
  };

  $scope.beneficiary4200TagData = function (creditor, rawPaymentDetails) {
    if ($scope.paymentRailMetaData.beneficiary && $scope.paymentRailMetaData.beneficiary.personal) {
      $scope.formData.beneficiaryIdCode = $scope.paymentRailMetaData.beneficiary.personal.identificationCode;
    }

    if ($scope.transferDetails.inOrOut === 'IN' && rawPaymentDetails) {
      const beneficiaryDetails = rawPaymentDetails.fedWireMessage.beneficiary.personal;
      const beneficiaryAddressDetails = beneficiaryDetails.address;
      $scope.formData = {
        ...$scope.formData,
        beneficiaryIdentifier: beneficiaryDetails.identifier,
        beneficiaryName: beneficiaryDetails.name,
        beneficiaryAddressLineOne: beneficiaryAddressDetails?.addressLineOne,
        beneficiaryAddressLineTwo: beneficiaryAddressDetails?.addressLineTwo,
        beneficiaryAddressLineThree: beneficiaryAddressDetails?.addressLineThree
      };
    } else {
      if (creditor.identifier) {
        const accountNumber = creditor.identifier.split('/').pop();
        $scope.formData.beneficiaryIdentifier = accountNumber;
      }
      if (creditor.name) {
        $scope.formData.beneficiaryName = creditor.name;
      }
      if (creditor.address) {
        if (creditor.address[0]) {
          $scope.formData.beneficiaryAddressLineOne = creditor.address[0];
        }
        if ($scope.transferDetails.creditor.address[1]) {
          $scope.formData.beneficiaryAddressLineTwo = creditor.address[1];
        }
        if (creditor.address[2]) {
          $scope.formData.beneficiaryAddressLineThree = creditor.address[2];
        }
      }
    }
  };

  $scope.beneficiaryFI4100TagData = function (creditor, beneficiaryFI) {
    if (beneficiaryFI) {
      if (beneficiaryFI.financialInstitution) {
        $scope.formData.beneficiaryFiIdCode = beneficiaryFI.financialInstitution.identificationCode;
      }
    }
    if (creditor.agent) {
      if (creditor.agent.identifier) {
        $scope.formData.beneficiaryFiIdentifier = creditor.agent.identifier;
      }
      if (creditor.agent.name) {
        $scope.formData.beneficiaryFiName = creditor.agent.name;
      }

      if (creditor.agent.address) {
        if (creditor.agent.address[0]) {
          $scope.formData.beneficiaryFiAddressLineOne = creditor.agent.address[0];
        }
        if (creditor.agent.address[1]) {
          $scope.formData.beneficiaryFiAddressLineTwo = creditor.agent.address[1];
        }
        if (creditor.agent.address[2]) {
          $scope.formData.beneficiaryFiAddressLineThree = creditor.agent.address[2];
        }
      }
    }
  };

  $scope.intermediaryFI4000TagData = function (beneficiaryIntermediaryFI) {
    if (beneficiaryIntermediaryFI && beneficiaryIntermediaryFI.financialInstitution) {
      if (beneficiaryIntermediaryFI.financialInstitution.identificationCode) {
        $scope.formData.intermediaryFiCode = beneficiaryIntermediaryFI.financialInstitution.identificationCode;
      }
      if (beneficiaryIntermediaryFI.financialInstitution.identifier) {
        $scope.formData.intermediaryFiIdentifier = beneficiaryIntermediaryFI.financialInstitution.identifier;
      }
      if (beneficiaryIntermediaryFI.financialInstitution.name) {
        $scope.formData.intermediaryFiName = beneficiaryIntermediaryFI.financialInstitution.name;
      }
      if (beneficiaryIntermediaryFI.financialInstitution.address) {
        if (beneficiaryIntermediaryFI.financialInstitution.address.addressLineOne) {
          $scope.formData.intermediaryFiAddressLineOne = beneficiaryIntermediaryFI.financialInstitution.address.addressLineOne;
        }
        if (beneficiaryIntermediaryFI.financialInstitution.address.addressLineTwo) {
          $scope.formData.intermediaryFiAddressLineTwo = beneficiaryIntermediaryFI.financialInstitution.address.addressLineTwo;
        }
        if (beneficiaryIntermediaryFI.financialInstitution.address?.addressLineThree) {
          $scope.formData.intermediaryFiAddressLineThree = beneficiaryIntermediaryFI.financialInstitution.address?.addressLineThree;
        }
      }
    }
  };

  $scope.fItoFIInformation6500TagData = function (fiAdditionalFIToFI) {
    if (fiAdditionalFIToFI && fiAdditionalFIToFI.additionalFiToFi) {
      $scope.formData.fiToFiInformationReference = $scope.textMergeMethod($scope.lineParamNames, fiAdditionalFIToFI.additionalFiToFi);
    }
  };

  $scope.getOriginatorDetails = function () {
    $scope.originator5000TagData($scope.transferDetails.debtor, $scope.paymentRailMetaData);
    if ($scope.paymentRailMetaData) {
      if ($scope.paymentRailMetaData.originatorFI) {
        $scope.originatorFi5100TagData($scope.paymentRailMetaData.originatorFI);
      }
    }
    if ($scope.paymentRailMetaData) {
      if ($scope.paymentRailMetaData.instructingFI) {
        $scope.instructingFi5200TagData($scope.paymentRailMetaData.instructingFI);
      }
    }

    // tag 6000 data
    if ($scope.transferDetails.reference) {
      $scope.formData.OriginatorToBeneficiaryReference = $scope.transferDetails.reference.join('');
    }
  };

  $scope.originatorFi5100TagData = function (originatorFI) {
    if (originatorFI.financialInstitution) {
      if (originatorFI.financialInstitution.identificationCode) {
        $scope.formData.originatorFiIdCode = originatorFI.financialInstitution.identificationCode;
      }
      if (originatorFI.financialInstitution.identifier) {
        $scope.formData.originatorFiIdentifier = originatorFI.financialInstitution.identifier;
      }
      if (originatorFI.financialInstitution.name) {
        $scope.formData.originatorFiName = originatorFI.financialInstitution.name;
      }
      if (originatorFI.financialInstitution.address) {
        if (originatorFI.financialInstitution.address.addressLineOne) {
          $scope.formData.originatorFiAddressLineOne = originatorFI.financialInstitution.address.addressLineOne;
        }
        if (originatorFI.financialInstitution.address.addressLineTwo) {
          $scope.formData.originatorFiAddressLineTwo = originatorFI.financialInstitution.address.addressLineTwo;
        }
        if (originatorFI.financialInstitution.address?.addressLineThree) {
          $scope.formData.originatorFiAddressLineThree = originatorFI.financialInstitution.address?.addressLineThree;
        }
      }
    }
  };

  $scope.instructingFi5200TagData = function (instructingFI) {
    if (instructingFI.financialInstitution) {
      if (instructingFI.financialInstitution.identificationCode) {
        $scope.formData.originatorInstructingFiCode = instructingFI.financialInstitution.identificationCode;
      }
      if (instructingFI.financialInstitution.identifier) {
        $scope.formData.originatorInstructingFiIdentifier = instructingFI.financialInstitution.identifier;
      }
      if (instructingFI.financialInstitution.name) {
        $scope.formData.originatorInstructingFiName = instructingFI.financialInstitution.name;
      }
      if (instructingFI.financialInstitution.address) {
        if (instructingFI.financialInstitution.address.addressLineOne) {
          $scope.formData.originatorInstructingFiAddressLineOne = instructingFI.financialInstitution.address.addressLineOne;
        }
        if (instructingFI.financialInstitution.address.addressLineTwo) {
          $scope.formData.originatorInstructingFiAddressLineTwo = instructingFI.financialInstitution.address.addressLineTwo;
        }
        if (instructingFI.financialInstitution.address?.addressLineThree) {
          $scope.formData.originatorInstructingFiAddressLineThree = instructingFI.financialInstitution.address?.addressLineThree;
        }
      }
    }
  };

  $scope.originator5000TagData = function (debtor, paymentRailData) {
    if (debtor.address) {
      if (debtor.address[0]) {
        $scope.formData.originatorAddressLineOne = debtor.address[0];
      }
      if (debtor.address[1]) {
        $scope.formData.originatorAddressLineTwo = debtor.address[1];
      }
      if (debtor.address[2]) {
        $scope.formData.originatorAddressLineThree = debtor.address[2];
      }
    }

    if (paymentRailData.originator) {
      if (paymentRailData.originator.personal) {
        $scope.formData.originatorIdCode = paymentRailData.originator.personal.identificationCode;
      }
    }

    if (debtor.identifier) {
      if ($scope.transferDetails.inOrOut === 'OUT') {
        $scope.formData.originatorIdentifier = debtor.identifier.split(':').pop();
      } else {
        $scope.formData.originatorIdentifier = debtor.identifier.split('/').pop();
      }
    }
    if (debtor.name) {
      $scope.formData.originatorName = $scope.transferDetails.debtor.name;
    }
  };

  $scope.getImadAndOmadDetails = function (paymentRailMetaData) {
    if ($scope.isIncomingPostedFromBO) {
      $scope.formData.imad = paymentRailMetaData?.inputMessageAccountabilityData;
      $scope.formData.omad = paymentRailMetaData?.outputMessageAccountabilityData;
    }
  };

  resourceFactory.paymentTypeResource.getAll(function (dataPaymentTypes) {
    $scope.paymentTypes = dataPaymentTypes.filter(function (dataPaymentType) {
      return ['WIRE', 'SWIFT'].includes(dataPaymentType.name) && dataPaymentType.isSystemDefined;
    });
    // Check if we have custom name for the payment type or not.
    $scope.paymentTypes.forEach(paymentType => {
      paymentType.label = $scope.paymentTypeLabels[paymentType.name] || paymentType.name;
    }
    );
  });

  $scope.updateWireTransfer = function () {
    const submitData = getWireData();

    resourceFactory.transferResource.update({ id: routeParams.transferId }, submitData,
      () => {
        location.path(`/transfers/${routeParams.transferId}`);
      },
      errorData => formErrorHandler(errorData)
    );
  };

  const formErrorHandler = apiValidationErrors => {
    $scope.formErrors = {};
    const { data: { errors } } = apiValidationErrors;

    const isRoutingNumberError = displayMessage => {
      const routingNumberErrorDisplayMessage = 'invalid routing number';
      return displayMessage === routingNumberErrorDisplayMessage || $scope.formData.paymentType === 'SWIFT';
    };

    errors.forEach(
      ({ parameterName, developerMessage }) => {
        // CORE returns the same parameter for ( creditor.identifier ) for identifier and routing number
        // On core side both both errors are creditor.identifier error
        // Implement a check on developer message to differentiate them.

        if (parameterName === 'creditor.identifier') { parameterName = isRoutingNumberError(developerMessage) ? 'creditor.identifierRoutingNumber' : parameterName; }

        $scope.formErrors = { ...$scope.formErrors, [parameterName]: developerMessage };
      }
    );
  };

  $scope.closeAlert = function () {
    $scope.errorMsg = '';
  };

  $scope.cancel = function () {
    window.history.back();
  };
}

EditExternalWireTransferController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams'];
