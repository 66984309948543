import _ from 'underscore';

export function SchedulerJobsController (scope, resourceFactory, route, location, $uibModal) {
  let jobIdArray = [];
  scope.activeall = false;
  resourceFactory.jobsResource.get(function (data) {
    scope.jobs = data;
  });

  resourceFactory.schedulerResource.get(function (data) {
    scope.schedulerstatus = data.active === true ? 'Active' : 'Standby';
  });

  scope.selectAll = function (selectAll) {
    if (selectAll === true) {
      for (let i = 0; i < scope.jobs.length; i++) {
        jobIdArray.push(scope.jobs[i].id);
        scope.jobs[i].checkbox = true;
      }
    } else {
      for (let i = 0; i < scope.jobs.length; i++) {
        jobIdArray = _.without(jobIdArray, scope.jobs[i].id);
        scope.jobs[i].checkbox = false;
      }
    }

    jobIdArray = _.uniq(jobIdArray);
  };

  scope.errorLog = function (id) {
    scope.id = id;
    $uibModal.open({
      templateUrl: 'errorlog.html',
      controller: ErrorLogCtrl,
      resolve: {
        ids () {
          return id;
        }
      }
    });
  };

  const ErrorLogCtrl = function ($scope, $uibModalInstance, ids) {
    for (const i in scope.jobs) {
      if (scope.jobs[i].id === ids) {
        const index = i;
        $scope.error = scope.jobs[index].lastRunHistory.jobRunErrorLog;
      }
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('close');
    };
  };

  scope.routeTo = function (id) {
    location.path(`/viewschedulerjob/${id}`);
  };

  scope.runJobSelected = function (id, checkbox) {
    for (let i = 0; i < scope.jobs.length; i++) {
      if (scope.jobs[i].id === id) {
        if (checkbox === true) {
          scope.jobs[i].checkbox = true;
          jobIdArray.push(id);
          break;
        } else {
          scope.jobs[i].checkbox = false;
          jobIdArray = _.without(jobIdArray, scope.jobs[i].id);
          break;
        }
      }
    }

    if (jobIdArray.length === 0) {
      scope.activeall = false;
    }

    jobIdArray = _.uniq(jobIdArray);
  };

  scope.runSelectedJobs = function () {
    scope.sentForExecution = [];
    for (const i in jobIdArray) {
      for (const j in scope.jobs) {
        if (scope.jobs[j].id === jobIdArray[i]) {
          scope.sentForExecution.push(scope.jobs[j].displayName);
        }
      }
    }

    for (const i in jobIdArray) {
      resourceFactory.jobsResource.save({ id: jobIdArray[i], command: 'executeJob' }, {}, function () {
      });
    }
  };

  scope.suspendJobs = function () {
    resourceFactory.schedulerResource.save({ command: 'stop' }, {}, function () {
      route.reload();
    });
  };

  scope.activeJobs = function () {
    resourceFactory.schedulerResource.save({ command: 'start' }, {}, function () {
      route.reload();
    });
  };

  scope.refresh = function () {
    route.reload();
  };
}

SchedulerJobsController.$inject = ['$scope', 'ResourceFactory', '$route', '$location', '$uibModal'];
