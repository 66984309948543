export function ListGuarantorController (scope, routeParams, resourceFactory, route, $uibModal) {
  scope.modified = 0;
  resourceFactory.LoanAccountResource.getLoanAccountDetails({ loanId: routeParams.id, associations: 'guarantors' }, function (data) {
    scope.loandetails = data;
    scope.guarantorDetails = data.guarantors;
    scope.status = data.status.value;
    scope.decimals = data.currency.decimalPlaces;
    scope.changedDetail = [];
    scope.hideDeletedGuarantors = true;
    scope.hideWithDrawnGuarantors = true;
    scope.isHideDeletedGuarantorsCheckboxChecked = true;
    scope.updateCheckBoxStatus = function () {
      scope.isHideDeletedGuarantorsCheckboxChecked = !scope.isHideDeletedGuarantorsCheckboxChecked;
    };
  });

  scope.showDetails = function (parentindex, index) {
    scope.guarantorData = scope.guarantorDetails[parentindex];
    if (index === null) {
      scope.guarantorFundDetail = null;
    } else {
      scope.guarantorFundDetail = scope.guarantorData.guarantorFundingDetails[index];
    }

    $uibModal.open({
      templateUrl: 'viewguarantor.html',
      controller: GuarantorViewCtrl,
      resolve: {
        guarantorFundDetail () {
          return scope.guarantorFundDetail;
        },
        guarantorData () {
          return scope.guarantorData;
        }
      }
    });
  };

  const GuarantorViewCtrl = function ($scope, $uibModalInstance, guarantorData, guarantorFundDetail) {
    $scope.guarantorFundDetail = guarantorFundDetail;
    $scope.guarantorData = guarantorData;
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.deleteGuarantor = function (id, fundId) {
    scope.guarantorId = id;
    scope.guarantorFundId = fundId;
    $uibModal.open({
      templateUrl: 'deleteguarantor.html',
      controller: GuarantorDeleteCtrl,
      resolve: {
        id () {
          return scope.guarantorId;
        },
        fundId () {
          return scope.guarantorFundId;
        }
      }
    });
  };
  const GuarantorDeleteCtrl = function ($scope, $uibModalInstance, id, fundId) {
    $scope.delete = function () {
      resourceFactory.guarantorResource.delete({ loanId: routeParams.id, templateResource: id, guarantorFundingId: fundId }, {}, function () {
        $uibModalInstance.close('delete');
        route.reload();
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ListGuarantorController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$route', '$uibModal'];
