<template>
  <tr
    v-show="!hidden"
    @click.prevent="$emit('click')"
  >
    <td>
      <a class="text-primary text-decoration-none fw-medium">
        {{ name.toUpperCase() }}
        <span
          class="text-secondary fw-normal"
        >
          {{ matchedResult }}
        </span>
      </a>
    </td>
  </tr>
</template>
<script setup>
defineProps({
  name: {
    type: String,
    default: ''
  },
  hidden: {
    type: Boolean,
    default: false
  },
  matchedResult: {
    type: Number,
    default: 0
  }
});
</script>
<style lang="scss" scoped>
@import '@/angular-app/styles-dev/main/components/_variables.scss';
  tr.active td {
    background-color: $active-cell;
  }
</style>
