export function ViewNCLimitRuleController (scope, routeParams, resourceFactory, location) {
  const { ruleGroupId: ruleId, id: limitRuleId } = routeParams;
  scope.limitRule = {};
  scope.ruleGroup = {};

  function loadData () {
    const params = {
      amlNameMatchsRule: { ruleId, fields: 'id,name' },
      limitRule: { ruleId, action: 'limitrule', actionId: limitRuleId }
    };

    resourceFactory.amlNetworkConnectionsResource.get(
      params.amlNameMatchsRule,
      function (resAmlRule) {
        resourceFactory.amlNetworkConnectionsResource.get(
          params.limitRule,
          function (resLimitRule) {
            scope.ruleGroup = resAmlRule;
            scope.limitRule = { ...resLimitRule };
          },
          handleResponseError
        );
      },
      handleResponseError
    );
  }

  loadData();

  // Actions on Rule Group
  scope.routeToEditLimitRule = function () {
    location.path(`/payments/aml/rulegroups/${ruleId}/editNclimitrule/${limitRuleId}`);
  };

  scope.activateLimitRule = function () {
    const params = { ruleId, action: 'limitrule', actionId: limitRuleId };

    resourceFactory.amlNetworkConnectionsResource.update(
      params, { active: true },
      function () {
        scope.limitRule = { ...scope.limitRule, active: true };
      },
      handleResponseError
    );
  };

  scope.deactivateLimitRule = function () {
    const params = { ruleId, action: 'limitrule', actionId: limitRuleId };

    resourceFactory.amlNetworkConnectionsResource.update(
      params, { active: false },
      function () {
        scope.limitRule = { ...scope.limitRule, active: false };
      },
      handleResponseError
    );
  };

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }
}

ViewNCLimitRuleController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
