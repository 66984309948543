<template>
  <div
    v-if="userDetails"
    id="mifos-reskin-body-container"
  >
    <nav
      id="mifos-reskin-side-nav"
      class="bg-white position-fixed bottom-0 border-end"
    >
      <div class="left-nav navbar">
        <ul class="navbar-nav">
          <li
            v-for="(m, index) in menu"
            :key="`menu-${index}`"
            class="nav-item"
          >
            <a
              class="nav-link ps-2"
              :href="`#/${m.link}`"
            >
              <i
                class="px-2 fas"
                :class="m.icon"
              />
              <span class="ps-2 pe-3">{{ $t(m.title) }}</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
export default {
  name: 'LeftNav',
  data () {
    return {
      menu: [
        { link: 'help', icon: 'fa-keyboard', title: 'label.anchor.keyboardshortcut' },
        { link: 'tasks', icon: 'fa-check', title: 'label.anchor.tasks' },
        { link: 'client-monitoring', icon: 'fa-user', title: 'label.anchor.clientMonitoring' },
        { link: 'transaction-monitoring', icon: 'fa-search', title: 'label.anchor.transactionandmonitoring' },
        { link: 'transfers', icon: 'fa-exchange-alt', title: 'label.anchor.transfers' },
        { link: 'settlements', icon: 'fa-balance-scale', title: 'label.anchor.settlements' },
        { link: 'freqposting', icon: 'fa-redo', title: 'label.anchor.frequentpostings' },
        { link: 'journalentry', icon: 'fa-plus', title: 'label.anchor.addjournalentries' },
        { link: 'accounts_closure', icon: 'fa-folder', title: 'label.anchor.closingentries' },
        { link: 'accounting-coa', icon: 'fa-sitemap', title: 'label.heading.chartofaccounts' },
        { link: 'notifications', icon: 'fa-bell', title: 'label.heading.notification' },
        { link: 'createclient', icon: 'fa-user-plus', title: 'label.anchor.createclient' },
        { link: 'dispute-management', icon: 'fa-pencil', title: 'label.anchor.disputeManagement' }
      ]
    };
  },
  computed: {
    ...mapState(useUserStore, ['userDetails'])
  }
};
</script>
