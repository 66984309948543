export function EditSchedulerJobController ($scope, routeParams, resourceFactory, location) {
  resourceFactory.jobsResource.getJobDetails({ id: routeParams.id }, function (data) {
    $scope.job = data;
    $scope.formData = {
      displayName: data.displayName,
      cronExpression: data.cronExpression,
      active: data.active
    };
  });

  $scope.cancel = function () {
    location.path(`/viewschedulerjob/${routeParams.id}`);
  };

  $scope.submit = function () {
    resourceFactory.jobsResource.update({ id: routeParams.id }, this.formData, function (data) {
      location.path(`/viewschedulerjob/${data.resourceId}`);
    });
  };
}

EditSchedulerJobController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
