export function MakeAccountInternalTransferController ($scope, resourceFactory, location, routeParams) {
  $scope.restrictDate = new Date();
  $scope.buttonClicked = '';
  const params = { fromAccountId: routeParams.accountId };
  const accountType = routeParams.accountType || '';
  if (accountType === 'fromsavings') params.fromAccountType = 2;
  else if (accountType === 'fromloans') params.fromAccountType = 1;
  else params.fromAccountType = 0;

  $scope.toOffices = [];
  $scope.toClients = [];
  $scope.toAccountTypes = [];
  $scope.toAccounts = [];
  $scope.loading = false;

  $scope.back = function () {
    window.history.back();
  };

  const promiseCallbackHandler = (callback) => {
    return () => {
      $scope.loading = false;
      if (callback) callback();
    };
  };

  $scope.formData = { fromAccountId: params.fromAccountId, fromAccountType: params.fromAccountType };
  resourceFactory.accountTransfersTemplateResource.get(params, function (data) {
    $scope.transfer = data;
    $scope.toOffices = data.toOfficeOptions;
    $scope.toAccountTypes = data.toAccountTypeOptions;

    $scope.handleClientChange();
  });

  $scope.changeClient = function (client) {
    $scope.formData.toClientId = client.id;
    $scope.formData.creditorName = client.displayName;
    $scope.changeEvent('client');
  };

  $scope.changeEvent = function (_field) {
    const params = { ...$scope.formData };
    params.toAccountType = 2;

    delete params.transferAmount;
    delete params.transferDate;
    delete params.transferDescription;

    resourceFactory.accountTransfersTemplateResource.get(params, function (data) {
      $scope.transfer = data;
      $scope.toOffices = data.toOfficeOptions;
      $scope.toAccounts = data.toAccountOptions;

      if (_field === 'office') {
        $scope.formData.toClientId = '';
        $scope.formData.creditorName = '';
        $scope.formData.creditorIdetifier = '';
      } else if (_field === 'client') {
        $scope.formData.creditorIdetifier = '';
      }
    });
  };

  function getSubmitData () {
    let data = {
      type: 'CREDIT',
      paymentType: 'INTERNAL',
      amount: $scope.formData.transferAmount,
      debtor: {
        identifier: `id:${routeParams.accountId}`,
        accountType: $scope.formData.accountType
      },
      creditor: {
        identifier: `id:${$scope.formData.creditorIdetifier}`,
        name: $scope.formData.creditorName,
        accountType: $scope.formData.accountType
      }
    };

    if ($scope.formData.reference) {
      data = { ...data, reference: $scope.formData.reference.trim().split('\n') };
    }

    return data;
  }

  $scope.submit = function () {
    $scope.loading = true;
    const submitData = getSubmitData();
    const payload = $scope.buttonClicked === 'save' ? {} : { command: 'create,force_execute' };
    resourceFactory.transferResource.save(payload, submitData, promiseCallbackHandler(function () {
      location.path(`/viewsavingaccount/${routeParams.accountId}`);
    }), promiseCallbackHandler());
  };

  $scope.handleClientChange = function () {
    if (!$scope.formData.toOfficeId || !$scope.creditorIdentifier) return;
    if ($scope.creditorIdentifier.includes('  /  ')) {
      // If Client Name Search with space should not call other endpoint
      // If Client Name Search with dash should call to other endpoint
      const [clientAccountNumber, displayName] = $scope.creditorIdentifier.split('  /  ');
      const clientId = ($scope.toClients.find(client => client.accountNo === clientAccountNumber))?.id;
      $scope.changeClient({ id: clientId, displayName });
    } else {
      const clientsParams = {
        limit: 100,
        fields: 'id,accountNo,displayName',
        officeId: $scope.formData.toOfficeId,
        sqlSearch: $scope.creditorIdentifier
      };
      resourceFactory.clientResource.getAllClients(clientsParams, function (resClients) {
        $scope.toClients = resClients.pageItems;
      });
    }
  };
}

MakeAccountInternalTransferController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams'];
