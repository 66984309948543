export function CreateStandingInstructionController ($scope, resourceFactory, location, routeParams, dateFilter) {
  $scope.restrictDate = new Date();
  const params = { clientId: routeParams.clientId, officeId: routeParams.officeId };
  const accountType = routeParams.accountType || '';
  if (accountType === 'fromsavings') params.fromAccountType = 2;
  else if (accountType === 'fromloans') params.fromAccountType = 1;
  else params.fromAccountType = 0;

  $scope.toOffices = [];
  $scope.toClients = [];
  $scope.toAccountTypes = [];
  $scope.isEditableAmount = true;
  $scope.toAccounts = [];
  $scope.destinationOptions = [{ id: 1, name: 'own account' }, { id: 2, name: 'with in bank' }];
  $scope.showselctclient = 'false';
  $scope.allowclientedit = 'true';
  $scope.isRepayment = false;

  $scope.formData = { fromOfficeId: Number(params.officeId), fromClientId: Number(params.clientId), fromAccountType: params.fromAccountType };
  resourceFactory.standingInstructionTemplateResource.get($scope.formData, function (data) {
    $scope.standinginstruction = data;
    $scope.toOffices = data.toOfficeOptions;
    $scope.toAccountTypes = data.toAccountTypeOptions;
  });

  $scope.changeEvent = function () {
    const params = $scope.formData;

    resourceFactory.standingInstructionTemplateResource.get(params, function (data) {
      $scope.standinginstruction = data;
      $scope.toOffices = data.toOfficeOptions;
      $scope.toAccountTypes = data.toAccountTypeOptions;
      $scope.toClients = data.toClientOptions;
      $scope.toAccounts = data.toAccountOptions;
      $scope.formData.transferAmount = data.transferAmount;
    });
  };

  $scope.changedestination = function () {
    if ($scope.destination === 1) {
      $scope.allowclientedit = 'false';
      $scope.formData.toOfficeId = $scope.formData.fromOfficeId;
      $scope.formData.toClientId = $scope.formData.fromClientId;
      $scope.changeEvent();
    } else {
      $scope.allowclientedit = 'true';
      $scope.formData.toOfficeId = null;
      $scope.formData.toClientId = null;
    }
  };

  $scope.checkEditAmount = (standinginstructionId) => {
    const instructionCode = $scope.standinginstruction?.instructionTypeOptions.find(item => item.id === standinginstructionId)?.code;
    const nonEditableAmount = ['standingInstructionType.statement.balance', 'standingInstructionType.min.due'];
    if (nonEditableAmount.includes(instructionCode)) {
      $scope.isEditableAmount = false;
      // use to delete the amount key when submit to avoid confusion and make the input null when select other type
      // even if we add key amount it still unnecessary since it auto pay implementation
      delete $scope.formData.amount;
    } else $scope.isEditableAmount = true;
  };

  $scope.checkTransferType = (transferType) => {
    $scope.changeEvent();
    const instructionCode = $scope.standinginstruction?.transferTypeOptions.find(item => item.id === transferType)?.code;
    if (instructionCode !== 'accountTransferType.credit.card.auto.pay') {
      $scope.isRepayment = false;
      delete $scope.formData.repaymentOnDay;
    } else $scope.isRepayment = true;
  };

  $scope.submit = function () {
    this.formData.locale = $scope.optlang.code;
    this.formData.dateFormat = $scope.df;
    if (this.formData.validFrom) this.formData.validFrom = dateFilter(this.formData.validFrom, $scope.df);
    if (this.formData.validTill) this.formData.validTill = dateFilter(this.formData.validTill, $scope.df);
    if (this.recurrenceOnMonthDay) {
      const reqDate = dateFilter(this.recurrenceOnMonthDay, 'dd MMMM');
      this.formData.recurrenceOnMonthDay = reqDate;
      this.formData.monthDayFormat = 'dd MMMM';
    }
    this.formData.fromClientId = $scope.standinginstruction.fromClient.id;
    this.formData.fromOfficeId = $scope.standinginstruction.fromClient.officeId;
    resourceFactory.standingInstructionResource.save(this.formData, function (data) {
      location.path(`/viewclient/${data.clientId}`);
    });
  };
}

CreateStandingInstructionController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams', 'dateFilter'];
