<template>
  <tr>
    <th>{{ $t(detail.key) }}</th>
    <td>{{ detail.value }}</td>
  </tr>
</template>
<script>
export default {
  name: 'DetailTableRow',
  props: {
    detail: {
      type: Object,
      required: true
    }
  }
};
</script>
