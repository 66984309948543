export function FixedDepositProductController (scope, resourceFactory, location) {
  scope.routeTo = function (id) {
    location.path(`/viewfixeddepositproduct/${id}`);
  };

  if (!scope.searchCriteria.fdp) {
    scope.searchCriteria.fdp = null;
    scope.saveSC();
  }
  scope.filterText = scope.searchCriteria.fdp || '';

  scope.onFilter = function () {
    scope.searchCriteria.fdp = scope.filterText;
    scope.saveSC();
  };

  scope.FixedDepositsPerPage = 15;
  resourceFactory.fixedDepositProductResource.getAllFixedDepositProducts(function (data) {
    scope.depositproducts = data;
  });
}

FixedDepositProductController.$inject = ['$scope', 'ResourceFactory', '$location'];
