export function ViewAccessAttemptLogsController (scope, resourceFactory, routeParams) {
  scope.accessAttemptActionsPerPage = 15;
  scope.offset = 0;

  scope.getResultsPage = function (pageNumber) {
    scope.offset = (pageNumber - 1) * scope.accessAttemptActionsPerPage;
    scope.getAccessAttempActions();
  };

  scope.getAccessAttempActions = function () {
    const handleGetAccessAttemptActions = (data) => {
      const { pageItems: accessAttemptlogs, totalFilteredRecords } = data;
      scope.totalAccessAttemptActions = totalFilteredRecords;

      scope.accessAttemptlogs = accessAttemptlogs
        .map(
          ({ id, clientId, actionName, entityName, madeOnDate, makerId, resourceId, url }) =>
            ({ id, clientId, actionName, entityName, madeOnDate, makerId, resourceId, url })
        );
      const totalAccessAttemptActionsPerPage = scope.accessAttemptlogs.slice(0, scope.accessAttemptActionsPerPage);
      scope.accessAttemptActions = totalAccessAttemptActionsPerPage;
    };
    const params = {
      offset: scope.offset,
      limit: scope.accessAttemptActionsPerPage,
      accessattemptId: routeParams.userId
    };
    resourceFactory.accessAttemptActionsResource.get(params, handleGetAccessAttemptActions);
  };
  scope.getAccessAttempActions();
}
ViewAccessAttemptLogsController.$inject = ['$scope', 'ResourceFactory', '$routeParams'];
