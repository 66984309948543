<template>
  <div>
    <div class="d-flex justify-content-end">
      <button
        :disabled="loading"
        class="btn btn-primary mb-2"
        @click="setCardAuthorizations"
      >
        {{ $t(`label.button.view${viewCardAuthorizationBtnText}CardAuthorization`) }}
      </button>
    </div>
    <data-grid
      :fields="fields"
      :items="cardAuthorizations.pageItems"
      :limit="limit"
      :total-records="cardAuthorizations.totalFilteredRecords"
      clickable
      @entries-per-page="updateEntriesPerPage"
      @click-row="routeToCardAuthorization"
      @pagination-update="getCardAuthorizationsByCardResource"
    >
      <template #expiryDate="{ item }">
        <span>
          {{ dateStringToFormat(item.expiryDate) }}
        </span>
      </template>
      <template #amount="{ item }">
        <span>
          {{ item.amount }} {{ currencyIsoCodeToLetterCode(item.currency) }}
        </span>
      </template>
      <template #clearedAmount="{ item }">
        <span>
          {{ item.clearedAmount }} {{ currencyIsoCodeToLetterCode(item.currency) }}
        </span>
      </template>
      <template #settledAmount="{ item }">
        <span v-if="item.settledAmount">
          {{ item.settledAmount }} {{ currencyIsoCodeToLetterCode(item.currency) }}
        </span>
      </template>
      <template #merchant="{ item }">
        <span>
          {{ item.merchant.description }}
        </span>
      </template>
      <template #executionMessage="{ item }">
        <span v-if="item.executionCode">
          {{ $t('label.anchor.code') }} {{ item.executionCode }}:
        </span>
        {{ item.executionMessage }}
      </template>
      <template #avsDetails="{ item }">
        <span v-if="item.avsData">
          {{ `${item.avsData?.address || ''}, ${item.avsData?.zip || ''}` }}
        </span>
      </template>
    </data-grid>
  </div>
</template>

<script setup>
import DataGrid from '@/components/shared/data-grid/index.vue';
import apiService from '@/services/apiService';
import i18n from '@/plugins/i18n';
import { get, set } from '@vueuse/core';
import { dateStringToFormat } from '@/helper/dateHelper';
import { currencyIsoCodeToLetterCode } from '@/helper/currencyHelper';
import { onMounted, ref, computed } from 'vue';
import { useSharedStore } from '@/stores/shared';

const sharedStore = useSharedStore();
const loading = computed(() => sharedStore.loading);

const offset = ref(0);
const limit = ref(15);
const cardAuthorizations = ref([]);
const isActiveCardAuthorization = ref(true);
const viewCardAuthorizationBtnText = computed(() => get(isActiveCardAuthorization) ? 'Closed' : 'Active');

const props = defineProps({
  cardItem: {
    type: Object,
    default: () => ({})
  }
});
const routeToCardAuthorization = (item) => location.assign(`#/viewcardauthorization/${props.token}/authId/${item.id}`);

const fields = [
  { key: 'externalAuthId', label: i18n.t('label.head.authorizationId') },
  { key: 'externalOriginalAuthId', label: i18n.t('label.head.originalAuthId') },
  { key: 'createdAt', label: i18n.t('label.head.createdAt') },
  { key: 'expiryDate', label: i18n.t('label.head.expiryDate') },
  { key: 'authType', label: i18n.t('label.head.authType') },
  { key: 'transactionType', label: i18n.t('label.head.transactionType') },
  { key: 'amount', label: i18n.t('label.head.amount') },
  { key: 'clearedAmount', label: i18n.t('label.head.clearedAmount') },
  { key: 'settledAmount', label: i18n.t('label.head.settledAmount') },
  { key: 'merchant', label: i18n.t('label.head.merchant') },
  { key: 'status', label: i18n.t('label.head.status') },
  { key: 'executionMessage', label: i18n.t('label.head.statusNotes') },
  { key: 'transaction.entryType', label: i18n.t('label.head.transactionStatus') },
  { key: 'avsDetails', label: i18n.t('label.head.avsDetails') }
];

onMounted(async () => {
  await getCardAuthorizationsByCardResource();
});

const getCardAuthorizationsByCardResource = async (offsetChange) => {
  const cardToken = props.cardItem.token;
  const { data: cardAuthorizationsByCardResource } = await apiService.cardAuthorizationsByCardResource.get(cardToken,
    {
      isActiveCardAuthorizations: get(isActiveCardAuthorization),
      limit: get(limit),
      offset: offsetChange | get(offset)
    });
  set(cardAuthorizations, cardAuthorizationsByCardResource);
};

const setCardAuthorizations = () => {
  set(isActiveCardAuthorization, !get(isActiveCardAuthorization));
  getCardAuthorizationsByCardResource();
};

const updateEntriesPerPage = (limitChange) => {
  set(limit, limitChange);
  getCardAuthorizationsByCardResource();
};

</script>
