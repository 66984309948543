export function HasPermissionDirective ($rootScope) {
  return {
    link (scope, element, attrs) {
      const permissionValue = attrs.hasPermission.trim();

      if (!angular.isString(permissionValue)) {
        throw new Error('hasPermission value must be a string');
      }

      const notPermissionFlag = permissionValue.startsWith('!');
      const permissionName = notPermissionFlag ? permissionValue.slice(1) : permissionValue;

      function toggleVisibility () {
        const hasPermission = $rootScope.hasPermission(permissionName);
        if ((hasPermission && !notPermissionFlag) || (!hasPermission && notPermissionFlag)) {
          $(element).show();
        } else {
          $(element).hide();
        }
      }

      toggleVisibility();
      scope.$on('permissionsChanged', toggleVisibility);
    }
  };
}

HasPermissionDirective.$inject = ['$rootScope'];

// import _ from 'underscore';

// export function HasPermissionDirective ($rootScope) {
//   return {
//     link (scope, element, attrs) {
//       if (!_.isString(attrs.hasPermission)) {
//         throw new Error('hasPermission value must be a string');
//       }

//       let value = attrs.hasPermission.trim();
//       const notPermissionFlag = value[0] === '!';
//       if (notPermissionFlag) {
//         value = value.slice(1).trim();
//       }

//       function toggleVisibilityBasedOnPermission () {
//         const hasPermission = $rootScope.hasPermission(value);

//         if (hasPermission && !notPermissionFlag || !hasPermission && notPermissionFlag) {
//           $(element).show();
//         } else {
//           $(element).hide();
//         }
//       }

//       toggleVisibilityBasedOnPermission();
//       scope.$on('permissionsChanged', toggleVisibilityBasedOnPermission);
//     }
//   };
// }

// HasPermissionDirective.$inject = ['$rootScope'];
