import { render, staticRenderFns } from "./ScreeningDetails.vue?vue&type=template&id=37a7c903&scoped=true"
import script from "./ScreeningDetails.vue?vue&type=script&setup=true&lang=js"
export * from "./ScreeningDetails.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ScreeningDetails.vue?vue&type=style&index=0&id=37a7c903&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a7c903",
  null
  
)

export default component.exports