export function ViewHolController (scope, routeParams, resourceFactory, $uibModal, location, route) {
  resourceFactory.holValueResource.getholvalues({ officeId: 1, holId: routeParams.id }, function (data) {
    scope.holiday = data;
    if (data.status.value === 'Pending for activation') {
      scope.holidayStatusPending = true;
    } else if (data.status.value === 'Active') {
      scope.holidayStatusActive = true;
    } else if (data.status.value === 'Deleted') {
      scope.holidayStatusDeleted = true;
    }
  });

  scope.activateHoliday = function () {
    $uibModal.open({
      templateUrl: 'activateHoliday.html',
      controller: activateHolidayCtrl
    });
  };

  const activateHolidayCtrl = function ($scope, $uibModalInstance) {
    $scope.activate = function () {
      resourceFactory.holValueResource.save({ holId: routeParams.id, command: 'Activate' }, {}, function () {
        $uibModalInstance.close('activate');
        route.reload();
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.deleteHoliday = function () {
    $uibModal.open({
      templateUrl: 'deleteHoliday.html',
      controller: deleteHolidayCtrl
    });
  };

  const deleteHolidayCtrl = function ($scope, $uibModalInstance) {
    $scope.activate = function () {
      resourceFactory.holValueResource.delete({ holId: routeParams.id }, {}, function () {
        $uibModalInstance.close('activate');
        location.path('holidays');
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewHolController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$uibModal', '$location', '$route'];
