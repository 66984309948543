export function UibTooltipDirective () {
  return {
    link (scope, element, attributes) {
      element[0]?.setAttribute('data-bs-toggle', 'tooltip');
      element[0]?.setAttribute('title', attributes.uibTooltip);
      element.tooltip();
      scope.$on('$destroy', function () {
        element.tooltip('dispose');
      });
    }
  };
}
