import { TokenService } from '../../../../services/token.service';
import router from '@/router/index';
import { ClientStatus } from '../../models/ClientStatus';

export function ViewEmployeeClientController (scope, routeParams, route, location, resourceFactory, http, $uibModal, API_VERSION, $rootScope, $filter, Upload, $sce, dateFilter) {
  scope.client = [];
  scope.displayApproveButton = process.env.VUE_APP_SUBLEDGER !== 'evolve';
  $rootScope.authorizedUsersTab = {};
  $rootScope.authorizedUsersTab.searchText = '';
  $rootScope.authorizedUsersTab.showAll = false;
  $rootScope.authorizedUsersTab.authorizedUsers = [];
  $rootScope.authorizedUsersTab.resultsPerPage = 20;
  $rootScope.authorizedUsersTab.results = 0;

  scope.clientId = routeParams.clientId;
  scope.identitydocuments = [];
  scope.buttons = [];
  scope.clientdocuments = [];
  scope.staffData = {};
  scope.formData = {};
  scope.openLoan = true;
  scope.openSaving = true;
  scope.openShares = true;
  scope.openFixed = true;
  scope.openRecurring = true;
  scope.showInactiveCard = false;
  scope.showExternalStatusCard = false;
  scope.showFixed = false;
  scope.showRecurring = false;
  scope.updateDefaultSavings = false;
  scope.charges = [];
  scope.hasClosedSavingAccounts = false;
  scope.hasClosedLoanAccounts = false;
  scope.primaryAccountId = routeParams.accountId;
  // address
  scope.addresses = [];
  scope.phoneNumbers = [];
  scope.view = {};
  scope.view.data = [];
  scope.families = [];
  scope.clientCards = [];
  scope.clientExternalCards = [];
  scope.clientCreditAccounts = [];

  resourceFactory.configurationByNameResource.get({ name: 'enable-credit-card-feature' }, function (data) {
    scope.creditCardFeatureEnabled = data.enabled;
    if (scope.creditCardFeatureEnabled) {
      resourceFactory.clientResource.query({ clientId: scope.clientId, anotherresource: 'creditcards' }, function (data) {
        scope.clientCreditAccounts = data;
      });
    }
  });

  scope.beneficiary = {
    items: [],
    total: '',
    search: '',
    showAll: false
  };

  // Transfer data
  scope.errorMsg = '';
  scope.showFilter = true;
  scope.showTable = false;
  scope.isLoading = false;
  scope.tableHeader = [];
  scope.tableData = [];
  scope.transfersPerPage = 15;
  scope.totalTransfers = 0;
  scope.pageNumber = 1;
  scope.usersFound = false;

  scope.originatedByOptions = [
    { value: 'us', name: 'OUTGOING' }
  ];

  scope.paymentSchemeOptions = [
    { value: 'WIRE', name: 'WIRE' },
    { value: 'ACH', name: 'ACH' },
    { value: 'SWIFT', name: 'SWIFT' },
    { value: 'INTERNAL', name: 'INTERNAL' },
    { value: 'CARD', name: 'CARD' }
  ];

  scope.transferTypeOptions = [
    { value: '', name: 'ALL' },
    { value: 'CREDIT', name: 'CREDIT' },
    { value: 'DEBIT', name: 'DEBIT' }
  ];

  scope.transferStatusOptions = [
    { value: '', name: 'ALL' },
    { value: 'DRAFT', name: 'DRAFT' },
    { value: 'UNKNOWN', name: 'UNKNOWN' },
    { value: 'AML_SCREENING', name: 'AML_SCREENING' },
    { value: 'AML_REJECTED', name: 'AML_REJECTED' },
    { value: 'RETURNED', name: 'RETURNED' },
    { value: 'CANCELLED', name: 'CANCELLED' },
    { value: 'EXECUTION_SCHEDULED', name: 'EXECUTION SCHEDULED' },
    { value: 'EXECUTION_PROCESSING', name: 'EXECUTION PROCESSING' },
    { value: 'EXECUTION_SUCCESS', name: 'EXECUTION SUCCESS' },
    { value: 'EXECUTION_FAILURE', name: 'EXECUTION FAILURE' },
    { value: 'DELETED', name: 'DELETED' }
  ];

  scope.fieldOptions = [
    { value: 'transferId', name: 'Transfer ID' },
    { value: 'originatorName', name: 'Originator Name' },
    { value: 'originatorAccount', name: 'Originator Account' },
    { value: 'originatorBankRoutingCode', name: 'Originator Bank Routing Code' },
    { value: 'recipientName', name: 'Recipient Name' },
    { value: 'recipientAccount', name: 'Recipient Account' },
    { value: 'recipientBankRoutingCode', name: 'Recipient Bank Routing Code' },
    { value: 'reference', name: 'Reference' },
    { value: 'trace', name: 'Trace' }
  ];

  scope.filterData = {
    originatedBy: 'us',
    transferStatus: '',
    transferDateFrom: '',
    transferDateTo: '',
    returnDateFrom: '',
    returnDateTo: '',
    paymentScheme: 'ACH',
    transferType: '',
    field: 'transferId'
  };

  scope.currentUserId = TokenService.getSessionData()?.userId;

  // client transfer limits
  scope.transferClientLimits = [];

  // Start of Authorized User Management
  scope.addUser = function (clientId, userId) {
    resourceFactory.clientResource.get({ clientId }, function (data) {
      let authorizations = [];
      if (data.authorizations) {
        authorizations = data.authorizations;
      }
      authorizations.push(userId);
      resourceFactory.clientResource.update({ clientId }, { authorizations }, function () {
        scope.findUsers(clientId, 1);
      });
    });
  };

  scope.removeUser = function (clientId, userId) {
    resourceFactory.clientResource.get({ clientId }, function (data) {
      const updatedAuthorizations = [];
      if (data.authorizations) {
        data.authorizations.forEach(function (id) {
          if (id !== userId) {
            updatedAuthorizations.push(id);
          }
        });
      }
      resourceFactory.clientResource.update({ clientId }, { authorizations: updatedAuthorizations }, function () {
        scope.findUsers(clientId, 1);
      });
    });
  };

  scope.getUsersForClient = function (clientId, pageNumber) {
    scope.authorizedUsersTab.showAll = true;
    scope.authorizedUsersTab.searchText = '';
    scope.findUsers(clientId, pageNumber);
  };

  scope.getAllUsers = function (clientId, pageNumber) {
    scope.authorizedUsersTab.showAll = false;
    scope.findUsers(clientId, pageNumber);
  };

  scope.findUsers = function (clientId, pageNumber) {
    const limit = $rootScope.authorizedUsersTab.resultsPerPage;
    const offset = (pageNumber - 1) * limit;
    const search = $rootScope.authorizedUsersTab.searchText;
    resourceFactory.clientAuthorizations.get({ limit, offset, name: search, clientId: $rootScope.authorizedUsersTab.showAll ? clientId : '' }, function (response) {
      $rootScope.authorizedUsersTab.authorizedUsers = response.pageItems;
      scope.usersFound = !!response.totalFilteredRecords;
      $rootScope.authorizedUsersTab.results = response.totalFilteredRecords;
      $rootScope.authorizedUsersTab.authorizedUsers.forEach(function (user) {
        user.authorized = user.client?.id === clientId;
      });
    });
  };
  // End of Authorized User Management

  scope.findRecipients = function (clientId, pageNumber) {
    const limit = 20;
    const offset = (pageNumber - 1) * limit;
    if (scope.beneficiary.showAll) {
      scope.beneficiary.search = '';
    }
    resourceFactory.beneficiariesResource.get({ clientId, name: scope.beneficiary.search, limit, offset }, function (response) {
      scope.beneficiary.items = response.pageItems;
      scope.beneficiary.total = response.totalFilteredRecords;
    });
  };

  resourceFactory.clientTemplateResource.get(function (data) {
    scope.enableCommunicationDetails = data.isAddressEnabled;
    if (scope.enableCommunicationDetails === true) {
      resourceFactory.addressFieldConfiguration.get({ entity: 'ADDRESS' }, function (response) {
        const { pageItems } = response;

        pageItems.forEach(({ fieldValidations }) => {
          fieldValidations.forEach(fieldValidation => {
            scope.view[fieldValidation.field] = true;
          });
        });
      });
    }
  });

  const getClientAddresses = function () {
    resourceFactory.clientAddress.query({ clientId: scope.clientId }, function (data) {
      scope.addresses = data;
    });
  };

  const getClientPhoneNumber = function () {
    resourceFactory.clientPhoneNumber.query({ clientId: scope.clientId }, function (data) {
      scope.phoneNumbers = data;
    });
  };

  scope.getCommunicationDetails = function () {
    getClientAddresses();
    getClientPhoneNumber();
  };

  scope.changeAddressStatus = function ({ addressTypeId, isActive, addressId }) {
    const formData = { addressId, isActive: !isActive };
    resourceFactory.clientAddress.put({ clientId: scope.clientId, type: addressTypeId }, formData, function () {
      getClientAddresses();
    });
  };

  scope.history = function (id, type) {
    location.path(`/clients/${scope.clientId}/history/${type}`);
  };

  resourceFactory.codeResources.getAllCodes(function (data) {
    scope.codes = data.find(({ name: codeValuePhoneNumber }) => codeValuePhoneNumber === 'Phone Number Type');
  });

  // end of address

  // client transfer limits
  scope.updateTransferClientLimits = function (id) {
    location.path(`/viewclient/${scope.clientId}/limits/${id}`);
  };
  scope.deleteTransferClientLimit = function (id) {
    resourceFactory.transferClientLimitsResource.delete({ transferLimitsId: id }, function () {
      scope.getTransferClientLimits();
    });
  };
  scope.getTransferClientLimits = function () {
    resourceFactory.transferClientLimitsResource.get({
      offset: 0,
      limit: 100,
      clientId: scope.clientId
    },
    function (data) {
      scope.transferClientLimits = data.pageItems;
    });
  };
  scope.toggleInactiveLimitStatus = function (limit, value) {
    limit.isActive = value;
    resourceFactory.transferClientLimitsResource.update({ transferLimitsId: limit.id }, { ...limit, locale: 'en' }, function () {
      scope.getTransferClientLimits();
    });
  };

  scope.changeTariffEndDate = function () {
    resourceFactory.clientResource.save({ clientId: scope.clientId, command: 'chageClassificationApplicableDate' }, this.formData, function (data) {
      location.path(`/viewclient/${data.clientId}`);
    });
  };

  scope.getTransferClientLimits();
  // end of client transfer limits

  // family members
  scope.getFamilyMembers = function () {
    resourceFactory.familyMembers.get({ clientId: scope.clientId }, function (data) {
      scope.families = data;
    });
  };

  scope.deleteFamilyMember = function (clientFamilyMemberId) {
    resourceFactory.familyMember.delete({ clientId: scope.clientId, clientFamilyMemberId }, function () {
      route.reload();
    });
  };

  scope.editFamilyMember = function (clientFamilyMemberId) {
    location.path(`/editfamilymember/${scope.clientId}/${clientFamilyMemberId}`);
  };

  scope.routeToaddFamilyMember = function () {
    location.path(`/addfamilymembers/${scope.clientId}`);
  };

  // end of family members

  scope.routeToLoan = function (id) {
    location.path(`/viewloanaccount/${id}`);
  };
  scope.routeToChargeOverview = function () {
    location.path(scope.pathToChargeOverview());
  };

  scope.pathToChargeOverview = function () {
    return (`/viewclient/${scope.client.id}/chargeoverview`);
  };

  scope.routeToCharge = function (chargeId) {
    location.path(`/viewclient/${scope.client.id}/charges/${chargeId}`);
  };

  scope.routeToSaving = function (depositTypeCode, subAccountId) {
    if (depositTypeCode === 'depositAccountType.savingsDeposit') {
      location.path(`/clients/${scope.client.parentId}/employees/sub-accounts/${subAccountId}`);
    } else if (depositTypeCode === 'depositAccountType.fixedDeposit') {
      location.path(`/viewfixeddepositaccount/${subAccountId}`);
    } else if (depositTypeCode === 'depositAccountType.recurringDeposit') {
      location.path(`/viewrecurringdepositaccount/${subAccountId}`);
    }
  };

  scope.routeToShareAccount = function (id) {
    location.path(`/viewshareaccount/${id}`);
  };

  scope.routeToCreditAccount = function (id) {
    routeParams.clientId = scope.clientId;
    location.path(`/viewcreditaccount/${id}`);
  };

  scope.routeToCard = function (id) {
    location.path(`/clients/${scope.client.parentId}/employees/card/${id}`).search({ primaryAccountId: scope.primaryAccountId });
  };

  scope.routeToEditAddress = function (addressTypeId, addressId) {
    location.path(`/clients/${scope.clientId}/address/${addressTypeId}/${addressId}/edit`).search({ primaryAccountId: scope.primaryAccountId });
  };

  scope.routeToPhoneNumber = function () {
    router.push({ name: 'AddPhoneNumber', params: { cliendId: scope.clientId }, query: { primaryAccountId: scope.primaryAccountId } });
  };

  scope.routeToEditPhoneNumber = function (phoneNumberId, phonenumberType) {
    router.push({ name: 'EditPhoneNumber', params: { cliendId: scope.clientId, phoneNumberId }, query: { primaryAccountId: scope.primaryAccountId, phonenumberType } });
  };

  scope.routeToAddIdentities = function () {
    location.path(`/clients/${scope.clientId}/identifier/add`).search({ primaryAccountId: scope.primaryAccountId });
  };

  scope.routeToAddDocument = function () {
    location.path(`/addclientdocument/${scope.clientId}`).search({ primaryAccountId: scope.primaryAccountId });
  };

  scope.routeToEditDocument = function (clientdocument) {
    location.path(`/editclientdocument/${scope.clientId}/${clientdocument.id}`).search({ primaryAccountId: scope.primaryAccountId });
  };

  scope.routeToEditIdentities = function (documentId) {
    location.path(`/editclientidentifier/${scope.clientId}/${documentId}`).search({ primaryAccountId: scope.primaryAccountId });
  };

  scope.routeToAddress = function () {
    location.path(`/clients/${scope.clientId}/address/add`).search({ primaryAccountId: scope.primaryAccountId });
  };

  scope.routeToExternalCard = function (id) {
    location.path(`/client/${scope.clientId}/viewexternalcard/${id}`);
  };

  scope.haveFile = [];

  scope.closeExpiredIdentifierAlert = () => {
    scope.expiredDocumentTypes = '';
  };

  resourceFactory.clientResource.get({ clientId: scope.clientId, checkIdentitiesExpiration: true }, function (data) {
    const { expiredIdentifiers } = data;

    if (expiredIdentifiers.length) {
      scope.expiredDocumentTypes = expiredIdentifiers.map(
        identifier => identifier.documentType.name
      ).join(', ');
    }

    scope.client = data;
    scope.isClosedClient = scope.client.status.value === 'Closed';
    scope.staffData.staffId = data.staffId;
    if (data.imagePresent) {
      http({ method: 'GET', url: `${$rootScope.hostUrl + API_VERSION}/clients/${scope.clientId}/images?maxHeight=150` })
        .then(function (imageData) { scope.image = imageData.data; });
    }
    http({ method: 'GET', url: `${$rootScope.hostUrl + API_VERSION}/clients/${scope.clientId}/documents` })
      .then(function (docsData) {
        let docId = -1;
        for (let i = 0; i < docsData.data.length; ++i) {
          if (docsData.data[i].name === 'clientSignature') {
            docId = docsData.data[i].id;
            scope.signature_url = `${$rootScope.hostUrl + API_VERSION}/clients/${scope.clientId}/documents/${docId}/attachment?tenantIdentifier=${$rootScope.tenantIdentifier}`;
          }
        }
      });

    scope.navigateToSavingsOrDepositAccount = function (eventName, accountId, savingProductType) {
      switch (eventName) {
        case 'deposit':
          if (savingProductType === 100) location.path(`/savingaccount/${accountId}/deposit`);
          if (savingProductType === 300) location.path(`/recurringdepositaccount/${accountId}/deposit`);
          break;
        case 'withdraw':
          if (savingProductType === 100) location.path(`/savingaccount/${accountId}/withdrawal`);
          if (savingProductType === 300) location.path(`/recurringdepositaccount/${accountId}/withdrawal`);
          break;
        default:
          break;
      }
    };

    const clientStatus = new ClientStatus();

    if (clientStatus.statusKnown(data.status.value)) {
      scope.buttons = clientStatus.getStatus(`subAccount${data.status.value}`);
      scope.savingsActionbuttons = [
        {
          name: 'button.deposit',
          type: '100',
          icon: 'fas fa-arrow-up',
          taskPermissionName: 'DEPOSIT_SAVINGSACCOUNT'
        },
        {
          name: 'button.withdraw',
          type: '100',
          icon: 'fas fa-arrow-down',
          taskPermissionName: 'WITHDRAW_SAVINGSACCOUNT'
        },
        {
          name: 'button.deposit',
          type: '300',
          icon: 'fas fa-arrow-up',
          taskPermissionName: 'DEPOSIT_RECURRINGDEPOSITACCOUNT'
        },
        {
          name: 'button.withdraw',
          type: '300',
          icon: 'fas fa-arrow-down',
          taskPermissionName: 'WITHDRAW_RECURRINGDEPOSITACCOUNT'
        }
      ];
    }

    scope.buttonsArray = {
      options: [{ name: 'button.clientscreenreports' }]
    };
    scope.buttonsArray.singlebuttons = scope.buttons;
    resourceFactory.runReportsResource.get({ reportSource: 'ClientSummary', genericResultSet: 'false', R_clientId: scope.clientId }, function (data) {
      scope.client.ClientSummary = data[0];
    });
    // const qUserTOTPs = `{"query": "{UserTOTPs(where: {user: {authorizations: {client: {id: {EQ: ${data.id}}}}}}) {select {user {id authorizations {id}} id}}}"}`;
    resourceFactory.totpResource.get({ clientId: data.id }, function (response) {
      scope.clientUserTOTPs = response.pageItems[0];
    });

    if (scope.client.legalForm) {
      resourceFactory.DataTablesResource.getAllDataTables({ apptable: 'm_client', legalFormId: scope.client.legalForm.id }, function (data) {
        scope.clientdatatables = data;
      });
    } else {
      resourceFactory.DataTablesResource.getAllDataTables({ apptable: 'm_client' }, function (data) {
        scope.clientdatatables = data;
      });
    }

    if (data.status.value === 'DECEASED client') {
      alert('This client is marked as deceased.');
    }
  });
  scope.deleteClient = function () {
    $uibModal.open({
      templateUrl: 'deleteClient.html',
      controller: ClientDeleteCtrl
    });
  };
  scope.uploadPic = function () {
    $uibModal.open({
      templateUrl: 'uploadpic.html',
      controller: UploadPicCtrl
    });
  };
  const UploadPicCtrl = function ($scope, $uibModalInstance) {
    $scope.upload = function (file) {
      if (file) {
        Upload.upload({
          url: `${$rootScope.hostUrl + API_VERSION}/clients/${scope.clientId}/images`,
          data: {},
          file
        }).then(function () {
          // to fix IE not refreshing the model
          if (!scope.$$phase) {
            scope.$apply();
          }
          $uibModalInstance.close('upload');
          route.reload();
        });
      }
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
  scope.capturePic = function () {
    $uibModal.open({
      templateUrl: 'capturepic.html',
      controller: CapturePicCtrl,
      windowClass: 'modalwidth700'
    });
  };
  const CapturePicCtrl = function ($scope, $uibModalInstance) {
    $scope.picture = null;
    $scope.error = null;
    $scope.videoChannel = {
      video: null,
      videoWidth: 320,
      videoHeight: 240
    };
    $scope.stream = null;

    $scope.onVideoSuccess = function () {
      $scope.error = null;
    };

    $scope.onStream = function (stream) {
      $scope.stream = stream;
    };

    $scope.onVideoError = function (err) {
      if (typeof err !== 'undefined') { $scope.error = `${err.message}(${err.name})`; }
    };

    $scope.takeScreenshot = function () {
      if ($scope.videoChannel.video) {
        const picCanvas = document.createElement('canvas');
        const width = $scope.videoChannel.video.width;
        const height = $scope.videoChannel.video.height;

        picCanvas.width = width;
        picCanvas.height = height;
        const ctx = picCanvas.getContext('2d');
        ctx.drawImage($scope.videoChannel.video, 0, 0, width, height);
        const imageData = ctx.getImageData(0, 0, width, height);
        document.querySelector('#clientSnapshot').getContext('2d').putImageData(imageData, 0, 0);
        $scope.picture = picCanvas.toDataURL();
      }
    };
    $scope.uploadscreenshot = function () {
      if ($scope.picture !== null) {
        http({
          method: 'POST',
          url: `${$rootScope.hostUrl + API_VERSION}/clients/${scope.clientId}/images`,
          data: $scope.picture
        }).then(function () {
          if (!scope.$$phase) {
            scope.$apply();
          }
          $scope.stream.getVideoTracks()[0].stop();
          $uibModalInstance.close('upload');
          route.reload();
        });
      }
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
      $scope.stream.getVideoTracks()[0].stop();
    };
    $scope.reset = function () {
      $scope.picture = null;
    };
  };
  scope.deletePic = function () {
    $uibModal.open({
      templateUrl: 'deletePic.html',
      controller: DeletePicCtrl
    });
  };
  const DeletePicCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      http({
        method: 'DELETE',
        url: `${$rootScope.hostUrl + API_VERSION}/clients/${scope.clientId}/images`
      }).then(function () {
        if (!scope.$$phase) {
          scope.$apply();
        }
        $uibModalInstance.close('delete');
        route.reload();
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
  scope.uploadSig = function () {
    $uibModal.open({
      templateUrl: 'uploadsig.html',
      controller: UploadSigCtrl
    });
  };
  const UploadSigCtrl = function ($scope, $uibModalInstance) {
    $scope.upload = function (file) {
      if (file) {
        Upload.upload({
          url: `${$rootScope.hostUrl + API_VERSION}/clients/${scope.clientId}/documents`,
          data: {
            name: 'clientSignature',
            description: 'client signature'
          },
          file
        }).then(function () {
          // to fix IE not refreshing the model
          if (!scope.$$phase) {
            scope.$apply();
          }
          $uibModalInstance.close('upload');
          route.reload();
        });
      }
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.deleteSig = function () {
    $uibModal.open({
      templateUrl: 'deletesig.html',
      controller: DeleteSigCtrl
    });
  };
  const DeleteSigCtrl = function ($scope, $uibModalInstance) {
    http({
      method: 'GET',
      url: `${$rootScope.hostUrl + API_VERSION}/clients/${scope.clientId}/documents`
    }).then(function (docsData) {
      $scope.docId = -1;
      for (let i = 0; i < docsData.data.length; ++i) {
        if (docsData.data[i].name === 'clientSignature') {
          $scope.docId = docsData.data[i].id;
          scope.signature_url = `${$rootScope.hostUrl + API_VERSION}/clients/${scope.clientId}/documents/${$scope.docId}/attachment?tenantIdentifier=${$rootScope.tenantIdentifier}`;
        }
      }
    });
    $scope.delete = function () {
      http({
        method: 'DELETE',
        url: `${$rootScope.hostUrl + API_VERSION}/clients/${scope.clientId}/documents/${$scope.docId}`
      }).then(function () {
        if (!scope.$$phase) {
          scope.$apply();
        }
        $uibModalInstance.close('upload');
        route.reload();
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.unassignStaffCenter = function () {
    $uibModal.open({
      templateUrl: 'clientunassignstaff.html',
      controller: ClientUnassignCtrl
    });
  };
  const ClientDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.clientResource.delete({ clientId: scope.clientId }, {}, function () {
        $uibModalInstance.close('delete');
        location.path('/clients');
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
  const ClientUnassignCtrl = function ($scope, $uibModalInstance) {
    $scope.unassign = function () {
      resourceFactory.clientResource.save({ clientId: scope.clientId, command: 'unassignstaff' }, scope.staffData, function () {
        $uibModalInstance.close('unassign');
        route.reload();
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
  resourceFactory.clientAccountResource.get({ clientId: scope.clientId }, function (data) {
    scope.clientAccounts = data;

    if (data.loanAccounts) {
      scope.hasClosedLoanAccounts = data.loanAccounts.some((account) => {
        return scope.isClosed(account);
      });
    }

    if (data.savingsAccounts) {
      for (const i in data.savingsAccounts) {
        if (data.savingsAccounts[i].status.value === 'Active' || data.savingsAccounts[i].status.value === 'DECEASED client') {
          scope.updateDefaultSavings = true;
          break;
        }
      }
      scope.totalAllSavingsAccountsBalanceBasedOnCurrency = [];
      for (const i in data.savingsAccounts) {
        if (!scope.isSavingClosed(data.savingsAccounts[i])) {
          let isNewEntryMap = true;
          for (const j in scope.totalAllSavingsAccountsBalanceBasedOnCurrency) {
            if (scope.totalAllSavingsAccountsBalanceBasedOnCurrency[j].code === data.savingsAccounts[i].currency.code) {
              isNewEntryMap = false;
              const totalSavings = scope.totalAllSavingsAccountsBalanceBasedOnCurrency[j].totalSavings + data.savingsAccounts[i].accountBalance;
              scope.totalAllSavingsAccountsBalanceBasedOnCurrency[j].totalSavings = totalSavings;
            }
          }
          if (isNewEntryMap) {
            const map = {};
            map.code = data.savingsAccounts[i].currency.code;
            map.totalSavings = data.savingsAccounts[i].accountBalance;
            scope.totalAllSavingsAccountsBalanceBasedOnCurrency.push(map);
          }
        } else {
          scope.hasClosedSavingAccounts = true;
        }
      }
    }
    for (const i in data.savingsAccounts) {
      if (data.savingsAccounts[i].depositType.value === 'Recurring Deposit') {
        scope.showRecurring = true;
      }
    }
    for (const i in data.savingsAccounts) {
      resourceFactory.savingsAccounts.get({ accountId: data.savingsAccounts[i].id }, function (savingsAccount) {
        data.savingsAccounts[i].savingsAccountNickname = savingsAccount.savingsAccountNickname;
      });
      if (data.savingsAccounts[i].depositType.value === 'Fixed Deposit') {
        scope.showFixed = true;
      }
    }
  });

  resourceFactory.clientResource.query({ clientId: scope.clientId, anotherresource: 'cards' }, function (cards) {
    scope.clientCards = cards;
  });

  resourceFactory.clientResource.query({ clientId: scope.clientId, anotherresource: 'externalCards' }, function (externalCards) {
    // externalCards.expiryDate = `${externalCards.expiryDate.substring(0, 4)} - ${externalCards.expiryDate.substring(4, -1)}`
    externalCards = externalCards.map(card => {
      return {
        ...card,
        expiryDate: `${card.expiryDate.substring(4, -1)}-${card.expiryDate.substring(4)}`
      };
    });
    scope.clientExternalCards = externalCards;
  });

  scope.isSavings = function (savingaccount) {
    if (savingaccount.depositType.value === 'Savings') {
      return true;
    }

    return false;
  };
  scope.isFixed = function (savingaccount) {
    if (savingaccount.depositType.value === 'Fixed Deposit') {
      return true;
    }

    return false;
  };
  scope.isRecurring = function (savingaccount) {
    if (savingaccount.depositType.value === 'Recurring Deposit') {
      return true;
    }

    return false;
  };

  resourceFactory.clientChargesResource.getCharges({ clientId: scope.clientId, pendingPayment: true }, function (data) {
    scope.charges = data.pageItems;
  });

  scope.isClosed = function (loanaccount) {
    if (loanaccount.status.code === 'loanStatusType.closed.written.off' ||
      loanaccount.status.code === 'loanStatusType.closed.obligations.met' ||
      loanaccount.status.code === 'loanStatusType.closed.reschedule.outstanding.amount' ||
      loanaccount.status.code === 'loanStatusType.withdrawn.by.client' ||
      loanaccount.status.code === 'loanStatusType.rejected') {
      return true;
    }
    return false;
  };
  scope.isSavingClosed = function (savingaccount) {
    if (savingaccount.status.code === 'savingsAccountStatusType.withdrawn.by.applicant' ||
      savingaccount.status.code === 'savingsAccountStatusType.closed' ||
      savingaccount.status.code === 'savingsAccountStatusType.pre.mature.closure' ||
      savingaccount.status.code === 'savingsAccountStatusType.rejected') {
      return true;
    }
    return false;
  };

  scope.toggleInactiveCard = function () {
    scope.showInactiveCard = !scope.showInactiveCard;
  };

  scope.toggleExternalCardStatus = function () {
    scope.showExternalStatusCard = !scope.showExternalStatusCard;
  };

  scope.cardFilter = function (card) {
    const ACTIVE_CARD = ['ACTIVE', 'SUSPENDED', 'RENEWED'];

    return scope.showInactiveCard ? !ACTIVE_CARD.includes(card.status) : ACTIVE_CARD.includes(card.status);
  };

  scope.externalCardFilter = function (card) {
    return card.deleted === scope.showExternalStatusCard;
  };

  scope.isShareClosed = function (shareAccount) {
    if (shareAccount.status.code === 'shareAccountStatusType.closed' ||
      shareAccount.status.code === 'shareAccountStatusType.rejected') {
      return true;
    }
    return false;
  };
  scope.setLoan = function () {
    scope.openLoan = !scope.openLoan;
  };
  scope.setSaving = function () {
    if (scope.openSaving) {
      scope.openSaving = false;
    } else {
      scope.openSaving = true;
    }
  };

  scope.setShares = function () {
    if (scope.openShares) {
      scope.openShares = false;
    } else {
      scope.openShares = true;
    }
  };

  scope.setFixed = function () {
    if (scope.openFixed) {
      scope.openFixed = false;
    } else {
      scope.openFixed = true;
    }
  };

  scope.setRecurring = function () {
    if (scope.openRecurring) {
      scope.openRecurring = false;
    } else {
      scope.openRecurring = true;
    }
  };

  scope.findColumnValueForMultiSelect = function (columnValues, value) {
    for (const j in columnValues) {
      if (value === columnValues[j].id) {
        return columnValues[j].value;
      }
    }
  };

  scope.getClientIdentityDocuments = function () {
    resourceFactory.clientResource.getAllClientDocuments({ clientId: scope.clientId, anotherresource: 'identifiers' }, function (data) {
      scope.identitydocuments = data;
      for (let i = 0; i < scope.identitydocuments.length; i++) {
        resourceFactory.clientIdentifierResource.get({ clientIdentityId: scope.identitydocuments[i].id }, function (data) {
          for (let j = 0; j < scope.identitydocuments.length; j++) {
            if (data.length > 0 && scope.identitydocuments[j].id === data[0].parentEntityId) {
              for (let l = 0; l < data.length; l++) {
                let loandocs = {};
                loandocs = `${API_VERSION}/${data[l].parentEntityType}/${data[l].parentEntityId}/documents/${data[l].id}/attachment?tenantIdentifier=${$rootScope.tenantIdentifier}`;
                data[l].url = loandocs;
              }
              scope.identitydocuments[j].documents = data;
            }
          }
        });
      }
    });
  };

  scope.findColumnValueForMultiSelect = function (columnValues, value) {
    for (const j in columnValues) {
      if (value === columnValues[j].id) {
        return columnValues[j].value;
      }
    }
  };

  scope.dataTableChange = function (clientdatatable) {
    resourceFactory.DataTablesResource.getTableDetails({
      datatablename: clientdatatable.registeredTableName,
      entityId: scope.clientId,
      genericResultSet: 'true'
    }, function (data) {
      scope.datatabledetails = data;
      scope.datatabledetails.isData = data.data.length > 0;
      scope.datatabledetails.isMultirow = clientdatatable.isMultiRow;
      scope.showDataTableAddButton = !scope.datatabledetails.isData || scope.datatabledetails.isMultirow;
      scope.showDataTableEditButton = scope.datatabledetails.isData && !scope.datatabledetails.isMultirow;
      scope.list = [];
      for (const i in data.columnHeaders) {
        if (scope.datatabledetails.columnHeaders[i].columnCode) {
          if (data.columnHeaders[i].columnType === 'multiselect') {
            for (const k in data.data) {
              if (data.data[k].row[i] !== undefined || data.data[k].row[i] !== null) {
                const tempColumnValuesIds = data.data[k].row[i].split(',');
                const tempColumnValues = [];
                for (const l in tempColumnValuesIds) {
                  tempColumnValues.push(scope.findColumnValueForMultiSelect(scope.datatabledetails.columnHeaders[i].columnValues, tempColumnValuesIds[l]));
                }
                data.data[k].row[i] = tempColumnValues.toString();
              }
            }
          } else if (data.columnHeaders[i].columnType !== 'fileupload') {
            for (const k in data.data) {
              if (data.data[k].row[i] !== undefined || data.data[k].row[i] !== null) {
                data.data[k].row[i] = scope.findColumnValueForMultiSelect(scope.datatabledetails.columnHeaders[i].columnValues, data.data[k].row[i]);
              }
            }
          }
        }
      }

      scope.list = scope.datatabledetails.data.map(datatabledetail => {
        return scope.datatabledetails.columnHeaders.map((item, index) => {
          const row = {};

          if (item.columnName !== 'client_id') {
            row.key = item.columnDisplayName || item.columnName;
            row.value = datatabledetail.row[index];
            row.columnCode = item.columnCode;
            if (row.value !== null && row.columnCode === 'FILE_UPLOAD' && row.value !== 'PLACE_HOLDER') {
              const fileFieldValue = row.value.split(',');
              const rowDoc = `${API_VERSION}/${clientdatatable.registeredTableName}/${scope.clientId}/documents/${fileFieldValue[0]}/attachment?tenantIdentifier=${$rootScope.tenantIdentifier}`;
              const doc = { url: rowDoc, id: fileFieldValue[0], fileName: fileFieldValue[1], type: fileFieldValue[2] };
              row.doc = doc;
              row.value = doc.fileName;
              row.showDownLoadButton = true;
            }
          }

          return row;
        }).filter(item => item?.key && item?.value);
      });
    });
  };

  scope.downloadDocumentXhr = function (doc) {
    const configObj = {
      method: 'GET',
      url: `${scope.hostUrl}${doc.url}`,
      responseType: 'arraybuffer'
    };
    http(configObj).then(function onFulfilled (response) {
      const a = document.createElement('a');
      const blob = new Blob([response.data], { type: doc.type, responseType: 'arraybuffer' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = doc.fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch(function onRejection (errorResponse) {
      console.error('Error: ', errorResponse.status);
      console.error(errorResponse);
    });
  };

  scope.viewstandinginstruction = function () {
    location.path(`/liststandinginstructions/${scope.client.officeId}/${scope.client.id}`);
  };
  scope.createstandinginstruction = function () {
    location.path(`/createstandinginstruction/${scope.client.officeId}/${scope.client.id}/fromsavings`);
  };
  scope.deleteAll = function (apptableName, entityId) {
    resourceFactory.DataTablesResource.delete({ datatablename: apptableName, entityId, genericResultSet: 'true' }, {}, function () {
      route.reload();
    });
  };

  scope.getClientDocuments = function () {
    resourceFactory.clientDocumentsResource.getAllClientDocuments({ clientId: scope.clientId }, data => {
      scope.clientdocuments = data.map(
        ({ parentEntityType, parentEntityId, id, ...dataKeys }) => ({
          id,
          ...dataKeys,
          url: `${API_VERSION}/${parentEntityType}/${parentEntityId}/documents/${id}/attachment?tenantIdentifier=${$rootScope.tenantIdentifier}`
        })
      );
    });
  };

  scope.isPreviewAbleFile = (fileName) => {
    const fileExtenstion = fileName?.split('.').pop();
    const previewAbleFileExtensions = ['jpg', 'jpeg', 'png', 'pdf'];

    return previewAbleFileExtensions.includes(fileExtenstion);
  };

  scope.deleteDocument = function (documentId, index) {
    resourceFactory.clientDocumentsResource.delete({ clientId: scope.clientId, documentId }, '', function () {
      scope.clientdocuments.splice(index, 1);
    });
  };

  scope.viewDataTable = function (registeredTableName, data) {
    if (scope.datatabledetails.isMultirow) {
      location.path(`/viewdatatableentry/${registeredTableName}/${scope.client.id}/${data[0].value}`).search({ mode: 'edit' });
    } else {
      location.path(`/viewsingledatatableentry/${registeredTableName}/${scope.client.id}`);
    }
  };

  scope.isLoanNotClosed = function (loanaccount) {
    if (loanaccount.status.code === 'loanStatusType.closed.written.off' ||
      loanaccount.status.code === 'loanStatusType.closed.obligations.met' ||
      loanaccount.status.code === 'loanStatusType.closed.reschedule.outstanding.amount' ||
      loanaccount.status.code === 'loanStatusType.withdrawn.by.client' ||
      loanaccount.status.code === 'loanStatusType.rejected') {
      return false;
    }
    return true;
  };

  scope.isSavingNotClosed = function (savingaccount) {
    if (savingaccount.status.code === 'savingsAccountStatusType.withdrawn.by.applicant' ||
      savingaccount.status.code === 'savingsAccountStatusType.closed' ||
      savingaccount.status.code === 'savingsAccountStatusType.pre.mature.closure' ||
      savingaccount.status.code === 'savingsAccountStatusType.rejected') {
      return false;
    }
    return true;
  };

  scope.isShareNotClosed = function (shareAccount) {
    if (shareAccount.status.code === 'shareAccountStatusType.closed' ||
      shareAccount.status.code === 'shareAccountStatusType.rejected') {
      return false;
    }
    return true;
  };
  scope.saveNote = function () {
    resourceFactory.clientResource.save({ clientId: scope.clientId, anotherresource: 'notes' }, this.formData, function () {
      resourceFactory.clientNotesResource.getAllNotes({ clientId: scope.clientId }, function (data) {
        scope.clientNotes = data.map(item => ({ ...item, ableToEditOrDelete: `${item.createdById}` === `${scope.currentUserId}` }));
      });
      scope.formData.note = '';
      scope.predicate = '-id';
    });
  };

  scope.showEditNote = function (clientId, clientNote, index) {
    $uibModal.open({
      templateUrl: 'editNote.html',
      controller: EditNoteCtrl,
      resolve: {
        items () {
          return {
            clientId,
            clientNote,
            index
          };
        }
      },
      size: 'lg'
    });
  };

  scope.showDeleteAddress = function (clientId, addressId, index) {
    $uibModal.open({
      templateUrl: 'deleteAddress.html',
      controller: DeleteAddressCtrl,
      resolve: {
        items () {
          return {
            clientId,
            addressId,
            index
          };
        }
      },
      size: 'lg'
    });
  };

  scope.showDeletePhoneNumber = function (clientId, phonenumberId, index) {
    $uibModal.open({
      templateUrl: 'deletePhoneNumber.html',
      controller: deletePhoneNumberCrtl,
      resolve: {
        items () {
          return {
            clientId,
            phonenumberId,
            index
          };
        }
      },
      size: 'lg'
    });
  };

  scope.showDeleteNote = function (clientId, clientNote, index) {
    $uibModal.open({
      templateUrl: 'deleteNote.html',
      controller: DeleteNoteCtrl,
      resolve: {
        items () {
          return {
            clientId,
            clientNote,
            index
          };
        }
      },
      size: 'lg'
    });
  };

  const EditNoteCtrl = function ($scope, $uibModalInstance, items) {
    $scope.editData = {};
    $scope.editData.editNote = scope.clientNotes[items.index].note;
    $scope.editNote = function () {
      resourceFactory.clientNotesResource.put({ clientId: items.clientId, noteId: items.clientNote }, { note: this.editData.editNote }, function () {
        scope.clientNotes[items.index].note = $scope.editData.editNote;
        $uibModalInstance.close();
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  const DeleteAddressCtrl = function ($scope, $uibModalInstance, items) {
    $scope.disabledDeleteAddressButton = false;

    $scope.deleteAddress = function () {
      const { clientId, addressId, index } = items;
      $scope.disabledDeleteAddressButton = true;
      resourceFactory.clientAddress.delete({ clientId, addressId }, function () {
        $uibModalInstance.close();
        scope.addresses.splice(index, 1);
      });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  const deletePhoneNumberCrtl = function ($scope, $uibModalInstance, items) {
    $scope.disabledDeletePhoneNumberButton = false;

    $scope.deletePhoneNumber = function () {
      const { clientId, phonenumberId, index } = items;
      $scope.disabledDeletePhoneNumberButton = true;
      resourceFactory.clientPhoneNumber.delete({ clientId, id: phonenumberId }, function () {
        $uibModalInstance.close();
        scope.phoneNumbers.splice(index, 1);
      });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  const DeleteNoteCtrl = function ($scope, $uibModalInstance, items) {
    $scope.deleteNote = function () {
      resourceFactory.clientNotesResource.delete({ clientId: items.clientId, noteId: items.clientNote }, '', function () {
        $uibModalInstance.close();
        scope.clientNotes.splice(items.index, 1);
        scope.noteDeleted = true;
      }, function (error) {
        $uibModalInstance.close();
        scope.errorMsg = error.data.defaultUserMessage;
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.deleteClientIdentifierDocument = function (clientId, entityId, index) {
    resourceFactory.clientIdenfierResource.delete({ clientId, id: entityId }, '', function () {
      scope.identitydocuments.splice(index, 1);
    });
  };

  scope.waiveCharge = function (chargeId) {
    resourceFactory.clientChargesResource.waive({ clientId: scope.clientId, resourceType: chargeId }, function () {
      route.reload();
    });
  };

  scope.showSignature = function () {
    $uibModal.open({
      templateUrl: 'clientSignature.html',
      controller: ViewLargerClientSignature,
      size: 'lg'
    });
  };

  scope.showWithoutSignature = function () {
    $uibModal.open({
      templateUrl: 'clientWithoutSignature.html',
      controller: ViewClientWithoutSignature,
      size: 'lg'
    });
  };

  scope.showPicture = function () {
    $uibModal.open({
      templateUrl: 'photo-dialog.html',
      controller: ViewLargerPicCtrl
    });
  };

  scope.showClientDocument = function (clientDocument) {
    $uibModal.open({
      templateUrl: 'client-document.html',
      controller: ViewClientDocumentCtrl,
      size: 'xl',
      resolve: {
        items () {
          return {
            xurl: `${scope.hostUrl}${clientDocument.url}`,
            fileName: clientDocument.fileName
          };
        }
      }
    });
  };

  const ViewClientDocumentCtrl = function ($scope, $uibModalInstance, items) {
    $scope.items = items;

    function previewDocument () {
      http({
        method: 'GET',
        url: items.xurl,
        responseType: 'arraybuffer'
      }).then(function (imageData) {
        if ($scope.fileType.startsWith('image')) {
          let binary = '';
          const bytes = new Uint8Array(imageData.data);
          const len = bytes.byteLength;
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          $scope.fileBase64 = btoa(binary);
        } else {
          const blob = new Blob([imageData.data], { type: $scope.fileType });
          const fileURL = URL.createObjectURL(blob);
          $scope.content = $sce.trustAsResourceUrl(fileURL);
        }
      });

      if (items.fileName.toLowerCase().indexOf('.png') !== -1) {
        $scope.fileType = 'image/png';
        $scope.isImage = true;
      } else if (items.fileName.toLowerCase().indexOf('.jpg') !== -1) {
        $scope.fileType = 'image/jpg';
        $scope.isImage = true;
      } else if (items.fileName.toLowerCase().indexOf('.jpeg') !== -1) {
        $scope.fileType = 'image/jpeg';
        $scope.isImage = true;
      } else if (items.fileName.toLowerCase().indexOf('.pdf') !== -1) {
        $scope.fileType = 'application/pdf';
        $scope.isImage = false;
      } else if (items.fileName.toLowerCase().indexOf('.doc') !== -1) {
        $scope.fileType = 'application/msword';
        $scope.isImage = false;
      } else if (items.fileName.toLowerCase().indexOf('.docx') !== -1) {
        $scope.fileType = 'application/msword';
        $scope.isImage = false;
      }
    }

    previewDocument();

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  const ViewClientWithoutSignature = function ($scope, $uibModalInstance) {
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.uploadSig = function () {
      $uibModalInstance.dismiss('cancel');
      scope.uploadSig();
    };
  };

  const ViewLargerClientSignature = function ($scope, $uibModalInstance) {
    const loadSignature = function () {
      http({
        method: 'GET',
        url: `${$rootScope.hostUrl + API_VERSION}/clients/${scope.clientId}/documents`
      }).then(function (docsData) {
        $scope.docId = -1;
        for (let i = 0; i < docsData.data.length; ++i) {
          if (docsData.data[i].name === 'clientSignature') {
            $scope.fileType = docsData.data[i].mimeType;
            $scope.docId = docsData.data[i].id;
            scope.signature_url = `${$rootScope.hostUrl + API_VERSION}/clients/${scope.clientId}/documents/${$scope.docId}/attachment?tenantIdentifier=${$rootScope.tenantIdentifier}`;
          }
        }
        if (scope.signature_url !== null) {
          http({
            method: 'GET',
            url: `${$rootScope.hostUrl + API_VERSION}/clients/${scope.clientId}/documents/${$scope.docId}/attachment?tenantIdentifier=${$rootScope.tenantIdentifier}`,
            responseType: 'arraybuffer'
          }).then(function (docsData) {
            let binary = '';
            const bytes = new Uint8Array(docsData.data);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            $scope.largeImageType = $scope.fileType;
            $scope.largeImageSignature = btoa(binary);
          });
        }
      });
    };
    loadSignature();
    $scope.deleteSig = function () {
      $uibModalInstance.dismiss('cancel');
      scope.deleteSig();
    };
    $scope.uploadSig = function () {
      $uibModalInstance.dismiss('cancel');
      scope.uploadSig();
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  const ViewLargerPicCtrl = function ($scope, $uibModalInstance) {
    const loadImage = function () {
      if (scope.client.imagePresent) {
        http({
          method: 'GET',
          url: `${$rootScope.hostUrl + API_VERSION}/clients/${scope.clientId}/images?maxWidth=860`
        }).then(function (imageData) {
          $scope.largeImage = imageData.data;
        });
      }
    };
    loadImage();
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.disableTOTP = function () {
    $uibModal.open({
      templateUrl: 'disableTOTP.html',
      controller: DisableTOTPCtrl
    });
  };

  const DisableTOTPCtrl = function ($scope, $uibModalInstance) {
    $scope.disable = function () {
      resourceFactory.totpResource.disable({ userId: scope.client.id }, function () {
        $uibModalInstance.close();
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.verifyClient = function () {
    $uibModal.open({
      templateUrl: 'verifyClient.html',
      controller: verifyClientCtrl
    });
  };

  const verifyClientCtrl = function ($scope, $uibModalInstance) {
    const urlParams = new URLSearchParams(window.location.search);
    const tenantIdentifier = urlParams.get('tenantIdentifier') || process.env.VUE_APP_TENANT;

    $scope.showDialog = true;
    $scope.showConfirm = true;
    $scope.showMsg = false;

    $scope.confirm = function () {
      resourceFactory.clientResource.verify(
        { clientId: scope.clientId, tenantIdentifier },
        function handleSuccess () {
          $scope.showDialog = false;
          $scope.showConfirm = false;
          $scope.msg = `This client's details have been sent for verification. Please check after sometime.`;
          $scope.showMsg = true;
        },
        function handleFailed (error) {
          $scope.showDialog = false;
          $scope.showConfirm = false;
          scope.error = error.data.errors[0].defaultUserMessage;
          $scope.showMsg = true;
        }
      );
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.deleteDatatableEntry = function (clientdatatable, data) {
    $uibModal.open({
      templateUrl: 'deletedatatable.html',
      controller: DatatableDeleteCtrl,
      resolve: {
        items () {
          return {
            clientdatatable,
            resourceId: data[0].value
          };
        }
      }
    });
  };

  const DatatableDeleteCtrl = function ($scope, $uibModalInstance, items) {
    $scope.delete = function () {
      const reqparams = { datatablename: items.clientdatatable.registeredTableName, entityId: scope.client.id, genericResultSet: 'true', resourceId: items.resourceId };
      resourceFactory.DataTablesResource.delete(reqparams, {}, function () {
        scope.dataTableChange(items.clientdatatable);
        $uibModalInstance.close('delete');
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  // Transfer methods
  const getTableHeader = function () {
    const generalOutgoingHeader = ['Transfer Time', 'Originator Name', 'Originator Account', 'Amount', 'Recipient', 'Recipient Account', 'Recipient Bank Route Code', 'Recipient Bank Name', 'Status', 'Reference', 'Error Code', 'Error Details', 'Return Date', 'Type', 'Transaction Detail'];
    const generalIncomingHeader = ['Transfer Time', 'Recipient Name', 'Recipient Account', 'Type', 'Amount', 'Status', 'Originator Bank Route Code', 'Reference', 'Error Code', 'Error Details'];

    if (scope.filterData.originatedBy === 'us' && scope.filterData.paymentScheme === 'WIRE') {
      return generalOutgoingHeader.concat('Link to Instructions');
    } if (scope.filterData.originatedBy === 'external party' && scope.filterData.paymentScheme === 'WIRE') {
      return generalIncomingHeader.concat('Link to Instructions');
    } if (scope.filterData.originatedBy === 'us' && scope.filterData.paymentScheme === 'ACH') {
      return generalOutgoingHeader.concat('Batch File');
    } if (scope.filterData.originatedBy === 'external party' && scope.filterData.paymentScheme === 'ACH') {
      return generalIncomingHeader.concat('Batch File');
    } if (scope.filterData.originatedBy === 'us' && scope.filterData.paymentScheme === 'SWIFT') {
      return generalOutgoingHeader.concat('Link to Instructions');
    } if (scope.filterData.originatedBy === 'external party' && scope.filterData.paymentScheme === 'SWIFT') {
      return generalIncomingHeader;
    } if (scope.filterData.originatedBy === 'us' && scope.filterData.paymentScheme === 'INTERNAL') {
      return generalOutgoingHeader;
    } if (scope.filterData.originatedBy === 'external party' && scope.filterData.paymentScheme === 'INTERNAL') {
      return generalIncomingHeader;
    } if (scope.filterData.paymentScheme === 'CARD') {
      return ['Transfer Time', 'Originator Name', 'Originator Account', 'Amount', 'Recipient', 'Recipient Account', 'Status', 'Reference', 'Error Code', 'Error Details', 'Type', 'Transaction Detail'];
    }

    return [];
  };

  const getTableData = function (data) {
    if (scope.filterData.paymentScheme === 'CARD') {
      // When payment scheme is CARD, the originatedBy is only from us or Outgoing.
      return data.map(function (d, i) {
        const isOutbounceCredit = d.type === 'CREDIT';
        const isOutbounceDebit = d.type === 'DEBIT';
        const debtorSavingAccount = d.debtorAccountNumber || d.debtor?.identifier;
        const creditorSavingAccount = d.creditorAccountNumber || d.creditor?.identifier;
        return {
          rowId: `r${i}`,
          showViewDebtorBtn: isOutbounceCredit,
          showViewCreditorBtn: isOutbounceDebit,
          transferId: d.id,
          debtorAccountId: d.debtorAccountId,
          debtorSavingsAccountTransactionId: d.debtorSavingsAccountTransactionId,
          creditorAccountId: d.creditorAccountId,
          creditorSavingsAccountTransactionId: d.creditorSavingsAccountTransactionId,
          status: d.status,
          type: d.type,
          dataRow: [
            { type: 'text', value: d.executedAt },
            { type: 'text', value: isOutbounceCredit ? d.debtor.name : d.creditor.name },
            { type: 'link', value: isOutbounceCredit ? $filter('AccountIdentifierFormat')(debtorSavingAccount) : $filter('AccountIdentifierFormat')(creditorSavingAccount) },
            { type: 'text', value: d.amount },
            { type: 'text', value: d.transferDetails?.externalCardName },
            { type: 'text', value: $filter('FormatPAN')(d.transferDetails?.externalCardLastDigit?.toString(), true) },
            { type: 'text', value: d.status },
            { type: 'text', value: d.reference?.join(', ') },
            { type: 'text', value: d.errorCode },
            { type: 'text', value: d.errorMessage },
            { type: 'text', value: d.type }
          ]
        };
      });
    }

    if (scope.filterData.originatedBy === 'us') {
      return data.map(function (d, i) {
        const isOutbounceCredit = d.type === 'CREDIT';
        const isOutbounceDebit = d.type === 'DEBIT';
        const debtorSavingAccount = d.debtorAccountNumber || d.debtor?.identifier;
        const creditorSavingAccount = d.creditorAccountNumber || d.creditor?.identifier;
        return {
          rowId: `r${i}`,
          showViewDebtorBtn: isOutbounceCredit,
          showViewCreditorBtn: isOutbounceDebit,
          transferId: d.id,
          debtorAccountId: d.debtorAccountId,
          debtorSavingsAccountTransactionId: d.debtorSavingsAccountTransactionId,
          creditorAccountId: d.creditorAccountId,
          creditorSavingsAccountTransactionId: d.creditorSavingsAccountTransactionId,
          status: d.status,
          type: d.type,
          dataRow: [
            { type: 'text', value: d.executedAt },
            { type: 'text', value: isOutbounceCredit ? d.debtor.name : d.creditor.name },
            { type: 'link', value: isOutbounceCredit ? $filter('AccountIdentifierFormat')(debtorSavingAccount) : $filter('AccountIdentifierFormat')(creditorSavingAccount) },
            { type: 'text', value: d.amount },
            { type: 'text', value: isOutbounceCredit ? d.creditor.name : d.debtor.name },
            { type: 'text', value: isOutbounceCredit ? $filter('AccountIdentifierFormat')(creditorSavingAccount) : $filter('AccountIdentifierFormat')(debtorSavingAccount) },
            { type: 'text', value: isOutbounceCredit ? $filter('RoutingNumberFormat')(creditorSavingAccount) : $filter('RoutingNumberFormat')(debtorSavingAccount) },
            { type: 'text', value: isOutbounceCredit ? d.creditor?.agent?.name : d.debtor?.agent?.name },
            { type: 'text', value: d.status },
            { type: 'text', value: d.reference?.join(', ') },
            { type: 'text', value: d.errorCode },
            { type: 'text', value: d.errorMessage },
            { type: 'text', value: d.returnDate },
            { type: 'text', value: d.type }
          ]
        };
      });
    }

    if (scope.filterData.originatedBy === 'external party') {
      return data.map(function (d, i) {
        const isInbounceCredit = d.type === 'CREDIT';
        const isInbounceDebit = d.type === 'DEBIT';
        const debtorSavingAccount = d.debtorAccountNumber || d.debtor?.identifier;
        const creditorSavingAccount = d.creditorAccountNumber || d.creditor?.identifier;

        return {
          rowId: `r${i}`,
          showViewDebtorBtn: isInbounceDebit,
          showViewCreditorBtn: isInbounceCredit,
          transferId: d.id,
          debtorAccountId: d.debtorAccountId,
          debtorSavingsAccountTransactionId: d.debtorSavingsAccountTransactionId,
          creditorAccountId: d.creditorAccountId,
          creditorSavingsAccountTransactionId: d.creditorSavingsAccountTransactionId,
          status: d.status,
          type: d.type,
          dataRow: [
            { type: 'text', value: d.executedAt },
            { type: 'text', value: isInbounceCredit ? d.creditor.name : d.debtor.name },
            { type: 'link', value: $filter('AccountIdentifierFormat')(isInbounceCredit ? creditorSavingAccount : debtorSavingAccount) },
            { type: 'text', value: d.type },
            { type: 'text', value: d.amount },
            { type: 'text', value: d.status },
            { type: 'text', value: isInbounceCredit ? $filter('RoutingNumberFormat')(debtorSavingAccount) : $filter('RoutingNumberFormat')(creditorSavingAccount) },
            { type: 'text', value: d.reference?.join(', ') },
            { type: 'text', value: d.errorCode },
            { type: 'text', value: d.errorMessage }
          ]
        };
      });
    }

    return [];
  };

  scope.redirectToProfile = function (accountId) {
    location.path(`/viewsavingaccount/${accountId}`);
  };

  scope.getTransfers = function () {
    const transferFilter = {
      clientId: scope.clientId,
      offset: ((scope.pageNumber - 1) * scope.transfersPerPage),
      limit: scope.transfersPerPage,
      dateFormat: 'yyyy-MM-dd',
      locale: scope.optlang.code,
      originatedBy: scope.filterData.originatedBy,
      paymentType: scope.filterData.paymentScheme,
      orderBy: 'executed_at',
      sortOrder: 'DESC'
    };

    if (scope.filterData?.transferStatus) {
      transferFilter.status = scope.filterData.transferStatus;
    }

    if (scope.filterData?.transferType) {
      transferFilter.transferType = scope.filterData.transferType;
    }

    if (scope.filterData.transferDateFrom && scope.filterData.transferDateTo) {
      transferFilter.fromExecuteDate = dateFilter(scope.filterData.transferDateFrom, 'yyyy-MM-dd');
      transferFilter.toExecuteDate = dateFilter(scope.filterData.transferDateTo, 'yyyy-MM-dd');
    }

    if (scope.filterData.returnDateFrom && scope.filterData.returnDateTo && scope.filterData.transferStatus === 'EXECUTION_FAILURE') {
      transferFilter.fromReturnDate = dateFilter(scope.filterData.returnDateFrom, 'yyyy-MM-dd');
      transferFilter.toReturnDate = dateFilter(scope.filterData.returnDateTo, 'yyyy-MM-dd');
    }

    if (scope.filterData?.fieldValue) {
      transferFilter[scope.filterData.field] = scope.filterData?.fieldValue;
    }

    resourceFactory.transferResource.query(transferFilter, function (data) {
      scope.isLoading = false;
      scope.totalTransfers = data.totalFilteredRecords;
      scope.tableHeader = getTableHeader();
      scope.tableData = getTableData(data.pageItems);
      scope.showFilter = false;
      scope.showTable = true;
    }, handleResponseError);
  };

  scope.routeToTransaction = function (accountId, transactionId) {
    location.path(`/viewsavingtrxn/${accountId}/trxnId/${transactionId}`);
  };

  scope.routeToCreateExternalCard = function (clientId) {
    location.path(`/clients/${clientId}/external-card/create`);
  };

  // Function Event Handler
  scope.onSubmitFilter = function () {
    scope.isLoading = true;
    scope.getTransfers();
  };

  scope.onClickReturn = function () {
    scope.showFilter = true;
    scope.showTable = false;
    scope.tableHeader = [];
    scope.tableData = [];
    scope.totalTransfers = 0;
    scope.pageNumber = 1;
  };

  scope.onPaginationChange = function (pageNumber) {
    scope.pageNumber = pageNumber;
    scope.getTransfers();
  };

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.message || data.errors[0].defaultUserMessage;
  }

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
  scope.closeDeleteNoteAlert = function () {
    scope.noteDeleted = false;
    scope.errorMsg = '';
  };

  scope.handlePaymentSchemeOptionsChange = function () {
    if (scope.filterData.paymentScheme === 'CARD') {
      scope.isDisabledOriginatedBy = true;
      scope.filterData.originatedBy = 'us';
    } else {
      scope.isDisabledOriginatedBy = false;
    }
  };

  scope.toggleAvsCheck = function () {
    $uibModal.open({
      templateUrl: 'toggleAvsCheck.html',
      controller: ToggleAvsCheckCtrl
    });
  };
  const ToggleAvsCheckCtrl = function ($scope, $uibModalInstance) {
    $scope.confirm = function () {
      resourceFactory.clientResource.avs({ clientId: scope.clientId }, { skipAvs: !scope.client.skipAvs }, function () {
        $uibModalInstance.dismiss('close');
        route.reload();
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.tabChange = function (tabName) {
    switch (tabName) {
      case 'notes':
        resourceFactory.noteResource.getAll({ resourceType: 'clients', resourceId: scope.clientId, limit: 5 }, function (data) {
          scope.clientNotes = data.map(item => ({ ...item, ableToEditOrDelete: `${item.createdById}` === `${scope.currentUserId}` }));
        });
        resourceFactory.noteResource.getAll({ resourceType: 'clients', resourceId: scope.clientId, associations: 'savings', limit: 5 }, function (data) {
          scope.accountsNotes = data;
        });
        resourceFactory.noteResource.getAll({ resourceType: 'clients', resourceId: scope.clientId, associations: 'savingsTransactions', limit: 5 }, function (data) {
          scope.transactionNotes = data;
        });
        resourceFactory.noteResource.getAll({ resourceType: 'clients', resourceId: scope.clientId, associations: 'authorizations', limit: 5 }, function (data) {
          scope.cardAuthNotes = data;
        });

        break;

      default:
        break;
    }
  };
}

ViewEmployeeClientController.$inject = [
  '$scope',
  '$routeParams',
  '$route',
  '$location',
  'ResourceFactory',
  '$http',
  '$uibModal',
  'API_VERSION',
  '$rootScope',
  '$filter',
  'Upload',
  '$sce',
  'dateFilter'
];
