<template>
  <base-modal
    id="modal-confirm-close"
    @on-submit="$emit('submit')"
  >
    <template #header>
      Confirmation
    </template>

    <p>Are you sure you want to close this credit account?</p>
  </base-modal>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
</script>
