export function ManageFundsController ($scope, location, resourceFactory) {
  $scope.funderror = [];
  $scope.formData = [];
  $scope.addfunderror = false;
  resourceFactory.fundsResource.getAllFunds(function (data) {
    $scope.funds = data;
  });
  $scope.editFund = function (fund, name, id) {
    fund.edit = !fund.edit;
    $scope.formData[id] = name;
  };
  $scope.saveFund = function (id) {
    if (this.formData[id]) {
      $scope.funderror[id] = false;
      resourceFactory.fundsResource.update({ fundId: id }, { name: this.formData[id] }, function () {
        location.path('/managefunds');
      });
    } else {
      $scope.funderror[id] = true;
    }
  };
  $scope.addFund = function () {
    if ($scope.newfund !== undefined) {
      $scope.addfunderror = false;
      resourceFactory.fundsResource.save({ name: $scope.newfund }, function () {
        location.path('/managefunds');
      });
    } else {
      $scope.addfunderror = true;
    }

    $scope.newfund = undefined;
  };
}

ManageFundsController.$inject = ['$scope', '$location', 'ResourceFactory'];
