// (function (module) {
//   mbanq.directives = _.extend(module, {
//     PanelDirective () {
//       return {
//         restrict: 'E',
//         transclude: true,
//         scope: {
//           title: '@'
//         },

//         template: '<div class=\'panel\'>' +
//                     '<div class=\'panel-header\'>' +
//                     '<div class=\'alert-box panel-header-text\'>{{title}}</div></div>' +
//                     '<div ng-transclude></div></div>'
//       }

//     }
//   })
// }(mbanq.directives || {}))

// mbanq.ng.application.directive('panel', [mbanq.directives.PanelDirective]).run(function ($log) {
//   $log.info('PanelDirective initialized')
// })

export function PanelDirective () {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      title: '@'
    },

    template: '<div class=\'panel\'>' +
            '<div class=\'panel-header\'>' +
            '<div class=\'alert-box panel-header-text\'>{{title}}</div></div>' +
            '<div ng-transclude></div></div>'
  };
}
