export function ViewDataTableWorkflowController (scope, routeParams, resourceFactory, location, $uibModal) {
  scope.workflow = {};
  resourceFactory.DataTablesWorkflowResource.getWorkflowDetails({ entityName: routeParams.entityName }, function (data) {
    if (data.entityType) {
      scope.workflow.entityName = data.entityType;
      scope.workflow.steps = [];
      if (data.entityWorkflows !== undefined && data.entityWorkflows.length > 0) {
        for (const i in data.entityWorkflows) {
          scope.workflow.steps.push(data.entityWorkflows[i]);
        }
      }
    }
  });
  scope.deletetableworkflow = function () {
    $uibModal.open({
      templateUrl: 'deletetableworkflow.html',
      controller: TableDeleteCtrl
    });
  };
  const TableDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.DataTablesWorkflowResource.delete({ entityName: routeParams.entityName }, {}, function () {
        $uibModalInstance.close('delete');
        location.path('/datatableworkflows');
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewDataTableWorkflowController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location', '$uibModal'];
