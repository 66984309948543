export function ViewExternalCardDetailsController (scope, routeParams, resourceFactory, location, $uibModal) {
  scope.externalCardDetails = [];
  scope.hideAction = false;
  scope.clientId = routeParams.clientId;
  scope.externalCardId = routeParams.externalCardId;
  scope.clientDetails = [];
  scope.externalCardsTransactions = [];
  resourceFactory.clientResource.get({ clientId: routeParams.clientId }, function (data) {
    scope.clientDetails = data;
  });

  resourceFactory.externalCardsResource.get({ clientId: routeParams.clientId, externalCardId: routeParams.externalCardId }, (card) => {
    scope.externalCardDetails = card;
    scope.hideAction = card.deleted || card.status === 'DELETE_ON_PROCESSING';
  });

  resourceFactory.externalCardsTransactions.get({ clientId: routeParams.clientId, externalCardId: routeParams.externalCardId }, (transactions) => {
    scope.externalCardsTransactions = transactions.pageItems;
  });

  scope.routeTo = function (savingsAccountId, transactionId, accountTransfer, transferId) {
    if (accountTransfer) {
      location.path(`/viewaccounttransfers/${transferId}`);
    } else {
      location.path(`/viewsavingtrxn/${savingsAccountId}/trxnId/${transactionId}`);
    }
  };

  scope.undoCharge = function (accountId, transactionId) {
    location.path(`/savingaccount/${accountId}/undoCharge/transactions/${transactionId}`);
  };

  scope.isDebit = function (savingsTransactionType) {
    return savingsTransactionType.withdrawal === true || savingsTransactionType.feeDeduction === true ||
      savingsTransactionType.overdraftInterest === true || savingsTransactionType.withholdTax === true ||
      savingsTransactionType.overdraftInterestReceivableAccrued === true;
  };

  scope.delete = function () {
    $uibModal.open({
      templateUrl: 'delete.html',
      controller: DeleteCtrl
    });
  };

  scope.chargeBack = function () {
    $uibModal.open({
      templateUrl: 'charge-back.html',
      controller: ChargeBackCtrl
    });
  };

  const DeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.externalCardsResource.delete({ clientId: routeParams.clientId, externalCardId: routeParams.externalCardId }, () => {
        $uibModalInstance.close('delete');
        location.path(`/viewclient/${routeParams.clientId}`);
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  const ChargeBackCtrl = function ($scope, $uibModalInstance) {
    resourceFactory.clientResource.getSavingAccounts({ clientId: scope.clientId, anotherresource: 'accounts' }, function (dataSavingsAccounts) {
      $scope.savingsAccount = dataSavingsAccounts.savingsAccounts.filter(({ status: { active } }) => active);
    });
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.submitChargeBack = function () {
      const savingAccountId = $scope?.formData?.account?.id;
      $scope.formData = {
        locale: 'en',
        externalCardId: scope.externalCardId,
        note: $scope?.formData?.note,
        transactionAmount: $scope?.formData?.transactionAmount
      };
      resourceFactory.chargeBack.save({ savingAccountId, command: 'externalcardchargeback' }, $scope.formData, function () {
        $scope.cancel();
        location.path(`/client/${routeParams.clientId}/viewexternalcard/${scope.externalCardId}`);
      });
    };
  };
}

ViewExternalCardDetailsController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location', '$uibModal'];
