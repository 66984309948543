export function EditRecurringPaymentController (scope, resourceFactory, routeParams, location, dateFilter) {
  let oldStartDate = '';
  let newInputDates = [];
  scope.formData = {};
  scope.formData.paymentTimeType = 'INTERVAL';
  scope.beneficiaries = [];
  scope.savingsAccounts = [];
  scope.paymentTimeTypes = ['INTERVAL', 'SPECIFIC_DATE'];
  scope.frequencies = ['DAYS', 'WEEKS', 'MONTHS', 'YEARS'];
  scope.specificDates = [];
  scope.inputDate = { date: '', dateFormat: scope.df, locale: scope.optlang.code };
  scope.formData.startDate = new Date();
  scope.clientId = routeParams.clientId;

  resourceFactory.clientResource.getSavingAccounts({ clientId: scope.clientId, anotherresource: 'accounts' }, function (dataSavingsAccounts) {
    scope.savingsAccounts = dataSavingsAccounts.savingsAccounts.filter(({ status: { active } }) => active);
  });

  resourceFactory.beneficiariesResource.get({ clientId: scope.clientId }, function (data) {
    scope.beneficiaries = data?.pageItems;
  });

  resourceFactory.recurringPaymentResource.getRecurringPaymentDetails({ id: routeParams.id }, function (data) {
    // eslint-disable-next-line no-unused-vars
    const { id, nextPaymentDate, $promise, $resolved, ...formData } = data;
    oldStartDate = data.startDate;
    scope.formData = { ...formData };
    scope.specificDates = data.specificDates.map(specificDate => ({
      date: dateFilter(specificDate, scope.df),
      dateFormat: scope.inputDate.dateFormat,
      locale: scope.inputDate.locale
    }));
  });

  scope.addDate = function () {
    const isDuplicateSpecificDate = scope.specificDates.find(specificDate => specificDate.date === dateFilter(scope.inputDate.date, scope.df));
    if (
      scope.inputDate.date &&
      !isDuplicateSpecificDate
    ) {
      newInputDates.push({ date: dateFilter(scope.inputDate.date, scope.df) });
      scope.specificDates.push({
        date: dateFilter(scope.inputDate.date, scope.df),
        dateFormat: scope.inputDate.dateFormat,
        locale: scope.inputDate.locale
      });
    }

    scope.inputDate = { date: '', dateFormat: scope.df, locale: scope.optlang.code };
  };
  scope.deleteDate = function (index) {
    const specificDateDataToDelete = scope.specificDates[index].date;
    newInputDates = newInputDates.filter(newInputDate => newInputDate.date !== specificDateDataToDelete);
    scope.specificDates.splice(index, 1);
  };
  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
  scope.$watch('formData.startDate', function (newDate) {
    oldStartDate = dateFilter(oldStartDate, scope.df);
    newDate = dateFilter(newDate, scope.df);
    scope.isStartDateNotChange = oldStartDate === newDate;
  });

  scope.submit = function () {
    scope.formData.startDate = dateFilter(scope.formData.startDate, scope.df);
    if (scope.isStartDateNotChange) { delete scope.formData.startDate; }
    scope.formData.specificDates = scope.specificDates;
    if (!newInputDates.length) {
      delete scope.formData.specificDates;
    }

    scope.formData.endDate = dateFilter(scope.formData.endDate, scope.df);
    scope.formData.locale = scope.optlang.code;
    scope.formData.dateFormat = scope.df;
    resourceFactory.recurringPaymentResource.update({ id: routeParams.id }, scope.formData, function (data) {
      location.path(`/viewrecurringpayment/${routeParams.clientId}/${data.resourceId}`);
    }, (error) => {
      const { data: { errors } } = error;
      scope.errorMsg = errors[0].defaultUserMessage;
    });
  };
}

EditRecurringPaymentController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location', 'dateFilter'];
