import { TokenService } from '../../../services/token.service';
import { setAxiosBearerHeader } from '../../../services/apiService';

export function SessionManager (httpService, SECURITY, resourceFactory) {
  const EMPTY_SESSION = {};

  this.get = function (data) {
    const isOauth = SECURITY === 'oauth';
    if (isOauth) {
      data.accessToken = TokenService.getTokenData()?.access_token;
    }
    if (data.shouldRenewPassword) {
      if (isOauth) {
        httpService.setAuthorization(data.accessToken, true);
        setAxiosBearerHeader(data.accessToken);
      } else {
        httpService.setAuthorization(data.base64EncodedAuthenticationKey, false);
      }
    } else {
      if (isOauth) {
        TokenService.saveSessionData({ userId: data.userId, authenticationKey: data.accessToken, userPermissions: data.permissions });
        httpService.setAuthorization(data.accessToken, true);
        setAxiosBearerHeader(data.accessToken);
      } else {
        TokenService.saveSessionData({ userId: data.userId, authenticationKey: data.base64EncodedAuthenticationKey, userPermissions: data.permissions });
        httpService.setAuthorization(data.base64EncodedAuthenticationKey, false);
      }
      const { userPermissions: permissions, id: userId, username } = data;
      return { username, userId, permissions };
    }
  };

  this.clear = function () {
    TokenService.removeTokenData();
    httpService.cancelAuthorization();
    return EMPTY_SESSION;
  };

  this.restore = function (handler) {
    const sessionData = TokenService.getTokenData() ? TokenService.getSessionData() : null;
    if (sessionData !== null) {
      const isOauth = SECURITY === 'oauth';
      httpService.setAuthorization(sessionData.authenticationKey, isOauth);
      if (isOauth) setAxiosBearerHeader(sessionData.authenticationKey);

      const twoFAToken = TokenService.get2FAToken();
      if (twoFAToken) {
        httpService.setTwoFactorAccessToken(twoFAToken);
      }
      resourceFactory.userResource.get({ userId: sessionData.userId }, function (userData) {
        userData.userPermissions = sessionData.userPermissions;
        const { userPermissions: permissions, id: userId, username } = userData;
        handler({ username, userId, permissions });
      }, function (err) {
        if (err && err.status === 401) { // if on user profile request we get 401, we do not allow log in with current token data
          handler(EMPTY_SESSION);
        }
      });
    } else {
      handler(EMPTY_SESSION);
    }
  };
}

SessionManager.$inject = ['HttpService', 'SECURITY', 'ResourceFactory'];
