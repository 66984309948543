<template>
  <container-login :title="$t('label.heading.resetLinkHasBeenSent')">
    <div class="d-flex flex-column">
      <p>{{ $t('label.resetPasswordInstruction') }}</p>
      <button
        class="btn btn-link px-0 align-self-end"
        @click="routeToLogin"
      >
        {{ $t('label.button.returnToLogin') }}
      </button>
    </div>
  </container-login>
</template>

<script setup>
import ContainerLogin from '@/components/login/Container.vue';

import router from '@/router';
const routeToLogin = () => router.push({ name: 'Login' });
</script>
