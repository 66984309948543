// (function (module) {
//   mbanq.filters = _.extend(module, {
//     UrlToString () {
//       return function (input) {
//         const exp = input
//         let alpha = ''
//         for (let i = 0; i < exp.length; i++) {
//           if (exp[i] >= 'A' && exp[i] <= 'z') {
//             alpha += exp[i]
//           }
//         }
//         return alpha
//       }
//     }
//   })
//   mbanq.ng.application.filter('UrlToString', ['dateFilter', mbanq.filters.UrlToString]).run(function ($log) {
//     $log.info('UrlToString filter initialized')
//   })
// }(mbanq.filters || {}))

export function UrlToString () {
  return function (input) {
    const exp = input;
    let alpha = '';
    for (let i = 0; i < exp.length; i++) {
      if (exp[i] >= 'A' && exp[i] <= 'z') {
        alpha += exp[i];
      }
    }
    return alpha;
  };
}
