<template>
  <div class="card">
    <div class="card-body">
      <PageTitle>{{ $t('label.anchor.editCreditApplication') }}</PageTitle>
      <form-wrapper @submit="submit">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <input-select
              :id="$t('label.anchor.cardProduct')"
              v-model="formData.cardProductId"
              :options="creditAccountData.cardProductOptions"
              :label="$t('label.anchor.cardProduct')"
              value-field="id"
              required
              disabled
              @input="changeProduct"
            />
            <input-date-picker
              v-if="formData.cardProductId"
              v-model="formData.submittedOnDate"
              :max-date="today"
              required
              :label="$t('label.anchor.submittedOn')"
            />
            <input-text
              v-if="formData.cardProductId"
              v-model="formData.externalId"
              :label="$t('label.anchor.externalId')"
            />
          </div>
          <div class="col-sm-12 col-md-6">
            <input-select
              v-if="formData.isSecuredCreditProduct"
              v-model="formData.currentAccountId"
              :options="creditAccountData.accountOptions"
              text-field="accountNo"
              value-field="id"
              :label="$t('label.anchor.currentAccount')"
            />
          </div>
        </div>
        <hr>
        <h6>{{ $t('label.anchor.terms') }}</h6>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="form-group row align-items-center pb-3 ">
              <label class="control-label text-end col-sm-4">{{ $t('label.anchor.currency') }}</label>
              <label class="control-label col-sm-8"><b>{{ creditAccountData?.currency?.displayLabel }}</b></label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="form-group row align-items-center pb-3 ">
              <label class="control-label text-end col-sm-4">{{ $t('label.anchor.decimalPlaces') }}</label>
              <label class="control-label col-sm-8"><b>{{ creditAccountData?.currency?.decimalPlaces }}</b></label>
            </div>
          </div>
          <div
            v-if="linkedToFloatingInterestRates"
            class="col-sm-12 col-md-6"
          >
            <div class="form-group row align-items-center pb-3 ">
              <label class="control-label text-end col-sm-4">{{ $t('label.anchor.floatingInterestRate') }}</label>
              <label class="control-label col-sm-8"><b>{{ creditAccountData?.floatingRateName }}</b></label>
            </div>
          </div>
          <div
            v-if="linkedToFloatingInterestRates"
            class="col-sm-12 col-md-6"
          >
            <div class="form-group row align-items-center pb-3 ">
              <label class="control-label text-end col-sm-4">{{ $t('label.anchor.isBaseLendingRate') }}</label>
              <label class="control-label col-sm-8"><b>{{ creditAccountData?.isBaseLendingRate }}</b></label>
            </div>
          </div>

          <div
            class="col-sm-12 col-md-6"
          >
            <input-text
              v-if="!linkedToFloatingInterestRates"
              v-model="formData.nominalAnnualInterestRate"
              required
              :label="$t('label.anchor.nominalAnnualInterest')"
            />
            <input-text
              v-model="formData.nominalCashAdvanceInterestRate"
              required
              :label="$t('label.anchor.nominalAnnualCashAdvanceInterest')"
            />
            <input-text
              v-if="linkedToFloatingInterestRates"
              v-model="formData.interestRateDifferential"
              required
              :label="$t('label.anchor.nominalDifferentialRate')"
            />
            <input-text
              v-model="creditAccountData.currency.inMultiplesOf"
              required
              disabled
              :label="$t('label.anchor.currencyInMultiplesOf')"
            />
            <input-select
              v-model="formData.interestCalculationType"
              :options="creditAccountData.interestCalculationTypeOptions"
              required
              text-field="value"
              value-field="id"
              :label="$t('label.anchor.interestCalculatedUsing')"
            />
            <input-text
              v-model="formData.creditLimit"
              required
              :label="$t('label.anchor.creditLimit')"
            />
            <input-check-box
              v-if="formData.withHoldTax"
              v-model="formData.withHoldTax"
              required
              :label="$t('label.anchor.isWithholdTaxApplicable')"
            />
          </div>
          <div
            class="col-sm-12 col-md-6"
          >
            <input-select
              v-if="!linkedToFloatingInterestRates"
              v-model="formData.interestCompoundingPeriodType"
              :options="creditAccountData.interestCompoundingPeriodTypeOptions"
              required
              text-field="value"
              value-field="id"
              :label="$t('label.anchor.interestCompoundingPeriod')"
            />
            <input-text
              v-model="formData.nominalAnnualPenaltyInterestRate"
              required
              :label="$t('label.anchor.nominalAnnualPenaltyInterest')"
            />
            <input-select
              v-model="formData.interestPostingPeriodType"
              :options="creditAccountData.interestPostingPeriodTypeOptions"
              required
              value-field="id"
              text-field="value"
              :label="$t('label.anchor.interestPostingPeriod')"
            />
            <input-select
              v-model="formData.interestCalculationDaysInYearType"
              :options="creditAccountData.interestCalculationDaysInYearTypeOptions"
              required
              value-field="id"
              text-field="value"
              :label="$t('label.anchor.dayInYears')"
            />
            <input-text
              v-model="formData.cashLimit"
              required
              :label="$t('label.anchor.cashLimit')"
            />
          </div>
        </div>
        <div class="offset-6">
          <button
            class="btn btn-default me-3"
            @click.prevent="cancel"
          >
            {{ $t('label.button.cancel') }}
          </button>
          <button
            id="save"
            class="btn btn-primary"
            type="submit"
          >
            {{ $t('label.button.save') }}
          </button>
        </div>
      </form-wrapper>
    </div>
  </div>
</template>

<script setup>
import PageTitle from '@/components/shared/PageTitle.vue';
import { InputText, InputDatePicker, FormWrapper, InputSelect, InputCheckBox } from '@/components/shared/form-input/index';
import apiService from '@/services/apiService';
import router from '@/router';
import i18n from '@/plugins/i18n';
import { onMounted, reactive, ref } from 'vue';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { $notify } from '@/plugins/prototypes';
import { set, get } from '@vueuse/core';
import { dateStringToFormat } from '@/helper/dateHelper';
import { useSharedStore } from '@/stores/shared';

const sharedStore = useSharedStore();
const breadCrumbsStore = useBreadcrumbsStore();
const { accountId } = router.currentRoute.params;

const today = ref(new Date());
const linkedToFloatingInterestRates = ref(null);

const creditAccountData = reactive({
  interestCalculationTypeOptions: [],
  interestCompoundingPeriodTypeOptions: [],
  cardProductOptions: [],
  interestPostingPeriodTypeOptions: [],
  accountOptions: [],
  interestCalculationDaysInYearTypeOptions: [],
  currency: {}
});

const formData = reactive({
  locale: 'en',
  dateFormat: 'dd MMMM yyyy',
  cardProductId: null,
  interestCompoundingPeriodType: '',
  interestPostingPeriodType: '',
  interestCalculationDaysInYearType: '',
  interestCalculationType: '',
  submittedOnDate: '',
  nominalAnnualInterestRate: '',
  nominalAnnualPenaltyInterestRate: '',
  cashLimit: '',
  creditLimit: '',
  nominalCashAdvanceInterestRate: '',
  isSecuredCreditProduct: '',
  currentAccountId: ''
});

onMounted(async () => {
  const requestParams = {
    associations: 'account',
    template: true
  };
  const {
    data: {
      creditAccountData: {
        cashLimit,
        currency,
        externalId,
        creditLimit,
        clientId: id,
        cardProductId,
        accountOptions,
        floatingRateName,
        isBaseLendingRate,
        cardProductOptions,
        isSecuredCreditProduct,
        interestCalculationType,
        interestRateDifferential,
        interestPostingPeriodType,
        nominalAnnualInterestRate,
        timeline: { submittedOnDate },
        interestCompoundingPeriodType,
        interestCalculationTypeOptions,
        nominalCashAdvanceInterestRate,
        isLinkedToFloatingInterestRates,
        nominalAnnualPenaltyInterestRate,
        interestPostingPeriodTypeOptions,
        interestCalculationDaysInYearType,
        overdraftInterestRateDifferential,
        interestCompoundingPeriodTypeOptions,
        interestCalculationDaysInYearTypeOptions,
        currentAccountId
      }
    }
  } = await apiService.creditAccountResource.get(accountId, requestParams);

  if (id) formData.clientId = id;
  if (externalId) formData.externalId = externalId;

  if (isLinkedToFloatingInterestRates) {
    set(linkedToFloatingInterestRates, isLinkedToFloatingInterestRates);
    creditAccountData.floatingRateName = floatingRateName;
    creditAccountData.isBaseLendingRate = isBaseLendingRate;
    formData.interestRateDifferential = interestRateDifferential;
    formData.overdraftInterestRateDifferential = overdraftInterestRateDifferential;
  }

  Object.assign(creditAccountData, {
    currency,
    accountOptions,
    cardProductOptions,
    interestCalculationTypeOptions,
    interestPostingPeriodTypeOptions,
    interestCompoundingPeriodTypeOptions,
    interestCalculationDaysInYearTypeOptions
  });

  Object.assign(formData, {
    cashLimit,
    creditLimit,
    cardProductId,
    isSecuredCreditProduct,
    nominalAnnualInterestRate,
    nominalCashAdvanceInterestRate,
    nominalAnnualPenaltyInterestRate,
    interestCalculationType: interestCalculationType.id,
    interestPostingPeriodType: interestPostingPeriodType.id,
    interestCompoundingPeriodType: interestCompoundingPeriodType.id,
    interestCalculationDaysInYearType: interestCalculationDaysInYearType.id,
    submittedOnDate: dateStringToFormat(new Date(submittedOnDate), sharedStore.dateFormat),
    currentAccountId
  });

  breadCrumbsStore.setBreadcrumbs([
    { text: i18n.t('label.anchor.viewCreditAccount'), href: `#/viewcreditaccount/${accountId}` },
    { text: i18n.t('label.anchor.editCreditAccount') }
  ]);
});

const cancel = () => {
  location.assign(`#/viewcreditaccount/${accountId}`);
};

const changeProduct = async () => {
  const inparams = { productId: get(formData).cardProductId };
  if (formData?.clientId) inparams.clientId = formData.clientId;
  try {
    const {
      data: {
        nominalAnnualInterestRate,
        nominalCashAdvanceInterestRate,
        nominalAnnualPenaltyInterestRate,
        interestCalculationType,
        interestPostingPeriodType,
        interestCompoundingPeriodType,
        interestCalculationDaysInYearType,
        isSecuredCreditProduct
      }
    } = await apiService.creditAccountResource.template(inparams);

    Object.assign(formData, {
      nominalAnnualInterestRate,
      nominalCashAdvanceInterestRate,
      nominalAnnualPenaltyInterestRate,
      interestCalculationType: interestCalculationType?.id,
      interestPostingPeriodType: interestPostingPeriodType?.id,
      interestCompoundingPeriodType: interestCompoundingPeriodType?.id,
      interestCalculationDaysInYearType: interestCalculationDaysInYearType?.id,
      isSecuredCreditProduct
    });
  } catch (error) {
    $notify.error(error);
  }
};

const submit = async () => {
  try {
    await apiService.creditAccountResource.update(accountId, formData);
    cancel();
  } catch (error) {
    $notify.error(error);
  }
};
</script>
