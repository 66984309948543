<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.heading.riskRating') }}
    </template>
    <form-wrapper
      class="col-sm-5 mt-4"
      @submit="submit"
    >
      <input-select
        v-model="formData.riskRating"
        :options="riskRatingItem"
        :label="$t('label.input.riskRating')"
        not-clearable
        text-field="value"
        value-field="id"
      />
      <input-date-picker
        v-model="formData.dueDiligenceDate"
        format="YYYY-MM-DD"
        :label="$t('label.input.riseRatingDate')"
      />
      <div class="w-100 mt-3 d-flex justify-content-end">
        <button
          class="btn btn-default mx-1"
          :disabled="isValueChanged"
          @click.prevent="cancel"
        >
          {{ $t('label.button.cancel') }}
        </button>
        <button
          type="submit"
          class="btn btn-primary mx-1"
          :disabled="isValueChanged"
        >
          {{ $t('label.button.submit') }}
        </button>
      </div>
    </form-wrapper>
  </main-content-layout>
</template>
<script setup>
import apiService from '@/services/apiService';
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import { set, get } from '@vueuse/core';
import { dateStringToFormat } from '@/helper/dateHelper';
import { ref, onMounted, reactive, watch, nextTick } from 'vue';
import { InputSelect, InputDatePicker, FormWrapper } from '@/components/shared/form-input';
import { getAngularRootScope } from '@/helper/angularRootScope';

const riskRatingItem = ref([]);
const isValueChanged = ref(true);
const isFormDoneSet = ref(false);
const initialFormData = ref({});
const formData = reactive({
  riskRating: null,
  dueDiligenceDate: null
});
const props = defineProps({
  clientId: {
    type: String,
    required: true
  }
});

onMounted(async () => getRiskRatings());
const getRiskRatings = async () => {
  const { data: { riskRatings } } = await apiService.riskRating.template(props.clientId);
  const { data: { dueDiligenceDate, riskRating } } = await apiService.riskRating.get(props.clientId);

  set(riskRatingItem, riskRatings);
  formData.riskRating = riskRating.id;
  formData.dueDiligenceDate = dateStringToFormat(dueDiligenceDate, 'YYYY-MM-DD');
  Object.assign(get(initialFormData), { ...formData });
  await nextTick();
  set(isFormDoneSet, true);
};

const cancel = () => Object.assign(formData, { ...get(initialFormData) });
const isFormDataChanged = () => Object.keys(formData).some(key => formData[key] !== get(initialFormData)[key]);

watch(() => [formData.riskRating, formData.dueDiligenceDate], () => {
  if (get(isFormDoneSet)) set(isValueChanged, !isFormDataChanged());
});

const resetInitialData = () => {
  set(isFormDoneSet, false);
  set(isValueChanged, true);
  set(initialFormData, {});
};

const submit = async () => {
  try {
    await apiService.riskRating.update(props.clientId, formData);
    resetInitialData();
    getRiskRatings();
  } catch (error) {
    const $rootScope = getAngularRootScope();
    $rootScope.setApiValidationError(error);
  }
};
</script>
