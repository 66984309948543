export function ChargeController (scope, resourceFactory, location) {
  scope.charges = [];

  scope.routeTo = function (id) {
    location.path(`/viewcharge/${id}`);
  };

  if (!scope.searchCriteria.charges) {
    scope.searchCriteria.charges = null;
    scope.saveSC();
  }
  scope.filterText = scope.searchCriteria.charges || '';

  scope.onFilter = function () {
    scope.searchCriteria.charges = scope.filterText;
    scope.saveSC();
  };

  scope.ChargesPerPage = 15;
  scope.$broadcast('ChargeDataLoadingStartEvent');
  resourceFactory.chargeResource.getAllCharges(function (data) {
    scope.charges = data;
    scope.$broadcast('ChargeDataLoadingCompleteEvent');
  });
}

ChargeController.$inject = ['$scope', 'ResourceFactory', '$location'];
