import _ from 'underscore';

export function NewLoanAccAppController (scope, routeParams, resourceFactory, location, dateFilter, uiConfigService, WizardHandler) {
  scope.previewRepayment = false;
  scope.clientId = routeParams.clientId;
  scope.groupId = routeParams.groupId;
  scope.restrictDate = new Date();
  scope.formData = {};
  scope.loandetails = {};
  scope.chargeFormData = {}; // For charges
  scope.collateralFormData = {}; // For collaterals
  scope.inparams = { resourceType: 'template', activeOnly: 'true' };
  scope.date = {
    first: new Date()
  };
  scope.formDat = {};
  scope.datatables = [];
  scope.noOfTabs = 1;
  scope.step = '-';
  scope.formData.datatables = [];
  scope.formDat.datatables = [];
  scope.tf = 'HH:mm';
  scope.loanApp = 'LoanApp';
  scope.customSteps = [];
  scope.tempDataTables = [];
  scope.disabled = true;
  scope.onDayTypeOptions = Array.from({ length: 28 }, (_, index) => index + 1);

  if (scope.clientId) {
    scope.inparams.clientId = scope.clientId;
    scope.formData.clientId = scope.clientId;
  }

  if (scope.groupId) {
    scope.inparams.groupId = scope.groupId;
    scope.formData.groupId = scope.groupId;
  }

  if (scope.clientId && scope.groupId) scope.inparams.templateType = 'jlg';
  else if (scope.groupId) scope.inparams.templateType = 'group';
  else if (scope.clientId) scope.inparams.templateType = 'individual';

  scope.inparams.staffInSelectedOfficeOnly = true;
  scope.inparams.loanType = 'nonrevolving';

  resourceFactory.loanResource.get(scope.inparams, function (data) {
    scope.products = data.productOptions;

    if (data.clientName) scope.clientName = data.clientName;
    if (data.group) scope.groupName = data.group.name;
  });

  scope.loanProductChange = function (loanProductId) {
    scope.inparams.productId = loanProductId;
    scope.inparams.loanType = 'nonrevolving';
    resourceFactory.loanResource.get(scope.inparams, function (data) {
      scope.loanaccountinfo = data;
      scope.previewClientLoanAccInfo();
      scope.loandetails.interestValue = scope.loanaccountinfo.interestType.value;
      scope.loandetails.amortizationValue = scope.loanaccountinfo.amortizationType.value;
      scope.loandetails.interestCalculationPeriodValue =
        scope.loanaccountinfo.interestCalculationPeriodType.value;
      scope.loandetails.transactionProcessingStrategyValue = scope.formValue(
        scope.loanaccountinfo.transactionProcessingStrategyOptions,
        scope.formData.transactionProcessingStrategyId,
        'id',
        'name'
      );
      scope.datatables = data.datatables;
      scope.handleDatatables(scope.datatables);
      scope.disabled = false;
    });

    resourceFactory.loanResource.get(
      {
        resourceType: 'template',
        templateType: 'collateral',
        productId: loanProductId,
        fields: 'id,loanCollateralOptions'
      },
      function (data) {
        scope.collateralOptions = data.loanCollateralOptions || [];
      }
    );
  };

  scope.goNext = function (form) {
    WizardHandler.wizard().checkValid(form);
  };

  scope.handleDatatables = function (datatables) {
    if (!_.isUndefined(datatables) && datatables.length > 0) {
      scope.formData.datatables = [];
      scope.formDat.datatables = [];
      scope.noOfTabs = datatables.length + 1;
      angular.forEach(datatables, function (datatable, index) {
        scope.updateColumnHeaders(datatable.columnHeaderData);
        angular.forEach(datatable.columnHeaderData, function (colHeader, i) {
          if (_.isEmpty(scope.formDat.datatables[index])) { scope.formDat.datatables[index] = { data: {} }; }

          if (_.isEmpty(scope.formData.datatables[index])) {
            scope.formData.datatables[index] = {
              registeredTableName: datatable.registeredTableName,
              data: { locale: scope.optlang.code }
            };
          }

          if (datatable.columnHeaderData[i].columnDisplayType === 'DATETIME') { scope.formDat.datatables[index].data[datatable.columnHeaderData[i].columnName] = {}; }
        });
      });
    }
  };

  scope.updateColumnHeaders = function (columnHeaderData) {
    let colName = columnHeaderData[0].columnName;
    if (colName === 'id') columnHeaderData.splice(0, 1);

    colName = columnHeaderData[0].columnName;
    if (
      colName === 'client_id' ||
      colName === 'office_id' ||
      colName === 'group_id' ||
      colName === 'center_id' ||
      colName === 'loan_id' ||
      colName === 'savings_account_id' ||
      colName === 'credit_account_id'
    ) {
      columnHeaderData.splice(0, 1);
    }
  };
  // Wizard is creating new scope on every step. So resetting the variable here
  scope.resetPreviewFlag = function () {
    scope.previewRepayment = !scope.previewRepayment;
  };

  scope.previewClientLoanAccInfo = function () {
    scope.previewRepayment = false;
    scope.charges = scope.loanaccountinfo.charges || [];
    scope.formData.disbursementData = scope.loanaccountinfo.disbursementDetails || [];
    scope.collaterals = [];

    if (scope.loanaccountinfo.calendarOptions) {
      scope.formData.syncRepaymentsWithMeeting = true;
      scope.formData.syncDisbursementWithMeeting = true;
    }
    scope.multiDisburseLoan = scope.loanaccountinfo.multiDisburseLoan;
    scope.formData.productId = scope.loanaccountinfo.loanProductId;
    scope.formData.fundId = scope.loanaccountinfo.fundId;
    scope.formData.principal = scope.loanaccountinfo.principal;
    scope.formData.loanTermFrequency = scope.loanaccountinfo.termFrequency;
    scope.formData.loanTermFrequencyType = scope.loanaccountinfo.termPeriodFrequencyType.id;
    scope.loandetails.loanTermFrequencyValue = scope.loanaccountinfo.termPeriodFrequencyType.value;
    scope.formData.numberOfRepayments = scope.loanaccountinfo.numberOfRepayments;
    scope.formData.repaymentEvery = scope.loanaccountinfo.repaymentEvery;
    scope.formData.repaymentFrequencyType = scope.loanaccountinfo.repaymentFrequencyType.id;
    scope.loandetails.repaymentFrequencyValue = scope.loanaccountinfo.repaymentFrequencyType.value;
    scope.formData.interestRatePerPeriod = scope.loanaccountinfo.interestRatePerPeriod;
    scope.formData.amortizationType = scope.loanaccountinfo.amortizationType.id;
    scope.formData.isEqualAmortization = scope.loanaccountinfo.isEqualAmortization;
    scope.loandetails.amortizationValue = scope.loanaccountinfo.amortizationType.value;
    scope.formData.interestType = scope.loanaccountinfo.interestType.id;
    scope.loandetails.interestValue = scope.loanaccountinfo.interestType.value;
    scope.formData.interestCalculationPeriodType = scope.loanaccountinfo.interestCalculationPeriodType.id;
    scope.loandetails.interestCalculationPeriodValue =
      scope.loanaccountinfo.interestCalculationPeriodType.value;
    scope.formData.allowPartialPeriodInterestCalculation =
      scope.loanaccountinfo.allowPartialPeriodInterestCalculation;
    scope.formData.inArrearsTolerance = scope.loanaccountinfo.inArrearsTolerance;
    scope.formData.graceOnPrincipalPayment = scope.loanaccountinfo.graceOnPrincipalPayment;
    scope.formData.graceOnInterestPayment = scope.loanaccountinfo.graceOnInterestPayment;
    scope.formData.graceOnArrearsAgeing = scope.loanaccountinfo.graceOnArrearsAgeing;
    scope.formData.transactionProcessingStrategyId = scope.loanaccountinfo.transactionProcessingStrategyId;
    scope.loandetails.transactionProcessingStrategyValue = scope.formValue(
      scope.loanaccountinfo.transactionProcessingStrategyOptions,
      scope.formData.transactionProcessingStrategyId,
      'id',
      'name'
    );
    scope.formData.graceOnInterestCharged = scope.loanaccountinfo.graceOnInterestCharged;
    scope.formData.fixedEmiAmount = scope.loanaccountinfo.fixedEmiAmount;
    scope.formData.maxOutstandingLoanBalance = scope.loanaccountinfo.maxOutstandingLoanBalance;

    if (
      scope.loanaccountinfo.isInterestRecalculationEnabled &&
      scope.loanaccountinfo.interestRecalculationData.recalculationRestFrequencyDate
    ) {
      scope.date.recalculationRestFrequencyDate = new Date(
        scope.loanaccountinfo.interestRecalculationData.recalculationRestFrequencyDate
      );
    }
    if (
      scope.loanaccountinfo.isInterestRecalculationEnabled &&
      scope.loanaccountinfo.interestRecalculationData.recalculationCompoundingFrequencyDate
    ) {
      scope.date.recalculationCompoundingFrequencyDate = new Date(
        scope.loanaccountinfo.interestRecalculationData.recalculationCompoundingFrequencyDate
      );
    }

    if (scope.loanaccountinfo.isLoanProductLinkedToFloatingRate) {
      scope.formData.isFloatingInterestRate = false;
    }

    scope.loandetails = angular.copy(scope.formData);
    scope.loandetails.productName = scope.formValue(scope.products, scope.formData.productId, 'id', 'name');
  };

  scope.$watch(
    'formData',
    function (newVal) {
      scope.loandetails = angular.extend(scope.loandetails, newVal);
    },
    true
  );

  scope.formValue = function (array, model, findattr, retAttr) {
    findattr = findattr || 'id';
    retAttr = retAttr || 'value';
    return _.find(array, function (obj) {
      return obj[findattr] === model;
    })[retAttr];
  };

  scope.addCharge = function () {
    if (scope.chargeFormData.chargeId) {
      resourceFactory.chargeResource.get(
        { chargeId: this.chargeFormData.chargeId, template: 'true' },
        function (data) {
          data.chargeId = data.id;
          scope.charges.push({ ...data, addToPrincipalAmount: false });
          scope.chargeFormData.chargeId = undefined;
        }
      );
    }
  };

  scope.deleteCharge = function (index) {
    scope.charges.splice(index, 1);
  };

  scope.addTranches = function () {
    scope.formData.disbursementData.push({});
  };
  scope.deleteTranches = function (index) {
    scope.formData.disbursementData.splice(index, 1);
  };

  scope.syncRepaymentsWithMeetingchange = function () {
    if (!scope.formData.syncRepaymentsWithMeeting) scope.formData.syncDisbursementWithMeeting = false;
  };

  scope.syncDisbursementWithMeetingchange = function () {
    if (scope.formData.syncDisbursementWithMeeting) scope.formData.syncRepaymentsWithMeeting = true;
  };

  scope.addCollateral = function () {
    if (scope.collateralFormData.collateralIdTemplate && scope.collateralFormData.collateralValueTemplate) {
      scope.collaterals.push({
        type: scope.collateralFormData.collateralIdTemplate.id,
        name: scope.collateralFormData.collateralIdTemplate.name,
        value: scope.collateralFormData.collateralValueTemplate,
        description: scope.collateralFormData.collateralDescriptionTemplate
      });
      scope.collateralFormData.collateralIdTemplate = undefined;
      scope.collateralFormData.collateralValueTemplate = undefined;
      scope.collateralFormData.collateralDescriptionTemplate = undefined;
    }
  };

  scope.deleteCollateral = function (index) {
    scope.collaterals.splice(index, 1);
  };

  scope.previewRepayments = function () {
    // Make sure charges and collaterals are empty before initializing.
    delete scope.formData.charges;
    delete scope.formData.collateral;
    if (
      _.isUndefined(scope.formData.datatables) ||
      (!_.isUndefined(scope.formData.datatables) && scope.formData.datatables.length === 0)
    ) {
      delete scope.formData.datatables;
    }

    const reqFirstDate = dateFilter(scope.date.first, scope.df);
    const reqSecondDate = dateFilter(scope.date.second, scope.df);
    const reqThirdDate = dateFilter(scope.date.third, scope.df);
    const reqFourthDate = dateFilter(scope.date.fourth, scope.df);
    if (scope.charges.length > 0) {
      scope.formData.charges = scope.charges.map(
        ({ chargeId, amount, dueDate, addToPrincipalAmount }) => ({ chargeId, amount, dueDate: dateFilter(dueDate, scope.df), addToPrincipalAmount })
      );
    }

    if (scope.formData.disbursementData.length > 0) {
      for (const i in scope.formData.disbursementData) {
        scope.formData.disbursementData[i].expectedDisbursementDate = dateFilter(
          scope.formData.disbursementData[i].expectedDisbursementDate,
          scope.df
        );
      }
    }

    if (scope.collaterals.length > 0) {
      scope.formData.collateral = [];
      for (const i in scope.collaterals) {
        scope.formData.collateral.push({
          type: scope.collaterals[i].type,
          value: scope.collaterals[i].value,
          description: scope.collaterals[i].description
        });
      }
    }

    if (this.formData.syncRepaymentsWithMeeting) {
      this.formData.calendarId = scope.loanaccountinfo.calendarOptions[0].id;
      scope.syncRepaymentsWithMeeting = this.formData.syncRepaymentsWithMeeting;
    }
    delete this.formData.syncRepaymentsWithMeeting;

    this.formData.interestChargedFromDate = reqThirdDate;
    this.formData.repaymentsStartingFromDate = reqFourthDate;
    this.formData.locale = scope.optlang.code;
    this.formData.dateFormat = scope.df;
    this.formData.loanType = scope.inparams.templateType;
    this.formData.expectedDisbursementDate = reqSecondDate;
    this.formData.submittedOnDate = reqFirstDate;
    if (this.formData.interestCalculationPeriodType === 0) {
      this.formData.allowPartialPeriodInterestCalculation = false;
    }
    resourceFactory.loanResource.save({ command: 'calculateLoanSchedule' }, this.formData, function (data) {
      scope.repaymentscheduleinfo = data;
      scope.previewRepayment = true;
      scope.formData.syncRepaymentsWithMeeting = scope.syncRepaymentsWithMeeting;
    });
  };

  // uiConfigService.appendConfigToScope(scope);

  // return input type
  scope.fieldType = function (type) {
    let fieldType = '';
    if (type) {
      if (type === 'CODELOOKUP' || type === 'CODEVALUE') {
        fieldType = 'SELECT';
      } else if (type === 'DATE') {
        fieldType = 'DATE';
      } else if (type === 'DATETIME') {
        fieldType = 'DATETIME';
      } else if (type === 'BOOLEAN') {
        fieldType = 'BOOLEAN';
      } else {
        fieldType = 'TEXT';
      }
    }
    return fieldType;
  };

  scope.submit = function () {
    // Make sure charges and collaterals are empty before initializing.
    delete scope.formData.charges;
    delete scope.formData.collateral;
    const reqFirstDate = dateFilter(scope.date.first, scope.df);
    const reqSecondDate = dateFilter(scope.date.second, scope.df);
    const reqThirdDate = dateFilter(scope.date.third, scope.df);
    const reqFourthDate = dateFilter(scope.date.fourth, scope.df);

    if (scope.charges.length > 0) {
      scope.formData.charges = [];
      scope.formData.charges = scope.charges.map(
        ({ chargeId, amount, dueDate, addToPrincipalAmount }) => ({ chargeId, amount, dueDate: dateFilter(dueDate, scope.df), addToPrincipalAmount })
      );
    }

    if (scope.formData.disbursementData.length > 0) {
      for (const i in scope.formData.disbursementData) {
        scope.formData.disbursementData[i].expectedDisbursementDate = dateFilter(
          scope.formData.disbursementData[i].expectedDisbursementDate,
          scope.df
        );
      }
    }
    if (scope.collaterals.length > 0) {
      scope.formData.collateral = [];
      for (const i in scope.collaterals) {
        scope.formData.collateral.push({
          type: scope.collaterals[i].type,
          value: scope.collaterals[i].value,
          description: scope.collaterals[i].description
        });
      }
    }

    if (this.formData.syncRepaymentsWithMeeting) {
      this.formData.calendarId = scope.loanaccountinfo.calendarOptions[0].id;
    }
    delete this.formData.syncRepaymentsWithMeeting;
    this.formData.interestChargedFromDate = reqThirdDate;
    this.formData.repaymentsStartingFromDate = reqFourthDate;
    this.formData.locale = scope.optlang.code;
    this.formData.dateFormat = scope.df;
    this.formData.loanType = scope.inparams.templateType;
    this.formData.expectedDisbursementDate = reqSecondDate;
    this.formData.submittedOnDate = reqFirstDate;
    this.formData.createStandingInstructionAtDisbursement =
      scope.formData.createStandingInstructionAtDisbursement;
    if (scope.date.recalculationRestFrequencyDate) {
      const restFrequencyDate = dateFilter(scope.date.recalculationRestFrequencyDate, scope.df);
      scope.formData.recalculationRestFrequencyDate = restFrequencyDate;
    }
    if (scope.date.recalculationCompoundingFrequencyDate) {
      const restFrequencyDate = dateFilter(scope.date.recalculationCompoundingFrequencyDate, scope.df);
      scope.formData.recalculationCompoundingFrequencyDate = restFrequencyDate;
    }
    if (this.formData.interestCalculationPeriodType === 0) {
      this.formData.allowPartialPeriodInterestCalculation = false;
    }
    if (!_.isUndefined(scope.datatables) && scope.datatables.length > 0) {
      angular.forEach(scope.datatables, function (datatable, index) {
        scope.columnHeaders = datatable.columnHeaderData;
        angular.forEach(scope.columnHeaders, function (colHeader, i) {
          scope.dateFormat = `${scope.df} ${scope.tf}`;
          if (scope.columnHeaders[i].columnDisplayType === 'DATE') {
            if (
              !_.isUndefined(
                scope.formDat.datatables[index].data[scope.columnHeaders[i].columnName]
              )
            ) {
              scope.formData.datatables[index].data[
                scope.columnHeaders[i].columnName
              ] = dateFilter(
                scope.formDat.datatables[index].data[scope.columnHeaders[i].columnName],
                scope.dateFormat
              );
              scope.formData.datatables[index].data.dateFormat = scope.dateFormat;
            }
          } else if (scope.columnHeaders[i].columnDisplayType === 'DATETIME') {
            if (
              !_.isUndefined(
                scope.formDat.datatables[index].data[scope.columnHeaders[i].columnName].date
              ) &&
              !_.isUndefined(
                scope.formDat.datatables[index].data[scope.columnHeaders[i].columnName].time
              )
            ) {
              scope.formData.datatables[index].data[scope.columnHeaders[i].columnName] =
                `${dateFilter(
                  scope.formDat.datatables[index].data[scope.columnHeaders[i].columnName]
                    .date,
                  scope.df
                )
                } ${dateFilter(
                  scope.formDat.datatables[index].data[scope.columnHeaders[i].columnName]
                    .time,
                  scope.tf
                )}`;
              scope.formData.datatables[index].data.dateFormat = scope.dateFormat;
            }
          }
        });
      });
    } else {
      delete scope.formData.datatables;
    }
    resourceFactory.loanResource.save(this.formData, function (data) {
      location.path(`/viewloanaccount/${data.loanId}`);
    });
  };

  scope.cancel = function () {
    if (scope.groupId) {
      location.path(`/viewgroup/${scope.groupId}`);
    } else if (scope.clientId) {
      location.path(`/viewclient/${scope.clientId}`);
    }
  };

  scope.$watch('step', function () {
    if (scope.step === 'Terms') {
      setTimeout(() => {
        $('#fixedEmiAmount').width(`${$('#numberOfRepayments').width()}px`);
      }, 100);
    }
  });
}

NewLoanAccAppController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location', 'dateFilter', 'UIConfigService', 'WizardHandler'];
