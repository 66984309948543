import _, { isNull, isUndefined } from 'underscore';

export function DataTableEntryController (scope, location, routeParams, route, resourceFactory, $uibModal, dateFilter, API_VERSION, Upload, $rootScope, http) {
  if (routeParams.tableName) {
    scope.tableName = routeParams.tableName;
  }
  if (routeParams.entityId) {
    scope.entityId = routeParams.entityId;
  }
  if (routeParams.resourceId) {
    scope.resourceId = routeParams.resourceId;
  }
  scope.fromEntity = routeParams.fromEntity;
  scope.formData = {};
  scope.columnHeaders = [];
  scope.columnData = {};
  scope.isViewMode = true;
  scope.tf = 'HH:mm';
  scope.files = [];
  scope.sectionData = [];
  scope.parentColumnOptions = [];

  scope.getSection = function (sectionId) {
    return scope.sectionData.find(item => item.id === sectionId);
  };

  if (routeParams.mode && routeParams.mode === 'edit') {
    scope.isViewMode = false;
  }

  const reqparams = { datatablename: scope.tableName, entityId: scope.entityId, genericResultSet: 'true' };
  if (scope.resourceId) {
    reqparams.resourceId = scope.resourceId;
  }

  function findColumnIdForMultiSelect (columnValues, value) {
    const col = columnValues.find(columnValue => columnValue.value === value);
    return col?.id.toString();
  }

  function findParentAttribute (data, colName) {
    return !isUndefined(data.parentNameAttribute) && data.parentNameAttribute === colName;
  }
  scope.updateShowHideField = function (colName, state) {
    scope.parentColumnOptions.slice(colName);
    scope.sectionData.forEach(sectionData => {
      if (findParentAttribute(sectionData, colName)) {
        sectionData.hide = !state;
      }
      sectionData.columnHeaders.forEach(columnHeader => {
        if (findParentAttribute(columnHeader, colName)) {
          columnHeader.hide = !state;
        }
      });
    });
  };
  resourceFactory.DataTablesResource.getTableDetails(reqparams, function (data) {
    scope.datatableDisplayName = data.datatableDisplayName;
    data.sections.forEach(section => {
      scope.sectionData[section.position] = {
        title: section.title,
        description: section.description,
        id: section.id,
        columnHeaders: [],
        hide: false,
        parentNameAttribute: section.parentNameAttribute
      };
    });

    data.columnHeaders.forEach((columnHeader, columnIndex) => {
      const rowData = data.data[0].row[columnIndex];
      if (columnHeader.columnCode) {
        const isFileUpload = columnHeader.columnDisplayType === 'CODEVALUE' && columnHeader.columnCode === 'FILE_UPLOAD';
        const isMuliSelect = columnHeader.columnType === 'multiselect' && !(isNull(rowData) || isUndefined(rowData));
        if (isFileUpload) {
          columnHeader.value = rowData;
          if (!isUndefined(columnHeader.value) && !isNull(columnHeader.value)) {
            const fileFieldValue = columnHeader.value.split(',');
            columnHeader.file = {};
            columnHeader.file.id = fileFieldValue[0];
            columnHeader.file.name = fileFieldValue[1];
            columnHeader.file.type = fileFieldValue[2];
            columnHeader.value = fileFieldValue[1];
          }
        } else if (isMuliSelect) {
          const values = rowData.split(',');
          const muliSelectValues = [];
          values.forEach(value => {
            muliSelectValues.push(columnHeader.columnValues.find(columnValue => columnValue.id === value));
          });
          columnHeader.value = muliSelectValues.value?.toString();
        } else {
          columnHeader.columnValues.forEach(columnValue => {
            if (columnHeader.columnDisplayType === 'CODELOOKUP' && rowData === columnValue.id) { columnHeader.value = columnValue.value; } else if (columnHeader.columnDisplayType === 'CODEVALUE' && rowData === columnValue.value) { columnHeader.value = columnValue.value; }
          });
        }
      } else {
        columnHeader.value = rowData;
      }
      if (columnHeader.columnDisplayType === 'BOOLEAN' && columnHeader.value === 'false') {
        scope.parentColumnOptions.push(columnHeader.columnName);
      }
      columnHeader.hide = false;
    });

    data.columnHeaders.forEach(columnHeader => {
      const colName = columnHeader.columnName;
      const isInvalidColumn = ['id', 'client_id', 'office_id', 'loan_id', 'savings_account_id', 'credit_account_id'].includes(colName);
      if (!isInvalidColumn) {
        const sections = scope.getSection(columnHeader.sectionId);
        if (!isUndefined(columnHeader.parentNameAttribute)) {
          const hasParentNameAttribute = scope.parentColumnOptions.indexOf(columnHeader.parentNameAttribute) !== -1;
          columnHeader.hide = hasParentNameAttribute;
        }
        sections.columnHeaders.push(columnHeader);
      } else {
        scope.columnHeaders.push(columnHeader);
      }
    });

    if (routeParams.mode && routeParams.mode === 'edit') {
      scope.editDatatableEntry();
    }
  });

  scope.fieldType = function (type) {
    const fieldTypes = {
      CODELOOKUP: 'SELECT',
      CODEVALUE: 'SELECT',
      DATE: 'DATE',
      DATETIME: 'DATETIME',
      BOOLEAN: 'BOOLEAN',
      INTEGER: 'INTEGER',
      DECIMAL: 'DECIMAL'
    };

    return fieldTypes[type] || 'TEXT';
  };

  scope.dateTimeFormat = function () {
    for (const i in scope.columnHeaders) {
      if (scope.columnHeaders[i].columnDisplayType === 'DATETIME') {
        return `${scope.df} ${scope.tf}`;
      }
    }
    return scope.df;
  };

  scope.editDatatableEntry = function () {
    scope.isViewMode = false;
    scope.columnHeaders.forEach(columnHeader => {
      scope.columnData[columnHeader.columnName] = columnHeader.value;
    });

    scope.sectionData.forEach(sectionDataItem => {
      sectionDataItem.columnHeaders.forEach((columnHeader, columnIndex) => {
        const colName = columnHeader.columnName;
        const isInvalidColumn = ['id', 'client_id', 'office_id', 'loan_id', 'savings_account_id', 'credit_account_id'].includes(colName);
        isInvalidColumn && sectionDataItem.columnHeaders.splice(columnIndex, 1);

        switch (columnHeader.columnDisplayType) {
          case 'DATE':
            scope.formData[colName] = columnHeader.value;
            break;

          case 'DATETIME':
            scope.formData[colName] = {};
            if (!isNull(columnHeader.value)) {
              scope.formData[colName] = {
                date: dateFilter(new Date(columnHeader.value), scope.df),
                time: new Date(columnHeader.value)
              };
            }
            break;

          case 'INTEGER':
            scope.columnData[colName] = Number.parseInt(columnHeader.value, 10);
            break;

          case 'DECIMAL':
            scope.columnData[colName] = Number.parseFloat(columnHeader.value);
            break;

          case 'CODEVALUE':
            if (!isUndefined(columnHeader.value) && !isNull(columnHeader.value)) {
              const values = columnHeader.value.split(',');
              const value = values.map(value => findColumnIdForMultiSelect(columnHeader.columnValues, value));
              scope.formData[columnHeader.columnName] = {
                values: value
              };
            }
            break;

          default:
            scope.columnData[colName] = columnHeader.value;
            break;
        }

        if (columnHeader.columnCode && columnHeader.columnType !== 'multiselect') {
          if (columnHeader.columnDisplayType === 'CODEVALUE' && columnHeader.columnCode === 'FILE_UPLOAD') { scope.columnData[columnHeader.columnName] = columnHeader.value; }

          columnHeader.columnValues.forEach(columnValue => {
            if (columnHeader.value === columnValue.value) {
              if (columnHeader.columnDisplayType === 'CODELOOKUP') { scope.columnData[columnHeader.columnName] = columnValue.id; } else if (columnHeader.columnDisplayType === 'CODEVALUE') { scope.columnData[columnHeader.columnName] = columnValue.value; }
            }
          });
        }

        if (!isUndefined(sectionDataItem.parentNameAttribute)) {
          if (scope.parentColumnOptions.indexOf(sectionDataItem.parentNameAttribute) !== -1) {
            sectionDataItem.hide = true;
          }
        }
      });
    });
  };

  scope.deleteDatatableEntry = function () {
    $uibModal.open({
      templateUrl: 'deletedatatable.html',
      controller: DatatableDeleteCtrl
    });
  };
  const DatatableDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.DataTablesResource.delete(reqparams, {}, function (data) {
        let destination = '';
        if (data.loanId) {
          destination = `/viewloanaccount/${data.loanId}`;
        } else if (data.savingsId) {
          destination = `/viewsavingaccount/${data.savingsId}`;
        } else if (data.clientId) {
          destination = `/viewclient/${data.clientId}`;
        } else if (data.officeId) {
          destination = `/viewoffice/${data.officeId}`;
        } else if (scope.fromEntity === 'creditaccount') {
          destination = `/viewcreditaccount/${routeParams.cardId}`;
        }
        $uibModalInstance.close('delete');
        location.path(destination);
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.cancel = function () {
    if (routeParams.mode) {
      window.history.back();
    } else {
      route.reload();
    }
  };
  scope.onFileSelect = function (files, index, columnName) {
    const tempFile = files[0];
    const fileTemplate = {};
    fileTemplate.file = tempFile;
    fileTemplate.columnName = columnName;
    scope.files[index] = fileTemplate;
    scope.columnData[columnName] = tempFile?.name;
  };
  scope.downloadDataTableDocument = function (baseurl, id, name, type, datatableName) {
    const docUrl = `${API_VERSION}/${datatableName}/${routeParams.entityId}/documents/${id}/attachment?tenantIdentifier=${$rootScope.tenantIdentifier}`;
    const configObj = {
      method: 'GET',
      url: baseurl + docUrl,
      responseType: 'arraybuffer'
    };
    http(configObj).then(function onFulfilled (response) {
      const a = document.createElement('a');
      const windowUrl = window.URL || window.webkitURL;
      const blob = new Blob([response.data], { type, responseType: 'arraybuffer' });
      const url = windowUrl.createObjectURL(blob);
      a.href = url;
      a.download = name;
      a.click();
      windowUrl.revokeObjectURL(url);
    }).catch(function onRejection (errorResponse) {
      console.error(errorResponse);
    });
  };

  scope.submit = function () {
    this.columnData.locale = scope.optlang.code;
    this.columnData.dateFormat = scope.dateTimeFormat();
    scope.tableData = [];
    scope.fileColumns = {};
    scope.tempfiles = [];
    scope.files.forEach((file, index) => {
      scope.fileColumns[index] = file.columnName;
      scope.tempfiles.push(file);
    });

    scope.sectionData.forEach(sectionData => {
      return scope.columnHeaders.push(...sectionData.columnHeaders);
    });
    scope.columnHeaders.forEach(columnHeader => {
      const isFileUpload = columnHeader.columnCode === 'FILE_UPLOAD' && !isUndefined(columnHeader.file) && !isUndefined(columnHeader.file.id);
      const isMuliSelect = columnHeader.columnType === 'multiselect' && !isUndefined(this.formData[columnHeader.columnName]?.values);
      if (!_.contains(_.keys(this.columnData), columnHeader.columnName)) {
        this.columnData[columnHeader.columnName] = '';
      }

      if (columnHeader.columnDisplayType === 'DATE') {
        if (this.formData[columnHeader.columnName]) {
          this.columnData[columnHeader.columnName] = dateFilter(this.formData[columnHeader.columnName], this.columnData.dateFormat);
        } else {
          this.columnData[columnHeader.columnName] = '';
        }
      } else if (columnHeader.columnDisplayType === 'DATETIME') {
        if (!this.formData[columnHeader.columnName].date && !this.formData[columnHeader.columnName].time) {
          this.columnData[columnHeader.columnName] = '';
        } else {
          this.columnData[columnHeader.columnName] = `${dateFilter(this.formData[columnHeader.columnName].date, scope.df)} ${dateFilter(this.formData[columnHeader.columnName].time, scope.tf)}`;
        }
      } else if (isFileUpload) {
        this.columnData[columnHeader.columnName] = columnHeader.file.id;
      } else if (isMuliSelect) {
        this.columnData[columnHeader.columnName] = this.formData[columnHeader.columnName].values?.toString();
      }
    });
    let tempurl = '';
    if (reqparams.resourceId === undefined) { tempurl = `${$rootScope.hostUrl + API_VERSION}/datatables/${routeParams.tableName}/${routeParams.entityId}?genericResultSet=true`; } else { tempurl = `${$rootScope.hostUrl + API_VERSION}/datatables/${routeParams.tableName}/${routeParams.entityId}/${reqparams.resourceId}?genericResultSet=true`; }
    Upload.upload({
      url: tempurl,
      data: { tableData: JSON.stringify(scope.columnData), file: scope.tempfiles }
    }).then(function (data) {
      // to fix IE not refreshing the model
      let destination = '';
      if (data.data.loanId) {
        destination = `/viewloanaccount/${data.data.loanId}`;
      } else if (data.data.savingsId) {
        destination = `/viewsavingaccount/${data.data.savingsId}`;
      } else if (data.data.clientId) {
        destination = `/viewclient/${data.data.clientId}`;
      } else if (data.data.officeId) {
        destination = `/viewoffice/${data.data.officeId}`;
      } else if (scope.fromEntity === 'creditaccount') {
        destination = `/viewcreditaccount/${routeParams.cardId}`;
      }
      location.path(destination);
    });
  };
}
DataTableEntryController.$inject = ['$scope', '$location', '$routeParams', '$route', 'ResourceFactory', '$uibModal', 'dateFilter', 'API_VERSION', 'Upload', '$rootScope', '$http'];
