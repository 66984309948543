export function ClientTransferOptionsController ($scope, resourceFactory, routeParams) {
  $scope.accountId = routeParams.id;
  $scope.options = [];

  resourceFactory.savingsAccounts.getTransferOptions({ accountId: $scope.accountId }, {}, function (_data) {
    $scope.options = Object.entries(_data.transferOption);
  });

  // Methods

  /**
   * @param {string | number} _index
   * @param {boolean} _value
   */
  function updateOption (_index, _value) {
    const optionsCloned = [...$scope.options];

    const options = {
      [optionsCloned[_index][0]]: _value
    };

    /**
     * @param {object} _res
     */
    function handleUpdateTransferOptions (_res) {
      if (_res.savingsId) {
        $scope.options[_index][1] = _value;
      }
    }

    resourceFactory.savingsAccounts.updateTransferOptions({ accountId: $scope.accountId }, options, handleUpdateTransferOptions);
  }

  $scope.updateOption = updateOption;
}

ClientTransferOptionsController.$inject = ['$scope', 'ResourceFactory', '$routeParams'];
