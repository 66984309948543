export function ViewFixedDepositTransactionController ($scope, resourceFactory, location, routeParams, dateFilter) {
  $scope.flag = false;
  resourceFactory.fixedDepositTrxnsResource.get({ savingsId: routeParams.accountId, transactionId: routeParams.transactionId }, function (data) {
    $scope.transaction = data;
    if ($scope.transaction.transactionType.value === 'Transfer' || $scope.transaction.reversed === 'true') {
      $scope.flag = true;
    }
  });

  $scope.undoTransaction = function (accountId, transactionId) {
    const params = { savingsId: accountId, transactionId, command: 'undo' };
    const formData = { dateFormat: $scope.df, locale: $scope.optlang.code, transactionAmount: 0 };
    formData.transactionDate = dateFilter(new Date(), $scope.df);
    resourceFactory.fixedDepositTrxnsResource.save(params, formData, function (data) {
      location.path(`/viewfixeddepositaccount/${data.savingsId}`);
    });
  };
}

ViewFixedDepositTransactionController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams', 'dateFilter'];
