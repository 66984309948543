<template>
  <main-content-layout v-if="Object.keys(itemsInfo).length">
    <template #title>
      <card-header :item="itemsInfo" />
    </template>

    <template #actions>
      <action-buttons-container :status="itemsInfo.status">
        <component
          :is="cardButton"
          v-for="(cardButton, index) in cardButtons"
          :key="index"
          :card-item="itemsInfo"
          :card-type="cardType"
          @onStatusChanged="getCardData"
        />
      </action-buttons-container>
    </template>
    <card-table-list
      :item-info="itemsInfo"
      :field-items="fieldsItem"
    >
      <template #country="{ item }">
        <div class="d-flex gap-1">
          <span
            v-for="(blockCountries, childrenIndex) in item.value"
            :key="childrenIndex"
            class="badge bg-secondary fw-normal"
            style="font-size: 12px;"
          >
            {{ regions[blockCountries] }}
          </span>
        </div>
      </template>
    </card-table-list>
    <b-tabs
      content-class="mt-3"
      lazy
    >
      <b-tab
        v-for="(cardTab, index) in cardTabs"
        :key="index"
        :active="index === 0"
        :title="cardTab.title"
      >
        <component
          :is="cardTab.component"
          :card-item="itemsInfo"
        />
      </b-tab>
    </b-tabs>
  </main-content-layout>
</template>

<script setup>
import { ChangeStatus, Options, EditSecurity, EditLimit } from '@/components/clients/card/actions/index.js';
import { CardTableList, CardHeader, ActionButtonsContainer } from '@/components/clients/card/index.js';
import { CardAuthorization, CardHistory } from '@/components/clients/card/tabs/index.js';
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import apiService from '@/services/apiService';
import i18n from '@/plugins/i18n';
import router from '@/router';
import { onMounted, ref } from 'vue';
import { set, get } from '@vueuse/core';
import { formatPAN } from '@/helper/FormatPan';
import { verlocity } from '@/composable/verlocityLimit';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { currencyFormatter } from '@/helper/currencyHelper';
import { dateStringToFormat } from '@/helper/dateHelper';
import { regions } from '@/utils/dictionaries';

const formatterPAN = formatPAN();
const breadCrumbsStore = useBreadcrumbsStore();
const { clientId, id: cardId } = router.currentRoute.params;
const { cardType } = router.currentRoute.query;
const { setInitialLimits, formatVelocity } = verlocity();

const itemsInfo = ref({});
const fieldsItem = ref([]);
const cardButtons = [EditSecurity, EditLimit, ChangeStatus, Options];
const cardTabs = [
  { title: i18n.t('label.head.cardAuthorization'), component: CardAuthorization },
  { title: i18n.t('label.head.cardHistory'), component: CardHistory }
];

onMounted(async () => {
  await getCardData();
  breadCrumbsStore.setBreadcrumbs([
    {
      text: i18n.t('label.anchor.clients'),
      href: '#/clients'
    },
    {
      text: get(itemsInfo).clientName,
      href: `#/viewclient/${clientId}`
    },
    {
      text: `${get(itemsInfo).productName} (${get(itemsInfo).primaryAccountNumber})`
    }
  ]);
});

const getCardData = async () => {
  const { data: cardResource } = await apiService.cardsResource.get(cardId, { associations: 'cardHistory,shippingHistory' });
  const { data: savingAccountResource } = await apiService.savingAccounts.get(cardResource?.accountId, { fields: 'accountNo,summary,currency,clientId,clientName' });

  set(itemsInfo, { ...cardResource, ...savingAccountResource });

  setInitialLimits(cardResource?.velocityRules.filter(item => item.type === 'AMOUNT'));
  fieldTableData();
};

const fieldTableData = async () => {
  const {
    token, status, physicalCardActivated, shippingHistory,
    virtual, embossedNames, expiresOn, registeredOn, activatedAt,
    producedOn, shippedOn, primaryAccountNumber, physicalCardActivatedAt,
    blockedCountries, user, onlinePaymentEnabled, contactlessPaymentEnabled,
    network, currency, accountNo, summary, atmWithdrawalsEnabled, internationalPaymentsEnabled, posPaymentEnabled
  } = get(itemsInfo);

  const label = (title) => i18n.t(`label.heading.${title}`);
  const shipHistory = shippingHistory?.[shippingHistory.length - 1];
  const fieldsTable = [
    [
      {
        item: [
          { label: label('cardSystemIdentifier'), value: token },
          { label: label('status'), value: status },
          { label: label('isPhysicalCardActivated'), value: physicalCardActivated },
          { label: label('isItVirtualOnlyCard'), value: virtual },
          { label: label('embossedName'), value: embossedNames?.[0] },
          { label: label('pan'), value: formatterPAN(primaryAccountNumber) },
          { label: label('expires'), value: dateStringToFormat(expiresOn) },
          { label: label('network'), value: network },
          { label: label('currency'), value: currency.code }
        ]
      },
      {
        title: i18n.t('label.heading.cardHistory'),
        item: [
          { label: label('registeredOn'), value: dateStringToFormat(registeredOn) },
          { label: label('activatedOn'), value: dateStringToFormat(activatedAt?.date) },
          { label: label('producedOn'), value: dateStringToFormat(producedOn) },
          { label: label('shippedOn'), value: dateStringToFormat(shippedOn) },
          { label: label('physicalActivatedOn'), value: dateStringToFormat(physicalCardActivatedAt?.date) }
        ]
      },
      {
        title: i18n.t('label.heading.shippingHistory'),
        item: [
          { label: label('shippedBy'), value: shipHistory?.shippedBy },
          { label: label('trackingId'), value: shipHistory?.trackingId }
        ]
      },
      {
        title: i18n.t('label.heading.country'),
        item: [{ key: 'country', label: label('blockedCountries'), value: blockedCountries }]
      }
    ],
    [
      {
        item: [
          { label: label('accountNo'), value: accountNo },
          { label: label('balance'), value: currencyFormatter(summary.availableBalance, currency.displaySymbol) }
        ]
      },
      {
        title: i18n.t('label.heading.cardHolder'),
        item: [
          { label: label('firstname'), value: user?.firstname },
          { label: label('lastname'), value: user?.lastname },
          { label: label('userId'), value: user?.id }
        ]
      },
      {
        title: i18n.t('label.heading.security'),
        item: [
          { label: label('onlinePayment'), value: onlinePaymentEnabled ? 'ON' : 'OFF' },
          { label: label('contactlessPayment'), value: contactlessPaymentEnabled ? 'ON' : 'OFF' },
          { label: label('atmWithdrawals'), value: atmWithdrawalsEnabled ? 'ON' : 'OFF' },
          { label: label('internationalPayments'), value: internationalPaymentsEnabled ? 'ON' : 'OFF' },
          { label: label('posPayment'), value: posPaymentEnabled ? 'ON' : 'OFF' }
        ]
      },
      { title: i18n.t('label.heading.pos'), item: formatVelocity('PURCHASE', currency.displaySymbol, label) },
      { title: i18n.t('label.heading.atm'), item: formatVelocity('WITHDRAWAL', currency.displaySymbol, label) }
    ]
  ];
  set(fieldsItem, fieldsTable);
};
</script>
