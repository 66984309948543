<template>
  <div
    class="form-group pb-3 align-items-center"
    :class="{row: !vertical}"
  >
    <label
      v-if="label"
      class="control-label"
      :class="vertical ? 'mb-1' : 'text-end col-sm-4'"
      :for="id"
    >
      {{ label }}
      <span
        v-if="required"
        class="required"
      >*</span>
    </label>
    <div :class="[vertical ? 'col' : 'col-8']">
      <vue-select
        :input-id="id"
        :options="options"
        :reduce="updateReduce"
        :label="textField"
        :placeholder="placeholder"
        :disabled="disabled"
        :clearable="!notClearable"
        :multiple="multiple"
        :value="value"
        @input="updateInput"
        @search="search"
      >
        <template
          v-for="slotName in scopedSlots"
          #[slotName]="item"
        >
          <slot
            :name="slotName"
            :item="item"
          />
        </template>
      </vue-select>
    </div>
    <div
      v-if="description"
      class="text-body-tertiary"
      :class="[vertical ? 'col' : 'col-8', {'offset-sm-4': !vertical}]"
    >
      {{ description }}
    </div>
    <div
      v-if="errorMessage"
      class="text-danger"
      :class="[vertical ? 'col' : 'col-8', {'offset-sm-4': !vertical}]"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import VueSelect from 'vue-select';
import formWrapperRegister from '@/components/shared/form-input/mixin.js';
import OpenIndicator from './OpenIndicator.vue';
import CloseIndicator from './CloseIndicator.vue';

VueSelect.props.components.default = () => ({
  Deselect: CloseIndicator,
  OpenIndicator
});

export default {
  components: {
    VueSelect
  },
  mixins: [formWrapperRegister],
  props: {
    options: {
      type: Array,
      required: true
    },
    textField: {
      type: String,
      default: 'name'
    },
    valueField: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    notClearable: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String, Object, Array],
      default: null || '' || (() => {}) || (() => [])
    },
    id: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    vertical: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      scopedSlots: Object.keys(this.$scopedSlots)
    };
  },
  computed: {
    isFlexOrContents () {
      return this.multiple ? 'flex' : 'contents';
    }
  },
  created () {
    this.localRule = [
      value => {
        const isValueEmpty = (value) => value === '' || value === null || value === undefined;
        const isRequiredValid = !this.required || (this.multiple ? value?.length : isValueEmpty);
        return isRequiredValid || 'This field is required';
      }
    ];
  },
  methods: {
    updateInput (value) {
      this.validatorRunner();
      this.$emit('input', value);
    },
    updateReduce (item) {
      if (!this.valueField) return item;
      return item[this.valueField];
    },
    search (value, loading) {
      this.$emit('search', value, loading);
    }
  }
};
</script>

<style scoped>
.v-select :deep(.vs__search),
.v-select :deep(.vs__search:focus) {
  font-size: 14px;
  margin: 5px 0px;
  padding-left: 12px;
}

.v-select :deep(.vs__dropdown-toggle) {
  font-size: 14px;
  padding: 0;
}

.v-select :deep(.vs__actions) {
  padding-top: 0;
  padding-right: 10px;
}

.v-select :deep(.vs__selected) {
  margin: 5px 0 5px 5px;
  padding: 0 4px 0 6px;
  white-space: nowrap;
  overflow: hidden;
  border: none;
}

.v-select :deep(.vs__selected-options) {
  display: v-bind(isFlexOrContents);
}

.v-select :deep(.vs__clear),
.v-select :deep(.vs__open-indicator) {
  width: 0.8em;
}

.v-select :deep(.vs__clear) {
  margin-right: 6px;
  margin-bottom: 3px;
}
</style>
