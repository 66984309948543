<template>
  <span>
    <slot>
      {{ value }}
    </slot>
    <i
      v-tooltips="$t('label.anchor.' + title, {variable: variableTranslate})"
      class="fas fa-copy"
      @click="copySavingTransactionId(value)"
    />
  </span>
</template>

<script>
import { useClipboard } from '@vueuse/core';
export default ({
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    variableTranslate: {
      type: String,
      default: 'Id'
    }
  },
  data () {
    return {
      title: 'copy'
    };
  },
  methods: {
    copySavingTransactionId (value) {
      const { copy } = useClipboard();
      copy(value);
      this.title = 'copied';
      setTimeout(() => {
        this.title = 'copy';
      }, 1000);
    }
  }
});
</script>
