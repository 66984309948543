export function ViewProductMixController (scope, resourceFactory, routeParams, location, $uibModal) {
  scope.productmix = [];
  scope.choice = 0;
  scope.allowed = [];
  scope.restricted = [];
  resourceFactory.loanProductResource.getProductmix({ loanProductId: routeParams.id, resourceType: 'productmix' }, function (data) {
    scope.productmix = data;
    scope.allowedProducts = data.allowedProducts;
    scope.restrictedProducts = data.restrictedProducts;
  });

  scope.deleteProductmix = function () {
    $uibModal.open({
      templateUrl: 'deleteproductmix.html',
      controller: ProductmixDeleteCtrl
    });
  };
  const ProductmixDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.loanProductResource.delete({ loanProductId: routeParams.id, resourceType: 'productmix' }, {}, function () {
        $uibModalInstance.close('delete');
        location.path('/productmix');
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
  scope.restrict = function () {
    for (const i in this.allowed) {
      for (const j in scope.allowedProducts) {
        if (scope.allowedProducts[j].id === this.allowed[i]) {
          const temp = {};
          temp.id = this.allowed[i];
          temp.name = scope.allowedProducts[j].name;
          temp.includeInBorrowerCycle = scope.allowedProducts[j].includeInBorrowerCycle;
          scope.restrictedProducts.push(temp);
          scope.allowedProducts.splice(j, 1);
        }
      }
    }
    for (const i in this.allowed) {
      for (const j in scope.restrictedProducts) {
        if (scope.restrictedProducts[j].id === this.allowed[i]) {
          scope.allowed.splice(i, 1);
        }
      }
    }
  };
  scope.allow = function () {
    for (const i in this.restricted) {
      for (const j in scope.restrictedProducts) {
        if (scope.restrictedProducts[j].id === this.restricted[i]) {
          const temp = {};
          temp.id = this.restricted[i];
          temp.name = scope.restrictedProducts[j].name;
          temp.includeInBorrowerCycle = scope.restrictedProducts[j].includeInBorrowerCycle;
          scope.allowedProducts.push(temp);
          scope.restrictedProducts.splice(j, 1);
        }
      }
    }
    for (const i in this.restricted) {
      for (const j in scope.allowedProducts) {
        if (scope.allowedProducts[j].id === this.restricted[i]) {
          scope.restricted.splice(i, 1);
        }
      }
    }
  };
  scope.submit = function () {
    const temp = [];
    const final = {};
    for (const i in scope.restrictedProducts) {
      temp[i] = scope.restrictedProducts[i].id;
    }
    final.restrictedProducts = temp;
    resourceFactory.loanProductResource.put({ loanProductId: routeParams.id, resourceType: 'productmix' }, final, function () {
      location.path(`/viewproductmix/${routeParams.id}`);
    });
  };
}

ViewProductMixController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location', '$uibModal'];
