<template>
  <span>
    <button
      v-has-permission="'DELETE_USER'"
      class="btn btn-danger me-1"
      @click="show"
    >
      <i class="fas fa-trash me-1" />
      {{ $t('label.button.delete') }}
    </button>
    <base-modal
      id="deleteConfirmModal"
      ref="deleteConfirmModal"
      v-has-permission="'DELETE_USER'"
      @on-submit="deleteUser"
    >
      <template #header>
        <h4>{{ $t('label.heading.deleteConfirm') }}</h4>
      </template>
    </base-modal>
  </span>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import { $notify } from '@/plugins/prototypes';
import router from '@/router/index';
import apiService from '@/services/apiService';
import { ref } from 'vue';

const deleteConfirmModal = ref(null);
const { userId } = router.currentRoute.params;

const show = () => {
  deleteConfirmModal.value.show();
};

const deleteUser = () => {
  try {
    apiService.userResource.delete(userId);
    deleteConfirmModal.value.hide();
    router.push({ name: 'UserList' });
  } catch (error) {
    $notify.error(error);
  }
};
</script>
