export function ViewAMLNCRuleGroupController (scope, routeParams, resourceFactory, location) {
  const ruleId = routeParams.id;

  function loadData () {
    resourceFactory.amlNetworkConnectionsResource.get({ ruleId }, function (resAmlNcRule) {
      resourceFactory.amlNetworkConnectionsResource.get({ ruleId, action: 'limitrule' }, scope.formData, function (resLimitRule) {
        resourceFactory.amlNetworkConnectionsResource.get({ ruleId, action: 'periodlimitrule' }, scope.formData, function (resPeriodLimitRule) {
          scope.ruleGroup = resAmlNcRule;
          scope.limitRules = resLimitRule.pageItems;
          scope.periodLimitRules = resPeriodLimitRule.pageItems;
        }, handleResponseError);
      }, handleResponseError);
    }, handleResponseError);
  }

  loadData();

  // Actions on Rule Group
  scope.routeToEditRuleGroup = function (id) {
    location.path(`/payments/aml/editncrulegroup/${id}`);
  };

  // Actions on Rules
  scope.routeToViewLimitRule = function (id) {
    location.path(`/payments/aml/rulegroups/${ruleId}/nclimitrules/${id}`);
  };

  scope.routeToViewPeriodLimitRule = function (id) {
    location.path(`/payments/aml/rulegroups/${ruleId}/ncPeriodlimitrules/${id}`);
  };

  scope.routeToCreateLimitRule = function () {
    location.path(`/payments/aml/rulegroups/${ruleId}/createnclimitrule`);
  };

  scope.routeToCreatePeriodLimitRule = function () {
    location.path(`/payments/aml/rulegroups/${ruleId}/createncperiodlimitrule`);
  };

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }
}

ViewAMLNCRuleGroupController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
