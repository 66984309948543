export function AccCoaController ($scope, $rootScope, translate, resourceFactory, location) {
  let parentNode;
  $rootScope.tempNodeID = -100; // variable used to store nodeID (from directive), so it(nodeID) is available for detail-table

  $scope.coadata = [];
  $scope.isTreeView = false;

  $scope.routeTo = function (id) {
    location.path(`/viewglaccount/${id}`);
  };

  if (!$scope.searchCriteria.acoa) {
    $scope.searchCriteria.acoa = null;
    $scope.saveSC();
  }
  $scope.filterText = $scope.searchCriteria.acoa || '';

  $scope.onFilter = function () {
    $scope.searchCriteria.acoa = $scope.filterText || '';
    $scope.saveSC();
  };

  $scope.deepCopy = function deepCopy (obj) {
    if (Object.prototype.toString.call(obj) === '[object Array]') {
      const out = []; let i = 0; const len = obj.length;
      for (; i < len; i++) {
        out[i] = deepCopy(obj[i]);
      }
      return out;
    }
    if (typeof obj === 'object') {
      const out = {}; let i;
      for (i in obj) {
        out[i] = deepCopy(obj[i]);
      }
      return out;
    }
    return obj;
  };

  $scope.ChartsPerPage = 15;
  resourceFactory.accountCoaResource.getAllAccountCoas(function (data) {
    $scope.coadatas = $scope.deepCopy(data);
    $scope.ASSET = translate.instant('ASSET');
    $scope.LIABILITY = translate.instant('LIABILITY');
    $scope.EQUITY = translate.instant('EQUITY');
    $scope.INCOME = translate.instant('INCOME');
    $scope.EXPENSE = translate.instant('EXPENSE');
    $scope.Accounting = translate.instant('Accounting');

    const assetObject = { id: -1, name: $scope.ASSET, parentId: -999, children: [] };
    const liabilitiesObject = { id: -2, name: $scope.LIABILITY, parentId: -999, children: [] };
    const equitiyObject = { id: -3, name: $scope.EQUITY, parentId: -999, children: [] };
    const incomeObject = { id: -4, name: $scope.INCOME, parentId: -999, children: [] };
    const expenseObject = { id: -5, name: $scope.EXPENSE, parentId: -999, children: [] };
    const rootObject = { id: -999, name: $scope.Accounting, children: [] };
    const rootArray = [rootObject, assetObject, liabilitiesObject, equitiyObject, incomeObject, expenseObject];

    const idToNodeMap = {};
    for (const i in rootArray) {
      idToNodeMap[rootArray[i].id] = rootArray[i];
    }

    for (let i = 0; i < data.length; i += 1) {
      if (data[i].type.value === 'ASSET') {
        if (data[i].parentId === null) data[i].parentId = -1;
      } else if (data[i].type.value === 'LIABILITY') {
        if (data[i].parentId === null) data[i].parentId = -2;
      } else if (data[i].type.value === 'EQUITY') {
        if (data[i].parentId === null) data[i].parentId = -3;
      } else if (data[i].type.value === 'INCOME') {
        if (data[i].parentId === null) data[i].parentId = -4;
      } else if (data[i].type.value === 'EXPENSE') {
        if (data[i].parentId === null) data[i].parentId = -5;
      }
      delete data[i].disabled;
      delete data[i].manualEntriesAllowed;
      delete data[i].type;
      delete data[i].usage;
      delete data[i].description;
      delete data[i].nameDecorated;
      delete data[i].tagId;
      data[i].children = [];
      idToNodeMap[data[i].id] = data[i];
    }

    function sortByParentId (a, b) {
      return a.parentId - b.parentId;
    }

    data.sort(sortByParentId);
    const glAccountsArray = rootArray.concat(data);

    const root = [];
    for (let i = 0; i < glAccountsArray.length; i++) {
      const currentObj = glAccountsArray[i];
      if (typeof currentObj.parentId === 'undefined') {
        root.push(currentObj);
      } else {
        parentNode = idToNodeMap[currentObj.parentId];
        parentNode.children.push(currentObj);
        currentObj.collapsed = 'true';
      }
    }
    $scope.treedata = root;
  });
}

AccCoaController.$inject = ['$scope', '$rootScope', '$translate', 'ResourceFactory', '$location'];
