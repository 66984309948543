export function ClientChargesOverviewController ($scope, resourceFactory, location, routeParams) {
  $scope.clientId = routeParams.id;
  $scope.charges = [];
  $scope.chargesPerPage = 14;

  $scope.routeToCharge = function (chargeId) {
    location.path(`/viewclient/${$scope.clientId}/charges/${chargeId}`);
  };

  $scope.getClientChargeResultsPage = function (pageNumber) {
    resourceFactory.clientChargesResource.getCharges({
      clientId: routeParams.id,
      offset: ((pageNumber - 1) * $scope.chargesPerPage),
      limit: $scope.chargesPerPage
    }, function (data) {
      $scope.totalCharges = data.totalFilteredRecords;
      $scope.charges = data.pageItems;
    });
  };

  $scope.getClientChargeResultsPage(1);
}

ClientChargesOverviewController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams'];
