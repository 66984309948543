export const statusHandler = (status) => {
  if (typeof status === 'string') return status.toLowerCase();
  return status.code.split('.').slice(1).join('.');
};

export const generalStatusLookUp = (value) => {
  const statusLookUp = {
    active: 'statusactive',
    inactive: 'statusinactive',
    suspended: 'statussuspended',
    terminated: 'statusblocked',
    rejected: 'statusrejected',
    ordered: 'statusordered',
    created: 'statuscreated',
    renewd: 'statusrenewed',
    overpaid: 'statusoverpaid',
    approved: 'statusApproved',
    activeInactive: 'statusactiveoverdue',
    activeDormant: 'statusactiveoverdue',
    matured: 'statusmatured',
    pending: 'statuspending',
    closed: 'statusclosed',
    withdraw: 'statuswithdraw',
    applied: 'statuspending',
    true: 'statusactive',
    false: 'statusdeleted',
    'submitted.and.pending.approval': 'statuspending',
    'transfer.in.progress': 'statustransferprogress',
    'transfer.on.hold': 'statustransferonhold',
    'withdrawn.by.applicant': 'statuswithdraw',
    'loanproduct.active': 'statusactive',
    'loanproduct.inactive': 'statusinactive'
  };
  return statusLookUp[statusHandler(value)];
};
