<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.heading.reversalCharge') }}
    </template>
    <reversal-charge-form />
  </main-content-layout>
</template>

<script setup>
import ReversalChargeForm from '@/components/clients/ReversalChargeForm.vue';
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import i18n from '@/plugins/i18n';
import router from '@/router';
import { onMounted } from 'vue';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';

const breadCrumbsStore = useBreadcrumbsStore();
const { creditAccountId } = router.currentRoute.params;

onMounted(() => {
  breadCrumbsStore.setBreadcrumbs([
    { text: i18n.t('label.anchor.viewCreditAccount'), href: `#/viewcreditaccount/${creditAccountId}` },
    { text: i18n.t('label.anchor.reversalCharge') }
  ]);
});
</script>
