<template>
  <b-modal
    id="tx-return-wire-transfer-modal"
    hide-header-close
    title="Return WIRE Transfer"
    hide-footer
    @show="parse"
    @hide="formData = null"
  >
    <form-wrapper
      v-if="formData"
      ref="form"
      class="form"
      @submit="submit"
    >
      <input-text
        v-model="formData.errorCode"
        :label="$t('label.search.returnCode')"
        vertical
        required
      />
      <small class="text-muted">
        <i>Return code should contain at most 5 characters.</i>
      </small>

      <input-text
        v-model="formData.errorMessage"
        class="pt-3"
        :label="$t('label.search.returnMessage')"
        type="textarea"
        vertical
      />

      <div v-if="isSubledgerEvolve">
        <h4 class="mt-2">
          Originator Address
        </h4>
        <input-text
          v-for="formField in textFields"
          :id="formField.key"
          :key="formField.key"
          v-model="formData.originatorAddress[formField.key]"
          :label="$t(`label.input.${formField.label}`)"
          :required="formField.requried"
          :disabled="formField.disabled"
          vertical
        />

        <input-select
          id="country"
          v-model="selectedCountry"
          searchable
          :label="$t('label.input.country')"
          :options="templates.countryIdOptions"
          placeholder="Select Country"
          vertical
          required
          @input="selectCountry"
        />
        <input-select
          id="state-or-province"
          v-model="formData.originatorAddress.stateOrProvince"
          searchable
          :label="$t('label.input.stateOrProvince')"
          :options="stateFilter"
          value-field="name"
          placeholder="Select State"
          vertical
          required
        />
        <input-text
          v-model="formData.originatorAddress.postalCode"
          :label="$t('label.input.zipPostalCode')"
          vertical
          required
        />
      </div>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-default"
          @click="$bvModal.hide('tx-return-wire-transfer-modal');"
        >
          {{ $t('label.button.cancel') }}
        </button>
        <button
          type="submit"
          class="btn btn-primary"
        >
          {{ $t('label.button.confirm') }}
        </button>
      </div>
    </form-wrapper>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import apiService from '@/services/apiService';
import { useTxMonitoringStore } from '@/stores/txmonitoring';
import { InputText, FormWrapper, InputSelect } from '@/components/shared/form-input';
import { useSharedStore } from '@/stores/shared';

export default {
  name: 'TxConfirmPendingReturnModal',
  components: {
    InputText,
    InputSelect,
    FormWrapper
  },
  data () {
    return {
      formData: null,
      textFields: [
        { label: 'addressLineOne', key: 'addressLine1', requried: true },
        { label: 'addressLineTwo', key: 'addressLine2' },
        { label: 'city', key: 'city', requried: true }
      ],
      templates: { addressTypeIdOptions: [], countryIdOptions: [], stateProvinceIdOptions: [] },
      selectedCountry: null,
      stateFilter: []
    };
  },
  computed: {
    ...mapState(useTxMonitoringStore, ['transaction', 'paymentType']),
    isSubledgerEvolve () {
      return process.env.VUE_APP_SUBLEDGER === 'evolve';
    }
  },
  created: async function () {
    const data = await this.getAddressTemplate();
    Object.assign(this.templates, data);
  },
  methods: {
    selectCountry (selectedCountry) {
      this.formData.originatorAddress.country = selectedCountry.name;
      this.stateFilter = this.templates?.stateProvinceIdOptions.filter(value => value.parentId === selectedCountry?.id);
    },
    ...mapActions(useTxMonitoringStore, ['setNeedsToReloadList']),
    ...mapActions(useSharedStore, ['getAddressTemplate']),
    parse () {
      this.formData = {
        errorCode: this.transaction?.errorCode,
        errorMessage: this.transaction?.errorMessage
      };
      const originatorAddress = {
        addressLine1: '',
        addressLine2: '',
        country: '',
        stateOrProvince: '',
        postalCode: '',
        city: ''
      };

      if (this.transaction?.debtor?.address && this.transaction?.debtor?.address[0]) {
        originatorAddress.addressLine1 = this.transaction?.debtor?.address[0];
        this.textFields[0].disabled = true;
      }
      if (this.isSubledgerEvolve) this.formData = { ...this.formData, originatorAddress };
    },
    async submit () {
      if (!this.formData.errorCode) return;

      const { city, stateOrProvince, postalCode } = this.formData.originatorAddress;
      this.formData.originatorAddress.addressLine1 =
      this.formData.originatorAddress.addressLine1.slice(0, -(city.length + stateOrProvince.length + postalCode.length));
      const data = {
        ...this.formData,
        id: this.transaction.id,
        paymentType: this.paymentType
      };

      try {
        await apiService.transfer.returnTransfers(data);
        this.$bvModal.hide('tx-return-wire-transfer-modal');
        this.setNeedsToReloadList(true);
      } catch (e) {
        this.$notify.error(e);
      }
    }
  }
};
</script>
