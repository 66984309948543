<template>
  <form
    ref="formWrapper"
    class="form-provider"
    @submit.prevent="validate"
  >
    <fieldset :disabled="loading">
      <slot />
    </fieldset>
  </form>
</template>

<script>
import { useSharedStore } from '@/stores/shared';
import { mapState } from 'pinia';
export default {
  name: 'FormProvider',
  provide () {
    return {
      // Arrow function because we need to access the this instance
      register: (component) => {
        this.fields.push(component);
      },
      unregister: (component) => {
        this.fields = this.fields.filter(field => field.id !== component.id);
      }
    };
  },
  data () {
    return {
      fields: []
    };
  },
  computed: {
    ...mapState(useSharedStore, ['loading'])
  },
  methods: {
    reset () {
      this.$refs.formWrapper?.reset();
    },
    async validate (event) {
      const errors = [];
      for (const field of this.fields) {
        const validator = await field.validatorRunner();
        if (validator.length && !field._isDestroyed) {
          errors.push('error');
        }
      }
      if (errors.length > 0) {
        return 'errors';
      }

      if (!event) return;
      this.$emit('submit', event);
    }
  }
};
</script>
