<template>
  <div>
    <div
      v-for="(meta, index) in metaData"
      :key="index"
      class="row mb-4"
    >
      <div class="col-sm-4 col-md-4">
        <input-text
          :id="`key-${index}`"
          v-model="meta.key"
          :label="$t('label.input.metaDataKey')"
          vertical
        />
      </div>
      <div class="col-sm-4 col-md-4">
        <input-text
          :id="`value-${index}`"
          v-model="meta.value"
          :label="$t('label.input.metaDataValue')"
          vertical
        />
      </div>
      <div class="col-sm-2 col-md-2 d-flex align-items-center">
        <button
          v-if="index <= 0"
          class="btn"
          @click.prevent="addMetaData()"
        >
          <i class="fas fa-plus " />
        </button>
        <button
          v-else
          class="btn"
          @click.prevent="removeMetaData(index)"
        >
          <i class="fas fa-minus" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { InputText } from '../shared/form-input';
import { toRefs } from 'vue';
import { get } from '@vueuse/core';

const props = defineProps({
  formMetaData: {
    type: Array,
    required: true
  }
});

const { formMetaData: metaData } = toRefs(props);

const addMetaData = () => {
  get(metaData).push({ key: '', value: '' });
};

const removeMetaData = (index) => {
  get(metaData).splice(index, 1);
};
</script>
