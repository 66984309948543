<template>
  <div>
    <button
      class="btn btn-primary"
      :disabled="loading"
      @click="actionPrompt()"
    >
      {{ $t('label.button.returnAll') }}
    </button>
    <base-modal
      ref="promptReturnPendingModal"
      @on-submit="returnAllPendingTx"
    >
      <template #header>
        <h5 class="modal-title">
          {{ $t('label.heading.returnAllPendingReturnAchConfirmation') }}
        </h5>
      </template>
      <template #body>
        {{ $t('label.heading.confirmReturnAllPendingACH') }}
      </template>
    </base-modal>
  </div>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import { ref, computed } from 'vue';
import { useTxMonitoringStore } from '@/stores/txmonitoring';
import { useSharedStore } from '@/stores/shared';
import { $notify } from '@/plugins/prototypes';
import apiService from '@/services/apiService';
import { get } from '@vueuse/core';

const txMonitoringStore = useTxMonitoringStore();
const sharedStore = useSharedStore();

const loading = computed(() => sharedStore.loading);

const promptReturnPendingModal = ref(null);
const actionPrompt = () => {
  get(promptReturnPendingModal).show();
};

const returnAllPendingTx = async () => {
  const paymentType = txMonitoringStore.paymentType;
  if (!paymentType) return;
  try {
    await apiService.transfer.returnTransfers({ isMarkACHReturnAll: true, paymentType });
    get(promptReturnPendingModal).hide();
    txMonitoringStore.setNeedsToReloadList(true);
  } catch (e) {
    $notify.error(e);
  }
};

</script>
