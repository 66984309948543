export function CreateNameMatchingController (scope, resourceFactory, location) {
  scope.currencies = null;
  scope.errorMsg = '';

  function loadCurrencies () {
    resourceFactory.currenciesResource.get(
      { fields: 'selectedCurrencyOptions' },
      function (res) {
        scope.currencies = res.selectedCurrencyOptions;
      }
    );
  }

  loadCurrencies();

  scope.submit = function () {
    resourceFactory.amlNameMatchsResource.save(
      scope.formData,
      function (res) {
        const { resourceId } = res;
        location.path(`/payments/aml/namematchingrule/${resourceId}`);
      },
      handleResponseError
    );
  };

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
}

CreateNameMatchingController.$inject = ['$scope', 'ResourceFactory', '$location'];
