<template>
  <div>
    <button
      v-has-permission="'READ_AGING_BUCKET'"
      class="btn btn-primary me-1"
      @click.prevent="showModal"
    >
      {{ $t('label.button.manageAgingBucket') }}
    </button>
    <base-modal
      id="manageAgingBucket"
      ref="thisModal"
      modal-size="lg"
      hide-footer
    >
      <template #header>
        <h4>{{ $t('label.heading.manageAgingBucket') }}</h4>
      </template>
      <template #body>
        <add-or-edit-aging-bucket
          v-if="addOrEditForm"
          :form-data="formData"
          :form-type="formType"
          @on-cancel="onCancel"
        />
        <div v-if="!addOrEditForm">
          <button
            v-has-permission="'CREATE_AGING_BUCKET'"
            class="btn btn-primary mb-2"
            @click.prevent="addOrEdit()"
          >
            {{ $t('label.button.create') }}
          </button>
          <data-grid
            ref="bucketTable"
            :items="agingBuckets"
            :fields="fields"
            hide-entries-per-page
          >
            <template #actions="{item}">
              <button
                v-has-permission="'UPDATE_AGING_BUCKET'"
                class="btn btn-primary me-1"
                @click.prevent="addOrEdit(item)"
              >
                <span class="fa fa-edit" />
              </button>
              <button
                v-has-permission="'DELETE_AGING_BUCKET'"
                class="btn btn-danger"
                :disabled="loading"
                @click.prevent="remove(item)"
              >
                <span class="fa fa-trash" />
              </button>
            </template>
          </data-grid>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import DataGrid from '@/components/shared/data-grid/index.vue';
import { ref, reactive } from 'vue';
import apiService from '@/services/apiService';
import i18n from '@/plugins/i18n';
import addOrEditAgingBucket from '@/components/system/transfer-limits/manageAgingBucket/addOrEdit.vue';
import { $notify } from '@/plugins/prototypes';

const formData = reactive({});
const formType = ref();
const fields = ref([
  { key: 'id', label: i18n.t('label.heading.id') },
  { key: 'name', label: i18n.t('label.heading.name') },
  { key: 'fromDaysPastDue', label: i18n.t('label.heading.fromDaysPastDue') },
  { key: 'toDayPastDue', label: i18n.t('label.heading.toDayPastDue') },
  { key: 'actions', label: i18n.t('label.heading.actions') }
]);
const addOrEditForm = ref(false);
const loading = ref(false);

const agingBuckets = ref([]);

const addOrEdit = (item) => {
  if (item) {
    Object.assign(formData, item);
    formType.value = 'edit';
  } else {
    formType.value = 'create';
  }
  addOrEditForm.value = true;
};

const bucketTable = ref();
const getAgingBucket = async () => {
  bucketTable.value?.toggleLoading(true);
  try {
    const { data } = await apiService.agingBucketResource.get();
    agingBuckets.value = data.pageItems;
  } catch (error) {
    $notify.error(error);
  } finally {
    bucketTable.value?.toggleLoading(false);
  }
};

const remove = async (item) => {
  loading.value = true;
  try {
    await apiService.agingBucketResource.delete(item.id);
    getAgingBucket();
  } catch (e) {
    $notify.error(e);
  } finally {
    loading.value = false;
  }
};
const onCancel = (command) => {
  addOrEditForm.value = false;
  formData.name = '';
  formData.fromDaysPastDue = '';
  formData.toDayPastDue = '';
  if (command === 'reload') {
    getAgingBucket();
  }
};

const thisModal = ref(null);
const showModal = () => {
  getAgingBucket();
  thisModal.value.show();
};
</script>
