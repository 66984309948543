import { isString } from 'underscore';
export function formatPAN () {
  return (_input, _hidePan) => {
    if (!_input) return '';

    if (_hidePan) {
      if (_input.length === 4) {
        return _input.padStart(16, '\u2022').replace(/(.{4})/g, '$1 ');
      }
      return _input.slice(-4).padStart(_input.length, '\u2022').replace(/(.{4})/g, '$1 ');
    }

    if (isString(_input)) {
      return _input.padStart(_input.length, '\u2022').replace(/(.{4})/g, '$1 ');
    }
  };
}
