export function ClientEditDocumentController ($scope, routeParams, API_VERSION, Upload, $rootScope, resourceFactory) {
  $scope.clientId = routeParams.clientId;
  $scope.documentId = routeParams.documentId;

  $scope.primaryAccountId = routeParams.primaryAccountId;

  resourceFactory.clientResource.get({ clientId: $scope.clientId }, function (data) {
    $scope.displaySubAccountClientName = data.displayName;
  });

  resourceFactory.clientDocumentsResource.getClientDocument(
    { clientId: $scope.clientId, documentId: $scope.documentId },
    function (data) {
      $scope.formData = updateFormData(data);
    }
  );

  $scope.onFileSelect = function (files) {
    $scope.formData.file = files[0];
    $scope.formData.fileName = $scope.formData.file.name;
  };

  $scope.loading = false;
  $scope.submit = function () {
    $scope.loading = true;
    Upload.upload({
      url: `${$rootScope.hostUrl + API_VERSION}/clients/${$scope.clientId}/documents/${$scope.documentId}`,
      method: 'PUT',
      data: { name: $scope.formData.name, description: $scope.formData.description, file: $scope.formData.file, type: $scope.formData.type }
    }).then(function () {
      // to fix IE not refreshing the model
      if (!$scope.$$phase) {
        $scope.$apply();
      }
      $scope.back();
    }).finally(() => {
      $scope.loading = false;
    });
  };

  $scope.back = () => {
    history.back();
  };
}

function updateFormData (data) {
  const formattedData = {
    id: data.id,
    name: data.name,
    fileName: data.fileName,
    type: data.type,
    description: data.description,
    userName: data.userName
  };
  return formattedData;
}

ClientEditDocumentController.$inject = ['$scope', '$routeParams', 'API_VERSION', 'Upload', '$rootScope', 'ResourceFactory'];
