import { paymentRailsValueExcludes } from '@/utils/dictionaries.js';
export function UpdateTransferClientLimitsController ($scope, resourceFactory, routeParams, location) {
  $scope.client = {};
  $scope.clients = {};
  $scope.formData = {};
  $scope.transferTypes = ['DEBIT', 'CREDIT'];
  $scope.durationTypes = ['DAILY', 'WEEKLY', 'MONTHLY'];
  $scope.paymentTypeNames = paymentRailsValueExcludes(['ALL', 'SWIFT']);

  resourceFactory.clientResource.get({ clientId: routeParams.id }, function (data) {
    $scope.client = data;
    $scope.clients = [{ ...data }];
    $scope.formData.clientId = data.id;
  });

  resourceFactory.transferClientLimitsResource.get({ transferLimitsId: routeParams.limitId }, function (data) {
    $scope.formData = data;
  });

  $scope.submit = function () {
    resourceFactory.transferClientLimitsResource.update({ transferLimitsId: routeParams.limitId }, { ...$scope.formData, locale: 'en' }, function () {
      location.path(`/viewclient/${routeParams.id}`);
    });
  };
}

UpdateTransferClientLimitsController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location'];
