<template>
  <div>
    <h4>{{ $t(`label.heading.${type}`) }}</h4>
    <p>
      <span v-if="latestHistory?.length">
        {{ $t('text.lastAchSubmmitted', { date: $d(new Date(latestHistory.processingDate), 'long') }) }}
      </span>
      <button
        class="btn btn-link ps-0"
        @click="viewHistory"
      >
        {{ $t('label.button.viewHistory') }}
      </button>
    </p>

    <p>{{ $t('text.serverDatetime', { date: cutOffTime }) }}</p>

    <hr>

    <div class="row">
      <div class="col-sm-12">
        <data-grid
          :items="achProcessingPendingTableData"
          :fields="achProcessingPendingTableField"
          hide-entries-per-page
        />
      </div>

      <div class="col-sm-12 text-end">
        <button
          class="btn btn-primary"
          :disabled="loading || !isSubmitEnable"
          @click="submit"
        >
          {{ loading ? $t('label.button.loading') : $t('label.button.submit') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import DataGrid from '@/components/shared/data-grid/index.vue';
import { ref, computed } from 'vue';
import { useSharedStore } from '@/stores/shared';
import i18n from '@/plugins/i18n';
import router from '@/router/index';

const sharedStore = useSharedStore();

const loading = computed(() => sharedStore.loading);
const props = defineProps({
  type: {
    type: String,
    default: 'achProcessing'
  },
  latestHistory: {
    type: Object,
    default: null
  },
  achProcessingPendingTableData: {
    type: Array,
    default: () => []
  },
  isSubmitEnable: {
    type: Boolean,
    default: false
  },
  cutOffTime: {
    type: String,
    default: ''
  }
});

const achProcessingPendingTableField = ref([
  { key: 'totalCreditCount', label: i18n.t('label.head.totalCreditCount') },
  { key: 'totalCreditAmount', label: i18n.t('label.head.totalCreditAmount') },
  { key: 'totalDebitCount', label: i18n.t('label.head.totalDebitCount') },
  { key: 'totalDebitAmount', label: i18n.t('label.head.totalDebitAmount') }
]);

const emit = defineEmits(['on-submit']);
const submit = async () => {
  emit('on-submit');
};

const viewHistory = () => {
  const query = props.type === 'sameDayAchProcessing' ? { paymentType: 'SAMEDAYACH' } : '';
  router.push({ name: 'AchProcessingHistory', query });
};
</script>
