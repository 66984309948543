<template>
  <div class="pb-3">
    {{ label }}
  </div>
</template>

<script setup>
defineProps({
  label: {
    type: String,
    default: ''
  }
});
</script>
