import CreateUser from '@/pages/users/create.vue';
import EditUser from '@/pages/users/edit.vue';
import ViewUser from '@/pages/users/view.vue';
import UserList from '@/pages/users/index.vue';

export const usersRoutes = [
  {
    path: '/users/:userId(\\d+)',
    name: 'ViewUser',
    component: ViewUser
  },
  {
    path: '/users/create',
    name: 'CreateUser',
    component: CreateUser
  },
  {
    path: '/users/:userId/edit',
    name: 'EditUser',
    component: EditUser
  },
  {
    path: '/users',
    name: 'UserList',
    component: UserList
  }
];
