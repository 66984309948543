export function FloatingRatesController ($scope, resourceFactory, location) {
  $scope.floatingrates = [];

  $scope.routeTo = function (floatingRateId) {
    location.path(`/viewfloatingrate/${floatingRateId}`);
  };

  resourceFactory.floatingrates.getAll(function (data) {
    $scope.floatingrates = data;
  });
}

FloatingRatesController.$inject = ['$scope', 'ResourceFactory', '$location'];
