<template>
  <div>
    <button
      class="btn btn-primary"
      @click="openDeleteModal"
    >
      <i class="fa fa-trash-alt" />
    </button>
    <base-modal
      ref="deleteChargeClassification"
      @on-submit="onSubmit"
    >
      <template #header>
        <h4>{{ $t('messages.areYouSureYouWantToDelete') }}</h4>
      </template>
    </base-modal>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { get } from '@vueuse/core';
import { $notify } from '@/plugins/prototypes';
import BaseModal from '@/components/shared/BaseModal.vue';
import apiService from '@/services/apiService';
import i18n from '@/plugins/i18n';

const deleteChargeClassification = ref('');
const emits = defineEmits(['on-success']);
const props = defineProps({
  savingChargeClassificationId: {
    type: [String, Number],
    default: ''
  }
});
const openDeleteModal = () => get(deleteChargeClassification).show();
const onSubmit = async () => {
  try {
    await apiService.savingChargeClassification.delete(props.savingChargeClassificationId);
    $notify.success(i18n.t('label.deleteChargeSuccess'));
    emits('on-success');
    get(deleteChargeClassification).hide();
  } catch (error) {
    $notify.error(error);
  }
};
</script>
