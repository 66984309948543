<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.anchor.bulkImport') }}
    </template>
    <div class="container-fluid pe-0">
      <list-view :items-list="itemsList" />
    </div>
  </main-content-layout>
</template>

<script>
import ListView from '@/components/shared/ListView.vue';
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { mapActions } from 'pinia';

export default {
  components: {
    ListView,
    MainContentLayout
  },
  data () {
    return {
      itemsList: [
        {
          icon: 'fa-building',
          title: 'label.anchor.importoffices',
          href: '#/bulkimportoffices',
          permission: 'READ_OFFICE'
        },
        {
          icon: 'fa-user-plus',
          title: 'label.anchor.importusers',
          href: '#/bulkimportusers',
          permission: 'READ_OFFICE'
        },
        {
          icon: 'fa-money-bill-alt',
          title: 'label.anchor.importloanaccounts',
          href: '#/bulkimportloanaccounts',
          permission: 'READ_OFFICE'
        },
        {
          icon: 'fa-money-bill-alt',
          title: 'label.anchor.importsavingsaccounts',
          href: '#/bulkimportsavingsaccounts',
          permission: 'READ_OFFICE'
        },
        {
          icon: 'fa-money-bill-alt',
          title: 'label.anchor.importfdaccounts',
          href: '#/bulkimportfixeddepositaccounts',
          permission: 'READ_OFFICE'
        },
        {
          icon: 'fa-money-bill-alt',
          title: 'label.anchor.importrdaccounts',
          href: '#/bulkimportrecurringdeposits',
          permission: 'READ_OFFICE'
        },
        {
          icon: 'fa-money-bill-alt',
          title: 'label.anchor.importcoa',
          href: '#/bulkimportcoa',
          permission: 'READ_OFFICE'
        },
        {
          icon: 'fa-arrow-right',
          title: 'label.anchor.importshareaccounts',
          href: '#/bulkimportshareaccounts',
          permission: 'READ_OFFICE'
        },
        {
          icon: 'fa-user',
          title: 'label.anchor.employees',
          href: '#/bulkimportemployees',
          permission: 'READ_OFFICE'
        },
        {
          icon: 'fa-user',
          title: 'label.anchor.importclients',
          href: '#/organization/bulk-import/clients',
          permission: 'READ_OFFICE'
        },
        {
          icon: 'fa-user',
          title: 'label.anchor.importcenters',
          href: '#/bulkimportcenters',
          permission: 'READ_OFFICE'
        },
        {
          icon: 'fa-arrow-right',
          title: 'label.anchor.importloanrepayments',
          href: '#/bulkimportloanrepayments',
          permission: 'READ_OFFICE'
        },
        {
          icon: 'fa-arrow-right',
          title: 'label.anchor.importsavingstransactions',
          href: '#/bulkimportsavingsaccountstransactions',
          permission: 'READ_OFFICE'
        },
        {
          icon: 'fa-arrow-right',
          title: 'label.anchor.importrdtransactions',
          href: '#/bulkimportrecurringdepositstransactions',
          permission: 'READ_OFFICE'
        },
        {
          icon: 'fa-arrow-right',
          title: 'label.anchor.importjournalentries',
          href: '#/bulkimportjournalentries',
          permission: 'READ_OFFICE'
        },
        {
          icon: 'fa-arrow-right',
          title: 'label.anchor.importguarantor',
          href: '#/bulkimportguarantor',
          permission: 'READ_OFFICE'
        }
      ]
    };
  },
  created () {
    this.setBreadcrumbs([
      { text: this.$t('label.anchor.organization'), href: '#/organization' },
      { text: this.$t('label.anchor.bulkimport') }
    ]);
  },
  methods: {
    ...mapActions(useBreadcrumbsStore, ['setBreadcrumbs'])
  }
};
</script>
