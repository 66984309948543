export function EditTaxComponentController ($scope, resourceFactory, routeParams, location, dateFilter) {
  $scope.start = {};
  $scope.start.date = new Date();
  $scope.restrictDate = new Date('2099-12-31');
  $scope.formData = {};
  resourceFactory.taxcomponent.get({ taxComponentId: routeParams.taxComponentId, template: 'true' }, function (data) {
    $scope.taxComponent = data;
    if (data.startDate) {
      $scope.start.date = new Date(data.startDate);
    }
    $scope.formData = {
      name: $scope.taxComponent.name,
      percentage: $scope.taxComponent.percentage
    };
  });

  $scope.submit = function () {
    this.formData.locale = $scope.optlang.code;
    const reqDate = dateFilter($scope.start.date, $scope.df);
    this.formData.dateFormat = $scope.df;
    this.formData.startDate = reqDate;
    resourceFactory.taxcomponent.put({ taxComponentId: routeParams.taxComponentId }, this.formData, function (data) {
      location.path(`/viewtaxcomponent/${data.resourceId}`);
    });
  };
}

EditTaxComponentController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location', 'dateFilter'];
