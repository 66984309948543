<template>
  <span>
    <button
      v-has-permission="'UPDATE_USER'"
      class="btn btn-primary"
      @click="()=> {$refs['managePasswordModal'].show()}"
    >
      <i class="fas fa-gear" />
      {{ $t('label.button.manageUserPassword') }}
    </button>
    <base-modal
      id="managePasswordModal"
      ref="managePasswordModal"
      v-has-permission="'UPDATE_USER'"
      modal-size="md"
      hide-footer
    >
      <template #header>
        <h4>{{ $t('label.heading.manageUserPassword') }}</h4>
      </template>
      <template #body>
        <div class="row">
          <h4 class="bolder">
            {{ $t('label.heading.resetToTemporaryPassword') }}
          </h4>
          <p>{{ $t('messages.resetToTemporaryPassword') }}</p>
          <div>
            <button
              class="btn btn-primary float-end"
              :disabled="loading"
              @click="resetToTemporaryPassword"
            >
              {{ $t('label.button.requestNewTemporaryPassword') }}
            </button>
          </div>
        </div>
        <hr>
        <form-wrapper
          ref="changePasswordForm"
          name="changePasswordForm"
          @submit="changePassword"
        >
          <h4 class="bolder pb-2">
            {{ $t('label.heading.changePassword') }}
          </h4>
          <input-text
            id="password"
            v-model="formData.password"
            vertical
            type="password"
            required
            minlength="6"
            :label="$t('label.input.newPassword')"
          />
          <input-text
            id="repeatPassword"
            v-model="formData.repeatPassword"
            vertical
            type="password"
            required
            minlength="6"
            :label="$t('label.input.repeatPassword')"
          />

          <div class="modal-footer p-0 pt-2">
            <button
              class="btn btn-default"
              type="reset"
              @click="cancel"
            >
              {{ $t('label.button.cancel') }}
            </button>
            <button
              class="btn btn-primary"
              :disabled="loading"
              type="submit"
            >
              {{ $t('label.button.saveNewPassword') }}
            </button>
          </div>
        </form-wrapper>
      </template>
    </base-modal>
  </span>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import { FormWrapper, InputText } from '@/components/shared/form-input/index.js';
import { ref, computed } from 'vue';
import { useSharedStore } from '@/stores/shared';
import router from '@/router/index';
import apiService from '@/services/apiService';
import { $notify } from '@/plugins/prototypes';
import { useUserStore } from '@/stores/user';

const sharedStore = useSharedStore();
const userStore = useUserStore();

const loading = computed(() => sharedStore.loading);
const managePasswordModal = ref(null);
const formData = ref({
  password: '',
  repeatPassword: ''
});
const { userId } = router.currentRoute.params;
const changePasswordForm = ref(null);

const cancel = () => {
  managePasswordModal.value.hide();
};

const resetToTemporaryPassword = async () => {
  try {
    await apiService.userResource.resetPassword(userId);

    managePasswordModal.value.hide();
  } catch (error) {
    $notify.error(error);
  }
};

const changePassword = async () => {
  try {
    const { data } = await apiService.userResource.update(userId, formData.value);
    if (data.resourceId === userStore.userDetails?.userId) {
      userStore.logout();
    }
    managePasswordModal.value.hide();
    changePasswordForm.value.reset();
  } catch (error) {
    $notify.error(error);
  }
};
</script>
