export function IncomingWireTransactionController ($scope, $rootScope, resourceFactory, $uibModal, location, routeParams) {
  let statementDescription = 'WIRE IN';
  $scope.fromAccountId = routeParams.accountId;

  $scope.formData = {};

  $scope.isIntermediaryFiFieldsRequired = false;
  $scope.isIntermediaryFiIdentifierAndBeneficiaryFiRequired = false;
  $scope.formErrors = {};
  $scope.countryOptions = [];
  $scope.stateOptions = [];
  let stateProvinceIdOptions = [];
  $scope.zipMaxLen = 5;

  // Check if the country field is filled.
  const isCountryOptionFilled = false;

  resourceFactory.clientaddressFields.get(function (data) {
    $scope.addressTypes = data.addressTypeIdOptions;
    $scope.countryOptions = sortedCountry(data.countryIdOptions);

    // Check if the option form has been selected as Domestic WIRE or SWIFT or not
    // If it does not fill the countries selection here
    // If it has been filled will not fill this again
    if (!isCountryOptionFilled) $scope.countries = [...$scope.countryOptions];
    stateProvinceIdOptions = data.stateProvinceIdOptions;
  });

  resourceFactory.clientAddress.query({ clientId: routeParams.clientId }, function (data) {
    $scope.originatorAddress = data;
    const primaryAddress = $scope.originatorAddress.find(a => a.addressType === 'PRIMARY');
    if (primaryAddress.minifiedAddress) {
      $scope.formData.beneficiaryAddressLineOne = primaryAddress.minifiedAddress[0];
      $scope.formData.beneficiaryAddressLineTwo = primaryAddress.minifiedAddress[1];
      $scope.formData.beneficiaryAddressLineThree = primaryAddress.minifiedAddress[2];
    }
  });

  $scope.businessFuncCodes = [
    { code: 'BTR', label: 'BTR - Bank Transfer(Beneficiary is a bank)' },
    { code: 'CTR', label: 'CTR - Customer Transfer(Beneficiary is not bank)' },
    { code: 'CKS', label: 'CKS - Check Same Day Settlements' },
    { code: 'CTP', label: 'CTP - Customer Transfer Plus' },
    { code: 'DEP', label: `DEP - Deposit to Sender's Account` },
    { code: 'DRB', label: 'DRB - Bank to Bank Drawdown Request' },
    { code: 'DRC', label: 'DRC - Customer or Corporate Drawdown Request' },
    { code: 'DRW', label: 'DRW - Drawdown Payment' },
    { code: 'FFR', label: 'FFR - Fed Funds Returned' },
    { code: 'FFS', label: 'FFS - Fed Funds Sold' },
    { code: 'SVC', label: 'SVC - Service Message' }
  ];

  $scope.wireTypes = [
    { typeSubTypeCode: '1000', typeSubTypeLabel: '1000 - Fund Transfer/ Basic Fund Transfer' },
    { typeSubTypeCode: '1001', typeSubTypeLabel: '1001 - Fund Transfer/ Request for Reversal' },
    { typeSubTypeCode: '1002', typeSubTypeLabel: '1002 - Fund Transfer/ Reversal of Transfer' },
    { typeSubTypeCode: '1007', typeSubTypeLabel: '1007 - Fund Transfer/ Request for Reversal of a Prior day Transfer' },
    { typeSubTypeCode: '1008', typeSubTypeLabel: '1008 - Fund Transfer/ Reversal of a Prior day Transfer' },
    { typeSubTypeCode: '1031', typeSubTypeLabel: '1031 - Fund Transfer/ Request for Credit(Drawdown)' },
    {
      typeSubTypeCode: '1032',
      typeSubTypeLabel: '1032 - Fund Transfer/ Funds Transfer Honoring a Request for Credit(Drawdown)'
    },
    {
      typeSubTypeCode: '1033',
      typeSubTypeLabel: '1033 - Fund Transfer/ Refusal to Honor a Request for Credit(Drawdown)'
    },
    { typeSubTypeCode: '1090', typeSubTypeLabel: '1090 - Fund Transfer/ Service Message' },

    { typeSubTypeCode: '1500', typeSubTypeLabel: '1500 - Foreign Transfer/ Basic Fund Transfer' },
    { typeSubTypeCode: '1501', typeSubTypeLabel: '1501 - Foreign Transfer/ Request for Reversal' },
    { typeSubTypeCode: '1502', typeSubTypeLabel: '1502 - Foreign Transfer/ Reversal of Transfer' },
    {
      typeSubTypeCode: '1507',
      typeSubTypeLabel: '1507 - Foreign Transfer/ Request for Reversal of a Prior day Transfer'
    },
    { typeSubTypeCode: '1508', typeSubTypeLabel: '1508 - Foreign Transfer/ Reversal of a Prior day Transfer' },
    { typeSubTypeCode: '1531', typeSubTypeLabel: '1531 - Foreign Transfer/ Request for Credit(Drawdown)' },
    {
      typeSubTypeCode: '1532',
      typeSubTypeLabel: '1532 - Foreign Transfer/ Funds Transfer Honoring a Request for Credit(Drawdown)'
    },
    {
      typeSubTypeCode: '1533',
      typeSubTypeLabel: '1533 - Foreign Transfer/ Refusal to Honor a Request for Credit(Drawdown)'
    },
    { typeSubTypeCode: '1590', typeSubTypeLabel: '1590 - Foreign Transfer/ Service Message' },

    { typeSubTypeCode: '1600', typeSubTypeLabel: '1600 - Settlement Transfer/ Basic Fund Transfer' },
    { typeSubTypeCode: '1601', typeSubTypeLabel: '1601 - Settlement Transfer/ Request for Reversal' },
    { typeSubTypeCode: '1602', typeSubTypeLabel: '1602 - Settlement Transfer/ Reversal of Transfer' },
    {
      typeSubTypeCode: '1607',
      typeSubTypeLabel: '1607 - Settlement Transfer/ Request for Reversal of a Prior day Transfer'
    },
    { typeSubTypeCode: '1608', typeSubTypeLabel: '1608 - Settlement Transfer/ Reversal of a Prior day Transfer' },
    { typeSubTypeCode: '1631', typeSubTypeLabel: '1631 - Settlement Transfer/ Request for Credit(Drawdown)' },
    {
      typeSubTypeCode: '1632',
      typeSubTypeLabel: '1632 - Settlement Transfer/ Funds Transfer Honoring a Request for Credit(Drawdown)'
    },
    {
      typeSubTypeCode: '1633',
      typeSubTypeLabel: '1633 - Settlement Transfer/ Refusal to Honor a Request for Credit(Drawdown)'
    },
    { typeSubTypeCode: '1690', typeSubTypeLabel: '1690 - Settlement Transfer/ Service Message' }

  ];

  $scope.textAreaLineParamNames = ['addressLineOne', 'addressLineTwo', 'addressLineThree', 'addressLineFour', 'addressLineFive', 'addressLineSix'];

  $scope.lineParamNames = ['lineOne', 'lineTwo', 'lineThree', 'lineFour', 'lineFive', 'lineSix'];
  // before submitting the form call the input string with required aramet it will generate object then assign that object wherever you want
  $scope.textSplitMethod = function (paramNames, firstLineNumberOfChar, otherLinesNumberOfChar, value, totalNumberOfLine) {
    let stringLength = value.length;
    const data = {};
    let startIndex = 0;
    let endIndex = firstLineNumberOfChar;
    for (let i = 0; i < totalNumberOfLine && stringLength > 0; i += 1) {
      const lineLength = (i === 0) ? firstLineNumberOfChar : otherLinesNumberOfChar;
      if (stringLength <= lineLength) {
        data[paramNames[i]] = value.substring(startIndex, value.length);
        stringLength = 0;
      } else {
        data[paramNames[i]] = value.substring(startIndex, endIndex);
        stringLength -= lineLength;
        startIndex = endIndex;
        endIndex = otherLinesNumberOfChar + endIndex;
      }
    }
    return data;
  };

  $scope.textSplitAsArray = function (firstLineNumberOfChar, otherLinesNumberOfChar, value, totalNumberOfLine) {
    const data = [];
    if (value !== undefined && value.length > 0) {
      value = value.replace(/(\r\n|\n|\r)/gm, ' ');
      let length = value.length;
      let startIndex = 0;
      let endIndex = firstLineNumberOfChar;
      for (let i = 0; i < totalNumberOfLine && length > 0; i += 1) {
        const lineLength = (i === 0) ? firstLineNumberOfChar : otherLinesNumberOfChar;
        if (length <= lineLength) {
          data.push(value.substring(startIndex, value.length));
          length = 0;
        } else {
          data.push(value.substring(startIndex, endIndex));
          length -= lineLength;
          startIndex = endIndex;
          endIndex = otherLinesNumberOfChar + endIndex;
        }
      }
    }
    return data;
  };

  $scope.currencyCodes = [
    { code: 'USD', label: 'USD' },
    { code: 'GBP', label: 'GBP' },
    { code: 'INR', label: 'INR' }
  ];

  $scope.IDCodes5000and4200 = [
    { code: 'B', label: 'B - SWIFT Bank Identifier Code(BIC)' },
    { code: 'C', label: 'C - CHIPS Participant' },
    { code: 'D', label: 'D - Demand Deposit Account(DDA) Number ' },
    { code: 'F', label: 'F - Fed Routing Number' },
    { code: 'T', label: 'T - SWIFT BIC or Bank Entity Identifier(BEI) and Account Number' },
    { code: 'U', label: 'U - CHIPS Identifier' },
    { code: '1', label: '1 - Passport Number' },
    { code: '2', label: '2 - Tax Identification Number' },
    { code: '3', label: '3 - Driver\'s License Number' },
    { code: '4', label: '4 - Alien Registration Number' },
    { code: '5', label: '5 - Corporate Identification' },
    { code: '9', label: '9 - Other Identification' }
  ];

  $scope.formData.originatorIdCode = $scope.IDCodes5000and4200[2].code;
  $scope.formData.beneficiaryIdCode = $scope.IDCodes5000and4200[2].code;

  $scope.IDCodes5100and5200 = [
    { code: 'B', label: 'B - SWIFT Bank Identifier Code(BIC)' },
    { code: 'C', label: 'C - CHIPS Participant' },
    { code: 'D', label: 'D - Demand Deposit Account(DDA) Number ' },
    { code: 'F', label: 'F - Fed Routing Number' },
    { code: 'U', label: 'U - CHIPS Identifier' }
  ];

  $scope.intermediaryFiCodes = [
    { code: 'B', label: 'B - SWIFT Bank Identifier Code(BIC)' },
    { code: 'C', label: 'C - CHIPS Participant' },
    { code: 'D', label: 'D - Demand Deposit Account(DDA) Number ' },
    { code: 'F', label: 'F - Fed Routing Number' },
    { code: 'U', label: 'U - CHIPS Identifier' }
  ];

  $scope.detailsOfCharges = [
    { code: 'B', label: 'B - Beneficiary' },
    { code: 'S', label: 'S - Shared' }
  ];

  $scope.adviceCodes = [
    { code: 'LTR', label: 'LTR - Letter' },
    { code: 'PHN', label: 'PHN - Phone' },
    { code: 'TLX', label: 'TLX - Telex' },
    { code: 'WRE', label: 'WRE - Wire' },
    { code: 'HLD', label: 'HLD - Hold' }
  ];

  $scope.methodOfPayments = [
    { code: 'CHECK', label: 'CHECK' }
  ];

  // $scope.formData.paymentMethod = $scope.methodOfPayments[0].code

  // Custom names for payment types
  $scope.paymentTypeLabels = { WIRE: 'Domestic Wire', SWIFT: 'International Wire [SWIFT]' };

  resourceFactory.savingsResource.get({ accountId: routeParams.accountId }, function (accountData) {
    $scope.formData.beneficiaryIdentifier = accountData.accountNo;
    $scope.formData.beneficiaryName = accountData.clientName;
  });

  $scope.onIntermediaryFiCodeSelect = function () {
    $scope.isIntermediaryFiFieldsRequired = !!$scope.formData.intermediaryFiCode;
  };

  $scope.onEnteringIntermediaryFiIdentifier = function () {
    if ($scope.formData.intermediaryFiIdentifier) {
      $scope.isIntermediaryFiIdentifierAndBeneficiaryFiRequired = true;
    } else {
      $scope.isIntermediaryFiIdentifierAndBeneficiaryFiRequired = false;
      $scope.isIntermediaryFiIdentifierAndBeneficiaryFiRequired = !!$scope.formData.intermediaryFiIdentifier;
    }
  };

  function getWireData () {
    const data = {
      type: 'CREDIT',
      dateFormat: $scope.df,
      paymentType: $scope.formData.paymentType,
      amount: $scope.formData.amount,
      reference: [],
      statementDescription,
      debtor: {
        name: $scope.formData.originatorName,
        address: []
      },
      creditor: {
        identifier: `ACCOUNT:${$scope.formData.beneficiaryIdentifier}`,
        name: $scope.formData.beneficiaryName,
        agent: {
          identifier: $scope.formData.beneficiaryFiIdentifier,
          name: $scope.formData.beneficiaryFiName
        },
        address: [] // Populate address details later
      },
      paymentRailMetaData: {
        businessFunctionCode: {
          businessFunctionCode: $scope.formData.businessFunctionCode,
          transactionTypeCode: $scope.formData.transactionType
        },
        receiverDepositoryInstitution: {
          receiverABANumber: $scope.formData.receiverAbaNum,
          receiverShortName: $scope.formData.receiverName
        },
        originator: {
          personal: {
            identificationCode: $scope.formData.originatorIdCode
          }
        },
        beneficiary: {
          personal: {
            identificationCode: $scope.formData.beneficiaryIdCode
          }
        },
        beneficiaryFI: {
          financialInstitution: {
            identificationCode: $scope.formData.beneficiaryFiIdCode
          }
        },
        beneficiaryIntermediaryFI: {
          financialInstitution: {}
        },
        originatorFI: {
          financialInstitution: {}
        },
        instructingFI: {
          financialInstitution: {}
        }
      }
    };

    if ($scope.formData.senderABANumber || $scope.formData.senderName) {
      data.paymentRailMetaData.senderDepositoryInstitution = {};

      if ($scope.formData.senderABANumber) {
        data.paymentRailMetaData.senderDepositoryInstitution.senderABANumber = $scope.formData.senderABANumber;
      }

      if ($scope.formData.senderName) {
        data.paymentRailMetaData.senderDepositoryInstitution.senderShortName = $scope.formData.senderName;
      }
    }

    if ($scope.formData.imad) {
      data.paymentRailMetaData.inputMessageAccountabilityData = $scope.formData.imad;
    }

    if ($scope.formData.omad) {
      data.paymentRailMetaData.outputMessageAccountabilityData = $scope.formData.omad;
    }

    if ($scope.formData.originatorFiIdCode) {
      data.paymentRailMetaData.originatorFI.financialInstitution.identificationCode = $scope.formData.originatorFiIdCode;
    }
    if ($scope.formData.originatorFiIdentifier) {
      data.paymentRailMetaData.originatorFI.financialInstitution.identifier = $scope.formData.originatorFiIdentifier;
    }
    if ($scope.formData.originatorFiName) {
      data.paymentRailMetaData.originatorFI.financialInstitution.name = $scope.formData.originatorFiName;
    }

    if ($scope.formData.originatorFiAddressLineOne) {
      data.paymentRailMetaData.originatorFI.financialInstitution.address = {};
      if ($scope.formData.originatorFiAddressLineOne) {
        data.paymentRailMetaData.originatorFI.financialInstitution.address.addressLineOne = $scope.formData.originatorFiAddressLineOne;
      }
      if ($scope.formData.originatorFiAddressLineTwo) {
        data.paymentRailMetaData.originatorFI.financialInstitution.address.addressLineTwo = $scope.formData.originatorFiAddressLineTwo;
      }
      if ($scope.formData.originatorFiAddressLineThree) {
        data.paymentRailMetaData.originatorFI.financialInstitution.address.addressLineThree = $scope.formData.originatorFiAddressLineThree;
      }
    }

    if ($scope.formData.originatorFiCountry) {
      data.paymentRailMetaData.originatorFI.financialInstitution.country = $scope.countryOptions.find(country => country.id === $scope.formData.originatorFiCountry).identifier;
    }
    if ($scope.formData.originatorFiState) {
      data.paymentRailMetaData.originatorFI.financialInstitution.stateOrProvince = $scope.originatorFiStateOptions.find(state => state.id === $scope.formData.originatorFiState).name;
    }
    data.paymentRailMetaData.originatorFI.financialInstitution.city = $scope.formData.originatorFiCity;
    data.paymentRailMetaData.originatorFI.financialInstitution.postalCode = $scope.formData.originatorFiPostalCode;

    if ($scope.formData.originatorInstructingFiCode) {
      data.paymentRailMetaData.instructingFI.financialInstitution.identificationCode = $scope.formData.originatorInstructingFiCode;
    }
    if ($scope.formData.originatorInstructingFiIdentifier) {
      data.paymentRailMetaData.instructingFI.financialInstitution.identifier = $scope.formData.originatorInstructingFiIdentifier;
    }
    if ($scope.formData.originatorInstructingFiName) {
      data.paymentRailMetaData.instructingFI.financialInstitution.name = $scope.formData.originatorInstructingFiName;
    }

    if ($scope.formData.originatorInstructingFiAddressLineOne) {
      data.paymentRailMetaData.instructingFI.financialInstitution.address = {};
      if ($scope.formData.originatorInstructingFiAddressLineOne) {
        data.paymentRailMetaData.instructingFI.financialInstitution.address.addressLineOne = $scope.formData.originatorInstructingFiAddressLineOne;
      }
      if ($scope.formData.originatorInstructingFiAddressLineTwo) {
        data.paymentRailMetaData.instructingFI.financialInstitution.address.addressLineTwo = $scope.formData.originatorInstructingFiAddressLineTwo;
      }
      if ($scope.formData.originatorInstructingFiAddressLineThree) {
        data.paymentRailMetaData.instructingFI.financialInstitution.address.addressLineThree = $scope.formData.originatorInstructingFiAddressLineThree;
      }
    }

    if ($scope.formData.originatorFiIdentifier) {
      data.debtor.identifier = `${$scope.formData.paymentType}://${$scope.formData.originatorFiIdentifier}/${$scope.formData.originatorIdentifier}`;
    } else {
      data.debtor.identifier = `${$scope.formData.paymentType}://${$scope.formData.senderABANumber}/${$scope.formData.originatorIdentifier}`;
    }

    if ($scope.formData.OriginatorToBeneficiaryReference) {
      data.statementDescription = $scope.formData.OriginatorToBeneficiaryReference.trim();
    }
    if ($scope.formData.fiToFiInformationReference) {
      data.paymentRailMetaData.fiAdditionalFiToFi = {};
      data.paymentRailMetaData.fiAdditionalFiToFi.additionalFiToFi = {};
      data.paymentRailMetaData.fiAdditionalFiToFi.additionalFiToFi = $scope.textSplitMethod($scope.lineParamNames, 35, 35, $scope.formData.fiToFiInformationReference, 6);
    }
    if ($scope.formData.beneficiaryAddressLineOne) {
      data.creditor.address.push($scope.formData.beneficiaryAddressLineOne);
    }
    if ($scope.formData.beneficiaryAddressLineTwo) {
      data.creditor.address.push($scope.formData.beneficiaryAddressLineTwo);
    }
    if ($scope.formData.beneficiaryAddressLineThree) {
      data.creditor.address.push($scope.formData.beneficiaryAddressLineThree);
    }
    if ($scope.formData.originatorAddressLineOne) {
      data.debtor.address.push($scope.formData.originatorAddressLineOne);
    }
    if ($scope.formData.originatorAddressLineTwo) {
      data.debtor.address.push($scope.formData.originatorAddressLineTwo);
    }
    if ($scope.formData.originatorAddressLineThree) {
      data.debtor.address.push($scope.formData.originatorAddressLineThree);
    }

    if ($scope.formData.originatorCountry) {
      data.debtor.country = $scope.countryOptions.find(country => country.id === $scope.formData.originatorCountry).identifier;
    }
    if ($scope.formData.originatorState) {
      data.debtor.stateOrProvince = $scope.originatorStateOptions.find(state => state.id === $scope.formData.originatorState).name;
    }
    data.debtor.city = $scope.formData.originatorCity;
    data.debtor.postalCode = $scope.formData.originatorPostalCode;

    if ($scope.formData.beneficiaryFiAddressLineOne) {
      data.creditor.agent.address = [];
      data.creditor.agent.address[0] = $scope.formData.beneficiaryFiAddressLineOne;
      if ($scope.formData.beneficiaryFiAddressLineTwo) {
        data.creditor.agent.address[1] = $scope.formData.beneficiaryFiAddressLineTwo;
      }
      if ($scope.formData.beneficiaryFiAddressLineThree) {
        data.creditor.agent.address[2] = $scope.formData.beneficiaryFiAddressLineThree;
      }
    }

    if ($scope.formData.wireType) {
      const typeSubType = $scope.formData.wireType.match(/.{1,2}/g);
      data.paymentRailMetaData.typeSubType = {
        typeCode: typeSubType[0],
        subTypeCode: typeSubType[1]
      };
    }

    if ($scope.formData.instructedAmountCurrencyCode && $scope.formData.instructedAmount) {
      data.paymentRailMetaData.instructedAmount = {
        currencyCode: $scope.formData.instructedAmountCurrencyCode,
        amount: $scope.formData.instructedAmount
      };
    }

    if ($scope.formData.exchangeRate) {
      data.paymentRailMetaData.exchangeRate = { exchangeRate: $scope.formData.exchangeRate };
    }

    if ($scope.formData.beneficiaryReference) {
      data.paymentRailMetaData.beneficiaryReference = { beneficiaryReference: $scope.formData.beneficiaryReference };
    }

    if ($scope.formData.senderReference) {
      data.paymentRailMetaData.senderReference = { senderReference: $scope.formData.senderReference };
    }

    if ($scope.formData.beneficiaryInformationReference) {
      data.creditor.forFurtherCredit = $scope.formData.beneficiaryInformationReference;
    }

    data.paymentRailMetaData.fiPaymentMethodToBeneficiary = {
      paymentMethod: $scope.formData.paymentMethod
    };

    if ($scope.formData.paymentMethodAdditionalInfomation) {
      data.paymentRailMetaData.fiPaymentMethodToBeneficiary.AdditionalInformation = $scope.formData.paymentMethodAdditionalInfomation;
    }

    if ($scope.formData.intermediaryFiCode) {
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.identificationCode = $scope.formData.intermediaryFiCode;
    }
    if ($scope.formData.intermediaryFiIdentifier) {
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.identifier = $scope.formData.intermediaryFiIdentifier;
    }
    if ($scope.formData.intermediaryFiIdentifier) {
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.name = $scope.formData.intermediaryFiName;
    }
    if ($scope.formData.intermediaryFiAddressLineOne) {
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.address = {};
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.address.addressLineOne = $scope.formData.intermediaryFiAddressLineOne;
    }
    if ($scope.formData.intermediaryFiAddressLineTwo) {
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.address.addressLineTwo = $scope.formData.intermediaryFiAddressLineTwo;
    }
    if ($scope.formData.intermediaryFiAddressLineThree) {
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.address.addressLineThree = $scope.formData.intermediaryFiAddressLineThree;
    }
    // const intermediaryFiAddressLineThree = constructAddressLine($scope.formData.intermediaryFiCity, $scope.formData.intermediaryFiCountry, $scope.formData.intermediaryFiState, $scope.formData.intermediaryFiPostalCode)
    // if (intermediaryFiAddressLineThree){
    //   data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.address.addressLineThree = intermediaryFiAddressLineThree
    // }
    return data;
  }

  resourceFactory.paymentTypeResource.getAll(function (dataPaymentTypes) {
    $scope.paymentTypes = dataPaymentTypes.filter(function (dataPaymentType) {
      return ['WIRE', 'SWIFT'].includes(dataPaymentType.name) && dataPaymentType.isSystemDefined;
    });
    // Check if we have custom name for the payment type or not.
    $scope.paymentTypes.forEach(paymentType => {
      paymentType.label = $scope.paymentTypeLabels[paymentType.name] || paymentType.name;
    }
    );
  });

  const successModalCtrl = function ($scope, $uibModalInstance, accountId) {
    $scope.confirm = function () {
      $uibModalInstance.close(location.path(`/viewsavingaccount/${accountId}`));
    };
  };

  $scope.resetInputErrorMessage = inputErrorKey => {
    $scope.formErrors[inputErrorKey] = '';
  };

  const formErrorHandler = apiValidationErrors => {
    $scope.formErrors = {};
    const { data: { errors } } = apiValidationErrors;

    const isRoutingNumberError = displayMessage => {
      const routingNumberErrorDisplayMessage = 'invalid routing number';
      return displayMessage === routingNumberErrorDisplayMessage || $scope.formData.paymentType === 'SWIFT';
    };

    errors.forEach(
      ({ parameterName, developerMessage }) => {
        // CORE returns the same parameter for ( creditor.identifier ) for identifier and routing number
        // On core side both both errors are creditor.identifier error
        // Implement a check on developer message to differentiate them.

        if (parameterName === 'creditor.identifier') { parameterName = isRoutingNumberError(developerMessage) ? 'creditor.identifierRoutingNumber' : parameterName; }

        $scope.formErrors = { ...$scope.formErrors, [parameterName]: developerMessage };
      }
    );
  };

  $scope.handleCountryDropDown = function () {
    $scope.formData.beneficiaryCountry = ``; // re-set
    $scope.formData.beneficiaryFiCountry = ``;
    $scope.formData.intermediaryFiCountry = ``;
    if (['WIRE'].includes($scope.formData.paymentType)) {
      $scope.countries = [$scope.countryOptions.find(country => country.name === `United States`)];
      $scope.zipMaxLen = 5;
      $scope.stateOptions = sortedStateByCountry();

      // reset the originatorCountry once the country dropdown is reset
      // if it is the US then it is fine ( for domestic WIRE).
      const usId = 284;

      $scope.originatorCountry = $scope.originatorCountry === usId ? $scope.originatorCountry : undefined;
      $scope.originatorFiCountry = $scope.originatorFiCountry === usId ? $scope.originatorFiCountry : undefined;
    } else {
      $scope.countries = [...$scope.countryOptions];
      $scope.zipMaxLen = 10;
    }
  };

  $scope.onCountryChange = function (countryId, inputSelectorIdentifier) {
    if (inputSelectorIdentifier === 'originator') {
      $scope.originatorStateOptions = sortedStateByCountry(countryId);
      return;
    }

    if (inputSelectorIdentifier === 'originatorFi') {
      $scope.originatorFiStateOptions = sortedStateByCountry(countryId);
      return;
    }

    // For beneficiary
    $scope.stateOptions = sortedStateByCountry(countryId);
  };

  function sortedCountry (_countries) {
    return _countries.sort(function (a, b) {
      return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
    });
  }

  function sortedStateByCountry (countryId) {
    const states = stateProvinceIdOptions.filter(function (_option) {
      return _option.parentId === countryId;
    });

    return states.sort(function (a, b) {
      return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
    });
  }

  // function constructAddressLine(city,countryCodeId,stateCodeId,postalCode){
  //   let addressLine = ``;
  //   if(city) {
  //     addressLine += ` ${city}`;
  //   }
  //   if (stateCodeId){
  //     const selectedState = stateProvinceIdOptions.find(state => state.id === stateCodeId);
  //     addressLine +=  ` ${selectedState.identifier}`;
  //   }
  //   if (postalCode) {
  //     addressLine += ` ${postalCode}`;
  //   }
  //   if (countryCodeId) {
  //     const selectedCountry = $scope.countryOptions.find(country => country.id === countryCodeId);
  //     addressLine +=  ` ${selectedCountry.identifier}`;
  //   }
  //   return addressLine.trim();
  // }

  $scope.submitForApproval = function () {
    const submitData = getWireData();

    resourceFactory.transferResource.save({}, submitData,
      () => {
        $uibModal.open({
          backdrop: 'static',
          keyboard: 'false',
          templateUrl: 'ConfirmationModal.html',
          controller: successModalCtrl,
          resolve: {
            accountId: () => routeParams.accountId
          }
        });

        location.path(`/viewsavingaccount/${routeParams.accountId}`);
      },
      errorData => formErrorHandler(errorData)
    );
  };

  $scope.cancel = function () {
    window.history.back();
  };

  $scope.$watch('formData.originatorName', function (newVal) {
    statementDescription = `WIRE IN: ${newVal || ''} ${$scope?.formData?.originatorFiName || ''}`;
    $scope.formData.OriginatorToBeneficiaryReference = statementDescription;
  });
  $scope.$watch('formData.originatorFiName', function (newVal) {
    statementDescription = `WIRE IN: ${$scope?.formData?.originatorName || ''} ${newVal || ''}`;
    $scope.formData.OriginatorToBeneficiaryReference = statementDescription;
  });
}

IncomingWireTransactionController.$inject = ['$scope', '$rootScope', 'ResourceFactory', '$uibModal', '$location', '$routeParams'];
