export function ReleaseAmountSavingsController ($scope, resourceFactory, location, routeParams) {
  $scope.errorMsg = '';
  $scope.cancelRoute = routeParams.id;

  resourceFactory.savingsAmountResource.get({ savingsAccountId: routeParams.id }, function (data) {
    const { pendingAmount } = data.pageItems.find(holdFund => holdFund.id.toString() === routeParams.authorizationId);
    $scope.amount = pendingAmount;
  }, handleResponseError);

  $scope.submit = function () {
    resourceFactory.savingsAmountResource.release({ savingsAccountId: routeParams.id }, {
      amount: $scope.amount,
      authorizationId: routeParams.authorizationId
    }, function () {
      location.path(`/viewsavingaccount/${routeParams.id}`);
    }, handleResponseError);
  };
  function handleResponseError (error) {
    const { data } = error;
    $scope.errorMsg = data.errors[0].defaultUserMessage;
  }

  $scope.closeAlert = function () {
    $scope.errorMsg = '';
  };
}

ReleaseAmountSavingsController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams'];
