export function BulkImportFixedDepositTransactionsController (scope, resourceFactory, API_VERSION, $rootScope, Upload) {
  scope.first = {};
  scope.first.templateUrl = `${API_VERSION}/fixeddepositaccounts/transaction/downloadtemplate?tenantIdentifier=${$rootScope.tenantIdentifier}&locale=${scope.optlang.code}&dateFormat=${scope.df}`;
  scope.formData = {};

  resourceFactory.officeResource.getAllOffices(function (data) {
    scope.offices = data;
  });

  scope.first.queryParams = '&';
  scope.changeOffice = function () {
    if (scope.formData.officeId) {
      if (scope.first.queryParams.indexOf('officeId') === -1) {
        scope.first.queryParams += `officeId=${scope.formData.officeId}`;
      } else {
        scope.first.queryParams = scope.first.queryParams.replace(/&officeId=\d+/i, `&officeId=${scope.formData.officeId}`);
      }
    } else {
      scope.first.queryParams = '&';
    }
  };

  scope.onFileSelect = function (files) {
    scope.formData.file = files[0];
  };

  scope.refreshImportTable = function () {
    resourceFactory.importResource.getImports({ entityType: 'fixeddeposittransactions' }, function (data) {
      for (const l in data) {
        let importdocs = {};
        importdocs = `${API_VERSION}/imports/downloadOutputTemplate?importDocumentId=${data[l].importId}&tenantIdentifier=${$rootScope.tenantIdentifier}`;
        data[l].docUrl = importdocs;
      }
      scope.imports = data;
    });
  };

  scope.upload = function () {
    Upload.upload({
      url: `${$rootScope.hostUrl + API_VERSION}/fixeddepositaccounts/transaction/uploadtemplate`,
      data: { file: scope.formData.file, locale: scope.optlang.code, dateFormat: scope.df }
    }).then(function () {
      // to fix IE not refreshing the model
      if (!scope.$$phase) {
        scope.$apply();
      }
    });
  };
}

BulkImportFixedDepositTransactionsController.$inject = ['$scope', 'ResourceFactory', 'API_VERSION', '$rootScope', 'Upload'];
