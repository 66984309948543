<template>
  <div class="row pb-3 align-items-center">
    <label
      v-if="label"
      :class="[colAuto ? 'col-auto' : 'text-end col-sm-4', { 'text-secondary': disabled }]"
      :for="id"
    >
      {{ label }}
      <span
        v-if="required"
        class="required"
      >*</span>
      <span
        v-if="hint"
        v-tooltips="hint"
        class="fa fa-question-circle"
      />
    </label>
    <div :class="colAuto ? 'col-auto' : 'col-sm-8'">
      <div
        v-for="(option, index) in options"
        :key="index"
        class="form-check"
      >
        <input
          :id="option.value"
          v-model="inputValue"
          class="form-check-input"
          type="checkbox"
          :value="option.value"
          @change="updateCheckBox"
        >
        <label
          class="form-check-label"
          :for="option.value"
        >
          {{ option.label }}
        </label>
      </div>
    </div>
    <div
      v-if="errorMessage"
      class="offset-sm-4 text-danger"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import formWrapperRegister from '@/components/shared/form-input/mixin.js';
export default ({
  mixins: [formWrapperRegister],
  props: {
    id: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: () => []
    },
    rules: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    colAuto: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    inputValue: []
  }),
  created () {
    this.inputValue = this.value;
    this.localRule = [
      value => {
        const isRequiredValid = !this.required || value.length > 0;
        return isRequiredValid || 'Please select your options';
      }
    ];
  },
  methods: {
    updateCheckBox (value) {
      this.validatorRunner();
      this.$emit('input', this.inputValue);
    }
  }
});
</script>
