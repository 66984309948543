export function FinancialActivityMappingsController ($scope, resourceFactory, location) {
  resourceFactory.officeToGLAccountMappingResource.getAll(function (data) {
    $scope.mappings = data;
  });

  $scope.routeTo = function (resourceId) {
    location.path(`/viewfinancialactivitymapping/${resourceId}`);
  };
}

FinancialActivityMappingsController.$inject = ['$scope', 'ResourceFactory', '$location'];
