import InputDatePicker from './InputDatePicker.vue';
import InputMobile from './InputMobile.vue';
import InputSelect from './InputSelect.vue';
import InputText from './InputText.vue';
import InputCheckBox from './InputCheckBox.vue';
import InputCheckBoxMultiple from './InputCheckBoxMultiple.vue';
import FormWrapper from './FormWrapper.vue';
import InputPostalCode from './InputPostalCode.vue';
import InputFile from './InputFile.vue';
import InputAmount from './InputAmount.vue';
import InputRadio from './InputRadio.vue';

export {
  InputDatePicker,
  InputMobile,
  InputSelect,
  InputText,
  InputCheckBox,
  FormWrapper,
  InputPostalCode,
  InputFile,
  InputAmount,
  InputRadio,
  InputCheckBoxMultiple
};
