import AchProcessing from '@/pages/ach-processing/index';
import AchProcessingHistory from '@/pages/ach-processing/history';

export const achProcessingRoutes = [
  {
    path: '/ach-processing',
    name: 'AchProcessing',
    component: AchProcessing
  },
  {
    path: '/ach-processing/history',
    name: 'AchProcessingHistory',
    component: AchProcessingHistory
  }
];
