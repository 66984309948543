import { isObject } from 'underscore';
import { cancellableTransfer } from '@/helper/transferHelper';

export function ViewTransferController ($rootScope, scope, resourceFactory, routeParams, API_VERSION, http, $uibModal, location) {
  scope.errorMsg = '';
  scope.transferDetails = {};
  scope.displayApproveButton = false;

  if (process.env.VUE_APP_SUBLEDGER !== 'evolve') {
    scope.displayApproveButton = true;
  }

  resourceFactory.transferResource.get({ id: routeParams.transferId }, function (data) {
    scope.transferDetails = data;
  });

  scope.onViewWireTransferClick = function () {
    location.path(`/accounttransfers/${routeParams.transferId}/viewWireTransfer`);
  };

  scope.onFedWireViewClick = function (transferStatus) {
    $uibModal.open({
      backdrop: 'static',
      templateUrl: 'fed-wire-file.html',
      controller: viewFedWireCtrl,
      size: 'lg',
      resolve: {
        transferID () {
          return routeParams.transferId;
        },
        transferStatus () {
          return transferStatus;
        }
      }
    });
  };

  scope.downloadPaymentFile = function (downloadType) {
    if (scope.transferDetails.paymentType === 'ACH') {
      resourceFactory.transferResource.query({ id: routeParams.transferId, action: 'download', type: downloadType }, (response) => {
        const a = document.createElement('a');
        const rawDetails = downloadType === 'paymentReturnInstruction' ? response.rawReturnDetails : response.rawPaymentDetails;
        const blob = isObject(rawDetails)
          ? new Blob([JSON.stringify(rawDetails, null, 2)], { type: 'application/json' })
          : new Blob([rawDetails], { responseType: 'arraybuffer' });

        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `transfer-${routeParams.transferId}-${scope.transferDetails.paymentType.toLowerCase()}-${downloadType}`;
        a.click();
        window.URL.revokeObjectURL(url);
      });
    } else {
      scope.downloadFileDirectly(downloadType);
    }
  };

  scope.downloadFileDirectly = function (downloadType) {
    const transferPaymentFileURL = `${$rootScope.hostUrl + API_VERSION}/transfers/${routeParams.transferId}/payment-file`;
    http({
      method: 'GET',
      url: transferPaymentFileURL,
      params: { type: downloadType, action: 'download' }
    })
      .then(function (response) {
        const a = document.createElement('a');
        const blob = isObject(response.data)
          ? new Blob([JSON.stringify(response.data, null, 2)], { type: 'application/json' })
          : new Blob([response.data], { responseType: 'arraybuffer' });

        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `transfer-${routeParams.transferId}-${scope.transferDetails.paymentType.toLowerCase()}-${downloadType}`;

        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(handleResponseError);
  };

  const viewFedWireCtrl = function ($scope, $uibModalInstance, transferStatus) {
    viewPaymentFile(routeParams.transferId, transferStatus);

    $scope.onCloseClick = function () {
      $uibModalInstance.dismiss();
    };

    $scope.onDownloadFedWireClick = function () {
      const downloadType = transferStatus === 'EXECUTION_FAILURE' ? 'paymentReturnInstruction' : 'paymentInstruction';
      scope.downloadPaymentFile(downloadType);
    };
  };

  const viewPaymentFile = function (transferStatus) {
    const transferPaymentFileURL = `${$rootScope.hostUrl + API_VERSION}/transfers/${routeParams.transferId}/payment-file`;
    const type = transferStatus === 'RETURNED' ? 'paymentReturnInstruction' : 'paymentInstruction';

    http({
      method: 'GET',
      url: transferPaymentFileURL,
      params: { type, action: 'view' }
    })
      .then(function (response) {
        const tempText = isObject(response.data) ? JSON.stringify(response.data, null, 2) : response.data;
        $('#file-wrapper code').text(tempText);
      })
      .catch(function (response) {
        $('#file-wrapper code').text(response.data.message);
      });
  };

  scope.onExecuteTransferClick = function () {
    $uibModal.open({
      backdrop: 'static',
      templateUrl: 'execute-transfer.html',
      controller: executeTransferCtrl,
      size: 'lg',
      resolve: {
        id () {
          return routeParams.transferId;
        }
      }
    });
  };

  scope.downloadReport = function () {
    resourceFactory.runReportsResource.getTransferReport({ reportSource: 'Wire Transfer Outgoing Request Form', R_transfer_id: routeParams.transferId, 'output-type': 'PDF' }, function (data) {
      const blob = new Blob([new Uint8Array(data.data)], { type: 'application/pdf', responseType: 'arraybuffer' });
      const url = URL.createObjectURL(blob);
      const element = document.createElement('a');
      element.href = url;
      element.download = `swift-${routeParams.transferId}`;
      element.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const executeTransferCtrl = function ($scope, $uibModalInstance, id) {
    $scope.onCloseClick = function () {
      $uibModalInstance.dismiss();
    };

    $scope.confirmExecuteTransfer = function () {
      resourceFactory.transferResource.save({ id, command: 'execute' }, {}, function () {
        location.path(`/transfers`);
      }, handleResponseError);
      $scope.onCloseClick();
    };
  };

  scope.isCancellableTransfer = () => {
    if (Object(scope.transferDetails).length) return;
    const { status, inOrOut } = scope.transferDetails;
    return cancellableTransfer(status, inOrOut);
  };

  scope.onCancelTransferClick = function () {
    $uibModal.open({
      backdrop: 'static',
      templateUrl: 'cancel-transfer.html',
      controller: cancelTransferCtrl,
      size: 'lg',
      resolve: {
        id () {
          return routeParams.transferId;
        }
      }
    });
  };

  const cancelTransferCtrl = function ($scope, $uibModalInstance, id) {
    $scope.onCloseClick = function () {
      $uibModalInstance.dismiss();
    };

    $scope.confirmCancelTransfer = function () {
      resourceFactory.transferResource.save({ id, command: 'cancel' }, {}, function () {
        location.path(`/transfers`);
      }, handleResponseError);
      $scope.onCloseClick();
    };
  };

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.message || data.errors[0].defaultUserMessage;
  }
  scope.onDeleteTransferClick = function () {
    $uibModal.open({
      backdrop: 'static',
      templateUrl: 'delete-transfer.html',
      controller: deleteTransferCtrl,
      size: 'lg',
      resolve: {
        id () {
          return routeParams.transferId;
        }
      }
    });
  };

  const deleteTransferCtrl = function ($scope, $uibModalInstance, id) {
    $scope.onCloseClick = function () {
      $uibModalInstance.dismiss();
    };

    $scope.confirmDeleteTransfer = function () {
      resourceFactory.transferResource.save({ id, command: 'delete' }, {}, function () {
        location.path(`/transfers`);
      }, handleResponseError);
      $scope.onCloseClick();
    };
  };

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };

  scope.cancel = function () {
    window.history.back();
  };
  scope.editOutgoingWireTransfer = function () {
    location.path(`/accounttransfers/${routeParams.transferId}/client/${scope.transferDetails.clientId}/editExternalWireTransfer`);
  };
}

ViewTransferController.$inject = ['$rootScope', '$scope', 'ResourceFactory', '$routeParams', 'API_VERSION', '$http', '$uibModal', '$location'];
