<template>
  <form
    class="d-flex"
    @submit.prevent="saveOrEdit"
  >
    <span
      v-if="!isEditing"
      class="d-flex align-items-center"
    >
      <span v-if="!$slots.default">
        {{ value }}
      </span>
      <span v-else>
        <slot>
          {{ value }}
        </slot>
      </span>
    </span>
    <span v-if="isEditing">
      <input
        v-model="currentValue"
        class="form-control"
        :type="type"
        :min="min"
        :max="max"
        :step="step"
      >
    </span>
    <button
      v-if="isEditing"
      class="btn btn-light btn-sm"
      type="reset"
      @click="cancel()"
    >
      <span class="fa fa-times" />
    </button>
    <button
      class="btn btn-light btn-sm"
      type="submit"
    >
      <span :class="`fa ${isEditing ? 'fa-check' : 'fa-pencil'}`" />
    </button>
  </form>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  value: {
    type: [Number, String],
    default: null
  },
  type: {
    type: String,
    default: 'text'
  },
  step: {
    type: Number,
    default: 0.001
  },
  min: {
    type: Number,
    default: null
  },
  max: {
    type: Number,
    default: null
  }
});

const currentValue = ref('');
const isEditing = ref(false);

const emit = defineEmits(['on-save']);
const saveOrEdit = () => {
  isEditing.value = !isEditing.value;
  // editing
  if (isEditing.value) {
    currentValue.value = props.value;
  }
  // saving
  if (!isEditing.value) {
    emit('on-save', currentValue);
  }
};

const cancel = () => {
  isEditing.value = false;
};
</script>
