export function EditCardSecuritiesController (scope, routeParams, resourceFactory, location, $rootScope) {
  scope.cardInfo = {};
  scope.error = '';
  scope.regions = $rootScope.regions;
  scope.cardTypeRoute = location.search().cardType;

  resourceFactory.cardsResource.get({ cardId: routeParams.cardId, fields: 'id,cardType,accountId,productId,onlinePaymentEnabled,contactlessPaymentEnabled,blockedCountries,primaryAccountNumber,atmWithdrawalsEnabled,internationalPaymentsEnabled,posPaymentEnabled' }, function (card) {
    if (card.cardType === 'DEBIT') {
      resourceFactory.savingsAccounts.get({ accountId: card.accountId, fields: 'clientName,clientId' }, ({ clientName, clientId }) => {
        resourceFactory.cardProductsResource.get({ cardProductId: card.productId, fields: 'name' }, ({ name }) => {
          scope.cardInfo = { ...card, account: { clientName, clientId }, product: { name } };
        });
      });
    } else {
      const clientIdQuery = `{"query": "{ Cards( where: { id : { EQ : ${routeParams.cardId}} }) {select { creditAccount { client {id displayName} product { name } } } } }"}`;

      resourceFactory.gqlResource.query(clientIdQuery, function (response) {
        const clientId = response.data.Cards.select[0].creditAccount.client.id;
        const clientName = response.data.Cards.select[0].creditAccount.client.displayName;
        const name = response.data.Cards.select[0].creditAccount.product.name;
        scope.cardInfo = { ...card, account: { clientName, clientId }, product: { name } };
      });
    }
  });

  scope.updatecard = function () {
    const data = {
      onlinePaymentEnabled: scope.cardInfo.onlinePaymentEnabled,
      contactlessPaymentEnabled: scope.cardInfo.contactlessPaymentEnabled,
      atmWithdrawalsEnabled: scope.cardInfo.atmWithdrawalsEnabled,
      internationalPaymentsEnabled: scope.cardInfo.internationalPaymentsEnabled,
      posPaymentEnabled: scope.cardInfo.posPaymentEnabled,
      blockedCountries: scope.cardInfo.blockedCountries
    };

    resourceFactory.cardsResource.update({ cardId: routeParams.cardId }, data, () => {
      if (scope.cardInfo.cardType === 'DEBIT') {
        scope.cancel();
      } else {
        location.path(`/viewcreditaccount/${scope.cardInfo.id}`);
      }
    }, (error) => {
      scope.error = error.data.errors[0].defaultUserMessage;
    });
  };

  scope.closeAlert = function () {
    scope.error = '';
  };

  scope.cancel = function () {
    if (scope.cardInfo.cardType === 'DEBIT') {
      history.back();
    } else {
      location.path(`/viewcreditaccount/${scope.cardInfo.id}`);
    }
  };
}

EditCardSecuritiesController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location', '$rootScope'];
