export function ViewRestrictedCountryController (scope, resourceFactory) {
  scope.selectedCountries = [];
  scope.restrictedCountries = [];
  scope.prohibitedCountries = [];
  scope.selectedRestrictedCountries = [];
  scope.selectedProhibitedCountries = [];
  resourceFactory.amlGroupsResource.get({},
    function (response) {
      const ruleGroups = response.pageItems;
      scope.ruleGroup = ruleGroups.find(ruleGroup => ruleGroup.type === 'RESTRICTED_COUNTRY');
    }
  );

  scope.editMode = false;

  scope.enableEditMode = () => { scope.editMode = true; };
  scope.disableEditMode = () => {
    scope.editMode = false;
    queryResource();
  };

  const sortAlphabeticalOrder = (a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  };

  function queryResource () {
    resourceFactory.amlRestrictedCountries.get((data) => {
      scope.blackListCountries = data.pageItems.map(({ country, type }) => ({ country, type })).sort((a, b) => sortAlphabeticalOrder(a, b));
      scope.blackListCountries.forEach(({ country, type }) => {
        type === 'PROHIBITED' ? scope.prohibitedCountries.push(country) : scope.restrictedCountries.push(country);
      });
      // REMOVE DUPLICATE
      scope.restrictedCountries = [...new Map(scope.restrictedCountries.map((item) => [item.id, item])).values()];
      scope.prohibitedCountries = [...new Map(scope.prohibitedCountries.map((item) => [item.id, item])).values()];
    });

    resourceFactory.codeResources.getAllCodes(function (data) {
      const codes = data;
      const countryCodeId = codes.find(code => code.name === 'COUNTRY').id;
      resourceFactory.codeValueResource.getAllCodeValues({ codeId: countryCodeId }, function (data) {
        scope.countries = data.filter(country => !scope.blackListCountries.map(blackListCountry => blackListCountry.country.id).includes(country.id)).sort((a, b) => sortAlphabeticalOrder(a, b));
      });
    });
  }
  queryResource();

  scope.addRestrictedCountry = () => {
    scope.selectedRestrictedCountries = scope.selectedCountries;
    scope.countries = scope.countries.filter(country => !scope.selectedRestrictedCountries.map(selectedCountry => selectedCountry.id).includes(country.id));
    scope.restrictedCountries = scope.restrictedCountries.concat(scope.selectedRestrictedCountries).sort((a, b) => sortAlphabeticalOrder(a, b));
    scope.updateRestrictedCountries();
    scope.selectedRestrictedCountries = [];
    scope.selectedCountries = [];
  };

  scope.removeRestrictedCountry = () => {
    scope.restrictedCountries = scope.restrictedCountries.filter(restrictedCountry => !scope.selectedRestrictedCountries.map(selectedCountry => selectedCountry.id).includes(restrictedCountry.id));
    scope.countries = scope.countries.concat(scope.selectedRestrictedCountries).sort((a, b) => sortAlphabeticalOrder(a, b));
    scope.countries = [...new Map(scope.countries.map((item) => [item.id, item])).values()];
    scope.updateRestrictedCountries();
    scope.selectedRestrictedCountries = [];
    scope.selectedCountries = [];
  };

  scope.addProhibitedCountry = () => {
    scope.selectedProhibitedCountries = scope.selectedCountries;
    scope.countries = scope.countries.filter(country => !scope.selectedProhibitedCountries.map(selectedCountry => selectedCountry.id).includes(country.id));
    scope.prohibitedCountries = scope.prohibitedCountries.concat(scope.selectedProhibitedCountries).sort((a, b) => sortAlphabeticalOrder(a, b));
    scope.updateRestrictedCountries();
    scope.selectedProhibitedCountries = [];
    scope.selectedCountries = [];
  };

  scope.removeProhibitedCountry = () => {
    scope.prohibitedCountries = scope.prohibitedCountries.filter(prohibitedCountry => !scope.selectedProhibitedCountries.map(selectedCountry => selectedCountry.id).includes(prohibitedCountry.id));
    scope.countries = scope.countries.concat(scope.selectedProhibitedCountries).sort((a, b) => sortAlphabeticalOrder(a, b));
    scope.updateRestrictedCountries();
    scope.selectedProhibitedCountries = [];
    scope.selectedCountries = [];
  };

  scope.updateRestrictedCountries = () => {
    const submissionData = {
      restricted: scope.restrictedCountries.map(restrictedCountry => restrictedCountry.id),
      prohibited: scope.prohibitedCountries.map(prohibitedCountry => prohibitedCountry.id)
    };
    resourceFactory.amlRestrictedCountries.update({}, submissionData);
  };
}

ViewRestrictedCountryController.$inject = ['$scope', 'ResourceFactory'];
