import _ from 'underscore';
import { clearAxios2faHeader, setAxios2faHeader } from '../../../services/apiService';

export function HttpServiceProvider () {
  const requestInterceptors = {};

  this.addRequestInterceptor = (id, interceptorFn) => {
    requestInterceptors[id] = interceptorFn;
  };

  this.removeRequestInterceptor = (id) => {
    delete requestInterceptors[id];
  };

  this.$get = ['$http', (http) => {
    const HttpService = function () {
      const getConfig = (config) => _.reduce(_.values(requestInterceptors), (c, i) => i(c), config);

      const self = this;
      _.each(['get', 'delete', 'head'], (method) => {
        self[method] = (url) => {
          const config = getConfig({
            method: method.toUpperCase(),
            url
          });
          return http(config);
        };
      });
      _.each(['post', 'put'], (method) => {
        self[method] = (url, data) => {
          const config = getConfig({
            method: method.toUpperCase(),
            url,
            data
          });
          return http(config);
        };
      });

      this.authenticateWithUsernamePassword = (url, username, password) => {
        const config = getConfig({
          method: 'POST',
          url,
          data: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&grant_type=password&client_id=backoffice&client_secret=123`,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });
        return http(config);
      };

      this.setAuthorization = (key, isOauth) => {
        if (isOauth) {
          http.defaults.headers.common.Authorization = `Bearer ${key}`;
        } else {
          http.defaults.headers.common.Authorization = `Basic ${key}`;
        }
      };

      this.cancelAuthorization = () => {
        delete http.defaults.headers.common.Authorization;
        delete http.defaults.headers.common['2FA-Token'];
        clearAxios2faHeader();
      };

      this.setTwoFactorAccessToken = (token) => {
        http.defaults.headers.common['2FA-Token'] = token;
        setAxios2faHeader(token);
      };
    };
    return new HttpService();
  }];
}
