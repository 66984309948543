import _ from 'underscore';

export function CreateSubscriberController (scope, resourceFactory, location) {
  let eventsResource = [];
  scope.formData = {};
  scope.formData.active = true;
  scope.templates = [];
  scope.headers = [];
  scope.inputHeader = { name: '', value: '' };
  scope.subscriberTypes = ['WEBHOOK', 'EMAIL', 'CLIENT_EMAIL', 'PUSH_NOTIFICATION'];
  scope.isRequiredTarget = false;
  scope.isRequiredTemplate = false;
  scope.rowSubscriptions = [];
  scope.inputSubscription = { entity: '', action: '', templateId: null, templateName: '' };

  scope.addHeader = function () {
    const hasDuplicateHeader = scope.headers.find(header => header.name === scope.inputHeader.name && header.value === scope.inputHeader.value);
    if (
      scope.inputHeader.name &&
      scope.inputHeader.value &&
      !hasDuplicateHeader
    ) {
      scope.headers.push({ name: scope.inputHeader.name, value: scope.inputHeader.value });
    }

    scope.inputHeader = { name: '', value: '' };
  };
  scope.deleteHeader = function (index) {
    scope.headers.splice(index, 1);
  };

  scope.listEventsByType = function (type) {
    scope.rowSubscriptions = [];
    resourceFactory.subscriptionResource.listEvents({ resourceType: 'events', type }, function (response) {
      eventsResource = response;
      scope.eventEntity = eventsResource.map(resource => resource.entity).sort();
    });
    resourceFactory.templateResource.get(function (response) {
      if (type === 'PUSH_NOTIFICATION') {
        response = response.filter(template => template.type === 'Push Notification');
      }
      if (['EMAIL', 'CLIENT_EMAIL'].includes(type)) {
        response = response.filter(template => template.type === 'E-Mail');
      }
      scope.templates = response;
    });
  };

  scope.onTemplateChange = function (templateId) {
    const template = scope.templates.find(item => item.id === templateId);
    scope.isRequiredTemplate = false;
    scope.inputSubscription.templateName = template?.name;
  };

  scope.onEntityChange = function (entity) {
    scope.inputSubscription.action = '';
    scope.eventActions = eventsResource.find(resource => resource.entity === entity)?.actions.sort();
  };

  scope.onSubscriberTypeChange = function (type) {
    scope.isRequiredTarget = ['WEBHOOK', 'EMAIL'].includes(type);
    scope.isRequiredTemplate = false;
    scope.listEventsByType(type);
  };

  scope.addSubscription = function () {
    scope.isEmailType = ['EMAIL', 'CLIENT_EMAIL', 'PUSH_NOTIFICATION'].includes(scope.formData.type);

    scope.isRequiredTemplate = (
      scope.isEmailType &&
      scope.inputSubscription.entity &&
      scope.inputSubscription.action &&
      !scope.inputSubscription.templateId
    );
    if (scope.isRequiredTemplate) return;

    const hasDuplicateRowSubscriptionWithTemplate = scope.rowSubscriptions.find(row => row.entity === scope.inputSubscription.entity && row.action === scope.inputSubscription.action && row.templateId === scope.inputSubscription.templateId);
    if (
      scope.isEmailType &&
      scope.inputSubscription.entity &&
      scope.inputSubscription.action &&
      scope.inputSubscription.templateId &&
      !hasDuplicateRowSubscriptionWithTemplate
    ) {
      scope.rowSubscriptions.push({ ...scope.inputSubscription });
    }
    const hasDuplicateRowSubscriptionWithoutTemplate = scope.rowSubscriptions.find(row => row.entity === scope.inputSubscription.entity && row.action === scope.inputSubscription.action);
    if (
      !scope.isEmailType &&
      scope.inputSubscription.entity &&
      scope.inputSubscription.action &&
      !hasDuplicateRowSubscriptionWithoutTemplate
    ) {
      scope.rowSubscriptions.push({
        entity: scope.inputSubscription.entity,
        action: scope.inputSubscription.action
      });
    }

    scope.eventActions = [];
    scope.inputSubscription = { entity: '', action: '', templateId: null, templateName: '' };
  };

  scope.deleteRowSubscription = function (index) {
    scope.rowSubscriptions.splice(index, 1);
  };

  scope.saveSubscription = function (events, subscriberId) {
    const groupedByEntity = _.mapObject(_.groupBy(events, 'entity'), // Group the events by entity
      event => event.map(obj => _.omit(obj, ['entity', 'subscriptionId', 'templateName']))); // Remove unwanted properties from each event
    const formattedData = { subscriberId, entityMap: groupedByEntity };

    resourceFactory.subscriptionResource.save(formattedData);
  };

  scope.submit = function () {
    scope.formData.headers = scope.headers.length ? [...scope.headers] : [];
    resourceFactory.subscriberResource.save({ ...scope.formData }, function (data) {
      if (scope.rowSubscriptions?.length) {
        scope.saveSubscription(scope.rowSubscriptions, data.resourceId);
      }
      location.path(`/viewsubscriber/${data.resourceId}`);
    });
  };
}

CreateSubscriberController.$inject = ['$scope', 'ResourceFactory', '$location'];
