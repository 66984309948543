import _ from 'underscore';

export function CreateFixedDepositProductController (scope, resourceFactory, location, dateFilter, $uibModal, WizardHandler) {
  scope.formData = {};
  scope.depositproduct = {};
  scope.charges = [];
  scope.showOrHideValue = 'show';
  scope.configureFundOptions = [];
  scope.specificIncomeaccounts = [];
  scope.penaltySpecificIncomeaccounts = [];
  scope.configureFundOption = {};
  scope.isClicked = false;

  // interest rate details
  scope.chart = {};
  scope.restrictDate = new Date();
  scope.fromDate = {}; // required for date formatting
  scope.endDate = {};// required for date formatting
  scope.isPrimaryGroupingByAmount = false;

  resourceFactory.fixedDepositProductResource.get({ resourceType: 'template' }, function (data) {
    scope.product = data;
    scope.product.chargeOptions = scope.product.chargeOptions || [];
    scope.assetAccountOptions = scope.product.accountingMappingOptions.assetAccountOptions || [];
    scope.liabilityAccountOptions = scope.product.accountingMappingOptions.liabilityAccountOptions || [];
    scope.incomeAccountOptions = scope.product.accountingMappingOptions.incomeAccountOptions || [];
    scope.expenseAccountOptions = scope.product.accountingMappingOptions.expenseAccountOptions || [];

    scope.formData.currencyCode = data.currencyOptions[0].code;
    scope.formData.digitsAfterDecimal = data.currencyOptions[0].decimalPlaces;
    scope.formData.interestCompoundingPeriodType = data.interestCompoundingPeriodType.id;
    scope.formData.interestPostingPeriodType = data.interestPostingPeriodType.id;
    scope.formData.interestCalculationType = data.interestCalculationType.id;
    scope.formData.interestCalculationDaysInYearType = data.interestCalculationDaysInYearType.id;
    scope.formData.preClosurePenalInterestOnTypeId = scope.product.preClosurePenalInterestOnTypeOptions[0].id;
    // set chart template
    scope.chart = scope.product.chartTemplate;
    scope.chart.chartSlabs = [];
    scope.formData.accountingRule = '1';
    scope.depositproduct = angular.copy(scope.formData);
    scope.depositproduct.interestCompoundingPeriodOption = data.interestCompoundingPeriodType.value;
    scope.depositproduct.interestPostingPeriodOption = data.interestPostingPeriodType.value;
    scope.depositproduct.interestCalculationOption = data.interestCalculationType.value;
    scope.depositproduct.interestCalculationDaysInYearOption = data.interestCalculationDaysInYearType.value;
  });

  scope.$watch('formData', function (newVal) {
    scope.depositproduct = angular.extend(scope.depositproduct, newVal);
  }, true);

  scope.goNext = function (form) {
    WizardHandler.wizard().checkValid(form);
    scope.isClicked = true;
  };

  scope.formValue = function (array, model, findattr, retAttr) {
    findattr = findattr || 'id';
    retAttr = retAttr || 'value';
    return _.find(array, function (obj) {
      return obj[findattr] === model;
    })[retAttr];
  };
  // advanced accounting rule
  scope.showOrHide = function (showOrHideValue) {
    if (showOrHideValue === 'show') {
      scope.showOrHideValue = 'hide';
    }

    if (showOrHideValue === 'hide') {
      scope.showOrHideValue = 'show';
    }
  };

  scope.chargeSelected = function (chargeId) {
    if (chargeId) {
      resourceFactory.chargeResource.get({ chargeId, template: 'true' }, this.formData, function (data) {
        data.chargeId = data.id;
        scope.charges.push(data);
        // to charge select box empty
        scope.chargeId = '';
      });
    }
  };

  scope.deleteCharge = function (index) {
    scope.charges.splice(index, 1);
  };

  scope.addConfigureFundSource = function () {
    if (scope.product.paymentTypeOptions && scope.product.paymentTypeOptions.length > 0 &&
                    scope.assetAccountOptions && scope.assetAccountOptions.length > 0) {
      scope.configureFundOptions.push({
        paymentTypeId: scope.product.paymentTypeOptions[0].id,
        fundSourceAccountId: scope.assetAccountOptions[0].id,
        paymentTypeOptions: scope.product.paymentTypeOptions,
        assetAccountOptions: scope.assetAccountOptions
      });
    }
  };

  scope.mapFees = function () {
    if (scope.product.chargeOptions && scope.product.chargeOptions.length > 0 && scope.incomeAccountOptions && scope.incomeAccountOptions.length > 0) {
      scope.specificIncomeaccounts.push({
        chargeId: scope.product.chargeOptions[0].id,
        incomeAccountId: scope.incomeAccountOptions[0].id,
        chargeOptions: scope.product.chargeOptions,
        incomeAccountOptions: scope.product.accountingMappingOptions.incomeAccountOptions
      });
    }
  };

  scope.mapPenalty = function () {
    if (scope.product.penaltyOptions && scope.product.penaltyOptions.length > 0 && scope.incomeAccountOptions && scope.incomeAccountOptions.length > 0) {
      scope.penaltySpecificIncomeaccounts.push({
        chargeId: scope.product.penaltyOptions[0].id,
        incomeAccountId: scope.incomeAccountOptions[0].id,
        penaltyOptions: scope.product.penaltyOptions,
        incomeAccountOptions: scope.incomeAccountOptions
      });
    }
  };

  scope.deleteFund = function (index) {
    scope.configureFundOptions.splice(index, 1);
  };

  scope.deleteFee = function (index) {
    scope.specificIncomeaccounts.splice(index, 1);
  };

  scope.deletePenalty = function (index) {
    scope.penaltySpecificIncomeaccounts.splice(index, 1);
  };

  scope.cancel = function () {
    location.path('/fixeddepositproducts');
  };

  scope.submit = function () {
    scope.paymentChannelToFundSourceMappings = [];
    scope.feeToIncomeAccountMappings = [];
    scope.penaltyToIncomeAccountMappings = [];
    scope.chargesSelected = [];

    let temp = '';

    // configure fund sources for payment channels
    for (const i in scope.configureFundOptions) {
      temp = {
        paymentTypeId: scope.configureFundOptions[i].paymentTypeId,
        fundSourceAccountId: scope.configureFundOptions[i].fundSourceAccountId
      };
      scope.paymentChannelToFundSourceMappings.push(temp);
    }

    // map fees to specific income accounts
    for (const i in scope.specificIncomeaccounts) {
      temp = {
        chargeId: scope.specificIncomeaccounts[i].chargeId,
        incomeAccountId: scope.specificIncomeaccounts[i].incomeAccountId
      };
      scope.feeToIncomeAccountMappings.push(temp);
    }

    // map penalties to specific income accounts
    for (const i in scope.penaltySpecificIncomeaccounts) {
      temp = {
        chargeId: scope.penaltySpecificIncomeaccounts[i].chargeId,
        incomeAccountId: scope.penaltySpecificIncomeaccounts[i].incomeAccountId
      };
      scope.penaltyToIncomeAccountMappings.push(temp);
    }

    for (const i in scope.charges) {
      temp = {
        id: scope.charges[i].id
      };
      scope.chargesSelected.push(temp);
    }

    this.formData.paymentChannelToFundSourceMappings = scope.paymentChannelToFundSourceMappings;
    this.formData.feeToIncomeAccountMappings = scope.feeToIncomeAccountMappings;
    this.formData.penaltyToIncomeAccountMappings = scope.penaltyToIncomeAccountMappings;
    this.formData.charges = scope.chargesSelected;
    this.formData.locale = scope.optlang.code;
    this.formData.charts = [];// declare charts array
    this.formData.charts.push(copyChartData(scope.chart));// add chart details

    resourceFactory.fixedDepositProductResource.save(this.formData, function (data) {
      location.path(`/viewfixeddepositproduct/${data.resourceId}`);
    });
  };

  /**
             * Add a new row with default values for entering chart details
             */
  scope.addNewRow = function () {
    let fromPeriod = '';
    let amountRangeFrom = '';
    let periodType = { id: '' };
    let toPeriod = '';
    let amountRangeTo = '';
    if (_.isNull(scope.chart.chartSlabs) || _.isUndefined(scope.chart.chartSlabs)) {
      scope.chart.chartSlabs = [];
    } else {
      let lastChartSlab = {};
      if (scope.chart.chartSlabs.length > 0) {
        lastChartSlab = angular.copy(scope.chart.chartSlabs[scope.chart.chartSlabs.length - 1]);
      } else {
        lastChartSlab = null;
      }
      if (!(_.isNull(lastChartSlab) || _.isUndefined(lastChartSlab))) {
        if (scope.isPrimaryGroupingByAmount) {
          if ((_.isNull(lastChartSlab.toPeriod) || _.isUndefined(lastChartSlab.toPeriod) || lastChartSlab.toPeriod.length === 0)) {
            amountRangeFrom = _.isNull(lastChartSlab) ? '' : parseFloat(lastChartSlab.amountRangeTo) + 1;
            fromPeriod = (_.isNull(lastChartSlab.fromPeriod) || _.isUndefined(lastChartSlab.fromPeriod) || lastChartSlab.fromPeriod.length === 0) ? '' : 1;
          } else {
            amountRangeFrom = lastChartSlab.amountRangeFrom;
            amountRangeTo = lastChartSlab.amountRangeTo;
            fromPeriod = _.isNull(lastChartSlab) ? '' : parseInt(lastChartSlab.toPeriod, 10) + 1;
          }
        } else if ((_.isNull(lastChartSlab.amountRangeTo) || _.isUndefined(lastChartSlab.amountRangeTo) || lastChartSlab.amountRangeTo.length === 0)) {
          amountRangeFrom = (_.isNull(lastChartSlab.amountRangeFrom) || _.isUndefined(lastChartSlab.amountRangeFrom) || lastChartSlab.amountRangeFrom.length === 0) ? '' : 1;
          fromPeriod = _.isNull(lastChartSlab) ? '' : parseFloat(lastChartSlab.toPeriod) + 1;
        } else {
          fromPeriod = lastChartSlab.fromPeriod;
          toPeriod = lastChartSlab.toPeriod;
          amountRangeFrom = _.isNull(lastChartSlab) ? '' : parseInt(lastChartSlab.amountRangeTo, 10) + 1;
        }
        periodType = angular.copy(lastChartSlab.periodType);
      }
    }

    const chartSlab = {
      periodType,
      fromPeriod,
      amountRangeFrom,
      incentives: []
    };
    if (!_.isUndefined(toPeriod) && toPeriod.length > 0) {
      chartSlab.toPeriod = toPeriod;
    }
    if (!_.isUndefined(amountRangeTo) && amountRangeTo.length > 0) {
      chartSlab.amountRangeTo = amountRangeTo;
    }
    scope.chart.chartSlabs.push(chartSlab);
  };

  /**
             * Remove chart details row
             */
  scope.removeRow = function (index) {
    scope.chart.chartSlabs.splice(index, 1);
  };

  /**
             *  create new chart data object
             */

  const copyChartData = function () {
    const newChartData = {
      name: scope.chart.name,
      description: scope.chart.description,
      fromDate: dateFilter(scope.fromDate.date, scope.df),
      endDate: dateFilter(scope.endDate.date, scope.df),
      isPrimaryGroupingByAmount: scope.isPrimaryGroupingByAmount,
      // savingsProductId: $scope.productId,
      dateFormat: scope.df,
      locale: scope.optlang.code,
      chartSlabs: angular.copy(copyChartSlabs(scope.chart.chartSlabs))
    };

    // remove empty values
    _.each(newChartData, function (v, k) {
      if (!v) { delete newChartData[k]; }
    });

    return newChartData;
  };

  /**
             *  copy all chart details to a new Array
             * @param chartSlabs
             * @returns {Array}
             */
  const copyChartSlabs = function (chartSlabs) {
    const detailsArray = [];
    _.each(chartSlabs, function (chartSlab) {
      const chartSlabData = copyChartSlab(chartSlab);
      detailsArray.push(chartSlabData);
    });
    return detailsArray;
  };

  /**
             * create new chart detail object data from chartSlab
             * @param chartSlab
             *
             */

  const copyChartSlab = function (chartSlab) {
    const newChartSlabData = {
      id: chartSlab.id,
      description: chartSlab.description,
      fromPeriod: chartSlab.fromPeriod,
      toPeriod: chartSlab.toPeriod,
      amountRangeFrom: chartSlab.amountRangeFrom,
      amountRangeTo: chartSlab.amountRangeTo,
      annualInterestRate: chartSlab.annualInterestRate,
      locale: scope.optlang.code,
      incentives: angular.copy(copyIncentives(chartSlab.incentives))
    };

    if (chartSlab.periodType !== undefined) {
      newChartSlabData.periodType = chartSlab.periodType.id;
    }
    // alert("Period type id" + chartSlab.periodType.id);
    // remove empty values
    _.each(newChartSlabData, function (v, k) {
      if (!v && v !== 0) {
        // alert('key:' + k + " and value:" + v);
        delete newChartSlabData[k];
      }
    });

    return newChartSlabData;
  };

  scope.incentives = function (index) {
    $uibModal.open({
      templateUrl: 'incentive.html',
      controller: IncentiveCtrl,
      size: 'lg',
      resolve: {
        data () {
          return scope.chart;
        },
        chartSlab () {
          return scope.chart.chartSlabs[index];
        }
      }
    });
  };

  /**
             *  copy all chart details to a new Array
             * @param incentiveDatas
             * @returns {Array}
             */
  const copyIncentives = function (incentives) {
    const detailsArray = [];
    _.each(incentives, function (incentive) {
      const incentiveData = copyIncentive(incentive);
      detailsArray.push(incentiveData);
    });
    return detailsArray;
  };

  /**
             * create new chart detail object data from chartSlab
             * @param incentiveData
             *
             */

  const copyIncentive = function (incentiveData) {
    const newIncentiveDataData = {
      id: incentiveData.id,
      entityType: incentiveData.entityType,
      attributeName: incentiveData.attributeName,
      conditionType: incentiveData.conditionType,
      attributeValue: incentiveData.attributeValue,
      incentiveType: incentiveData.incentiveType,
      amount: incentiveData.amount
    };
    return newIncentiveDataData;
  };

  const IncentiveCtrl = function ($scope, $uibModalInstance, data, chartSlab) {
    $scope.data = data;
    $scope.chartSlab = chartSlab;
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.addNewRow = function () {
      const incentive = {
        entityType: '2',
        attributeName: '',
        conditionType: '',
        attributeValue: '',
        incentiveType: { id: '' },
        amount: ''
      };

      $scope.chartSlab.incentives.push(incentive);
    };

    /**
                 * Remove chart details row
                 */
    $scope.removeRow = function (index) {
      $scope.chartSlab.incentives.splice(index, 1);
    };
  };
}

CreateFixedDepositProductController.$inject = ['$scope', 'ResourceFactory', '$location', 'dateFilter', '$uibModal', 'WizardHandler'];
