export function CreateDataTableWorkflowController (scope, routeParams, resourceFactory, location) {
  scope.labelerror = 'requiredfield';
  scope.datatableSelect = [];
  scope.entityWorkflows = [];
  scope.entityWorkflowTemplate = {};
  scope.errorDetails = [];

  resourceFactory.DataTablesResource.getAllDataTables(function (data) {
    for (const i in data) {
      if (data[i].registeredTableName !== undefined) { scope.datatableSelect.push({ name: data[i].datatableTitle, value: data[i].registeredTableName }); }
    }
    scope.datatableSelect.push({ name: 'Client', value: 'm_client' });
    scope.datatableSelect.push({ name: 'Loan', value: 'm_loan' });
    scope.datatableSelect.push({ name: 'Office', value: 'm_office' });
    scope.datatableSelect.push({ name: 'Saving Account', value: 'm_savings_account' });
  });
  scope.addWorkflowStep = function () {
    if (scope.entityWorkflowTemplate.datatableName && scope.entityWorkflowTemplate.position) {
      scope.entityWorkflows.push({
        datatableName: scope.entityWorkflowTemplate.datatableName,
        isDatatable: scope.entityWorkflowTemplate.isDatatable || false,
        position: scope.entityWorkflowTemplate.position,
        resourceIdentifier: scope.entityWorkflowTemplate.resourceIdentifier,
        groupName: scope.entityWorkflowTemplate.groupName
      });
      scope.entityWorkflowTemplate.datatableName = undefined;
      scope.entityWorkflowTemplate.isDatatable = false;
      scope.entityWorkflowTemplate.position = undefined;
      scope.entityWorkflowTemplate.resourceIdentifier = undefined;
      scope.entityWorkflowTemplate.groupName = undefined;
    } else if (!scope.entityWorkflowTemplate.datatableName) {
      const errorObj = {};
      errorObj.args = {
        params: []
      };
      errorObj.args.params.push({ value: 'validation.msg.datatableworkflow.datatableName.cannot.be.blank' });
      scope.errorDetails.push(errorObj);
    }
  };
  scope.removeWorkflow = function (index) {
    scope.entityWorkflows.splice(index, 1);
  };
  scope.submit = function () {
    scope.formData.steps = [];
    scope.errorDetails = [];
    if (scope.entityWorkflows.length === 0) {
      const errorObj = {};
      errorObj.args = {
        params: []
      };
      errorObj.args.params.push({ value: 'error.msg.click.on.add.to.add.workflows' });
      scope.errorDetails.push(errorObj);
    }
    const positionList = [];
    for (const i in scope.entityWorkflows) {
      positionList.push(Number(scope.entityWorkflows[i].position));
    }
    const unique = positionList.filter((v, i, a) => a.indexOf(v) === i);
    if (positionList.length !== unique.length) {
      const errorObj = {};
      errorObj.args = {
        params: []
      };
      errorObj.args.params.push({ value: 'error.msg.workflow.duplicate.position' });
      scope.errorDetails.push(errorObj);
    }
    const start = 1;
    const expectedSeqRange = Array(scope.entityWorkflows.length - start + 1).fill().map((_, idx) => start + idx);
    for (const i in positionList) {
      if (!(expectedSeqRange.indexOf(positionList[i]) >= 0)) {
        const errorObj = {};
        errorObj.args = {
          params: []
        };
        errorObj.args.params.push({ value: 'error.msg.workflow.position.out.of.range' });
        scope.errorDetails.push(errorObj);
      }
    }
    if (scope.errorDetails.length === 0) {
      delete scope.errorDetails;
      for (const i in scope.entityWorkflows) {
        scope.formData.steps.push(scope.entityWorkflows[i]);
      }
      resourceFactory.DataTablesWorkflowResource.save(this.formData, function (data) {
        location.path(`/viewdatatableworkflow/${data.resourceIdentifier}`);
      });
    }
  };
}

CreateDataTableWorkflowController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
