<template>
  <nav
    v-if="userDetails"
    class="navbar navbar-default navbar-fixed-top position-fixed start-0 w-100 ps-5 bg-primary"
    role="navigation"
    style="z-index: 1030"
  >
    <div class="ps-4 w-100">
      <div class="container justify-content-end">
        <div class="navbar-header text-end text-md-left">
          <button
            type="button"
            class="btn d-inline-block d-md-none navbar-toggle"
            data-bs-toggle="collapse"
            data-bs-target="#topNavbarCollapse"
            aria-expanded="false"
          >
            <span class="navbar-toggler-icon navbar-dark" />
            <span class="sr-only">Toggle navigation</span>
          </button>
        </div>
        <div
          id="topNavbarCollapse"
          class="collapse w-100 d-md-block"
        >
          <div class="row pt-4 pt-md-0">
            <div class="col-auto d-md-block d-none">
              <a href="#/home">
                <img
                  src="/assets/images/logos/logo-white.png"
                  height="45"
                  alt="Mbanq"
                >
              </a>
            </div>
            <div class="col-md-6 col-sm-12">
              <form
                id="search-top"
                class="navbar-form"
                @submit.prevent="searchSubmit"
              >
                <div class="input-group">
                  <input
                    id="search"
                    v-model="search"
                    type="text"
                    required
                    minlength="3"
                    autocomplete="off"
                    class="form-control border-0 input-sm unstyled"
                    :placeholder="$t('label.searchorpress')"
                  >
                  <b-dropdown
                    variant="none"
                    :text="currentScope ? $t(currentScope.name) : ''"
                    toggle-class="bg-white rounded-0 rounded-end"
                  >
                    <b-dropdown-item
                      v-for="(searchScope, index) in searchScopes"
                      :key="`sc-${index}`"
                      @click="currentScope = searchScope"
                    >
                      {{ $t(searchScope.name) }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </form>
              <div class="navbar-expand-md">
                <ul
                  id="main-menu-left"
                  class="nav navbar-nav align-items-center"
                >
                  <li class="pe-2">
                    <a
                      v-has-permission="'READ_CLIENT'"
                      href="#/clients"
                      class="nav-link text-white"
                    >
                      {{ $t('label.anchor.clients') }}
                    </a>
                  </li>
                  <li class="pe-2">
                    <a
                      class="nav-link text-white"
                      href="#/accounting"
                    >
                      <i class="fas fa-money-bill-alt me-1 d-none" />{{ $t('label.anchor.accounting') }}
                    </a>
                  </li>
                  <li
                    id="reports-menu"
                    class="pe-2"
                  >
                    <b-dropdown
                      variant="none"
                      toggle-class="text-white link"
                    >
                      <template #button-content>
                        <i class="fas fa-chart-bar me-1 d-none" />{{ $t('label.anchor.reports') }}
                      </template>
                      <b-dropdown-item href="#/reports/all">
                        &nbsp;{{ $t('label.anchor.all') }}
                      </b-dropdown-item>
                      <b-dropdown-item href="#/reports/clients">
                        &nbsp;{{ $t('label.anchor.clients') }}
                      </b-dropdown-item>
                      <b-dropdown-item href="#/reports/loans">
                        &nbsp;{{ $t('label.anchor.loans') }}
                      </b-dropdown-item>
                      <b-dropdown-item href="#/reports/savings">
                        &nbsp;{{ $t('label.anchor.savings') }}
                      </b-dropdown-item>
                      <b-dropdown-item href="#/reports/funds">
                        &nbsp;{{ $t('label.anchor.funds') }}
                      </b-dropdown-item>
                      <b-dropdown-item href="#/reports/accounting">
                        &nbsp;{{ $t('label.anchor.accounting') }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </li>
                  <li
                    id="preview-menu"
                    class=""
                    uib-dropdown
                  >
                    <b-dropdown
                      variant="none"
                      toggle-class="text-white"
                    >
                      <template #button-content>
                        <i class="fas fa-wrench me-1 d-none" />{{ $t('label.anchor.admin') }}
                      </template>
                      <b-dropdown-item
                        v-has-permission="'READ_USER'"
                        href="#/users"
                      >
                        {{ $t('label.anchor.users') }}
                      </b-dropdown-item>
                      <b-dropdown-item href="#/organization">
                        {{ $t('label.anchor.organization') }}
                      </b-dropdown-item>
                      <b-dropdown-item href="#/system">
                        {{ $t('label.anchor.system') }}
                      </b-dropdown-item>
                      <b-dropdown-item href="#/products">
                        {{ $t('label.anchor.products') }}
                      </b-dropdown-item>
                      <b-dropdown-item href="#/templates">
                        {{ $t('label.anchor.templates') }}
                      </b-dropdown-item>
                      <b-dropdown-item href="#/payments">
                        {{ $t('label.anchor.payments') }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col text-md-end">
              <div class="navbar-expand-md">
                <ul
                  id="main-menu-right"
                  class="nav navbar-nav navbar-right justify-content-md-end align-items-center"
                >
                  <NotificationsDropdown v-if="notificationsEnabled" />
                  <div class="dropdown">
                    <button
                      id="user-dropdown"
                      class="btn text-white dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span class="text-capitalize">{{ currentUser }}</span>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          id="help"
                          class="dropdown-item"
                          href="#/help"
                        ><i class="fas fa-question-circle me-2" />{{ $t('label.anchor.help') }}</a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#/profile"
                        ><i class="fas fa-user me-2" />{{ $t('label.anchor.profile') }}</a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#/usersetting"
                        ><i class="fas fa-cog me-2" />{{ $t('label.anchor.settings') }}</a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#/about"
                        ><i class="fas fa-info-circle me-2" />{{ $t('label.anchor.about') }}</a>
                      </li>
                      <li>
                        <button
                          id="logout"
                          class="dropdown-item"
                          @click="logout"
                        >
                          <i class="fas fa-power-off me-2" />{{
                            $t('label.anchor.logout') }}
                        </button>
                      </li>
                    </ul>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue';
import { mapState, mapActions } from 'pinia';
import NotificationsDropdown from '@/components/shared/NotificationsDropdown.vue';
import { useUserStore } from '@/stores/user';

export default {
  name: 'TopBar',
  components: {
    NotificationsDropdown,
    BDropdown,
    BDropdownItem
  },
  data () {
    return {
      searchScopes: [
        { name: 'label.search.scope.all', value: 'clients,clientIdentifiers,groups,savings,shares,loans,old_savings' },
        { name: 'label.search.scope.savings', value: 'savings' },
        { name: 'label.search.scope.cs', value: 'clients,savings' },
        { name: 'label.input.adhoc.search.loans', value: 'loans' },
        { name: 'label.search.scope.clients.and.clientIdentifiers', value: 'clients,clientIdentifiers' },
        { name: 'label.search.scope.cards', value: 'cards' },
        { name: 'label.search.scope.oldAccounts', value: 'old_savings' }
      ],
      currentScope: null,
      search: '',
      notificationsEnabled: false
    };
  },
  computed: {
    ...mapState(useUserStore, ['userDetails']),
    currentUser () {
      return this.userDetails ? this.userDetails?.username : '';
    }
  },
  created () {
    this.currentScope = this.searchScopes[2];
  },
  methods: {
    ...mapActions(useUserStore, ['logout']),
    searchSubmit () {
      if (this.search.length < 3) return;
      let searchString = this.search;
      let exactMatch = false;
      searchString = searchString.replace(/(^"|"$)/g, '');
      const n = searchString.localeCompare(this.search);
      if (n !== 0) exactMatch = true;
      location.href = `#/search/${searchString}?exactMatch=${exactMatch}&resource=${this.currentScope.value}`;
    }
  }
};
</script>
