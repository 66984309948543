<template>
  <data-grid
    :items="items"
    :limit="limit"
    :total-records="totalRecords"
    :fields="fields"
    clickable
    @pagination-update="paginationUpdate"
    @sort-column="sortColumn"
    @entries-per-page="updateEntriesPerPage"
    @click-row="rowClicked"
  >
    <template #isSelfService="{ item }">
      <i
        class="fa-regular"
        :class="item.isSelfService ? 'fa-circle-check text-primary' : 'fa-circle-xmark text-danger'"
      />
    </template>
    <template #disabled="{ item }">
      {{ item.disabled ? 'Disabled' : 'Enabled' }}
    </template>
  </data-grid>
</template>
<script setup>
import DataGrid from '@/components/shared/data-grid/index.vue';
import i18n from '@/plugins/i18n';
defineProps({
  items: {
    type: Array,
    default: () => []
  },
  totalRecords: {
    type: Number,
    default: 0
  },
  limit: {
    type: [Number, String],
    default: 15
  }
});

const emit = defineEmits([
  'pagination-update',
  'sort-column',
  'entries-per-page',
  'click-row'
]);

const fields = [
  { key: 'name', label: i18n.t('label.heading.name'), sortable: false },
  { key: 'description', label: i18n.t('label.heading.description'), sortable: false },
  { key: 'isSelfService', label: i18n.t('label.heading.isSelfService'), sortable: false },
  { key: 'disabled', label: i18n.t('label.heading.status'), sortable: false }
];

const paginationUpdate = (offset) => {
  emit('pagination-update', offset);
};

const sortColumn = (sort) => {
  emit('sort-column', sort);
};

const updateEntriesPerPage = (entry) => {
  emit('entries-per-page-update', entry);
};

const rowClicked = (item) => {
  emit('click-row', item);
};
</script>
