<template>
  <base-modal
    id="modal-confirm-close"
    hide-footer
    show-button-close
    modal-size="lg"
    scrollable
  >
    <template #header>
      <div class="d-flex align-items-center w-100">
        <span class="me-auto">
          Closure report
        </span>

        <button
          class="btn btn-primary me-3"
          @click="handleDownload"
        >
          {{ $t('label.button.download') }}
        </button>
      </div>
    </template>

    <template #body>
      <p class="mb-0">
        <iframe
          v-if="showIframe"
          :src="fileData"
          width="100%"
          height="800px"
          type="application/pdf"
          loading="lazy"
        />
      </p>
    </template>
  </base-modal>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import { downloadHelper } from '@/helper/downloadHelper';
import { computed } from 'vue';
import { $notify } from '@/plugins/prototypes';

const props = defineProps({
  reportFile: {
    type: Object,
    required: true
  }
});

const fileData = computed(() => {
  const blob = new Blob([props.reportFile.data], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(blob);

  return fileURL;
});

const showIframe = computed(() => !!props.reportFile.data);

async function handleDownload () {
  try {
    downloadHelper(props.reportFile.data, `Credit_Card_Closure_Report_${new Date().toISOString()}.pdf`, props.reportFile.headers['Content-Type']);
  } catch (error) {
    $notify.error(error);
  }
}
</script>
