export function EditFieldConfigurationController (scope, routeParams, resourceFactory, location) {
  scope.formData = {};
  scope.formData.isEnabled = true;

  resourceFactory.fieldconfigurationResource.get({ id: routeParams.id }, function (data) {
    scope.formData = data;
  });

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
  scope.submit = function () {
    const { id, entity, isEnabled, fieldCondition } = scope.formData;
    resourceFactory.fieldconfigurationResource.update({ id }, { entity, isEnabled, fieldCondition }, function (data) {
      location.path(`/viewfieldconfiguration/${data.resourceId}`);
    }, ({ data: { errors } }) => {
      scope.errorMsg = errors[0].defaultUserMessage;
    });
  };
}

EditFieldConfigurationController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
