import router from '@/router/index';
export function ViewCreditProductController (scope, routeParams, location, anchorScroll, resourceFactory) {
  scope.isAccountingEnabled = false;
  scope.isAccrualAccountingEnabled = false;
  scope.creditproduct = [];
  scope.purchaseFloatingRateData = {};
  scope.cashAdvanceFloatingRateData = {};
  resourceFactory.creditProductResource.get({ creditProductId: routeParams.id, template: 'true' }, function (data) {
    scope.creditproduct = data;
    if (data.isLinkedToFloatingInterestRates) {
      for (const creditAccountFloatingData of data.creditAccountFloatingData) {
        if (creditAccountFloatingData.creditInterestType.id === 0) {
          scope.purchaseFloatingRateData.floatingRateName = creditAccountFloatingData.floatingRateName;
          scope.purchaseFloatingRateData.interestRateDifferential = creditAccountFloatingData.interestRateDifferential;
          scope.purchaseFloatingRateData.isFloatingInterestRateCalculationAllowed = creditAccountFloatingData.isFloatingInterestRateCalculationAllowed;
        } else if (creditAccountFloatingData.creditInterestType.id === 1) {
          scope.cashAdvanceFloatingRateData.floatingRateName = creditAccountFloatingData.floatingRateName;
          scope.cashAdvanceFloatingRateData.interestRateDifferential = creditAccountFloatingData.interestRateDifferential;
          scope.cashAdvanceFloatingRateData.isFloatingInterestRateCalculationAllowed = creditAccountFloatingData.isFloatingInterestRateCalculationAllowed;
        }
      }
    }
    if (data.accountingRule.id === 2 || data.accountingRule.id === 3 || data.accountingRule.id === 4) {
      scope.isAccountingEnabled = true;
    }

    if (data.accountingRule.id === 3 || data.accountingRule.id === 4) {
      scope.isAccrualAccountingEnabled = true;
    }
    if (scope.creditproduct.allowAttributeOverrides != null) {
      scope.amortization = scope.creditproduct.allowAttributeOverrides.amortizationType;
      scope.arrearsTolerance = scope.creditproduct.allowAttributeOverrides.inArrearsTolerance;
      scope.graceOnArrearsAging = scope.creditproduct.allowAttributeOverrides.graceOnArrearsAgeing;
      scope.interestCalcPeriod = scope.creditproduct.allowAttributeOverrides.interestCalculationPeriodType;
      scope.interestMethod = scope.creditproduct.allowAttributeOverrides.interestType;
      scope.graceOnPrincipalAndInterest = scope.creditproduct.allowAttributeOverrides.graceOnPrincipalAndInterestPayment;
      scope.repaymentFrequency = scope.creditproduct.allowAttributeOverrides.repaymentEvery;
      scope.transactionProcessingStrategy = scope.creditproduct.allowAttributeOverrides.transactionProcessingStrategyId;
    }
    if (scope.amortization || scope.arrearsTolerance || scope.graceOnArrearsAging ||
                        scope.interestCalcPeriod || scope.interestMethod || scope.graceOnPrincipalAndInterest ||
                        scope.repaymentFrequency || scope.transactionProcessingStrategy === true) {
      scope.allowAttributeConfiguration = true;
    } else {
      scope.allowAttributeConfiguration = false;
    }
  });
  scope.routeToListProducts = () => {
    router.push('/products/credit');
  };

  scope.scrollto = function (link) {
    location.hash(link);
    anchorScroll();
  };
}

ViewCreditProductController.$inject = ['$scope', '$routeParams', '$location', '$anchorScroll', 'ResourceFactory'];
