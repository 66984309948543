export function CreateMerchantChargeController (scope, routeParams, resourceFactory, location) {
  scope.mccCharge = {};
  scope.chargeOptions = [];
  scope.formData = {
    chargeId: '',
    mcc: '',
    overrideCharge: false,
    amount: 0.00,
    locale: scope.optlang.code,
    dateFormat: scope.df
  };

  resourceFactory.chargeResource.getAllCharges({}, function (data) {
    for (const i in data) {
      if (data[i].id !== undefined) {
        scope.chargeOptions.push({ id: data[i].id, name: data[i].name, amount: data[i].amount });
      }
    }
  });

  scope.updateCharge = function (option) {
    scope.formData.chargeId = option.id;
    scope.formData.amount = option.amount;
  };

  scope.submit = function () {
    resourceFactory.mccChargeResource.save(this.formData, function () {
      location.path(`/merchantcharges`);
    });
  };
}

CreateMerchantChargeController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
