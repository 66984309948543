import Vue from 'vue';
import {
  AlertPlugin,
  BreadcrumbPlugin,
  ButtonPlugin,
  FormDatepickerPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  PaginationPlugin,
  TabsPlugin,
  TablePlugin,
  ModalPlugin
} from 'bootstrap-vue';

window.bootstrap = require('bootstrap/dist/js/bootstrap.min.js');

const plugins = [
  AlertPlugin,
  BreadcrumbPlugin,
  ButtonPlugin,
  FormDatepickerPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  PaginationPlugin,
  TabsPlugin,
  TablePlugin,
  ModalPlugin
];

plugins.forEach(
  (plugin) => Vue.use(plugin)
);
