<template>
  <div>
    <div class="col-md-5 mb-2">
      <TxSearch @onSearch="search" />
    </div>
    <data-grid
      ref="table"
      :items="items"
      :limit="itemPerPage"
      :fields="fields"
      :total-records="totalRows"
      @pagination-update="getAmlSuspendedScreeningList"
      @entries-per-page="updateEntriesPerPage"
    >
      <template #originatorRouteCode="data">
        <TxScreeningProfileTd :profile="data.item.originator" />
      </template>
      <template #recipientRouteCode="data">
        <TxScreeningProfileTd :profile="data.item.recipient" />
      </template>
      <template #Direction="data">
        {{ data.item.transfer.inOrOut === 'IN' ? 'INCOMING' : 'OUTGOING' }}
      </template>
      <template #Reference="data">
        <TxReferenceTd :reference-items="getReference(data)" />
      </template>
      <template #Actions="data">
        <div class="d-flex gap-2 flex-nowrap align-items-center">
          <button
            class="btn btn-info d-flex flex-nowrap align-items-center"
            @click="$emit('showDetails', data.item)"
          >
            <i class="fa fa-search-plus" />&nbsp;&nbsp;Details
          </button>
          <button
            class="btn btn-info d-flex flex-nowrap align-items-center"
            @click="navigateSuspendAccount(data.item)"
          >
            <i class="fa fa-search-plus" />&nbsp;&nbsp;View&nbsp;Account
          </button>
          <button
            class="btn btn-success d-flex flex-nowrap align-items-center"
            @click="OFACChangeStatus(data.item, 'OFAC_APPROVE')"
          >
            <i class="fa fa-check" />&nbsp;&nbsp;Approve
          </button>
          <button
            class="btn btn-danger d-flex flex-nowrap align-items-center"
            @click="OFACChangeStatus(data.item, 'OFAC_REJECT')"
          >
            <i class="fa fa-trash" />&nbsp;&nbsp;Reject
          </button>
        </div>
      </template>
    </data-grid>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import apiService from '@/services/apiService';
import { amlScreeningResultAdapter } from '@/services/adapters/transaction-monitoring/amlScreeningResultAdapter';
import TxScreeningProfileTd from '@/components/transaction-monitoring/custom/TxScreeningProfileTd.vue';
import TxReferenceTd from '@/components/transaction-monitoring/custom/TxReferenceTd.vue';
import { useTxMonitoringStore } from '@/stores/txmonitoring';
import DataGrid from '@/components/shared/data-grid/index.vue';
import TxSearch from '@/components/transaction-monitoring/custom/TxSearch.vue';

export default {
  name: 'TxSuspended',
  components: {
    TxScreeningProfileTd,
    TxReferenceTd,
    DataGrid,
    TxSearch
  },
  data () {
    return {
      itemPerPage: 15,
      currentPage: 1,
      searchText: '',
      totalRows: 0,
      offset: 0,
      items: [],
      fields: [
        { key: 'dateScreened', label: 'Screened Date' },
        { key: 'transfer.id', label: 'Transfer ID' },
        { key: 'transfer.amount', label: 'Amount' },
        { key: 'originatorRouteCode', label: 'Originator bank routing code / account / name' },
        { key: 'recipientRouteCode', label: 'Recipient bank routing code / account / name' },
        { key: 'Direction', label: 'Direction' },
        { key: 'transfer.type', label: 'Type' },
        { key: 'transfer.paymentType', label: 'Payment Rails' },
        { key: 'Reference', label: 'Reference' },
        { key: 'Actions', label: 'Actions' }
      ]
    };
  },
  computed: {
    ...mapState(useTxMonitoringStore, ['needsToReloadList'])
  },
  watch: {
    currentPage () {
      this.getAmlSuspendedScreeningList();
    },
    needsToReloadList (val) {
      if (val) {
        this.getAmlSuspendedScreeningList();
        this.setNeedsToReloadList(false);
      }
    }
  },
  mounted () {
    this.getAmlSuspendedScreeningList();
  },
  methods: {
    ...mapActions(useTxMonitoringStore, ['setSelectedAction', 'setSelectedTransaction', 'setNeedsToReloadList']),
    getReference (reference) {
      return reference.item.transfer.reference || [];
    },
    updateEntriesPerPage (params) {
      this.itemPerPage = params;
      this.getAmlSuspendedScreeningList();
    },
    async getAmlSuspendedScreeningList (offset, searchId) {
      const params = {
        state: 'SUSPENDED',
        offset: offset || this.offset,
        sortOrder: 'DESC',
        limit: this.itemPerPage,
        transferId: searchId
      };

      try {
        const { data: { pageItems: amlScreeningResult, totalFilteredRecords: totalRows } } = await apiService.amlScreeningResult.getAll(params);
        this.totalRows = totalRows;
        this.items = amlScreeningResultAdapter(amlScreeningResult);
      } catch (e) {
        this.$notify.error(e);
      }
    },
    navigateSuspendAccount (data) {
      location.assign(`#/viewsavingaccount/${data.transfer.suspenseAccountId}`);
    },
    OFACChangeStatus (transaction, action) {
      this.setSelectedAction(action);
      this.setSelectedTransaction(transaction);
      this.$nextTick(() => this.$bvModal.show('tx-change-status-modal'));
    },
    search (id) {
      this.getAmlSuspendedScreeningList(this.offset, id);
    }
  }
};
</script>
