export function ViewLoanTransactionController (scope, resourceFactory, location, routeParams, dateFilter, $uibModal) {
  resourceFactory.loanTrxnsResource.get({ loanId: routeParams.accountId, transactionId: routeParams.id }, function (data) {
    scope.transaction = data;
    scope.transaction.accountId = routeParams.accountId;
  });

  scope.undo = function (accountId, transactionId) {
    $uibModal.open({
      templateUrl: 'undotransaction.html',
      controller: UndoTransactionModel,
      resolve: {
        accountId () {
          return accountId;
        },
        transactionId () {
          return transactionId;
        }
      }
    });
  };

  const UndoTransactionModel = function ($scope, $uibModalInstance, accountId, transactionId) {
    $scope.undoTransaction = function () {
      const params = { loanId: accountId, transactionId, command: 'undo' };
      const formData = { dateFormat: scope.df, locale: scope.optlang.code, transactionAmount: 0 };
      formData.transactionDate = dateFilter(new Date(), scope.df);
      resourceFactory.loanTrxnsResource.save(params, formData, function (data) {
        $uibModalInstance.close('delete');
        location.path(`/viewloanaccount/${data.loanId}`);
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewLoanTransactionController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams', 'dateFilter', '$uibModal'];
