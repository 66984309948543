export function RejectLoanRescheduleRequestController (scope, resourceFactory, routeParams, location, dateFilter) {
  scope.formData = {};
  scope.loanId = routeParams.loanId;
  scope.requestId = routeParams.requestId;

  scope.cancel = function () {
    location.path(`/loans/${scope.loanId}/viewreschedulerequest/${scope.requestId}`);
  };
  scope.reject = function () {
    this.formData.dateFormat = scope.df;
    this.formData.locale = scope.optlang.code;
    this.formData.rejectedOnDate = dateFilter(this.formData.rejectedOnDate, scope.df);

    resourceFactory.loanRescheduleResource.reject({ scheduleId: scope.requestId }, this.formData, function () {
      location.path(`/viewloanaccount/${scope.loanId}`);
    });
  };
}

RejectLoanRescheduleRequestController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location', 'dateFilter'];
