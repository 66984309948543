export function ViewMerchantChargeController (scope, routeParams, resourceFactory, location, $uibModal) {
  scope.mcccharge = {};
  resourceFactory.mccChargeResource.get({ id: routeParams.id }, function (data) {
    if (data.mcc) {
      scope.mcccharge.mcc = data.mcc;
      scope.mcccharge.name = data.name;
      scope.mcccharge.chargeMccMappingId = data.chargeMccMappingId;
      scope.mcccharge.amount = data.amount;
      scope.mcccharge.overrideCharge = data.overrideCharge;
    }
  });
  scope.deletemcccharge = function () {
    $uibModal.open({
      templateUrl: 'deletemcccharge.html',
      controller: TableDeleteCtrl
    });
  };
  const TableDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.mccChargeResource.delete({ id: routeParams.id }, {}, function () {
        $uibModalInstance.close('delete');
        location.path('/merchantcharges');
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewMerchantChargeController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location', '$uibModal'];
