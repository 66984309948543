<template>
  <div>
    <form @submit.prevent="addNote">
      <input
        v-model="noteText"
        type="text"
        :placeholder="$t('label.input.note')"
        class="form-control"
        required
      >
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-primary mt-2"
          :disabled="loading || !noteText"
          type="submit"
        >
          {{ $t('label.button.add') }}
        </button>
      </div>
    </form>
    <div
      v-if="notes.length"
      class="h5"
    >
      {{ $t('label.heading.notes') }}
    </div>
    <div
      v-for="(note, index) in notes"
      :key="index"
      class="mt-3"
    >
      <div class="mb-2">
        <span class="text-muted">{{ $t('label.head.id') }}: {{ note.id }} | </span> {{ note.note }}
      </div>
      <div>
        <span class="text-muted">{{ $t('label.head.createdBy') }}</span>: {{ note.createdByUsername }} |
        <span class="text-muted">{{ $t('label.head.createdAt') }}</span>: {{ dateStringToFormat(note.createdOn) }}
      </div>
      <hr v-if="index < notes.length - 1">
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useSharedStore } from '@/stores/shared';
import { dateStringToFormat } from '@/helper/dateHelper';

const sharedStore = useSharedStore();

const loading = computed(() => sharedStore.loading);

defineProps({
  notes: {
    type: Array,
    default: () => []
  }
});

const noteText = ref(null);

const emit = defineEmits(['on-submit']);

const addNote = () => {
  if (!noteText.value) return;
  emit('on-submit', noteText.value);
};

const clearNote = () => {
  noteText.value = '';
};

defineExpose({ clearNote });
</script>
