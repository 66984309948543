export function ViewAccRuleController (scope, resourceFactory, routeParams, location, $uibModal) {
  resourceFactory.accountingRulesResource.getById({ accountingRuleId: routeParams.id }, function (data) {
    scope.rule = data;
  });
  scope.deleteRule = function () {
    $uibModal.open({
      templateUrl: 'deleteaccrule.html',
      controller: AccRuleDeleteCtrl
    });
  };
  const AccRuleDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.accountingRulesResource.delete({ accountingRuleId: routeParams.id }, {}, function () {
        $uibModalInstance.close('delete');
        location.path('/accounting_rules');
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewAccRuleController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location', '$uibModal'];
