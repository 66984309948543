export function ViewRecurringPaymentController (scope, routeParams, resourceFactory) {
  let savingAccounts = [];
  let beneficiaries = [];
  scope.recurringPayment = {};
  scope.defaultDecimalPlaces = 2;
  scope.clientId = routeParams.clientId;

  resourceFactory.clientResource.getSavingAccounts({ clientId: scope.clientId, anotherresource: 'accounts' }, function (savingAccountData) {
    savingAccounts = savingAccountData.savingsAccounts;
    resourceFactory.beneficiariesResource.get(function (beneficaryData) {
      beneficiaries = beneficaryData.pageItems;
      resourceFactory.recurringPaymentResource.getRecurringPaymentDetails({ id: routeParams.id }, function (data) {
        const savingAccount = savingAccounts.find(item => item.id === data.savingsAccountId);
        const beneficiary = beneficiaries.find(item => item.id === data.beneficiaryId);
        scope.recurringPayment = data;
        scope.recurringPayment.savingAccount = savingAccount;
        scope.recurringPayment.beneficiary = beneficiary;
        scope.decimalPlaces = savingAccount?.currency?.decimalPlaces || scope.defaultDecimalPlaces;
      });
    });
  });
}

ViewRecurringPaymentController.$inject = ['$scope', '$routeParams', 'ResourceFactory'];
