export function ViewProvisioningCriteriaController (scope, routeParams, resourceFactory, location, $uibModal) {
  scope.charge = [];
  scope.choice = 0;
  let temp = '';
  resourceFactory.provisioningcriteria.get({ criteriaId: routeParams.criteriaId }, function (data) {
    scope.loanproducts = data.loanProducts;
    scope.definitions = data.definitions;
    scope.criterianame = data.criteriaName;
    scope.criteriaId = data.criteriaId;
    const length = data.loanProducts.length;
    for (let i = 0; i < length; i += 1) {
      temp = temp.concat(data.loanProducts[i].name);
      if (i < (length - 1)) {
        temp = temp.concat(', ');
      }
    }
    scope.formattedProductNames = temp;
  });

  scope.deleteProvisionigCriteria = function () {
    $uibModal.open({
      templateUrl: 'deletech.html',
      controller: criteriaDeleteCtrl
    });
  };

  const criteriaDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.provisioningcriteria.delete({ criteriaId: scope.criteriaId }, {}, function () {
        $uibModalInstance.close('delete');
        location.path('/viewallprovisionings/');
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewProvisioningCriteriaController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location', '$uibModal'];
