import { config } from '@/config/general.config';

export const StorageService = {

  setItem (key, value, engine) {
    if (angular.isUndefined(value)) {
      value = null;
    } else if (angular.isObject(value) || angular.isArray(value)) {
      value = angular.toJson(value);
    }
    window[this.validateEngine(engine)].setItem(this.getKeyWithPrefix(key), value);
  },

  getItem (key, engine) {
    const item = window[this.validateEngine(engine)].getItem(this.getKeyWithPrefix(key));
    if (!item || item === 'null') {
      return null;
    }
    if (item.charAt(0) === '{' || item.charAt(0) === '[') {
      return angular.fromJson(item);
    }
    return item;
  },

  removeItem (key, engine) {
    window[this.validateEngine(engine)].removeItem(this.getKeyWithPrefix(key));
  },

  getKeyWithPrefix (key) {
    let prefix = config.PREFIX;
    if (prefix.substr(-1) !== '.') {
      prefix = prefix ? `${prefix}.` : '';
    }
    return `${prefix}${key}`;
  },

  validateEngine (engine) {
    return engine === 'localStorage' ? 'localStorage' : 'sessionStorage';
  }

};
