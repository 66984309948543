<template>
  <form-wrapper
    ref="form"
    @submit="$emit('on-submit', formData)"
  >
    <input-select
      v-if="!isEdit"
      id="address-type"
      v-model="formData.addressTypeId"
      :label="$t('label.input.addressType')"
      :options="templates.addressTypeIdOptions"
      value-field="id"
      required
      @input="onAddressTypeChange"
    />
    <div
      v-if="isEdit"
      class="form-group row pb-3 align-items-center"
    >
      <label class="control-label text-end col-sm-4">{{ $t('label.input.addressType') }}</label>
      <div class="font-weight-bold col-sm-8">
        {{ currentAddressType }}
      </div>
    </div>

    <input-text
      v-for="formField in addressField"
      :id="formField.key"
      :key="formField.key"
      v-model="formData[formField.key]"
      :rules="formField.rules"
      :label="$t(`label.input.${formField.label}`)"
      :required="formField.requried"
    />

    <input-text
      id="city"
      v-model="formData.city"
      :label="$t('label.input.city')"
      :required="isPrimaryType"
    />

    <input-select
      id="country"
      v-model="selectedCountry"
      searchable
      :label="$t('label.input.country')"
      :options="templates.countryIdOptions"
      placeholder="Select Country"
      :required="isPrimaryType"
    />

    <input-select
      id="state-or-province"
      v-model="formData.stateProvinceId"
      searchable
      :label="$t('label.input.stateOrProvince')"
      :options="stateFilter"
      value-field="id"
      placeholder="Select State"
      :required="isPrimaryType"
    />
    <input-postal-code
      id="zip-postal-code"
      v-model="formData.postalCode"
      :label="$t('label.input.zipPostalCode')"
      :regex="zipCodeRegex"
      :required="isPrimaryType"
    />
    <div class="offset-6">
      <button
        class="btn btn-default me-3"
        @click="$emit('cancel')"
      >
        {{ $t('label.button.cancel') }}
      </button>

      <button
        id="save"
        type="submit"
        class="btn btn-primary"
      >
        {{ $t('label.button.save') }}
      </button>
    </div>
  </form-wrapper>
</template>

<script setup>
import apiService from '@/services/apiService';
import { InputText, InputSelect, FormWrapper, InputPostalCode } from '@/components/shared/form-input';
import i18n from '@/plugins/i18n';
import { getZipcodeFromIsoCode } from '@/utils/zipcodes';
import { reactive, computed, ref, onMounted, watch } from 'vue';
import router from '@/router';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { get, set } from '@vueuse/core';
import { maxLength } from '../shared/form-input/rules';

const breadCrumnsStore = useBreadcrumbsStore();

const props = defineProps({
  isEdit: {
    type: Boolean,
    default: false
  }
});

const formData = reactive({
  addressTypeId: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  countryId: '',
  stateProvinceId: '',
  postalCode: ''
});
const addressField = ref([
  { label: 'addressLineOne', key: 'addressLine1', requried: true, rules: [(value) => maxLength(value, 40)] },
  { label: process.env.VUE_APP_CLIENT === 'qorbis' ? 'companyAndUnit' : 'addressLineTwo', key: 'addressLine2', rules: [(value) => maxLength(value, 40)] },
  { label: 'addressLineThree', key: 'addressLine3', rules: [] }
]);
const templates = reactive({ addressTypeIdOptions: [], countryIdOptions: [], stateProvinceIdOptions: [] });
const selectedCountry = ref(null);
const zipCodeRegex = ref(null);
const form = ref(null);
const currentAddressType = ref();

onMounted(async () => {
  const { addrType, addrId, clientId } = router.currentRoute.params;
  const { data } = await apiService.clients.addressTemplate();
  Object.assign(templates, data);

  if (props.isEdit) {
    const { data: clientAddress } = await apiService.clientAddress.get(clientId, { type: addrType });
    const { addressTypeId, addressLine1, addressLine2, addressLine3, city, countryId, stateProvinceId, postalCode, addressType } = clientAddress[0];
    Object.assign(formData, {
      addressTypeId,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      countryId,
      stateProvinceId: stateProvinceId || null,
      postalCode,
      addressId: addrId
    });
    set(currentAddressType, addressType);
    const country = templates.countryIdOptions.find((country) => country.id === formData.countryId);
    set(selectedCountry, country);
  }

  const { primaryAccountId } = router.currentRoute.query;
  const subAccountBreadCrumbs = primaryAccountId
    ? [
        { text: i18n.t(`label.anchor.viewSubClient`), href: `#/saving-accounts/${primaryAccountId}/employee-accounts/client/${clientId}` }
      ]
    : [
        { text: i18n.t('label.anchor.viewClient'), href: `#/viewclient/${clientId}` }
      ];
  breadCrumnsStore.setBreadcrumbs([
    { text: i18n.t('label.anchor.clients'), href: '#/clients' },
    ...subAccountBreadCrumbs,
    { text: i18n.t(`label.anchor.${props.isEdit ? 'editAddress' : 'addAddress'}`) }
  ]);
});

const isPrimaryType = computed(() => {
  const primaryType = templates.addressTypeIdOptions.find((type) => type.name === 'PRIMARY');
  return formData.addressTypeId === primaryType?.id;
});

const stateFilter = computed(() => {
  const state = [];
  templates?.stateProvinceIdOptions.forEach(value => {
    if (value.parentId === get(selectedCountry)?.id) state.push(value);
  });
  return state;
});

watch(() => get(selectedCountry), (newValue, oldValue) => {
  const zipCode = getZipcodeFromIsoCode(newValue?.identifier);
  set(zipCodeRegex, zipCode);

  get(form).validate();
  formData.countryId = get(selectedCountry)?.id;
  if (oldValue) {
    formData.postalCode = '';
    formData.stateProvinceId = null;
  }
});

const onAddressTypeChange = () => {
  get(form).validate();
};

</script>
