<template>
  <base-modal
    id="bulk-import-form"
    ref="bulkImportForm"
    hide-footer
    hide-header-close
    @hidden="destroyDataWhenModalClose"
  >
    <template #header>
      <h4>
        <i class="fa-solid fa-download me-2 text-secondary" />
        {{ title }}
      </h4>
    </template>
    <template #body>
      <form-wrapper @submit="importFile">
        <div
          v-for="(itemOption, index) in itemOptions"
          :key="index"
        >
          <InputSelect
            :id="itemOption.label"
            v-model="formData[itemOption.model]"
            vertical
            :label="itemOption.label"
            :options="itemOption.item"
            :required="itemOption.required"
            :placeholder="itemOption.placeholder"
            :value-field="itemOption.valueField"
            :text-field="itemOption.textField"
          />
        </div>
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-default me-2 text-primary"
            @click.prevent="bulkImportForm.hide()"
          >
            {{ $t('label.button.cancel') }}
          </button>
          <button
            id="save"
            type="submit"
            class="btn btn-primary"
            :disabled="loading"
          >
            <i class="fa-solid fa-download me-1" />
            {{ $t('label.button.download') }}
          </button>
        </div>
      </form-wrapper>
    </template>
  </base-modal>
</template>

<script setup>
import { InputSelect, FormWrapper } from '@/components/shared/form-input';
import { ref } from 'vue';
import { get, set } from '@vueuse/core';
import BaseModal from '@/components/shared/BaseModal.vue';

const formData = ref({});
const bulkImportForm = ref(null);
defineProps({
  itemOptions: {
    type: Array,
    default: () => ([])
  },
  title: {
    type: String,
    default: ''
  },
  loading: {
    type: Boolean,
    default: false
  }
});

const emits = defineEmits(['import-file']);
const importFile = () => emits('import-file', get(formData));
const destroyDataWhenModalClose = () => set(formData, {});
</script>
