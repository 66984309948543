export function ListOnHoldTransactionController ($scope, resourceFactory, paginatorService, routeParams) {
  $scope.fromPath = routeParams.fromPath;
  $scope.fromPathId = routeParams.fromPathId;
  $scope.transactions = [];

  const fetchFunction = function (offset, limit, callback) {
    const params = {};
    params.offset = offset;
    params.limit = limit;
    params.locale = $scope.optlang.code;
    params.dateFormat = $scope.df;
    params.guarantorFundingId = routeParams.fundingId;
    params.savingsId = routeParams.savingsId;

    resourceFactory.savingsOnHoldTrxnsResource.get(params, callback);
  };

  $scope.transactions = paginatorService.paginate(fetchFunction, 14);
}

ListOnHoldTransactionController.$inject = ['$scope', 'ResourceFactory', 'paginatorService', '$routeParams'];
