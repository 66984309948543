import { amlNormalizationAdapter } from '@/services/adapters/transaction-monitoring/amlNormalizationAdapter';
import { findRecipientAndOriginator } from '@/services/adapters/transaction-monitoring/findRecipientAndOriginator';

export const amlScreeningResultAdapter = (data) => {
  let adaptedData = amlNormalizationAdapter(data);
  adaptedData = adaptedData.map(function (data) {
    const { recipient, originator, displayAmount } = findRecipientAndOriginator(data.transfer);
    return {
      ...data,
      recipient,
      originator,
      displayAmount
    };
  });

  // show watchman flagged
  adaptedData.forEach((screens, index) => {
    screens.details?.forEach(data => {
      if (data.ruleType === 'WATCHMAN') {
        adaptedData[index].watchmanId = data.id;
      }
    });
  });
  return adaptedData;
};
