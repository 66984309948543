import Vue from 'vue';
const state = Vue.observable({
  type: 'alert',
  active: false,
  message: '',
  title: '',
  okText: 'Ok',
  cancelText: 'Cancel',
  html: false
});

// -----------------------------------
// Private Methods
// -----------------------------------
let close; // will hold our promise resolve function
const promptPromise = () => new Promise((resolve) => (close = resolve));
const open = (message) => {
  state.message = message;
  state.active = true;
  return promptPromise();
};
const reset = () => {
  state.active = false;
  state.message = '';
  state.okText = 'Ok';
  state.cancelText = 'Cancel';
  state.html = false;
  state.title = '';
  state.type = 'alert';
};

// -----------------------------------
// Public
// -----------------------------------

const prompt = {
  get state () {
    return state;
  },
  title (title) {
    state.title = title;
    return this;
  },
  okText (text) {
    state.okText = text;
    return this;
  },

  cancelText (text) {
    state.cancelText = text;
    return this;
  },
  alert (message) {
    state.type = 'alert';
    return open(message);
  },
  confirm (message) {
    state.type = 'confirm';
    return open(message);
  },
  prompt (message) {
    state.type = 'prompt';
    return open(message);
  },
  cancel () {
    close(false);
    reset();
  },
  ok (input = true) {
    input = state.type === 'prompt' ? input : true;
    close(input);
    reset();
  }
};

export default prompt;
