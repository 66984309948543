// (function (module) {
//   mbanq.directives = _.extend(module, {
//     overlayDirective () {
//       return function (scope, element, attrs) {
//         const options = scope.$eval(attrs.mfOverlayOptions) || {}
//         const eventsMap = scope.$eval(attrs.mfOverlay)
//         const target = attrs.mfOverlayTarget

//         $.blockUI.defaults.css = {}
//         const settings = {
//           message: $(element),
//           overlayCSS: { opacity: (options.opacity || '0.5') },
//           fadeIn: options.fadeIn || 100,
//           fadeOut: options.fadeOut || 200
//         }

//         const actionsMap = (function () {
//           const selector = target ? $(target) : $
//           const showFn = target ? 'block' : 'blockUI'
//           const hideFn = target ? 'unblock' : 'unblockUI'
//           return {
//             show () {
//               selector[showFn](settings)
//             },
//             hide () {
//               selector[hideFn]()
//             }
//           }
//         }())

//         _.each(_.keys(eventsMap), function (actionName) {
//           const events = eventsMap[actionName].split(',')
//           _.each(events, function (eventName) {
//             scope.$on(eventName, actionsMap[actionName])
//           })
//         })
//       }
//     }
//   })
// }(mbanq.directives || {}))

// mbanq.ng.application.directive('mfOverlay', [mbanq.directives.overlayDirective]).run(function ($log) {
//   $log.info('overlayDirective initialized')
// })

import _ from 'underscore';

export function overlayDirective () {
  return function (scope, element, attrs) {
    const options = scope.$eval(attrs.mfOverlayOptions) || {};
    const eventsMap = scope.$eval(attrs.mfOverlay);
    const target = attrs.mfOverlayTarget;

    $.blockUI.defaults.css = {};
    const settings = {
      message: $(element),
      overlayCSS: { opacity: (options.opacity || '0.5') },
      fadeIn: options.fadeIn || 100,
      fadeOut: options.fadeOut || 200
    };

    const actionsMap = (function () {
      const selector = target ? $(target) : $;
      const showFn = target ? 'block' : 'blockUI';
      const hideFn = target ? 'unblock' : 'unblockUI';
      return {
        show () {
          selector[showFn](settings);
        },
        hide () {
          selector[hideFn]();
        }
      };
    }());

    _.each(_.keys(eventsMap), function (actionName) {
      const events = eventsMap[actionName].split(',');
      _.each(events, function (eventName) {
        scope.$on(eventName, actionsMap[actionName]);
      });
    });
  };
}
