export default {
  inject: {
    register: {
      default: false
    },
    unregister: {
      default: false
    }
  },
  data () {
    return {
      errorBucket: [],
      isValid: true,
      localRule: []
    };
  },
  computed: {
    validators () {
      const isValueEmpty = (value) => value === '' || value === null || value === undefined;
      const requiredRule = (value) => (this.required && isValueEmpty(value)) ? 'This field is required' : true;
      return [...this.rules, ...this.localRule, requiredRule];
    },
    errorMessage () {
      return this.errorBucket.length ? this.errorBucket[0] : '';
    }
  },
  beforeMount () {
    if (this.register) this.register(this);
  },
  beforeUnmount () {
    if (this.unregister) this.unregister(this);
  },
  methods: {
    async validatorRunner () {
      this.errorBucket = [];
      await this.$nextTick();
      const inputElement = this.$refs.input;
      inputElement?.setCustomValidity('');

      for (const rule of this.validators) {
        const validatedResult = rule(this.value);
        if (typeof validatedResult === 'string') {
          inputElement?.setCustomValidity(' ');
          this.errorBucket.push(validatedResult);
        }
        if (typeof validatedResult === 'undefined' || validatedResult === null) {
          console.warn('function must return error message as string');
        }
      }
      return this.errorBucket;
    }
  }
};
