<template>
  <ck-editor
    :value="value"
    :config="editorConfig"
    @input="handleInput"
  />
</template>

<script>
import CKEditor from 'ckeditor4-vue';

export default {
  components: {
    ckEditor: CKEditor.component
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      editorConfig: {
        allowedContent: true
      }
    };
  },
  methods: {
    handleInput (HTMLText) {
      this.$emit('change', HTMLText);
    }
  }
};
</script>
