export const cardButtonStatuses = {
  ordered: [
    {
      action: 'activate',
      taskPermissionName: 'ACTIVATE_CARD'
    },
    {
      action: 'return',
      taskPermissionName: 'RETURN_CARD'
    }
  ],
  inactive: [
    {
      action: 'activate',
      taskPermissionName: 'ACTIVATE_CARD'
    },
    {
      action: 'return',
      taskPermissionName: 'RETURN_CARD'
    }
  ],
  suspended: [
    {
      action: 'activate',
      taskPermissionName: 'ACTIVATE_CARD'
    },
    {
      action: 'terminate',
      taskPermissionName: 'TERMINATE_CARD'
    },
    {
      action: 'return',
      taskPermissionName: 'RETURN_CARD'
    }
  ],
  active: [
    {
      action: 'suspend',
      taskPermissionName: 'SUSPEND_CARD'
    },
    {
      action: 'terminate',
      taskPermissionName: 'TERMINATE_CARD'
    },
    {
      action: 'return',
      taskPermissionName: 'RETURN_CARD'
    }
  ],
  renewed: [
    {
      action: 'terminate',
      taskPermissionName: 'TERMINATE_CARD'
    }
  ],
  terminated: [
    {
      action: 'reactivate',
      taskPermissionName: 'REACTIVATE_CARD'
    }
  ]
};
export const validEditStatus = ['active', 'suspended'];
