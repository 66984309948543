import _ from 'underscore';

export function CreateTaxGroupController ($scope, resourceFactory, location, dateFilter) {
  $scope.taxComponents = [];
  $scope.restrictDate = new Date('2099-12-31');
  $scope.formData = {};
  $scope.tempAccounts = [];
  resourceFactory.taxgrouptemplate.get(function (data) {
    $scope.data = data;
  });

  $scope.addTaxComponent = function () {
    const taxComponent = {
      date: new Date()
    };
    $scope.taxComponents.push(taxComponent);
  };

  $scope.deleteTaxComponent = function (index) {
    $scope.taxComponents.splice(index, 1);
  };

  $scope.copyForSubmit = function () {
    $scope.formData.taxComponents = [];
    _.each($scope.taxComponents, function (taxcomponent) {
      const taxComponentDetail = {};
      taxComponentDetail.taxComponentId = taxcomponent.taxComponentId;
      taxComponentDetail.startDate = dateFilter(taxcomponent.date, $scope.df);
      $scope.formData.taxComponents.push(taxComponentDetail);
    });
  };

  $scope.submit = function () {
    this.formData.locale = $scope.optlang.code;
    $scope.copyForSubmit();
    this.formData.dateFormat = $scope.df;
    resourceFactory.taxgroup.save(this.formData, function (data) {
      location.path(`/viewtaxgroup/${data.resourceId}`);
    });
  };
}

CreateTaxGroupController.$inject = ['$scope', 'ResourceFactory', '$location', 'dateFilter'];
