<template>
  <span>
    <button
      class="btn btn-primary"
      :disabled="disabled"
      @click="actionPrompt()"
    >
      {{ $t('label.button.process') }}
    </button>
    <base-modal
      ref="disputeProcessModal"
      modal-size="lg"
      hide-footer
    >
      <template #body>
        <form-wrapper @submit="onSubmit">
          <input-select
            v-model="closureType"
            :label="$t('label.input.closureType')"
            :options="templates.closureType"
            value-field="value"
            required
            @input="onClosureTypeChange"
          />
          <input-select
            v-model="formData.closureReasonTypeCode"
            :label="$t('label.input.closureReason')"
            :options="templates.closureReason"
            value-field="id"
            required
          />
          <input-text
            v-model="formData.note"
            type="textarea"
            :label="$t('label.input.note')"
          />
          <div class="d-flex justify-content-center">
            <button
              type="reset"
              class="btn btn-default"
              data-bs-dismiss="modal"
            >
              {{ $t('label.button.cancel') }}
            </button>
            <button
              type="submit"
              :disabled="loading"
              class="btn btn-primary"
            >
              {{ $t('label.button.submit') }}
            </button>
          </div>
        </form-wrapper>
      </template>
    </base-modal>
  </span>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import { ref, computed } from 'vue';
import { useSharedStore } from '@/stores/shared';
import { InputSelect, InputText, FormWrapper } from '@/components/shared/form-input';
import apiService from '@/services/apiService';
import { disputeClosureTypes } from '@/utils/templates';

const sharedStore = useSharedStore();
const loading = computed(() => sharedStore.loading);

defineProps({
  currentItem: {
    type: Object,
    default: () => ({})
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const templates = ref({
  closureType: disputeClosureTypes,
  closureReason: []
});
const closureType = ref(null);

const formData = ref({
  closureReasonTypeCode: null,
  note: null
});

const disputeProcessModal = ref(null);

const onClosureTypeChange = async () => {
  formData.value.closureReasonTypeCode = null;
  const codeValue = closureType.value === 'DENIED' ? 'DisputeReasonsDenied' : 'DisputeReasonsAccepted';
  const { data } = await apiService.codeValueResource.getCodeValueByName(codeValue);
  templates.value.closureReason = data;
};

const actionPrompt = () => {
  disputeProcessModal.value.show();
};

const closePropmt = () => {
  disputeProcessModal.value.hide();
};
const emit = defineEmits(['on-submit']);
const onSubmit = () => {
  emit('on-submit', formData.value, closureType.value);
};

defineExpose({ closePropmt });
</script>
