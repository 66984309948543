export function ViewExternalServicesController ($scope, resourceFactory, $routeParams, location) {
/**
 * Created by 27 on 05-08-2015.
 */
  $scope.Configs = [];
  $scope.externalServicesType = $routeParams.externalServicesType;
  // $scope.name = $routeParams.name;
  resourceFactory.externalServicesResource.get({ id: $scope.externalServicesType }, function (data) {
    for (const i in data) {
      if (data[i] !== null && data[i].name !== null) {
        data[i].name.replace(/ /g, '');
        if (!angular.equals(data[i].name, '')) {
          $scope.Configs.push(data[i]);
        }
      }
    }
  });

  $scope.cancel = function () {
    location.path('/externalservices');
  };
}

ViewExternalServicesController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location'];
