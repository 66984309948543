<template>
  <div class="row mt-3">
    <div
      v-for="(sideFields, index) in fieldItems"
      :key="index"
      class="col-sm-6"
    >
      <base-table
        v-for="(sideField, childrenIndex) in sideFields"
        :key="childrenIndex"
        vertical
        :title="sideField.title"
        :items="sideField.item"
      >
        <template
          v-for="(_, slotName) in $scopedSlots"
          #[slotName]="props"
        >
          <slot
            :name="slotName"
            v-bind="props"
          />
        </template>
      </base-table>
    </div>
  </div>
</template>

<script setup>
import BaseTable from '@/components/shared/data-grid/BaseTable.vue';

defineProps({
  itemInfo: {
    type: Object,
    default: () => ({})
  },
  fieldItems: {
    type: Array,
    default: () => ([])
  }
});
</script>
