export function CurrencyConfigController (scope, resourceFactory, route) {
  scope.selectedCurrOptions = [];
  scope.allCurrOptions = [];
  scope.hideview = false;
  scope.selected = undefined;

  resourceFactory.currenciesResource.get(function (data) {
    scope.selectedCurrOptions = data.selectedCurrencyOptions;
    scope.allCurrOptions = data.currencyOptions;
  });

  scope.deleteCur = function (code) {
    const index = scope.selectedCurrOptions.findIndex(option => option.code === code);
    if (index !== -1) {
      scope.allCurrOptions.push(scope.selectedCurrOptions[index]);
      scope.selectedCurrOptions.splice(index, 1);
    }
  };

  scope.addCur = function () {
    if (scope.selected) {
      const index = scope.allCurrOptions.findIndex(option => option.id === scope.selected);

      if (index !== -1) {
        scope.selectedCurrOptions.push(scope.allCurrOptions[index]);
        scope.allCurrOptions.splice(index, 1);
      }
    }

    scope.selected = undefined;
  };

  scope.submit = function () {
    const currencies = [];
    const curr = {};
    for (let i = 0; i < scope.selectedCurrOptions.length; i++) {
      currencies.push(scope.selectedCurrOptions[i].code);
    }
    curr.currencies = currencies;
    resourceFactory.currenciesResource.update(curr, function () {
      route.reload();
    });
  };

  scope.cancel = function () {
    route.reload();
  };

  scope.handleCurrentUpdate = (value) => {
    scope.selected = value;
  };
}

CurrencyConfigController.$inject = ['$scope', 'ResourceFactory', '$route'];
