import { dateStringToFormat } from '../../../helper/dateHelper';

export const amlNormalizationAdapter = (data) => {
  if (!data || !data.length) return [];

  return data.map(function (data) {
    let normalizeData = Object.assign(data, {
      transactionStatus: getTransactionStatusByState(data.state),
      dateScreened: dateStringToFormat(data.dateScreened, 'DD MMMM YYYY h:mm:ss a')
    });

    if (data.stateChanges && data.stateChanges.length) {
      const newStateChanges = [];
      data.stateChanges.forEach(function (item) {
        item.updatedAt = dateStringToFormat(item.updatedAt, 'DD MMMM YYYY h:mm:ss a');
        newStateChanges.push(item);
      });

      normalizeData = Object.assign(normalizeData, { stateChanges: newStateChanges });
    }

    return normalizeData;
  });
};

function getTransactionStatusByState (state) {
  const status = {
    GO: 'Accepted',
    STOP: 'Rejected'
  };
  return status[state] || state;
}
