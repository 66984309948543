<template>
  <main-content-layout>
    <template #title>
      Transactions
    </template>
    <fieldset>
      <b-tabs lazy>
        <b-tab
          v-for="(m, mind) in menu"
          :key="`btab-${m.title}`"
          :title="m.title"
          :active="mind === 0"
        >
          <component
            :is="m.component"
            class="pt-3"
            @showDetails="showDetails"
            @getPendingReturnsDetails="getPendingReturnsDetails"
          />
        </b-tab>
      </b-tabs>
    </fieldset>

    <tx-details-modal />
    <tx-transaction-change-status-modal />
    <tx-force-post-transfer-modal />
    <tx-edit-transfer-modal />
  </main-content-layout>
</template>

<script>
import TxScreening from '@/components/transaction-monitoring/tabs/TxScreening.vue';
import TxHistory from '@/components/transaction-monitoring/tabs/TxHistory.vue';
import TxPendingReturnACH from '@/components/transaction-monitoring/tabs/TxPendingReturnACH.vue';
import TxPendingReturnNOC from '@/components/transaction-monitoring/tabs/TxPendingReturnNOC.vue';
import TxPendingReturnWIRE from '@/components/transaction-monitoring/tabs/TxPendingReturnWIRE.vue';
import TxPendingReturnSWIFT from '@/components/transaction-monitoring/tabs/TxPendingReturnSWIFT.vue';
import TxSuspended from '@/components/transaction-monitoring/tabs/TxSuspended.vue';
import PageTitle from '@/components/shared/PageTitle.vue';
import SwiftManual from '@/components/transaction-monitoring/tabs/SwiftManual.vue';
import TxDetailsModal from '@/components/transaction-monitoring/modals/TxDetailsModal.vue';
import TxForcePostTransferModal from '@/components/transaction-monitoring/modals/TxForcePostTransferModal.vue';
import TxEditTransferModal from '@/components/transaction-monitoring/modals/TxEditTransferModal.vue';
import TxTransactionChangeStatusModal from '@/components/transaction-monitoring/modals/TxTransactionChangeStatusModal.vue';
import apiService from '@/services/apiService';
import { useTxMonitoringStore } from '@/stores/txmonitoring';
import { useSharedStore } from '@/stores/shared';
import { mapActions } from 'pinia';
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';

export default {
  name: 'TxnMonitoringPage',
  components: {
    PageTitle,
    TxDetailsModal,
    TxTransactionChangeStatusModal,
    TxForcePostTransferModal,
    TxEditTransferModal,
    MainContentLayout
  },
  data () {
    return {
      menu: [
        { title: 'Screening', component: TxScreening },
        { title: 'Screened History', component: TxHistory },
        { title: 'Pending Return ACH', component: TxPendingReturnACH },
        { title: 'Pending NOC', component: TxPendingReturnNOC },
        { title: 'Pending Return WIRE', component: TxPendingReturnWIRE },
        { title: 'Pending Return SWIFT', component: TxPendingReturnSWIFT },
        { title: 'Suspended', component: TxSuspended },
        { title: 'Swift Manual Update', component: SwiftManual }
      ]
    };
  },
  mounted () {
    this.getCountries();
  },
  methods: {
    ...mapActions(useTxMonitoringStore, ['setSelectedTransaction', 'setWatchmanLists']),
    ...mapActions(useSharedStore, ['getCountries']),
    async showDetails (item) {
      this.setSelectedTransaction(item);
      this.$nextTick(() => this.$bvModal.show('tx-details-modal'));
    },
    async getPendingReturnsDetails (item) {
      try {
        const { data: response } = await apiService.amlScreeningResult.getTransferDetails(item.id);
        this.showDetails({ ...response[0], details: response[0].details, transfer: { ...item }, transactionStatus: item.status });
      } catch (e) {
        this.$notify.error(e);
      }
    }
  }
};
</script>
