// (function (window, document) {

// Create all modules and define dependencies to make sure they exist
// and are loaded in the correct order to satisfy dependency injection
// before all nested files are concatenated by Grunt

// config
angular.module('ngCsv.config', [])
  .value('ngCsv.config', {
    debug: true
  })
  .config(['$compileProvider', function ($compileProvider) {
    if (angular.isDefined($compileProvider.urlSanitizationWhitelist)) {
      $compileProvider.urlSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|data):/);
    } else {
      $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|data):/);
    }
  }]);

// Modules
angular.module('ngCsv.directives', []);
angular.module('ngCsv',
  [
    'ngCsv.config',
    'ngCsv.directives',
    'ngSanitize'
  ]);
/**
     * ng-csv module
     * Export Javascript's arrays to csv files from the browser
     *
     * Author: asafdav - https://github.com/asafdav
     */

// })(window, document)
angular
  .module('ngCsv.directives', [])
  .directive('ngCsv', ['$parse', () => {
    return {
      restrict: 'AC',
      replace: true,
      transclude: true,
      scope: { data: '&ngCsv', filename: '@filename', header: '&csvHeader' },
      controller: ['$scope', '$attrs', function ($scope, $attrs) {
        $scope.csv = '';
        $scope.$watch($scope.data, (newValue) => {
          $scope.buildCsv(newValue);
        }, true);

        $scope.buildCsv = (data) => {
          let csvContent = 'data:text/csv;charset=utf-8,';

          // Check if there's a provided header array
          if (angular.isDefined($attrs.csvHeader)) {
            const header = $scope.$eval($scope.header);
            let encodingArray;

            if (angular.isArray(header)) {
              encodingArray = header;
            } else {
              encodingArray = [];
              angular.forEach(header, (title) => {
                this.push(title);
              }, encodingArray);
            }

            const headerString = encodingArray.join(',');
            csvContent += `${headerString}\n`;
          }

          // Process the data
          angular.forEach(data, (row, index) => {
            let infoArray;

            if (angular.isArray(row)) {
              infoArray = row;
            } else {
              infoArray = [];
              angular.forEach(row, (field) => {
                this.push(field);
              }, infoArray);
            }

            const dataString = `"${infoArray.join('","')}"`;
            csvContent += index < data.length ? `${dataString}\n` : dataString;
          });

          $scope.csv = encodeURI(csvContent);
        };

        $scope.getFilename = () => {
          return $scope.filename ? $scope.filename : 'download.csv';
        };
      }],
      template: `
        <div class="csv-wrap">
          <div class="element" ng-transclude></div>
          <a class="hidden-link" ng-hide="true" ng-href="{{ csv }}" download="{{ getFilename() }}"></a>
        </div>
      `,
      link (_, element) {
        const subject = angular.element(element.children()[0]);
        const link = angular.element(element.children()[1]);

        subject.on('click', () => {
          link[0].click();
        });
      }
    };
  }]);
