<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.heading.addOrEditPhoneNumber', { action: get(isEditing) ? 'Edit' : 'Add'}) }}
    </template>
    <div class="col-lg-4">
      <form-wrapper>
        <input-select
          v-model="formData.typeId"
          :label="$t('label.input.phoneNumberType')"
          :options="phoneNumberTypes"
          text-field="name"
          value-field="id"
          required
          vertical
          not-clearable
        />
        <input-text
          v-model="formData.mobileCountryCode"
          :label="$t('label.input.countryCode')"
          :rules="[mobileFormat.countryCode]"
          placeholder="+###"
          vertical
          required
        />
        <input-text
          v-model="formData.mobileNo"
          :label="$t('label.input.mobileNumber')"
          :rules="[mobileFormat.number]"
          vertical
          required
        />
        <div class="d-flex justify-content-center gap-2">
          <button
            type="reset"
            class="btn btn-outline-secondary"
            @click="back"
          >
            {{ $t('label.button.cancel') }}
          </button>
          <button
            class="btn btn-primary"
            @click="submit"
          >
            {{ get(isEditing) ? $t('label.button.edit') : $t('label.button.save') }}
          </button>
        </div>
      </form-wrapper>
    </div>
  </main-content-layout>
</template>

<script setup>
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import {
  FormWrapper,
  InputSelect,
  InputText
} from '@/components/shared/form-input/index';
import router from '@/router/index';
import apiService from '@/services/apiService';
import { computed, onMounted, reactive, ref } from 'vue';
import { get, set } from '@vueuse/core';
import { $notify } from '@/plugins/prototypes';
import i18n from '@/plugins/i18n';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { createValidator } from '@/components/shared/form-input/rules';

const breadcrumbsStore = useBreadcrumbsStore();
const { phoneNumberId, clientId } = router.currentRoute.params;
const { phoneNumberType, primaryAccountId } = router.currentRoute.query;
const formData = ref({ typeId: null });
const phoneNumberTypes = ref([]);
const mobileFormat = reactive({
  countryCode: null,
  number: null
});
const isEditing = computed(() => !!phoneNumberId);

onMounted(async () => {
  try {
    const fetchers = [setBreadcrumbs, getPhoneNumberTypes, getTemplate];
    if (phoneNumberId) fetchers.push(getPhoneNumberData);

    await Promise.all(fetchers.map(fetcher => fetcher()));

    get(formData).typeId = parseInt(phoneNumberType) || get(phoneNumberTypes)[0].id;
  } catch (error) {
    $notify.error(error);
  }
});

async function submit () {
  try {
    if (!phoneNumberId) await apiService.clientPhoneNumber.save(clientId, get(formData));
    else await apiService.clientPhoneNumber.put(clientId, phoneNumberId, get(formData));

    router.push(`/viewclient/${clientId}`);
    $notify.success(i18n.t(get(isEditing) ? 'messages.phoneNumberUpdated' : 'messages.newPhoneNumberAdded'));
  } catch (error) {
    $notify.error(error);
  }
}

async function getTemplate () {
  const { data: { mobileNumberFormat } } = await apiService.clients.template();

  const countryCodeRegex = mobileNumberFormat.find(format => format.name === 'mobile-country-code-regex')?.value;
  const mobileRegex = mobileNumberFormat.find(format => format.name === 'mobile-number-regex')?.value;

  mobileFormat.number = createValidator(mobileRegex, 'Invalid mobile number');
  mobileFormat.countryCode = createValidator(countryCodeRegex, 'Invalid country code');
}

async function getPhoneNumberData () {
  const { data } = await apiService.clientPhoneNumber.get(clientId, phoneNumberId);

  data.typeId = data.type;
  delete data.type;

  set(formData, data);
}

async function getPhoneNumberTypes () {
  const { data } = await apiService.codeValueResource.getCodeValueByName('Phone Number Type');
  set(phoneNumberTypes, data);
}

async function setBreadcrumbs () {
  const { data: { displayName } } = await apiService.clients.get(clientId);

  const breadcrumbs = [
    { text: i18n.t('label.anchor.clients'), href: '#/clients' },
    { text: displayName, href: `#/viewclient/${clientId}` },
    { text: i18n.t('label.anchor.addOrEditPhoneNumber', { action: get(isEditing) ? 'Edit' : 'Add' }) }
  ];

  breadcrumbsStore.setBreadcrumbs(breadcrumbs);
}

function back () {
  router.push({ path: primaryAccountId ? `/saving-accounts/${primaryAccountId}/employee-accounts/client/${clientId}` : `/viewclient/${clientId}` });
}
</script>
