export function SettleAmountSavingsController ($scope, resourceFactory, location, routeParams) {
  $scope.errorMsg = '';
  $scope.cancelRoute = routeParams.id;

  resourceFactory.paymentTypeResource.getAll(function (data) {
    $scope.paymentTypes = data;
  });

  resourceFactory.savingsAmountResource.get({ savingsAccountId: routeParams.id }, function (data) {
    const { pendingAmount } = data.pageItems.find(holdFund => holdFund.id.toString() === routeParams.authorizationId);
    $scope.amount = pendingAmount;
  }, handleResponseError);

  $scope.submit = function () {
    const formData = {
      amount: $scope.amount,
      authorizationId: routeParams.authorizationId
    };
    if ($scope.paymentType) {
      formData.paymentType = $scope.paymentType;
    }
    resourceFactory.savingsAmountResource.settlement({ savingsAccountId: routeParams.id }, formData, function () {
      location.path(`/viewsavingaccount/${routeParams.id}`);
    }, handleResponseError);
  };
  function handleResponseError (error) {
    const { data } = error;
    $scope.errorMsg = data.errors[0].defaultUserMessage;
  }

  $scope.closeAlert = function () {
    $scope.errorMsg = '';
  };
}

SettleAmountSavingsController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams'];
