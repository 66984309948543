export function SearchFilter () {
  return function (list, searchText) {
    const searchRegx = new RegExp(searchText, 'i');
    if (searchText === undefined) {
      return list;
    }
    const result = [];
    for (let i = 0; i < list.length; i++) {
      if (list[i].name.search(searchRegx) !== -1 ||
        list[i].glCode.toString().search(searchRegx) !== -1 || list[i].type.value.search(searchRegx) !== -1) {
        result.push(list[i]);
      }
    }
    return result;
  };
}
