<template>
  <div class="row">
    <div class="col-lg-6">
      <form-wrapper
        class="row g-3 align-items-center"
        @submit="handleSubmit"
      >
        <div class="col-9">
          <input-text
            :id="commentType"
            v-model="formData.comments"
            :label="$t(`label.input.${commentType}Comments`)"
            :rows="3"
            type="textarea"
            required
          />
        </div>

        <div class="col-3">
          <button
            type="submit"
            class="btn btn-primary"
          >
            {{ $t('label.button.submit') }}
          </button>
        </div>
      </form-wrapper>
    </div>
  </div>
</template>

<script>
import apiService from '@/services/apiService';
import { mapActions, mapState } from 'pinia';
import { useTxMonitoringStore } from '@/stores/txmonitoring';
import { InputText, FormWrapper } from '@/components/shared/form-input';

export default {
  components: {
    InputText,
    FormWrapper
  },
  props: {
    commentType: {
      type: String,
      required: true,
      validator (value) {
        return ['verifier', 'qc'].includes(value);
      }
    }
  },
  data () {
    return {
      formData: {}
    };
  },
  computed: {
    ...mapState(useTxMonitoringStore, ['transaction'])
  },
  methods: {
    ...mapActions(useTxMonitoringStore, ['setNeedsToReloadList']),
    async handleSubmit () {
      const command = {
        verifier: 'verify',
        qc: 'qc'
      };

      try {
        await apiService.amlScreeningResult.update(this.transaction.id, { command: command[this.commentType] }, this.formData);
        await apiService.amlScreeningResult.getTransferDetails(this.transaction.id);
        this.$bvModal.hide('tx-details-modal');
        this.setNeedsToReloadList(true);
      } catch (error) {
        this.$notify.error(error);
      }
    }
  }
};
</script>
