export function CreateDataTableController (scope, routeParams, resourceFactory, location, filter) {
  scope.columns = [];
  scope.columnnameerror = false;
  scope.columntypeerror = false;
  scope.datatableTemplate = {};
  scope.labelerror = 'requiredfield';
  scope.columnIndex = 0;
  scope.sectionIndex = 0;
  scope.sections = [];
  scope.sectionTemplate = {};
  scope.showClientTypeDropdown = false;
  scope.clientLegalFormOptions = [
    { id: 1, value: 'PERSON' },
    { id: 2, value: 'ENTITY' }
  ];
  scope.columnOptions = [{ id: null, name: 'Select None', secIndex: -1, colIndex: -1 }];
  scope.isParentColumnFieldEnabled = false;
  scope.filteredOptions = [];

  resourceFactory.codeResources.getAllCodes({}, function (data) {
    scope.codes = data;
  });
  scope.generateTableName = function () {
    scope.formData.datatableName = `d_${scope.formData.datatableTitle?.replace(/\s/g, '_').toLowerCase()}`;
  };
  scope.generateColumnName = function (column) {
    column.name = column.title.replace(/\s/g, '_').toLowerCase();
  };
  scope.generateColumnNameForNew = function () {
    scope.datatableTemplate.columnName = scope.datatableTemplate.columnDisplayName.replace(/\s/g, '_').toLowerCase();
  };
  scope.addColumn = function () {
    if (scope.datatableTemplate.columnName && scope.datatableTemplate.columnType) {
      scope.columnnameerror = false;
      scope.columntypeerror = false;
      if (scope.datatableTemplate.columnType === 'fileupload') {
        scope.columns.push({
          name: scope.datatableTemplate.columnName,
          type: scope.datatableTemplate.columnType,
          mandatory: false,
          code: 'FILE_UPLOAD',
          length: 200,
          title: scope.datatableTemplate.columnDisplayName,
          description: scope.datatableTemplate.columnDisplayName,
          position: scope.columnIndex,
          sectionIndex: scope.sectionIndex,
          parentNameAttribute: scope.datatableTemplate.parentNameAttribute
        });
      } else if (scope.datatableTemplate.columnType === 'multiselect') {
        scope.columns.push({
          name: scope.datatableTemplate.columnName,
          type: scope.datatableTemplate.columnType,
          mandatory: false,
          length: 200,
          title: scope.datatableTemplate.columnDisplayName,
          description: scope.datatableTemplate.columnDisplayName,
          position: scope.columnIndex,
          sectionIndex: scope.sectionIndex,
          parentNameAttribute: scope.datatableTemplate.parentNameAttribute
        });
      } else {
        scope.columns.push({
          name: scope.datatableTemplate.columnName,
          type: scope.datatableTemplate.columnType,
          mandatory: false,
          title: scope.datatableTemplate.columnDisplayName,
          description: scope.datatableTemplate.columnDescription,
          position: scope.columnIndex,
          sectionIndex: scope.sectionIndex,
          parentNameAttribute: scope.datatableTemplate.parentNameAttribute
        });
      }

      if (scope.datatableTemplate.columnType === 'Boolean') {
        scope.columnOptions.push({
          id: scope.datatableTemplate.columnName,
          name: scope.datatableTemplate.columnDisplayName,
          secIndex: scope.sectionIndex,
          colIndex: scope.columnIndex
        });
        scope.isParentColumnFieldEnabled = true;
      }
      scope.filteredOptions = filterParentColumnBasedInColumnIndex(scope.sectionIndex, scope.columnIndex);
      scope.datatableTemplate = {};
      scope.columnIndex = scope.columnIndex + 1;
    } else if (!scope.datatableTemplate.columnName) {
      scope.errorDetails = [];
      scope.columnnameerror = true;
      scope.labelerror = 'columnnameerr';
    } else if (scope.datatableTemplate.columnName) {
      scope.errorDetails = [];
      scope.columntypeerror = true;
      scope.labelerror = 'columntypeerr';
    }
  };
  function filterParentColumnBasedInColumnIndex (secIndex, colIndex) {
    return scope.columnOptions.filter(function (_option) {
      return secIndex >= _option.secIndex || colIndex > _option.colIndex;
    });
  }
  scope.addSection = function () {
    scope.sectionError = '';
    if (!scope.sectionTemplate.title) {
      scope.sectionError = 'Title requried';
      return;
    }
    if (!scope.columns.length) {
      scope.sectionError = 'Columns requried';
      return;
    }
    scope.sections.push({
      name: scope.sectionTemplate.title.replace(/\s/g, '_').toLowerCase(),
      columns: scope.columns,
      title: scope.sectionTemplate.title,
      description: scope.sectionTemplate.description,
      position: scope.sectionIndex,
      parentNameAttribute: scope.sectionTemplate.parentNameAttribute
    });
    scope.sectionIndex = scope.sectionIndex + 1;
    scope.sectionTemplate = {};
    scope.datatableTemplate = {};
    scope.columns = [];
    scope.columnIndex = 0;
  };
  scope.removeColumn = function (index) {
    const colName = removeColumnOption(undefined, index);
    scope.columns.splice(index, 1);
    removeParentNameAttribute(colName);
  };
  scope.removeSection = function (index) {
    const colName = removeColumnOption(index, undefined);
    scope.sections.splice(index, 1);
    removeParentNameAttribute(colName);
  };
  function removeColumnOption (secIndex, colIndex) {
    let match = false;
    let i = 0;
    for (; i < scope.columnOptions.length; i++) {
      const temp = scope.columnOptions[i];
      if (secIndex !== undefined && colIndex !== undefined && temp.secIndex === secIndex && temp.colIndex === colIndex) {
        match = true;
      } else if (secIndex === undefined && colIndex !== undefined && temp.colIndex === colIndex) {
        match = true;
      } else if (secIndex !== undefined && colIndex === undefined && temp.secIndex === secIndex) {
        match = true;
      }
      if (match) {
        break;
      }
    }
    if (match) {
      const colName = scope.columnOptions[i].id;
      scope.columnOptions.splice(i, 1);
      return colName;
    }
  }
  function removeParentNameAttribute (colName) {
    for (const i in scope.sections) {
      if (scope.sections[i].parentNameAttribute !== undefined && scope.sections[i].parentNameAttribute === colName) {
        delete scope.sections[i].parentNameAttribute;
      } else if (scope.sections[i].parentNameAttribute === undefined) {
        for (const j in scope.sections[i].columns) {
          if (scope.sections[i].columns[j].parentNameAttribute !== undefined && scope.sections[i].columns[j].parentNameAttribute === colName) {
            delete scope.sections[i].column[j].parentNameAttribute;
          }
        }
      }
    }
    if (scope.columnOptions.length === 1 && scope.columnOptions[0].id === undefined) {
      scope.isParentColumnFieldEnabled = false;
    }
  }
  scope.removeColumnSection = function (colIndex, secIndex) {
    const colName = removeColumnOption(secIndex, colIndex);
    for (const i in scope.sections) {
      const tempSec = scope.sections[i];
      if (tempSec.position === secIndex) {
        tempSec.columns.splice(colIndex, 1);
      }
    }
    removeParentNameAttribute(colName);
  };
  scope.updateDepenedencies = function (colIndex, secIndex) {
    for (const i in scope.sections) {
      const tempSec = scope.sections[i];
      if (tempSec.position === secIndex) {
        tempSec.columns.splice(colIndex, 1);
        if (tempSec.columns[colIndex].type !== 'string') {
          delete tempSec.columns[colIndex].length;
        }
        if (tempSec.columns[colIndex].type !== 'dropdown' && tempSec.columns[colIndex].type !== 'multiselect') {
          delete tempSec.columns[colIndex].code;
        }
        if (tempSec.columns[colIndex].type !== 'fileupload') {
          delete tempSec.columns[colIndex];
        }
      }
    }
  };

  scope.displayClientTypeDropdown = function (appTableName) {
    if (appTableName !== null && appTableName === 'm_client') {
      scope.showClientTypeDropdown = true;
    } else {
      scope.showClientTypeDropdown = false;
      scope.formData.legalFormId = null;
    }
  };

  scope.submit = function () {
    scope.formData.sections = [];
    if (scope.sections.length === 0) {
      scope.errorDetails = [];
      scope.errorDetails.push({ message: filter('translate')('error.msg.sectionRequired') });
    } else {
      delete scope.errorDetails;
      scope.formData.multiRow = scope.formData.multiRow || false;
      for (const i in scope.sections) {
        scope.formData.sections.push(scope.sections[i]);
      }

      resourceFactory.DataTablesResource.save(this.formData, function (data) {
        location.path(`/viewdatatable/${data.resourceIdentifier}`);
      });
    }
  };
}

CreateDataTableController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location', '$filter'];
