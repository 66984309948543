import _ from 'underscore';

export function UserFormController ($scope, resourceFactory) {
  $scope.offices = [];
  $scope.roles = [];
  resourceFactory.officeResource.getAllOffices({}, function (data) {
    $scope.offices = data;
  });
  resourceFactory.roleResource.getAllRoles({}, function (data) {
    $scope.roles = data;
  });

  $scope.$on('OpenUserFormDialog', function () {
    $scope.userFormData = { selectedRoles: {}, sendPasswordToEmail: false };
    $scope.formInError = false;
    $scope.errors = [];
  });

  $scope.submitUserForm = function () {
    $scope.formInError = false;
    $scope.errors = [];
    const userData = {
      username: $scope.userFormData.username,
      firstname: $scope.userFormData.firstname,
      lastname: $scope.userFormData.lastname,
      email: $scope.userFormData.email,
      officeId: $scope.userFormData.office.id,
      sendPasswordToEmail: $scope.userFormData.sendPasswordToEmail,
      roles: _.map(
        _.keys($scope.userFormData.selectedRoles), function (roleId) {
          return parseInt(roleId, 10);
        }
      )
    };

    $scope.$emit('SubmitUserFormStart');
    resourceFactory.userResource(userData).$save({},
      function (data) {
        userData.id = data.resourceId;
        $scope.users.push(userData);
        $scope.$emit('SubmitUserFormSuccess');
      },
      function (response) {
        $scope.formInError = true;
        $scope.errors = response.data.errors;
        $scope.$emit('SubmitUserFormError');
      }
    );
  };

  $scope.cancelUserForm = function () {
    $scope.$emit('CloseUserForm');
  };
}

UserFormController.$inject = ['$scope', 'ResourceFactory'];
