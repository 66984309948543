export function prettifyDataTableColumn () {
  const prettifyColumnName = function (input, split) {
    const temp = input.split(split);
    if (temp[1] && temp[1] !== '') {
      return temp[1];
    }
    return temp[0];
  };

  return function (input) {
    // hide lots of underfined error in browser console
    if (typeof (input) === 'undefined') {
      return '';
    }
    let retVal;
    if (input.indexOf('_cd_') > 0) {
      retVal = prettifyColumnName(input, '_cd_');
    } else if (input.indexOf('_cv_') > 0) {
      retVal = prettifyColumnName(input, '_cv_');
    } else if (input.indexOf('_cd') > 0) {
      retVal = prettifyColumnName(input, '_cd');
    } else if (input.indexOf('_cv') > 0) {
      retVal = prettifyColumnName(input, '_cv');
    } else {
      retVal = input;
    }
    return retVal;
  };
}
