export function ViewLoanCollateralController (scope, resourceFactory, routeParams, location, $uibModal) {
  scope.loanId = routeParams.loanId;
  scope.collateralId = routeParams.id;
  scope.showEditButtons = routeParams.status === 'Submitted and pending approval';
  resourceFactory.loanResource.get({ resourceType: 'collaterals', loanId: scope.loanId, resourceId: scope.collateralId }, function (data) {
    scope.collateral = data;
  });
  scope.deleteCollateral = function () {
    $uibModal.open({
      templateUrl: 'deletecollateral.html',
      controller: CollateralDeleteCtrl
    });
  };
  const CollateralDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.loanResource.delete({ resourceType: 'collaterals', loanId: scope.loanId, resourceId: scope.collateralId }, {}, function () {
        $uibModalInstance.close('delete');
        location.path(`/viewloanaccount/${scope.loanId}`);
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewLoanCollateralController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location', '$uibModal'];
