export function TableSortableDirective () {
  return {
    link (_, element) {
      const sortable = element.find('.sortable');
      sortable.bind('click', function () {
        const $this = $(this);
        const asc = $this.hasClass('asc');
        const desc = $this.hasClass('desc');
        sortable.removeClass('asc').removeClass('desc');
        if (desc || (!asc && !desc)) {
          $this.addClass('asc');
          return;
        }
        $this.addClass('desc');
      });
    }
  };
}
