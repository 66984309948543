export function AdHocQueryListController ($scope, resourceFactory, location) {
  $scope.adhocquerys = [];

  $scope.routeTo = function (id) {
    location.path(`/viewadhocquery/${id}`);
  };

  resourceFactory.adHocQueryResource.getAllAdHocQuery(function (data) {
    $scope.adhocquerys = data;

    for (let j = 0; j < data.length; j += 1) {
      const d = data[j];
      for (let i = 0; i < d.reportRunFrequencies.length; i += 1) {
        if (d.reportRunFrequency === d.reportRunFrequencies[i].id) {
          d.reportRunFrequency = d.reportRunFrequencies[i].code;
          break;
        }
      }
    }
  });
}

AdHocQueryListController.$inject = ['$scope', 'ResourceFactory', '$location'];
