export function DataTableWorkflowController (scope, resourceFactory, location) {
  scope.routeTo = function (entityName) {
    location.path(`/viewdatatableworkflow/${entityName}`);
  };

  if (!scope.searchCriteria.datatableworkflows) {
    scope.searchCriteria.datatableworkflows = null;
    scope.saveSC();
  }
  scope.filterText = scope.searchCriteria.datatableworkflows || '';

  scope.onFilter = function () {
    scope.searchCriteria.datatableworkflows = scope.filterText;
    scope.saveSC();
  };

  scope.DataTablesPerPage = 15;
  resourceFactory.DataTablesWorkflowResource.getAllDataTableWorkflows(function (data) {
    scope.datatableworkflows = data;
  });
}

DataTableWorkflowController.$inject = ['$scope', 'ResourceFactory', '$location'];
