import LoginPage from '@/pages/login/index.vue';
import RequestResetPasswordPage from '@/pages/login/request-reset-password.vue';
import TotpOrOtpPage from '@/pages/login/totp-or-otp.vue';
import RequestResetPasswordSuccessPage from '@/pages/login/request-reset-password-success.vue';
import RequestOtpPage from '@/pages/login/request-otp.vue';
import ResetPasswordPage from '@/pages/login/reset-password.vue';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      public: true
    }
  },
  {
    path: '/reset-password/:token([a-zA-Z]*\\d+[a-zA-Z]*|\\d+[a-zA-Z]+[a-zA-Z0-9]*)',
    name: 'ResetPasswordPage',
    component: ResetPasswordPage,
    meta: {
      public: true
    }
  },
  {
    path: '/reset-password',
    name: 'RequestResetPassword',
    component: RequestResetPasswordPage,
    meta: {
      public: true
    }
  },
  {
    path: '/reset-password/requested',
    name: 'RequestResetPasswordSuccess',
    component: RequestResetPasswordSuccessPage,
    meta: {
      public: true
    }
  },
  {
    path: '/totp',
    name: 'Totp',
    component: TotpOrOtpPage,
    meta: {
      public: true
    }
  },
  {
    path: '/request-otp',
    name: 'RequestOtp',
    component: RequestOtpPage,
    meta: {
      public: true
    }
  },
  {
    path: '/otp',
    name: 'Otp',
    component: TotpOrOtpPage,
    meta: {
      public: true
    }
  }
];

export default routes;
