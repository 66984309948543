export function EditStandingInstructionController (scope, resourceFactory, location, routeParams, dateFilter) {
  scope.restrictDate = new Date();
  const params = {};
  scope.formData = {};
  scope.showselctclient = 'false';
  scope.allowclientedit = 'true';
  scope.isEditableAmount = true;
  scope.standingInstructionId = routeParams.instructionId;
  if (routeParams.from === 'list') {
    scope.fromlist = true;
  }
  scope.from = routeParams.from;
  params.standingInstructionId = scope.standingInstructionId;
  resourceFactory.standingInstructionResource.withTemplate(params, function (data) {
    scope.standinginstruction = data;
    scope.formData = {
      priority: data.priority.id,
      status: data.status.id,
      instructionType: data.instructionType.id,
      amount: data.amount,
      validFrom: data.validFrom,
      validTill: data.validTill,
      recurrenceType: data.recurrenceType.id,
      recurrenceInterval: data.recurrenceInterval
    };
    if (data.repaymentOnDay) {
      scope.formData.repaymentOnDay = data.repaymentOnDay;
    }
    if (data.recurrenceFrequency) {
      scope.formData.recurrenceFrequency = data.recurrenceFrequency.id;
    }

    if (data.fromClient.id === data.toClient.id) {
      scope.allowclientedit = false;
    }
    if (data.recurrenceOnMonthDay) {
      const d = new Date();
      const year = d.getFullYear();
      const day = data.recurrenceOnMonthDay.day;
      const month = data.recurrenceOnMonthDay.month;
      data.recurrenceOnMonthDay = [month, day, year];
      const actDate = dateFilter(data.recurrenceOnMonthDay, 'dd MMMM');
      scope.recurrenceOnMonthDay = new Date(actDate);
    }
    scope.formData.validFrom = new Date(scope.formData.validFrom);
    if (scope.formData.validTill) {
      scope.formData.validTill = new Date(scope.formData.validTill);
    }
  });

  scope.submit = function () {
    this.formData.locale = scope.optlang.code;
    this.formData.dateFormat = scope.df;
    if (this.formData.validFrom) this.formData.validFrom = dateFilter(this.formData.validFrom, scope.df);
    if (this.formData.validTill) this.formData.validTill = dateFilter(this.formData.validTill, scope.df);
    if (this.recurrenceOnMonthDay) {
      const reqDate = dateFilter(this.recurrenceOnMonthDay, 'dd MMMM');
      this.formData.recurrenceOnMonthDay = reqDate;
      this.formData.monthDayFormat = 'dd MMMM';
    }
    resourceFactory.standingInstructionResource.update({ standingInstructionId: scope.standingInstructionId }, this.formData, function () {
      location.path(`/viewstandinginstruction/${scope.standingInstructionId}`);
    });
  };

  scope.checkEditAmount = (standinginstructionId) => {
    const instructionCode = scope.standinginstruction?.instructionTypeOptions.find(item => item.id === standinginstructionId)?.code;
    const nonEditableAmount = ['standingInstructionType.statement.balance', 'standingInstructionType.min.due'];
    if (nonEditableAmount.includes(instructionCode)) {
      scope.isEditableAmount = false;
      // use to delete the amount key when submit to avoid confusion and make the input null when select other type
      // even if we add key amount it still unnecessary since it auto pay implementation
      delete scope.formData.amount;
    } else scope.isEditableAmount = true;
  };

  scope.cancel = function () {
    if (scope.fromlist) {
      scope.viewliststandinginstruction();
    } else {
      scope.viewstandinginstruction();
    }
  };

  scope.viewstandinginstruction = function () {
    location.path(`/viewstandinginstruction/${scope.standingInstructionId}`);
  };

  scope.viewliststandinginstruction = function () {
    location.path(`/liststandinginstructions/${scope.standinginstruction.fromClient.officeId}/${scope.standinginstruction.fromClient.id}`);
  };
}

EditStandingInstructionController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams', 'dateFilter'];
