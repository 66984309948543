<template>
  <div class="btn-group">
    <button
      v-if="validEditStatus.includes(cardItem?.status.toLowerCase())"
      class="btn btn-primary"
      @click="triggerSecurityModal"
    >
      <i class="fas fa-pencil-alt" />
      {{ $t('label.button.editSecurity') }}
    </button>
    <base-modal
      ref="modifySecurity"
      modal-size="lg"
      scrollable
      @on-submit="onSubmit"
      @on-cancel="onCancel"
    >
      <template #header>
        <div>
          <h4>{{ $t('label.heading.editSecurity') }}</h4>
        </div>
      </template>
      <template #body>
        <div class="row m-0 pt-3">
          <div class="col-10">
            <input-check-box
              v-for="(cardSecurityItem, index) in cardSecurityItems"
              :id="cardSecurityItem.id"
              :key="index"
              v-model="formData[cardSecurityItem.model]"
              :label="cardSecurityItem.label"
            />
            <input-select
              v-model="formData.blockedCountries"
              :options="formmatCountriesKeyValue(regions)"
              :label="$t('label.anchor.reason')"
              multiple
              text-field="name"
              value-field="label"
            />
          </div>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import apiService from '@/services/apiService';
import i18n from '@/plugins/i18n';
import { validEditStatus } from '@/utils/dictionaries/cardButtonStatuses.dictionary';
import { InputSelect, InputCheckBox } from '@/components/shared/form-input/index';
import { regions } from '@/utils/dictionaries';
import { $notify } from '@/plugins/prototypes';
import { ref, reactive, onMounted, watch } from 'vue';
import { get } from '@vueuse/core';

const props = defineProps({
  cardItem: {
    type: Object,
    default: () => ({})
  },
  cardType: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['onStatusChanged']);

const modifySecurity = ref('');
const cardSecurityItems = ref([
  { id: 'online-payments', label: i18n.t('label.anchor.enabledOnlinePayments'), model: 'onlinePaymentEnabled' },
  { id: 'contactless-payments', label: i18n.t('label.anchor.enabledContactlessPayments'), model: 'contactlessPaymentEnabled' },
  { id: 'atm-withdrawal', label: i18n.t('label.anchor.enabledAtmWithdrawal'), model: 'atmWithdrawalsEnabled' },
  { id: 'international-payments', label: i18n.t('label.anchor.enabledInternationalPayments'), model: 'internationalPaymentsEnabled' },
  { id: 'pos-payment', label: i18n.t('label.anchor.enabledPosPayments'), model: 'posPaymentEnabled' }
]);
const formData = reactive({
  onlinePaymentEnabled: false,
  contactlessPaymentEnabled: false,
  atmWithdrawalsEnabled: false,
  internationalPaymentsEnabled: false,
  posPaymentEnabled: false,
  blockedCountries: []
});

const getCardItemSecurity = () => {
  const {
    onlinePaymentEnabled,
    contactlessPaymentEnabled,
    atmWithdrawalsEnabled,
    internationalPaymentsEnabled,
    posPaymentEnabled,
    blockedCountries
  } = props.cardItem;

  return {
    onlinePaymentEnabled,
    contactlessPaymentEnabled,
    atmWithdrawalsEnabled,
    internationalPaymentsEnabled,
    posPaymentEnabled,
    blockedCountries
  };
};

watch(() => props.cardItem, () => {
  Object.assign(formData, getCardItemSecurity());
});

onMounted(() => {
  Object.assign(formData, getCardItemSecurity());
});

const formmatCountriesKeyValue = (countries) =>
  Object.entries(countries).map(value => {
    return {
      label: value[0],
      name: value[1]
    };
  });

const triggerSecurityModal = () => {
  get(modifySecurity).show();
};

const onCancel = () => {
  get(modifySecurity).hide();
  Object.assign(formData, getCardItemSecurity());
};

const onSubmit = async () => {
  try {
    await apiService.cardsResource.update(props.cardItem.id, formData);
    emit('onStatusChanged');
    onCancel();
    $notify.success(i18n.t('label.updateSecuritySuccessfully'));
  } catch (e) {
    $notify.error(e);
  }
};
</script>
