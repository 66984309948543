<template>
  <form-wrapper
    class="col-12 col-lg-6"
    @submit="onSubmit"
  >
    <input-text
      id="username"
      v-model="formData.username"
      :label="$t('label.input.username')"
      required
    />
    <input-text
      id="firstname"
      v-model="formData.firstname"
      :label="$t('label.input.firstname')"
      required
    />
    <input-text
      id="lastname"
      v-model="formData.lastname"
      :label="$t('label.input.lastname')"
      required
    />
    <input-text
      id="email"
      v-model="formData.email"
      :label="$t('label.input.email')"
      :rules="[emailValidate]"
      required
    />
    <input-mobile
      id="mobile"
      v-model="formData.mobile"
      :label="$t('label.input.mobileNumber')"
    />
    <input-check-box
      v-if="!isEdit"
      id="send-password-to-email"
      v-model="formData.sendPasswordToEmail"
      :label="$t('label.input.autoGeneratePassword')"
    />
    <input-text
      v-if="!formData.sendPasswordToEmail && !isEdit"
      id="password"
      v-model="formData.password"
      type="password"
      required
      :label="$t('label.input.password')"
    />
    <input-text
      v-if="!formData.sendPasswordToEmail && !isEdit"
      id="repeat-password"
      v-model="formData.repeatPassword"
      type="password"
      required
      :label="$t('label.input.repeatPassword')"
    />
    <input-check-box
      id="password-never-expires"
      v-model="formData.passwordNeverExpires"
      :label="$t('label.input.overridePasswordExpiryPolicy')"
      :hint="$t('label.tooltips.overridePasswordExpiry')"
    />
    <input-check-box
      v-if="!isEdit"
      id="reset-password-after-first-login"
      v-model="formData.resetPasswordAfterFirstLogin"
      :label="$t('label.input.resetPasswordAfterFirstLogin')"
    />
    <input-check-box
      id="is-self-service-user"
      v-model="formData.isSelfServiceUser"
      :disabled="isEdit"
      :label="$t('label.input.selfServiceUser')"
    />
    <input-select
      id="office-id"
      v-model="formData.officeId"
      :label="$t('label.input.office')"
      :options="template.office"
      value-field="id"
      required
      @input="getStaff"
    />
    <input-select
      id="staff-id"
      v-model="formData.staffId"
      :label="$t('label.input.staff')"
      value-field="id"
      text-field="displayName"
      :options="template.staff"
    />
    <input-select
      id="roles"
      v-model="formData.roles"
      multiple
      :label="$t('label.input.roles')"
      value-field="id"
      :options="availableRoles"
      required
    />
    <div class="d-flex justify-content-center">
      <button
        class="btn btn-default"
        type="reset"
        @click="goBack"
      >
        {{ $t('label.button.cancel') }}
      </button>
      <button
        v-has-permission="getPermission"
        class="btn btn-primary"
        type="submit"
      >
        {{ $t('label.button.save') }}
      </button>
    </div>
  </form-wrapper>
</template>

<script setup>
import { FormWrapper, InputText, InputCheckBox, InputSelect, InputMobile } from '@/components/shared/form-input';
import { ref, reactive, onMounted, computed, watch } from 'vue';
import apiService from '@/services/apiService';
import { $notify } from '@/plugins/prototypes';
import { emailValidate } from '@/components/shared/form-input/rules.js';
import router from '@/router';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import i18n from '@/plugins/i18n';

const breadcrumbStore = useBreadcrumbsStore();

const formData = reactive({
  username: null,
  firstname: null,
  lastname: null,
  email: null,
  mobile: null,
  sendPasswordToEmail: true,
  password: null,
  repeatPassword: null,
  passwordNeverExpires: null,
  resetPasswordAfterFirstLogin: true,
  isSelfServiceUser: false,
  officeId: null,
  staffId: null,
  roles: []
});

const template = reactive({
  office: [],
  staff: [],
  roles: []
});

const isEdit = computed(() => !!userId);

const availableRoles = computed(() => {
  return template.roles.filter((role) => role.isSelfService === formData.isSelfServiceUser).sort(
    (a, b) => a.name.localeCompare(b.name)
  );
});

const getPermission = computed(() => isEdit.value ? 'UPDATE_USER' : 'CREATE_USER');
const username = ref('');
onMounted(async () => {
  try {
    const { data: { allowedOffices, availableRoles } } = await apiService.userResource.template();
    if (isEdit.value) {
      await getUser();
      breadcrumbStore.setBreadcrumbs([
        { text: i18n.t('label.anchor.users'), href: '#/users' },
        { text: username.value, href: `#/users/${userId}` },
        { text: i18n.t('label.anchor.editUser') }
      ]);
    }
    template.office = allowedOffices;
    template.roles = availableRoles;
  } catch (error) {
    $notify.error(error);
  }
});

const { userId } = router.currentRoute.params;

const getUser = async () => {
  try {
    const { data } = await apiService.userResource.get(userId);
    username.value = data.username;
    formData.username = data.username;
    formData.firstname = data.firstname;
    formData.lastname = data.lastname;
    formData.email = data.email;
    formData.mobile = data.mobile;
    formData.passwordNeverExpires = data.passwordNeverExpires;
    formData.isSelfServiceUser = data.isSelfServiceUser;
    formData.officeId = data.officeId;
    await getStaff();
    formData.staffId = data.staff?.id;
    formData.roles = data.selectedRoles.map(role => role.id);
  } catch (error) {
    $notify.error(error);
  }
};

const getStaff = async () => {
  const { data } = await apiService.staffResource.getAll({ officeId: formData.officeId, status: 'all' });
  template.staff = data;
};

const emits = defineEmits(['on-submit', 'on-cancel']);

const goBack = () => {
  emits('on-cancel');
};

const onSubmit = () => {
  emits('on-submit', formData);
};

watch(() => formData.isSelfServiceUser, () => {
  formData.roles = [];
});
</script>
