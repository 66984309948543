<template>
  <div>
    <div class="d-flex align-items-center justify-content-end pb-3">
      <input
        id="showInterestAccruals"
        v-model="showInterestAccruals"
        name="showInterestAccruals"
        type="checkbox"
        @change="onShowInterestAccruals($event)"
      >
      <label
        class="form-check-label h5 mb-0 ms-1"
        for="showInterestAccruals"
      >
        {{ $t('label.heading.showInterestAccruals') }}
      </label>
      <button
        v-if="items.length > 0"
        class="btn btn-primary ms-2"
        @click="viewJournalEntries"
      >
        {{ $t('label.button.viewJournalEntries') }}
      </button>
      <button
        class="btn btn-primary ms-2"
        :disabled="showInterestAccruals || !selectedRows.length"
        @click="createDispute"
      >
        {{ $t('label.button.raiseDispute') }}
      </button>
    </div>
    <data-grid
      :fields="fields"
      :items="items"
      :limit="limit"
      :total-records="totalRecords"
      :multiselect="multiselect"
      clickable
      hide-entries-per-page
      @pagination-update="onPaginationUpdate"
      @sort-column="changeTransactionSort"
      @click-row="routeTo"
    >
      <template #checkbox="{ item }">
        <input
          v-model="selectedRows"
          type="checkbox"
          :disabled="isTxDisabled(item)"
          :checked="selectedRows.includes(item)"
          :value="item"
        >
      </template>
      <template #id="{ item }">
        {{ item.id }}
        <span
          v-if="item.dispute?.length"
          v-tooltips="$t(`label.${getLastDispute(item)?.status === 'OPEN'? 'disputeProcessing' : 'disputeClosed'}`)"
          class="ms-2 fa fa-repeat fa-xs"
          :class="getLastDispute(item)?.status === 'OPEN' ? 'text-success' : 'text-danger'"
        />
      </template>
      <template #bookingDate="{ item }">
        {{ dateStringToFormat(item.bookingDate) }}
      </template>
      <template #date="{ item }">
        {{ dateStringToFormat(item.date) }}
      </template>
      <template #subTransactionType="{ item }">
        {{ item.subTransactionType?.value }}
      </template>
      <template #debit="{ item }">
        {{ item.transactionType.isDebit ? currencyFormatter(item.amount) : '' }}
      </template>
      <template #credit="{ item }">
        {{ !item.transactionType.isDebit ? currencyFormatter(item.amount) : '' }}
      </template>
      <template #actions="{ item }">
        <button
          v-if="item.dispute?.length"
          v-tooltips="$t('label.button.viewDispute')"
          class="btn btn-light"
          @click.stop="gotoDispute(item)"
        >
          <span class="fas fa-magnifying-glass-dollar" />
        </button>
        <button
          v-if="item.transactionType.id === 7 || item.transactionType.id === 4"
          v-tooltips="$t('label.button.undoCharge')"
          v-has-permission="'REVERSE_CREDIT_CARD_CHARGE'"
          class="btn btn-light"
          @click.stop="reverseCharge(item.id)"
        >
          <span class="fas fa-undo" />
        </button>
      </template>
    </data-grid>
  </div>
</template>

<script setup>
import DataGrid from '@/components/shared/data-grid/index.vue';
import { ref, onMounted } from 'vue';
import i18n from '@/plugins/i18n';
import { dateStringToFormat } from '@/helper/dateHelper';
import { currencyFormatter } from '@/helper/currencyHelper';
import router from '@/router';
import apiService from '@/services/apiService.js';
import { useDisputeCreditTxStore } from '@/stores/disputeCreditTx';

const disputeCreditTxStore = useDisputeCreditTxStore();

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  totalRecords: {
    type: Number,
    default: 0
  },
  limit: {
    type: [Number, String],
    default: 15
  },
  multiselect: {
    type: Boolean,
    default: false
  },
  creditAccountDetails: {
    type: Object,
    default: () => ({})
  },
  cardId: {
    type: String,
    default: ''
  }
});

const showInterestAccruals = ref(false);
const selectedRows = ref([]);

const fields = ref([
  { key: 'id', label: i18n.t('label.heading.id'), sortable: true },
  { key: 'bookingDate', label: i18n.t('label.heading.bookingDate'), sortable: true },
  { key: 'date', label: i18n.t('label.heading.transactionDate'), sortable: true },
  { key: 'transactionType.value', label: i18n.t('label.heading.transactiontype'), sortable: true },
  { key: 'subTransactionType.value', label: i18n.t('label.heading.subTransactionType'), sortable: true },
  { key: 'paymentDetailData.reference', label: i18n.t('label.heading.reference'), sortable: true },
  { key: 'debit', label: i18n.t('label.heading.debit'), sortable: true },
  { key: 'credit', label: i18n.t('label.heading.credit'), sortable: true },
  { key: 'actions', label: i18n.t('label.heading.actions') }
]);
const disputableTemplate = ref('');

onMounted(async () => {
  const { data: { templatecode } } = await apiService.creditCardDispute.getcreditTxTemplate();
  disputableTemplate.value = templatecode;
});

const emit = defineEmits(['pagination-update', 'sort-column', 'click-row', 'row-select', 'reverse-charge']);

const getLastDispute = (item) => {
  if (!item.dispute?.length) return;
  return item.dispute[item.dispute?.length - 1];
};

const isTxDisabled = (item) => {
  if (showInterestAccruals.value || item.dispute?.length) return true;
  if (!selectedRows.value.length) return item.disputable.toUpperCase() !== 'YES';
  if (item.disputable.toUpperCase() !== 'YES') return true;

  const selectTxType = selectedRows.value[0]?.transactionType?.id;
  const selectTxSubType = selectedRows.value[0]?.subTransactionType?.id;

  const { id: txId } = item.transactionType;
  const { id: subTxId } = item.subTransactionType;

  for (const { transactionType, transactionSubType } of disputableTemplate.value) {
    if (transactionType === selectTxType) {
      for (const { transactionSubType: subType, disputeInclude } of transactionSubType) {
        if (subType === selectTxSubType &&
          disputeInclude.some(({ transactionType: txType, transactionSubType: txSubType }) =>
            txType === txId && txSubType === subTxId)) {
          return false;
        }
      }
    }
  }
  return true;
};

const createDispute = () => {
  disputeCreditTxStore.setSelectedTransactions(selectedRows.value);
  disputeCreditTxStore.setSelectedCreditCard(props.creditAccountDetails);
  const clientId = props.creditAccountDetails?.creditAccountData?.clientId;
  router.push(`/clients/${clientId}/credit-accounts/${props.cardId}/create-dispute`);
};

const viewJournalEntries = () => {
  router.push({
    name: 'SearchJournalEntries',
    query: {
      creditAccountId: props.creditAccountDetails?.creditAccountData?.id,
      cardId: props.cardId
    }
  });
};

const onShowInterestAccruals = () => {
  selectedRows.value = [];
  emit('show-interest-accrual', showInterestAccruals.value);
};

const onPaginationUpdate = (offset) => {
  emit('pagination-update', offset);
};

const changeTransactionSort = (field) => {
  emit('sort-column', field);
};

const routeTo = (item) => {
  emit('click-row', item.id);
};

const reverseCharge = (id) => {
  emit('reverse-charge', id);
};

const gotoDispute = (item) => {
  router.push({ name: 'ViewDispute', params: { disputeId: getLastDispute(item).id } });
};
</script>
