<template>
  <container-login :title="$t('label.head.twoFactorAuthentication')">
    <p>{{ $t('label.pleaseEnterTheAuthenticationToken') }}</p>
    <form-wrapper @submit="onConfirmTotpOrOtp">
      <input-text
        v-model="formData.token"
        :label="$t('label.input.authenticationToken')"
        vertical
      />
      <button
        type="submit"
        class="btn btn-primary w-100 d-flex justify-content-center"
      >
        {{ $t('label.button.signIn') }}
      </button>
    </form-wrapper>
  </container-login>
</template>

<script setup>
import ContainerLogin from '@/components/login/Container.vue';
import router from '@/router';
import { FormWrapper, InputText } from '@/components/shared/form-input/index';
import apiService, { setAxios2faHeader } from '@/services/apiService';
import { TokenService } from '@/services/token.service';
import { $notify } from '@/plugins/prototypes';
import { reactive } from 'vue';
import { useUserStore } from '@/stores/user';

const userStore = useUserStore();
const currentRoute = router.currentRoute;
const formData = reactive({
  token: null,
  deliveryMethod: currentRoute.name === 'Otp' ? 'EMAIL' : 'TOTP'
});

const onConfirmTotpOrOtp = async () => {
  try {
    const { data } = await apiService.authentication.confirmTotpOrOtp(formData);
    TokenService.save2FAToken(data.token);
    setAxios2faHeader(data.token);
    await userStore.updateUserDetails();
  } catch (error) {
    $notify.error(error);
  }
};
</script>
