export function EditDataTableController (scope, routeParams, resourceFactory, location) {
  scope.columns = [];
  scope.dropColumns = [];
  scope.dropSections = [];
  scope.formData = {};
  scope.columnnameerror = false;
  scope.columntypeerror = false;
  scope.datatableTemplate = {};
  scope.labelerror = 'requiredfield';
  scope.sections = [];
  scope.sectionTemplate = {};
  scope.showClientTypeDropdown = false;
  scope.clientLegalFormOptions = [
    { id: 1, value: 'PERSON' },
    { id: 2, value: 'ENTITY' }
  ];
  scope.columnOptions = [];
  scope.isParentColumnFieldEnabled = false;
  resourceFactory.codeResources.getAllCodes({}, function (data) {
    scope.codes = data;
  });

  resourceFactory.DataTablesResource.getTableDetails({ datatablename: routeParams.tableName }, function (data) {
    scope.datatable = data;
    scope.datatable.breadcrumbName = scope.datatable.datatableTitle || scope.datatable.registeredTableName;
    scope.formData.apptableName = data.applicationTableName;
    scope.formData.datatableTitle = data.datatableTitle;
    scope.formData.description = data.description;
    scope.formData.resourcePath = data.resourcePath;
    scope.formData.datatableName = data.registeredTableName;
    scope.formData.multiRow = data.isMultiRow;
    scope.formData.legalFormId = (scope.datatable.legalFormId > 0) ? scope.datatable.legalFormId : null;

    scope.displayClientTypeDropdown(data.applicationTableName);

    let temp = [];
    let colName = data.columnHeaderData[0].columnName;
    if (colName === 'id') {
      data.columnHeaderData.splice(0, 1);
    }
    colName = data.columnHeaderData[0].columnName;
    if (colName === 'client_id' || colName === 'office_id' || colName === 'group_id' || colName === 'center_id' || colName === 'loan_id' || colName === 'savings_account_id' || colName === 'credit_account_id') {
      data.columnHeaderData.splice(0, 1);
    }
    const secColumnTemplateList = [];
    let tempSec;
    for (const i in data.sections) {
      secColumnTemplateList.push({ index: data.sections[i].id, section: data.sections[i] });
    }
    for (const i in data.columnHeaderData) {
      data.columnHeaderData[i].originalName = data.columnHeaderData[i].columnName;
      if (data.columnHeaderData[i].columnName.indexOf('_cd_') > 0) {
        temp = data.columnHeaderData[i].columnName.split('_cd_');
        data.columnHeaderData[i].columnName = temp[1];
        data.columnHeaderData[i].code = temp[0];
      } else if (data.columnHeaderData[i].columnName.indexOf('_cv_') > 0) {
        temp = data.columnHeaderData[i].columnName.split('_cv_');
        data.columnHeaderData[i].columnName = temp[1];
        data.columnHeaderData[i].code = temp[0];
      }
      const tempColumn = {
        name: data.columnHeaderData[i].columnName,
        mandatory: !data.columnHeaderData[i].isColumnNullable,
        position: data.columnHeaderData[i].position,
        title: data.columnHeaderData[i].columnDisplayName,
        description: data.columnHeaderData[i].description,
        length: data.columnHeaderData[i].columnLength,
        unmaskedSuffixLength: data.columnHeaderData[i].unmaskedSuffixLength,
        id: data.columnHeaderData[i].id,
        code: data.columnHeaderData[i].code,
        originalName: data.columnHeaderData[i].originalName,
        regex: data.columnHeaderData[i].regex,
        parentNameAttribute: data.columnHeaderData[i].parentNameAttribute
      };
      let colType = data.columnHeaderData[i].columnDisplayType.toLowerCase();
      if (colType === 'integer') {
        colType = 'number';
      } else if (colType === 'codelookup') {
        colType = 'dropdown';
      } else if (colType === 'codevalue' && data.columnHeaderData[i].columnCode === 'FILE_UPLOAD') {
        colType = 'fileupload';
      } else if (colType === 'codevalue' && data.columnHeaderData[i].columnType === 'multiselect') {
        colType = 'multiselect';
      }
      tempColumn.type = colType;
      if (colType === 'string' || colType === 'fileupload' || colType === 'multiselect') {
        tempColumn.length = data.columnHeaderData[i].columnLength;
      } else { delete tempColumn.length; }
      if (data.columnHeaderData[i].columnCode) {
        tempColumn.code = data.columnHeaderData[i].columnCode;
        tempColumn.originalCode = data.columnHeaderData[i].columnCode;
      }
      scope.columns[tempColumn.position] = tempColumn;

      const sectionId = data.columnHeaderData[i].sectionId;
      Object.entries(secColumnTemplateList).forEach(([key, value]) => {
        if (key && value.index === sectionId) { tempSec = value.section; }
      });
      if (tempSec.columns === undefined) { tempSec.columns = []; }
      tempColumn.sectionIndex = tempSec.position;
      if (tempColumn.type === 'boolean') {
        scope.columnOptions.push({
          id: tempColumn.name,
          name: tempColumn.title,
          secIndex: tempColumn.sectionIndex,
          colIndex: tempColumn.position
        });
        scope.isParentColumnFieldEnabled = true;
      }
      tempSec.columns[tempColumn.position] = tempColumn;
    }
    Object.entries(secColumnTemplateList).forEach(([, value]) => {
      value.section.columns = value.section.columns?.filter(column => column);
      scope.sections[value.section.position] = value.section;
    });
  });

  scope.addColumn = function (index) {
    let tempSec;
    for (const i in scope.sections) {
      tempSec = scope.sections[i];
      if (tempSec.position === index) {
        const presentColumnIndex = tempSec.columns[tempSec.columns.length - 1].position;
        const parentColumn = (tempSec.parentNameAttribute !== null) ? tempSec.parentNameAttribute : null;
        tempSec.columns.push({
          name: '',
          type: '',
          mandatory: false,
          title: '',
          description: '',
          position: presentColumnIndex + 1,
          sectionIndex: index,
          regex: '',
          parentNameAttribute: parentColumn
        });
      }
    }
  };
  scope.addSection = function () {
    const presentSecIndex = scope.sections[scope.sections.length - 1].position;
    const tempColumn = [];
    tempColumn.push({ name: '', type: '', mandatory: false, title: '', description: '', position: 0, sectionIndex: presentSecIndex + 1, regex: '' });
    scope.sections.push({ name: '', columns: tempColumn, title: '', description: '', position: presentSecIndex + 1 });
  };
  scope.generateColumnName = function (secIndex, colIndex) {
    if (scope.sections[secIndex].columns[colIndex].name === '' || scope.sections[secIndex].columns[colIndex].id === undefined) { scope.sections[secIndex].columns[colIndex].name = scope.sections[secIndex].columns[colIndex].title.replace(/\s/g, '_').toLowerCase(); }
  };

  scope.removeSection = function (index) {
    let tempSec;
    let droppedSection;
    for (const i in scope.sections) {
      tempSec = scope.sections[i];
      if (tempSec.position === index) {
        droppedSection = tempSec;
        scope.sections.splice(i, 1);
      }
    }
    if (droppedSection.id !== undefined) {
      scope.dropSections.push({ id: droppedSection.id });
      for (const i in droppedSection.columns) {
        scope.dropColumns.push({ id: droppedSection.columns[i].id, name: droppedSection.columns[i].name });
      }
    }
  };
  scope.removeColumnSection = function (colIndex, secIndex) {
    let tempSec;
    for (const i in scope.sections) {
      tempSec = scope.sections[i];
      if (tempSec.position === secIndex) {
        if (tempSec.columns[colIndex].id !== undefined) {
          scope.dropColumns.push({ id: tempSec.columns[colIndex].id, name: tempSec.columns[colIndex].originalName });
        }
        tempSec.columns.splice(colIndex, 1);
      }
    }
  };
  scope.updateDepenedencies = function (colIndex, secIndex) {
    if (scope.sections[secIndex].columns[colIndex].type !== 'string') {
      scope.sections[secIndex].columns[colIndex].length = undefined;
    }
    if (scope.sections[secIndex].columns[colIndex].type !== 'dropdown' &&
      scope.sections[secIndex].columns[colIndex].type !== 'fileupload' &&
      scope.sections[secIndex].columns[colIndex].type !== 'multiselect') {
      scope.sections[secIndex].columns[colIndex].code = undefined;
    }
    if (scope.sections[secIndex].columns[colIndex].type === 'fileupload') {
      scope.sections[secIndex].columns[colIndex].length = 200;
      scope.sections[secIndex].columns[colIndex].code = 'FILE_UPLOAD';
    }
    if (scope.sections[secIndex].columns[colIndex].type === 'multiselect') {
      scope.sections[secIndex].columns[colIndex].length = 200;
    }
  };

  scope.displayClientTypeDropdown = function (appTableName) {
    if (appTableName !== null && appTableName === 'm_client') {
      scope.showClientTypeDropdown = true;
    } else {
      scope.showClientTypeDropdown = false;
      scope.formData.legalFormId = null;
    }
  };

  scope.submit = function () {
    scope.formData.changeColumns = [];
    scope.formData.dropColumns = [];
    scope.formData.addSections = [];
    scope.formData.changeSections = [];
    scope.formData.addColumns = [];
    scope.formData.dropSections = [];
    if (scope.dropColumns.length > 0) {
      scope.formData.dropColumns = scope.dropColumns;
    }
    if (scope.dropSections.length > 0) {
      scope.formData.dropSections = scope.dropSections;
    }
    scope.columns = [];
    for (const i in scope.sections) {
      if (scope.formData.addSections === undefined) { scope.formData.addSections = []; }
      if (scope.formData.changeSections === undefined) { scope.formData.changeSections = []; }
      scope.columns.push.apply(scope.columns, scope.sections[i].columns?.slice());
      // delete scope.sections[i].columns
      if (scope.sections[i].id === undefined || scope.sections[i].id === '') { scope.formData.addSections.push(scope.sections[i]); } else { scope.formData.changeSections.push(scope.sections[i]); }
    }
    for (const i in scope.columns) {
      if (scope.formData.addColumns === undefined) { scope.formData.addColumns = []; }
      if (scope.formData.changeColumns === undefined) { scope.formData.changeColumns = []; }
      if (scope.columns[i].id !== undefined || scope.columns[i].originalName) {
        if (scope.columns[i].type === 'dropdown' && !(scope.columns[i].name.indexOf('_cd_') >= 0)) {
          scope.columns[i].name = `${scope.columns[i].code}_cd_${scope.columns[i].name}`;
        }
        if ((scope.columns[i].type === 'fileupload' || scope.columns[i].type === 'multiselect') && !(scope.columns[i].name.indexOf('_cv_') >= 0)) {
          scope.columns[i].name = `${scope.columns[i].code}_cv_${scope.columns[i].name}`;
        }
        scope.columns[i].newName = scope.columns[i].name;
        if (scope.columns[i].originalName !== scope.columns[i].name) {
          scope.columns[i].name = scope.columns[i].originalName;
          // delete scope.columns[i].originalName
        }
        scope.columns[i].newCode = scope.columns[i].code;
        if (scope.columns[i].originalCode !== scope.columns[i].code) {
          scope.columns[i].code = scope.columns[i].originalCode;
          // delete scope.columns[i].originalCode
        }

        scope.formData.changeColumns.push(scope.columns[i]);
      } else { scope.formData.addColumns.push(scope.columns[i]); }
    }
    if (scope.formData.addSections !== undefined && scope.formData.addSections.length === 0) { delete scope.formData.addSections; }
    if (scope.formData.dropSections !== undefined && scope.formData.dropSections.length === 0) { delete scope.formData.dropSections; }
    if (scope.formData.changeSections !== undefined && scope.formData.changeSections.length === 0) { delete scope.formData.changeSections; }
    if (scope.formData.addColumns !== undefined && scope.formData.addColumns.length === 0) { delete scope.formData.addColumns; }
    if (scope.formData.dropColumns !== undefined && scope.formData.dropColumns.length === 0) { delete scope.formData.dropColumns; }
    if (scope.formData.changeColumns !== undefined && scope.formData.changeColumns.length === 0) { delete scope.formData.changeColumns; }

    resourceFactory.DataTablesResource.update({ datatablename: routeParams.tableName }, this.formData, function (data) {
      location.path(`/viewdatatable/${data.resourceIdentifier}`);
    });
  };
}

EditDataTableController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
