export function UibDropdownMenuDirective () {
  return {
    link (scope, element) {
      element.addClass('dropdown-menu');
      const links = element[0]?.querySelectorAll('a');
      if (links) {
        links.forEach(item => {
          item.classList.add('dropdown-item');
        });
      }
    }
  };
}
