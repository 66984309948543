<template>
  <div class="form-group align-items-center row pb-3">
    <label class="control-label text-end col-sm-4">
      {{ $t('label.input.mobileNumber') }}
    </label>

    <div class="col-sm-8">
      <input
        :id="id"
        ref="mobileInput"
        :class="['form-control', {'is-invalid': errorMessage}]"
        type="text"
        name="mobileNo"
        placeholder="+#########"
        :value="value"
        @input="input"
      >
    </div>
    <div
      v-if="errorMessage"
      class="offset-sm-4 text-danger"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import formWrapperRegister from '@/components/shared/form-input/mixin.js';

export default {
  mixins: [formWrapperRegister],
  props: {
    id: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String],
      default: null || ''
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  created () {
    this.localRule = [(value) => {
      if (!value) return () => true;
      return value.match(/^(?:\+\d{9,23})$|^$/) || 'Invalid mobile number';
    }];
  },
  methods: {
    input (e) {
      this.validatorRunner();
      this.$emit('input', e.target.value);
    }
  }
};
</script>
