<template>
  <table class="w-100">
    <tr>
      <td
        width="30%"
        valign="top"
        class="pe-3"
      >
        <PageTitle>{{ $t('label.heading.permissions') }}</PageTitle>
        <div class="input-group mb-3">
          <input
            id="search"
            v-model="filterString"
            type="search"
            class="form-control"
            :placeholder="$t('label.input.search-permissions')"
          >
        </div>
        <table
          class="w-100 table table-borderless table-hover"
        >
          <tbody>
            <group-name
              v-for="(groupName, index) in permissionGroups"
              ref="groupNames"
              :key="groupName"
              :name="formatName(groupName)"
              :hidden="!getPermissionsLength(groupName)"
              :matched-result="getPermissionsLength(groupName)"
              style="cursor: pointer;"
              @click="onSelectPermission(groupName, index)"
            />
          </tbody>
        </table>
      </td>
      <td
        width="70%"
        valign="top"
        class="ps-3"
      >
        <div class="d-flex justify-content-between">
          <div>
            <page-title>{{ filterData().title }}</page-title>
            <p class="text-secondary">
              {{ `${filterData().list.length} ${$t('label.heading.permissions')}` }}
            </p>
          </div>
          <div>
            <button
              v-show="selectedGroup"
              class="btn btn-outline-primary"
              @click.prevent="selectedGroup = '', removeActiveGroup()"
            >
              {{ $t('label.button.showAllPermissions') }}
            </button>
          </div>
        </div>
        <table class="table table-hover">
          <thead>
            <tr>
              <td>
                {{ $t('label.heading.permissions') }}
              </td>
              <td>
                {{ $t('label.heading.normalaccess') }}
              </td>
              <td v-show="selfService">
                {{ $t('label.heading.fullaccess') }}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="permission in filterData().list"
              :key="permission.code"
            >
              <td>
                <div>
                  {{ formatName(permission.code) }}
                </div>
                <div class="text-black-50">
                  {{ permission.description }}
                </div>
              </td>
              <td
                class="align-middle"
                width="20%"
              >
                <input
                  :id="`${permission.code}-normal`"
                  v-model="permission.selected"
                  type="checkbox"
                  :disabled="!isEditing"
                  style="cursor: pointer;"
                >
              </td>
              <td
                v-if="selfService"
                class="align-middle"
                width="20%"
              >
                <input
                  :id="`${permission.code}-full`"
                  v-model="permission.hasFullAccess"
                  type="checkbox"
                  :disabled="!isEditing || !permission.selected"
                  style="cursor: pointer;"
                >
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </table>
</template>
<script setup>
import { ref } from 'vue';
import { useVModel } from '@vueuse/core';
import { get, set } from '@vueuse/shared';
import PageTitle from '@/components/shared/PageTitle.vue';
import GroupName from '@/components/system/manage-roles/GroupName.vue';
import i18n from '@/plugins/i18n';

const selectedGroup = ref('');
const filterString = ref('');
const groupNames = ref();
const emits = defineEmits(['select-permission', 'select-grouping']);
const props = defineProps({
  permissionGroups: {
    type: Array,
    default: () => []
  },
  value: {
    type: Array,
    default: () => []
  },
  isEditing: {
    type: Boolean,
    default: false
  },
  selfService: {
    type: Boolean,
    default: false
  }
});

const permissionData = useVModel(props, 'value', emits);

const filterData = () => {
  const filterValue = get(filterString);
  const groupingValue = get(selectedGroup);

  if (filterValue) {
    // When search within a permission group
    if (groupingValue) {
      return {
        title: formatName(groupingValue).toUpperCase().concat(`: ${i18n.t('label.resultsFor')} '${filterValue}'`),
        list: getPermissionsByGroup(groupingValue).filter(item => item.code.toLowerCase().includes(filterValue.toLowerCase()))
      };
    }
    // when search all permissions
    return {
      title: `${i18n.t('label.resultsFor')} '${filterValue}'`,
      list: get(permissionData).filter(item => item.code.toLowerCase().includes(filterValue.toLowerCase()))
    };
  } else {
    // When selected a permission grouping
    if (groupingValue) {
      return {
        title: formatName(groupingValue).toUpperCase(),
        list: getPermissionsByGroup(groupingValue)
      };
    }
    // When no search and no grouping is selected
    return {
      title: i18n.t('label.heading.allPermissions'),
      list: get(permissionData)
    };
  }
};

const getPermissionsByGroup = (group) => {
  return get(permissionData).filter(item => item.grouping === group);
};

const getPermissionsLength = (group) => {
  const filter = get(filterString);
  if (filter) return getPermissionsByGroup(group).filter(item => item.code.toLowerCase().includes(filter.toLowerCase())).length;
  return getPermissionsByGroup(group).length;
};

const formatName = (name) => {
  return name.replace(/_/g, ' ');
};

const onSelectPermission = (groupName, index) => {
  set(selectedGroup, groupName);
  setNewActiveGroup(index);
  emits('select-grouping');
};

const setNewActiveGroup = (index) => {
  removeActiveGroup();
  get(groupNames)[index].$el.classList.add('active');
};

const removeActiveGroup = () => {
  const currentActiveGroup = get(groupNames).find(item => item.$el.className.includes('active'));
  if (currentActiveGroup) currentActiveGroup.$el.classList.remove('active');
};
</script>
