<template>
  <section class="card form-horizontal well">
    <manage-organization-form />
  </section>
</template>

<script>
import ManageOrganizationForm from '@/components/ManageOrganizationForm.vue';

export default {
  name: 'ManageOrganization',
  components: {
    ManageOrganizationForm
  }
};
</script>
