<template>
  <div
    class="form-group align-items-center pb-3"
    :class="{ row: !vertical }"
  >
    <label
      v-if="label"
      class="control-label"
      :class="vertical ? 'mb-1' : 'text-end col-sm-4'"
      :for="id"
    >
      {{ label }}
      <span
        v-if="required"
        class="required"
      >*</span>
    </label>

    <div :class="{ 'col-sm-8': !vertical }">
      <vue-number
        v-if="!disabled"
        :id="id"
        :value="value"
        :class="['form-control', { 'is-invalid': errorMessage }]"
        v-bind="options"
        @input="updateInput"
      />

      <input
        v-else
        :id="id"
        v-number="options"
        disabled
        type="text"
        :value="value"
        class="form-control"
      >
    </div>
    <div
      v-if="errorMessage"
      class="text-danger"
      :class="{ 'offset-sm-4': !vertical }"
    >
      {{ errorMessage }}
    </div>
    <div
      v-if="noteMessage && !errorMessage"
      class="offset-sm-4 text-secondary"
    >
      {{ noteMessage }}
    </div>
  </div>
</template>

<script>
import formWrapperRegister from '@/components/shared/form-input/mixin.js';
import { directive as VNumber, options as defaultOptions } from '@coders-tm/vue-number-format';

// https://vue-number-format.netlify.app/guide/config.html
export default {
  directives: {
    number: VNumber
  },
  mixins: [formWrapperRegister],
  props: {
    id: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String],
      default: ''
    },
    required: {
      type: [Boolean, Object],
      default: false || (() => { })
    },
    config: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: ''
    },
    noteMessage: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    vertical: {
      type: Boolean,
      defualt: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    options () {
      return { ...defaultOptions, ...this.config };
    }
  },
  created () {
    this.localRule = [value => Number(value) !== 0 || !this.required || 'This field is required'];
  },
  methods: {
    updateInput (value) {
      this.validatorRunner();
      if (value === null || value === 0) return;
      this.$emit('input', value);
    }
  }
};
</script>
