import _ from 'underscore';
import { Langs } from '../../models/Langs';
import { useUserStore } from '@/stores/user';
import { useVueTransitionStore } from '@/stores/vuetransition';

export function MainController ($scope, $location, sessionManager, $translate, $rootScope, localStorageService, Idle) {
  $scope.islogofoldernamefetched = false;
  $scope.islogofoldernameconfig = false;
  $scope.isFaviconPath = false;
  $scope.isHeaderLogoPath = false;
  $scope.isBigLogoPath = false;
  $scope.isLargeLogoPath = false;
  $scope.isVueRoute = false;

  const userStore = useUserStore();
  const useTransitionStore = useVueTransitionStore();
  $scope.currentSession = userStore.userDetails;

  if (!$scope.islogofoldernamefetched && $rootScope.tenantIdentifier && $rootScope.tenantIdentifier !== 'default') {
    $scope.islogofoldernamefetched = true;
  }
  $scope.$on('configJsonObj', function (e, response) {
    $scope.response = response;
  });
  // hides loader
  $scope.domReady = true;
  $scope.activity = {};
  $scope.activityQueue = [];
  if (localStorageService.getFromLocalStorage('Location')) {
    $scope.activityQueue = localStorageService.getFromLocalStorage('Location');
  }
  $scope.loadSC = function () {
    if (!localStorageService.getFromLocalStorage('searchCriteria')) { localStorageService.addToLocalStorage('searchCriteria', {}); }
    $scope.searchCriteria = localStorageService.getFromLocalStorage('searchCriteria');
  };
  $scope.saveSC = function () {
    localStorageService.addToLocalStorage('searchCriteria', $scope.searchCriteria);
  };
  $scope.loadSC();
  $scope.setDf = function () {
    if (localStorageService.getFromLocalStorage('dateformat')) {
      $scope.dateformat = localStorageService.getFromLocalStorage('dateformat');
    } else {
      localStorageService.addToLocalStorage('dateformat', 'dd MMMM yyyy');
      $scope.dateformat = 'dd MMMM yyyy';
    }
    $scope.df = $scope.dateformat;
    $scope.dft = `${$scope.dateformat} HH:mm:ss`;
  };

  $rootScope.updateDf = function (dateFormat) {
    localStorageService.addToLocalStorage('dateformat', dateFormat);
    $scope.dateformat = dateFormat;
    $scope.setDf();
  };
  $scope.setDf();
  $rootScope.setPermissions = function (permissions) {
    $rootScope.permissionList = permissions;
    localStorageService.addToLocalStorage('userPermissions', permissions);
    $rootScope.$broadcast('permissionsChanged');
  };

  $rootScope.hasPermission = function (permission) {
    permission = permission.trim();
    // FYI: getting all permissions from localstorage, because if scope changes permissions array will become undefined
    $rootScope.permissionList = localStorageService.getFromLocalStorage('userPermissions');
    // If user is a Super user return true
    if ($rootScope.permissionList && _.contains($rootScope.permissionList, 'ALL_FUNCTIONS')) {
      return true;
    } if ($rootScope.permissionList && permission && permission !== '') {
      // If user have all read permission return true
      if (permission.substring(0, 5) === 'READ_' && _.contains($rootScope.permissionList, 'ALL_FUNCTIONS_READ')) {
        return true;
      } if (_.contains($rootScope.permissionList, permission)) {
        // check for the permission if user doesn't have any special permissions
        return true;
      }
      // return false if user doesn't have permission
      return false;
    }
    // return false if no value assigned to has-permission directive
    return false;
  };

  $scope.$watch(function () {
    return $location.path();
  }, function () {
    $scope.activity = $location.path();
    $scope.activityQueue.push($scope.activity);
    localStorageService.addToLocalStorage('Location', $scope.activityQueue);
  });

  $scope.$watch(function () {
    return useTransitionStore.isVueRoute;
  }, function (value) {
    $scope.isVueRoute = value;
  });

  // Logout the user if Idle
  $scope.$on('IdleTimeout', function () {
    userStore.logout();
    Idle.unwatch();
  });

  // // Log out the user when the window/tab is closed.
  window.onunload = function () {
    Idle.unwatch();
  };

  $scope.start = function (session) {
    if (session) Idle.watch();
    useTransitionStore.sessionProcessed = true;
  };

  $scope.langs = Langs;
  if (localStorageService.getFromLocalStorage('Language')) {
    const temp = localStorageService.getFromLocalStorage('Language');
    for (const i in Langs) {
      if (Langs[i].code === temp.code) {
        $scope.optlang = Langs[i];
      }
    }
  } else {
    $scope.optlang = $scope.langs[0];
  }
  $translate.use($scope.optlang.code);

  $scope.isActive = function (route) {
    if (route === 'clients') {
      const temp = ['/clients', '/centers'];
      for (const i in temp) {
        if (temp[i] === $location.path()) {
          return true;
        }
      }
    } else if (route === 'acc') {
      const temp1 = ['/accounting', '/freqposting', '/accounting-coa', '/journalentry', '/accounts_closure', '/accounting/search-journal-entries', '/accounting_rules'];
      for (const i in temp1) {
        if (temp1[i] === $location.path()) {
          return true;
        }
      }
    } else if (route === 'rep') {
      const temp2 = ['/reports/all', '/reports/clients', '/reports/loans', '/reports/funds', '/reports/accounting', 'reports/savings'];
      for (const i in temp2) {
        if (temp2[i] === $location.path()) {
          return true;
        }
      }
    } else if (route === 'admin') {
      const temp3 = ['/users/', '/organization', '/system', '/products', '/global'];
      for (const i in temp3) {
        if (temp3[i] === $location.path()) {
          return true;
        }
      }
    } else {
      const active = route === $location.path();
      return active;
    }
  };

  $scope.changeLang = function (lang) {
    $translate.use(lang.code);
    localStorageService.addToLocalStorage('Language', lang);
    $scope.optlang = lang;
  };

  sessionManager.restore(function (session) {
    $scope.start($scope.currentSession);

    if (session?.permissions) {
      $rootScope.setPermissions(session?.permissions);
      localStorageService.addToLocalStorage('userPermissions', session?.permissions);
    }
  });

  $scope.$on('$locationChangeStart', function () {
    const currentLocation = $location.path();

    sessionManager.restore(function (session) {
      if (_.isEmpty(session)) {
        $scope.currentSession = session;

        if (!currentLocation.includes('reset-password') && !currentLocation.includes('otp')) {
          $location.path('/login');
        }
      }
    });
  });
}

MainController.$inject = ['$scope', '$location', 'SessionManager', '$translate', '$rootScope', 'localStorageService', 'Idle'];
