import { formatExchangeRate } from '../../../helper/currencyHelper';

export function FormatNumber ($filter) {
  return function (input, fractionSize) {
    if (isNaN(input)) {
      return input;
    }
    // TODO- Add number formatting also
    if (input !== '' && input !== undefined) {
      return $filter('number')(input, fractionSize);
    }
  };
}

export function FormatExchangeRate () {
  return function (input) {
    return formatExchangeRate(input);
  };
}

FormatNumber.$inject = ['$filter'];
