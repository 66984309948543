export function CreditAccountActionsController (scope, resourceFactory, location, routeParams, dateFilter) {
  scope.action = routeParams.action || '';
  scope.accountId = routeParams.id;
  scope.formData = {};
  scope.restrictDate = new Date();
  scope.showPaymentDetails = false;
  scope.paymentTypes = [];
  scope.tf = 'HH:mm';
  scope.showLimitFields = false;
  scope.creditLimit = 0;
  scope.cashLimit = 0;
  scope.savingsAccounts = [];
  scope.showMakePaymentFields = false;
  scope.showDateField = false;
  scope.errorMsg = '';
  scope.loading = false;
  scope.showRefundFields = false;

  function getPaymentTypes () {
    resourceFactory.paymentTypeResource.getAll(function (dataPaymentTypes) {
      scope.paymentTypes = dataPaymentTypes.filter(function (dataPaymentType) {
        return (dataPaymentType.name === 'CASH' && dataPaymentType.isSystemDefined);
      });
      if (scope.paymentTypes.length) {
        scope.formData.paymentTypeId = scope.paymentTypes.find(function (paymentType) {
          return paymentType.name === 'CASH';
        }).id;
      }
    });
  }

  const promiseCallbackHandler = (callback) => {
    return () => {
      scope.loading = false;
      if (callback) callback();
    };
  };

  scope.fetchEntities = function () {
    resourceFactory.creditAccountResource.get({ accountId: routeParams.id, associations: 'account' },
      function (data) {
        scope.productId = data.creditAccountData.creditProductId;
      });
  };

  scope.fieldType = function (type) {
    let fieldType = '';
    if (type) {
      if (type === 'CODELOOKUP' || type === 'CODEVALUE') fieldType = 'SELECT';
      else if (type === 'DATE') fieldType = 'DATE';
      else if (type === 'DATETIME') fieldType = 'DATETIME';
      else if (type === 'BOOLEAN') fieldType = 'BOOLEAN';
      else fieldType = 'TEXT';
    }
    return fieldType;
  };

  switch (scope.action) {
    case 'approve':
      scope.title = 'label.heading.approvesavingaccount';
      scope.labelName = 'label.input.savingaccountapprovedOnDate';
      scope.modelName = 'approvedOnDate';
      scope.showDateField = true;
      scope.taskPermissionName = 'APPROVE_CREDIT_CARD';
      scope.fetchEntities('m_credit_account', 'APPROVE');
      break;
    case 'reject':
      scope.title = 'label.heading.rejectsavingaccount';
      scope.labelName = 'label.input.rejectedon';
      scope.modelName = 'rejectedOnDate';
      scope.showDateField = true;
      scope.showNoteField = false;
      scope.taskPermissionName = 'REJECT_CREDIT_CARD';
      scope.fetchEntities('m_credit_account', 'REJECT');
      break;
    case 'withdrawnByApplicant':
      scope.title = 'label.heading.withdrawsavingaccount';
      scope.labelName = 'label.input.withdrawnon';
      scope.modelName = 'withdrawnOnDate';
      scope.showDateField = true;
      scope.showNoteField = false;
      scope.taskPermissionName = 'WITHDRAW_CREDIT_CARD';
      break;
    case 'adjustLimit':
      scope.title = 'label.heading.adjustLimit';
      scope.showLimitFields = true;
      scope.taskPermissionName = 'ADJUSTLIMIT_CREDIT_CARD';
      break;
    case 'makepayment':
      resourceFactory.gqlResource.query(`{"query": "{ Cards( where: { id : { EQ : ${routeParams.id}} }) {select { creditAccount { client {id} } } } }"}`, function (response) {
        scope.clientId = response.data.Cards.select[0].creditAccount.client.id;
        resourceFactory.clientResource.getSavingAccounts({ clientId: scope.clientId, anotherresource: 'accounts' }, function (dataSavingsAccounts) {
          scope.savingsAccounts = dataSavingsAccounts.savingsAccounts?.filter(({ status: { active } }) => active);
        });
      });

      scope.title = 'label.heading.makepayment';
      scope.showMakePaymentFields = true;
      scope.taskPermissionName = 'PAYMENTDUE_CREDIT_CARD';
      break;
    case 'withdrawal':
      getPaymentTypes();
      scope.title = 'label.heading.withdrawmoneyfromsavingaccount';
      scope.labelName = 'label.input.valuedate';
      scope.modelName = 'transactionDate';
      scope.formData[scope.modelName] = new Date();
      scope.bookingDatelabelName = 'label.heading.booking.date';
      scope.bookingDateModelName = 'bookingDate';
      scope.showBookingDate = true;
      scope.showDateField = true;
      scope.showNoteField = true;
      scope.isTransaction = true;
      scope.transactionAmountField = true;
      scope.showPaymentDetails = false;
      scope.taskPermissionName = 'WITHDRAWAL_CREDITACCOUNT';
      scope.fetchEntities('m_credit_account', 'WITHDRAWN');
      break;
    case 'applyAnnualFees':
      resourceFactory.savingsResource.get({ accountId: routeParams.id, resourceType: 'charges', chargeId: routeParams.chargeId },
        function (data) {
          scope.formData.amount = data.amount;
          if (data.dueDate) {
            const dueDate = dateFilter(data.dueDate, scope.df);
            scope.formData.dueDate = new Date(dueDate);
          }
        });
      scope.title = 'label.heading.savingaccountapplyannualFee';
      scope.labelName = 'label.input.annualfeetransactiondate';
      scope.modelName = 'dueDate';
      scope.showDateField = true;
      scope.showAnnualAmountField = true;
      scope.showAmountField = false;
      scope.showNoteField = false;
      scope.taskPermissionName = 'APPLYANNUALFEE_CREDITACCOUNT';
      break;
    case 'close':
      getPaymentTypes();
      resourceFactory.savingsResource.get({ accountId: routeParams.id, fields: 'summary' }, function (accountData) { scope.accountBalance = accountData.summary.accountBalance; });
      scope.title = 'label.heading.closesavingaccount';
      scope.labelName = 'label.input.closedon';
      scope.modelName = 'closedOnDate';
      scope.showDateField = true;
      scope.showNoteField = false;
      scope.withdrawBalance = true;
      scope.postInterestValidationOnClosure = true;
      scope.formData.postInterestValidationOnClosure = true;
      scope.taskPermissionName = 'CLOSE_CREDITACCOUNT';
      scope.fetchEntities('m_credit_account', 'CLOSE');
      break;
    case 'modifytransaction':
      resourceFactory.savingsTrxnsResource.get({ savingsId: scope.accountId, transactionId: routeParams.transactionId, template: 'true' },
        function (data) {
          scope.title = 'label.heading.editsavingaccounttransaction';
          scope.labelName = 'label.input.transactiondate';
          scope.modelName = 'transactionDate';
          scope.formData[scope.modelName] = new Date(data.date) || new Date();
          scope.paymentTypes = data.paymentTypeOptions;
          scope.formData.transactionAmount = data.amount;
          if (data.paymentDetailData) {
            if (data.paymentDetailData.paymentType) scope.formData.paymentTypeId = data.paymentDetailData.paymentType.id;
            scope.formData.accountNumber = data.paymentDetailData.accountNumber;
            scope.formData.checkNumber = data.paymentDetailData.checkNumber;
            scope.formData.routingCode = data.paymentDetailData.routingCode;
            scope.formData.receiptNumber = data.paymentDetailData.receiptNumber;
            scope.formData.bankNumber = data.paymentDetailData.bankNumber;
          }
        });
      scope.showDateField = true;
      scope.showNoteField = false;
      scope.isTransaction = true;
      scope.transactionAmountField = true;
      scope.showPaymentDetails = false;
      scope.taskPermissionName = 'ADJUSTTRANSACTION_CREDITACCOUNT';
      break;
    case 'editsavingcharge':
      resourceFactory.savingsResource.get({ accountId: routeParams.id, resourceType: 'charges', chargeId: routeParams.chargeId },
        function (data) {
          scope.formData.amount = data.amount;
          if (data.feeOnMonthDay) {
            scope.dateArray = [];
            scope.dateArray.push(2013);
            for (const i in data.feeOnMonthDay) {
              scope.dateArray.push(data.feeOnMonthDay[i]);
            }
            const feeOnMonthDay = dateFilter(scope.dateArray, scope.df);
            scope.formData.feeOnMonthDayFullDate = new Date(feeOnMonthDay);
            scope.labelName = 'label.heading.savingaccounttransactionDate';
            scope.modelName = 'feeOnMonthDayFullDate';
            scope.showDateField = true;
            scope.showAnnualAmountField = true;
            scope.showAmountField = false;
          } else {
            scope.labelName = 'label.input.amount';
            scope.modelName = 'amount';
            scope.showDateField = false;
            scope.showAnnualAmountField = false;
            scope.showAmountField = true;
          }
        });
      scope.taskPermissionName = 'UPDATE_CREDITACCOUNTCHARGE';
      break;
    case 'deletesavingcharge':
      scope.showDelete = true;
      scope.taskPermissionName = 'DELETE_CREDITACCOUNTCHARGE';
      break;
    case 'paycharge':
      scope.formData.dueDate = new Date();
      resourceFactory.creditAccountResource.get({
        accountId: routeParams.id,
        resourceType: 'charges',
        chargeId: routeParams.chargeId,
        command: 'paycharge'
      }, function (data) { scope.formData.amount = data.amountOutstanding; });
      scope.labelName = 'label.input.amount';
      scope.showAmountField = true;
      scope.paymentDatefield = true;
      scope.modelName = 'dueDate';
      scope.taskPermissionName = 'PAY_CREDIT_CARD_CHARGE';
      break;
    case 'inactivate':
      scope.inactivateCharge = true;
      scope.taskPermissionName = 'INACTIVATE_CREDITACCOUNTCHARGE';
      break;
    case 'waive':
      scope.waiveCharge = true;
      scope.taskPermissionName = 'WAIVE_CREDITACCOUNTCHARGE';
      break;
    case 'chargeBack':
      resourceFactory.savingsTrxnsResource.get({ savingsId: scope.accountId, transactionId: routeParams.transactionId, template: 'true' },
        function (data) {
          scope.title = 'label.button.chargeBack';
          scope.labelName = 'label.input.transactiondate';
          scope.modelName = 'transactionDate';
          scope.formData[scope.modelName] = new Date();
          scope.formData.transactionAmount = `${data.amount}`;
          scope.paymentTypes = data.paymentTypeOptions;
          scope.formData.externalCardId = data.transferData.debtor.identifier;
        });
      scope.showExternalCardId = true;
      scope.showDateField = false;
      scope.showNoteField = true;
      scope.isTransaction = true;
      scope.showBookingDate = false;
      scope.transactionAmountField = true;
      scope.showPaymentDetails = false;
      scope.formData.note = routeParams.transactionId;
      scope.taskPermissionName = 'WAIVE_CUSTOM_CREDITACCOUNTCHARGE';
      scope.formData.paymentTypeId = 1;
      scope.formData.transactionId = routeParams.transactionId;
      break;
    case 'undoCharge':
      resourceFactory.savingsTrxnsResource.get({ savingsId: scope.accountId, transactionId: routeParams.transactionId, template: 'true' },
        function (data) {
          scope.title = 'label.button.undoCharge';
          scope.labelName = 'label.input.transactiondate';
          scope.modelName = 'transactionDate';
          scope.formData[scope.modelName] = new Date();
          scope.formData.transactionAmount = `${data.amount}`;
          scope.paymentTypes = data.paymentTypeOptions;
        });
      scope.showDateField = false;
      scope.showNoteField = true;
      scope.isTransaction = true;
      scope.showBookingDate = false;
      scope.transactionAmountField = true;
      scope.showPaymentDetails = false;
      scope.formData.note = routeParams.transactionId;
      scope.taskPermissionName = 'WAIVE_CUSTOM_CREDITACCOUNTCHARGE';
      scope.formData.paymentTypeId = 1;
      scope.formData.transactionId = routeParams.transactionId;
      break;
    case 'refund':
      resourceFactory.gqlResource.query(`{"query": "{ Cards( where: { id : { EQ : ${routeParams.id}} }) {select { creditAccount { client {id} } } } }"}`, function (response) {
        scope.clientId = response.data.Cards.select[0].creditAccount.client.id;
        resourceFactory.clientResource.getSavingAccounts({
          clientId: scope.clientId,
          anotherresource: 'accounts'
        }, function (dataSavingsAccounts) {
          scope.savingsAccounts = dataSavingsAccounts.savingsAccounts?.filter(({ status: { active } }) => active);
        });
      });

      scope.title = 'label.heading.refund';
      scope.showRefundFields = true;
      scope.taskPermissionName = 'REFUND_CREDIT_CARD';
      break;
  }

  scope.cancel = function () { location.path(`/viewcreditaccount/${routeParams.id}`); };

  scope.submit = function () {
    scope.loading = true;
    let params = { command: scope.action };
    if (scope.action !== 'undoapproval') {
      this.formData.locale = scope.optlang.code;
      this.formData.dateFormat = scope.df;
    }

    if (scope.action === 'chargeBack') {
      const creditAccountId = scope.accountId;
      scope.formData = {
        locale: 'en',
        externalCardId: scope.formData?.externalCardId.split(':')[1],
        note: scope?.formData?.reference,
        transactionId: routeParams.transactionId
      };
      resourceFactory.chargeBack.save({ creditAccountId, command: 'externalcardchargeback' }, scope.formData, function (data) {
        location.path(`/viewcreditaccount/${data.savingsId}`);
      });
    } else {
      params.accountId = scope.accountId;
      if (scope.action === 'approve') {
        if (this.formData.approvedOnDate) this.formData.approvedOnDate = dateFilter(this.formData.approvedOnDate, scope.df);
      } else if (scope.action === 'withdrawnByApplicant') {
        if (this.formData.withdrawnOnDate) this.formData.withdrawnOnDate = dateFilter(this.formData.withdrawnOnDate, scope.df);
      } else if (scope.action === 'reject') {
        if (this.formData.rejectedOnDate) this.formData.rejectedOnDate = dateFilter(this.formData.rejectedOnDate, scope.df);
      } else if (scope.action === 'activate') {
        if (this.formData.activatedOnDate) this.formData.activatedOnDate = dateFilter(this.formData.activatedOnDate, scope.df);
      } else if (scope.action === 'applyAnnualFees' || scope.action === 'paycharge' || scope.action === 'waivecharge') {
        params = { accountId: routeParams.id, resourceType: 'charges', chargeId: routeParams.chargeId, command: 'paycharge' };
        if (this.formData.dueDate) this.formData.dueDate = dateFilter(this.formData.dueDate, scope.df);
      } else if (scope.action === 'close') {
        if (this.formData.closedOnDate) this.formData.closedOnDate = dateFilter(this.formData.closedOnDate, scope.df);
      } else if (scope.action === 'undoCharge') {
        this.formData.paymentTypeId = 1;
        this.formData.note = `${this.formData.note}Reverse`;
      } else if (scope.action === 'waive') {
        params = { accountId: routeParams.id, chargeId: routeParams.chargeId, command: 'waive' };
        return resourceFactory.creditCardChargesAction.save(params, this.formData, promiseCallbackHandler(() => {
          location.path(`/viewcreditaccount/${scope.accountId}`);
        }), promiseCallbackHandler());
      }
      resourceFactory.creditAccountResource.save(params, this.formData, promiseCallbackHandler(() => {
        location.path(`/viewcreditaccount/${scope.accountId}`);
      }), promiseCallbackHandler());
    }
  };

  resourceFactory.creditAccountResource.get({ accountId: routeParams.id, associations: 'all' }, function (data) { scope.account = data; });

  scope.createTransfer = function (type) {
    // Create the request
    const request = {
      type,
      dateFormat: this.formData.dateFormat
    };

    const party = {};
    if (scope.action === 'withdrawal') {
      request.debtor = { identifier: `id:${scope.accountId}` };
      request.creditor = party;
    } else {
      request.debtor = party;
      request.creditor = { name: scope.account.clientName, identifier: `id:${scope.accountId}` };
    }

    if (this.formData.transactionAmount) {
      if (this.formData.locale.toLowerCase().startsWith('en') || this.formData.locale.toLowerCase().startsWith('th')) {
        request.amount = Number(this.formData.transactionAmount.replace(',', '').replace(' ', ''));
      } else {
        request.amount = Number(this.formData.transactionAmount.replace('.', '').replace(',', '.').replace(' ', ''));
      }
    }

    if (this.formData.paymentTypeId) {
      const paymentTypeId = this.formData.paymentTypeId;
      request.paymentType = scope.paymentTypes.find(function (paymentType) { return paymentType.id === paymentTypeId; }).name;
    }

    if (this.formData.transactionDate) request.valueDate = this.formData.transactionDate;
    if (this.formData.bookingDate) {
      request.bookingDate = this.formData.bookingDate;
    }

    if (this.formData.reference) request.reference = this.formData.reference.trim().split('\n');

    if (this.formData.name) party.name = this.formData.name;

    if (this.formData.address) party.address = this.formData.address.trim().split('\n');

    if (this.formData.country) party.country = this.formData.country;

    if (this.formData.agentName) {
      if (!party.agent) party.agent = {};
      party.agent.name = this.formData.agentName;
    }
    if (this.formData.agentIdentifier) {
      if (!party.agent) party.agent = {};
      party.agent.identifier = this.formData.agentIdentifier;
    }
    if (this.formData.agentAddress) {
      if (!party.agent) party.agent = {};
      party.agent.address = this.formData.agentAddress.trim().split('\n');
    }
    if (this.formData.agentCountry) {
      if (!party.agent) party.agent = {};
      party.agent.country = this.formData.country;
    }

    return request;
  };
}

CreditAccountActionsController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams', 'dateFilter'];
