export function CreateSelfServiceUserController (scope, resourceFactory, routeParams, location, $uibModal) {
  // TODO: Add Account Is Active Logic through email validation
  scope.accountActive = false;
  scope.available = [];
  scope.selected = [];
  scope.displayName = '';
  scope.selectedRoles = [];
  scope.availableRoles = [];
  scope.formData = {
    isSelfServiceUser: true,
    sendPasswordToEmail: true,
    resetPasswordAfterFirstLogin: true,
    roles: []
  };
  scope.clientId = routeParams.clientId;
  resourceFactory.userTemplateResource.get(function (data) {
    scope.availableRoles = data.availableRoles.filter(function (role) {
      return role.isSelfService;
    });
    if (!scope.availableRoles.length) return;
    scope.formData.roles = [scope.availableRoles[0].id.toString()];
  });
  resourceFactory.clientResource.get({ clientId: scope.clientId }, function (data) {
    scope.formData.firstname = data.firstname;
    scope.formData.lastname = data.lastname;
    scope.displayName = data.displayName;
    scope.formData.email = '';
    scope.formData.officeId = data.officeId;
    scope.formData.staffId = data.staffId;
    scope.formData.clients = [scope.clientId];
  });

  scope.submit = function () {
    resourceFactory.userListResource.save(scope.formData, function () {
      scope.accountActive = true;
      $uibModal.open({
        templateUrl: 'selfserviceModal.html',
        controller: SuccessModalCtrl
      });
    });
  };

  const SuccessModalCtrl = function ($scope, $uibModalInstance) {
    $scope.close = function () {
      $uibModalInstance.close(location.path(`/viewclient/${routeParams.clientId}`));
    };
  };
}

CreateSelfServiceUserController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location', '$uibModal'];
