export function ChosenComboboxDirective () {
  const linker = function (scope, element, attrs) {
    const list = attrs.chosen;
    scope.$watch(list, function () {
      element.trigger('liszt:updated');
      element.trigger('chosen:updated');
    });
    const selectOptions = () => element[0].length;
    scope.$watch(selectOptions, function (newOptions, oldOptions) {
      if (newOptions !== oldOptions) {
        element.trigger('liszt:updated');
        element.trigger('chosen:updated');
      }
    });

    element.chosen({ search_contains: true });
  };

  return {
    restrict: 'A',
    link: linker
  };
}
