<template>
  <div class="card">
    <div class="card-body">
      <form-wrapper
        @submit="submit"
      >
        <legend>{{ $t('label.head.createExternalCard') }}</legend>
        <div class="form-group mb-3 row align-items-center">
          <div class="col-sm-6">
            <input-text
              id="card-number"
              v-model="formData.cardNumber"
              required
              :rules="cardNumberValidation"
              :label="$t('label.input.cardNumber')"
            />
          </div>
        </div>
        <div class="form-group mb-3 row align-items-center">
          <div class="col-sm-6">
            <input-date-picker
              id="expiry-date"
              v-model="formData.expiryDate"
              type="month"
              format="YYYY-MM"
              :label="$t('label.input.expiryDate')"
              required
              :min-date="today"
            />
          </div>
        </div>
        <div class="form-group mb-3 row align-items-center">
          <div class="col-sm-6">
            <input-text
              id="cvv"
              v-model="formData.cvv"
              :rules="cvvNumberValidation"
              required
              :label="$t('label.input.cvv')"
            />
          </div>
        </div>
        <div class="col-sm-12 text-right text-end">
          <a
            class="btn btn-md btn-text"
            @click="cancel"
          >
            {{ $t('label.button.cancel') }}
          </a>
          <button
            type="submit"
            class="btn btn-md btn-primary"
          >
            {{ $t('label.button.submit') }}
          </button>
        </div>
      </form-wrapper>
    </div>
  </div>
</template>

<script>
import apiService from '@/services/apiService';
import { encrypt } from '@/helper/encryptExternalCard';
import { InputText, InputDatePicker, FormWrapper } from '@/components/shared/form-input';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { mapActions } from 'pinia';
import { numeric, exactLength } from '@/components/shared/form-input/rules';

export default {
  name: 'CreateExternalCard',
  components: { InputDatePicker, InputText, FormWrapper },
  data () {
    return {
      formData: {
        expiryDate: null
      },
      validated: false,
      today: new Date(),
      clientId: this.$route.params.clientId,
      cardNumberValidation: [
        cardNumber => numeric(cardNumber),
        cardNumber => exactLength(cardNumber, 16)
      ],
      cvvNumberValidation: [
        cvvNumber => numeric(cvvNumber),
        cvvNumber => exactLength(cvvNumber, 3)
      ]
    };
  },
  async created () {
    const { data: { displayName } } = await apiService.clientResource.get(this.clientId);
    this.setBreadcrumbs([
      { text: this.$t('label.anchor.clients'), href: '#/clients' },
      { text: displayName, href: `#/viewclient/${this.clientId}` },
      { text: this.$t('label.anchor.createExternalCard') }
    ]);
  },
  methods: {
    ...mapActions(useBreadcrumbsStore, ['setBreadcrumbs']),
    async submit () {
      const [year, month] = this.formData.expiryDate.split('-');
      const cardInfo = `${this.formData.cardNumber}|${year}${month}|${this.formData.cvv}`;
      try {
        const keyData = await apiService.externalCard.getKeyId();
        const key = keyData.data.key;
        const encryptKey = await encrypt(key, cardInfo);
        await apiService.clients.createExternalCard(this.clientId, { cardData: encryptKey });
        this.cancel();
      } catch (error) {
        this.$notify.error(error);
      }
    },
    cancel () {
      location.assign(`#/viewclient/${this.clientId}`);
    }
  }
};
</script>
