<template>
  <li>
    <b-dropdown
      variant="none"
      toggle-class="text-white"
      right
      @show="fetchNotifications"
    >
      <template #button-content>
        <span class="fas fa-bell" />
      </template>
      <b-dropdown-item
        v-for="(notification, index) in notifications"
        :key="`notification-${index}`"
        :class="{ 'new-notificaion': !notification.isRead } "
        @click="navigateToAction(notification)"
      >
        <h4>{{ notification.content }}</h4>
        <span>{{ notification.createdAt }}</span>
      </b-dropdown-item>
      <b-dropdown-item
        class="notification-list-link"
        href="#/notifications"
      >
        Show all notifications
      </b-dropdown-item>
    </b-dropdown>
  </li>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue';
import apiService from '@/services/apiService';
import { StorageService } from '@/services/storage.service';

export default {
  name: 'NotificationsDropdown',
  components: {
    BDropdown,
    BDropdownItem
  },
  data () {
    return {
      notifications: [],
      numberOfUnreadNotifications: 0,
      objTypeUrlMap: {
        client: '/viewclient/',
        group: '/viewgroup/',
        loan: '/viewloanaccount/',
        shareAccount: '/viewshareaccount/',
        fixedDeposit: 'viewfixeddepositaccount/',
        recurringDepositAccount: '/viewrecurringdepositaccount/',
        shareProduct: '/viewshareproduct/',
        savingsAccount: '/viewsavingaccount/',
        center: '/viewcenter/',
        loanProduct: '/viewloanproduct/'
      }
    };
  },
  methods: {
    navigateToAction (notification) {
      if (!notification.objectType || typeof (notification.objectType) !== 'string') {
        console.error('no object type found');
        return;
      }
      if (!this.objTypeUrlMap[notification.objectType]) {
        return;
      }
      location.href = `#/${(this.objTypeUrlMap[notification.objectType] + notification.objectId)}`;
    },
    async fetchNotifications () {
      const readNotifications = StorageService.getItem('notifications', 'localStorage');
      if (readNotifications === null) {
        const data = await this.fetchNotificationsFromServer();
        if (data) {
          this.notifications = data.pageItems;
          StorageService.setItem('notifications', JSON.stringify(this.notifications), 'localStorage');
        }
      } else {
        this.notifications = readNotifications;
      }
      await this.setNotificationsRead();
    },
    async setNotificationsRead () {
      if (this.numberOfUnreadNotifications < 1) return;
      try {
        await apiService.notifications.readNotifications();
        this.numberOfUnreadNotifications = 0;
      } catch (e) {
        console.error(e);
      }
    },
    async fetchNotificationsFromServer () {
      try {
        const res = await apiService.notifications.getAllNotifications({
          offset: 0,
          isRead: true,
          limit: 15
        });
        return res.data;
      } catch (e) {
        return null;
      }
    }
  }
};
</script>
