export function AddStopPaymentController (scope, resourceFactory, routeParams, location, dateFilter) {
  scope.savingAccountId = routeParams.id;
  scope.formData = {};
  scope.form = {};
  scope.routeTo = function () {
    location.path(`viewsavingaccount/${scope.savingAccountId}`);
  };

  resourceFactory.stopPaymentResourceTemplate.get({ savingAccountId: scope.savingAccountId }, function (data) {
    const { type: stopPaymentType, reason: stopPaymentReason, stopPaymentTill } = data;
    scope.stopPaymentType = stopPaymentType;
    scope.reason = stopPaymentReason;
    scope.stopPaymentTills = stopPaymentTill.map(stopPaymentValue => {
      const stopPayment = {};
      stopPayment[stopPaymentValue] = stopPaymentValue.replace(/_/g, ' ');
      return stopPayment;
    });
  });
  resourceFactory.chargesTemplate.get({ chargeTimeType: 'SPECIFIED_DUE_DATE' }, function (chargesData) {
    scope.charges = chargesData;
  });

  scope.setAmount = function () {
    scope.formData.specifiedDueDateChargeAmount = scope.form.selectedDueDateCharge?.amount;
  };

  scope.submit = function () {
    let endDate = '';
    scope.formData.locale = 'en';
    if (!this.formData.waiveCharge) {
      scope.formData.specifiedDueDateChargeId = scope.form.selectedDueDateCharge?.id;
    } else {
      delete scope.formData.specifiedDueDateChargeId;
      delete scope.formData.specifiedDueDateChargeAmount;
    }
    if (scope.formData.minAmount > scope.formData.maxAmount) {
      scope.error = 'Min Amount must lower than Max Amount';
      return;
    }

    if (scope.formData.stopPaymentTill === 'UNTIL_DATE') {
      scope.formData.dateFormat = 'yyyy-MM-dd';
      endDate = dateFilter(scope.formData.endDate, scope.formData.dateFormat);
    } else {
      delete scope.formData.dateFormat;
      delete scope.formData.endDate;
    }

    resourceFactory.stopPaymentResource.create({ savingAccountId: scope.savingAccountId }, { ...scope.formData, endDate }, function () {
      location.path(`/viewsavingaccount/${scope.savingAccountId}`);
    });
  };
}

AddStopPaymentController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location', 'dateFilter'];
