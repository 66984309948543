import { reactive, toRefs } from 'vue';
import { currencyFormatter } from '@/helper/currencyHelper';
import { dateStringToFormat } from '@/helper/dateHelper';

export function verlocity () {
  const state = reactive({
    clientLimits: {
      WITHDRAWAL: { SECONDS: {}, DAYS: {}, WEEKS: {}, MONTHS: {} },
      PURCHASE: { SECONDS: {}, DAYS: {}, WEEKS: {}, MONTHS: {} }
    },
    initialLimits: {}
  });

  function setInitialLimits (apiResponse) {
    if (!apiResponse) return;
    state.initialLimits = JSON.parse(JSON.stringify(apiResponse));
    setLimit(apiResponse, 'WITHDRAWAL');
    setLimit(apiResponse, 'PURCHASE');
  }

  function getLimit (apiResponse, limitType) {
    return apiResponse.filter((response) => response.controls.includes(limitType));
  }

  function setLimit (limitItem, limitType) {
    const apiResponse = JSON.parse(JSON.stringify(limitItem));
    const limitItems = getLimit(apiResponse, limitType);
    limitItems.forEach((item) => {
      if (!state.clientLimits[limitType] || !state.clientLimits[limitType][item.timeUnit]) return;
      state.clientLimits[limitType][item.timeUnit] = item;
      if (item.expiryDate) state.clientLimits[limitType][item.timeUnit].expiryDate = dateStringToFormat(item?.expiryDate?.date);
    });
  }

  function formatVelocity (limitType, currencySymbol, label) {
    const formatLabel = (key, expireDate) => {
      if (expireDate) return `${label(key)} (will expire after ${expireDate})`;
      return label(key);
    };
    return ['SECONDS', 'DAYS', 'WEEKS', 'MONTHS'].map(key => {
      const label = formatLabel(key.toLowerCase(), state.clientLimits[limitType][key].expiryDate);
      const value = currencyFormatter(state.clientLimits[limitType][key].value, currencySymbol);
      return { label, value };
    });
  }

  function resetLimits () {
    setLimit(state.initialLimits, 'WITHDRAWAL');
    setLimit(state.initialLimits, 'PURCHASE');
  }

  return { ...toRefs(state), setLimit, setInitialLimits, resetLimits, formatVelocity };
}
