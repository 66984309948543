/* eslint-disable no-undef */

export function CreateHookController (scope, resourceFactory, location) {
  let eventsResource = [];

  scope.name = '';
  scope.url = '';
  scope.secretKey = '';
  scope.eventActions = [];
  scope.eventNames = [];
  scope.events = [];
  scope.inputEvent = { entity: '', action: '' };

  resourceFactory.eventsResource.query(function (response) {
    eventsResource = response;
    scope.eventEntity = eventsResource.map(resource => resource.entity).sort((a, b) => a > b);
  });

  scope.onEntityChange = function (entity) {
    scope.inputEvent.action = '';
    scope.eventActions = eventsResource.find(resource => resource.entity === entity).actions.sort((a, b) => a > b);
  };

  scope.addEvent = function () {
    if (
      scope.inputEvent.entity &&
      scope.inputEvent.action &&
      !scope.events.find(e => e.entity === scope.inputEvent.entity && e.action === scope.inputEvent.action)
    ) {
      scope.events.push({ entity: scope.inputEvent.entity, action: scope.inputEvent.action });
    }

    scope.inputEvent = { entity: '', action: '' };
  };

  scope.deleteEvent = function (index) {
    scope.events.splice(index, 1);
  };

  scope.submit = function () {
    const { name, url, secretKey } = scope;
    resourceFactory.webhooksResource.save({ eventAction: 'endpoints' }, { name, url, secretKey, timeout: 4000 }, async (response) => {
      const newEndpointId = response.resourceId;
      location.path(`/viewhook/${newEndpointId}`);
    });
  };
}

CreateHookController.$inject = ['$scope', 'ResourceFactory', '$location'];
