export function ExternalServicesController ($scope, resourceFactory) {
  $scope.S3Configs = [];
  $scope.SMTPConfigs = [];
  $scope.notification = [];
  resourceFactory.externalServicesS3Resource.get(function (data) {
    for (const i in data) {
      if (data[i] !== null && data[i].name !== null) {
        data[i].name.replace(/ /g, '');
        if (!angular.equals(data[i].name, '')) {
          data[i].showEditvalue = true;
          $scope.S3Configs.push(data[i]);
        }
      }
    }
  });
  resourceFactory.externalServicesSMTPResource.get(function (data) {
    for (const i in data) {
      if (data[i].name !== '') {
        data[i].showEditvalue = true;
        $scope.SMTPConfigs.push(data[i]);
      }
    }
  });
  resourceFactory.externalServicesNotificationResource.get(function (data) {
    for (const i in data) {
      if (data[i] !== null && data[i].name !== null) {
        data[i].name.replace(/ /g, '');
        if (!angular.equals(data[i].name, '')) {
          data[i].showEditvalue = true;
          $scope.notification.push(data[i]);
        }
      }
    }
  });
}

ExternalServicesController.$inject = ['$scope', 'ResourceFactory'];
