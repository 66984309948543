export function PreviewLoanRepaymentScheduleController (scope, resourceFactory, routeParams, location) {
  scope.requestId = routeParams.requestId;
  scope.loanId = routeParams.loanId;
  scope.data = {};

  resourceFactory.loanRescheduleResource.preview({ scheduleId: scope.requestId }, function (data) {
    scope.data = data;
  });
  scope.reject = function () {
    location.path(`/loans/${scope.loanId}/rejectreschedulerequest/${scope.requestId}`);
  };
  scope.approve = function () {
    location.path(`/loans/${scope.loanId}/approvereschedulerequest/${scope.requestId}`);
  };

  scope.back = function () {
    location.path(`/loans/${scope.loanId}/viewreschedulerequest/${scope.requestId}`);
  };
}

PreviewLoanRepaymentScheduleController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location'];
