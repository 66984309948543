<template>
  <main-content-layout>
    <template #title>
      <div class="d-flex">
        <div>{{ $t('label.head.associatedClientsAndEmployees') }}</div>
      </div>
    </template>
    <div class="row">
      <div class="col-12 col-sm-6">
        <input-select
          v-model="selectedPrimaryAccountId"
          :label="$t('label.anchor.selectPrimaryAccount')"
          :options="primaryAccounts"
          text-field="accountNo"
          value-field="id"
          @input="filterChange"
        >
          <template #option="{ item }">
            <div class="fs-6 fw-bold mb-1">
              {{ item.savingsAccountNickname || item.savingsProductName }}
            </div>
            <div>
              {{ item.accountNo }}
            </div>
          </template>
          <template #selected-option="{ item }">
            <div>
              <span
                v-if="item.savingsAccountNickname || item.savingsProductName"
                class="fw-medium"
              >{{ item.savingsAccountNickname || item.savingsProductName }} -</span>
              {{ item.accountNo }}
            </div>
          </template>
        </input-select>
      </div>
      <div class="row pe-0">
        <div class="col-12 col-md-3 pb-3">
          <div class="input-group">
            <input
              v-model="searchKey"
              type="text"
              class="form-control col-sm-5"
              :placeholder="$t('label.anchor.searchByName')"
            >
            <button
              class="btn btn-primary"
              type="button"
              @click="getAssociatedClients()"
            >
              <span class="fas fa-search" />
            </button>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <input-select
            v-model="selectedStatusOption"
            :label="$t('label.clientStatus')"
            :options="clientStatusOptions"
            :text-end="false"
            text-field="label"
            value-field="value"
            @input="filterChange"
          />
        </div>
        <div class="col-12 col-md-3">
          <input-select
            v-model="selectedKycStatus"
            :label="$t('label.anchor.KYCStatus')"
            :options="KYCStatusOptions"
            :text-end="false"
            text-field="label"
            value-field="value"
            @input="filterChange"
          />
        </div>
        <div class="col-12 col-md-3 pb-3 px-0">
          <button
            class="btn btn-primary pull-right"
            :disabled="!selectedPrimaryAccountId"
            @click="$router.push(`/clients/${clientId}/employees/create?primaryAccountId=${selectedPrimaryAccountId}`)"
          >
            <i class="fas fa-plus " /> {{ $t('label.button.createEmployeeAccount') }}
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <add-exsiting-client
        class="ms-auto"
        @on-submitted="getAssociatedClients"
      />
      <button
        class="btn btn-primary ms-2"
        @click="handleReviewRegisterSummary"
      >
        {{ $t('label.button.registrationSummary') }}
      </button>
    </div>

    <div class="mt-4">
      <data-table
        ref="bTable"
        :items="items"
        :limit="limit"
        :total-records="totalRecords"
        :fields="fields"
        @entries-per-page="updateEntriesPerPage"
        @pagination-update="getAssociatedClients"
      >
        <template #displayName="{ item }">
          <a
            class="link-primary text-underline"
            @click="onClickActions(item, 'manageClient')"
          >
            {{ item.displayName }}
          </a>
        </template>
        <template #subAccountNo="{ item }">
          <a
            class="link-primary text-underline"
            @click="onClickActions(item, 'manageSubAccount')"
          >
            {{ item.subAccountNo }}
          </a>
        </template>
        <template #clientTypes="{ item }">
          <div v-if="item.clientTypes.length">
            <span
              v-for="(type, i) in item.clientTypes"
              :key="i"
              class="badge bg-success me-1"
            >
              {{ type.name }}
            </span>
          </div>
          <div v-else />
        </template>
        <template #actions="{ item }">
          <div class="d-flex">
            <button
              v-for="(action, i) in actionButtons(item)"
              :key="i"
              class="btn btn-primary m-1"
              :disabled="loading"
              @click="onClickActions(item, action)"
            >
              {{ $t(`label.button.${action}`) }}
            </button>

            <button-sms-link-clipboard :link="item.verificationRedirectUrl" />
            <button-verification-result :link="item.kycVerificationDetailsUrl" />
            <button-document-verification-result :link="item.documentVerificationDetailsUrl" />
          </div>
        </template>
      </data-table>
    </div>

    <registration-summary-modal
      ref="registrationSummaryModal"
      :client-id="clientId"
    />
  </main-content-layout>
</template>

<script>
import DataTable from '@/components/shared/data-grid/index.vue';
import apiService from '@/services/apiService';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { mapActions } from 'pinia';
import AddExsitingClient from '@/pages/clients/associated-clients/add-exsiting-client.vue';
import RegistrationSummaryModal from '@/components/clients/associated-clients/RegistrationSummaryModal.vue';
import { InputSelect } from '@/components/shared/form-input';

export default {
  name: 'AssociatedClients',
  components: {
    DataTable,
    InputSelect,
    AddExsitingClient,
    RegistrationSummaryModal,
    MainContentLayout: () => import('@/components/shared/main-content-layout/index.vue'),
    ButtonSmsLinkClipboard: () => import('@/components/clients/associated-clients/ButtonSmsLinkClipboard.vue'),
    ButtonVerificationResult: () => import('@/components/clients/associated-clients/ButtonVerificationResult.vue'),
    ButtonDocumentVerificationResult: () => import('@/components/clients/associated-clients/ButtonDocumentVerificationResult.vue')
  },
  data () {
    return {
      items: [],
      totalRecords: 0,
      limit: 15,
      offset: 0,
      fields: [
        { key: 'displayName', label: this.$t('label.displayName') },
        { key: 'subAccountNo', label: this.$t('label.subAccount') },
        { key: 'tier.name', label: this.$t('label.tier') },
        { key: 'designation', label: this.$t('label.designation') },
        { key: 'user.email', label: this.$t('label.email') },
        { key: 'clientTypes', label: this.$t('label.clientTypes') },
        { key: 'clientStatus.value', label: this.$t('label.clientStatus') },
        { key: 'kycVerificationStatus', label: this.$t('label.kycVerificationStatus') },
        { key: 'documentVerificationStatus', label: this.$t('label.documentVerificationStatus') },
        { key: 'actions', label: this.$t('label.actions') }
      ],
      clientId: this.$route.params.clientId,
      params: {},
      loading: false,
      summaryData: {},
      primaryAccounts: [],
      searchKey: null,
      selectedPrimaryAccountId: null,
      KYCStatusOptions: [],
      selectedKycStatus: null,
      clientStatusOptions: [
        {
          label: 'Active',
          value: 'ACTIVE'
        },
        {
          label: 'Pending',
          value: 'PENDING'
        }
      ],
      selectedStatusOption: null
    };
  },
  async created () {
    await this.getPrimaryAccounts();
    this.selectedPrimaryAccountId = Number(this.$route.query.primaryAccountId) || null;

    const { data: { clientKycStatusList } } = await apiService.clients.kycRequestStatusTemplate();
    this.KYCStatusOptions = clientKycStatusList.map((statusType) => ({
      label: statusType.replace('_', ' '),
      value: statusType
    }));

    const { data: { displayName } } = await apiService.clients.get(this.clientId);
    this.setBreadcrumbs([
      {
        text: this.$t('label.anchor.clients'),
        href: '#/clients'
      },
      {
        text: displayName,
        href: `#/viewclient/${this.clientId}`
      },
      {
        text: this.$t('label.anchor.associatedClients'),
        href: '#'
      }
    ]);
    this.getAssociatedClients();
  },
  methods: {
    ...mapActions(useBreadcrumbsStore, ['setBreadcrumbs']),
    async getPrimaryAccounts () {
      try {
        const { data } = await apiService.clients.getPrimaryAccounts(this.clientId);
        this.primaryAccounts = data.filter(account => account.status.code === 'savingsAccountStatusType.active');
      } catch (error) {
        this.$notify.error(error);
      }
    },
    async getAssociatedClients (offset) {
      try {
        const params = {
          limit: this.limit,
          offset: offset || this.offset,
          primaryAccount: this.selectedPrimaryAccountId,
          clientStatus: this.selectedStatusOption,
          clientKycStatus: this.selectedKycStatus,
          search: this.searchKey
        };
        if (this.selectedSearchOption && this.searchKey) {
          params[this.selectedSearchOption] = this.searchKey;
        }
        this.$refs.bTable?.toggleLoading(true);
        const { data: { pageItems, totalFilteredRecords } } = await apiService.clients.getAssociatedClients(this.clientId, params);

        this.items = pageItems.map((item) => ({
          id: item.id,
          parentId: item.parentId,
          displayName: item?.displayName,
          designation: item.designation,
          clientTypes: item.clientTypes,
          clientStatus: item.status,
          kycVerificationStatus: item.kycRequests?.[0]?.kycVerificationStatus || 'NOT YET INITIATED',
          documentVerificationStatus: item.kycRequests?.[0]?.documentVerificationStatus || 'NOT YET INITIATED',
          verificationRedirectUrl: item.kycRequests?.[0]?.verificationRedirectUrl,
          kycVerificationDetailsUrl: item.kycRequests?.[0]?.kycVerificationDetailsUrl,
          documentVerificationDetailsUrl: item.kycRequests?.[0]?.documentVerificationDetailsUrl,
          tier: item.employeeTierData,
          subAccount: item.subAccounts,
          subAccountNo: item.subAccounts[0]?.accountNumber,
          user: item.users[0],
          action: null
        }));
        this.totalRecords = totalFilteredRecords;
      } catch (error) {
        this.$notify.error(error);
      } finally {
        this.$refs.bTable?.toggleLoading(false);
      }
    },
    async initiateKyc (associatedClientId) {
      try {
        const response = await apiService.clients.initiateKyc(associatedClientId, 'initiateKyc');
        console.warn({ response });
        if (response?.data?.data?.kycVerificationStatus === 'ERROR') {
          throw new Error(response?.data?.data?.kycVerificationResponse);
        }

        this.getAssociatedClients();
      } catch (error) {
        console.error({ error });
        this.$notify.error(error.message);
      }
    },
    onClickActions (item, action) {
      switch (action) {
        case 'manageClient':
          this.redirect(`/viewclient/${item.parentId}/associated-client/${item.id}`);
          break;
        case 'manageSubAccount':
          this.redirect(`/clients/${item.parentId}/associated-client/${item.id}/saving-accounts/${item.subAccount[0].id}`);
          break;
        case 'initiateKyc':
          this.initiateKyc(item.id);
          break;
        case 'reInitiateKyc':
          this.initiateKyc(item.id);
          break;
        case 'deactivate':
          this.activateOrDeActivateSubAccount(item, 'DEACTIVATE');
          break;
      }
    },
    actionButtons (item) {
      const { kycVerificationStatus, clientStatus, documentVerificationStatus } = item;
      const buttons = [];

      if (kycVerificationStatus === 'NOT YET INITIATED') {
        buttons.push('initiateKyc');
      }
      if (kycVerificationStatus === 'ERROR' || documentVerificationStatus === 'ERROR') {
        buttons.push('reInitiateKyc');
      }
      if (this.selectedPrimaryAccountId) {
        if (clientStatus.code !== 'clientStatusType.pending' && clientStatus.code !== 'clientStatusType.active') {
          buttons.push('activate');
        }
        if (clientStatus.code === 'clientStatusType.active') {
          buttons.push('deactivate');
        }
      }
      return buttons;
    },
    redirect (path) {
      location.assign(`#${path}`);
    },
    async summaryPageData (associatedClientId) {
      this.summaryData = await apiService.watchmanLists.registrationSummary(associatedClientId);
    },
    handleReviewRegisterSummary () {
      this.$refs.registrationSummaryModal.$refs.modal.show();
    },
    async activateOrDeActivateSubAccount (item, command) {
      try {
        this.loading = true;
        await apiService.manageEmployeeAccounts.activateOrDeactivate(this.selectedPrimaryAccountId, item.subAccount[0]?.id, command);
        this.getAssociatedClients();
      } catch (error) {
        this.$notify.error(error);
      } finally {
        this.loading = false;
      }
    },
    filterChange () {
      this.searchKey = '';
      if (this.selectedPrimaryAccountId) {
        this.$router.push({ query: { primaryAccountId: this.selectedPrimaryAccountId } }).catch(() => {});
      } else {
        this.items = [];
        this.$router.replace({ query: null }).catch(() => {});
      }
      this.getAssociatedClients();
    },
    updateEntriesPerPage (limit) {
      this.limit = limit;
      this.getAssociatedClients();
    }
  }
};
</script>
