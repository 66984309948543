export function YesOrNo () {
  return (_input) => {
    let status = 'No';
    if (_input === true) {
      status = 'Yes';
    } else if (_input === false) {
      status = 'No';
    }
    return status;
  };
}
