export function PeriodicAccrualAccountingController ($scope, resourceFactory, location, translate, routeParams, dateFilter) {
  $scope.first = {};
  $scope.formData = {};
  $scope.accrueTill = new Date();
  $scope.restrictDate = new Date();

  $scope.submit = function () {
    const reqDate = dateFilter($scope.accrueTill, $scope.df);
    this.formData.locale = $scope.optlang.code;
    this.formData.dateFormat = $scope.df;
    this.formData.tillDate = reqDate;
    resourceFactory.periodicAccrualAccountingResource.run(this.formData, function () {
      location.path('/accounting');
    });
  };
}

PeriodicAccrualAccountingController.$inject = ['$scope', 'ResourceFactory', '$location', '$translate', '$routeParams', 'dateFilter'];
