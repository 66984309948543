<template>
  <nav
    v-if="totalPages.length > 1"
    aria-label="datatable-pagination"
  >
    <ul class="pagination mb-0">
      <li
        class="page-item"
        :class="{ 'disabled': isFirstPage }"
      >
        <a
          class="page-link"
          aria-label="First"
          @click.prevent="setPage(1)"
        >
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ 'disabled': isFirstPage }"
      >
        <a
          class="page-link"
          aria-label="Previous"
          @click.prevent="setPage(currentPage - 1)"
        >
          <span aria-hidden="true">&lsaquo;</span>
        </a>
      </li>
      <li
        v-for="(page, i) in totalPages"
        :key="i"
        :class="[{ active: currentPage === page }]"
      >
        <a
          class="page-link"
          @click.prevent="setPage(page)"
        >{{ page }}</a>
      </li>
      <li
        class="page-item"
        :class="{ 'disabled': isLastPage }"
      >
        <a
          class="page-link"
          aria-label="Next"
          @click.prevent="setPage(currentPage + 1)"
        >
          <span aria-hidden="true">&rsaquo;</span>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ 'disabled': isLastPage }"
      >
        <a
          class="page-link"
          aria-label="Last"
          @click.prevent="setPage(allPages)"
        >
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'PaginationComponent',
  model: {
    prop: 'currentPage',
    event: 'update'
  },
  props: {
    totalRows: {
      type: Number,
      default: 0
    },
    limitPerPage: {
      type: [Number, String],
      default: 15
    },
    currentPage: {
      type: [Number, String],
      default: 1
    },
    pagesShown: {
      type: Number,
      default: 8
    }
  },
  computed: {
    isFirstPage () {
      return this.currentPage === 1;
    },
    isLastPage () {
      return this.currentPage === this.allPages;
    },
    allPages () {
      return Math.ceil(this.totalRows / this.limitPerPage);
    },
    totalPages () {
      const numShown = Math.min(this.pagesShown, this.allPages);
      let first = this.currentPage - Math.floor(numShown / 2);
      first = Math.max(first, 1);
      first = Math.min(first, this.allPages - numShown + 1);
      return [...Array(numShown)].map((_, i) => i + first);
    }
  },
  watch: {
    totalPages () {
      if (this.currentPage > this.allPages) {
        this.setPage(1);
      }
    }
  },
  methods: {
    setPage: function (page) {
      if (page <= 0 || page > this.totalPages) {
        return;
      }
      this.$emit('update', page);
    }
  }
};
</script>
