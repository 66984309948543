<template>
  <div class="card">
    <div class="card-body">
      <PageTitle>{{ pageTitle }}</PageTitle>
      <form-wrapper @submit="submit">
        <div class="row">
          <div
            class="col-sm-12 col-md-6"
          >
            <input-text
              v-model="formData.name"
              required
              label="Tier name"
            />
            <input-text
              v-model="formData.description"
              required
              label="Tier description"
              type="textarea"
            />
            <input-check-box
              v-model="formData.enabled"
              label="Enable tier"
            />
          </div>
        </div>
        <div class="offset-6">
          <button
            class="btn btn-default me-3"
            @click.prevent="cancel"
          >
            {{ $t('label.button.cancel') }}
          </button>
          <button
            id="save"
            class="btn btn-primary"
            type="submit"
          >
            {{ $t('label.button.save') }}
          </button>
        </div>
      </form-wrapper>
    </div>
  </div>
</template>

<script setup>
import PageTitle from '@/components/shared/PageTitle.vue';
import { InputText, FormWrapper, InputCheckBox } from '@/components/shared/form-input/index';
import apiService from '@/services/apiService';
import router from '@/router';
import i18n from '@/plugins/i18n';
import { onMounted, reactive, computed } from 'vue';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { $notify } from '@/plugins/prototypes';

const breadCrumbsStore = useBreadcrumbsStore();
const { clientId } = router.currentRoute.params;

const formData = reactive({
  name: '',
  description: '',
  enabled: false
});
const { tierId } = router.currentRoute.query;

const pageTitle = computed(() => tierId ? i18n.t('label.anchor.editTiers') : i18n.t('label.anchor.addTiers'));

onMounted(async () => {
  const { data: { displayName } } = await apiService.clients.get(clientId);
  if (tierId) {
    const { data: { employeeTierData } } = await apiService.tier.getTierVelocity(clientId, tierId);
    formData.name = employeeTierData.name;
    formData.description = employeeTierData.description;
    formData.enabled = employeeTierData.enabled;
  }
  breadCrumbsStore.setBreadcrumbs([
    {
      text: i18n.t('label.anchor.clients'),
      href: '#/clients'
    },
    {
      text: displayName,
      href: `#/viewclient/${clientId}`
    },
    {
      text: pageTitle
    }
  ]);
});

const cancel = () => {
  location.assign(`#/viewclient/${clientId}`);
};

const submit = async () => {
  try {
    let response = null;
    if (tierId) {
      const { data } = await apiService.tier.update(clientId, tierId, formData);
      response = data;
    } else {
      const { data } = await apiService.tier.add(clientId, formData);
      response = data;
    }
    router.push(`/clients/${clientId}/manage-tiers/${response.resourceId}`);
  } catch (e) {
    $notify.error(e);
  }
};
</script>
