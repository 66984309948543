export function RecurringPaymentController (scope, resourceFactory, routeParams, location) {
  scope.routeTo = function (clientId, id) {
    location.path(`/viewrecurringpayment/${clientId}/${id}`);
  };

  scope.clientId = routeParams.clientId;

  resourceFactory.clientDetailResource.get({ clientId: scope.clientId }, function (data) {
    scope.clientName = data?.displayName;
  });

  resourceFactory.recurringPaymentResource.get({ clientId: scope.clientId }, function (data) {
    scope.recurringPayments = data?.pageItems;
  });
}

RecurringPaymentController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location'];
