import { defineStore } from 'pinia';

const defaultState = {
  breadcrumbs: []
};

export const useBreadcrumbsStore = defineStore('breadcrumbs', {
  state: () => ({ ...defaultState }),
  getters: {
    getBreadcrumbs () {
      return this.breadcrumbs;
    }
  },
  actions: {
    setBreadcrumbs (value) {
      this.breadcrumbs = value;
    },
    removeBreadcrumbs () {
      this.breadcrumbs = [];
    }
  }
});
