export function EditOperationalAccountMappingController (scope, resourceFactory, routeParams, location) {
  resourceFactory.operationalAccountMappingResource.get({ activityType: routeParams.type }, function (data) {
    scope.formData = data;
    scope.formData.activityType = data.activityType;
    scope.formData.currentAccountId = data.currentAccountId;
    scope.formData.currentAccountNumber = data.currentAccountNumber;
    scope.allActivityTypes = data.allActivityTypes;
  });

  scope.submit = function () {
    resourceFactory.operationalAccountMappingResource.update({ activityType: routeParams.type }, this.formData, function (data) {
      location.path(`/viewoperationaccountmapping/${data.resourceIdentifier}`);
    });
  };
}

EditOperationalAccountMappingController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location'];
