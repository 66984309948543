export function UibCollapseDirective () {
  return {
    link (scope, element, attributes) {
      element.addClass('collapse');
      scope.$watch(attributes.uibCollapse, function (v) {
        if (v) {
          element.removeClass('show');
        } else {
          element.addClass('show');
        }
      });
    }
  };
}
