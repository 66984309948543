<template>
  <base-modal
    ref="confirmSubmitNoc"
    hide-footer
  >
    <template #header>
      <div>
        {{ $t('label.heading.returnTransfer') }}
      </div>
    </template>
    <template #body>
      <form-wrapper @submit="submit">
        <input-select
          v-model="formData.errorCode"
          vertical
          required
          :label="$t('label.search.returnCode')"
          :options="errorCodeOptions"
        />
        <input-text
          v-model="formData.errorMessage"
          vertical
          :label="$t('label.search.returnMessage')"
        />
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-default"
            @click.prevent="cancelModal"
          >
            {{ $t('label.button.cancel') }}
          </button>
          <button
            type="submit"
            class="btn btn-primary"
          >
            {{ $t('label.button.submit') }}
          </button>
        </div>
      </form-wrapper>
    </template>
  </base-modal>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import apiService from '@/services/apiService';
import { FormWrapper, InputSelect, InputText } from '@/components/shared/form-input/index';
import { $notify } from '@/plugins/prototypes';
import { ref, reactive, watch } from 'vue';
import { get, set } from '@vueuse/core';

const props = defineProps({
  id: {
    type: Number,
    default: null
  }
});
const confirmSubmitNoc = ref(null);
const errorCodeOptions = ref([]);
const emits = defineEmits(['onChanged']);
const formData = reactive({
  errorMessage: '',
  errorCode: ''
});
watch(() => props.id, async (newVal) => {
  if (newVal) {
    const { data: { achReturnErrorCodes } } = await apiService.transfer.getTransfersErrorCodes();
    set(errorCodeOptions, achReturnErrorCodes);
  }
});

const clearFormData = () => {
  Object.assign(formData, {
    errorMessage: '',
    errorCode: ''
  });
};

const cancelModal = () => {
  get(confirmSubmitNoc).hide();
  clearFormData();
};

const submit = async () => {
  try {
    await apiService.transfer.cancelTransfer(props.id, formData, { command: 'cancel' });
    cancelModal();
    emits('onChanged');
  } catch (error) {
    $notify.error(error);
  }
};
</script>
