<template>
  <div>
    <img
      :src="qrcode"
      class="img-fluid"
      alt="QR Code"
    >
  </div>
</template>

<script setup>
import { useQRCode } from '@vueuse/integrations/useQRCode';

const props = defineProps({
  qrString: {
    type: String,
    default: ''
  }
});
const qrcode = useQRCode(props.qrString);
</script>
