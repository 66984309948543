import angular from 'angular';
import { ActivitiesDisplayPanelDirective } from './ActivitiesDisplayPanelDirective';
import { ApiValidationDirective } from './ApiValidationDirective';
import { AutofocusDirective } from './AutofocusDirective';
import { BigPanelDirective } from './BigPanelDirective';
import { BinInputDirective } from './BinInputDirective';
import { CompositeMobileDirective } from './CompositeMobileDirective';
import { ChosenComboboxDirective } from './ChosenComboboxDirective';
import { DecimalDirective } from './DecimalDirective';
import { dialogDirective } from './DialogDirective';
import { FormSubmitValidateDirective } from './FormSubmitValidateDirective';
import { FormValidateDirective } from './FormValidateDirective';
import { HasPermissionDirective } from './HasPermissionDirective';
import { LateValidateDirective } from './LateValidateDirective';
import { NumberFormatDirective } from './NumberFormatDirective';
import { OnBlurDirective } from './OnBlurDirective';
import { overlayDirective } from './OverlayDirective';
import { PanelDirective } from './PanelDirective';
import { PrimaryAccountNumberDirective } from './PrimaryAccountNumberDirective';
import { ScrollableDirective } from './ScrollableDirective';
import { ScrollbarTopDirective } from './ScrollbarTopDirective';
import { SuccessfulResponsesDirective } from './SuccessfulResponsesDirective';
import { SummaryDirective } from './SummaryDirective';
import { TabsPersistenceDirective } from './TabsPersistenceDirective';
import { TreeviewDirective } from './TreeviewDirective';
import { UibDropdownDirective } from './bootstrap/UibDropdownDirective';
import { UibDropdownMenuDirective } from './bootstrap/UibDropdownMenuDirective';
import { UibDropdownToggleDirective } from './bootstrap/UibDropdownToggleDirective';
import { UibCollapseDirective } from './bootstrap/UibCollapseDirective';
import { UibTooltipDirective } from './bootstrap/UibTooltipDirective';
import { TableSortableDirective } from './TableSortDirective';

export default angular
  .module('mbanq.directives', [])
  .directive('ngDisplaypanel', ActivitiesDisplayPanelDirective)
  .directive('apiValidate', ApiValidationDirective)
  .directive('ngAutofocus', AutofocusDirective)
  .directive('panelbig', BigPanelDirective)
  .directive('binInput', BinInputDirective)
  .directive('compositeMobile', CompositeMobileDirective)
  .directive('chosen', ChosenComboboxDirective)
  .directive('decimals', DecimalDirective)
  .directive('mfDialog', dialogDirective)
  .directive('rcSubmit', FormSubmitValidateDirective)
  .directive('formValidate', FormValidateDirective)
  .directive('hasPermission', HasPermissionDirective)
  .directive('lateValidate', LateValidateDirective)
  .directive('numberFormat', NumberFormatDirective)
  .directive('ngOnBlur', OnBlurDirective)
  .directive('mfOverlay', overlayDirective)
  .directive('panel', PanelDirective)
  .directive('primaryAccountNumber', PrimaryAccountNumberDirective)
  .directive('scrollable', ScrollableDirective)
  .directive('scroll', ScrollbarTopDirective)
  .directive('successfulResponses', SuccessfulResponsesDirective)
  .directive('ngSummary', SummaryDirective)
  .directive('persistentTab', TabsPersistenceDirective)
  .directive('treeModel', TreeviewDirective)
  .directive('uibDropdown', UibDropdownDirective)
  .directive('uibDropdownMenu', UibDropdownMenuDirective)
  .directive('uibDropdownToggle', UibDropdownToggleDirective)
  .directive('uibCollapse', UibCollapseDirective)
  .directive('uibTooltip', UibTooltipDirective)
  .directive('tableSortable', TableSortableDirective)
  .name;
