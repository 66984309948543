export function AddUpTotalFor () {
  return function (data, key, conditionfield, conditionvalue) {
    let condFields;
    if (typeof (data) === 'undefined' && typeof (key) === 'undefined') {
      return 0;
    }
    if (data) {
      let sum = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i][key]) {
          if (conditionfield) {
            condFields = conditionfield.split('.');
            if (condFields.length === 1) {
              if (data[i][condFields[0]] === conditionvalue) {
                sum += data[i][key];
              }
            } else if (condFields.length === 2) {
              if (data[i][condFields[0]][condFields[1]] === conditionvalue) {
                sum += data[i][key];
              }
            } else if (condFields.length === 3) {
              if (data[i][condFields[0]][condFields[1]][condFields[2]] === conditionvalue) {
                sum += data[i][key];
              }
            }
          } else {
            sum += data[i][key];
          }
        }
      }
      return sum;
    }
    return 0;
  };
}
