export function ViewEmployeeTransactionController (scope, resourceFactory, routeParams) {
  scope.transaction = {};
  scope.isExternalTransfer = false;
  scope.showCreditor = false;
  scope.showDebitor = false;
  scope.transferData = {};
  scope.errorMsg = '';
  scope.rawPaymentDetails = {};
  scope.displayViewWire = false;
  scope.primaryAccountId = routeParams.accountId;
  scope.subsidiaryAccountId = routeParams.subAccountId;
  scope.transactionId = routeParams.txnId;
  const params = {
    primaryAccountId: scope.primaryAccountId,
    subsidiaryAccountId: scope.subsidiaryAccountId,
    transactionId: scope.transactionId
  };

  if (scope.primaryAccountId) {
    resourceFactory.savingsAccounts.get({ accountId: scope.primaryAccountId }, function (parentData) {
      scope.parentClientId = parentData.clientId;
    });
  }
  // scope.onClickViewTransaction = function(){
  //   location.path(`/accounttransfers/${scope.transferId}/viewWireTransfer`)
  // }

  if (process.env.VUE_APP_SUBLEDGER !== 'evolve') {
    scope.displayViewWire = true;
  }

  resourceFactory.savingsAccounts.get({ accountId: scope.subsidiaryAccountId }, function (data) {
    scope.subCLientName = data.clientName;
    scope.subClientId = data.clientId;
    scope.subAccountNo = data.accountNo;
    scope.subAccountProductName = data.savingsProductName;
  });

  function getSavingTransaction () {
    resourceFactory.employeeAccountTrxnsResource.get(params, function (data) {
      scope.transaction = data;
      scope.paymentReference = scope.transaction.paymentDetailData.reference;
      scope.paymentType = data.paymentDetailData.paymentType?.name;
      const transferData = data.transferData;
      if (transferData) {
        scope.rawPaymentDetails = JSON.stringify(transferData.rawPaymentDetails, null, 2);
        scope.transferData = transferData;
        scope.transferId = transferData.id;
        scope.isExternalTransfer = true;

        if (transferData.paymentType === 'CARD') {
          const clientId = scope.transferData.clientId;
          const externalCardId = scope.transferData.type === 'DEBIT' ? scope.transferData.debtor.identifier.replace('EXTERNALCARD:', '') : scope.transferData.creditor.identifier.replace('EXTERNALCARD:', '');

          getExternalCardDetail({ clientId, externalCardId });
        }
      }
      if (transferData) {
        scope.transferData = transferData;
        scope.isExternalTransfer = true;
        if ((scope.transferData.inOrOut === 'OUT' && scope.transferData.type === 'CREDIT') ||
          (scope.transferData.inOrOut === 'IN' && scope.transferData.type === 'DEBIT')) {
          scope.showCreditor = true;
        }
        if (scope.transferData.inOrOut === 'IN' && scope.transferData.type === 'CREDIT') {
          scope.showDebitor = true;
        }
      }
    }, handleResponseError);
  }

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };

  function getExternalCardDetail (params) {
    resourceFactory.externalCardsResource.get(params, function (data) {
      scope.externalCardDetail = data;
    }, handleResponseError);
  }

  getSavingTransaction();
}

ViewEmployeeTransactionController.$inject = ['$scope', 'ResourceFactory', '$routeParams'];
