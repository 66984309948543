import { paymentRailsExcludes } from '@/utils/dictionaries.js';
export function TransfersController (scope, resourceFactory, dateFilter, location, $filter) {
  // Init data
  scope.errorMsg = '';
  scope.isLoading = false;
  scope.tableHeader = [];
  scope.tableData = [];
  scope.transfersPerPage = 15;
  scope.totalTransfers = 0;
  scope.pageNumber = 1;
  scope.displayApproveButton = false;

  scope.originatedByOptions = [
    { value: 'us', name: 'OUTGOING' },
    { value: 'external party', name: 'INCOMING' }
  ];

  if (process.env.VUE_APP_SUBLEDGER !== 'evolve') {
    scope.displayApproveButton = true;
  }

  scope.paymentSchemeOptions = paymentRailsExcludes(['ALL']);

  scope.transferTypeOptions = [
    { value: '', name: 'ALL' },
    { value: 'CREDIT', name: 'CREDIT' },
    { value: 'DEBIT', name: 'DEBIT' }
  ];

  scope.transferStatusOptions = [
    { value: '', name: 'ALL' },
    { value: 'DRAFT', name: 'DRAFT' },
    { value: 'UNKNOWN', name: 'UNKNOWN' },
    { value: 'AML_SCREENING', name: 'AML SCREENING' },
    { value: 'AML_REJECTED', name: 'AML REJECTED' },
    { value: 'RETURNED', name: 'RETURNED' },
    { value: 'CANCELLED', name: 'CANCELLED' },
    { value: 'EXECUTION_SCHEDULED', name: 'EXECUTION SCHEDULED' },
    { value: 'EXECUTION_PROCESSING', name: 'EXECUTION PROCESSING' },
    { value: 'EXECUTION_SUCCESS', name: 'EXECUTION SUCCESS' },
    { value: 'EXECUTION_FAILURE', name: 'EXECUTION FAILURE' },
    { value: 'COMPLIANCE_FAILURE', name: 'COMPLIANCE FAILURE' },
    { value: 'DELETED', name: 'DELETED' }
  ];

  scope.fieldOptions = [
    { value: 'transferId', name: 'Transfer ID' },
    { value: 'originatorName', name: 'Originator Name' },
    { value: 'originatorAccount', name: 'Originator Account' },
    { value: 'originatorBankRoutingCode', name: 'Originator Bank Routing Code' },
    { value: 'recipientName', name: 'Recipient Name' },
    { value: 'recipientAccount', name: 'Recipient Account' },
    { value: 'recipientBankRoutingCode', name: 'Recipient Bank Routing Code' },
    { value: 'reference', name: 'Reference' },
    { value: 'traceNumber', name: 'Trace' }
  ];

  scope.filterData = {
    originatedBy: 'us',
    transferStatus: '',
    transferDateFrom: '',
    transferDateTo: '',
    returnDateFrom: '',
    returnDateTo: '',
    paymentScheme: 'ACH',
    transferType: '',
    field: 'transferId'
  };

  // Funtion helper
  const getTableHeader = function () {
    const generalOutgoingHeader = ['Transfer Time', 'Originator:<br>Account:', 'Amount', 'Recipient:<br>Account:<br>Bank Route Code:<br>Bank Name:', 'Status', 'Reference', 'Error Code', 'Error Details', 'Return Date', 'Type', 'Transaction Detail', 'Transfer Details'];
    const generalIncomingHeader = ['Transfer Time', 'Recipient:<br>Account:', 'Type', 'Amount', 'Status', 'Originator Bank Route Code', 'Reference', 'Error Code', 'Error Details', 'Transfer Details'];

    if (scope.filterData.originatedBy === 'us' && scope.filterData.paymentScheme === 'WIRE') {
      return generalOutgoingHeader;
    } if (scope.filterData.originatedBy === 'external party' && scope.filterData.paymentScheme === 'WIRE') {
      return generalIncomingHeader;
    } if (scope.filterData.originatedBy === 'us' && scope.filterData.paymentScheme === 'ACH') {
      return generalOutgoingHeader;
    } if (scope.filterData.originatedBy === 'external party' && scope.filterData.paymentScheme === 'ACH') {
      return generalIncomingHeader;
    } if (scope.filterData.originatedBy === 'us' && scope.filterData.paymentScheme === 'SWIFT') {
      return generalOutgoingHeader;
    } if (scope.filterData.originatedBy === 'external party' && scope.filterData.paymentScheme === 'SWIFT') {
      return generalIncomingHeader;
    } if (scope.filterData.originatedBy === 'us' && scope.filterData.paymentScheme === 'INTERNAL') {
      return generalOutgoingHeader;
    } if (scope.filterData.originatedBy === 'external party' && scope.filterData.paymentScheme === 'INTERNAL') {
      return generalIncomingHeader;
    } if (scope.filterData.paymentScheme === 'CARD') {
      return ['Transfer Time', 'Originator Name', 'Originator Account', 'Amount', 'Recipient', 'Recipient Account', 'Status', 'Reference', 'Error Code', 'Error Details', 'Type', 'Transaction Detail', 'Transfer Details'];
    } if (scope.filterData.paymentScheme === 'FXPAY') {
      return ['Transfer Time', 'Originator Name Account', 'Amount', 'Recipient', 'Status', 'Reference', 'Error Code', 'Error Details', 'Return Date', 'Type', 'Transaction Detail', 'Transfer Details'];
    } else {
      if (scope.filterData.originatedBy === 'us') {
        return generalOutgoingHeader;
      }
      return generalIncomingHeader;
    }
  };

  const getTableData = function (data) {
    if (scope.filterData.paymentScheme === 'CARD') {
      // When payment scheme is CARD, the originatedBy is only from us or Outgoing.
      return data.map(function (d, i) {
        const isOutbounceCredit = d.type === 'CREDIT';
        const isOutbounceDebit = d.type === 'DEBIT';
        const debtorSavingAccount = d.debtorAccountNumber || d.debtor?.identifier;
        const creditorSavingAccount = d.creditorAccountNumber || d.creditor?.identifier;
        return {
          rowId: `r${i}`,
          showViewDebtorBtn: isOutbounceCredit,
          showViewCreditorBtn: isOutbounceDebit,
          transferId: d.id,
          debtorAccountId: d.debtorAccountId,
          debtorSavingsAccountTransactionId: d.debtorSavingsAccountTransactionId,
          creditorAccountId: d.creditorAccountId,
          creditorSavingsAccountTransactionId: d.creditorSavingsAccountTransactionId,
          status: d.status,
          type: d.type,
          fileUrl: d?.fileUrl,
          dataRow: [
            [{ type: 'text', value: d.executedAt }],
            [{ type: 'text', value: isOutbounceCredit ? d.debtor.name : d.creditor.name }],
            [{ type: 'link', value: isOutbounceCredit ? $filter('AccountIdentifierFormat')(debtorSavingAccount) : $filter('AccountIdentifierFormat')(creditorSavingAccount) }],
            [{ type: 'text', value: d.amount }],
            [{ type: 'text', value: d.transferDetails?.CARDName }],
            [{ type: 'text', value: $filter('FormatPAN')(d.transferDetails?.CARDLastDigit?.toString(), true) }],
            [{ type: 'text', value: d.status }],
            [{ type: 'text', value: d.reference?.join(', ') }],
            [{ type: 'text', value: d.errorCode }],
            [{ type: 'text', value: d.errorMessage }],
            [{ type: 'text', value: d.type }]
          ]
        };
      });
    }

    if (scope.filterData.originatedBy === 'us') {
      return data.map(function (d, i) {
        const isOutbounceCredit = d.type === 'CREDIT';
        const isOutbounceDebit = d.type === 'DEBIT';
        const debtorSavingAccount = d.debtorAccountNumber || d.debtor?.identifier;
        const creditorSavingAccount = d.creditorAccountNumber || d.creditor?.identifier;
        return {
          rowId: `r${i}`,
          showViewDebtorBtn: isOutbounceCredit,
          showViewCreditorBtn: isOutbounceDebit,
          transferId: d.id,
          debtorAccountId: d.debtorAccountId,
          debtorSavingsAccountTransactionId: d.debtorSavingsAccountTransactionId,
          creditorAccountId: d.creditorAccountId,
          creditorSavingsAccountTransactionId: d.creditorSavingsAccountTransactionId,
          status: d.status,
          type: d.type,
          fileUrl: d?.fileUrl,
          dataRow: [
            [{ type: 'text', value: d.executedAt }],

            // Originiator Name , Originator Account
            [{ type: 'text', value: isOutbounceCredit ? d.debtor.name : d.creditor.name }, { type: 'link', value: isOutbounceCredit ? $filter('AccountIdentifierFormat')(debtorSavingAccount) : $filter('AccountIdentifierFormat')(creditorSavingAccount) }],

            // Amount
            [{ type: 'text', value: d.amount }],

            // Recipient, Recipient Name, Recipient Bank route code, Recipient Bank Name
            [
              { type: 'text', value: isOutbounceCredit ? d.creditor.name : d.debtor.name },
              { type: 'text', value: isOutbounceCredit ? $filter('AccountIdentifierFormat')(creditorSavingAccount) : $filter('AccountIdentifierFormat')(debtorSavingAccount) },
              { type: 'text', value: isOutbounceCredit ? $filter('RoutingNumberFormat')(d.creditor?.identifier) : $filter('RoutingNumberFormat')(d.debtor?.identifier) },
              { type: 'text', value: isOutbounceCredit ? d.creditor?.agent?.name : d.debtor?.agent?.name }
            ],

            [{ type: 'text', value: d.status }],
            [{ type: 'text', value: d.reference?.join(', ') }],
            [{ type: 'text', value: d.errorCode }],
            [{ type: 'text', value: d.errorMessage }],
            [{ type: 'text', value: d.returnDate }],
            [{ type: 'text', value: d.type }]
          ]
        };
      });
    }

    if (scope.filterData.originatedBy === 'external party') {
      return data.map(function (d, i) {
        const isInbounceCredit = d.type === 'CREDIT';
        const isInbounceDebit = d.type === 'DEBIT';
        const debtorSavingAccount = d.debtorAccountNumber || d.debtor?.identifier;
        const creditorSavingAccount = d.creditorAccountNumber || d.creditor?.identifier;

        return {
          rowId: `r${i}`,
          showViewDebtorBtn: isInbounceDebit,
          showViewCreditorBtn: isInbounceCredit,
          transferId: d.id,
          debtorAccountId: d.debtorAccountId,
          debtorSavingsAccountTransactionId: d.debtorSavingsAccountTransactionId,
          creditorAccountId: d.creditorAccountId,
          creditorSavingsAccountTransactionId: d.creditorSavingsAccountTransactionId,
          status: d.status,
          type: d.type,
          fileUrl: d?.fileUrl,
          dataRow: [
            [{ type: 'text', value: d.executedAt }],

            // Name and Acc
            [{ type: 'text', value: isInbounceCredit ? d.creditor.name : d.debtor.name }, { type: 'link', value: $filter('AccountIdentifierFormat')(isInbounceCredit ? creditorSavingAccount : debtorSavingAccount) }],

            [{ type: 'text', value: d.type }],
            [{ type: 'text', value: d.amount }],
            [{ type: 'text', value: d.status }],
            [{ type: 'text', value: isInbounceCredit ? $filter('RoutingNumberFormat')(d.debtor?.identifier) : $filter('RoutingNumberFormat')(d.creditor?.identifier) }],
            [{ type: 'text', value: d.reference?.join(', ') }],
            [{ type: 'text', value: d.errorCode }],
            [{ type: 'text', value: d.errorMessage }]
          ]
        };
      });
    }

    return [];
  };

  scope.redirectToProfile = function (accountId) {
    location.path(`/viewsavingaccount/${accountId}`);
  };

  scope.getTransfers = function () {
    const transferFilter = {
      offset: ((scope.pageNumber - 1) * scope.transfersPerPage),
      limit: scope.transfersPerPage,
      dateFormat: 'yyyy-MM-dd',
      locale: scope.optlang.code,
      originatedBy: scope.filterData.originatedBy,
      paymentType: scope.filterData.paymentScheme,
      orderBy: 'id',
      sortOrder: 'DESC'
    };

    if (scope.filterData?.transferStatus) {
      transferFilter.status = scope.filterData.transferStatus;
    }

    if (scope.filterData?.transferType) {
      transferFilter.transferType = scope.filterData.transferType;
    }

    if (scope.filterData.transferDateFrom && scope.filterData.transferDateTo) {
      transferFilter.fromExecuteDate = dateFilter(scope.filterData.transferDateFrom, 'yyyy-MM-dd');
      transferFilter.toExecuteDate = dateFilter(scope.filterData.transferDateTo, 'yyyy-MM-dd');
    }

    if (scope.filterData.returnDateFrom && scope.filterData.returnDateTo && scope.filterData.transferStatus === 'EXECUTION_FAILURE') {
      transferFilter.fromReturnDate = dateFilter(scope.filterData.returnDateFrom, 'yyyy-MM-dd');
      transferFilter.toReturnDate = dateFilter(scope.filterData.returnDateTo, 'yyyy-MM-dd');
    }

    if (scope.filterData?.fieldValue) {
      transferFilter[scope.filterData.field] = scope.filterData?.fieldValue;
    }

    resourceFactory.transferResource.query(transferFilter, function (data) {
      scope.isLoading = false;
      scope.totalTransfers = data.totalFilteredRecords;
      scope.tableHeader = getTableHeader();
      scope.tableData = getTableData(data.pageItems);
    }, handleResponseError);
  };
  scope.getTransfers();

  scope.routeToTransaction = function (accountId, transactionId) {
    location.path(`/viewsavingtrxn/${accountId}/trxnId/${transactionId}`);
  };

  scope.$watch('filterData.field', function (field) {
    scope.fieldOptionValueRegex = (field === 'transferId') ? /^\d+$/ : /\w/;
  });

  // Funtion Event Handler
  scope.onSubmitFilter = function () {
    scope.isLoading = true;
    scope.getTransfers();
  };

  scope.onPaginationChange = function (pageNumber) {
    scope.pageNumber = pageNumber;
    scope.getTransfers();
  };

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.message || data.errors[0].defaultUserMessage;
  }

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };

  scope.handlePaymentSchemeOptionsChange = function () {
    if (scope.filterData.paymentScheme === 'CARD') {
      scope.isDisabledOriginatedBy = true;
      scope.filterData.originatedBy = 'us';
    } else {
      scope.isDisabledOriginatedBy = false;
    }
  };
}

TransfersController.$inject = [
  '$scope',
  'ResourceFactory',
  'dateFilter',
  '$location',
  '$filter'
];
