export function DotRemove () {
  return function (input) {
    if (input) {
      const exp = input;
      let alpha = '';
      for (let i = 0; i < exp.length; i++) {
        if (exp[i] !== '.') {
          alpha += exp[i];
        }
      }
      return alpha;
    }
  };
}
