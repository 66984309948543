export function TaxComponentController ($scope, resourceFactory, location) {
  $scope.taxcomponents = [];

  $scope.routeTo = function (id) {
    location.path(`/viewtaxcomponent/${id}`);
  };

  resourceFactory.taxcomponent.getAll(function (data) {
    $scope.taxcomponents = data;
  });
}

TaxComponentController.$inject = ['$scope', 'ResourceFactory', '$location'];
