export function EditWatchmanRuleGroupController (scope, routeParams, resourceFactory, location) {
  const ruleId = routeParams.id;
  scope.formData = {};
  scope.ruleGroup = {};

  function loadData () {
    resourceFactory.amlWatchmansResource.get({ ruleId }, function (res) {
      const { id, name, description, matchRate, limit, currencyCode } = res;
      scope.ruleGroup = { id, name };
      scope.formData = { name, description, matchRate, limit };
      scope.currencyCode = currencyCode;
    }, handleResponseError);
  }

  loadData();

  scope.submit = function () {
    const data = { ...scope.formData };
    resourceFactory.amlWatchmansResource.update({ ruleId }, data, function (res) {
      const { resourceId } = res;
      location.path(`/payments/aml/watchmanrulegroup/${resourceId}`);
    }, handleResponseError);
  };

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
}

EditWatchmanRuleGroupController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
