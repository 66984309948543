export function EditPasswordPreferencesController ($scope, resourceFactory, location) {
  $scope.formData = {};
  resourceFactory.passwordPrefTemplateResource.get(function (data) {
    $scope.dataOptions = data;
    $scope.formData.validationPolicyId = data.find(item => item.active).id.toString();
  });

  $scope.submit = function () {
    resourceFactory.passwordPrefResource.put($scope.formData, function () {
      location.path('/organization/');
    });
  };
}

EditPasswordPreferencesController.$inject = ['$scope', 'ResourceFactory', '$location'];
