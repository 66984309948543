export function EditFieldValidationController (scope, routeParams, resourceFactory, location) {
  scope.formData = {};
  scope.formData.isMandatory = false;
  const fieldValidationId = routeParams.fieldValidationId;
  const fieldConfigurationId = routeParams.fieldConfigurationId;

  resourceFactory.fieldconfigurationResource.get({ id: fieldConfigurationId }, function (data) {
    scope.formData = data.fieldValidations.find(fieldValidation => fieldValidation.id === Number(fieldValidationId));
  });

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
  scope.submit = function () {
    const { id, ...formData } = scope.formData;
    resourceFactory.fieldvalidationResource.update({ fieldConfigurationId, fieldValidationId }, formData, function () {
      location.path(`/viewfieldconfiguration/${fieldConfigurationId}`);
    }, ({ data: { errors } }) => {
      scope.errorMsg = errors[0].defaultUserMessage;
    });
  };
}

EditFieldValidationController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
