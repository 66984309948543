export function GlobalConfigurationController ($scope, resourceFactory, route) {
  $scope.configs = [];
  $scope.GlobalsPerPage = 15;
  resourceFactory.configurationResource.get(function (data) {
    for (const i in data.globalConfiguration) {
      if (data.globalConfiguration[i].valueDataType !== 'NONE') {
        data.globalConfiguration[i].showEditvalue = true;
      } else data.globalConfiguration[i].showEditvalue = false;

      $scope.configs.push(data.globalConfiguration[i]);
    }
  });

  if (!$scope.searchCriteria.config) {
    $scope.searchCriteria.config = null;
    $scope.saveSC();
  }
  $scope.filterText = $scope.searchCriteria.config || '';

  $scope.onFilter = function () {
    $scope.searchCriteria.config = $scope.filterText;
    $scope.saveSC();
  };

  $scope.enable = function (id, name) {
    if (name === 'Is Cache Enabled') {
      const temp = {};
      temp.cacheType = 2;
      resourceFactory.cacheResource.update(temp, function () {
        route.reload();
      });
    } else {
      const temp = { enabled: 'true' };
      resourceFactory.configurationResource.update({ id }, temp, function () {
        route.reload();
      });
    }
  };
  $scope.disable = function (id, name) {
    if (name === 'Is Cache Enabled') {
      const temp = {};
      temp.cacheType = 1;
      resourceFactory.cacheResource.update(temp, function () {
        route.reload();
      });
    } else {
      const temp = { enabled: 'false' };
      resourceFactory.configurationResource.update({ id }, temp, function () {
        route.reload();
      });
    }
  };
}

GlobalConfigurationController.$inject = ['$scope', 'ResourceFactory', '$route'];
