<template>
  <div class="d-flex justify-content-end">
    <div
      v-if="!!cardButtonStatuses[status?.toLowerCase()]"
      class="btn-group mt-4"
    >
      <slot />
    </div>
  </div>
</template>

<script setup>
import { cardButtonStatuses } from '@/utils/dictionaries/cardButtonStatuses.dictionary';

defineProps({
  status: {
    type: String,
    default: ''
  }
});
</script>
