export function ViewReportsController (scope, routeParams, resourceFactory, location) {
  scope.reports = [];
  scope.type = routeParams.type;
  // to display type of report on breadcrumb
  const typeReport = `${routeParams.type.replace(routeParams.type[0], routeParams.type[0].toUpperCase())} ` + `Reports`;
  scope.type = typeReport;

  scope.routeTo = function (report) {
    location.path(`/run_report/${report.report_name}`).search({ reportId: report.report_id, type: report.report_type });
  };

  if (!scope.searchCriteria.reports) {
    scope.searchCriteria.reports = null;
    scope.saveSC();
  }
  scope.filterText = scope.searchCriteria.reports || '';

  scope.addLocaleReportName = function () {
    if (document.getElementsByName('locale_name') !== undefined && scope.reports) {
      if (scope.reports[0].report_locale_name === undefined) {
        const result = document.getElementsByName('locale_name');
        for (let i = 0; i < result.length; i++) {
          scope.reports[i].report_locale_name = result[i].value;
        }
        // console.log(JSON.stringify($scope.reports));
      }
      scope.onFilter();
    }
  };

  scope.filterByReportSubType = function (report) {
    return !(report.report_subtype === 'Triggered');
  };

  scope.onFilter = function () {
    scope.searchCriteria.reports = scope.filterText;
    scope.saveSC();
  };

  const reportTypes = {
    all: { reportSource: 'FullReportList' },
    clients: { reportSource: 'reportCategoryList', R_reportCategory: 'Client' },
    loans: { reportSource: 'reportCategoryList', R_reportCategory: 'Loan' },
    savings: { reportSource: 'reportCategoryList', R_reportCategory: 'Savings' },
    funds: { reportSource: 'reportCategoryList', R_reportCategory: 'Fund' },
    accounting: { reportSource: 'reportCategoryList', R_reportCategory: 'Accounting' }
  };

  const selectedType = reportTypes[routeParams.type];
  if (selectedType) {
    const reportParams = {
      parameterType: true,
      genericResultSet: false,
      ...selectedType
    };
    resourceFactory.runReportsResource.get(reportParams, function (data) {
      scope.reports = scope.getReports(data);
    });
  }

  scope.ReportsPerPage = 15;

  // Remove the duplicate entries from the array. The reports api returns same report multiple times if it have more than one parameter.
  scope.getReports = function (data) {
    let prevId = -1;
    let currId;
    const reports = [];
    for (let i = 0; i < data.length; i++) {
      currId = data[i].report_id;
      if (currId !== prevId) { reports.push(data[i]); }
      prevId = currId;
    }
    return reports;
  };
}

ViewReportsController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
