<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.anchor.disabling-payment-rails') }}
    </template>
    <div class="d-flex mb-2">
      <view-history
        class="ms-auto"
      />
    </div>

    <data-grid
      :items="items"
      :fields="fields"
    >
      <template #value="{ item }">
        <i
          :class="`fas fa-xl ${!item.value? 'fa-check-circle': 'fa-times-circle text-danger'}`"
        />
      </template>
      <template #actions="{ item }">
        <enable-or-disable
          v-has-permission="'UPDATE_DISABLING_TRANSFER_OPTION_CLIENT'"
          :current-item="item"
          @on-enabled-disabled="getPaymentRails"
        />
      </template>
    </data-grid>
  </main-content-layout>
</template>

<script setup>
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import { ref, onMounted } from 'vue';
import DataGrid from '@/components/shared/data-grid/index.vue';
import i18n from '@/plugins/i18n';
import apiService from '@/services/apiService';
import { $notify } from '@/plugins/prototypes';
import router from '@/router';
import ViewHistory from '@/components/disabling-payment-rails/ViewHistory.vue';
import EnableOrDisable from '@/components/disabling-payment-rails/EnableOrDisable.vue';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';

const breadCrumnsStore = useBreadcrumbsStore();
const fields = ref([
  { key: 'displayText', label: i18n.t('label.heading.name') },
  { key: 'value', label: i18n.t('label.heading.enabled') },
  { key: 'actions', label: i18n.t('label.heading.actions') }
]);

const { clientId } = router.currentRoute.params;

const items = ref();

onMounted(async () => {
  await getPaymentRails();
  const { data: { displayName } } = await apiService.clients.get(clientId);
  breadCrumnsStore.setBreadcrumbs([
    {
      text: i18n.t('label.anchor.clients'),
      href: '#/clients'
    },
    {
      text: displayName,
      href: `#/viewclient/${clientId}`
    },
    { text: i18n.t('label.anchor.disablingPaymentRails') }
  ]);
});

const getPaymentRails = async () => {
  try {
    const { data } = await apiService.clients.getPaymentRails(clientId);
    items.value = Object.entries(data)
      .filter(([key]) => key !== 'id' && key !== 'resourceId' && key !== 'type')
      .map(([key, value]) => {
        const splitString = key.split(/(?=[A-Z])/).slice(1, -1);
        return { name: key, displayText: splitString.join(' '), value };
      });
  } catch (error) {
    $notify.error(error);
  }
};

</script>
