// ISO 3166 codes

export const countries = [
  {
    name: 'Afghanistan',
    alpha2: 'AF',
    code: '004'
  },
  {
    name: 'Åland Islands',
    alpha2: 'AX',
    code: '248'
  },
  {
    name: 'Albania',
    alpha2: 'AL',
    code: '008'
  },
  {
    name: 'Algeria',
    alpha2: 'DZ',
    code: '012'
  },
  {
    name: 'American Samoa',
    alpha2: 'AS',
    code: '016'
  },
  {
    name: 'Andorra',
    alpha2: 'AD',
    code: '020'
  },
  {
    name: 'Angola',
    alpha2: 'AO',
    code: '024'
  },
  {
    name: 'Anguilla',
    alpha2: 'AI',
    code: '660'
  },
  {
    name: 'Antarctica',
    alpha2: 'AQ',
    code: '010'
  },
  {
    name: 'Antigua and Barbuda',
    alpha2: 'AG',
    code: '028'
  },
  {
    name: 'Argentina',
    alpha2: 'AR',
    code: '032'
  },
  {
    name: 'Armenia',
    alpha2: 'AM',
    code: '051'
  },
  {
    name: 'Aruba',
    alpha2: 'AW',
    code: '533'
  },
  {
    name: 'Australia',
    alpha2: 'AU',
    code: '036'
  },
  {
    name: 'Austria',
    alpha2: 'AT',
    code: '040'
  },
  {
    name: 'Azerbaijan',
    alpha2: 'AZ',
    code: '031'
  },
  {
    name: 'Bahamas',
    alpha2: 'BS',
    code: '044'
  },
  {
    name: 'Bahrain',
    alpha2: 'BH',
    code: '048'
  },
  {
    name: 'Bangladesh',
    alpha2: 'BD',
    code: '050'
  },
  {
    name: 'Barbados',
    alpha2: 'BB',
    code: '052'
  },
  {
    name: 'Belarus',
    alpha2: 'BY',
    code: '112'
  },
  {
    name: 'Belgium',
    alpha2: 'BE',
    code: '056'
  },
  {
    name: 'Belize',
    alpha2: 'BZ',
    code: '084'
  },
  {
    name: 'Benin',
    alpha2: 'BJ',
    code: '204'
  },
  {
    name: 'Bermuda',
    alpha2: 'BM',
    code: '060'
  },
  {
    name: 'Bhutan',
    alpha2: 'BT',
    code: '064'
  },
  {
    name: 'Bolivia (Plurinational State of)',
    alpha2: 'BO',
    code: '068'
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    alpha2: 'BQ',
    code: '535'
  },
  {
    name: 'Bosnia and Herzegovina',
    alpha2: 'BA',
    code: '070'
  },
  {
    name: 'Botswana',
    alpha2: 'BW',
    code: '072'
  },
  {
    name: 'Bouvet Island',
    alpha2: 'BV',
    code: '074'
  },
  {
    name: 'Brazil',
    alpha2: 'BR',
    code: '076'
  },
  {
    name: 'British Indian Ocean Territory',
    alpha2: 'IO',
    code: '086'
  },
  {
    name: 'Brunei Darussalam',
    alpha2: 'BN',
    code: '096'
  },
  {
    name: 'Bulgaria',
    alpha2: 'BG',
    code: '100'
  },
  {
    name: 'Burkina Faso',
    alpha2: 'BF',
    code: '854'
  },
  {
    name: 'Burundi',
    alpha2: 'BI',
    code: '108'
  },
  {
    name: 'Cabo Verde',
    alpha2: 'CV',
    code: '132'
  },
  {
    name: 'Cambodia',
    alpha2: 'KH',
    code: '116'
  },
  {
    name: 'Cameroon',
    alpha2: 'CM',
    code: '120'
  },
  {
    name: 'Canada',
    alpha2: 'CA',
    code: '124'
  },
  {
    name: 'Cayman Islands',
    alpha2: 'KY',
    code: '136'
  },
  {
    name: 'Central African Republic',
    alpha2: 'CF',
    code: '140'
  },
  {
    name: 'Chad',
    alpha2: 'TD',
    code: '148'
  },
  {
    name: 'Chile',
    alpha2: 'CL',
    code: '152'
  },
  {
    name: 'China',
    alpha2: 'CN',
    code: '156'
  },
  {
    name: 'Christmas Island',
    alpha2: 'CX',
    code: '162'
  },
  {
    name: 'Cocos (Keeling) Islands',
    alpha2: 'CC',
    code: '166'
  },
  {
    name: 'Colombia',
    alpha2: 'CO',
    code: '170'
  },
  {
    name: 'Comoros',
    alpha2: 'KM',
    code: '174'
  },
  {
    name: 'Congo',
    alpha2: 'CG',
    code: '178'
  },
  {
    name: 'Congo, Democratic Republic of the',
    alpha2: 'CD',
    code: '180'
  },
  {
    name: 'Cook Islands',
    alpha2: 'CK',
    code: '184'
  },
  {
    name: 'Costa Rica',
    alpha2: 'CR',
    code: '188'
  },
  {
    name: 'Côte d\'Ivoire',
    alpha2: 'CI',
    code: '384'
  },
  {
    name: 'Croatia',
    alpha2: 'HR',
    code: '191'
  },
  {
    name: 'Cuba',
    alpha2: 'CU',
    code: '192'
  },
  {
    name: 'Curaçao',
    alpha2: 'CW',
    code: '531'
  },
  {
    name: 'Cyprus',
    alpha2: 'CY',
    code: '196'
  },
  {
    name: 'Czechia',
    alpha2: 'CZ',
    code: '203'
  },
  {
    name: 'Denmark',
    alpha2: 'DK',
    code: '208'
  },
  {
    name: 'Djibouti',
    alpha2: 'DJ',
    code: '262'
  },
  {
    name: 'Dominica',
    alpha2: 'DM',
    code: '212'
  },
  {
    name: 'Dominican Republic',
    alpha2: 'DO',
    code: '214'
  },
  {
    name: 'Ecuador',
    alpha2: 'EC',
    code: '218'
  },
  {
    name: 'Egypt',
    alpha2: 'EG',
    code: '818'
  },
  {
    name: 'El Salvador',
    alpha2: 'SV',
    code: '222'
  },
  {
    name: 'Equatorial Guinea',
    alpha2: 'GQ',
    code: '226'
  },
  {
    name: 'Eritrea',
    alpha2: 'ER',
    code: '232'
  },
  {
    name: 'Estonia',
    alpha2: 'EE',
    code: '233'
  },
  {
    name: 'Eswatini',
    alpha2: 'SZ',
    code: '748'
  },
  {
    name: 'Ethiopia',
    alpha2: 'ET',
    code: '231'
  },
  {
    name: 'Falkland Islands (Malvinas)',
    alpha2: 'FK',
    code: '238'
  },
  {
    name: 'Faroe Islands',
    alpha2: 'FO',
    code: '234'
  },
  {
    name: 'Fiji',
    alpha2: 'FJ',
    code: '242'
  },
  {
    name: 'Finland',
    alpha2: 'FI',
    code: '246'
  },
  {
    name: 'France',
    alpha2: 'FR',
    code: '250'
  },
  {
    name: 'French Guiana',
    alpha2: 'GF',
    code: '254'
  },
  {
    name: 'French Polynesia',
    alpha2: 'PF',
    code: '258'
  },
  {
    name: 'French Southern Territories',
    alpha2: 'TF',
    code: '260'
  },
  {
    name: 'Gabon',
    alpha2: 'GA',
    code: '266'
  },
  {
    name: 'Gambia',
    alpha2: 'GM',
    code: '270'
  },
  {
    name: 'Georgia',
    alpha2: 'GE',
    code: '268'
  },
  {
    name: 'Germany',
    alpha2: 'DE',
    code: '276'
  },
  {
    name: 'Ghana',
    alpha2: 'GH',
    code: '288'
  },
  {
    name: 'Gibraltar',
    alpha2: 'GI',
    code: '292'
  },
  {
    name: 'Greece',
    alpha2: 'GR',
    code: '300'
  },
  {
    name: 'Greenland',
    alpha2: 'GL',
    code: '304'
  },
  {
    name: 'Grenada',
    alpha2: 'GD',
    code: '308'
  },
  {
    name: 'Guadeloupe',
    alpha2: 'GP',
    code: '312'
  },
  {
    name: 'Guam',
    alpha2: 'GU',
    code: '316'
  },
  {
    name: 'Guatemala',
    alpha2: 'GT',
    code: '320'
  },
  {
    name: 'Guernsey',
    alpha2: 'GG',
    code: '831'
  },
  {
    name: 'Guinea',
    alpha2: 'GN',
    code: '324'
  },
  {
    name: 'Guinea-Bissau',
    alpha2: 'GW',
    code: '624'
  },
  {
    name: 'Guyana',
    alpha2: 'GY',
    code: '328'
  },
  {
    name: 'Haiti',
    alpha2: 'HT',
    code: '332'
  },
  {
    name: 'Heard Island and McDonald Islands',
    alpha2: 'HM',
    code: '334'
  },
  {
    name: 'Holy See',
    alpha2: 'VA',
    code: '336'
  },
  {
    name: 'Honduras',
    alpha2: 'HN',
    code: '340'
  },
  {
    name: 'Hong Kong',
    alpha2: 'HK',
    code: '344'
  },
  {
    name: 'Hungary',
    alpha2: 'HU',
    code: '348'
  },
  {
    name: 'Iceland',
    alpha2: 'IS',
    code: '352'
  },
  {
    name: 'India',
    alpha2: 'IN',
    code: '356'
  },
  {
    name: 'Indonesia',
    alpha2: 'ID',
    code: '360'
  },
  {
    name: 'Iran (Islamic Republic of)',
    alpha2: 'IR',
    code: '364'
  },
  {
    name: 'Iraq',
    alpha2: 'IQ',
    code: '368'
  },
  {
    name: 'Ireland',
    alpha2: 'IE',
    code: '372'
  },
  {
    name: 'Isle of Man',
    alpha2: 'IM',
    code: '833'
  },
  {
    name: 'Israel',
    alpha2: 'IL',
    code: '376'
  },
  {
    name: 'Italy',
    alpha2: 'IT',
    code: '380'
  },
  {
    name: 'Jamaica',
    alpha2: 'JM',
    code: '388'
  },
  {
    name: 'Japan',
    alpha2: 'JP',
    code: '392'
  },
  {
    name: 'Jersey',
    alpha2: 'JE',
    code: '832'
  },
  {
    name: 'Jordan',
    alpha2: 'JO',
    code: '400'
  },
  {
    name: 'Kazakhstan',
    alpha2: 'KZ',
    code: '398'
  },
  {
    name: 'Kenya',
    alpha2: 'KE',
    code: '404'
  },
  {
    name: 'Kiribati',
    alpha2: 'KI',
    code: '296'
  },
  {
    name: 'Korea (Democratic People\'s Republic of)',
    alpha2: 'KP',
    code: '408'
  },
  {
    name: 'Korea, Republic of',
    alpha2: 'KR',
    code: '410'
  },
  {
    name: 'Kuwait',
    alpha2: 'KW',
    code: '414'
  },
  {
    name: 'Kyrgyzstan',
    alpha2: 'KG',
    code: '417'
  },
  {
    name: 'Lao People\'s Democratic Republic',
    alpha2: 'LA',
    code: '418'
  },
  {
    name: 'Latvia',
    alpha2: 'LV',
    code: '428'
  },
  {
    name: 'Lebanon',
    alpha2: 'LB',
    code: '422'
  },
  {
    name: 'Lesotho',
    alpha2: 'LS',
    code: '426'
  },
  {
    name: 'Liberia',
    alpha2: 'LR',
    code: '430'
  },
  {
    name: 'Libya',
    alpha2: 'LY',
    code: '434'
  },
  {
    name: 'Liechtenstein',
    alpha2: 'LI',
    code: '438'
  },
  {
    name: 'Lithuania',
    alpha2: 'LT',
    code: '440'
  },
  {
    name: 'Luxembourg',
    alpha2: 'LU',
    code: '442'
  },
  {
    name: 'Macao',
    alpha2: 'MO',
    code: '446'
  },
  {
    name: 'Madagascar',
    alpha2: 'MG',
    code: '450'
  },
  {
    name: 'Malawi',
    alpha2: 'MW',
    code: '454'
  },
  {
    name: 'Malaysia',
    alpha2: 'MY',
    code: '458'
  },
  {
    name: 'Maldives',
    alpha2: 'MV',
    code: '462'
  },
  {
    name: 'Mali',
    alpha2: 'ML',
    code: '466'
  },
  {
    name: 'Malta',
    alpha2: 'MT',
    code: '470'
  },
  {
    name: 'Marshall Islands',
    alpha2: 'MH',
    code: '584'
  },
  {
    name: 'Martinique',
    alpha2: 'MQ',
    code: '474'
  },
  {
    name: 'Mauritania',
    alpha2: 'MR',
    code: '478'
  },
  {
    name: 'Mauritius',
    alpha2: 'MU',
    code: '480'
  },
  {
    name: 'Mayotte',
    alpha2: 'YT',
    code: '175'
  },
  {
    name: 'Mexico',
    alpha2: 'MX',
    code: '484'
  },
  {
    name: 'Micronesia (Federated States of)',
    alpha2: 'FM',
    code: '583'
  },
  {
    name: 'Moldova, Republic of',
    alpha2: 'MD',
    code: '498'
  },
  {
    name: 'Monaco',
    alpha2: 'MC',
    code: '492'
  },
  {
    name: 'Mongolia',
    alpha2: 'MN',
    code: '496'
  },
  {
    name: 'Montenegro',
    alpha2: 'ME',
    code: '499'
  },
  {
    name: 'Montserrat',
    alpha2: 'MS',
    code: '500'
  },
  {
    name: 'Morocco',
    alpha2: 'MA',
    code: '504'
  },
  {
    name: 'Mozambique',
    alpha2: 'MZ',
    code: '508'
  },
  {
    name: 'Myanmar',
    alpha2: 'MM',
    code: '104'
  },
  {
    name: 'Namibia',
    alpha2: 'NA',
    code: '516'
  },
  {
    name: 'Nauru',
    alpha2: 'NR',
    code: '520'
  },
  {
    name: 'Nepal',
    alpha2: 'NP',
    code: '524'
  },
  {
    name: 'Netherlands',
    alpha2: 'NL',
    code: '528'
  },
  {
    name: 'New Caledonia',
    alpha2: 'NC',
    code: '540'
  },
  {
    name: 'New Zealand',
    alpha2: 'NZ',
    code: '554'
  },
  {
    name: 'Nicaragua',
    alpha2: 'NI',
    code: '558'
  },
  {
    name: 'Niger',
    alpha2: 'NE',
    code: '562'
  },
  {
    name: 'Nigeria',
    alpha2: 'NG',
    code: '566'
  },
  {
    name: 'Niue',
    alpha2: 'NU',
    code: '570'
  },
  {
    name: 'Norfolk Island',
    alpha2: 'NF',
    code: '574'
  },
  {
    name: 'North Macedonia',
    alpha2: 'MK',
    code: '807'
  },
  {
    name: 'Northern Mariana Islands',
    alpha2: 'MP',
    code: '580'
  },
  {
    name: 'Norway',
    alpha2: 'NO',
    code: '578'
  },
  {
    name: 'Oman',
    alpha2: 'OM',
    code: '512'
  },
  {
    name: 'Pakistan',
    alpha2: 'PK',
    code: '586'
  },
  {
    name: 'Palau',
    alpha2: 'PW',
    code: '585'
  },
  {
    name: 'Palestine, State of',
    alpha2: 'PS',
    code: '275'
  },
  {
    name: 'Panama',
    alpha2: 'PA',
    code: '591'
  },
  {
    name: 'Papua New Guinea',
    alpha2: 'PG',
    code: '598'
  },
  {
    name: 'Paraguay',
    alpha2: 'PY',
    code: '600'
  },
  {
    name: 'Peru',
    alpha2: 'PE',
    code: '604'
  },
  {
    name: 'Philippines',
    alpha2: 'PH',
    code: '608'
  },
  {
    name: 'Pitcairn',
    alpha2: 'PN',
    code: '612'
  },
  {
    name: 'Poland',
    alpha2: 'PL',
    code: '616'
  },
  {
    name: 'Portugal',
    alpha2: 'PT',
    code: '620'
  },
  {
    name: 'Puerto Rico',
    alpha2: 'PR',
    code: '630'
  },
  {
    name: 'Qatar',
    alpha2: 'QA',
    code: '634'
  },
  {
    name: 'Réunion',
    alpha2: 'RE',
    code: '638'
  },
  {
    name: 'Romania',
    alpha2: 'RO',
    code: '642'
  },
  {
    name: 'Russian Federation',
    alpha2: 'RU',
    code: '643'
  },
  {
    name: 'Rwanda',
    alpha2: 'RW',
    code: '646'
  },
  {
    name: 'Saint Barthélemy',
    alpha2: 'BL',
    code: '652'
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha2: 'SH',
    code: '654'
  },
  {
    name: 'Saint Kitts and Nevis',
    alpha2: 'KN',
    code: '659'
  },
  {
    name: 'Saint Lucia',
    alpha2: 'LC',
    code: '662'
  },
  {
    name: 'Saint Martin (French part)',
    alpha2: 'MF',
    code: '663'
  },
  {
    name: 'Saint Pierre and Miquelon',
    alpha2: 'PM',
    code: '666'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    alpha2: 'VC',
    code: '670'
  },
  {
    name: 'Samoa',
    alpha2: 'WS',
    code: '882'
  },
  {
    name: 'San Marino',
    alpha2: 'SM',
    code: '674'
  },
  {
    name: 'Sao Tome and Principe',
    alpha2: 'ST',
    code: '678'
  },
  {
    name: 'Saudi Arabia',
    alpha2: 'SA',
    code: '682'
  },
  {
    name: 'Senegal',
    alpha2: 'SN',
    code: '686'
  },
  {
    name: 'Serbia',
    alpha2: 'RS',
    code: '688'
  },
  {
    name: 'Seychelles',
    alpha2: 'SC',
    code: '690'
  },
  {
    name: 'Sierra Leone',
    alpha2: 'SL',
    code: '694'
  },
  {
    name: 'Singapore',
    alpha2: 'SG',
    code: '702'
  },
  {
    name: 'Sint Maarten (Dutch part)',
    alpha2: 'SX',
    code: '534'
  },
  {
    name: 'Slovakia',
    alpha2: 'SK',
    code: '703'
  },
  {
    name: 'Slovenia',
    alpha2: 'SI',
    code: '705'
  },
  {
    name: 'Solomon Islands',
    alpha2: 'SB',
    code: '090'
  },
  {
    name: 'Somalia',
    alpha2: 'SO',
    code: '706'
  },
  {
    name: 'South Africa',
    alpha2: 'ZA',
    code: '710'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    alpha2: 'GS',
    code: '239'
  },
  {
    name: 'South Sudan',
    alpha2: 'SS',
    code: '728'
  },
  {
    name: 'Spain',
    alpha2: 'ES',
    code: '724'
  },
  {
    name: 'Sri Lanka',
    alpha2: 'LK',
    code: '144'
  },
  {
    name: 'Sudan',
    alpha2: 'SD',
    code: '729'
  },
  {
    name: 'Suriname',
    alpha2: 'SR',
    code: '740'
  },
  {
    name: 'Svalbard and Jan Mayen',
    alpha2: 'SJ',
    code: '744'
  },
  {
    name: 'Sweden',
    alpha2: 'SE',
    code: '752'
  },
  {
    name: 'Switzerland',
    alpha2: 'CH',
    code: '756'
  },
  {
    name: 'Syrian Arab Republic',
    alpha2: 'SY',
    code: '760'
  },
  {
    name: 'Taiwan, Province of China',
    alpha2: 'TW',
    code: '158'
  },
  {
    name: 'Tajikistan',
    alpha2: 'TJ',
    code: '762'
  },
  {
    name: 'Tanzania, United Republic of',
    alpha2: 'TZ',
    code: '834'
  },
  {
    name: 'Thailand',
    alpha2: 'TH',
    code: '764'
  },
  {
    name: 'Timor-Leste',
    alpha2: 'TL',
    code: '626'
  },
  {
    name: 'Togo',
    alpha2: 'TG',
    code: '768'
  },
  {
    name: 'Tokelau',
    alpha2: 'TK',
    code: '772'
  },
  {
    name: 'Tonga',
    alpha2: 'TO',
    code: '776'
  },
  {
    name: 'Trinidad and Tobago',
    alpha2: 'TT',
    code: '780'
  },
  {
    name: 'Tunisia',
    alpha2: 'TN',
    code: '788'
  },
  {
    name: 'Turkey',
    alpha2: 'TR',
    code: '792'
  },
  {
    name: 'Turkmenistan',
    alpha2: 'TM',
    code: '795'
  },
  {
    name: 'Turks and Caicos Islands',
    alpha2: 'TC',
    code: '796'
  },
  {
    name: 'Tuvalu',
    alpha2: 'TV',
    code: '798'
  },
  {
    name: 'Uganda',
    alpha2: 'UG',
    code: '800'
  },
  {
    name: 'Ukraine',
    alpha2: 'UA',
    code: '804'
  },
  {
    name: 'United Arab Emirates',
    alpha2: 'AE',
    code: '784'
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    alpha2: 'GB',
    code: '826'
  },
  {
    name: 'United States of America',
    alpha2: 'US',
    code: '840'
  },
  {
    name: 'United States Minor Outlying Islands',
    alpha2: 'UM',
    code: '581'
  },
  {
    name: 'Uruguay',
    alpha2: 'UY',
    code: '858'
  },
  {
    name: 'Uzbekistan',
    alpha2: 'UZ',
    code: '860'
  },
  {
    name: 'Vanuatu',
    alpha2: 'VU',
    code: '548'
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    alpha2: 'VE',
    code: '862'
  },
  {
    name: 'Viet Nam',
    alpha2: 'VN',
    code: '704'
  },
  {
    name: 'Virgin Islands (British)',
    alpha2: 'VG',
    code: '092'
  },
  {
    name: 'Virgin Islands (U.S.)',
    alpha2: 'VI',
    code: '850'
  },
  {
    name: 'Wallis and Futuna',
    alpha2: 'WF',
    code: '876'
  },
  {
    name: 'Western Sahara',
    alpha2: 'EH',
    code: '732'
  },
  {
    name: 'Yemen',
    alpha2: 'YE',
    code: '887'
  },
  {
    name: 'Zambia',
    alpha2: 'ZM',
    code: '894'
  },
  {
    name: 'Zimbabwe',
    alpha2: 'ZW',
    code: '716'
  }
];
