<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 col-lg-4 mb-5">
        <div class="d-flex justify-content-center mb-4">
          <img
            style="max-height: 160px;"
            class="mx-auto img-fluid"
            src="/assets/images/logos/main-logo.png"
            alt="Mbanq"
            srcset="/assets/images/logos/main-logo@2x.png 2x, /assets/images/logos/main-logo@3x.png 3x"
          >
        </div>

        <page-title v-if="title">
          {{ title }}
        </page-title>
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
import PageTitle from '@/components/shared/PageTitle.vue';

defineProps({
  title: {
    type: String,
    default: ''
  }
});
</script>
