// (function (module) {
//   mbanq.directives = _.extend(module, {
//     BigPanelDirective () {
//       return {
//         restrict: 'E',
//         transclude: true,
//         scope: {
//           title: '@'
//         },

//         template: '<div class=\'panelbig\'>' +
//                     '<div class=\'panel-header\'>' +
//                     '<div class=\'alert-box panel-header-text\'>{{title}}</div></div>' +
//                     '<div ng-transclude></div></div>'
//       }

//     }
//   })
// }(mbanq.directives || {}))

// mbanq.ng.application.directive('panelbig', [mbanq.directives.BigPanelDirective]).run(function ($log) {
//   $log.info('BigPanelDirective initialized')
// })

export function BigPanelDirective () {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      title: '@'
    },

    template: '<div class=\'panelbig\'>' +
                    '<div class=\'panel-header\'>' +
                    '<div class=\'alert-box panel-header-text\'>{{title}}</div></div>' +
                    '<div ng-transclude></div></div>'
  };
}
