export function ViewWatchmanGroupController (scope, routeParams, resourceFactory, location) {
  const ruleId = routeParams.id;

  scope.loadRuleGroup = function () {
    resourceFactory.amlWatchmansResource.get({ ruleId }, function (res) {
      resourceFactory.amlWatchmansResource.get({ ruleId, action: 'limitrule' }, function (resLimitRule) {
        scope.ruleGroup = res;
        scope.ruleGroup.watchmanRules = resLimitRule.pageItems;
      }, handleResponseError);
    }, handleResponseError);
  };

  scope.loadRuleGroup();

  // Actions on Rule Group
  scope.routeToEditRuleGroup = function (id) {
    location.path(`/payments/aml/editwatchmanrulegroup/${id}`);
  };

  // Actions on Rules
  scope.routeToViewLimitRule = function (id) {
    location.path(`/payments/aml/rulegroups/${scope.ruleGroup.id}/watchmanrule/${id}`);
  };

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }
}

ViewWatchmanGroupController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
