<template>
  <main-content-layout>
    <template #title>
      {{ $t(`label.heading.savingAccounts.${$route.params.action}`) }}
    </template>
    <component :is="currentActionPage" />
  </main-content-layout>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';

export default {
  name: 'SavingAccountsActionsPage',
  components: {
    MainContentLayout,
    EditNicknameActions: defineAsyncComponent(() => import('@/components/clients/saving-accounts/actions/EditNickname.vue')),
    ApproveActions: defineAsyncComponent(() => import('@/components/clients/saving-accounts/actions/Approve.vue')),
    ActivateActions: defineAsyncComponent(() => import('@/components/clients/saving-accounts/actions/Activate.vue')),
    AssignOrUnassignStaff: defineAsyncComponent(() => import('@/components/clients/saving-accounts/actions/AssignOrUnassignStaff.vue')),
    CloseActions: defineAsyncComponent(() => import('@/components/clients/saving-accounts/actions/Close.vue')),
    EditOverdraftLimit: defineAsyncComponent(() => import('@/components/clients/saving-accounts/actions/EditOverdraftLimit.vue')),
    AddChargeActions: defineAsyncComponent(() => import('@/components/clients/saving-accounts/actions/AddCharge.vue')),
    DepositOrWithdrawal: defineAsyncComponent(() => import('@/components/clients/saving-accounts/actions/DepositOrWithDrawal.vue')),
    PostInterestAsOn: defineAsyncComponent(() => import('@/components/clients/saving-accounts/actions/PostInterestAsOn.vue')),
    EditSavingAccountNumber: defineAsyncComponent(() => import('@/components/clients/saving-accounts/actions/EditAccountNumber.vue'))
  },
  computed: {
    currentActionPage () {
      switch (this.$route.params.action) {
        case 'edit-nickname':
          return 'EditNicknameActions';
        case 'approve':
          return 'ApproveActions';
        case 'activate':
          return 'ActivateActions';
        case 'assign-staff':
          return 'AssignOrUnassignStaff';
        case 'unassign-staff':
          return 'AssignOrUnassignStaff';
        case 'close':
          return 'CloseActions';
        case 'edit-overdraft-limit':
          return 'EditOverdraftLimit';
        case 'add-charge':
          return 'AddChargeActions';
        case 'withdrawal':
          return 'DepositOrWithdrawal';
        case 'deposit':
          return 'DepositOrWithdrawal';
        case 'post-interest-on':
          return 'PostInterestAsOn';
        case 'edit-account-number':
          return 'EditSavingAccountNumber';
      }
      return location.replace('#/404');
    }
  }
};

</script>
