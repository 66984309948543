<template>
  <form-wrapper @submit="submit">
    <div class="col-5 mt-4">
      <input-amount
        v-model="formData.transactionAmount"
        required
        disabled
        :label="$t('label.input.transactionAmount')"
      />
      <input-text
        v-model="formData.reference"
        :label="$t('label.input.reference')"
      />
      <div class="mt-3 d-flex justify-content-end">
        <button
          class="btn btn-default me-3"
          @click.prevent="cancel"
        >
          {{ $t('label.button.cancel') }}
        </button>
        <button
          id="save"
          class="btn btn-primary"
          type="submit"
        >
          {{ $t('label.button.save') }}
        </button>
      </div>
    </div>
  </form-wrapper>
</template>

<script setup>
import { InputAmount, InputText, FormWrapper } from '@/components/shared/form-input/index';
import { $notify } from '@/plugins/prototypes';
import { reactive, onMounted } from 'vue';
import router from '@/router';
import apiService from '@/services/apiService';

const { creditAccountId, transactionId } = router.currentRoute.params;
const formData = reactive({
  transactionAmount: 0,
  reference: ''
});

onMounted(async () => {
  try {
    const { data } = await apiService.creditAccountResource.getTransactionDetails(creditAccountId, transactionId);
    formData.transactionAmount = data.amount;
  } catch (error) {
    $notify.error(error);
  }
});

const cancel = () => location.assign(`#/viewcreditaccount/${creditAccountId}`);
const submit = async () => {
  try {
    await apiService.creditAccountResource.actions(creditAccountId, transactionId, formData, { command: 'reverseCharge' });
    cancel();
  } catch (error) {
    $notify.error(error);
  }
};
</script>
