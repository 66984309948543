import { yearDiff } from '../../../../helper/dateHelper';

export function AddFamilyMembersController ($scope, resourceFactory, routeParams, dateFilter, location) {
  const clientId = routeParams.clientId;

  $scope.formData = {};
  $scope.date = {};
  $scope.restrictDate = new Date();
  $scope.minRestricDate = yearDiff(100);

  resourceFactory.familyMemberTemplate.get({ clientId }, function (data) {
    $scope.relationshipIdOptions = data.relationshipIdOptions;
    $scope.genderIdOptions = data.genderIdOptions;
    $scope.maritalStatusIdOptions = data.maritalStatusIdOptions;
    $scope.professionIdOptions = data.professionIdOptions;
  });

  $scope.routeTo = function () {
    location.path(`/viewclient/${clientId}`);
  };

  $scope.addClientMember = function () {
    this.formData.locale = $scope.optlang.code;
    this.formData.dateFormat = $scope.df;

    if ($scope.date.dateOfBirth) {
      this.formData.dateOfBirth = dateFilter($scope.date.dateOfBirth, $scope.df);
    }
    resourceFactory.familyMembers.post({ clientId }, $scope.formData, function () {
      location.path(`/viewclient/${clientId}`);
    });
  };
}

AddFamilyMembersController.$inject = ['$scope', 'ResourceFactory', '$routeParams', 'dateFilter', '$location'];
