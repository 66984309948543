export function AddLoanCollateralController ($scope, resourceFactory, routeParams, location) {
  $scope.collateralTypes = [];
  $scope.formData = {};
  $scope.loanId = routeParams.id;
  resourceFactory.loanCollateralTemplateResource.get({ loanId: $scope.loanId }, function (data) {
    $scope.collateralTypes = data.allowedCollateralTypes;
    $scope.formData.collateralTypeId = data.allowedCollateralTypes[0].id;
  });

  $scope.cancel = function () {
    location.path(`/viewloanaccount/${$scope.loanId}`);
  };

  $scope.submit = function () {
    this.formData.locale = $scope.optlang.code;
    resourceFactory.loanResource.save({ resourceType: 'collaterals', loanId: $scope.loanId }, this.formData, function (data) {
      location.path(`/loan/${data.loanId}/viewcollateral/${data.resourceId}`);
    });
  };
}

AddLoanCollateralController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location'];
