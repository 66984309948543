export function UIConfigService ($q, $http, $templateCache) {
  this.appendConfigToScope = function (scope) {
    const jsonData = $templateCache.get('configJsonObj');
    if (jsonData !== null && jsonData !== '') {
      jsonData.then(function (data) {
        if (data !== '' && data !== null && data !== 'undefined') {
          if (data.enableUIDisplayConfiguration !== null && data.enableUIDisplayConfiguration === true) {
            scope.response = data;
          }
        }
      });
    }
  };

  this.init = function (scope) {
    const deferred = $q.defer();
    $http({
      method: 'get',
      url: 'scripts/config/UIconfig.json'
    }).then(function (data) {
      scope.$emit('configJsonObj', data);
      deferred.resolve(data);
      $templateCache.put('configJsonObj', deferred.promise);
    }, function (error) {
      deferred.reject(error);
      console.error('Configuration file not found');
    });
  };
}

UIConfigService.$inject = ['$q', '$http', '$templateCache'];
