export function AdjustRepaymentSchedule (scope, routeParams, resourceFactory, location, $uibModal, dateFilter) {
  scope.formData = {};
  scope.enablePrincipal = false;
  scope.enableInstallment = false;
  scope.newinstallments = [];
  scope.deletedinstallments = [];
  scope.modifiedinstallments = [];
  scope.days = [];
  scope.months = [];
  let i = 0;
  // load days in this days array
  for (i = 0; i < 31; i++) {
    scope.days.push(i + 1);
  }
  // load months in this months array
  for (i = 0; i < 12; i++) {
    scope.months.push(i + 1);
  }
  loadData();

  function loadData () {
    scope.newinstallments.splice(0, scope.newinstallments.length);
    scope.deletedinstallments.splice(0, scope.deletedinstallments.length);
    scope.modifiedinstallments.splice(0, scope.modifiedinstallments.length);
    resourceFactory.LoanAccountResource.getLoanAccountDetails({ loanId: routeParams.accountId, associations: 'repaymentSchedule', exclude: 'guarantors' }, function (data) {
      scope.loandetails = data;
      scope.decimals = data.currency.decimalPlaces;

      let i = 0;
      while (i < scope.loandetails.repaymentSchedule.periods.length) {
        if (scope.loandetails.repaymentSchedule.periods[i].period) {
          const date = new Date(scope.loandetails.repaymentSchedule.periods[i].dueDate);
          scope.loandetails.repaymentSchedule.periods[i].dueDate = date;
          scope.loandetails.repaymentSchedule.periods[i].originalDueDate = scope.loandetails.repaymentSchedule.periods[i].dueDate;
          scope.loandetails.repaymentSchedule.periods[i].oroginalPrincipalDue = scope.loandetails.repaymentSchedule.periods[i].principalDue;
          scope.loandetails.repaymentSchedule.periods[i].originaltotalInstallmentAmountForPeriod = scope.loandetails.repaymentSchedule.periods[i].totalInstallmentAmountForPeriod;
          scope.loandetails.repaymentSchedule.periods[i].formattedDueDate = dateFilter(scope.loandetails.repaymentSchedule.periods[i].dueDate, scope.df);
          i++;
        } else {
          scope.loandetails.repaymentSchedule.periods.splice(i, 1);
        }
      }
      if (scope.loandetails.interestType.code === 'interestType.flat') {
        scope.enablePrincipal = true;
        scope.enableInstallment = false;
      } else if (scope.loandetails.interestType.code === 'interestType.declining.balance') {
        if (scope.loandetails.amortizationType.code === 'amortizationType.equal.principal') {
          scope.enablePrincipal = true;
        } else if (scope.loandetails.amortizationType.code === 'amortizationType.equal.installments') {
          scope.enableInstallment = true;
        }
      } else { // invalid case. don't allow anything to be modified
        scope.enablePrincipal = false;
        scope.enableInstallment = false;
      }
    });
  }

  scope.pattern = function () {
    $uibModal.open({
      templateUrl: 'pattern.html',
      controller: patternController,
      resolve: {
        periods () {
          return scope.loandetails.repaymentSchedule.periods;
        },
        enablePrincipal () {
          return scope.enablePrincipal;
        },
        enableInstallment () {
          return scope.enableInstallment;
        },
        months () {
          return scope.months;
        },
        days () {
          return scope.days;
        }

      }
    });
  };

  const patternController = function ($scope, $uibModalInstance, periods, enablePrincipal, enableInstallment, months, days) {
    $scope.periods = periods;
    $scope.enablePrincipal = enablePrincipal;
    $scope.enableInstallment = enableInstallment;
    $scope.months = months;
    $scope.days = days;
    $scope.adjustmentData = {};
    $scope.invaliddaterangeerror = false;
    $scope.submit = function () {
      const t1 = $scope.adjustmentData.fromDate.getTime();
      let t2 = $scope.adjustmentData.fromDate.getTime();
      if ($scope.adjustmentData.toDate) {
        t2 = $scope.adjustmentData.toDate.getTime();
        if (t1 > t2) {
          $scope.invaliddaterangeerror = true;
          return;
        }
      }
      $scope.invaliddaterangeerror = false;
      $uibModalInstance.close($scope.adjustmentData);
      for (const i in $scope.loandetails.repaymentSchedule.periods) {
        if ($scope.loandetails.repaymentSchedule.periods[i].dueDate.getTime() >= t1 &&
          $scope.loandetails.repaymentSchedule.periods[i].dueDate.getTime() <= t2) {
          if ($scope.enableInstallment && $scope.adjustmentData.totalInstallmentAmountForPeriod) {
            $scope.loandetails.repaymentSchedule.periods[i].totalInstallmentAmountForPeriod = $scope.adjustmentData.totalInstallmentAmountForPeriod;
          } else if ($scope.enablePrincipal && $scope.adjustmentData.principalDue) {
            $scope.loandetails.repaymentSchedule.periods[i].principalDue = $scope.adjustmentData.principalDue;
          }
          if ($scope.adjustmentData.month || $scope.adjustmentData.day) {
            const tempDate = new Date($scope.loandetails.repaymentSchedule.periods[i].dueDate);
            if ($scope.adjustmentData.month) {
              tempDate.setMonth(parseInt($scope.adjustmentData.month, 10) + $scope.loandetails.repaymentSchedule.periods[i].dueDate.getMonth());
            }
            if ($scope.adjustmentData.day) {
              const uiday = parseInt($scope.adjustmentData.day, 10);
              const day = numberOfDays(tempDate.getFullYear(), tempDate.getMonth() + 1, uiday);
              tempDate.setDate(day);
            }
            $scope.loandetails.repaymentSchedule.periods[i].dueDate = new Date(tempDate);
            $scope.loandetails.repaymentSchedule.periods[i].formattedDueDate = dateFilter($scope.loandetails.repaymentSchedule.periods[i].dueDate, $scope.df);
          }
        }
      }
    };

    function numberOfDays (year, month, day) {
      const d = new Date(year, month, 0);
      if (d.getDate() < day) {
        return d.getDate();
      }
      return day;
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.addInstallment = function (index) {
    const installment = {};
    scope.loandetails.repaymentSchedule.periods.splice(index + 1, 0, installment);
    scope.newinstallments.push(installment);
  };

  scope.deleteInstallment = function (index) {
    if (scope.loandetails.repaymentSchedule.periods[index].period) {
      const deleted = {};
      deleted.dueDate = dateFilter(scope.loandetails.repaymentSchedule.periods[index].dueDate, scope.df);
      scope.deletedinstallments.push(deleted);
    } else {
      let i = 0;
      const length = scope.newinstallments.length;
      for (i = 0; i < length; i++) {
        if (scope.newinstallments[i] === scope.loandetails.repaymentSchedule.periods[index]) {
          scope.newinstallments.splice(i, 1);
          break;
        }
      }
    }
    scope.loandetails.repaymentSchedule.periods.splice(index, 1);
  };

  function getNewInstallments () {
    const tempArray = [];
    for (const i in scope.newinstallments) {
      tempArray.push({});
      tempArray[i].dueDate = dateFilter(scope.newinstallments[i].dueDate, scope.df);
      if (scope.enablePrincipal) {
        tempArray[i].principal = scope.newinstallments[i].principalDue;
      }
      if (scope.enableInstallment) {
        tempArray[i].installmentAmount = scope.newinstallments[i].totalInstallmentAmountForPeriod;
      }
    }
    return tempArray;
  }

  function constructRequestData () {
    const exceptions = {};
    const modified = getUpdatedInstallments();

    if (modified.length > 0) {
      exceptions.modifiedinstallments = modified;
    }
    const newinstalls = getNewInstallments();
    if (newinstalls.length > 0) {
      exceptions.newinstallments = newinstalls;
    }
    if (scope.deletedinstallments.length > 0) {
      exceptions.deletedinstallments = scope.deletedinstallments;
    }
    return exceptions;
  }

  function getUpdatedInstallments () {
    const modified = [];
    let modifiedIndex = 0;
    scope.modifiedinstallments.splice(0, scope.modifiedinstallments.length);
    for (const i in scope.loandetails.repaymentSchedule.periods) {
      if (scope.loandetails.repaymentSchedule.periods[i].period) {
        const dateModified = scope.loandetails.repaymentSchedule.periods[i].originalDueDate.getTime() !== scope.loandetails.repaymentSchedule.periods[i].dueDate.getTime();

        if (!scope.loandetails.repaymentSchedule.periods[i].principalDue) {
          scope.loandetails.repaymentSchedule.periods[i].principalDue = 0;
        }
        const principalModified = scope.loandetails.repaymentSchedule.periods[i].oroginalPrincipalDue !== scope.loandetails.repaymentSchedule.periods[i].principalDue;

        if (!scope.loandetails.repaymentSchedule.periods[i].totalInstallmentAmountForPeriod) {
          scope.loandetails.repaymentSchedule.periods[i].totalInstallmentAmountForPeriod = 0;
        }
        const installmentModified = scope.loandetails.repaymentSchedule.periods[i].originaltotalInstallmentAmountForPeriod !== scope.loandetails.repaymentSchedule.periods[i].totalInstallmentAmountForPeriod;

        if (dateModified || principalModified || installmentModified) {
          const modifiedInstallment = {};
          scope.modifiedinstallments.push({});
          modifiedInstallment.dueDate = dateFilter(scope.loandetails.repaymentSchedule.periods[i].originalDueDate, scope.df);
          scope.modifiedinstallments[modifiedIndex].dueDate = scope.loandetails.repaymentSchedule.periods[i].originalDueDate;

          if (dateModified) {
            modifiedInstallment.modifiedDueDate = dateFilter(scope.loandetails.repaymentSchedule.periods[i].dueDate, scope.df);
            scope.modifiedinstallments[modifiedIndex].modifiedDueDate = scope.loandetails.repaymentSchedule.periods[i].dueDate;
          }
          if (scope.enablePrincipal && principalModified) {
            modifiedInstallment.principal = scope.loandetails.repaymentSchedule.periods[i].principalDue;
            scope.modifiedinstallments[modifiedIndex].oroginalPrincipalDue = scope.loandetails.repaymentSchedule.periods[i].oroginalPrincipalDue;
            scope.modifiedinstallments[modifiedIndex].principal = scope.loandetails.repaymentSchedule.periods[i].principalDue;
          }
          if (scope.enableInstallment && installmentModified) {
            modifiedInstallment.installmentAmount = scope.loandetails.repaymentSchedule.periods[i].totalInstallmentAmountForPeriod;
            scope.modifiedinstallments[modifiedIndex].originaltotalInstallmentAmountForPeriod = scope.loandetails.repaymentSchedule.periods[i].originaltotalInstallmentAmountForPeriod;
            scope.modifiedinstallments[modifiedIndex].installmentAmount = scope.loandetails.repaymentSchedule.periods[i].totalInstallmentAmountForPeriod;
          }
          modified.push(modifiedInstallment);
          modifiedIndex++;
        }
      }
    }
    return modified;
  }

  scope.resetToOriginalSchedule = function () {
    $uibModal.open({
      templateUrl: 'resetschedule.html',
      controller: resetToOriginalSchedule
    });
  };

  const resetToOriginalSchedule = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.letiableinstallments.deleteVariations({ loanId: routeParams.accountId }, function () {
        $uibModalInstance.close('Close');
        loadData();
      });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  function validateBeforesendingdata () {
    let emptyData = false;
    for (const i in scope.loandetails.repaymentSchedule.periods) {
      if (!scope.loandetails.repaymentSchedule.periods[i].dueDate) {
        emptyData = true;
        break;
      }
    }
    return emptyData;
  }
  scope.validate = function () {
    if (validateBeforesendingdata()) return true;
    scope.formData.exceptions = constructRequestData();
    if (!scope.formData.exceptions.modifiedinstallments &&
      !scope.formData.exceptions.newinstallments &&
      !scope.formData.exceptions.deletedinstallments) {
      return;
    }
    this.formData.locale = scope.optlang.code;
    this.formData.dateFormat = scope.df;
    resourceFactory.letiableinstallments.validate({ loanId: routeParams.accountId }, scope.formData, function (data) {
      const validRepaymentSchedule = data;
      for (const i in validRepaymentSchedule.periods) {
        let index = -1;
        const date = new Date(validRepaymentSchedule.periods[i].dueDate);
        for (const j in scope.modifiedinstallments) {
          if ((scope.modifiedinstallments[j].dueDate &&
            scope.modifiedinstallments[j].dueDate.getTime() === date.getTime())) {
            index = j;
            break;
          }
          if (scope.modifiedinstallments[j].modifiedDueDate &&
            scope.modifiedinstallments[j].modifiedDueDate.getTime() === date.getTime()) {
            index = j;
            break;
          }
        }
        validRepaymentSchedule.periods[i].dueDate = date;

        if (index > -1) {
          if (scope.modifiedinstallments[index].modifiedDueDate) {
            validRepaymentSchedule.periods[i].originalDueDate = scope.modifiedinstallments[index].dueDate;
          } else {
            validRepaymentSchedule.periods[i].originalDueDate = validRepaymentSchedule.periods[i].dueDate;
          }

          if (scope.modifiedinstallments[index].principal) {
            validRepaymentSchedule.periods[i].oroginalPrincipalDue = scope.modifiedinstallments[index].oroginalPrincipalDue;
          } else {
            validRepaymentSchedule.periods[i].oroginalPrincipalDue = validRepaymentSchedule.periods[i].principalDue;
          }

          if (scope.modifiedinstallments[index].installmentAmount) {
            validRepaymentSchedule.periods[i].originaltotalInstallmentAmountForPeriod = scope.modifiedinstallments[index].originaltotalInstallmentAmountForPeriod;
          } else {
            validRepaymentSchedule.periods[i].originaltotalInstallmentAmountForPeriod = validRepaymentSchedule.periods[i].totalInstallmentAmountForPeriod;
          }
          validRepaymentSchedule.periods[i].formattedDueDate = dateFilter(validRepaymentSchedule.periods[i].dueDate, scope.df);
        } else {
          validRepaymentSchedule.periods[i].originalDueDate = validRepaymentSchedule.periods[i].dueDate;
          validRepaymentSchedule.periods[i].oroginalPrincipalDue = validRepaymentSchedule.periods[i].principalDue;
          validRepaymentSchedule.periods[i].originaltotalInstallmentAmountForPeriod = validRepaymentSchedule.periods[i].totalInstallmentAmountForPeriod;
          validRepaymentSchedule.periods[i].formattedDueDate = dateFilter(validRepaymentSchedule.periods[i].dueDate, scope.df);
        }
      }
      scope.loandetails.repaymentSchedule = data;
    });
  };

  scope.submit = function () {
    this.formData.locale = scope.optlang.code;
    this.formData.dateFormat = scope.df;
    scope.formData.exceptions = constructRequestData();
    resourceFactory.letiableinstallments.addVariations({ loanId: routeParams.accountId }, scope.formData, function (data) {
      location.path(`/viewloanaccount/${data.loanId}`);
    });
  };
}

AdjustRepaymentSchedule.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location', '$uibModal', 'dateFilter'];
