export function MerchantChargeController (scope, resourceFactory, location) {
  scope.routeTo = function (id) {
    location.path(`/viewmerchantcharge/${id}`);
  };

  if (!scope.searchCriteria.mcccharges) {
    scope.searchCriteria.mcccharges = null;
    scope.saveSC();
  }
  scope.filterText = scope.searchCriteria.mcccharges || '';

  scope.onFilter = function () {
    scope.searchCriteria.mcccharges = scope.filterText;
    scope.saveSC();
  };

  scope.DataTablesPerPage = 15;
  resourceFactory.mccChargeResource.query(function (data) {
    scope.mcccharges = data;
  });
}

MerchantChargeController.$inject = ['$scope', 'ResourceFactory', '$location'];
