export function AccountingRuleController ($scope, resourceFactory, location) {
  $scope.routeTo = function (id) {
    location.path(`/viewaccrule/${id}`);
  };
  resourceFactory.accountingRulesResource.get(function (data) {
    $scope.rules = data;
  });
}

AccountingRuleController.$inject = ['$scope', 'ResourceFactory', '$location'];
