<template>
  <container-login :title="$t('label.heading.passwordResetRequest')">
    <form-wrapper @submit="requestPassword">
      <input-text
        id="reset-password"
        v-model="formData.identifier"
        :label="$t('label.input.username')"
        required
        vertical
      />
      <div class="d-flex w-100 justify-content-between">
        <button
          type="cancel"
          class="btn btn-default"
          @click.prevent="cancel"
        >
          {{ $t('label.button.cancel') }}
        </button>
        <button
          type="submit"
          class="btn btn-primary"
        >
          {{ $t('label.button.requestResetPassword') }}
        </button>
      </div>
    </form-wrapper>
  </container-login>
</template>

<script setup>
import { FormWrapper, InputText } from '@/components/shared/form-input/index';
import { $notify } from '@/plugins/prototypes';
import { reactive, onMounted } from 'vue';
import ContainerLogin from '@/components/login/Container.vue';
import apiService from '@/services/apiService';
import router from '@/router';
import i18n from '@/plugins/i18n';

const formData = reactive({ identifier: null });

onMounted(() => {
  if (router.currentRoute.query.isPasswordExpired) {
    $notify.error(i18n.t('messages.error.passwordExpired'));
  }
});

const requestPassword = async () => {
  try {
    await apiService.authentication.requestResetPassword(formData);
    router.push({ name: 'RequestResetPasswordSuccess' });
  } catch (error) {
    $notify.error(error);
  }
};

const cancel = () => router.push('/login');
</script>
