<template>
  <div>
    <table class="table table-bordered view-table">
      <tbody>
        <tr>
          <th
            :colspan="columns"
            class="text-center font-weight-bold"
          >
            <slot name="header" />
          </th>
        </tr>
        <slot name="rows" />
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'DetailTable',
  props: {
    columns: {
      type: Number,
      default: 2
    }
  }
};
</script>
