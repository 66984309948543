export function TransactionClientController (scope, resourceFactory, routeParams, location, dateFilter) {
  scope.formData = {};
  scope.formData.transferDate = new Date();
  scope.clientId = routeParams.id;
  resourceFactory.officeResource.getAllOffices(function (data) {
    scope.offices = data;
    scope.formData.destinationOfficeId = scope.offices[0].id;
  });

  scope.submit = function () {
    this.formData.locale = scope.optlang.code;
    this.formData.dateFormat = scope.df;
    this.formData.transferDate = dateFilter(this.formData.transferDate, scope.df);
    resourceFactory.clientResource.save({ clientId: routeParams.id, command: 'proposeTransfer' }, this.formData, function () {
      location.path(`/viewclient/${routeParams.id}`);
    });
  };
}

TransactionClientController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location', 'dateFilter'];
