export function ViewFloatingRateController ($scope, routeParams, resourceFactory) {
  resourceFactory.floatingrates.get({ floatingRateId: routeParams.floatingRateId }, function (data) {
    $scope.id = data.id;
    $scope.name = data.name;
    $scope.isBaseLendingRate = data.isBaseLendingRate;
    $scope.isActive = data.isActive;
    $scope.createdBy = data.createdBy;
    $scope.ratePeriods = data.ratePeriods;
  });
}

ViewFloatingRateController.$inject = ['$scope', '$routeParams', 'ResourceFactory'];
