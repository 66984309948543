/**
 * @param {string} str
 */
function unescapeBase64 (str) {
  // convert Base64 URL-Safe to Base64 regular
  return (str + '==='.slice((str.length + 3) % 4)).replace(/-/g, '+').replace(/_/g, '/');
}

/**
 * @param {string} str
 */
function escapeBase64 (str) {
  // convert Base64 regular to Base64 URL-Safe
  return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

/**
 * @param {string} keyID
 */
async function importRSAKey (keyID) {
  // fetch the part of the PEM string between header and footer
  const pemContents = unescapeBase64(keyID);
  // base64 decode the string to get the binary data
  const binaryDerString = window.atob(pemContents);
  // convert from a binary string to an ArrayBuffer
  const binaryDer = stringToArrayBuffer(binaryDerString);

  return window.crypto.subtle.importKey('spki', binaryDer, { name: 'RSA-OAEP', hash: 'SHA-256' }, true, ['encrypt']);
}

/**
 * @param {string} str
 */
function stringToArrayBuffer (str) {
  // String to array buffer
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);

  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }

  return buf;
}

/**
 * @param {string} value
 */
function getStringEncoding (value) {
  const enc = new TextEncoder();
  return enc.encode(value);
}

/**
 * @param {ArrayBuffer} buffer
 */
function arrayBufferToBase64 (buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

async function encrypt (keyId, cardInfo) {
//   keyID is ASN.1 Format Base64 URL-Safe
//   const result = ''
  return importRSAKey(keyId).then(RSAKey => {
    const encoded = getStringEncoding(cardInfo);
    return window.crypto.subtle.encrypt({ name: 'RSA-OAEP' }, RSAKey, encoded)
      .then(data => {
        // encrypted data
        const dataEncrypted = arrayBufferToBase64(data);

        // use value return from escapeBase64(dataEncrypted) with Tabapay
        return escapeBase64(dataEncrypted);
      });
  });
}
export {
  encrypt
};
