export function CompositeMobileDirective ($compile) {
  return {
    restrict: 'E',
    require: 'ngModel',
    scope: {
      fullPhone: '=ngModel'
    },
    link (scope, elm, attr, ctrl) {
      scope.valid = false;
      scope.splitPhone = function (val) {
        scope.countryCode = val?.split(' ')[0] || '';
        scope.mainPart = Number(val?.split(' ')[1]) || '';
      };
      scope.splitPhone(scope.fullPhone);
      scope.emitPhone = function (initial = false) {
        scope.fullPhone = (!scope.countryCode && !scope.mainPart) ? '' : `${scope.countryCode || ''} ${this.mainPart || ''}`;
        scope.validate(initial);
      };
      scope.validate = function (initial) {
        scope.subForm.countryCode.$validate();
        scope.subForm.mainPart.$validate();
        if (!initial) {
          scope.subForm.countryCode.$setTouched();
          scope.subForm.mainPart.$setTouched();
        }
        if (scope.countryCode && !scope.mainPart) scope.subForm.mainPart.$setValidity('pattern', false);
        if (scope.mainPart && !scope.countryCode) scope.subForm.countryCode.$setValidity('pattern', false);
        scope.valid = scope.subForm.countryCode.$valid && scope.subForm.mainPart.$valid;
        ctrl.$setValidity('mobile', scope.valid);
      };
      scope.$watch(function () {
        return ctrl.$modelValue;
      }, function (newValue) {
        scope.splitPhone(newValue);
      });
      const template = `
            <div class="row align-items-center" ng-form="subForm">
                <div class="col-sm-6">
                    <div class="row align-items-center">
                        <div class="col-sm-3">
                            <input type="text" 
                                ng-pattern="/(\\+\\d{1,3})$/"
                                style="width: 70px"
                                name="countryCode" 
                                ng-class=""
                                placeholder="+#"
                                ng-model="countryCode" 
                                ng-model-options="{ allowInvalid: true }"
                                ng-keyup="emitPhone()" 
                                class="form-control"/>
                        </div>
                        <div class="col-sm-9">
                            <input type="number" 
                                ng-pattern="/^\\d{8,20}$/"
                                class="form-control"
                                placeholder="#####"
                                name="mainPart" 
                                ng-model="mainPart" 
                                ng-model-options="{ allowInvalid: true }"
                                ng-keyup="emitPhone()"/>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <small class="error" ng-show="!valid">{{ 'validation.msg.mobile.phone.format' | translate }}</small>
                </div>
            </div>
            `;

      elm.html('').append($compile(template)(scope));

      scope.emitPhone(true);
    }
  };
}

CompositeMobileDirective.$inject = ['$compile'];
