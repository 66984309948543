export function ClientDeceasedController (scope, resourceFactory, routeParams, dateFilter, location) {
  const dateFormat = 'yyyy-MM-dd';
  const defaultDate = dateFilter(new Date(), dateFormat);
  scope.clientId = routeParams.clientId;
  scope.isLoading = false;
  scope.showSubmitButton = true;
  scope.formData = {
    insured: {
      selected: false,
      deathIntimationDate: defaultDate,
      deathDate: defaultDate,
      placeOfDeath: ''
    },
    coinsured: {
      selected: false,
      deathIntimationDate: defaultDate,
      deathDate: defaultDate,
      placeOfDeath: ''
    }
  };

  resourceFactory.clientResource.get({ clientId: scope.clientId, fields: 'displayName,accountNo' }, function (resClient) {
    scope.client = resClient;

    resourceFactory.codeResources.getAllCodes({ fields: 'id,name' }, function (resCode) {
      const relationshipCodeId = resCode.find(code => code.name === 'RELATIONSHIP').id;
      const causeOfDeathCodeId = resCode.find(code => code.name === 'Cause Death Type').id;

      resourceFactory.codeValueResource.getAllCodeValues({ codeId: relationshipCodeId, fields: 'id,name' }, function (data) {
        scope.relationshipOptions = data;
      });

      resourceFactory.codeValueResource.getAllCodeValues({ codeId: causeOfDeathCodeId, fields: 'id,name' }, function (data) {
        scope.causeOfDeathOptions = data;
        scope.formData.insured.causeOfDeath = data[0];
        scope.formData.coinsured.causeOfDeath = data[0];
      });

      resourceFactory.clientResource.get({ clientId: scope.clientId, anotherresource: 'deceased' }, function (resClientDeceased) {
        if (resClientDeceased?.value) {
          scope.showSubmitButton = false;

          const { relationshipType, insured: tempInsured, coInsured: tempCoinsured, ...tempData } = resClientDeceased.value;
          scope.formData = {
            ...tempData,
            relationship: relationshipType,
            insured: {
              deathIntimationDate: dateFilter(tempInsured.deathIntimationDate, dateFormat),
              deathDate: dateFilter(tempInsured.deathDate, dateFormat),
              placeOfDeath: tempInsured.placeOfDeath,
              selected: tempInsured.selected,
              causeOfDeath: tempInsured.causeOfDeathType
            },
            coinsured: {
              deathIntimationDate: dateFilter(tempCoinsured.deathIntimationDate, dateFormat),
              deathDate: dateFilter(tempCoinsured.deathDate, dateFormat),
              placeOfDeath: tempCoinsured.placeOfDeath,
              selected: tempCoinsured.selected,
              causeOfDeath: tempCoinsured.causeOfDeathType
            }
          };
        }
      });
    });
  });

  scope.mobileNoRegex = /^\+\d{1,15}$/;
  resourceFactory.configurationByNameResource.get({ name: 'full-mobile-number-regex' }, function (configuration) {
    scope.mobileNoRegex = configuration.enabled ? configuration.value : scope.mobileNoRegex;
  });

  function getSubmitData () {
    const { relationship, insured: tempInsured, coinsured: tempCoinsured, ...tempData } = scope.formData;

    return {
      ...tempData,
      relationshipTypeId: relationship.id,
      insured: {
        deathIntimationDate: dateFilter(tempInsured.deathIntimationDate, dateFormat),
        deathDate: dateFilter(tempInsured.deathDate, dateFormat),
        placeOfDeath: tempInsured.placeOfDeath,
        selected: tempInsured.selected,
        causeOfDeathTypeId: tempInsured?.causeOfDeath?.id
      },
      coInsured: {
        deathIntimationDate: dateFilter(tempCoinsured.deathIntimationDate, dateFormat),
        deathDate: dateFilter(tempCoinsured.deathDate, dateFormat),
        placeOfDeath: tempCoinsured.placeOfDeath,
        selected: tempCoinsured.selected,
        causeOfDeathTypeId: tempCoinsured?.causeOfDeath?.id
      }
    };
  }

  scope.submit = function () {
    scope.isLoading = true;
    const submitData = getSubmitData();

    resourceFactory.clientResource.save({ clientId: scope.clientId, command: 'deceased' }, submitData, function () {
      location.path(`/viewclient/${scope.clientId}`);
    }, function () {
      scope.isLoading = false;
    });
  };

  scope.handleClickReturn = function () {
    location.path(`/viewclient/${scope.clientId}`);
  };
}

ClientDeceasedController.$inject = ['$scope', 'ResourceFactory', '$routeParams', 'dateFilter', '$location'];
