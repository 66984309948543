import Vue from 'vue';
import VueRouter from 'vue-router';
import privateRoutes from '@/router/private/index.js';
import publicRoutes from '@/router/public/index.js';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { useSharedStore } from '@/stores/shared';
import { createPinia, setActivePinia } from 'pinia';

Vue.use(VueRouter);

const tempRoutes = [...privateRoutes, ...publicRoutes];
const router = new VueRouter({
  routes: [
    {
      path: '/',
      redirect: '/home'
    }, ...tempRoutes
  ]
});

const pinia = createPinia();
setActivePinia(pinia);

router.beforeEach((to, _, next) => {
  const isAuth = localStorage.getItem('ls.tokendetails');

  if (to.meta.public && isAuth) next('/home');

  if (!to.meta.public && !isAuth) {
    if (to.path === '/home') return next({ path: '/login' });
    next({ path: '/login', query: { redirect: to.path } });
  }

  next();
});

router.afterEach((to, from) => {
  if (to.path !== from.path) {
    useBreadcrumbsStore().removeBreadcrumbs();
    useSharedStore().resetNotificationMessage();
  }
});

export default router;
