export function ViewOfficeController (scope, routeParams, route, location, resourceFactory) {
  scope.charges = [];

  resourceFactory.officeResource.get({ officeId: routeParams.id }, function (data) {
    scope.office = data;
  });

  resourceFactory.DataTablesResource.getAllDataTables({ apptable: 'm_office' }, function (data) {
    scope.officedatatables = data;
  });
  scope.dataTableChange = function (officedatatable) {
    resourceFactory.DataTablesResource.getTableDetails({
      datatablename: officedatatable.registeredTableName,
      entityId: routeParams.id,
      genericResultSet: 'true'
    }, function (data) {
      scope.datatabledetails = data;
      scope.datatabledetails.isData = data.data.length > 0;
      scope.datatabledetails.isMultirow = data.columnHeaders[0].columnName === 'id';
      scope.showDataTableAddButton = !scope.datatabledetails.isData || scope.datatabledetails.isMultirow;
      scope.showDataTableEditButton = scope.datatabledetails.isData && !scope.datatabledetails.isMultirow;
      scope.singleRow = [];
      for (const i in data.columnHeaders) {
        if (scope.datatabledetails.columnHeaders[i].columnCode) {
          for (const j in scope.datatabledetails.columnHeaders[i].columnValues) {
            for (const k in data.data) {
              if (data.data[k].row[i] === scope.datatabledetails.columnHeaders[i].columnValues[j].id) {
                data.data[k].row[i] = scope.datatabledetails.columnHeaders[i].columnValues[j].value;
              }
            }
          }
        }
      }
      if (scope.datatabledetails.isData) {
        for (const i in data.columnHeaders) {
          if (!scope.datatabledetails.isMultirow) {
            const row = {};
            row.key = data.columnHeaders[i].columnName;
            row.value = data.data[0].row[i];
            scope.singleRow.push(row);
          }
        }
      }
    });
  };

  scope.deleteAll = function (apptableName, entityId) {
    resourceFactory.DataTablesResource.delete({ datatablename: apptableName, entityId, genericResultSet: 'true' }, {}, function () {
      route.reload();
    });
  };

  scope.viewDataTable = function (registeredTableName, data) {
    if (scope.datatabledetails.isMultirow) {
      location.path(`/viewdatatableentry/${registeredTableName}/${scope.office.id}/${data.row[0]}`);
    } else {
      location.path(`/viewsingledatatableentry/${registeredTableName}/${scope.office.id}`);
    }
  };
}

ViewOfficeController.$inject = ['$scope', '$routeParams', '$route', '$location', 'ResourceFactory'];
