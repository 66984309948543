<template>
  <div>
    <v-stepper
      ref="stepper"
      :steps="steps"
      :value="currentStep"
      class="container"
      @input="input"
    />
    <form-wrapper
      ref="form-wrapper"
      @submit="submit"
    >
      <div
        v-for="(templateStep, index) in steps"
        :key="index"
      >
        <template v-if="currentStep === templateStep">
          <slot :name="`step-${templateStep}`" />
        </template>
      </div>
      <button
        v-if="disablePreviousStep"
        class="btn btn-default pull-left"
        @click.prevent="previousStep"
      >
        <i class="fas fa-arrow-left" />
        {{ $t('label.button.previous') }}
      </button>

      <button
        v-if="disableNextStep"
        class="btn btn-default pull-right"
        @click.prevent="nextStep"
      >
        {{ $t('label.button.next') }}
        <i class="fas fa-arrow-right" />
      </button>

      <button
        v-if="isLastStep"
        type="submit"
        class="btn btn-primary pull-right"
      >
        {{ $t('label.button.submit') }}
      </button>

      <button
        v-if="isLastStep || !disablePreviousStep"
        class="btn btn-default"
        :class="{ 'pull-right' : disablePreviousStep }"
        @click.prevent="cancel"
      >
        {{ $t('label.button.cancel') }}
      </button>
    </form-wrapper>
  </div>
</template>
<script>
import { VStepper } from 'simple-vue-stepper';
import { FormWrapper } from './form-input';

export default {
  components: {
    VStepper,
    FormWrapper
  },
  model: {
    prop: 'currentStep',
    event: 'update'
  },
  props: {
    steps: {
      type: Number,
      default: 0
    },
    currentStep: {
      type: Number,
      default: 1
    },
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    disablePreviousStep () {
      return this.currentStep !== 1;
    },
    disableNextStep () {
      return this.currentStep < this.steps;
    },
    previousStep () {
      return this.$refs.stepper.previous;
    },
    isLastStep () {
      return this.steps === this.currentStep;
    }
  },
  methods: {
    async input (currentStep) {
      if (await this.$refs['form-wrapper']?.validate() === 'errors') return;
      this.$emit('update', currentStep);
    },
    async nextStep () {
      return this.$refs.stepper.next();
    },
    cancel () {
      this.$emit('on-cancel');
    },
    async submit () {
      this.$emit('on-submit');
    }
  }
};
</script>
