export function ViewDataTableController (scope, routeParams, resourceFactory, location, $uibModal) {
  scope.clientLegalFormOptions = [
    { id: 1, value: 'PERSON' },
    { id: 2, value: 'ENTITY' }
  ];
  scope.displayLegalForm = false;

  resourceFactory.DataTablesResource.getTableDetails({ datatablename: routeParams.tableName }, function (data) {
    let temp = [];
    let colName = data.columnHeaderData[0].columnName;
    if (colName === 'id') {
      data.columnHeaderData.splice(0, 1);
    }
    colName = data.columnHeaderData[0].columnName;
    if (['client_id', 'office_id', 'group_id', 'center_id', 'loan_id', 'savings_account_id', 'credit_account_id'].includes(colName)) {
      data.columnHeaderData.splice(0, 1);
    }
    let sectionIndex;
    let tempSec;
    const secColumnTemplateList = [];
    for (const i in data.sections) {
      secColumnTemplateList.push({ index: data.sections[i].id, section: data.sections[i] });
    }
    for (let i = 0; i < data.columnHeaderData.length; i += 1) {
      if (data.columnHeaderData[i].columnName.indexOf('_cd_') > 0) {
        temp = data.columnHeaderData[i].columnName.split('_cd_');
        data.columnHeaderData[i].columnName = temp[1];
        data.columnHeaderData[i].code = temp[0];
      } else if (data.columnHeaderData[i].columnName.indexOf('_cv_') > 0) {
        temp = data.columnHeaderData[i].columnName.split('_cv_');
        data.columnHeaderData[i].columnName = temp[1];
        data.columnHeaderData[i].code = temp[0];
      }
      sectionIndex = data.columnHeaderData[i].sectionId;

      Object.entries(secColumnTemplateList).forEach(([key, value]) => {
        if (key && value.index === sectionIndex) {
          tempSec = value.section;
        }
      });
      if (tempSec.columns === undefined) { tempSec.columns = []; }
      tempSec.columns.push({
        name: data.columnHeaderData[i].columnName,
        type: data.columnHeaderData[i].columnDisplayType.toLowerCase(),
        mandatory: !data.columnHeaderData[i].isColumnNullable,
        title: data.columnHeaderData[i].columnDisplayName,
        description: data.columnHeaderData[i].description,
        index: data.columnHeaderData[i].position,
        columnLength: data.columnHeaderData[i].columnLength,
        unmaskedSuffixLength: data.columnHeaderData[i].unmaskedSuffixLength,
        sectionIndex: tempSec.position,
        columnCode: data.columnHeaderData[i].columnCode,
        regex: data.columnHeaderData[i].regex,
        parentNameAttribute: data.columnHeaderData[i].parentNameAttribute
      });
    }
    scope.datatable = data;
    if (scope.datatable.applicationTableName === 'm_client') {
      scope.displayLegalForm = true;
      if (scope.datatable.legalFormId === 1) { scope.legalForm = 'PERSON'; } else if (scope.datatable.legalFormId === 2) { scope.legalForm = 'ENTITY'; } else { scope.legalForm = 'Both(PERSON & ENTITY)'; }
    }
    scope.datatable.breadcrumbName = scope.datatable.datatableTitle || scope.datatable.registeredTableName;
  });
  scope.deleteTable = function () {
    $uibModal.open({
      templateUrl: 'deletetable.html',
      controller: TableDeleteCtrl
    });
  };
  const TableDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.DataTablesResource.delete({ datatablename: routeParams.tableName }, {}, function () {
        $uibModalInstance.close('delete');
        location.path('/datatables');
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewDataTableController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location', '$uibModal'];
