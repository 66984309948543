<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.heading.client.addAddress') }}
    </template>
    <div class="col-6">
      <address-form
        @cancel="cancel"
        @on-submit="submit"
      />
    </div>
  </main-content-layout>
</template>

<script setup>
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import AddressForm from '@/components/clients/AddressForm.vue';
import apiService from '@/services/apiService';
import router from '@/router';
import { $notify } from '@/plugins/prototypes';

const {
  params: { clientId },
  query: { primaryAccountId }
} = router.currentRoute;

const cancel = () => {
  const url = primaryAccountId ? `#/saving-accounts/${primaryAccountId}/employee-accounts/client/${clientId}` : `#/viewclient/${clientId}`;
  location.assign(url);
};
const submit = async (form) => {
  try {
    await apiService.clientAddress.save(clientId, { type: form.addressTypeId }, form);
    cancel();
  } catch (e) {
    $notify.error(e);
  }
};
</script>
