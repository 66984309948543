import _ from 'underscore';

export function ViewRecurringDepositProductController (scope, routeParams, location, anchorScroll, resourceFactory, $uibModal) {
  resourceFactory.recurringDepositProductResource.get({ productId: routeParams.productId, template: 'true' }, function (data) {
    scope.depositproduct = data;
    scope.chartSlabs = scope.depositproduct.activeChart.chartSlabs;
    scope.hasAccounting = data.accountingRule.id === 2;
  });

  scope.scrollto = function (link) {
    location.hash(link);
    anchorScroll();
  };

  scope.incentives = function (index) {
    $uibModal.open({
      templateUrl: 'incentive.html',
      controller: IncentiveCtrl,
      resolve: {
        chartSlab () {
          return scope.depositproduct.activeChart.chartSlabs[index];
        }
      }
    });
  };

  const IncentiveCtrl = function ($scope, $uibModalInstance, chartSlab) {
    $scope.chartSlab = chartSlab;
    _.each($scope.chartSlab.incentives, function (incentive) {
      if (!incentive.attributeValueDesc) {
        incentive.attributeValueDesc = incentive.attributeValue;
      }
    });
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewRecurringDepositProductController.$inject = ['$scope', '$routeParams', '$location', '$anchorScroll', 'ResourceFactory', '$uibModal'];
