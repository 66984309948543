export function DataTableController (scope, resourceFactory, location) {
  scope.routeTo = function (id) {
    location.path(`/viewdatatable/${id}`);
  };

  if (!scope.searchCriteria.datatables) {
    scope.searchCriteria.datatables = null;
    scope.saveSC();
  }
  scope.filterText = scope.searchCriteria.datatables || '';

  scope.onFilter = function () {
    scope.searchCriteria.datatables = scope.filterText;
    scope.saveSC();
  };

  scope.DataTablesPerPage = 15;
  resourceFactory.DataTablesResource.getAllDataTables(function (data) {
    scope.datatables = data;
  });
}

DataTableController.$inject = ['$scope', 'ResourceFactory', '$location'];
