export function StatusLookup () {
  return (_input) => {
    const cssClassNameLookup = {
      true: 'statusactive',
      False: 'statusdeleted',
      Active: 'statusactive',
      ACTIVE: 'statusactive',
      INACTIVE: 'statusdeleted',
      'loanStatusType.submitted.and.pending.approval': 'statuspending',
      'loanStatusType.approved': 'statusApproved',
      'loanStatusType.active': 'statusactive',
      'loanStatusType.overpaid': 'statusoverpaid',
      'savingsAccountStatusType.submitted.and.pending.approval': 'statuspending',
      'savingsAccountStatusType.approved': 'statusApproved',
      'savingsAccountStatusType.active': 'statusactive',
      'savingsAccountStatusType.activeInactive': 'statusactiveoverdue',
      'savingsAccountStatusType.activeDormant': 'statusactiveoverdue',
      'savingsAccountStatusType.matured': 'statusmatured',
      'loanProduct.active': 'statusactive',
      'clientStatusType.pending': 'statuspending',
      'clientStatusType.closed': 'statusclosed',
      'clientStatusType.rejected': 'statusrejected',
      'clientStatusType.withdraw': 'statuswithdraw',
      'clientStatusType.active': 'statusactive',
      'clientStatusType.submitted.and.pending.approval': 'statuspending',
      'clientStatusTYpe.approved': 'statusApproved',
      'clientStatusType.transfer.in.progress': 'statustransferprogress',
      'clientStatusType.transfer.on.hold': 'statustransferonhold',
      'groupingStatusType.active': 'statusactive',
      'groupingStatusType.pending': 'statuspending',
      'groupingStatusType.submitted.and.pending.approval': 'statuspending',
      'groupingStatusType.approved': 'statusApproved',
      'shareAccountStatusType.submitted.and.pending.approval': 'statuspending',
      'shareAccountStatusType.approved': 'statusApproved',
      'shareAccountStatusType.active': 'statusactive',
      'shareAccountStatusType.rejected': 'statusrejected',
      'purchasedSharesStatusType.applied': 'statuspending',
      'purchasedSharesStatusType.approved': 'statusApproved',
      'purchasedSharesStatusType.rejected': 'statusrejected',
      'charges.StatusType.active.true': 'statusactive',
      'employees.StatusType.active.true': 'statusactive',
      'cardProduct.true': 'statusactive',
      'cardProduct.false': 'statusdeleted',
      'card.status.ACTIVE': 'statusactive',
      'card.status.INACTIVE': 'statusinactive',
      'card.status.SUSPENDED': 'statussuspended',
      'card.status.TERMINATED': 'statusblocked',
      'card.status.REJECTED': 'statusrejected',
      'card.status.ORDERED': 'statusordered',
      'card.status.CREATED': 'statuscreated',
      'card.status.RENEWED': 'statusrenewed',
      'card.status.submitted.and.pending.approval': 'statuspending',
      'card.status.withdrawn.by.applicant': 'statuswithdraw'
    };

    return cssClassNameLookup[_input];
  };
}
