export function EditTwoFactorConfigController ($scope, resourceFactory, $routeParams, location) {
  const configs = [];
  configs.sms = ['otp-delivery-sms-enable', 'otp-delivery-sms-provider', 'otp-delivery-sms-text'];
  configs.email = ['otp-delivery-email-enable', 'otp-delivery-email-subject', 'otp-delivery-email-body'];
  configs.token = ['otp-token-length', 'access-token-live-time-extended', 'otp-token-live-time', 'access-token-live-time'];

  const boolConfigs = ['otp-delivery-sms-enable', 'otp-delivery-email-enable'];
  const multiLineConfigs = ['otp-delivery-sms-text', 'otp-delivery-email-body'];

  $scope.configs = [];
  $scope.configType = $routeParams.configType;
  $scope.disabledTwoFactor = false;

  resourceFactory.twoFactorConfigResource.getAllConfigs(function (data) {
    for (const i in data.toJSON()) {
      if (configs[$scope.configType].indexOf(i) > -1) {
        $scope.configs.push({
          name: i,
          value: data[i].toString(),
          type: getConfigType(i)
        });
      }
    }
  }, function () {
    $scope.disabledTwoFactor = true;
  });

  $scope.cancel = function () {
    location.path('/twofactorconfig');
  };

  $scope.submit = function () {
    const formData = {};
    for (let i = 0; i < $scope.configs.length; i += 1) {
      const config = $scope.configs[i];
      formData[config.name] = config.value;
    }
    resourceFactory.twoFactorConfigResource.put(formData, function () {
      location.path('/twofactorconfig');
    });
  };

  const getConfigType = function (name) {
    if (boolConfigs.indexOf(name) > -1) { return 'BOOLEAN'; }
    if (multiLineConfigs.indexOf(name) > -1) {
      return 'MULTILINE';
    }
    return 'TEXT';
  };
}

EditTwoFactorConfigController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location'];
