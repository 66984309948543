// (function (module) {
//   mbanq.directives = _.extend(module, {
//     AutofocusDirective ($timeout, $parse) {
//       return {
//         link (scope, element, attrs) {
//           const focus = $parse(attrs.ngAutofocus)
//           scope.$watch(focus, function (value) {
//             if (value === true) {
//               $timeout(function () {
//                 element[0].focus()
//               })
//             }
//           })
//         }
//       }

//     }
//   })
// }(mbanq.directives || {}))

// mbanq.ng.application.directive('ngAutofocus', ['$timeout', '$parse', mbanq.directives.AutofocusDirective]).run(function ($log) {
//   $log.info('AutofocusDirective initialized')
// })

export function AutofocusDirective ($timeout, $parse) {
  return {
    link (scope, element, attrs) {
      const focus = $parse(attrs.ngAutofocus);
      scope.$watch(focus, function (value) {
        if (value === true) {
          $timeout(function () {
            element[0].focus();
          });
        }
      });
    }
  };
}

AutofocusDirective.$inject = ['$timeout', '$parse'];
