<template>
  <div class="btn-group">
    <button
      v-if="havePermissions(['CHANGE_TYPE_CARD']) || (havePermissions(['FULFILL_CARD']) && isFulfillStatusOnHoldNotVirtual)"
      id="optionDropDown"
      class="btn btn-primary dropdown-toggle"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ $t('label.button.options') }}
    </button>
    <ul
      class="dropdown-menu"
      aria-labelledby="optionDropDown"
    >
      <li>
        <button
          v-has-permission="'CHANGE_TYPE_CARD'"
          class="dropdown-item"
          @click="changeTypeModal.show()"
        >
          {{ $t(`label.anchor.changeType`) }}
        </button>
        <button
          v-if="isFulfillStatusOnHoldNotVirtual"
          v-has-permission="'FULFILL_CARD'"
          class="dropdown-item"
          @click="orderPhysicalCardModal.show()"
        >
          {{ $t(`label.button.orderPhysicalCard`) }}
        </button>
      </li>
    </ul>
    <base-modal
      ref="changeTypeModal"
      @on-submit="onSubmit('CHANGE_TYPE')"
    >
      <template #header>
        <h4>{{ $t('label.heading.confirmChangeCardType') }}</h4>
      </template>
    </base-modal>
    <base-modal
      ref="orderPhysicalCardModal"
      @on-submit="onSubmit('FULFILL')"
    >
      <template #header>
        <h4>{{ $t('label.heading.confirmOrderPhysicalCardModal') }}</h4>
      </template>
    </base-modal>
  </div>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import apiService from '@/services/apiService';
import { $notify } from '@/plugins/prototypes';
import { get } from '@vueuse/core';
import { ref, computed } from 'vue';
import { havePermissions } from '@/directives/permission.js';

const changeTypeModal = ref('');
const orderPhysicalCardModal = ref('');

const emit = defineEmits(['onStatusChanged']);
const props = defineProps({
  cardItem: {
    type: Object,
    default: () => ({})
  }
});

const isFulfillStatusOnHoldNotVirtual = computed(() =>
  props.cardItem.fulfillmentStatus === 'PHYSICAL_CARD_FULFILLMENT_ON_HOLD' && !props.cardItem.virtual);

const onSubmit = async (command) => {
  try {
    await apiService.cardsResource.command(props.cardItem.id, command);
    emit('onStatusChanged');
  } catch (e) {
    $notify.error(e);
  } finally {
    if (command === 'CHANGE_TYPE') get(changeTypeModal).hide();
    if (command === 'FULFILL') get(orderPhysicalCardModal).hide();
  }
};
</script>
