<template>
  <div class="row">
    <component
      :is="field.inputType?.key || field.type"
      v-for="field in schema"
      v-bind="field"
      :id="`field-${field.id}`"
      :key="`field-${field.id}`"
      :required="field.required"
      :label="field.label"
      :hint="field.note"
      reverse
      instant-upload
      :options="optionsCheck(field)"
      :value="getValue(`${step}_${field.id}`)"
      @input="updateForm(`${step}_${field.id}`, $event)"
    />
  </div>
</template>

<script>
import {
  InputDatePicker as INPUT_DATE,
  InputText as INPUT_TEXT,
  InputText as INPUT_TRANSACTION_ID,
  InputCheckBox as INPUT_AGREE_BUTTON,
  InputCheckBoxMultiple as INPUT_CHECKBOX,
  InputAmount as INPUT_AMOUNT,
  InputFile as INPUT_FILE,
  InputRadio as INPUT_YES_NO,
  InputRadio as INPUT_RADIO
} from '@/components/shared/form-input';
import Information from '@/components/shared/Information.vue';

export default {
  components: {
    INPUT_DATE,
    INPUT_TEXT,
    INPUT_AMOUNT,
    INPUT_FILE,
    INPUT_YES_NO,
    INPUT_RADIO,
    INPUT_AGREE_BUTTON,
    INFORMATION: Information,
    INPUT_CHECKBOX,
    INPUT_TRANSACTION_ID
  },
  props: {
    name: { // Form name is used as key for form element
      type: String,
      required: true
    },
    schema: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    step: {
      type: Number,
      default: null
    },
    nextStep: {
      type: Number,
      default: null
    },
    nextStepTrue: {
      type: Number,
      default: null
    },
    nextStepFalse: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      formData: this.value,
      yesNoOptions: [
        {
          label: this.$t('label.input.yes'),
          value: true
        },
        {
          label: this.$t('label.input.no'),
          value: false
        }
      ]
    };
  },
  watch: {
    value (data) {
      this.formData = data;
    }
  },
  methods: {
    optionsCheck (field) {
      if (field.inputType?.key === 'INPUT_YES_NO' && !field.options.length) {
        return this.yesNoOptions;
      }
      return field.options;
    },
    updateForm (fieldName, value) {
      this.$set(this.formData, fieldName, value);

      let nextStep = this.nextStep;
      if (this.nextStepTrue || this.nextStepFalse) {
        if (this.formData[fieldName] === true) {
          nextStep = this.nextStepTrue;
        }
        if (this.formData[fieldName] === false) {
          nextStep = this.nextStepFalse;
        }
      }
      this.$emit('input', fieldName, value, nextStep);
    },
    getValue (fieldName) {
      return this.formData[fieldName];
    }
  }
};
</script>
