<template>
  <div>
    <div class="modal-header silver">
      <h3 class="bolder">
        {{ $t('label.head.stopPayment') }}
      </h3>
    </div>
    <div class="modal-body">
      <div
        v-if="error"
        class="alert alert-danger alert-dismissible"
        role="alert"
      >
        {{ error }}
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="form-group row mb-3">
        <label
          for="payment-type"
          class="col-sm-2 col-form-label"
        >{{ $t('label.input.paymentType') }}</label>
        <div class="col-sm-4">
          <select
            id="payment-type"
            v-model="formData.type"
            class="form-control form-select"
            disabled
          >
            <option
              v-for="(type, i) in types"
              :key="i"
              selected
              :value="type"
            >
              {{ type }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group row mb-3">
        <label
          for="payee"
          class="col-sm-2 col-form-label"
        >{{ $t('label.input.payee') }}</label>
        <div class="col-sm-4">
          <input
            id="payee"
            v-model="formData.payee"
            type="text"
            class="form-control"
          >
        </div>
        <div class="col-sm-2 text-left">
          <label
            for="amount"
            class="col-form-label"
          >{{ $t('label.input.waiveFee') }}</label>
        </div>
        <div class="col-sm-1 mt-2">
          <input
            v-model="formData.waiveCharge"
            type="checkbox"
            style="cursor: pointer;height: 15px;width:15px;"
            class="form-control text-left"
          >
        </div>
      </div>
      <div
        v-if="formData.waiveCharge === false"
        class="form-group row mb-3"
      >
        <label
          for="amount"
          class="col-sm-2 col-form-label"
        >{{ $t('label.input.feeAmount') }}<span class="required"> *</span></label>
        <div class="col-sm-4">
          <select
            id="charge-amount"
            v-model="formData.specifiedDueDateChargeId"
            class="form-control form-select"
            @change="handleChange"
          >
            <option
              v-for="(charge, i) in charges"
              :key="i"
              :amount="charge.amount"
              :value="charge.id"
            >
              {{ charge.name }}
            </option>
          </select>
        </div>
        <div class="col-sm-2 text-left">
          <label
            for="amount"
            class="col-form-label"
          >{{ $t('label.input.charge') }}<span class="required"> *</span></label>
        </div>
        <div class="col-sm-4">
          <input
            id="max_amount"
            v-model="formData.specifiedDueDateChargeAmount"
            type="number"
            class="form-control"
          >
        </div>
      </div>
      <div class="form-group row mb-3">
        <label
          for="stop_payment_till"
          class="col-sm-2 col-form-label"
        >
          {{ $t('label.input.stopPaymentTill') }} <span class="required">*</span>
        </label>
        <div class="col-sm-10">
          <div
            v-for="(stopPaymentType, i) in stopPaymentTill"
            :key="i"
            class="form-check form-check-inline"
            value="type"
          >
            <input
              :id="stopPaymentType"
              v-model="formData.stopPaymentTill"
              class="form-check-input"
              type="radio"
              name="stop_payment_till"
              :value="stopPaymentType"
            >
            <label
              class="form-check-label"
              :for="stopPaymentType"
            >{{ stringReplace(stopPaymentType) }}</label>
          </div>
        </div>
      </div>
      <div
        v-if="formData.stopPaymentTill === 'UNTIL_DATE'"
        class="form-group row mb-3 d-flex flex-row-reverse"
      >
        <div class="col-sm-4">
          <input-date-picker
            id="end-date"
            v-model="formData.endDate"
            format="YYYY-MM-DD"
          />
        </div>
        <label
          for="end-date"
          class="col-sm-2 col-form-label"
        >{{ $t('label.input.endDate') }}</label>
      </div>
      <div class="form-group row mb-3">
        <label
          for="reason"
          class="col-sm-2 col-form-label"
        >
          {{ $t('label.input.reason') }} <span class="required">*</span>
        </label>
        <div class="col-sm-4">
          <select
            id="reason"
            v-model="formData.reasonId"
            class="form-control form-select"
          >
            <option
              disabled
              selected
              value=""
            >
              {{ $t('label.selectOption') }}
            </option>
            <option
              v-for="(reason, i) in reasons"
              :key="i"
              :value="reason.id"
            >
              {{ reason.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <span class="pull-right">
          <button
            class="btn btn-default"
            type="button"
            @click="$emit('cancel')"
          >{{ $t('label.button.cancel') }}</button>
          <button
            class="btn btn-primary"
            type="submit"
            :disabled="loading"
            @click="submit()"
          >{{ $t('label.button.confirm') }}</button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { InputDatePicker } from '@/components/shared/form-input';
import apiService from '@/services/apiService';

export default {
  components: {
    InputDatePicker
  },
  props: {
    savingsAccountId: {
      type: String,
      default: null
    },
    paymentType: {
      type: String,
      default: null
    },
    transferId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      formData: {
        type: this.paymentType || 'ACH',
        reasonId: '',
        transferId: this.transferId,
        waiveCharge: false,
        specifiedDueDateChargeId: ''
      },
      reasons: [],
      stopPaymentTill: [],
      types: [],
      charges: [],
      error: '',
      loading: false
    };
  },
  created () {
    this.getResources();
  },
  methods: {
    stringReplace (string) {
      return string.replace('_', ' ');
    },
    handleChange () {
      const id = this.formData.specifiedDueDateChargeId;
      const key = Object.keys(this.charges).find((key) => this.charges[key].id === id);
      this.formData.specifiedDueDateChargeAmount = this.charges[key].amount;
    },
    async getResources () {
      try {
        this.loading = true;
        const res = await apiService.stopPayments.template(this.savingsAccountId);
        alert();
        const chargesRes = await apiService.stopPayments.charges('SPECIFIED_DUE_DATE');
        const { reason, stopPaymentTill, type } = res.data;
        const charges = chargesRes.data;
        this.reasons = reason;
        this.stopPaymentTill = stopPaymentTill;
        this.types = type;
        this.charges = charges;
      } catch (error) {
        this.error = error.response?.data?.developerMessage;
      } finally {
        this.loading = false;
      }
    },
    async submit () {
      this.error = '';
      if (this.formData.stopPaymentTill === 'UNTIL_DATE' && !this.formData.endDate) {
        this.error = this.$t('messages.error.endDateRequired');
        return;
      }
      try {
        this.loading = true;
        if (this.formData.endDate) {
          this.formData = {
            ...this.formData,
            dateFormat: 'yyyy-MM-dd',
            locale: 'en'
          };
        }
        const res = await apiService.stopPayments.create(this.savingsAccountId, this.formData);
        if (res) {
          this.$emit('stopPaymentSuccess');
        }
      } catch (error) {
        this.error = error.response?.data?.errors?.map(e => `${e.parameterName} ${e.defaultUserMessage}`).toString();
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
