<template>
  <transition
    name="slide-fade-vertical"
  >
    <button
      v-if="windowScrollY > 200"
      id="back-to-top-btn"
      type="button"
      class="btn btn-secondary btn-lg"
      @click.prevent="scrollToTop"
    >
      <i class="fa-solid fa-arrow-up" />
    </button>
  </transition>
</template>
<script setup>
import { useWindowScroll } from '@vueuse/core';
import { nextTick } from 'vue';

const { y: windowScrollY } = useWindowScroll();

const scrollToTop = async () => {
  await nextTick(() => window.scrollTo(0, 0));
};
</script>

<style scoped>
#back-to-top-btn {
  z-index: 1049;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.slide-fade-vertical-enter-active,
.slide-fade-vertical-leave-active {
  transition: all 0.3s ease-out;
}
.slide-fade-vertical-enter,
.slide-fade-vertical-leave-to {
  transform: translateY(49px);
  opacity: 0;
}
</style>
