<template>
  <base-table
    :fields="fields"
    :items="cardHistories"
  >
    <template #event="{ item }">
      <span class="text-uppercase">
        {{ item.event }}
      </span>
    </template>
  </base-table>
</template>

<script setup>
import BaseTable from '@/components/shared/data-grid/BaseTable.vue';
import i18n from '@/plugins/i18n';
import { onMounted, ref } from 'vue';
import { set } from '@vueuse/core';

const cardHistories = ref([]);
const props = defineProps({
  cardItem: {
    type: Object,
    default: () => ({})
  }
});

onMounted(() => {
  const sortedCardHistories = props.cardItem.cardHistory;
  set(cardHistories, sortedCardHistories.sort((a, b) => new Date(b.date) - new Date(a.date)));
});

const fields = [
  { key: 'id', label: i18n.t('label.head.eventId') },
  { key: 'createdBy.username', label: i18n.t('label.head.username') },
  { key: 'event', label: i18n.t('label.head.event') },
  { key: 'date', label: i18n.t('label.head.date') },
  { key: 'reference', label: i18n.t('label.head.reference') }
];
</script>
