export function ViewAccountNumberPreferencesController (scope, resourceFactory, location, routeParams, $uibModal) {
  scope.resourceId = routeParams.id;
  resourceFactory.accountNumberResources.get({ accountNumberFormatId: scope.resourceId }, function (data) {
    scope.accountType = data.accountType.value;
    scope.prefixType = data.prefixType.value;
    scope.customPrefix = data.customPrefix;
    scope.accountNumberSeed = data.accountNumberSeed;
    scope.accountNumberLength = data.accountNumberLength;
  });

  scope.cancel = function () {
    location.path('/accountnumberpreferences');
  };

  scope.editPreferences = function () {
    location.path(`/editaccountnumberpreferences/${scope.resourceId}`);
  };

  const DeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.accountNumberResources.delete({ accountNumberFormatId: scope.resourceId }, function () {
        location.path('/accountnumberpreferences');
      });
      $uibModalInstance.close('delete');
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.deletePreferences = function () {
    $uibModal.open({
      templateUrl: 'deletepreferences.html',
      controller: DeleteCtrl
    });
  };
}

ViewAccountNumberPreferencesController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams', '$uibModal'];
