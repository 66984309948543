<template>
  <container-login :title="$t('label.head.twoFactorAuthentication')">
    <p>{{ $t('label.pleaseEnterTheAuthenticationToken') }}</p>
    <p><b>{{ $t('label.sendViaEmailToMbanq') }}</b></p>
    <form-wrapper @submit="onRequestOtp">
      <button
        type="submit"
        class="btn btn-primary w-100 d-flex justify-content-center"
      >
        {{ $t('label.button.requestToken') }}
      </button>
    </form-wrapper>
  </container-login>
</template>

<script setup>
import ContainerLogin from '@/components/login/Container.vue';
import apiService from '@/services/apiService';
import router from '@/router';
import { FormWrapper } from '@/components/shared/form-input/index';
import { $notify } from '@/plugins/prototypes';

const onRequestOtp = async () => {
  try {
    await apiService.authentication.requestOtp({ deliveryMethod: 'EMAIL' });
    return router.push({ name: 'Otp' });
  } catch (error) {
    $notify.error(error);
  }
};
</script>
