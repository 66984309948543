<template>
  <form-wrapper
    @submit="submit()"
  >
    <div class="row">
      <div class="col-sm-12 col-md-10">
        <div class="row mb-4">
          <div class="col-sm-4 col-md-4">
            <input-select
              id="type"
              v-model="type"
              required
              :label="$t('label.input.type')"
              value-field="id"
              :options="templateResource"
              vertical
            />
          </div>
          <div class="col-sm-4 col-md-4">
            <input-text
              id="name"
              v-model="name"
              required
              :label="$t('label.input.templateName')"
              vertical
            />
          </div>
        </div>
        <AdvanceTemplateForm
          v-if="isPushNotification"
          :form-meta-data="formMetaData"
        />
        <div class="row">
          <div class="form-group col-sm-8">
            <div class="d-flex justify-content-between">
              <label
                class="control-label mb-1"
              >
                {{ $t('label.input.text') }}
              </label>
              <div class="form-check form-switch align-item-end">
                <input
                  id="normalTextInputSwitch"
                  v-model="normalTextInput"
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                >
                <label
                  class="form-check-label"
                  for="normalTextInputSwitch"
                >
                  {{ $t(`label.input.swtichTo${normalTextInput ? 'Ckeditor' : 'NormalInput'}`) }}
                </label>
              </div>
            </div>
            <textarea
              v-if="normalTextInput"
              v-model="text"
              rows="5"
              class="form-control"
            />
            <ckeditor
              v-if="!normalTextInput"
              :value="text"
              @change="handleCkEditor"
            />
          </div>
          <div
            v-if="placeholders"
            class="col-sm-4 col-md-4"
          >
            <div class="form-group">
              <label
                class="control-label"
                for="name"
              >{{ $t('label.input.placeholder') }}&nbsp;</label>
              <div
                v-for="(placeholder, index) in placeholders"
                :key="index"
              >
                {{ placeholder }}
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2 float-right">
          <button
            class="btn btn-default"
            @click="cancel"
          >
            {{ $t('label.button.cancel') }}
          </button>
          <button
            id="save"
            v-has-permission="'CREATE_TEMPLATE'"
            type="submit"
            class="btn btn-primary"
          >
            {{ $t('label.button.save') }}
          </button>
        </div>
      </div>
    </div>
  </form-wrapper>
</template>

<script setup>
import router from '@/router';
import ckeditor from '@/components/shared/CKEditor.vue';
import AdvanceTemplateForm from './MetaTemplate.vue';
import { InputSelect, InputText, FormWrapper } from '../shared/form-input';
import { toRefs, reactive, ref, computed } from 'vue';
import { set, get } from '@vueuse/core';

const props = defineProps({
  templateData: {
    type: Object,
    default: () => ({})
  },
  templateResource: {
    type: Array,
    required: true
  }
});

const normalTextInput = ref(false);
const { metaData, placeholders, ...restData } = toRefs(props.templateData);
const formMetaData = ref([]);

const formData = reactive({
  name: '',
  text: '',
  type: null,
  ...restData
});

if (!metaData) {
  set(formMetaData, [{ key: '', value: '' }]);
} else {
  const metaDataObjects = Object.entries(metaData.value).map(([key, value]) => ({ key, value }));
  set(formMetaData, metaDataObjects);
}

const { text, name, type } = toRefs(formData);
const emits = defineEmits(['submit']);
const isPushNotification = computed(() => props.templateResource.find(resource => resource.id === get(type))?.name === 'Push Notification');

const handleCkEditor = (value) => {
  set(text, value);
};

const submit = async () => {
  let tempMetaData = {};
  get(formMetaData).forEach(item => {
    tempMetaData = { ...tempMetaData, [item.key]: item.value };
  });
  delete formData.identifier;
  const modifiedFormData = { ...formData, metaData: tempMetaData };
  emits('submit', modifiedFormData);
};

const cancel = () => {
  if (get(formData).id) { return router.push({ path: '/templates', name: 'ViewTemplate', params: { id: get(formData).id } }); }
  router.push({ path: '/templates', name: 'ListTemplates' });
};
</script>
