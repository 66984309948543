// (function (module) {
//   mbanq.directives = _.extend(module, {
//     LateValidateDirective () {
//       const numRegex = /^([0-9])*([0-9]+(,)[0-9]+)*$/
//       const decimalRegex=/^([0-9])*([0-9]+(,)[0-9]+)*([0-9]+(\.)[0-9]+)*$/
//       return {
//         restrict: 'A',
//         require: '^ngModel',
//         scope:{
//           number:'@number',
//           decimalNumber:'@decimalNumber'
//         },

//         link (scope, elm, attr, ctrl) {
//           if (attr.type === 'radio' || attr.type === 'checkbox' || attr.type ==='input') return
//           elm.bind('blur', function () {
//             scope.$apply(function () {
//               const isMatchRegex = numRegex.test(elm.val())
//               const isDecimalMatchRegex=decimalRegex.test(elm.val())
//               if (!elm.val()) {
//                 ctrl.$setValidity('req', false)
//               } else {
//                 ctrl.$setValidity('req', true)
//               }
//               if(scope.number) {
//                 if (isMatchRegex || !elm.val()) {
//                   ctrl.$setValidity('nval', true)
//                 } else {
//                   ctrl.$setValidity('nval', false)
//                 }
//               }
//               if(scope.decimalNumber) {
//                 if (isDecimalMatchRegex || !elm.val()) {
//                   ctrl.$setValidity('nval', true)
//                 } else {
//                   ctrl.$setValidity('nval', false)
//                 }
//               }
//             })
//           })
//         }
//       }
//     }
//   })
// }(mbanq.directives || {}))

// mbanq.ng.application.directive('lateValidate', [mbanq.directives.LateValidateDirective]).run(function ($log) {
//   $log.info('LateValidateDirective initialized')
// })

export function LateValidateDirective () {
  const numRegex = /^([0-9])*([0-9]+(,)[0-9]+)*$/;
  const decimalRegex = /^([0-9])*([0-9]+(,)[0-9]+)*([0-9]+(\.)[0-9]+)*$/;
  return {
    restrict: 'A',
    require: '^ngModel',
    scope: {
      number: '@number',
      decimalNumber: '@decimalNumber'
    },

    link (scope, elm, attr, ctrl) {
      if (attr.type === 'radio' || attr.type === 'checkbox' || attr.type === 'input') return;
      elm.bind('blur', function () {
        scope.$apply(function () {
          const isMatchRegex = numRegex.test(elm.val());
          const isDecimalMatchRegex = decimalRegex.test(elm.val());
          if (!elm.val()) {
            ctrl.$setValidity('req', false);
          } else {
            ctrl.$setValidity('req', true);
          }
          if (scope.number) {
            if (isMatchRegex || !elm.val()) {
              ctrl.$setValidity('nval', true);
            } else {
              ctrl.$setValidity('nval', false);
            }
          }
          if (scope.decimalNumber) {
            if (isDecimalMatchRegex || !elm.val()) {
              ctrl.$setValidity('nval', true);
            } else {
              ctrl.$setValidity('nval', false);
            }
          }
        });
      });
    }
  };
}
