<template>
  <b-modal
    id="tx-edit-transfer-modal"
    hide-header-close
    title="Are you sure you want to edit this transfer?"
    @show="parse"
    @hide="formData = null"
  >
    <form
      v-if="formData"
      class="form"
    >
      <b-form-group class="mb-3">
        <template #label>
          {{ $t('label.search.returnCode') }} <span class="required">*</span>
        </template>
        <input-select
          v-model="formData.errorCode"
          :options="errorCodeOptions"
        />
      </b-form-group>

      <b-form-group
        class="mb-3"
        :label="$t('label.search.returnMessage')"
      >
        <b-form-textarea
          v-model="formData.errorMessage"
          :state="true"
        />
      </b-form-group>

      <b-form-group
        class="mb-3"
        label="Reference"
      >
        <template #label>
          Reference <span class="required">*</span>
        </template>
        <b-form-textarea
          v-model="formData.reference"
          :state="formData.reference?.length > 0"
        />
      </b-form-group>
    </form>

    <template #modal-footer="{ cancel }">
      <button
        class="btn btn-default"
        @click="cancel"
      >
        {{ $t('label.button.cancel') }}
      </button>
      <button
        class="btn btn-primary"
        :disabled="!formValid"
        @click="formSubmit"
      >
        {{ $t('label.button.confirm') }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import apiService from '@/services/apiService';
import { useTxMonitoringStore } from '@/stores/txmonitoring';
import { InputSelect } from '@/components/shared/form-input';

export default {
  name: 'TxEditTransferModal',
  components: { InputSelect },
  data () {
    return {
      formData: null,
      errorCodeOptions: []
    };
  },
  computed: {
    ...mapState(useTxMonitoringStore, ['transaction']),
    isWirePayment () {
      return this.transaction && this.transaction.paymentType === 'WIRE';
    },
    formValid () {
      return this.formData && (this.formData.errorCode || this.isWirePayment) && this.formData.reference;
    }
  },
  async created () {
    const { data: { achReturnErrorCodes } } = await apiService.transfer.getTransfersErrorCodes();
    this.errorCodeOptions = achReturnErrorCodes;
  },
  methods: {
    ...mapActions(useTxMonitoringStore, ['setNeedsToReloadList']),
    parse () {
      this.formData = {
        errorCode: this.transaction?.errorCode,
        errorMessage: this.transaction?.errorMessage,
        reference: this.transaction?.reference?.join('\n')
      };
    },
    async formSubmit () {
      if (!this.formValid) return;
      const formData = { ...this.formData };
      if (formData.reference) formData.reference = formData.reference.trim().split('\n');
      try {
        await apiService.transfer.updateTransfer(this.transaction.id, formData);
        this.$bvModal.hide('tx-edit-transfer-modal');
        this.setNeedsToReloadList(true);
      } catch (e) {
        this.$notify.error(e);
      }
    }
  }
};
</script>
