export function AccountIdentifierFormat () {
  return (_accountIdentifier) => {
    if (!_accountIdentifier) return '';

    let tIdentifier;
    const tAccountIdentifier = _accountIdentifier.toLowerCase();

    if (tAccountIdentifier.includes('account:')) {
      tIdentifier = tAccountIdentifier.replace('account:', '');
    } else if (tAccountIdentifier.includes('ach://')) {
      tIdentifier = tAccountIdentifier.replace('ach://', '').split('/')[1];
    } else if (tAccountIdentifier.includes('wire://')) {
      tIdentifier = tAccountIdentifier.replace('wire://', '').split('/')[1];
    } else if (tAccountIdentifier.includes('swift://')) {
      tIdentifier = tAccountIdentifier.replace('swift://', '').split('/')[1];
    } else {
      tIdentifier = _accountIdentifier;
    }

    return tIdentifier;
  };
}

export function RoutingNumberFormat () {
  return (_accountIdentifier) => {
    if (!_accountIdentifier) return '';

    let tRoutingNumber;
    const tRoutingNumberFormat = _accountIdentifier.toLowerCase();

    if (tRoutingNumberFormat.includes('ach://')) {
      tRoutingNumber = tRoutingNumberFormat.replace('ach://', '').split('/')[0];
    } else if (tRoutingNumberFormat.includes('wire://')) {
      tRoutingNumber = tRoutingNumberFormat.replace('wire://', '').split('/')[0];
    } else if (tRoutingNumberFormat.includes('swift://')) {
      tRoutingNumber = tRoutingNumberFormat.replace('swift://', '').split('/')[0];
    } else {
      tRoutingNumber = 'N/A';
    }

    return tRoutingNumber;
  };
}

export function IdentifierFormat () {
  return (_accountIdentifier) => {
    if (!_accountIdentifier) return '';

    let tIdentifier;
    const tIdentifierFormat = _accountIdentifier.toLowerCase();

    if (tIdentifierFormat.includes('ach://')) {
      tIdentifier = tIdentifierFormat.replace('ach://', '').split('/');
    } else if (tIdentifierFormat.includes('wire://')) {
      tIdentifier = tIdentifierFormat.replace('wire://', '').split('/');
    } else if (tIdentifierFormat.includes('swift://')) {
      tIdentifier = tIdentifierFormat.replace('swift://', '').split('/');
    } else if (tIdentifierFormat.includes('account:')) {
      tIdentifier = tIdentifierFormat.split(':');
      tIdentifier[0] = ''; // No need route for internal account
    } else {
      tIdentifier = ['', ''];
    }

    return { accountNumber: tIdentifier[1] || 'N/A', routeCode: tIdentifier[0] || 'N/A' };
  };
}
