import sanitizeHtml from 'sanitize-html';

export const sanitize = {
  install (app, options = {}) {
    const defaultOptions = {
      ...sanitizeHtml.defaults,
      ...options
    };

    app.prototype.$sanitize = (dirty, opts = null) =>
      sanitizeHtml(dirty, opts || defaultOptions);
  }
};
