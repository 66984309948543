<template>
  <div>
    <div class="card">
      <div class="card-body">
        <stepper
          v-model="currentStep"
          :steps="steps"
          @on-cancel="goBack"
          @on-submit="submit()"
        >
          <template #step-1>
            <hr>
            <div class="row">
              <div class="col-sm-5">
                <input-select
                  id="card-product"
                  v-model="formData.selectedCardProduct"
                  :label="$t('label.head.selectCardProducts')"
                  :options="cardProducts"
                  text-field="name"
                  required
                  @input="updateCardProduct()"
                />
                <input-select
                  id="saving-accounts"
                  v-model="formData.savingsAccountId"
                  :label="$t('label.head.selectSavingsAccount')"
                  :options="savingsAccounts"
                  text-field="accountNo"
                  value-field="id"
                  required
                />
                <input-select
                  id="user"
                  v-model="formData.userId"
                  :label="$t('label.head.selectUser')"
                  :options="users"
                  text-field="username"
                  value-field="id"
                  :required="!isUserRequired"
                />
              </div>
            </div>
            <hr>
          </template>
          <template #step-2>
            <div
              v-for="(value, key, index) in summaryDetails"
              :key="index"
              class="row mb-4 px-3 mt-3"
            >
              <strong class="border-buttom"> {{ $t(`label.head.${key}`) }}</strong>
              <div
                v-for="item in value"
                :key="item.key"
                class="d-flex"
              >
                <span class="col-sm-6 mt-2">{{ $t(`label.head.${item.key}`) }} :</span>
                <span class="mt-2">{{ item.value }}</span>
              </div>
            </div>
          </template>
        </stepper>
      </div>
    </div>
  </div>
</template>

<script setup>
import Stepper from '@/components/shared/Stepper.vue';
import apiService from '@/services/apiService';
import i18n from '@/plugins/i18n';
import { InputSelect } from '@/components/shared/form-input/index';
import router from '@/router';
import { onBeforeMount, reactive, ref, computed } from 'vue';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { $notify } from '@/plugins/prototypes';
import { set, get } from '@vueuse/core';

const currentRoute = router.currentRoute;
const { clientId } = currentRoute.params;

const breadCrumbsStore = useBreadcrumbsStore();

const steps = ref(2);
const currentStep = ref(1);
const users = ref([]);
const cardProducts = ref([]);
const savingsAccounts = ref([]);

const formData = reactive({
  savingsAccountId: null,
  productId: null,
  userId: null,
  selectedCardProduct: null
});

const updateCardProduct = () => {
  formData.productId = formData.selectedCardProduct?.id;
};
const isUserRequired = computed(() => {
  return !!formData.selectedCardProduct?.prepaidCard;
});

const parentClientId = ref(null);
onBeforeMount(async () => {
  const { data: userResources } = await apiService.clientResource.get(clientId);
  await userResource(userResources);

  set(parentClientId, userResources.parentId);

  const { data: { pageItems: cardProductResources } } = await apiService.cardProductsResource.getCard();
  const getCardProducts = cardProductResources.filter(({ active, cardType, legalForm }) => {
    // if employee type should be legalFormId 2 = entity, else follow the user legalForm
    const legalFormId = userResources.parentId ? 2 : userResources.legalForm?.id;
    return active && cardType === 'DEBIT' && (legalFormId === legalForm || !legalForm);
  });
  set(cardProducts, getCardProducts);

  const { data: savingsAccountResource } = await apiService.clientResource.getSavingAccounts(clientId, 'accounts', { savingsAccounts: 'savingsAccounts' });
  const getSavingsAccount = savingsAccountResource.savingsAccounts?.filter(({ status: { active } }) => active);
  set(savingsAccounts, getSavingsAccount);
  const breadcrumbs = [
    { text: i18n.t('label.anchor.clients'), href: `#/clients` },
    { text: userResources.displayName, href: `#/viewclient/${clientId}` },
    { text: i18n.t('label.anchor.addcard') }
  ];
  breadCrumbsStore.setBreadcrumbs(breadcrumbs);
});

const userResource = async (clientResource) => {
  const { authorizations: connectedUserIds } = clientResource;
  if (connectedUserIds?.length) {
    const result = await Promise.allSettled(connectedUserIds.map(async (userId) => await apiService.userResource.get(userId)));
    const tempUsers = result.filter(item => item.status === 'fulfilled').map(item => item.value.data);
    set(users, tempUsers);

    if (connectedUserIds.length === 1) {
      const defaultSelectUserId = connectedUserIds[0];
      formData.userId = defaultSelectUserId;
    }
  }
};

const userName = computed(() => {
  if (!formData.userId) return null;
  return get(users).find(({ id }) => id === formData.userId).username;
});
const savingAccountNo = computed(() => get(savingsAccounts).find(({ id }) => id === formData.savingsAccountId).accountNo);

const summaryDetails = computed(() => ({
  userDetail: [
    {
      key: 'userName',
      value: userName.value
    },
    {
      key: 'savingAccountNo',
      value: savingAccountNo.value
    }
  ],
  cardProducts: [
    {
      key: 'selectedCard',
      value: formData.selectedCardProduct?.name
    }
  ],
  cardDetails: [
    {
      key: 'primaryAccountDetail',
      value: formData.savingsAccountId
    }
  ]
})
);

const goBack = () => {
  location.assign(`#/viewclient/${clientId}`);
};

const submit = async () => {
  try {
    const { savingsAccountId, productId, userId } = formData;
    const data = isUserRequired.value
      ? { savingsAccountId, productId }
      : { savingsAccountId, productId, userId };

    await apiService.cardsResource.save(data);
    goBack();
  } catch (err) {
    $notify.error(err);
  }
};
</script>
