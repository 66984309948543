import { defineStore } from 'pinia';

const defaultState = {
  selectedTransactions: [],
  disputeTransactions: [],
  selectedCreditCard: {}
};

export const useDisputeCreditTxStore = defineStore('disputeCreditTx', {
  persist: true,
  state: () => ({ ...defaultState }),
  actions: {
    updateDisputeAmounts (value, txId) {
      this.selectedTransactions = this.selectedTransactions.map((tx) => {
        if (tx.id === txId) {
          tx.disputeAmount = value;
        }
        return tx;
      });
    },
    getDisputeTransactions () {
      return this.selectedTransactions.map(({ id, disputeAmount }) => ({ transactionId: id, disputeAmount }));
    },
    setSelectedTransactions (value) {
      this.selectedTransactions = value.map((tx) => ({ ...tx, disputeAmount: tx.amount }));
    },
    setSelectedCreditCard (value) {
      this.selectedCreditCard = value;
    },
    clearDisputeCreditTx () {
      this.selectedTransactions = [];
      this.selectedCreditCard = {};
    },
    removeTransaction (tx) {
      this.selectedTransactions = this.selectedTransactions.filter((t) => t.id !== tx.id);
    }
  }
});
