<template>
  <base-modal
    id="modal-confirm-payoff-inquiry"
    @on-submit="$emit('submit');"
  >
    <template #header>
      Confirmation
    </template>

    <template #body>
      <p class="mb-0">
        Are you sure you want to request and download payoff inquiry?
      </p>
    </template>
  </base-modal>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
</script>
