<template>
  <span>
    <button
      class="btn btn-light"
      @click="viewNote"
    >
      <i class="fas fa-align-justify" />
    </button>
    <base-modal
      id="modal-note"
      ref="noteModal"
      show-button-close
      hide-footer
    >
      <template #header>
        {{ $t('label.heading.note') }}
      </template>
      <template #body>
        {{ note }}
      </template>
    </base-modal>
  </span>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import { ref } from 'vue';

defineProps({
  note: {
    type: String,
    default: ''
  }
});

const noteModal = ref(null);
const viewNote = () => {
  noteModal.value.show();
};

</script>
