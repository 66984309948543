export function AuditController (scope, resourceFactory, paginatorService, dateFilter, location) {
  scope.formData = [];
  scope.isCollapsed = true;
  scope.date = {};
  scope.audit = [];
  scope.searchData = [];

  scope.routeTo = function (id) {
    location.path(`viewaudit/${id}`);
  };

  scope.exportToCsv = function () {
    const processRow = function (row) {
      let finalVal = '';
      for (let j = 0; j < row.length; j += 1) {
        let innerValue = row[j]?.toString() || '';
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        }
        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) result = `"${result}"`;
        if (j > 0) finalVal += ',';
        finalVal += result;
      }
      return `${finalVal}\n`;
    };

    let csvFile = '';
    for (let i = 0; i < scope.csvData.length; i += 1) {
      csvFile += processRow(scope.csvData[i]);
    }

    const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, 'download');
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'download');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  resourceFactory.auditResource.get(
    { templateResource: 'searchtemplate' },
    function (data) {
      scope.template = data;
    }
  );

  if (!scope.searchCriteria.audit) {
    scope.searchCriteria.audit = [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ];
    scope.saveSC();
  }
  scope.formData.user = scope.searchCriteria.audit[0];
  scope.date.first = scope.searchCriteria.audit[1];
  scope.date.third = scope.searchCriteria.audit[2];
  scope.formData.status = scope.searchCriteria.audit[3];
  if (scope.searchCriteria.audit[4]) { scope.formData.action = scope.searchCriteria.audit[4]; }
  scope.formData.resourceId = scope.searchCriteria.audit[5];
  scope.date.second = scope.searchCriteria.audit[6];
  scope.date.fourth = scope.searchCriteria.audit[7];
  if (scope.searchCriteria.audit[8]) { scope.formData.entity = scope.searchCriteria.audit[8]; }
  if (scope.searchCriteria.audit[9]) { scope.formData.checkedBy = scope.searchCriteria.audit[9]; }

  const fetchFunction = function (offset, limit, callback) {
    // scope.isCollapsed = true
    scope.displayResults = true;
    // date format not used here since the underlying api does not support localization of dates
    const reqFirstDate = dateFilter(scope.date.first, 'yyyy-MM-dd');
    const reqSecondDate = dateFilter(scope.date.second, 'yyyy-MM-dd');
    const reqThirdDate = dateFilter(scope.date.third, 'yyyy-MM-dd');
    const reqFourthDate = dateFilter(scope.date.fourth, 'yyyy-MM-dd');

    const params = {};
    params.offset = offset;
    params.limit = limit;
    params.paged = true;

    if (scope.formData.user) {
      params.makerName = scope.formData.user;
      scope.searchCriteria.audit[0] = params.makerName;
    } else scope.searchCriteria.audit[0] = null;

    if (scope.date.first) {
      params.makerDateTimeFrom = reqFirstDate;
      scope.searchCriteria.audit[1] = params.makerDateTimeFrom;
    } else scope.searchCriteria.audit[1] = null;

    if (scope.date.third) {
      params.checkerDateTimeFrom = reqThirdDate;
      scope.searchCriteria.audit[2] = params.checkerDateTimeFrom;
    } else scope.searchCriteria.audit[2] = null;

    if (scope.formData.status) {
      params.processingResult = scope.formData.status;
      scope.searchCriteria.audit[3] = params.processingResult;
    } else scope.searchCriteria.audit[3] = null;

    if (scope.formData.action) {
      params.actionName = scope.formData.action;
      scope.searchCriteria.audit[4] = params.actionName;
    } else scope.searchCriteria.audit[4] = null;

    if (scope.formData.resourceId) {
      params.resourceId = scope.formData.resourceId;
      scope.searchCriteria.audit[5] = params.resourceId;
    } else scope.searchCriteria.audit[5] = null;

    if (scope.date.second) {
      params.makerDateTimeTo = reqSecondDate;
      scope.searchCriteria.audit[6] = params.makerDateTimeTo;
    } else scope.searchCriteria.audit[6] = null;

    if (scope.date.fourth) {
      params.checkerDateTimeTo = reqFourthDate;
      scope.searchCriteria.audit[7] = params.checkerDateTimeTo;
    } else scope.searchCriteria.audit[7] = null;

    if (scope.formData.entity) {
      params.entityName = scope.formData.entity;
      scope.searchCriteria.audit[8] = params.entityName;
    } else scope.searchCriteria.audit[8] = null;

    if (scope.formData.checkedBy) {
      params.checkerName = scope.formData.checkedBy;
      scope.searchCriteria.audit[9] = params.checkerName;
    } else scope.searchCriteria.audit[9] = null;

    scope.saveSC();
    resourceFactory.auditResource.search(params, function (data) {
      scope.searchData.pageItems = data.pageItems;

      if (scope.searchData.pageItems === '') scope.flag = false;
      else scope.flag = true;

      scope.row = [];
      scope.csvData = [];

      scope.row = [
        'Id',
        'Resource Id',
        'Status',
        'Office',
        'Made on',
        'Maker',
        'Checked on',
        'Checker',
        'Entity',
        'Action',
        'Client'
      ];
      scope.csvData.push(scope.row);
      for (const i in scope.searchData.pageItems) {
        scope.row = [
          scope.searchData.pageItems[i].id,
          scope.searchData.pageItems[i].resourceId,
          scope.searchData.pageItems[i].processingResult,
          scope.searchData.pageItems[i].officeName,
          dateFilter(scope.searchData.pageItems[i].madeOnDate, 'yyyy-MM-dd'),
          scope.searchData.pageItems[i].maker,
          dateFilter(
            scope.searchData.pageItems[i].checkedOnDate,
            'yyyy-MM-dd hh:mm:ss'
          ),
          scope.searchData.pageItems[i].checker,
          scope.searchData.pageItems[i].entityName,
          scope.searchData.pageItems[i].actionName,
          scope.searchData.pageItems[i].clientName
        ];
        scope.csvData.push(scope.row);
      }
      callback(data);
    });
  };

  scope.clearFilters = function () {
    scope.formData.user = null;
    scope.date.first = null;
    scope.date.third = null;
    scope.formData.status = null;
    scope.formData.action = '';
    scope.formData.resourceId = null;
    scope.date.second = null;
    scope.date.fourth = null;
    scope.formData.entity = '';
    scope.formData.checkedBy = '';
  };

  scope.searchAudit = function () {
    scope.audit = paginatorService.paginate(fetchFunction, 15);
  };

  scope.searchAudit();

  scope.handleSearchOption = () => {
    scope.isCollapsed = !scope.isCollapsed;
  };
}

AuditController.$inject = ['$scope', 'ResourceFactory', 'paginatorService', 'dateFilter', '$location'];
