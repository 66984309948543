export function SubscriberController (scope, resourceFactory, location) {
  scope.routeTo = function (id) {
    location.path(`/viewsubscriber/${id}`);
  };

  if (!scope.searchCriteria.subscribers) {
    scope.searchCriteria.subscribers = null;
    scope.saveSC();
  }
  scope.filterText = scope.searchCriteria.subscribers || '';

  scope.onFilter = function () {
    scope.searchCriteria.subscribers = scope.filterText;
    scope.saveSC();
  };

  resourceFactory.subscriberResource.get(function (data) {
    scope.subscribers = data.pageItems;
  });
}

SubscriberController.$inject = ['$scope', 'ResourceFactory', '$location'];
