export function ApproveLoanRescheduleRequestController ($scope, resourceFactory, routeParams, location, dateFilter) {
  $scope.formData = {};
  $scope.loanId = routeParams.loanId;
  $scope.requestId = routeParams.requestId;

  $scope.cancel = function () {
    location.path(`/loans/${$scope.loanId}/viewreschedulerequest/${$scope.requestId}`);
  };
  $scope.approve = function () {
    this.formData.dateFormat = $scope.df;
    this.formData.locale = $scope.optlang.code;
    this.formData.approvedOnDate = dateFilter(this.formData.approvedOnDate, $scope.df);

    resourceFactory.loanRescheduleResource.approve({ scheduleId: $scope.requestId }, this.formData, function () {
      location.path(`/viewloanaccount/${$scope.loanId}`);
    });
  };
}

ApproveLoanRescheduleRequestController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location', 'dateFilter'];
