<template>
  <main class="card">
    <section class="card-body">
      <section
        v-if="$slots.title || $slots.actions"
        id="content-title"
        class="mb-3 d-flex flex-wrap"
      >
        <h4 class="me-auto">
          <slot name="title" />
        </h4>

        <section
          v-if="$slots.actions"
          id="action"
          class="ms-auto"
        >
          <slot name="actions" />
        </section>
      </section>
      <slot />
    </section>
  </main>
</template>

<script>
export default {
  name: 'MainContentLayout'
};
</script>

<style scoped>
#action > button:not(:last-child) {
  margin-right: 5px;
}
</style>
