export const downloadHelper = (response, nameFile, type = 'application/octet-stream', responseType = 'arraybuffer') => {
  const el = document.createElement('a');
  const windowUrl = window.URL || window.webkitURL;
  const blob = new Blob([response], { type, responseType });
  const url = windowUrl.createObjectURL(blob);
  el.href = url;
  el.download = nameFile;
  el.click();
  windowUrl.revokeObjectURL(url);
};

export const viewHelper = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const isImage = (fileType) => {
  return /image\/(jpg|jpeg|png)/.test(fileType);
};

export const isPdf = (fileType) => {
  return fileType === 'application/pdf';
};

export const isFilePreviewable = (fileType) => {
  return isImage(fileType) || isPdf(fileType);
};
