export function CreateOfficeController (scope, resourceFactory, location, dateFilter) {
  scope.offices = [];
  scope.first = {};
  scope.first.date = new Date();
  scope.restrictDate = new Date();
  resourceFactory.officeResource.getAllOffices(function (data) {
    scope.offices = data;
    scope.formData = {
      parentId: scope.offices[0].id
    };
  });

  scope.minDat = function () {
    for (let i = 0; i < scope.offices.length; i++) {
      if ((scope.offices[i].id) === (scope.formData.parentId)) {
        return scope.offices[i].openingDate;
      }
    }
  };

  scope.submit = function () {
    this.formData.locale = scope.optlang.code;
    const reqDate = dateFilter(scope.first.date, scope.df);
    this.formData.dateFormat = scope.df;
    this.formData.openingDate = reqDate;
    resourceFactory.officeResource.save(this.formData, function (data) {
      location.path(`/viewoffice/${data.resourceId}`);
    });
  };
}

CreateOfficeController.$inject = ['$scope', 'ResourceFactory', '$location', 'dateFilter'];
