export function EditConfigurationController ($scope, resourceFactory, routeParams, location, dateFilter) {
  $scope.configId = routeParams.configId;
  $scope.valueDataType = '';
  resourceFactory.configurationResource.get({ id: $scope.configId }, function (data) {
    $scope.valueDataType = data.valueDataType;
    let result = {};
    const valueDataType = {
      NUMBER: v => ({ value: Number.parseInt(v, 10) }),
      DATE: (value) => ({ value, locale: 'en', dateFormat: 'yyyy-MM-dd' }),
      STRING: (value) => ({ value })
    };

    result = valueDataType[data.valueDataType](data.value);
    $scope.formData = result;
  });
  $scope.cancel = function () {
    location.path('/global');
  };

  $scope.submit = function () {
    resourceFactory.configurationResource.update({ resourceType: 'configurations', id: $scope.configId }, {
      ...this.formData,
      value: $scope.valueDataType === 'DATE' ? dateFilter(this.formData.value, 'yyyy-MM-dd') : this.formData.value
    }, function () {
      location.path('/global');
    });
  };
}

EditConfigurationController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location', 'dateFilter'];
