import { paymentRailsValueExcludes } from '@/utils/dictionaries.js';
export function CreateTransferClientLimitsController ($scope, resourceFactory, routeParams) {
  $scope.client = {};
  $scope.clients = [];
  $scope.formData = { isActive: false };
  $scope.transferTypes = ['DEBIT', 'CREDIT'];
  $scope.durationTypes = ['DAILY', 'WEEKLY', 'MONTHLY'];
  $scope.paymentTypeNames = paymentRailsValueExcludes(['ALL', 'SWIFT']);
  $scope.clientId = routeParams.id;

  resourceFactory.clientResource.get({ clientId: routeParams.id }, function (data) {
    $scope.client = data;
    $scope.clients = [{ ...data }];
    $scope.formData.clientId = data.id;
  });

  $scope.submit = function () {
    resourceFactory.transferClientLimitsResource.create($scope.formData, function () {
      history.back();
    });
  };
}

CreateTransferClientLimitsController.$inject = ['$scope', 'ResourceFactory', '$routeParams'];
