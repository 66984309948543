export function CreateRecurringPaymentController (scope, resourceFactory, routeParams, location, dateFilter) {
  scope.formData = {};
  scope.formData.active = true;
  scope.formData.paymentTimeType = 'INTERVAL';
  scope.beneficiaries = [];
  scope.savingsAccounts = [];
  scope.paymentTimeTypes = ['INTERVAL', 'SPECIFIC_DATE'];
  scope.frequencies = ['DAYS', 'WEEKS', 'MONTHS', 'YEARS'];
  scope.specificDates = [];
  scope.inputDate = { date: '', dateFormat: scope.df, locale: scope.optlang.code };
  scope.formData.startDate = new Date();
  scope.clientId = routeParams.clientId;

  resourceFactory.clientResource.getSavingAccounts({ clientId: scope.clientId, anotherresource: 'accounts' }, function (dataSavingsAccounts) {
    scope.savingsAccounts = dataSavingsAccounts.savingsAccounts?.filter(({ status: { active } }) => active);
  });

  resourceFactory.beneficiariesResource.get({ clientId: scope.clientId }, function (data) {
    scope.beneficiaries = data?.pageItems;
  });

  scope.addDate = function () {
    const isDuplicateSpecificDate = scope.specificDates.find(specificDate => specificDate.date === dateFilter(scope.inputDate.date, scope.df));
    if (
      scope.inputDate.date &&
      !isDuplicateSpecificDate
    ) {
      scope.specificDates.push({
        date: dateFilter(scope.inputDate.date, scope.df),
        dateFormat: scope.inputDate.dateFormat,
        locale: scope.inputDate.locale
      });
    }

    scope.inputDate = { date: '', dateFormat: scope.df, locale: scope.optlang.code };
  };
  scope.deleteDate = function (index) {
    scope.specificDates.splice(index, 1);
  };
  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
  scope.submit = function () {
    scope.formData.locale = scope.optlang.code;
    scope.formData.dateFormat = scope.df;
    scope.formData.startDate = dateFilter(scope.formData.startDate, scope.df);
    scope.formData.endDate = dateFilter(scope.formData.endDate, scope.df);
    scope.formData.specificDates = scope.specificDates;
    resourceFactory.recurringPaymentResource.save(scope.formData, function (data) {
      location.path(`/viewrecurringpayment/${routeParams.clientId}/${data.resourceId}`);
    }, (error) => {
      const { data: { errors } } = error;
      scope.errorMsg = errors[0].defaultUserMessage;
    });
  };
}

CreateRecurringPaymentController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location', 'dateFilter'];
