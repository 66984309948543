<template>
  <div class="form-group row pb-3">
    <label
      v-if="label"
      :for="id"
      class="control-label text-end col-sm-4"
    >
      {{ label }}
      <span
        v-if="required"
        class="required"
      >
        *
      </span>
    </label>
    <div class="col-sm-8">
      <input
        :id="id"
        v-facade="mask"
        :placeholder="mask"
        :value="value"
        :name="id"
        type="text"
        :class="['form-control', {'is-invalid': errorMessage}]"
        :disabled="disabled"
        @input="updateInput"
      >
    </div>
    <div
      v-if="errorMessage"
      class="offset-sm-4 text-danger"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { facade } from 'vue-input-facade';
import { masksToHumanReadableMessage } from '@/utils/zipcodes';
import formWrapperRegister from '@/components/shared/form-input/mixin.js';

export default {
  directives: { facade },
  mixins: [formWrapperRegister],
  props: {
    id: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String],
      default: null || ''
    },
    required: {
      type: [Boolean, Object],
      default: false || (() => {})
    },
    label: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    regex: {
      type: Object,
      default: () => null
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      fallBackRegex: '[a-zA-Z0-9]*' // can be absent as some countries do not have postal codes
    };
  },
  computed: {
    regexWithDefault () {
      return new RegExp((this.regex?.postal || this.fallBackRegex));
    },
    mask () {
      return this.regex?.mask || '';
    }
  },
  created () {
    this.localRule = [
      value => {
        if (!value) return false;
        const errorMessage = this.regex?.postal
          ? this.$t('label.error.zipCodeNoMatch', { format: masksToHumanReadableMessage(this.regex) })
          : this.$t('label.error.patternNotMatch');
        return value.match(this.regexWithDefault) || errorMessage;
      }
    ];
  },
  methods: {
    updateInput (e) {
      this.validatorRunner();
      this.$emit('input', e.target.value);
    }
  }
};
</script>
