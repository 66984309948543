export function CreateAMLRuleGroupController (scope, resourceFactory, location) {
  scope.currencies = null;
  scope.errorMsg = '';

  scope.loadCurrencies = function () {
    resourceFactory.currenciesResource.get(
      { fields: 'selectedCurrencyOptions' },
      function (res) {
        scope.currencies = res.selectedCurrencyOptions;
      }
    );
  };

  scope.loadCurrencies();

  scope.submit = function () {
    // let validFrom = null
    // let validTo = null
    // let validFromDate
    // let validToDate

    // if (scope.formData.validFrom) {
    //   validFromDate = new Date(scope.formData.validFrom)
    //   validFrom = validFromDate.toISOString()
    // }
    // if (scope.formData.validTo) {
    //   validToDate = new Date(scope.formData.validTo)
    //   validTo = validToDate.toISOString()
    // }

    resourceFactory.amlRulesResource.save(scope.formData, handleResponseSuccess, handleResponseError);

    function handleResponseSuccess (res) {
      const { resourceId } = res;
      location.path(`/payments/aml/rulegroups/${resourceId}`);
    }

    function handleResponseError (error) {
      const { data } = error;
      scope.errorMsg = data.errors[0].defaultUserMessage;
    }
  };

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
}

CreateAMLRuleGroupController.$inject = ['$scope', 'ResourceFactory', '$location'];
