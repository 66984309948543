<template>
  <div class="card card-body">
    <div>
      <div class="toolbar">
        <h4>{{ $t('label.head.fundTransfers') }}</h4>
      </div>
      <form-wrapper
        class="form-horizontal pt-5"
        @submit="fundTransfer"
      >
        <div class="col-12 col-md-6">
          <input-select
            id="transferType"
            v-model="transferType"
            :label="$t('label.input.transferType')"
            :options="transferTypes"
            required
          />
          <input-text
            id="transferAmount"
            v-model="formData.amount"
            :rules="transferAmountValidation"
            :label="$t('label.input.amount')"
            required
          />
          <input-text
            id="transferDescription"
            v-model="formData.reference"
            type="textarea"
            :label="$t('label.input.reference')"
            :rows="2"
            required
          />
        </div>
        <div class="offset-3">
          <button
            id="cancel"
            class="btn btn-default"
            @click="redirectBack"
          >
            {{ $t('label.button.cancel') }}
          </button>
          <button
            id="save"
            class="btn btn-primary"
            type="submit"
          >
            {{ $t('label.button.submit') }}
          </button>
        </div>
      </form-wrapper>
    </div>
  </div>
</template>

<script>
import apiService from '@/services/apiService';
import { FormWrapper, InputText, InputSelect } from '@/components/shared/form-input/index';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { mapActions } from 'pinia';
import { decimalNumeric } from '@/components/shared/form-input/rules';

export default {
  components: { FormWrapper, InputText, InputSelect },
  data () {
    return {
      formData: {},
      transferType: 'ALLOCATE_FUNDS',
      transferTypes: ['ALLOCATE_FUNDS', 'DEALLOCATE_FUNDS'],
      transferAmountValidation: [amount => decimalNumeric(amount)],
      primaryAccountId: this.$route.query.primaryAccountId,
      subAccountId: this.$route.params.subAccountId,
      parentClientId: this.$route.params.parentClientId,
      clientId: this.$route.params.clientId
    };
  },
  created () {
    this.setBreadcrumbs([
      {
        text: this.$t('label.anchor.viewSubAccount'),
        href: `#/clients/${this.parentClientId}/associated-client/${this.clientId}/saving-accounts/${this.subAccountId}`
      },
      { text: this.$t('label.anchor.fundTransfer') }
    ]);
  },
  methods: {
    ...mapActions(useBreadcrumbsStore, ['setBreadcrumbs']),
    redirectBack () {
      location.assign(`#/clients/${this.parentClientId}/associated-client/${this.clientId}/saving-accounts/${this.subAccountId}`);
    },
    async fundTransfer () {
      try {
        const requestData = [{ subAccountId: this.subAccountId, ...this.formData }];
        if (this.transferType === 'ALLOCATE_FUNDS') {
          await apiService.manageEmployeeAccounts.fundAllocate(this.primaryAccountId, requestData);
        }
        if (this.transferType === 'DEALLOCATE_FUNDS') {
          await apiService.manageEmployeeAccounts.fundDeallocate(this.primaryAccountId, requestData);
        }
        this.redirectBack();
      } catch (error) {
        this.$notify.error(error);
      }
    }
  }
};
</script>
