export function CreateProvisoningEntriesController ($scope, resourceFactory, location, dateFilter, translate) {
  $scope.template = [];
  $scope.formData = {};
  $scope.translate = translate;
  $scope.submitteddate = new Date();

  $scope.submit = function () {
    this.formData.locale = $scope.optlang.code;
    this.formData.dateFormat = $scope.df;
    this.formData.date = dateFilter(this.submitteddate, $scope.df);
    resourceFactory.provisioningentries.post(this.formData, function (data) {
      location.path(`/viewprovisioningentry/${data.resourceId}`);
    });
  };
}

CreateProvisoningEntriesController.$inject = ['$scope', 'ResourceFactory', '$location', 'dateFilter', '$translate'];
