<template>
  <div class="col-5 mt-4">
    <form-wrapper
      @submit="submit"
    >
      <div
        v-for="(formDataInputTextField, index) in formDataInputTextFields"
        :key="index"
      >
        <input-text
          :id="formDataInputTextField.key"
          v-model="formData[formDataInputTextField.key]"
          :required="formDataInputTextField.required"
          :type="formDataInputTextField.type"
          :disabled="formDataInputTextField.disabled"
          :label="$t(`label.anchor.${formDataInputTextField.label}`)"
        />
      </div>
      <input-check-box
        id="isActive"
        v-model="formData.isActive"
        :label="$t('label.anchor.isActive')"
      />
      <input-check-box
        id="isMasked"
        v-model="formData.isMasked"
        :label="$t('label.anchor.isMasked')"
      />
      <div class="w-100 mt-3 d-flex justify-content-end">
        <router-link :to="{ name: 'ViewCodeSystem', params: { codeId } }">
          <button
            class="btn btn-danger mx-1"
          >
            {{ $t('label.button.cancel') }}
          </button>
        </router-link>
        <button
          type="submit"
          class="btn btn-primary mx-1"
        >
          {{ $t('label.button.save') }}
        </button>
      </div>
    </form-wrapper>
  </div>
</template>

<script setup>
import router from '@/router';
import { InputText, InputCheckBox, FormWrapper } from '@/components/shared/form-input';
import { computed, reactive, toRefs } from 'vue';
import { get } from '@vueuse/core';

const { codeId } = router.currentRoute.params;
const props = defineProps({
  codeValueData: {
    type: Object,
    default: () => ({})
  }
});
const emits = defineEmits(['submit']);
const { codeValueId } = router.currentRoute.params;

const { name, position, identifier, description, active: isActive, systemDefined, isMasked } = toRefs(props.codeValueData);
const formCodeValueData = codeValueId ? { name, position, identifier, description, isActive, isMasked } : {};

const formData = reactive({
  isActive: true,
  isMasked: false,
  ...formCodeValueData
});

const isEditing = computed(() => {
  return get(systemDefined);
});

const formDataInputTextFields = computed(() => {
  return [
    { key: 'name', label: 'codeValueName', type: 'text', required: true, disabled: get(isEditing) },
    { key: 'position', label: 'position', type: 'number', required: false, disabled: false },
    { key: 'identifier', label: 'identifier', type: 'text', required: false, disabled: false },
    { key: 'description', label: 'description', type: 'textarea', required: false, disabled: get(isEditing) }
  ];
});

const submit = async () => {
  emits('submit', get(formData));
};
</script>
