export function CreateOperationalAccountMappingController ($scope, resourceFactory, location) {
  $scope.formData = {};

  resourceFactory.operationalAccountMappingTemplateResource.get({}, function (data) {
    $scope.allActivityTypes = data.allActivityTypes;
  });

  $scope.submit = function () {
    resourceFactory.operationalAccountMappingResource.create(this.formData, function (data) {
      location.path(`/viewoperationaccountmapping/${data.resourceIdentifier}`);
    });
  };
}

CreateOperationalAccountMappingController.$inject = ['$scope', 'ResourceFactory', '$location'];
