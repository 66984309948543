const arrayToTextWithAnd = (arr = []) => {
  if (arr.length === 0) return '';
  if (arr.length === 1) return arr[0];
  return `${arr.slice(0, -1).join(', ')} and ${arr.slice(-1)}`;
};

const emptyStringToUndefined = (string = '') => {
  return !string.trim() ? undefined : string.trim();
};

export { arrayToTextWithAnd, emptyStringToUndefined };
