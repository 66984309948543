export function ViewTwoFactorConfigController ($scope, resourceFactory) {
  const smsConfigs = ['otp-delivery-sms-enable', 'otp-delivery-sms-provider', 'otp-delivery-sms-text'];
  const emailConfigs = ['otp-delivery-email-enable', 'otp-delivery-email-subject', 'otp-delivery-email-body'];
  const tokenConfigs = ['otp-token-length', 'access-token-live-time-extended', 'otp-token-live-time', 'access-token-live-time'];
  $scope.configurations = [];
  $scope.smsConfig = [];
  $scope.emailConfig = [];
  $scope.tokenConfig = [];
  $scope.tabs = {};
  $scope.disabledTwoFactor = false;

  resourceFactory.twoFactorConfigResource.getAllConfigs(function (data) {
    for (let i = 0; i < smsConfigs.length; i++) {
      $scope.smsConfig.push({ name: smsConfigs[i], value: data[smsConfigs[i]] });
    }
    for (let i = 0; i < emailConfigs.length; i++) {
      $scope.emailConfig.push({ name: emailConfigs[i], value: data[emailConfigs[i]] });
    }
    for (let i = 0; i < tokenConfigs.length; i++) {
      $scope.tokenConfig.push({ name: tokenConfigs[i], value: data[tokenConfigs[i]] });
    }

    $scope.tabs.sms = $scope.smsConfig;
    $scope.tabs.email = $scope.emailConfig;
    $scope.tabs.token = $scope.tokenConfig;

    for (const i in data.toJSON()) {
      $scope.configurations.push({ name: i, value: data[i] });
    }
  }, function () {
    $scope.disabledTwoFactor = true;
  });
}

ViewTwoFactorConfigController.$inject = ['$scope', 'ResourceFactory'];
