<template>
  <div>
    <div v-has-permission="'READ_SWIFT_MANUAL_DATA'">
      <b-table
        :items="rows"
        :fields="fields"
        :responsive="true"
        :show-empty="true"
        empty-text="No Records"
      >
        <template #cell(imad)="data">
          <b-form-input
            id="imad"
            v-model="data.item.imad"
            name="imad"
            type="text"
          />
        </template>
        <template #cell(omad)="data">
          <b-form-input
            id="omad"
            v-model="data.item.omad"
            name="omad"
            type="text"
          />
        </template>
        <template #cell(transactionReceiptId)="data">
          <b-form-input
            id="transactionReceiptId"
            v-model="data.item.transactionReceiptId"
            name="transactionReceiptId"
            type="text"
          />
        </template>
        <template #cell(Actions)="data">
          <button
            v-has-permission="'UPDATE_SWIFT_MANUAL_DATA'"
            class="btn btn-info d-flex flex-nowrap align-items-center"
            @click="updateData(data.item)"
          >
            <i class="fa fa-edit me-2" />Update
          </button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import apiService from '@/services/apiService';
export default {
  name: 'SwiftManual',
  data () {
    return {
      fields: [
        { key: 'externalId', label: 'External Id' },
        { key: 'amount', label: 'Amount', sortable: true },
        { key: 'beneficiaryName', label: 'Beneficiary Name' },
        { key: 'beneficiaryAddress', label: 'Beneficiary Address' },
        { key: 'createdAt', label: 'Created At' },
        { key: 'transactionReceiptId', label: 'Transaction Receipt ID' },
        { key: 'imad', label: 'IMAD' },
        { key: 'omad', label: 'OMAD' },
        { key: 'Actions', label: 'Actions' }
      ],
      rows: []
    };
  },
  mounted () {
    this.getSwiftList();
  },
  methods: {
    async getSwiftList () {
      try {
        const data = await apiService.swiftManualService.get();
        this.rows = data.data;
      } catch (e) {
        this.$notify.error(e);
      }
    },
    async updateData (item) {
      const requestBody = { PK: item.PK };
      if (item.transactionReceiptId?.length > 0) {
        requestBody.transactionReceiptId = item.transactionReceiptId;
      }
      if (item.imad?.length > 0) {
        requestBody.imad = item.imad;
      }
      if (item.omad?.length > 0) {
        requestBody.omad = item.omad;
      }
      await apiService.swiftManualService.put(requestBody);
      this.getSwiftList();
    }
  }
};
</script>
