<template>
  <div v-if="referenceItems?.length">
    <div
      v-for="(reference, index) in referenceItems"
      :key="`ref-${index}`"
    >
      {{ reference }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TxReferenceTd',
  props: {
    referenceItems: {
      type: Array,
      required: true
    }
  }
};
</script>
