<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.heading.clients') }}
    </template>

    <template #actions>
      <router-link :to="{path: '/organization/bulk-import/clients', name: 'BulkImportClients'}">
        <button
          v-has-permission="'CREATE_CLIENT'"
          class="btn btn-outline-primary me-2"
        >
          <span class="fas fa-upload" />
          {{ $t('label.button.importClients') }}
        </button>
      </router-link>
      <button
        v-has-permission="'CREATE_CLIENT'"
        class="btn btn-primary"
        @click="goto('#/createclient')"
      >
        <span class="fas fa-plus" />
        {{ $t('label.button.createClient') }}
      </button>
    </template>
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <form
          class="form-horizontal"
          @submit.prevent="searchClient('0')"
        >
          <div class="row">
            <div
              id="search-bar"
              class="col-md-8"
            >
              <div class="input-group">
                <input
                  id="searchText"
                  v-model="searchText"
                  :placeholder="$t('label.input.searchByNameAccNo')"
                  class="form-control"
                  type="search"
                  minlength="3"
                >
                <button
                  class="btn btn-primary"
                  type="submit"
                >
                  <span class="fas fa-search" />
                </button>
              </div>
            </div>
            <div class="col-md-4 d-flex align-items-center">
              <div class="px-3">
                <label for="status">{{ $t('label.input.status') }}</label>
              </div>
              <div>
                <b-form-select
                  id="statusFilter"
                  v-model="clientStatus"
                  class="form-control form-select"
                  :options="clientStatusOption"
                  @change="searchClient('0')"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <hr>
    <clients-List
      ref="clientList"
      :items="clients"
      :limit="clientsPerPage"
      @pagination-update="pageChanged"
      @sort-column="sortColumn"
      @entries-per-page="updateEntriesPerPage"
      @click-row="(item)=>goto(`#/viewclient/${item.id}`)"
    />
  </main-content-layout>
</template>

<script>
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import ClientsList from '@/components/clients/ClientsList.vue';
import apiService from '@/services/apiService.js';
import _ from 'underscore';

export default {
  components: {
    MainContentLayout,
    ClientsList
  },
  data () {
    return {
      clients: [],
      searchText: '',
      clientsPerPage: 15,
      orderBy: 'id',
      sortOrder: 'DESC',
      offset: 0,
      clientStatus: 'ALL',
      clientStatusOption: ['ALL', 'ACTIVE', 'PENDING', 'INVALID', 'CLOSED', 'REJECTED',
        'WITHDRAWN', 'TRANSFER_IN_PROGRESS', 'TRANSFER_ON_HOLD']
    };
  },
  created () {
    this.searchText = this.$route.query?.search || '';
    this.clientStatus = this.$route.query?.status || 'ALL';
    this.getClients();
  },
  methods: {
    async searchClient (offset) {
      this.$refs.clientList.resetPage();
      this.offset = offset;
      let query = this.searchText
        ? {
            search: this.searchText.replace(/(^"|"$)/g, '')
          }
        : {};

      query = { ...query, status: this.clientStatus };

      if (_.isEqual(query, this.$route.query)) return;

      this.$router.push({ name: 'ClientsPage', query });
      this.getClients();
    },
    async getClients () {
      const data = await apiService.clients.getAll({
        offset: this.offset,
        limit: this.clientsPerPage,
        orderBy: this.orderBy,
        sortOrder: this.sortOrder,
        sqlSearch: this.searchText,
        clientStatus: this.clientStatus
      });
      const { data: { pageItems } } = data;
      this.clients = pageItems;
    },
    goto (path) {
      location.assign(path);
    },
    updateEntriesPerPage (params) {
      this.clientsPerPage = params;
      this.getClients();
    },
    pageChanged (offset) {
      this.offset = offset;
      this.getClients();
    },
    sortColumn (column) {
      const orderBy = () => {
        if (column.orderBy === 'accountNo') return 'account_no';
        if (column.orderBy === 'status') return 'status_enum';
        if (column.orderBy === 'submittedDate') return 'submittedon_date';
        return column.orderBy;
      };
      this.orderBy = orderBy();
      this.sortOrder = column.sortOrder;
      this.getClients();
    }

  }
};
</script>

<style lang="scss" scoped>
@import "/src/angular-app/styles-dev/bootstrap/main.scss";

@include media-breakpoint-down(md) {
  #search-bar {
    margin-bottom: 0.5rem;
  }
}
</style>
