export function AddFinancialMappingController ($scope, resourceFactory, location) {
  $scope.formData = {};

  resourceFactory.officeToGLAccountMappingResource.get({ mappingId: 'template' }, function (data) {
    $scope.formData.financialActivityId = 100;
    $scope.glAccountOptions = data.glAccountOptions;
    $scope.financialActivityOptions = data.financialActivityOptions;
    $scope.accountOptions = $scope.glAccountOptions.assetAccountOptions;
  });

  $scope.updateActivityOptions = function (activityId) {
    if (activityId === 100 || activityId === 101 || activityId === 102 || activityId === 103) {
      $scope.accountOptions = $scope.glAccountOptions.assetAccountOptions;
    } else if (activityId === 200 || activityId === 201) {
      $scope.accountOptions = $scope.glAccountOptions.liabilityAccountOptions;
    } else if (activityId === 300) {
      $scope.accountOptions = $scope.glAccountOptions.equityAccountOptions;
    }
  };

  $scope.submit = function () {
    resourceFactory.officeToGLAccountMappingResource.create(this.formData, function (data) {
      location.path(`/viewfinancialactivitymapping/${data.resourceId}`);
    });
  };
}

AddFinancialMappingController.$inject = ['$scope', 'ResourceFactory', '$location'];
