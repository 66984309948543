import { defineStore } from 'pinia';
import apiService from '@/services/apiService';

const defaultState = {
  codeResources: [],
  countries: [],
  notifyMessage: {
    message: [],
    type: ''
  },
  loading: false,
  templates: [],
  dateFormat: 'DD MMMM YYYY'
};

export const useSharedStore = defineStore('shared', {
  state: () => ({ ...defaultState }),
  actions: {
    setCodeResources (value) {
      this.codeResources = value;
    },
    setCountries (value) {
      this.countries = value;
    },
    setNotifyMessage ({ type, message }) {
      if (type === 'error') {
        message.forEach((error, index) => {
          if (error.defaultUserMessage === this.notifyMessage.message[index]) this.resetNotificationMessage();
          this.notifyMessage.message.push(error.defaultUserMessage);
          this.notifyMessage.type = type;
        });
      } else {
        this.resetNotificationMessage();
        this.notifyMessage.message.push(message);
        this.notifyMessage.type = type;
      }
    },
    removeMessage (index) {
      this.notifyMessage.message.splice(index, 1);
    },
    resetNotificationMessage () {
      this.notifyMessage = { message: [], type: '' };
    },
    setLoading (value) {
      this.loading = value;
    },
    async getCountries () {
      try {
        const { data: codes } = await apiService.codeResources.getAllCodes();
        this.setCodeResources(codes);
        const countryCodeId = codes.find(code => code.name === 'COUNTRY').id;
        const { data: countries } = await apiService.codeValueResource.getAllCodeValues(countryCodeId);
        this.setCountries(countries);
      } catch (error) { console.error({ error }); }
    },
    async getAddressTemplate () {
      const address = this.templates?.address;
      if (!address) {
        try {
          const { data } = await apiService.clients.addressTemplate();
          this.templates.address = data;
        } catch (error) { console.error({ error }); }
      }
      return this.templates.address;
    }
  }
});
