<template>
  <div>
    <div>
      <div class="row mb-2">
        <div class="col-md-3">
          <span>Date From:</span>
          <b-form-datepicker
            v-model="filterData.fromDate"
            name="fromDate"
            alt="Screened Date From"
            locale="en"
          />
        </div>
        <div class="col-md-3">
          <span>Date To:</span>
          <b-form-datepicker
            v-model="filterData.toDate"
            name="toDate"
            alt="Screened Date To"
            locale="en"
          />
        </div>
        <div class="col-md-2">
          <span>Transfer details type:</span>
          <b-form-select
            v-model="filterData.filterByOption"
            class="form-control form-select"
            name="filterByOption"
            :options="txFilterOptions"
          />
        </div>
        <div class="col-md-2">
          <span>Transfer details value:</span>
          <b-form-input
            id="dropListValue"
            v-model="filterData.optionValue"
            name="optionValue"
            type="text"
          />
        </div>
        <div class="col-md-2">
          <span>Amount >= :</span>
          <b-form-input
            id="amountGreaterOrEqual"
            v-model="filterData.amountGreaterOrEqual"
            name="amountGreaterOrEqual"
            type="number"
          />
        </div>
      </div>

      <div class="row mb-2 align-items-end">
        <div class="col-md-2">
          <span>Payment rail:</span>
          <b-form-select
            v-model="filterData.filterByPaymentRail"
            class="form-control form-select"
            name="filterByPaymentRail"
            :options="paymentRails"
          />
        </div>
        <div class="col-md-2">
          <span>Direction:</span>
          <b-form-select
            v-model="filterData.filterByDirection"
            class="form-control form-select"
            name="filterByDirection"
            :options="transferDirections"
          />
        </div>
        <div class="col-md-2">
          <span>Payment type:</span>
          <b-form-select
            v-model="filterData.filterByTransactionType"
            class="form-control form-select"
            name="filterByTransactionType"
            :options="paymentTypes"
          />
        </div>
        <div class="col-md-2">
          <span>Screening state:</span>
          <b-form-select
            v-model="filterData.state"
            class="form-control form-select"
            name="state"
            :options="screeningStates"
          />
        </div>
        <div class="col-md-2 mt-4 gap-2 d-flex">
          <button
            id="clearBtn"
            class="btn btn-secondary"
            @click="clearFilter"
          >
            Clear
          </button>
          <button
            id="saveBtn"
            class="btn btn-primary"
            @click="applyFilter"
          >
            Search
          </button>
        </div>
      </div>
    </div>

    <data-grid
      ref="table"
      :items="items"
      :limit="itemPerPage"
      :fields="fields"
      :total-records="totalRows"
      hide-entries-per-page
      @pagination-update="getAmlHistoryList"
      @update-entries-per-page="updateEntriesPerPage"
    >
      <template #originatorRouteCode="data">
        <TxScreeningProfileTd :profile="data.item.originator" />
      </template>
      <template #recipientRouteCode="data">
        <TxScreeningProfileTd :profile="data.item.recipient" />
      </template>
      <template #Direction="data">
        {{ data.item.transfer.inOrOut === 'IN' ? 'INCOMING' : 'OUTGOING' }}
      </template>
      <template #Reference="data">
        <TxReferenceTd :reference-items="getReference(data)" />
      </template>
      <template #stateChanges="{item}">
        {{ item.stateChanges ? item.stateChanges[0]?.updatedBy?.username : '' }}
      </template>
      <template #Actions="data">
        <button
          class="btn btn-info d-flex flex-nowrap align-items-center"
          @click="$emit('showDetails', data.item)"
        >
          <i class="fa fa-search-plus me-2" />Details
        </button>
      </template>
    </data-grid>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import apiService from '@/services/apiService';
import { dateStringToFormat } from '@/helper/dateHelper';
import TxScreeningProfileTd from '@/components/transaction-monitoring/custom/TxScreeningProfileTd.vue';
import TxReferenceTd from '@/components/transaction-monitoring/custom/TxReferenceTd.vue';
import { amlScreeningResultAdapter } from '@/services/adapters/transaction-monitoring/amlScreeningResultAdapter';
import { transferDirections, paymentRails, txFilterOptions, screeningStates, paymentTypes } from '@/utils/dictionaries';
import { useTxMonitoringStore } from '@/stores/txmonitoring';
import DataGrid from '@/components/shared/data-grid/index.vue';

export default {
  name: 'TxHistory',
  components: {
    TxReferenceTd,
    TxScreeningProfileTd,
    DataGrid
  },
  data () {
    return {
      filterData: {},
      itemPerPage: 15,
      currentPage: 1,
      offset: 0,
      totalRows: 0,
      items: [],
      fields: [
        { key: 'dateScreened', label: 'Screened Date' },
        { key: 'transfer.id', label: 'Transfer ID' },
        { key: 'transfer.amount', label: 'Amount' },
        { key: 'originatorRouteCode', label: 'Originator bank routing code / account / name' },
        { key: 'recipientRouteCode', label: 'Recipient bank routing code / account / name' },
        { key: 'Direction', label: 'Direction' },
        { key: 'transfer.type', label: 'Type' },
        { key: 'transfer.paymentType', label: 'Payment Type' },
        { key: 'Reference', label: 'Reference' },
        { key: 'state', label: 'Status', tdClass: 'statusCellClass' },
        { key: 'stateChanges', label: 'Reviewer' },
        { key: 'verify.madeBy.username', label: 'Verifier' },
        { key: 'qc.madeBy.username', label: 'QC' },
        { key: 'Actions', label: 'Actions' }
      ],
      transferDirections,
      paymentRails,
      txFilterOptions,
      screeningStates,
      paymentTypes
    };
  },
  computed: {
    ...mapState(useTxMonitoringStore, ['needsToReloadList'])
  },
  watch: {
    currentPage () {
      this.getAmlHistoryList();
    },
    needsToReloadList (val) {
      if (val) {
        this.getAmlHistoryList();
        this.setNeedsToReloadList(false);
      }
    }
  },
  mounted () {
    this.setDefaultFilter();
    this.getAmlHistoryList();
  },
  methods: {
    ...mapActions(useTxMonitoringStore, ['setNeedsToReloadList']),
    getReference (reference) {
      return reference.item.transfer.reference || [];
    },
    setDefaultFilter () {
      this.filterData = {
        toDate: dateStringToFormat(new Date(), 'YYYY-MM-DD'),
        fromDate: dateStringToFormat(new Date().setDate(new Date().getDate() - 1), 'YYYY-MM-DD'),
        optionValue: '',
        filterByOption: 'TRANSFER_ID',
        filterByDirection: 'ALL',
        filterByPaymentRail: 'ALL',
        filterByTransactionType: 'ALL',
        amountGreaterOrEqual: '',
        state: ''
      };
    },
    updateEntriesPerPage (params) {
      this.itemPerPage = params;
      this.getAmlHistoryList();
    },
    async getAmlHistoryList (offset) {
      try {
        const defaultState = screeningStates.map(state => state.value).filter(Boolean);

        const { data: { pageItems: amlScreeningResult, totalFilteredRecords: totalRows } } = await apiService.amlScreeningResult.getAll({
          state: this.filterData.state || defaultState,
          offset: offset || this.offset,
          sortOrder: 'DESC',
          limit: this.itemPerPage,
          toDate: this.filterData.toDate,
          fromDate: this.filterData.fromDate,
          optionValue: this.filterData.optionValue,
          filterByOption: this.filterData.filterByOption,
          filterByDirection: this.filterData.filterByDirection,
          filterByPaymentRail: this.filterData.filterByPaymentRail,
          filterByTransactionType: this.filterData.filterByTransactionType,
          amount: this.filterData.amountGreaterOrEqual
        });
        this.totalRows = totalRows;
        this.items = amlScreeningResultAdapter(amlScreeningResult);
      } catch (e) {
        this.$notify.error(e);
      }
    },
    clearFilter () {
      this.setDefaultFilter();
      this.applyFilter();
    },
    applyFilter () {
      this.getAmlHistoryList();
    },
    statusCellClass (value, key, item) {
      switch (item.state) {
        case 'GO':
        case 'HOLD_GO':
        case 'OFAC_APPROVE':
          return 'table-success';

        case 'STOP':
        case 'HOLD_STOP':
        case 'OFAC_REJECT':
          return 'table-danger';

        default:
          return '';
      }
    }
  }
};
</script>
