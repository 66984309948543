import { findRecipientAndOriginator } from './findRecipientAndOriginator';

export const transferResponseAdapter = (data) => {
  let adaptedData = data;
  adaptedData = adaptedData.map(function (data) {
    const { recipient, originator, displayAmount } = findRecipientAndOriginator(data);
    return {
      ...data,
      recipient,
      originator,
      displayAmount
    };
  });

  return adaptedData;
};
