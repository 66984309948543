export function EditCashierController (scope, routeParams, resourceFactory, location, dateFilter) {
  scope.formData = {};
  scope.cashierOption = [];
  resourceFactory.tellerCashierResource.getCashier({ tellerId: routeParams.tellerId, cashierId: routeParams.id }, function (data) {
    scope.cashier = data;
    scope.formData.description = scope.cashier.description;
    scope.formData.startDate = scope.cashier.startDate;
    scope.formData.endDate = scope.cashier.endDate;
    const startDate = dateFilter(scope.cashier.startDate, scope.df);
    scope.formData.startDate = new Date(startDate);
    const endDate = dateFilter(scope.cashier.endDate, scope.df);
    scope.formData.endDate = new Date(endDate);
    scope.formData.staffId = scope.cashier.staffId;
    scope.formData.isFullDay = scope.cashier.isFullDay;
    if (!scope.formData.isFullDay) {
      const startTime = scope.cashier.startTime;
      const endTime = scope.cashier.endTime;
      const timeStampForStartTime = startTime.split(':');
      const timeStampForEndTime = endTime.split(':');
      scope.formData.hourStartTime = Number(timeStampForStartTime[0]);
      scope.formData.minStartTime = Number(timeStampForStartTime[1]);
      scope.formData.hourEndTime = Number(timeStampForEndTime[0]);
      scope.formData.minEndTime = Number(timeStampForEndTime[1]);
    }
  });

  scope.setChoice = function () {
    if (this.formData.isFullDay) {
      scope.choice = 1;
    } else if (!this.formData.isFullDay) {
      scope.choice = 0;
    }
  };

  resourceFactory.tellerCashierTemplateResource.get({ tellerId: routeParams.tellerId }, function (data) {
    scope.office = data.officeName;
    scope.staffs = data.staffOptions;
    /* $scope.formData.staffId = scope.staffs.id; */
  });

  scope.submit = function () {
    this.formData.locale = scope.optlang.code;
    const reqDate = dateFilter(scope.formData.startDate, scope.df);
    const endDate = dateFilter(scope.formData.endDate, scope.df);
    this.formData.dateFormat = scope.df;
    this.formData.startDate = reqDate;
    this.formData.endDate = endDate;
    resourceFactory.tellerCashierResource.update({ tellerId: routeParams.tellerId, cashierId: routeParams.id }, this.formData, function () {
      location.path(`/tellers/${scope.cashier.tellerId}/cashiers`);
    });
  };
}

EditCashierController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location', 'dateFilter'];
