export function CreatePeriodLimitRuleController (scope, routeParams, resourceFactory, location) {
  const ruleId = routeParams.ruleGroupId;
  scope.ruleGroup = {};
  scope.formData = {};
  scope.errorMsg = '';

  scope.selectedLegalForm = '';
  scope.classifications = [];
  resourceFactory.transferLimitsTemplate.get(function (data) {
    scope.clientLegalFormOptions = data.clientLegalFormOptions;
    scope.classifications = data.clientClassificationOptions;
  });

  scope.updateLegalForm = function () {
    if (!scope.selectedLegalForm) {
      scope.clientClassificationId = null;
    }

    scope.clientClassificationsOptions = scope.classifications.filter(function (data) {
      return scope.selectedlegalForm === 'legalFormType.person'
        ? data.codeName === 'ClientClassification-PERSON'
        : data.codeName === 'ClientClassification-ENTITY';
    });
  };

  resourceFactory.paymentTypeResource.getAll(function (data) {
    scope.paymentTypes = [{ id: null, name: 'ALL' }, ...data.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    })];
  }, handleResponseError);

  function loadData () {
    const params = { ruleId, fields: 'id,name' };

    resourceFactory.amlRulesResource.get(
      params,
      scope.formData,
      function (res) {
        scope.ruleGroup = res;
      },
      handleResponseError
    );
  }

  loadData();

  scope.submit = function () {
    const params = {
      ruleId,
      action: 'periodlimitrule'
    };

    const data = {
      ...scope.formData,
      clientClassificationId: scope.formData.clientClassificationId,
      paymentTypeId: scope.formData.paymentTypeId,
      active: false
    };

    if (scope.formData.filterByClient !== 'OTHER') data.filterByCounterparty = undefined;

    resourceFactory.amlRulesResource.save(
      params,
      data,
      function (res) {
        const { resourceId } = res;
        location.path(`/payments/aml/rulegroups/${ruleId}/periodlimitrules/${resourceId}`);
      },
      handleResponseError
    );
  };

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
}

CreatePeriodLimitRuleController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
