import _ from 'underscore';

export function BulkLoanReassignmentController (scope, resourceFactory, route, dateFilter) {
  scope.offices = [];
  scope.accounts = {};
  scope.officeIdTemp = {};
  scope.first = {};
  scope.toOfficers = [];
  scope.first.date = new Date();
  resourceFactory.officeResource.getAllOffices(function (data) {
    scope.offices = data;
  });
  scope.getOfficers = function () {
    scope.officerChoice = true;
    resourceFactory.loanReassignmentResource.get({ templateSource: 'template', officeId: scope.officeIdTemp }, function (data) {
      scope.officers = data.loanOfficerOptions;
    });
  };

  scope.getOfficerClients = function () {
    let index;
    const toOfficers = angular.copy(scope.officers);

    for (const i in toOfficers) {
      if (toOfficers[i].id === this.formData.fromLoanOfficerId) {
        index = i;
      }
    }
    toOfficers.splice(index, 1);
    scope.toOfficers = toOfficers;
    resourceFactory.loanReassignmentResource.get({ templateSource: 'template', officeId: scope.officeIdTemp, fromLoanOfficerId: scope.formData.fromLoanOfficerId }, function (data) {
      scope.clients = data.accountSummaryCollection.clients;
      scope.groups = data.accountSummaryCollection.groups;
    });
  };

  scope.submit = function () {
    const reqDate = dateFilter(scope.first.date, scope.df);
    const loans = [];
    _.each(scope.accounts, function (value, key) {
      if (value === true) {
        loans.push(key);
      }
    });
    this.formData.assignmentDate = reqDate;
    this.formData.dateFormat = scope.df;
    this.formData.locale = scope.optlang.code;
    this.formData.loans = loans;
    resourceFactory.loanReassignmentResource.save(this.formData, function () {
      route.reload();
    });
  };
}

BulkLoanReassignmentController.$inject = ['$scope', 'ResourceFactory', '$route', 'dateFilter'];
