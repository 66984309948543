export function FormValidateDirective ($compile) {
  return {
    restrict: 'E',
    require: '?ngmodel',
    link (scope, elm, attr) {
      scope.formNameAttribute = attr.valattributeform;
      scope.inputAttributeName = attr.valattribute;
      const template = `
        <span ng-show="${scope.formNameAttribute}.${scope.inputAttributeName}.$invalid">
          <small class="error" ng-show="(${scope.formNameAttribute}.${scope.inputAttributeName}.$error.req || rc.${scope.formNameAttribute}.attempted || ${scope.formNameAttribute}.$submitted) && (!${scope.formNameAttribute}.${scope.inputAttributeName}.$error.accnum && ${scope.formNameAttribute}.$submitted && !${scope.formNameAttribute}.${scope.inputAttributeName}.$error.binLength)">
            {{'label.requirefield' | translate}}
          </small>
          <small class="required error" ng-show="${scope.formNameAttribute}.${scope.inputAttributeName}.$error.nval">
            {{'label.mustbenumeric' | translate}}
          </small>
          <small class="required error" ng-show="${scope.formNameAttribute}.${scope.inputAttributeName}.$error.binLength">
            {{'label.canonlybesixtilleight' | translate}}
          </small>
          <small class="required error" ng-show="${scope.formNameAttribute}.${scope.inputAttributeName}.$error.accnum">
            {{'label.mustbesixteendigits' | translate}}
          </small>
        </span>
      `;

      elm.html('').append($compile(template)(scope));
    }
  };
}

FormValidateDirective.$inject = ['$compile'];
