<template>
  <b-modal
    id="tx-change-status-modal"
    :title="modalTitle"
    hide-header-close
    size="md"
    @show="reason = ''"
  >
    <label>Reason <span class="required">*</span></label>
    <b-form-textarea
      v-model="reason"
      :autofocus="true"
      rows="2"
      class="form-control"
      :state="!!reason?.length"
    />
    <template #modal-footer>
      <button
        class="btn btn-default"
        @click="cancel"
      >
        {{ $t('label.button.cancel') }}
      </button>
      <button
        class="btn btn-primary"
        :disabled="!reason?.length"
        @click="statusChange"
      >
        {{ $t('label.button.confirm') }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import apiService from '@/services/apiService';
import { useTxMonitoringStore } from '@/stores/txmonitoring';
import { useSharedStore } from '@/stores/shared';

export default {
  name: 'TxTransactionChangeStatusModal',
  data () {
    return {
      reason: '',
      titles: {
        approve: 'Are you sure you want to allow this transaction?',
        reject: 'Are you sure you want to stop this transaction?',
        suspend: 'Are you sure you want to suspend this transaction?',
        rescreen: 'Are you sure you want to rescreen this transaction?',
        OFAC_APPROVE: 'Are you sure you want to allow this transaction?',
        OFAC_REJECT: 'Are you sure you want to reject this transaction?'
      }
    };
  },
  computed: {
    ...mapState(useTxMonitoringStore, ['transaction', 'selectedAction']),
    modalTitle () {
      if (!this.selectedAction) return '';
      return this.titles[this.selectedAction];
    }
  },
  methods: {
    ...mapActions(useTxMonitoringStore, ['setNeedsToReloadList']),
    ...mapActions(useSharedStore, ['resetNotificationMessage']),
    async statusChange () {
      this.resetNotificationMessage();
      if (!this.selectedAction || !this.transaction) return;
      try {
        await apiService.amlScreeningResult.changeStatus(this.transaction.id, this.selectedAction, { reason: this.reason });
        this.$bvModal.hide('tx-change-status-modal');
        this.$bvModal.hide('tx-details-modal');
        this.setNeedsToReloadList(true);
      } catch (e) {
        this.$notify.error(e);
      }
    },
    cancel () {
      this.resetNotificationMessage();
      this.$bvModal.hide('tx-change-status-modal');
    }
  }
};
</script>
