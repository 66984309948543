import router from '@/router/index';

export function UserEditDocumentController ($scope, location, routeParams, API_VERSION, Upload, $rootScope, resourceFactory) {
  $scope.userId = routeParams.userId;
  $scope.documentId = routeParams.documentId;

  resourceFactory.userDoccumentResource.getUserDocumentById({ userId: $scope.userId, documentId: $scope.documentId }, function (data) {
    $scope.formData = {
      id: data.id,
      name: data.name,
      fileName: data.fileName,
      type: data.type,
      description: data.description
    };
  });

  $scope.onFileSelect = function (files) {
    $scope.formData.file = files[0];
    $scope.formData.fileName = $scope.formData.file.name;
  };

  $scope.loading = false;
  $scope.submit = function () {
    $scope.loading = true;
    Upload.upload({
      url: `${$rootScope.hostUrl + API_VERSION}/users/${$scope.userId}/documents/${$scope.documentId}`,
      method: 'PUT',
      data: { name: $scope.formData.name, description: $scope.formData.description, file: $scope.formData.file, type: $scope.formData.type }
    }).then(function () {
      // to fix IE not refreshing the model
      if (!$scope.$$phase) {
        $scope.$apply();
      }
      router.push(`/users/${$scope.userId}`);
    }).finally(() => {
      $scope.loading = false;
    });
  };
}

UserEditDocumentController.$inject = ['$scope', '$location', '$routeParams', 'API_VERSION', 'Upload', '$rootScope', 'ResourceFactory'];
