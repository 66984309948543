export function ViewStandingInstructionController (scope, resourceFactory, location, routeParams) {
  scope.restrictDate = new Date();
  const params = {};
  scope.formData = {};
  scope.showselctclient = 'false';
  scope.allowclientedit = 'true';
  scope.standingInstructionId = routeParams.instructionId;
  params.standingInstructionId = scope.standingInstructionId;

  resourceFactory.standingInstructionResource.get(params, function (data) {
    scope.standinginstruction = data;

    const d = new Date();
    const year = d.getFullYear();
    const day = data.recurrenceOnMonthDay.day;
    const month = data.recurrenceOnMonthDay.month;

    scope.standinginstruction.recurrenceOnMonthDay = [month, day, year];
    if (data.fromClient.id === data.toClient.id) {
      scope.allowclientedit = false;
    }
  });

  scope.cancel = function () {
    location.path(`/liststandinginstructions/${scope.standinginstruction.fromClient.officeId}/${scope.standinginstruction.fromClient.id}`);
  };
}

ViewStandingInstructionController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams'];
