export function censoredText () {
  return (text) => {
    if (!text) return text;
    const length = text.length;
    const halfLength = Math.ceil(length / 2);
    // hide half last part
    const hiddenText = text.substring(0, length - halfLength) + text.substring(length - halfLength).replaceAll(/./g, '*');
    return hiddenText;
  };
}
