<template>
  <form-wrapper
    class="col-8 mx-auto"
    @submit="saveOrUpdate"
  >
    <div class="h3 pb-2">
      {{ $t(`label.heading.${formType}`) }}
    </div>
    <input-text
      v-model="localData.name"
      vertical
      :label="$t('label.input.name')"
      required
    />
    <input-text
      v-model="localData.fromDaysPastDue"
      type="number"
      :placeholder="$t('label.input.days')"
      vertical
      :label="$t('label.input.fromDayPastDue')"
      required
    />
    <input-text
      v-model="localData.toDayPastDue"
      :placeholder="$t('label.input.days')"
      type="number"
      vertical
      :label="$t('label.input.toDayPastDue')"
      required
    />

    <button
      v-has-permission="formType === 'create' ? 'CREATE_AGING_BUCKET' : 'UPDATE_AGING_BUCKET'"
      type="submit"
      class="btn btn-primary pull-right"
    >
      {{ $t('label.button.submit') }}
    </button>
    <button
      class="btn btn-default pull-right"
      @click.prevent="cancel()"
    >
      {{ $t('label.button.cancel') }}
    </button>
  </form-wrapper>
</template>

<script setup>
import { FormWrapper, InputText } from '@/components/shared/form-input';
import { reactive } from 'vue';
import apiService from '@/services/apiService';
import { $notify } from '@/plugins/prototypes';

const props = defineProps({
  formData: {
    type: Object,
    required: true
  },
  formType: {
    type: String,
    required: true
  }
});

const localData = reactive({
  name: props.formData.name,
  fromDaysPastDue: props.formData.fromDaysPastDue,
  toDayPastDue: props.formData.toDayPastDue
});

const emit = defineEmits(['on-cancel']);
const cancel = (command) => {
  emit('on-cancel', command);
};
const saveOrUpdate = async () => {
  try {
    const requestData = {
      name: localData.name,
      fromDaysPastDue: Number(localData.fromDaysPastDue),
      toDayPastDue: Number(localData.toDayPastDue)
    };
    if (props.formType === 'create') {
      await apiService.agingBucketResource.post(requestData);
    } else {
      await apiService.agingBucketResource.put(props.formData.id, requestData);
    }
    cancel('reload');
  } catch (error) {
    $notify.error(error);
  }
};

</script>
