import { $notify } from '@/plugins/prototypes';
import { useUserStore } from '@/stores/user';
import router from '@/router/index';

const expiryTokenInterceptor = function ($q) {
  return {
    responseError (response) {
      const isAuth = localStorage.getItem('ls.tokendetails');
      if (response.status === 401 && response.data.error === 'invalid_token' && isAuth) {
        useUserStore().resetSession();
        router.push({ name: 'Login' });
        $notify.error('Session Expired');
      }
      return $q.reject(response);
    }
  };
};

export { expiryTokenInterceptor };
