<template>
  <div class="content-container">
    <div class="card">
      <div class="card-body">
        <page-title>{{ $t('label.heading.holdAmountDetails') }}</page-title>
        <hr>
        <div class="row">
          <detail-table
            v-for="(table, tableIndex) in transaction"
            :key="tableIndex"
            class="col-sm-6"
          >
            <template #header>
              {{ $t(table.header) }}
            </template>
            <template
              v-if="table.details.length"
              #rows
            >
              <detail-table-row
                v-for="(detail, detailIndex) in table.details"
                :key="detailIndex"
                :detail="detail"
              />
            </template>
          </detail-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DetailTable from '@/components/shared/DetailTable.vue';
import PageTitle from '@/components/shared/PageTitle.vue';
import apiService from '@/services/apiService';
import DetailTableRow from '@/components/shared/DetailTableRow.vue';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { mapActions } from 'pinia';
export default {
  name: 'HoldAmountDetailsPage',
  components: {
    PageTitle,
    DetailTable,
    DetailTableRow
  },
  data () {
    return {
      transaction: []
    };
  },
  async created () {
    this.setBreadcrumbs([
      { text: this.$t('label.nav.transfers'), href: '#/transfers' },
      { text: this.$t('label.heading.holdAmountDetails') }
    ]);
    await this.getHoldAmount();
  },
  methods: {
    ...mapActions(useBreadcrumbsStore, ['setBreadcrumbs']),
    async getHoldAmount () {
      try {
        const { data: response } = await apiService.getAuthTransaction.get(
          this.$route.params.id,
          this.$route.params.externalAuthId
        );
        const transactionDetails = response.pageItems[0];
        this.transaction = [
          {
            header: 'label.heading.transactionInformation',
            details: [
              {
                key: 'label.heading.id',
                value: transactionDetails.id
              },
              {
                key: 'label.heading.holdAuthorizeTransactionId',
                value: transactionDetails.holdAuthorizeTransactionId
              },
              {
                key: 'label.heading.status',
                value: transactionDetails.status
              },
              {
                key: 'label.heading.createdAt',
                value: transactionDetails.createdAt
              }
            ]
          },
          {
            header: 'label.heading.heldAmount',
            details: [
              {
                key: 'label.heading.pendingAmount',
                value: transactionDetails.pendingAmount
              },
              {
                key: 'label.heading.clearedAmount',
                value: transactionDetails.clearedAmount
              },
              {
                key: 'label.heading.expiryDate',
                value: transactionDetails.expiryDate
              }
            ]
          }
        ];
      } catch (e) {
        this.$notify.error(e);
      }
    }
  }
};
</script>
