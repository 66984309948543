export function CreateNCPeriodLimitRuleController (scope, routeParams, resourceFactory, location) {
  const ruleId = routeParams.ruleGroupId;
  scope.ruleGroup = {};
  scope.formData = {};
  scope.errorMsg = '';

  function loadData () {
    const params = { ruleId, fields: 'id,name' };

    resourceFactory.amlNetworkConnectionsResource.get(
      params,
      scope.formData,
      function (res) {
        scope.ruleGroup = res;
      },
      handleResponseError
    );
  }

  loadData();

  scope.submit = function () {
    // scope.validFrom = null
    // scope.validTo = null
    // if (scope.formData.validFrom) {
    //   scope.validFromDate = new Date(scope.formData.validFrom)
    //   scope.validFrom = scope.validFromDate.toISOString()
    // }
    // if (scope.formData.validTo) {
    //   scope.validToDate = new Date(scope.formData.validTo)
    //   scope.validTo = scope.validToDate.toISOString()
    // }
    const params = {
      ruleId,
      action: 'periodlimitrule'
    };

    const data = {
      ...scope.formData,
      active: false
    };

    resourceFactory.amlNetworkConnectionsResource.save(
      params,
      data,
      function (res) {
        const { resourceId } = res;
        location.path(`/payments/aml/rulegroups/${ruleId}/ncPeriodlimitrules/${resourceId}`);
      },
      handleResponseError
    );
  };

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
}

CreateNCPeriodLimitRuleController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
