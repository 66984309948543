export function EditAMLNCRuleGroupController (scope, routeParams, resourceFactory, location) {
  const ruleId = routeParams.id;
  scope.errorMsg = '';
  scope.formData = {};
  scope.ruleGroup = { id: ruleId };
  scope.steps = [0, 1, 2, 3, 4, 5];

  scope.loadData = function () {
    const params = {
      ncRule: { ruleId },
      currencies: { fields: 'selectedCurrencyOptions' }
    };

    resourceFactory.amlNetworkConnectionsResource.get(
      params.ncRule,
      function (res) {
        const { name, description, currency, step } = res;
        scope.formData = { name, description, currency, step };
      },
      handleResponseError
    );

    resourceFactory.currenciesResource.get(
      params.currencies,
      function (res) {
        scope.currencies = res.selectedCurrencyOptions;
      },
      handleResponseError
    );
  };

  scope.loadData();

  scope.submit = function () {
    resourceFactory.amlNetworkConnectionsResource.update({ ruleId }, scope.formData, function (res) {
      const { resourceId } = res;
      location.path(`/payments/aml/ncrulegroups/${resourceId}`);
    }, handleResponseError);
  };

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
}

EditAMLNCRuleGroupController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
