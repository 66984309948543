// (function (module) {
//     mbanq.filters = _.extend(module, {
//         sort: function () {
//             return function (input) {
//                 return input.sort();
//             }
//         }
//     });
//     mbanq.ng.application.filter('sort', ['dateFilter', mbanq.filters.sort]).run(function ($log) {
//         $log.info("sort filter initialized");
//     });
// }(mbanq.filters || {}));

export function sort () {
  return function (input) {
    return input.sort();
  };
}
