export function OfficesController (scope, resourceFactory, location) {
  let parentNode;
  scope.offices = [];
  scope.isTreeView = false;
  const idToNodeMap = {};

  scope.routeTo = function (id) {
    location.path(`/viewoffice/${id}`);
  };

  if (!scope.searchCriteria.offices) {
    scope.searchCriteria.offices = null;
    scope.saveSC();
  }
  scope.filterText = scope.searchCriteria.offices || '';

  scope.onFilter = function () {
    scope.searchCriteria.offices = scope.filterText;
    scope.saveSC();
  };

  scope.deepCopy = function deepCopy (obj) {
    if (Object.prototype.toString.call(obj) === '[object Array]') {
      const out = []; let i = 0; const len = obj.length;
      for (; i < len; i++) {
        out[i] = deepCopy(obj[i]);
      }
      return out;
    }
    if (typeof obj === 'object') {
      const out = {}; let i;
      for (i in obj) {
        out[i] = deepCopy(obj[i]);
      }
      return out;
    }
    return obj;
  };

  scope.OfficesPerPage = 15;
  resourceFactory.officeResource.getAllOffices(function (data) {
    scope.offices = scope.deepCopy(data);
    for (const office of data) {
      office.children = [];
      idToNodeMap[office.id] = office;
    }
    function sortByParentId (a, b) {
      return a.parentId - b.parentId;
    }
    data.sort(sortByParentId);
    const root = [];
    for (const currentObj of data) {
      if (currentObj.children) {
        currentObj.collapsed = 'true';
      }
      if (typeof currentObj.parentId === 'undefined') {
        root.push(currentObj);
      } else {
        parentNode = idToNodeMap[currentObj.parentId];
        parentNode.children.push(currentObj);
      }
    }
    scope.treedata = root;
  });
}

OfficesController.$inject = ['$scope', 'ResourceFactory', '$location'];
