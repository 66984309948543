export function EditAccountNumberPreferencesController (scope, resourceFactory, location, routeParams) {
  scope.resourceId = routeParams.id;

  resourceFactory.accountNumberResources.getPrefixType({ accountNumberFormatId: scope.resourceId }, function (data) {
    scope.accountType = data.accountType.value;
    scope.formData = {
      prefixType: data.prefixType.id,
      customPrefix: data.customPrefix,
      accountNumberSeed: data.accountNumberSeed || '',
      accountNumberLength: data.accountNumberLength || ''
    };

    scope.prefixTypeOptions = data.prefixTypeOptions[data.accountType.code];
  });

  scope.cancel = function () {
    location.path('/accountnumberpreferences');
  };
  scope.submit = function () {
    resourceFactory.accountNumberResources.put({ accountNumberFormatId: scope.resourceId }, scope.formData, function (data) {
      location.path(`/viewaccountnumberpreferences/${data.resourceId}`);
    });
  };

  scope.handlePrefixTypeChange = function () {
    if (scope.formData.prefixType !== 401) {
      delete scope.formData.customPrefix;
    }
  };
}

EditAccountNumberPreferencesController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams'];
