export function BulkImportJournalEntriesController ($scope, resourceFactory, location, API_VERSION, $rootScope, Upload) {
  $scope.first = {};
  $scope.first.templateUrl = `${API_VERSION}/journalentries/downloadtemplate?tenantIdentifier=${$rootScope.tenantIdentifier}&locale=${$scope.optlang.code}&dateFormat=${$scope.df}`;
  $scope.formData = {};
  const requestParams = { staffInSelectedOfficeOnly: true };

  resourceFactory.clientTemplateResource.get(requestParams, function (data) {
    $scope.offices = data.officeOptions;
    $scope.staffs = data.staffOptions;
  });

  $scope.first.queryParams = '&';
  $scope.changeOffice = function () {
    if ($scope.formData.officeId) {
      if ($scope.first.queryParams.indexOf('officeId') === -1) {
        $scope.first.queryParams += `officeId=${$scope.formData.officeId}`;
      } else {
        $scope.first.queryParams = $scope.first.queryParams.replace(/&officeId=\d+/i, `&officeId=${$scope.formData.officeId}`);
      }
    } else {
      $scope.first.queryParams = '&';
    }
  };

  $scope.onFileSelect = function (files) {
    $scope.formData.file = files[0];
  };
  $scope.refreshImportTable = function () {
    resourceFactory.importResource.getImports({ entityType: 'gljournalentries' }, function (dataJournalEntries) {
      for (const dataJournalEntry of dataJournalEntries) {
        let importdocs = {};
        importdocs = `${API_VERSION}/imports/downloadOutputTemplate?importDocumentId=${dataJournalEntry.importId}&tenantIdentifier=${$rootScope.tenantIdentifier}`;
        dataJournalEntry.docUrl = importdocs;
      }
      $scope.imports = dataJournalEntries;
    });
  };

  $scope.upload = function () {
    Upload.upload({
      url: `${$rootScope.hostUrl + API_VERSION}/journalentries/uploadtemplate`,
      data: { file: $scope.formData.file, locale: $scope.optlang.code, dateFormat: $scope.df }
    }).then(function () {
      // to fix IE not refreshing the model
      if (!$scope.$$phase) {
        $scope.$apply();
      }
    });
  };
}

BulkImportJournalEntriesController.$inject = ['$scope', 'ResourceFactory', '$location', 'API_VERSION', '$rootScope', 'Upload'];
