export function EditAMLRuleGroupController (scope, routeParams, resourceFactory, location) {
  const ruleId = routeParams.id;
  scope.ruleGroup = { id: ruleId };
  scope.formData = {};
  scope.errorMsg = '';
  scope.currencies = [];

  function loadData () {
    const params = {
      amlRule: { ruleId },
      currencies: { fields: 'selectedCurrencyOptions' }
    };

    resourceFactory.amlRulesResource.get(params.amlRule, handleGetAmlRulesSuccess, handleResponseError);
    resourceFactory.currenciesResource.get(params.currencies, handleGetCurrenciesSuccess, handleResponseError);

    function handleGetAmlRulesSuccess (res) {
      const { name, description, isActive, ruleSequence } = res;
      scope.formData = { name, description, isActive, ruleSequence };
    }

    function handleGetCurrenciesSuccess (res) {
      scope.currencies = res.selectedCurrencyOptions;
    }
  }

  loadData();

  scope.submit = function () {
    resourceFactory.amlRulesResource.update({ ruleId }, scope.formData, handleUpdateAmlRulesSuccess, handleResponseError);

    function handleUpdateAmlRulesSuccess (res) {
      const { resourceId } = res;
      location.path(`/payments/aml/rulegroups/${resourceId}`);
    }
  };

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
}

EditAMLRuleGroupController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
