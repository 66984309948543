import _ from 'underscore';

export function DefineOpeningBalancesController (scope, resourceFactory, location, dateFilter) {
  scope.first = {};
  scope.formData = {};
  scope.first.date = new Date();
  scope.accountClosures = [];
  scope.restrictDate = new Date();
  scope.totalDebitAmount = 0;
  scope.totalCreditAmount = 0;

  resourceFactory.officeResource.getAllOffices(function (data) {
    scope.offices = data;
  });

  resourceFactory.currenciesResource.get({ fields: 'selectedCurrencyOptions' }, function (data) {
    scope.currencyOptions = data.selectedCurrencyOptions;
    scope.formData.currencyCode = scope.currencyOptions[0].code;
  });

  scope.submit = function () {
    const reqDate = dateFilter(scope.first.date, scope.df);
    this.formData.locale = scope.optlang.code;
    this.formData.dateFormat = scope.df;
    this.formData.transactionDate = reqDate;
    this.formData.currencyCode = scope.formData.currencyCode;
    this.formData.credits = [];
    this.formData.debits = [];
    scope.errorDetails = [];
    let noErrors = true;
    for (const i in scope.allGls) {
      if (scope.allGls[i].credit && scope.allGls[i].credit !== '' && scope.allGls[i].debit && scope.allGls[i].debit !== '') {
        if (noErrors) {
          noErrors = false;
          const errorObj = {};
          errorObj.code = 'error.msg.accounting.defining.openingbalance.both.credit.debits.are.passed';
          scope.errorDetails.push([errorObj]);
        }
      } else if (scope.allGls[i].debit && scope.allGls[i].debit !== '') {
        this.formData.debits.push({ glAccountId: scope.allGls[i].glAccountId, amount: scope.allGls[i].debit });
      } else if (scope.allGls[i].credit && scope.allGls[i].credit) {
        this.formData.credits.push({ glAccountId: scope.allGls[i].glAccountId, amount: scope.allGls[i].credit });
      }
    }
    if (noErrors) {
      delete scope.errorDetails;
      resourceFactory.journalEntriesResource.save({ command: 'defineOpeningBalance' }, this.formData, function (data) {
        location.path(`/viewtransactions/${data.transactionId}`);
      });
    }
  };

  scope.keyPress = function () {
    this.formData.credits = [];
    this.formData.debits = [];
    scope.totalDebitAmount = 0;
    scope.totalCreditAmount = 0;

    for (const l in scope.allGls) {
      if (scope.allGls[l].debit !== null && scope.allGls[l].debit !== '') {
        scope.totalDebitAmount += parseFloat(scope.allGls[l].debit);
      }
      if (scope.allGls[l].credit !== null && scope.allGls[l].credit !== '') {
        scope.totalCreditAmount += parseFloat(scope.allGls[l].credit);
      }
    }
  };

  scope.updateDebitCreditAmounts = function (gl) {
    if (gl.amount) {
      if (gl.entryType) {
        if (gl.entryType.value === 'DEBIT') {
          gl.debit = gl.amount;
        } else if (gl.entryType.value === 'CREDIT') {
          gl.credit = gl.amount;
        }
      }
    }
  };

  scope.mergeAllGLs = function () {
    scope.allGls = [];
    scope.debitTotal = 0;

    _.each(scope.data.assetAccountOpeningBalances, function (gl) {
      scope.updateDebitCreditAmounts(gl);
      scope.allGls.push(gl);
    });

    _.each(scope.data.liabityAccountOpeningBalances, function (gl) {
      scope.updateDebitCreditAmounts(gl);
      scope.allGls.push(gl);
    });

    _.each(scope.data.equityAccountOpeningBalances, function (gl) {
      scope.updateDebitCreditAmounts(gl);
      scope.allGls.push(gl);
    });

    _.each(scope.data.incomeAccountOpeningBalances, function (gl) {
      scope.updateDebitCreditAmounts(gl);
      scope.allGls.push(gl);
    });

    _.each(scope.data.expenseAccountOpeningBalances, function (gl) {
      scope.updateDebitCreditAmounts(gl);
      scope.allGls.push(gl);
    });
  };

  scope.retrieveOpeningBalances = function (officeId, currencyCode) {
    resourceFactory.officeOpeningResource.get({ officeId, currencyCode }, function (data) {
      scope.data = data;
      scope.mergeAllGLs();
    });
  };
}

DefineOpeningBalancesController.$inject = ['$scope', 'ResourceFactory', '$location', 'dateFilter'];
