export function HookController (scope, resourceFactory, location) {
  scope.hooks = [];
  scope.searchCriteria.hooks = '';

  scope.routeTo = function (id) {
    location.path(`/viewhook/${id}`);
  };

  if (!scope.searchCriteria.hooks) {
    scope.searchCriteria.hooks = null;
    scope.saveSC();
  }
  scope.filterText = scope.searchCriteria.hooks || '';

  scope.onFilter = function () {
    scope.searchCriteria.hooks = scope.filterText;
    scope.saveSC();
  };

  scope.HooksPerPage = 15;

  resourceFactory.webhooksResource.query({ eventAction: 'endpoints', fields: 'id,name,url' }, (response) => {
    scope.hooks = response;
  });
}

HookController.$inject = ['$scope', 'ResourceFactory', '$location'];
