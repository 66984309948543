export function CreatePaymentTypeController ($scope, resourceFactory, location) {
  $scope.formData = {};
  $scope.isCashPayment = true;

  $scope.submit = function () {
    this.formData.isCashPayment = this.formData.isCashPayment || false;
    resourceFactory.paymentTypeResource.save(this.formData, function () {
      location.path('/viewpaymenttype/');
    });
  };
}

CreatePaymentTypeController.$inject = ['$scope', 'ResourceFactory', '$location'];
