export function EditPaymentTypeController ($scope, routeParams, resourceFactory, location) {
  $scope.formData = {};
  resourceFactory.paymentTypeResource.get({ paymentTypeId: routeParams.id }, function (data) {
    $scope.formData = {
      name: data.name,
      description: data.description,
      isCashPayment: data.isCashPayment,
      position: data.position
    };
  });

  $scope.submit = function () {
    this.formData.isCashPayment = this.formData.isCashPayment || false;
    resourceFactory.paymentTypeResource.update({ paymentTypeId: routeParams.id }, this.formData, function () {
      location.path('/viewpaymenttype/');
    });
  };
}

EditPaymentTypeController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
