import { $notify } from '@/plugins/prototypes';
import apiService from '@/services/apiService';

export default {
  data () {
    return {
      lastProcessingDay: 1,
      achProcessingLatestHistory: {},
      achProcessingHistoryList: [],
      sameDayAchProcessingLatestHistory: {},
      sameDayAchProcessingHistoryList: []
    };
  },
  methods: {
    async getAllAchProcessingHistory () {
      try {
        const { data: { pageItems: achProcessingHistory } } = await apiService.transfer.getAllAchProcessingHistory({ lastProcessingDay: this.lastProcessingDay });

        if (achProcessingHistory.length) {
          this.hasAchProcessingLatestHistory = true;
          this.achProcessingHistoryList = achProcessingHistory;
          this.achProcessingLatestHistory = achProcessingHistory[0];
        }
      } catch (error) {
        $notify.error(error);
      }
    },
    async getSameDayAchProcessingHistory () {
      try {
        const { data: { pageItems: sameDayAchProcessingHistory } } = await apiService.transfer.getAllAchProcessingHistory({ lastProcessingDay: this.lastProcessingDay, paymentType: 'SAMEDAYACH' });

        if (sameDayAchProcessingHistory.length) {
          this.hasSameDayAchProcessingLatestHistory = true;
          this.sameDayAchProcessingHistoryList = sameDayAchProcessingHistory;
          this.sameDayAchProcessingLatestHistory = sameDayAchProcessingHistory[0];
        }
      } catch (error) {
        $notify.error(error);
      }
    }
  }
};
