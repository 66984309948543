<template>
  <div id="vue-app">
    <div
      v-if="routedInVue && sessionProcessed"
      id="mifos-reskin-ui-container"
    >
      <top-navbar v-if="userDetails" />
      <left-nav v-if="userDetails" />
      <div id="mifos-reskin-body-view">
        <div class="content-container">
          <bread-crumb v-if="userDetails && $router.currentRoute.name !== 'Home'" />
          <router-view />
        </div>
      </div>
      <loader />
    </div>
    <prompt />
    <notification-toast />
    <back-to-top-button />
  </div>
</template>

<script>
import BreadCrumb from '@/components/shared/BreadCrumb.vue';
import TopNavbar from './TopNavBar.vue';
import LeftNav from './left-nav.vue';
import NotificationToast from './notification-toast.vue';
import Loader from '@/components/shared/Loader.vue';
import BackToTopButton from '@/layouts/back-to-top-button.vue';
import { getAngularRootScope } from '@/helper/angularRootScope';
import { mapWritableState, mapState } from 'pinia';
import { useVueTransitionStore } from '@/stores/vuetransition';
import { useUserStore } from '@/stores/user';
import Prompt from '@/components/shared/Prompt.vue';

export default {
  name: 'App',
  components: {
    Loader,
    LeftNav,
    TopNavbar,
    BreadCrumb,
    BackToTopButton,
    NotificationToast,
    Prompt
  },
  computed: {
    ...mapState(useUserStore, ['userDetails']),
    ...mapWritableState(useVueTransitionStore, ['isVueRoute', 'sessionProcessed']),
    routedInVue () {
      return !!this.$route.matched.length;
    }
  },
  watch: {
    routedInVue: {
      handler (val) {
        this.isVueRoute = val;
      },
      immediate: true
    },
    isVueRoute () {
      this.updateStateInAngular();
    }
  },
  methods: {
    updateStateInAngular () {
      const $rootScope = getAngularRootScope();
      $rootScope.$digest();
    }
  }
};
</script>
