import { TokenService } from '../../../../services/token.service';
import { useUserStore } from '@/stores/user';

export function ProfileController (scope, $rootScope, httpService, localStorageService, resourceFactory, $uibModal) {
  scope.totp = false;
  scope.disableInput = false;
  scope.token = '';
  scope.secretKey = '';
  scope.recoveryCodes = [];
  scope.error = '';
  const userStore = useUserStore();

  scope.userDetails = userStore.userDetails;
  resourceFactory.userListResource.get({ userId: scope.userDetails.userId }, function (data) {
    scope.user = data;

    resourceFactory.authenticationDetailResource.get(function (authDetailData) {
      scope.totp = authDetailData?.mfaDeliveryMethods?.includes('TOTP');
    });
  });

  scope.status = 'Not Authenticated';
  if (scope.userDetails.authenticated === true) {
    scope.status = 'Authenticated';
  }
  scope.updatePassword = function () {
    $uibModal.open({
      templateUrl: 'password.html',
      controller: UpdatePasswordCtrl,
      resolve: {
        userId () {
          return scope.userDetails.userId;
        }
      }
    });
  };

  const UpdatePasswordCtrl = function ($scope, $uibModalInstance, userId) {
    $scope.error = '';

    $scope.$watch('formData.password', function () {
      $scope.error = '';
    });

    $scope.$watch('formData.repeatPassword', function () {
      $scope.error = '';
    });

    $scope.save = function () {
      const temp = {
        username: scope.user.username,
        password: this.formData.password,
        repeatPassword: this.formData.repeatPassword
      };

      if (temp.password !== temp.repeatPassword) {
        $scope.error = 'Passwords do not match';
        return;
      }

      resourceFactory.userChangePasswordResource.update({ userId }, temp, function (data) {
        $uibModalInstance.close('modal');
        if (data.resourceId === userId) {
          userStore.logout();
        }
      });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.disableTOTP = function () {
    scope.disableInput = true;
  };

  scope.confirmDisableTOTP = function (token) {
    $uibModal.open({
      templateUrl: 'disableTOTP.html',
      controller: DisableTOTPCtrl,
      resolve: {
        token () {
          return token;
        }
      }
    });
  };

  const DisableTOTPCtrl = function (
    $scope,
    $rootScope,
    $uibModalInstance,
    token
  ) {
    $scope.disable = function () {
      scope.error = '';

      resourceFactory.totpResource.save({ command: 'delete' }, { token }, function () {
        scope.disableInput = false;
        $rootScope.totp = false;
        scope.totp = false;
        scope.secretKey = false;
        scope.recoveryCodes = [];

        $uibModalInstance.close();
      }, () => { scope.error = 'Wrong TOTP code. Please try again.'; });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.confirmEnableTOTP = function (token) {
    scope.error = '';
    resourceFactory.totpResource.save({ command: 'confirm' }, { token }, function (response) {
      scope.disable = false;
      $rootScope.totp = true;
      scope.totp = true;
      httpService.setTwoFactorAccessToken(response.mfaToken?.token);
      TokenService.save2FAToken(response.mfaToken?.token);
      scope.recoveryCodes = response.recoveryCodes;
    }, () => { scope.error = 'Wrong TOTP code. Please try again.'; });
  };

  scope.getSecretKey = function () {
    resourceFactory.totpResource.save({ command: 'setup' }, {}, function (response) {
      scope.secretKey = response.data.secretKey;
      const username = scope.userDetails.username;
      scope.qrString = encodeURI(`otpauth://totp/BackOffice:${username}?secret=${scope.secretKey}&issuer=BackOffice&algorithm=SHA1&digits=6&period=30`);
    });
  };
}

ProfileController.$inject = ['$scope', '$rootScope', 'HttpService', 'localStorageService', 'ResourceFactory', '$uibModal'];
