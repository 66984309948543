export function getRandomString (lenght) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const randomString = Array.from({ length: lenght }, () => characters[Math.floor(Math.random() * characters.length)]).join('');
  return randomString;
}

export function getClonnedName (previousName) {
  const currentTimestamp = new Date();
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  const formattedDate = currentTimestamp.toLocaleString('en-US', options);

  const searchText = '_clone_';
  const index = previousName.indexOf(searchText);
  let name = '';
  if (index !== -1) {
    name = previousName.substring(0, index + searchText.length);
    name = name + formattedDate;
  } else {
    name = previousName + '_clone_' + formattedDate;
  }
  return name;
}
