<template>
  <div class="card-body">
    <form @submit.prevent="submit">
      <legend>{{ $t('label.head.manageOrganization') }}</legend>
      <fieldset
        :disabled="isFormLoading"
        class="row"
      >
        <div class="col-sm-6">
          <div class="form-group row align-items-center mb-3">
            <label
              class="control-label text-end col-sm-4"
              for="name"
            >
              {{ $t('label.input.name') }}
            </label>

            <div class="col-sm-8">
              <input
                id="name"
                v-model="organization.name"
                name="name"
                type="text"
                class="form-control"
              >
            </div>
          </div>

          <div class="form-group row align-items-center mb-3">
            <label
              class="control-label text-end col-sm-4"
              for="emailAddress"
            >
              {{ $t('label.input.email') }}
            </label>

            <div class="col-sm-8">
              <input
                id="emailAddress"
                v-model="organization.emailAddress"
                name="emailAddress"
                type="email"
                class="form-control"
              >
            </div>
          </div>

          <div class="form-group row align-items-center mb-3">
            <label
              class="control-label text-end col-sm-4"
              for="address"
            >
              {{ $t('label.input.address') }}
            </label>

            <div class="col-sm-8">
              <input
                id="address"
                v-model="organization.address"
                name="address"
                type="text"
                class="form-control"
              >
            </div>
          </div>

          <div class="form-group row align-items-center mb-3">
            <label
              class="control-label text-end col-sm-4"
              for="mobileNo"
            >
              {{ $t('label.input.mobileNo') }}
            </label>

            <div class="col-sm-8">
              <input
                id="mobileNo"
                v-model="organization.mobileNo"
                name="mobileNo"
                type="text"
                class="form-control"
              >
            </div>
          </div>

          <div class="form-group row align-items-center mb-3">
            <label
              class="control-label text-end col-sm-4"
              for="logoUrl"
            >
              {{ $t('label.input.logoUrl') }}
            </label>

            <div class="col-sm-8">
              <input
                id="logoUrl"
                v-model="organization.logoUrl"
                name="logoUrl"
                type="text"
                class="form-control"
              >
            </div>
          </div>
          <div class="form-group row align-items-center mb-3">
            <label
              class="control-label text-end col-sm-4"
              for="addressLineOne"
            >
              {{ $t('label.input.addressLineOne') }}
            </label>

            <div class="col-sm-8">
              <input
                id="addressLineOne"
                v-model="organization.addressLineOne"
                name="addressLineOne"
                type="text"
                class="form-control"
              >
            </div>
          </div>

          <div class="form-group row align-items-center mb-3">
            <label
              class="control-label text-end col-sm-4"
              for="addressLineTwo"
            >
              {{ $t('label.input.addressLineTwo') }}
            </label>

            <div class="col-sm-8">
              <input
                id="addressLineTwo"
                v-model="organization.addressLineTwo"
                name="addressLineTwo"
                type="text"
                class="form-control"
              >
            </div>
          </div>

          <div class="form-group row align-items-center mb-3">
            <label
              class="control-label text-end col-sm-4"
              for="state"
            >
              {{ $t('label.input.stateOrProvince') }}
            </label>

            <div class="col-sm-8">
              <input
                id="state"
                v-model="organization.state"
                name="state"
                type="text"
                class="form-control"
              >
            </div>
          </div>

          <div class="form-group row align-items-center mb-3">
            <label
              class="control-label text-end col-sm-4"
              for="city"
            >
              {{ $t('label.input.city') }}
            </label>

            <div class="col-sm-8">
              <input
                id="city"
                v-model="organization.city"
                name="city"
                type="text"
                class="form-control"
              >
            </div>
          </div>

          <div class="form-group row align-items-center mb-3">
            <label
              class="control-label text-end col-sm-4"
              for="zipCode"
            >
              {{ $t('label.input.zipCode') }}
            </label>

            <div class="col-sm-8">
              <input
                id="zipCode"
                v-model="organization.zipCode"
                name="zipCode"
                type="text"
                class="form-control"
              >
            </div>
          </div>

          <div class="form-group row align-items-center mb-3">
            <label
              class="control-label text-end col-sm-4"
              for="type"
            >
              {{ $t('label.input.type') }}
            </label>

            <div class="col-sm-8">
              <select
                id="type"
                v-model="organization.type"
                name="type"
                class="form-control"
              >
                <option>BANK</option>
                <option>FINTECH</option>
              </select>
            </div>
          </div>

          <div class="form-group row align-items-center mb-3">
            <label
              class="control-label text-end col-sm-4"
              for="trademark"
            >
              {{ $t('label.input.trademark') }}
            </label>

            <div class="col-sm-8">
              <input
                id="trademark"
                v-model="organization.trademark"
                name="trademark"
                type="text"
                class="form-control"
              >
            </div>
          </div>

          <div class="form-group row align-items-center mb-3">
            <label
              class="control-label text-end col-sm-4"
              for="about"
            >
              {{ $t('label.input.about') }}
            </label>

            <div class="col-sm-8">
              <textarea
                id="about"
                v-model="organization.about"
                name="about"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div
          v-if="logoSrc"
          class="col-sm-6"
        >
          <h5>{{ $t('label.head.logoDisplay') }}</h5>
          <img
            v-if="shouldShowDefaultLogo"
            loading="lazy"
            alt="Main Logo"
            class="img-responsive logo-display"
            src="/assets/images/logos/main-logo"
            srcset="/assets/images/logos/main-logo@2x.png 2x, /assets/images/logos/main-logo@3x.png 3x"
          >
          <img
            v-else
            loading="lazy"
            alt="Organization Logo"
            class="img-responsive logo-display"
            :src="logoSrc"
          >
        </div>

        <div class="col-sm-12 text-right text-end">
          <a
            href="#/organization"
            class="btn btn-default"
          >{{ $t('label.button.cancel') }}</a>
          <button
            type="submit"
            class="btn btn-primary"
          >
            {{ !isFormLoading ? $t('label.button.save') : $t('label.button.loading') }}
          </button>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import apiService from '@/services/apiService';

export default {
  name: 'ManageOrganizationForm',
  data () {
    return {
      isFormLoading: false,
      logoSrc: '',
      organization: {
        name: '',
        emailAddress: '',
        address: '',
        mobileNo: '',
        logoUrl: ''
      }
    };
  },
  computed: {
    shouldShowDefaultLogo () {
      return this.logoSrc === 'logo.jpeg';
    }
  },
  async mounted () {
    await this.getOrganization();
  },
  methods: {
    async getOrganization () {
      try {
        this.isFormLoading = true;
        const { data } = await apiService.organization.get();
        this.organization = { ...this.organization, ...data };
        this.logoSrc = this.organization?.logoUrl || '';
        this.isFormLoading = false;
      } catch (error) {
        this.$notify.error(error);
        this.isFormLoading = false;
      }
    },
    async submit () {
      try {
        this.isFormLoading = true;
        const { data } = await apiService.organization.update(this.organization);
        this.organization = { ...this.organization, ...data.changes };
        this.logoSrc = this.organization?.logoUrl || '';
        this.isFormLoading = false;
      } catch (error) {
        this.$notify.error(error);
        await this.getOrganization();
        this.isFormLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.logo-display {
  max-height: 200px;
  max-width: 400px
}
</style>
