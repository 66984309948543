export function CashierFundsAllocationSettlementController (scope, routeParams, location, dateFilter, resourceFactory) {
  scope.formData = {};
  scope.formData.txnDate = new Date();
  scope.settle = routeParams.settle;
  resourceFactory.cashierTxnTemplateResource.get({ tellerId: routeParams.tellerId, cashierId: routeParams.cashierId }, function (data) {
    scope.cashierTxnTemplate = data;
    scope.formData.currencyCode = data.currencyOptions[0].code;
  });
  scope.tellersId = routeParams.tellerId;
  scope.ifAllocate = function () {
    if (routeParams.action === 'allocate') {
      return true;
    }
  };

  scope.ifSettle = function () {
    if (routeParams.action === 'settle') {
      return true;
    }
  };

  /* $scope.cancel="#tellers"; */
  scope.allocate = function () {
    this.formData.locale = scope.optlang.code;
    const tDate = dateFilter(scope.formData.txnDate, scope.df);
    this.formData.dateFormat = scope.df;
    this.formData.txnDate = tDate;
    resourceFactory.tellerCashierTxnsAllocateResource.allocate(
      { tellerId: routeParams.tellerId, cashierId: routeParams.cashierId },
      this.formData, function () {
        location.path(`tellers/${routeParams.tellerId}/cashiers/${routeParams.cashierId}/txns/${scope.formData.currencyCode}`);
      });
  };

  scope.settle = function () {
    this.formData.locale = scope.optlang.code;
    const tDate = dateFilter(scope.formData.txnDate, scope.df);
    this.formData.dateFormat = scope.df;
    this.formData.txnDate = tDate;
    resourceFactory.tellerCashierTxnsSettleResource.settle(
      { tellerId: routeParams.tellerId, cashierId: routeParams.cashierId },
      this.formData, function () {
        location.path(`tellers/${routeParams.tellerId}/cashiers/${routeParams.cashierId}/txns/${scope.formData.currencyCode}`);
      });
  };
}

CashierFundsAllocationSettlementController.$inject = ['$scope', '$routeParams', '$location', 'dateFilter', 'ResourceFactory'];
