export function SettlementsController (scope, resourceFactory, dateFilter, $uibModal) {
  // Init data
  scope.showFilter = true;
  scope.showTable = false;
  scope.isLoading = false;
  scope.settlementsPerPage = 15;
  scope.totalCardSettlements = 0;
  scope.pageNumber = 1;

  scope.settlementStatusOptions = [
    { value: 'ALL', name: 'ALL' },
    { value: 'REJECTED', name: 'REJECTED' },
    { value: 'COMPLETED', name: 'COMPLETED' }
  ];

  scope.filterData = {
    status: '',
    startDate: '',
    endDate: ''
  };
  scope.getSettlements = function () {
    const settlementFilter = {
      offset: ((scope.pageNumber - 1) * scope.settlementsPerPage),
      limit: scope.settlementsPerPage,
      dateFormat: 'yyyy-MM-dd',
      locale: scope.optlang.code,
      startDate: scope.filterData.startDate,
      endDate: scope.filterData.endDate,
      sortOrder: 'DESC'
    };
    if (scope.filterData.status !== 'ALL' && scope.filterData.status !== '') {
      settlementFilter.status = scope.filterData.status;
    }

    if (scope.filterData.startDate && scope.filterData.endDate) {
      settlementFilter.startDate = dateFilter(scope.filterData.startDate, 'yyyy-MM-dd');
      settlementFilter.endDate = dateFilter(scope.filterData.endDate, 'yyyy-MM-dd');
    }

    resourceFactory.settlementsResource.get(settlementFilter, function (data) {
      scope.isLoading = false;
      scope.totalCardSettlements = data.totalFilteredRecords;
      scope.tableData = data.pageItems;
      scope.showFilter = false;
      scope.showTable = true;
    }, function () {
      scope.isLoading = false;
    });
  };

  function getSettlementDetails (id, callbackOpenModal) {
    resourceFactory.settlementsResource.get({ settlementId: id }, function (settlementData) {
      callbackOpenModal(settlementData);
    });
  }
  const settlementCtrl = function ($scope, $uibModalInstance, items) {
    $scope.settlementData = items;
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
  scope.showSettlementDetails = (id) => {
    getSettlementDetails(id, (settlementData) => {
      $uibModal.open({
        templateUrl: 'viewsettlements.html',
        controller: settlementCtrl,
        resolve: {
          items () {
            return settlementData;
          }
        },
        size: 'xl'
      });
    });
  };
  // Funtion Event Handler
  scope.onSubmitFilter = function () {
    scope.isLoading = true;
    scope.getSettlements();
  };

  scope.onClickReturn = function () {
    scope.showFilter = true;
    scope.showTable = false;
    scope.tableHeader = [];
    scope.tableData = [];
    scope.totalCardSettlements = 0;
    scope.pageNumber = 1;
  };

  scope.onPaginationChange = function (pageNumber) {
    scope.pageNumber = pageNumber;
    scope.getSettlements();
  };
}

SettlementsController.$inject = ['$scope', 'ResourceFactory', 'dateFilter', '$uibModal'];
