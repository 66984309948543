<template>
  <div
    style="height: 500px;"
    class="d-flex flex-column"
  >
    <page-title>
      <div class="d-flex align-items-center">
        <i class="fa-solid fa-cloud-arrow-down me-1" />
        {{ $t('label.heading.uploadedDocument') }}
      </div>
    </page-title>
    <hr>
    <div
      class="overflow-scroll flex-grow-1 overflow-x-hidden"
    >
      <div
        v-for="({ name, completed, importTime, importId, totalRecords, successCount, failureCount }, index) in importedData"
        :key="index"
        class="mb-3 border rounded list-group"
        type="button"
        data-bs-toggle="collapse"
        :data-bs-target="`#item-${index}`"
        aria-expanded="false"
        :aria-controls="`item-${index}`"
      >
        <div
          class="p-3 scrollButton position-relative list-group-item list-group-action"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="fs-6">
              <h6 class="mb-1 fw-bold">
                {{ name }}
              </h6>
              <span>
                <span class="text-secondary">
                  {{ completeUpload(completed) }}:
                </span>
                {{ $t('label.anchor.uploaded') }} {{ dateStringToFormat(importTime) }}
              </span>
              <div
                :id="`item-${index}`"
                class="collapse"
              >
                <div>
                  <span class="text-secondary">{{ $t('label.anchor.totalRecord') }}: </span>
                  {{ totalRecords }}
                </div>
                <div>
                  <span class="text-secondary">{{ $t('label.anchor.successCount') }}: </span>
                  {{ successCount }}
                </div>
                <div>
                  <span class="text-secondary">{{ $t('label.anchor.failureCount') }}:</span>
                  {{ failureCount }}
                </div>
              </div>
            </div>
            <button
              v-if="completed"
              :disabled="loading"
              class="btn btn-light me-2"
              @click="downloadDocument({ name, importId })"
            >
              <span class="fa-solid fa-download fa-lg" />
            </button>
          </div>
          <div class="position-absolute start-50">
            <i class="fa-solid fa-angle-down d-none" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import PageTitle from '@/components/shared/PageTitle.vue';
import i18n from '@/plugins/i18n';
import { useSharedStore } from '@/stores/shared';
import { computed } from 'vue';
import { dateStringToFormat } from '@/helper/dateHelper';

const sharedStore = useSharedStore();
const loading = computed(() => sharedStore.loading);

defineProps({
  importedData: {
    type: Array,
    default: () => []
  }
});
const emits = defineEmits(['upload-file']);
const completeUpload = (uploadComplete) => uploadComplete ? i18n.t('label.anchor.completed') : i18n.t('label.anchor.notCompleted');
const downloadDocument = (data) => emits('upload-file', data);
</script>

<style scoped lang="scss">
@import '@/angular-app/styles-dev/main/components/_variables.scss';
.scrollButton:hover i {
  display: block !important;
}
.scrollButton:hover {
  background-color: $active-cell !important;
}
</style>
