export function CreateFloatingRateController ($scope, resourceFactory, location, dateFilter) {
  $scope.formData = {};
  $scope.formData.ratePeriods = [];
  $scope.formData.isBaseLendingRate = false;
  $scope.formData.isActive = false;
  $scope.addRatePeriod = function () {
    $scope.formData.ratePeriods.push({
    });
  };

  $scope.deleteRatePeriod = function (index) {
    $scope.formData.ratePeriods.splice(index, 1);
  };

  $scope.submit = function () {
    let i = 0;
    const length = this.formData.ratePeriods.length;
    for (i = 0; i < length; i++) {
      this.formData.ratePeriods[i].locale = $scope.optlang.code;
      this.formData.ratePeriods[i].dateFormat = $scope.df;
      this.formData.ratePeriods[i].fromDate = dateFilter(this.formData.ratePeriods[i].fromDate, $scope.df);
    }

    resourceFactory.floatingrates.save(this.formData, function (data) {
      location.path(`/viewfloatingrate/${data.resourceId}`);
    });
  };
}

CreateFloatingRateController.$inject = ['$scope', 'ResourceFactory', '$location', 'dateFilter'];
