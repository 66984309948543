<template>
  <main-content-layout>
    <template #title>
      {{ $t(`label.heading.client.${clientAction}`) }}
    </template>
    <client-actions-form />
  </main-content-layout>
</template>

<script setup>
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import router from '@/router';
import apiService from '@/services/apiService';
import i18n from '@/plugins/i18n';
import ClientActionsForm from '@/components/clients/actions/ClientActionsForm.vue';
import { onMounted, computed } from 'vue';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';

const breadCrumnsStore = useBreadcrumbsStore();
const { id: clientId, action } = router.currentRoute.params;

const clientAction = computed(() => {
  return action.replace(/-action/, '');
});

onMounted(async () => {
  const { data: { displayName } } = await apiService.clients.get(clientId);
  breadCrumnsStore.setBreadcrumbs([
    {
      text: i18n.t('label.anchor.clients'),
      href: '#/clients'
    },
    {
      text: displayName,
      href: `#/viewclient/${clientId}`
    },
    { text: i18n.t(`label.anchor.${clientAction.value}`) }
  ]);
});
</script>
