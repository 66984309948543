export function ViewFieldConfigurationController (scope, routeParams, resourceFactory, location, $uibModal) {
  const getFieldConfiguration = function () {
    resourceFactory.fieldconfigurationResource.get({ id: routeParams.id }, function (data) {
      scope.fieldConfiguration = data;
    });
  };

  getFieldConfiguration();

  scope.deleteFieldConfiguration = function () {
    $uibModal.open({
      templateUrl: 'deletefieldConfiguration.html',
      controller: FieldConfigurationDeleteCtrl
    });
  };
  const FieldConfigurationDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.fieldconfigurationResource.delete({ id: routeParams.id }, {}, function () {
        $uibModalInstance.close('delete');
        location.path('/fieldconfigurations');
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.deleteFieldValidation = function (fieldValidationId) {
    $uibModal.open({
      templateUrl: 'deleteFieldValidation.html',
      controller: FieldValidationDeleteCtrl,
      resolve: {
        fieldValidationId () {
          return fieldValidationId;
        }
      }
    });
  };
  const FieldValidationDeleteCtrl = function ($scope, $uibModalInstance, fieldValidationId) {
    $scope.delete = function () {
      resourceFactory.fieldvalidationResource.delete({ fieldConfigurationId: routeParams.id, fieldValidationId }, {}, function () {
        $uibModalInstance.close('delete');
        getFieldConfiguration();
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewFieldConfigurationController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location', '$uibModal'];
