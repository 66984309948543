export function TaxGroupController ($scope, resourceFactory, location) {
  $scope.taxgroups = [];

  $scope.routeTo = function (id) {
    location.path(`/viewtaxgroup/${id}`);
  };

  resourceFactory.taxgroup.getAll(function (data) {
    $scope.taxgroups = data;
  });
}

TaxGroupController.$inject = ['$scope', 'ResourceFactory', '$location'];
