<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.anchor.defineVelocityRules') }}
    </template>
    <form-wrapper
      @submit="submitForm()"
    >
      <div class="col-12 col-md-6">
        <h4>{{ employeeTier?.name }}</h4>
        <dynamic-fields
          :items="cards"
          @on-add-fields="addFields"
          @on-remove-fields="removeFields"
        >
          <template #content="{item}">
            <div class="col">
              <input-select
                v-model="item.cardProduct"
                class="col-6"
                :label="$t('label.input.cardProduct')"
                :options="availableCardProducts"
                value-field="id"
                item-text="name"
                required
                :disabled="!item.productId && item.isCardProductAlreadySelected"
              />
            </div>
            <div class="h5">
              {{ $t('label.heading.velocityRules') }}
            </div>

            <div>{{ $t('label.heading.atm') }}</div>
            <hr>
            <div>{{ $t('label.heading.amount') }}</div>
            <div class="row">
              <div class="col-6">
                <input-text
                  v-model="item.WITHDRAWAL.SECONDS"
                  type="number"
                  :label="$t('label.input.single')"
                />
                <input-text
                  v-model="item.WITHDRAWAL.WEEKS"
                  type="number"
                  :label="$t('label.input.weekly')"
                />
              </div>
              <div class="col-6">
                <input-text
                  v-model="item.WITHDRAWAL.DAYS"
                  type="number"
                  :label="$t('label.input.daily')"
                />
                <input-text
                  v-model="item.WITHDRAWAL.MONTHS"
                  type="number"
                  :label="$t('label.input.monthly')"
                />
              </div>
            </div>

            <div>{{ $t('label.heading.pos') }}</div>
            <hr>
            <div>{{ $t('label.heading.amount') }}</div>
            <div class="row">
              <div class="col-6">
                <input-text
                  v-model="item.PURCHASE.SECONDS"
                  type="number"
                  :label="$t('label.input.single')"
                />
                <input-text
                  v-model="item.PURCHASE.WEEKS"
                  type="number"
                  :label="$t('label.input.weekly')"
                />
              </div>
              <div class="col-6">
                <input-text
                  v-model="item.PURCHASE.DAYS"
                  type="number"
                  :label="$t('label.input.daily')"
                />
                <input-text
                  v-model="item.PURCHASE.MONTHS"
                  type="number"
                  :label="$t('label.input.monthly')"
                />
              </div>
            </div>
          </template>
        </dynamic-fields>

        <div class="offset-md-5 mt-4">
          <button
            class="btn btn-default"
            @click.prevent="$router.push(`/clients/${clientId}/manage-tiers/${tierId}`)"
          >
            {{ $t('label.button.cancel') }}
          </button>
          <button
            class="btn btn-primary"
          >
            {{ $t('label.button.save') }}
          </button>
        </div>
      </div>
    </form-wrapper>
  </main-content-layout>
</template>

<script setup>
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import { InputText, InputSelect, FormWrapper } from '@/components/shared/form-input/index.js';
import DynamicFields from '@/components/shared/DynamicFields.vue';
import { ref, onMounted, computed } from 'vue';
import router from '@/router';
import i18n from '@/plugins/i18n';
import apiService from '@/services/apiService';
import { $notify } from '@/plugins/prototypes';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';

const breadCrumbsStore = useBreadcrumbsStore();
const cardProductsOptions = ref([]);
const cards = ref([
  {
    cardProduct: null,
    WITHDRAWAL: {
      SECONDS: '',
      DAYS: '',
      WEEKS: '',
      MONTHS: ''
    },
    PURCHASE: {
      SECONDS: '',
      DAYS: '',
      WEEKS: '',
      MONTHS: ''
    }
  }
]);

const isEdit = ref(false);

const addFields = () => {
  cards.value.push({
    cardProduct: null,
    WITHDRAWAL: {
      SECONDS: '',
      DAYS: '',
      WEEKS: '',
      MONTHS: ''
    },
    PURCHASE: {
      SECONDS: '',
      DAYS: '',
      WEEKS: '',
      MONTHS: ''
    }
  });
};
const { clientId, tierId } = router.currentRoute.params;
const employeeTier = ref();
const isNewTier = ref(false);
onMounted(async () => {
  const { data: { displayName } } = await apiService.clients.get(clientId);
  const { data: { cardProductOptions } } = await apiService.clientClassification.template();
  cardProductsOptions.value = cardProductOptions;

  const { data: { employeeTierData, productVelocityRules } } = await apiService.tier.getTierVelocity(clientId, tierId);
  employeeTier.value = employeeTierData;
  if (productVelocityRules) {
    cards.value = formFormatted(productVelocityRules);
  } else {
    isNewTier.value = true;
  }

  const breadcrumbs = [
    {
      text: i18n.t('label.anchor.clients'),
      href: '#/clients'
    },
    {
      text: displayName,
      href: `#/viewclient/${clientId}`
    }

  ];

  if (router.currentRoute.name === 'EditTierLimits') {
    isEdit.value = true;
    breadcrumbs.push({
      text: i18n.t('label.anchor.manageTiers'),
      href: `#/clients/${clientId}/manage-tiers/${tierId}`
    },
    {
      text: i18n.t('label.anchor.edit')
    });
  } else {
    breadcrumbs.push({
      text: i18n.t('label.anchor.add')

    });
  }

  breadCrumbsStore.setBreadcrumbs(breadcrumbs);
});

const formFormatted = (input) => {
  return input.map((item) => {
    const cardProduct = cardProductsOptions.value.find(
      (product) => product.id === item.cardProductId);
    const result = {
      cardProduct,
      WITHDRAWAL: {
        SECONDS: '',
        DAYS: '',
        WEEKS: '',
        MONTHS: ''
      },
      PURCHASE: {
        SECONDS: '',
        DAYS: '',
        WEEKS: '',
        MONTHS: ''
      },
      isCardProductAlreadySelected: true
    };

    item.velocityRules.forEach((rule) => {
      const control = rule.controls[0];
      const timeUnit = rule.timeUnit;
      const value = rule.value;

      result[control][timeUnit] = value;
    });

    return result;
  });
};

const formatToCoreFormat = (input) => {
  const velocityRules = [
    { controls: ['PURCHASE'], timePeriod: 1, timeUnit: 'SECONDS', type: 'AMOUNT' },
    { controls: ['PURCHASE'], timePeriod: 1, timeUnit: 'DAYS', type: 'AMOUNT' },
    { controls: ['PURCHASE'], timePeriod: 1, timeUnit: 'WEEKS', type: 'AMOUNT' },
    { controls: ['PURCHASE'], timePeriod: 1, timeUnit: 'MONTHS', type: 'AMOUNT' },
    { controls: ['WITHDRAWAL'], timePeriod: 1, timeUnit: 'SECONDS', type: 'AMOUNT' },
    { controls: ['WITHDRAWAL'], timePeriod: 1, timeUnit: 'DAYS', type: 'AMOUNT' },
    { controls: ['WITHDRAWAL'], timePeriod: 1, timeUnit: 'WEEKS', type: 'AMOUNT' },
    { controls: ['WITHDRAWAL'], timePeriod: 1, timeUnit: 'MONTHS', type: 'AMOUNT' }
  ];

  const output = input.map(item => ({
    cardProductId: item.cardProduct ? (item.cardProduct.id ? item.cardProduct.id : item.cardProduct) : null, // Extract the ID
    velocityRules: velocityRules.map(rule => ({
      ...rule,
      value: item[rule.controls[0]][rule.timeUnit]
    }))
      .filter(rule => rule.value !== '')
  }));

  return output;
};

const submitForm = async () => {
  try {
    const formatted = formatToCoreFormat(cards.value);
    if (isNewTier.value) {
      await apiService.tier.addTierVelocity(clientId, tierId, { productVelocityRules: formatted });
    } else {
      await apiService.tier.updateTierVelocity(clientId, tierId, { productVelocityRules: formatted });
    }
    router.push(`/clients/${clientId}/manage-tiers/${tierId}`);
  } catch (e) {
    $notify.error(e);
  }
};

const availableCardProducts = computed(() => {
  return cardProductsOptions.value.filter((product) => {
    // Check if the product ID is not included in any of the card products in the cards array
    const isProductUsed = cards.value.some((item) => item.cardProduct && item.cardProduct.id === product.id);
    return !isProductUsed;
  });
});

const removeFields = (index) => {
  cards.value.splice(index, 1);
};
</script>
