export function CreateCashierForTellerController (scope, routeParams, location, dateFilter, resourceFactory) {
  const requestParams = {};
  scope.formData = {};
  scope.formData.isFullDay = true;
  scope.tellerId = routeParams.tellerId;
  if (routeParams.tellerId) {
    requestParams.tellerId = routeParams.tellerId;
  }

  resourceFactory.tellerCashierTemplateResource.get({ tellerId: routeParams.tellerId }, function (data) {
    scope.cashier = data;
  });

  scope.setChoice = function () {
    if (this.formData.isFullDay) {
      scope.choice = 1;
    } else if (!this.formData.isFullDay) {
      scope.choice = 0;
    }
  };

  scope.submit = function () {
    this.formData.locale = scope.optlang.code;
    const reqDate = dateFilter(scope.first.date, scope.df);
    const endDate = dateFilter(scope.formData.endDate, scope.df);
    this.formData.dateFormat = scope.df;
    this.formData.startDate = reqDate;
    this.formData.endDate = endDate;
    resourceFactory.tellerCashierResource.save(
      { tellerId: routeParams.tellerId, cashierId: routeParams.cashierId },
      this.formData, function () {
        location.path(`tellers/${routeParams.tellerId}/cashiers`);
      });
  };
}

CreateCashierForTellerController.$inject = ['$scope', '$routeParams', '$location', 'dateFilter', 'ResourceFactory'];
