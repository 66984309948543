<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.heading.client.createCreditAccountDispute') }}
    </template>
    <div class="card">
      <div class="card-body row">
        <div
          v-for="(creditCard, key, index) in disputeDetails"
          :key="index"
          class="col-12 col-md-6 py-2"
        >
          <span class="fw-bold">{{ $t(`label.head.${key}`) }}</span>:
          {{ creditCard }}
        </div>
      </div>
    </div>
    <div>
      <div class="text-center pt-4">
        <h5 class="fw-bold">
          {{ $t('label.heading.disputedTransactions') }}
        </h5>
      </div>
      <data-grid
        :fields="fields"
        :items="selectedTransactions"
        hide-entries-per-page
      >
        <template #date="{ item }">
          {{ dateStringToFormat(item.date) }}
        </template>
        <template #amount="{ item }">
          {{ currencyFormatter(item.amount) }}
        </template>
        <template #disputeAmount="{ item }">
          <inline-edit
            :value="item.disputeAmount"
            type="number"
            :min="0.01"
            :max="item.amount"
            @on-save="editDisputeAmount($event.value, item.id)"
          >
            {{ currencyFormatter(item.disputeAmount) }}
          </inline-edit>
        </template>
        <template #action="{ item }">
          <button
            v-if="selectedTransactions.length > 1"
            class="btn btn-light"
            @click="removeTransaction(item)"
          >
            <span class="fa fa-times" />
          </button>
        </template>
      </data-grid>
    </div>
    <div class="card">
      <div class="card-body row">
        <div
          v-if="!fieldsTemplates"
          class="col-12 col-lg-6 py-2"
        >
          <form-wrapper @submit="proceed">
            <input-date-picker
              v-model="formData.disputeDate"
              :label="$t('label.input.disputeDate')"
              required
            />
            <input-select
              v-model="formData.disputeReasonId"
              :label="$t('label.input.disputeReason')"
              :options="reasonOptions"
              value-field="id"
              required
            />
            <div class="d-flex justify-content-end">
              <button
                type="reset"
                class="btn btn-default"
                @click.prevent="backToCreditAccount"
              >
                {{ $t('label.button.cancel') }}
              </button>
              <button
                type="submit"
                class="btn btn-primary"
              >
                {{ $t('label.button.proceed') }}
              </button>
            </div>
          </form-wrapper>
        </div>
        <div v-if="fieldsTemplates !== null">
          <dynamic-template-fields
            :fields="fieldsTemplates"
            @on-submit="onSubmit"
            @on-cancel="fieldsTemplates = null"
          />
        </div>
      </div>
    </div>
  </main-content-layout>
</template>

<script setup>
import { InputDatePicker, InputSelect, FormWrapper } from '@/components/shared/form-input';
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import DataGrid from '@/components/shared/data-grid/index.vue';
import DynamicTemplateFields from '@/components/clients/credit-accounts/dynamicTemplateFields/index.vue';
import InlineEdit from '@/components/shared/InlineEdit.vue';
import i18n from '@/plugins/i18n';
import apiService from '@/services/apiService';
import router from '@/router/index';
import { useDisputeCreditTxStore } from '@/stores/disputeCreditTx';
import { computed, ref, reactive, onMounted } from 'vue';
import { dateStringToFormat } from '@/helper/dateHelper';
import { currencyFormatter } from '@/helper/currencyHelper';
import { $notify } from '@/plugins/prototypes';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';

const { creditAccountId } = router.currentRoute.params;
const disputeCreditTxStore = useDisputeCreditTxStore();
const breadcrumbStore = useBreadcrumbsStore();

const fields = ref([
  { key: 'id', label: i18n.t('label.heading.id') },
  { key: 'date', label: i18n.t('label.heading.transactionDate') },
  { key: 'transactionType.value', label: i18n.t('label.heading.transactiontype') },
  { key: 'subTransactionType.value', label: i18n.t('label.heading.subTransactionType') },
  { key: 'amount', label: i18n.t('label.heading.transactionAmount') },
  { key: 'disputeAmount', label: i18n.t('label.heading.disputeAmount') },
  { key: 'paymentDetailData.reference', label: i18n.t('label.heading.description') },
  { key: 'action', label: i18n.t('label.heading.action') }
]);
const formData = reactive({
  disputeDate: null,
  disputeReasonId: null
});
const reasonOptions = ref([]);

const fieldsTemplates = ref(null);

onMounted(async () => {
  const { data: { pageItems } } = await apiService.creditCardDispute.getDisputeReasons({ paymentRail: 'CREDIT_CARD' });
  reasonOptions.value = pageItems;

  breadcrumbStore.setBreadcrumbs([
    {
      text: i18n.t('label.anchor.clients'),
      href: '#/clients'
    },
    {
      text: selectedCreditCard.value.customerName,
      href: `#/viewclient/${selectedCreditCard.value.clientId}`
    },
    {
      text: `${selectedCreditCard.value.creditCardName} (${selectedCreditCard.value.creditCardNumber})`,
      href: `#/viewcreditaccount/${creditAccountId}`
    },
    { text: i18n.t('label.anchor.createDispute') }
  ]);
});

const selectedTransactions = computed(() => disputeCreditTxStore.selectedTransactions);
const selectedCreditCard = computed(() => {
  const creditAccount = disputeCreditTxStore.selectedCreditCard;
  return {
    customerName: creditAccount.creditAccountData?.clientName,
    cardType: 'Credit',
    creditCardNumber: creditAccount.cardNumber,
    clientId: creditAccount.creditAccountData.clientId,
    creditCardName: creditAccount.creditAccountData.name,
    creditAccountId: creditAccount.creditAccountData.id
  };
});

const disputeDetails = ref({
  customerName: selectedCreditCard.value.customerName,
  cardType: selectedCreditCard.value.cardType,
  creditCardNumber: selectedCreditCard.value.creditCardNumber
});

const editDisputeAmount = (value, txId) => {
  disputeCreditTxStore.updateDisputeAmounts(value, txId);
};

const proceed = async () => {
  try {
    const { data } = await apiService.creditCardDispute.getDisputeFieldsTemplate(formData.disputeReasonId);
    fieldsTemplates.value = data;
  } catch (error) {
    $notify.error(error);
  }
};

const onSubmit = async (disputeData) => {
  try {
    await apiService.creditCardDispute.createDispute({
      disputeReasonId: formData.disputeReasonId,
      dateOfDispute: dateStringToFormat(formData.disputeDate, 'YYYY-MM-DD'),
      transactions: disputeCreditTxStore.getDisputeTransactions(),
      disputeType: 'CREDIT_CARD',
      disputeData,
      accountId: selectedCreditCard.value.creditAccountId,
      creditCardId: creditAccountId
    }, { paymentRail: 'creditCard' });
    backToCreditAccount();
  } catch (error) {
    $notify.error(error);
  }
};

const removeTransaction = (transaction) => {
  disputeCreditTxStore.removeTransaction(transaction);
};

const backToCreditAccount = () => {
  disputeCreditTxStore.clearDisputeCreditTx();
  location.assign(`#/viewcreditaccount/${creditAccountId}`);
};
</script>
