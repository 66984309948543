<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.heading.documentUploadTokens') }}
    </template>
    <div class="d-flex justify-content-end pb-2">
      <button
        class="btn btn-primary"
        @click="$refs['confirmGenerateNewUploadToken'].show()"
      >
        {{ $t('label.button.generateNewUploadToken') }}
      </button>
      <base-modal
        ref="confirmGenerateNewUploadToken"
        @on-submit="generateDocUploadToken"
      >
        <template #header>
          <h4>{{ $t('label.heading.confirmation') }}</h4>
        </template>
        <template #body>
          <div>{{ $t('text.confirmGenerateNewUploadToken') }}</div>
        </template>
      </base-modal>
    </div>
    <data-grid
      :fields="fields"
      :items="getCurrentPageTokens"
      :total-records="totalRecords"
      @pagination-update="value => pageOffset = value"
      @entries-per-page="value => tokenPerPage = value"
    >
      <template #token="{item}">
        <clipboard
          :value="item.token"
          variable-translate="token"
        >
          {{ textCencored(item.token) }}
        </clipboard>
      </template>
      <template #link="{item}">
        <clipboard
          v-if="docUploadUrl && item.token"
          :value="`${docUploadUrl}/${item.token}`"
          variable-translate="token"
        >
          {{ `${docUploadUrl}/${textCencored(item.token)}` }}
        </clipboard>
      </template>
      <template #expireAt="{item}">
        {{ dateStringToFormat(item.expireAt) }}
      </template>
    </data-grid>
  </main-content-layout>
</template>

<script setup>
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import DataGrid from '@/components/shared/data-grid/index.vue';
import apiService from '@/services/apiService';
import { onMounted, ref, computed } from 'vue';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import i18n from '@/plugins/i18n';
import router from '@/router/index';
import BaseModal from '@/components/shared/BaseModal.vue';
import { $notify } from '@/plugins/prototypes';
import Clipboard from '@/components/shared/Clipboard.vue';
import { censoredText } from '@/helper/censoredText.js';
import { dateStringToFormat } from '@/helper/dateHelper';
import { get } from '@vueuse/core';

const textCencored = censoredText();

const breadCrumbsStore = useBreadcrumbsStore();

const generatedTokenList = ref([]);
const totalRecords = ref(0);
const pageOffset = ref(0);
const tokenPerPage = ref(15);
const fields = ref([
  { key: 'token', label: i18n.t('label.heading.token') },
  { key: 'link', label: i18n.t('label.heading.link') },
  { key: `createdBy.username`, label: i18n.t('label.heading.createdBy') },
  { key: 'expireAt', label: i18n.t('label.heading.expireAt') }
]);
const { clientId } = router.currentRoute.params;

onMounted(async () => {
  const { data: { displayName } } = await apiService.clients.get(clientId);

  breadCrumbsStore.setBreadcrumbs([
    {
      text: i18n.t('label.anchor.clients'),
      href: '#/clients'
    },
    {
      text: displayName,
      href: `#/viewclient/${clientId}`
    },
    {
      text: i18n.t('label.anchor.generateDocUploadToken')
    }
  ]);
  getGeneratedDocUploadToken();
});

const docUploadUrl = computed(() => {
  return process?.env?.VUE_APP_DOC_UPLOAD_URL;
});

const getCurrentPageTokens = computed(() => {
  const temp = [...get(generatedTokenList)];
  const startIndex = get(pageOffset);

  return temp.splice(startIndex, get(tokenPerPage));
});

const getGeneratedDocUploadToken = async () => {
  try {
    const { data: { pageItems, totalFilteredRecords } } = await apiService.clients.getGeneratedDocUploadToken(clientId);
    generatedTokenList.value = pageItems;
    totalRecords.value = totalFilteredRecords;
  } catch (error) {
    $notify.error(error);
  }
};

const confirmGenerateNewUploadToken = ref(null);
const generateDocUploadToken = async () => {
  try {
    await apiService.clients.generateDocUploadToken(clientId);
    getGeneratedDocUploadToken();
    $notify.success(i18n.t('messages.success.generateDocUploadToken'));
  } catch (error) {
    $notify.error(error);
  } finally {
    confirmGenerateNewUploadToken.value.hide();
  }
};
</script>
