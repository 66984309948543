export function UserController ($scope, resourceFactory) {
  $scope.users = [];
  $scope.newUserFormDialog = function () {
    $scope.$broadcast('OpenUserFormDialog', { title: 'New User' });
  };
  resourceFactory.userResource.getAllUsers({ fields: 'id,firstname,lastname,username,officeName' }, function (data) {
    $scope.users = data;
  });
}

UserController.$inject = ['$scope', 'ResourceFactory'];
