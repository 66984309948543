<template>
  <b-modal
    id="tx-details-modal"
    hide-header-close
    size="xl"
    footer-class="justify-content-start"
    title="Transaction Details"
    scrollable
  >
    <div
      v-if="transaction?.transfer"
      class="tx-monitoring"
    >
      <div class="row mb-2">
        <div class="col-4">
          <b>Screened Date:</b>&nbsp;{{ transaction.dateScreened }}
        </div>
        <div class="col-4">
          <b>Transaction ID:</b>&nbsp;{{ transaction.transfer.id }}
        </div>
        <div class="col-4">
          <b>Transaction Status:</b>&nbsp;{{ transaction.transactionStatus }}
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-4">
          <b>Creditor:</b>&nbsp;{{ transaction.transfer.creditor.identifier }}
        </div>
        <div class="col-4">
          <b>Debtor:</b>&nbsp;{{ transaction.transfer.debtor.identifier }}
        </div>
        <div class="col-4">
          <b>Transaction Amount:</b>&nbsp;{{ transaction.transfer.amount }}
          {{ transaction.transfer?.currencyData?.displaySymbol }}
        </div>
      </div>

      <div class="row mb-3">
        <div
          v-if="transaction.transfer?.paymentSubType"
          class="col-4"
        >
          <b>{{ $t('label.heading.paymentSubType') }}:</b>&nbsp;{{ transaction.transfer.paymentSubType }}
        </div>
      </div>

      <div class="row">
        <div
          v-if="metabaseUrl"
          class="col-12"
        >
          <a
            target="_blank"
            :href="metabaseUrl"
          ><b>Transactions insight</b></a>
        </div>
      </div>

      <div v-if="rawPaymentDetails">
        <hr>
        <h5>Raw Transaction Info</h5>
        <pre><code>{{ rawPaymentDetails }}</code></pre>
      </div>

      <div class="pt-4">
        <table class="table">
          <thead>
            <th>Flagged</th>
            <th>Rule Name</th>
            <th>Details</th>
          </thead>

          <tbody>
            <tr
              v-for="detail in transactionDetail"
              :key="detail.id"
            >
              <td :class="['text-uppercase', { 'text-success': !detail.flagged }, { 'text-danger': detail.flagged }]">
                {{ detail.flagged }}
              </td>
              <td>{{ detail.ruleGroupName }}</td>
              <td>
                <a
                  v-if="detail.transactionDetail?.url"
                  :href="detail.transactionDetail?.url"
                  target="_blank"
                >{{
                  detail.transactionDetail?.detail }}</a>
                <span v-else>{{ detail.transactionDetail?.detail }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pt-4">
        <div class="row">
          <div
            v-if="transaction.stateChanges?.length"
            class="col"
          >
            <h5>Review</h5>
            <ul>
              <li
                v-for="(stateChange, index) in transaction.stateChanges"
                :key="index"
              >
                <b>{{ stateChange.state }}</b> by <b>{{ stateChange.updatedBy.username }}</b> at <b>{{
                  stateChange.updatedAt }}</b>, reason: <b>{{ stateChange.reason }}</b>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <template v-if="showComments">
        <section>
          <div
            v-if="transaction.verify"
            v-has-permission="'VERIFY_AML_SCREENING_RESULT'"
            class="pt-4"
          >
            <h5>Verify</h5>
            <div>By: <b>{{ transaction.verify?.madeBy.username }}</b></div>
            <div>On: <b>{{ transaction.verify?.madeAt }}</b></div>
            <div>Comment: <b>{{ transaction.verify?.comments }}</b></div>
          </div>

          <div
            v-else
            v-has-permission="'VERIFY_AML_SCREENING_RESULT'"
            class="pt-4"
          >
            <verification-comment comment-type="verifier" />
          </div>
        </section>

        <section>
          <div
            v-if="transaction.qc"
            v-has-permission="'QC_AML_SCREENING_RESULT'"
            class="pt-4"
          >
            <h5>QC</h5>
            <div>By: {{ transaction.qc?.madeBy.username }}</div>
            <div>On: {{ transaction.qc?.madeAt }}</div>
            <div>Comment: {{ transaction.qc?.comments }}</div>
          </div>

          <div
            v-else
            v-has-permission="'QC_AML_SCREENING_RESULT'"
            class="pt-4"
          >
            <verification-comment comment-type="qc" />
          </div>
        </section>
      </template>
    </div>

    <template #modal-footer="{ cancel }">
      <div class="w-100">
        <div class="d-flex gap-2 align-items-center justify-content-between">
          <div class="d-flex gap-2 align-items-center">
            <div
              v-if="transaction.state === 'ERROR'"
              class="me-3"
            >
              <i
                class="fa fa-exclamation-triangle text-danger me-2"
                aria-hidden="true"
              />Failed to perform AML check.
              Manually review or repost for AML check.
            </div>
            <div
              v-if="transaction.restrictedCountry"
              class="font-weight-bold"
            >
              {{ $t('label.heading.restrictedCountries') }} : {{ transaction.restrictedCountry }}
            </div>
            <template v-if="['HOLD', 'ERROR'].includes(transaction.state)">
              <button
                v-has-permission="'APPROVE_AML_SCREENING_RESULT'"
                class="btn btn-success"
                @click="changeStatus('approve')"
              >
                Approve
              </button>
              <button
                v-has-permission="'REJECT_AML_SCREENING_RESULT'"
                class="btn btn-danger"
                @click="changeStatus('reject')"
              >
                Reject
              </button>
              <button
                v-has-permission="'SUSPEND_AML_SCREENING_RESULT'"
                class="btn btn-warning"
                @click="changeStatus('suspend')"
              >
                Suspend
              </button>
              <button
                v-if="transaction.state === 'ERROR'"
                v-has-permission="'RESCREEN_AML_SCREENING_RESULT'"
                class="btn btn-primary"
                @click="changeStatus('rescreen')"
              >
                Repost
              </button>
            </template>
          </div>
          <button
            class="btn btn-secondary"
            @click="cancel"
          >
            {{ $t('label.button.close') }}
          </button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import VerificationComment from '@/components/transaction-monitoring/custom/VerificationComment.vue';
import { useTxMonitoringStore } from '@/stores/txmonitoring';
import apiService from '@/services/apiService';

export default {
  name: 'TxDetailsModal',
  components: {
    VerificationComment
  },
  data () {
    return {
      transactionDetail: []
    };
  },
  computed: {
    ...mapState(useTxMonitoringStore, ['transaction']),
    metabaseUrl () {
      return process.env.VUE_APP_METABASE_URL ? process.env.VUE_APP_METABASE_URL + this.getClientAccountNumber() : '';
    },
    rawPaymentDetails () {
      if (!this.transaction) return null;

      return JSON.stringify(this.transaction.transfer.rawPaymentDetails, null, 2);
    },
    showComments () {
      const transactionStatus = this.transaction?.transactionStatus || this.transaction?.transfer?.status;
      return ['SUSPENDED', 'GO', 'HOLD_GO', 'OFAC_APPROVE', 'STOP', 'HOLD_STOP', 'OFAC_REJECT'].includes(transactionStatus);
    },
    filteredTransactionDetails () {
      const details = [...this.transaction.details];
      return details.sort((a, b) => {
        if (a.flagged !== b.flagged) {
          return (a.flagged === true) ? -1 : 1;
        } else {
          return a.ruleType.localeCompare(b.ruleType);
        }
      });
    }
  },
  watch: {
    transaction: function (val) {
      if (val) {
        this.getTransactionDetails();
      }
    }
  },
  methods: {
    ...mapActions(useTxMonitoringStore, ['setSelectedAction']),
    getClientAccountNumber () {
      const { transfer, recipient = {}, originator = {} } = this.transaction || {};
      if (!transfer) return '';
      const client = {
        IN: recipient.accountNumber || transfer.recipient?.accountNumber,
        OUT: originator.accountNumber || transfer.originator?.accountNumber,
        INTERNAL: originator.accountNumber || transfer.originator?.accountNumber
      };
      return client[transfer.inOrOut];
    },
    close () {
      this.$bvModal.hide('tx-details-modal');
    },
    changeStatus (status) {
      this.setSelectedAction(status);
      this.$nextTick(() => this.$bvModal.show('tx-change-status-modal'));
    },
    async getTransactionDetails () {
      try {
        const { data: detailsList } = await apiService.newAmlScreeningResultDetailsResource.get(this.transaction.id);
        this.transactionDetail = detailsList;
      } catch (error) {
        console.error({ error });
      }
    }
  }
};
</script>
