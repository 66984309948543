<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.anchor.clientMonitoring') }}
    </template>
    <table-monitoring />
  </main-content-layout>
</template>

<script setup>
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { onMounted } from 'vue';
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import i18n from '@/plugins/i18n';
import { TableMonitoring } from '@/components/client-monitoring/index.js';

const breadcrumbsStore = useBreadcrumbsStore();

onMounted(() => {
  breadcrumbsStore.setBreadcrumbs([
    { text: i18n.t('label.anchor.clientMonitoring') }
  ]);
});
</script>
