export function EditWorkingDaysController ($scope, resourceFactory, location) {
  $scope.workingDays = [];
  $scope.selectedRepaymentType = '';
  $scope.compareWith = [
    { name: 'MO', value: 'Monday', code: 'day.monday' },
    { name: 'TU', value: 'Tuesday', code: 'day.tuesday' },
    { name: 'WE', value: 'Wednesday', code: 'day.wednesday' },
    { name: 'TH', value: 'Thursday', code: 'day.thursday' },
    { name: 'FR', value: 'Friday', code: 'day.friday' },
    { name: 'SA', value: 'Saturday', code: 'day.saturday' },
    { name: 'SU', value: 'Sunday', code: 'day.sunday' }
  ];
  resourceFactory.workingDaysResource.get(function (data) {
    $scope.selectedRepaymentType = data.repaymentRescheduleType.id;
    $scope.repaymentRescheduleOptions = data.repaymentRescheduleOptions; // Good have template options as part of a single REST call
    $scope.extendTermForDailyRepayments = data.extendTermForDailyRepayments;
    const temp = data.recurrence.split('=');
    const days = temp[3].split(',');

    for (const i in $scope.compareWith) {
      if (days.indexOf($scope.compareWith[i].name.toString()) > -1) {
        $scope.workingDays.push({
          day: $scope.compareWith[i].value,
          code: $scope.compareWith[i].code,
          value: true
        });
      } else {
        $scope.workingDays.push({
          day: $scope.compareWith[i].value,
          code: $scope.compareWith[i].code,
          value: false
        });
      }
    }
  });

  $scope.showLabel = function (day) {
    if (day !== 'Monday') {
      return true;
    }
    return false;
  };
  $scope.submit = function () {
    this.formData = {};
    const stringFormat = 'FREQ=WEEKLY;INTERVAL=1;BYDAY=';
    let selectedDays = '';
    for (const i in $scope.workingDays) {
      if ($scope.workingDays[i].value === true &&
        $scope.workingDays[i].day.indexOf($scope.compareWith[i].value.toString()) > -1) {
        if (selectedDays !== '') {
          selectedDays = `${selectedDays},`;
        }
        selectedDays = selectedDays.concat($scope.compareWith[i].name);
      }
    }
    if (selectedDays === '') {
      selectedDays = ',';
    }
    this.formData.repaymentRescheduleType = $scope.selectedRepaymentType;
    this.formData.recurrence = stringFormat.concat(selectedDays);
    this.formData.locale = $scope.optlang.code;
    this.formData.extendTermForDailyRepayments = $scope.extendTermForDailyRepayments;
    resourceFactory.workingDaysResource.put(this.formData, function () {
      location.path('/organization/');
    });
  };
}

EditWorkingDaysController.$inject = ['$scope', 'ResourceFactory', '$location'];
