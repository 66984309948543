<template>
  <span>
    <button
      class="btn btn-primary"
      @click="actionPrompt()"
    >
      {{ $t('label.button.disputeDetails') }}
    </button>
    <base-modal
      ref="disputeDetailsModal"
      modal-size="lg"
      show-button-close
      hide-footer
      scrollable
    >
      <template #header>
        <h4>{{ $t('label.heading.disputeDetails') }}</h4>
      </template>
      <template #body>
        <div v-if="!viewFile">
          <base-table
            :items="displayData"
            vertical
          >
            <template #value="{ item }">
              {{ item.value }}
              <button
                v-if="item.inputType === 'INPUT_FILE' && item.value"
                class="btn btn-link"
                :disabled="loading"
                @click="viewAttachment(item.value)"
              >
                {{ $t('label.button.viewFile') }}
              </button>
            </template>
          </base-table>
        </div>

        <div v-if="viewFile">
          <div>
            <button
              class="btn btn-link"
              @click="viewFile = !viewFile"
            >
              {{ $t('label.button.back') }}
            </button>
          </div>
          <img
            v-if="isImage(fileType)"
            class="img-fluid"
            :src="fileSrc"
            alt="img"
          >
          <iframe
            v-if="isPdf(fileType)"
            frameborder="0"
            width="100%"
            height="800px"
            :src="fileSrc"
          />
        </div>
      </template>
    </base-modal>
  </span>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import BaseTable from '@/components/shared/data-grid/BaseTable.vue';
import { downloadHelper, viewHelper, isImage, isPdf } from '@/helper/downloadHelper';
import apiService from '@/services/apiService';
import { ref, computed } from 'vue';
import { useSharedStore } from '@/stores/shared';
import { dateStringToFormat } from '@/helper/dateHelper';
import { Booleanish } from '@/utils/booleanish';

const sharedStore = useSharedStore();
const loading = computed(() => sharedStore.loading);

const props = defineProps({
  items: {
    type: Object,
    required: true
  }
});

const disputeDetailsModal = ref(null);
const viewFile = ref(false);
const fileSrc = ref(false);
const fileType = ref(null);

const displayData = computed(() =>
  props.items?.workFlowData?.map((field) => ({ label: field.label, value: normalizeData(field), inputType: field.inputType?.key })) || []
);

const normalizeData = (field) => {
  const { inputType, value } = field;
  if (inputType?.key === 'INPUT_DATE') return dateStringToFormat(value, 'DD MMMM YYYY');
  if (inputType?.key === 'INPUT_YES_NO') return Booleanish(value) ? 'Yes' : 'No';
  return value?.replace(/[[\]]/g, '');
};

const actionPrompt = () => {
  disputeDetailsModal.value.show();
};

const viewAttachment = async (docId) => {
  const { data } = await apiService.creditCardDispute.getDisputeAttachment(docId);
  if (isImage(data.type) || isPdf(data.type)) {
    fileType.value = data.type;
    viewFile.value = true;
    fileSrc.value = await viewHelper(data);
  } else {
    viewFile.value = false;
    downloadHelper(data, docId, data.type, 'bufferarray');
  }
};

</script>
