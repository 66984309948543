<template>
  <div>
    <div class="mb-2 d-flex justify-content-between">
      <div class="col-md-5">
        <TxSearch @onSearch="search" />
      </div>
    </div>
    <data-grid
      ref="table"
      :items="items"
      :limit="itemPerPage"
      :fields="fields"
      :total-records="totalRows"
      save-selected-on-page-change
      @entries-per-page="updateEntriesPerPage"
      @row-selected="selectedItem"
      @pagination-update="getPendingReturns"
    >
      <template #originatorRouteCode="data">
        <TxScreeningProfileTd :profile="data.item.originator" />
      </template>
      <template #recipientRouteCode="data">
        <TxScreeningProfileTd :profile="data.item.recipient" />
      </template>
      <template #Direction="data">
        {{ data.item.inOrOut === 'IN' ? 'INCOMING' : 'OUTGOING' }}
      </template>
      <template #Reference="data">
        <TxReferenceTd :reference-items="getReference(data)" />
      </template>
      <template #Actions="data">
        <div class="d-flex gap-2 flex-nowrap align-items-center">
          <button
            v-if="data.item.status === 'EXECUTION_SUCCESS'"
            class="btn btn-danger d-flex flex-nowrap align-items-center"
            @click="cancelPendingNOC(data.item.id)"
          >
            Cancel
          </button>

          <button
            v-else
            class="btn btn-success d-flex flex-nowrap align-items-center"
            @click="showEditTransfer(data.item)"
          >
            <i class="fa fa-edit" />&nbsp;Edit
          </button>

          <button
            v-if="['AML_REJECTED', 'EXECUTION_SUCCESS'].includes(data.item.status)"
            class="btn btn-info d-flex flex-nowrap align-items-center"
            @click="$emit('getPendingReturnsDetails', data.item)"
          >
            <i class="fa fa-search" />&nbsp;&nbsp;Details
          </button>
        </div>
      </template>
    </data-grid>
    <TxCancelNOC
      :id="selectedId"
      ref="cancelModalNOC"
      @onChanged="getPendingReturns"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import apiService from '@/services/apiService';
import TxScreeningProfileTd from '@/components/transaction-monitoring/custom/TxScreeningProfileTd.vue';
import TxReferenceTd from '@/components/transaction-monitoring/custom/TxReferenceTd.vue';
import { transferResponseAdapter } from '@/services/adapters/transaction-monitoring/transferResponseAdapter';
import { useTxMonitoringStore } from '@/stores/txmonitoring';
import DataGrid from '@/components/shared/data-grid/index.vue';
import TxSearch from '@/components/transaction-monitoring/custom/TxSearch.vue';
import TxCancelNOC from '../modals/TxCancelNOC.vue';

export default {
  name: 'TxPendingReturnACH',
  components: {
    TxScreeningProfileTd,
    TxReferenceTd,
    DataGrid,
    TxSearch,
    TxCancelNOC
  },
  data () {
    return {
      itemPerPage: 15,
      totalRows: 0,
      items: [],
      selectPendingReturnACH: [],
      disabledNoSelect: true,
      selectedId: null,
      offset: 0,
      fields: [
        { key: 'id', label: 'Transfer ID' },
        { key: 'displayAmount', label: 'Amount' },
        { key: 'originatorRouteCode', label: 'Originator bank routing code / account / name' },
        { key: 'recipientRouteCode', label: 'Recipient bank routing code / account / name' },
        { key: 'Direction', label: 'Direction' },
        { key: 'type', label: 'Type' },
        { key: 'paymentType', label: 'Payment Type' },
        { key: 'status', label: 'Status' },
        { key: 'errorCode', label: 'Error Code' },
        { key: 'errorMessage', label: 'Error Message' },
        { key: 'Reference', label: 'Reference' },
        { key: 'Actions', label: 'Actions' }
      ]
    };
  },
  computed: {
    ...mapState(useTxMonitoringStore, {
      needsToReloadList: 'needsToReloadList',
      paymentType: 'paymentType'
    })
  },
  watch: {
    needsToReloadList (val) {
      if (val) {
        this.getPendingReturns(this.offset);
        this.setNeedsToReloadList(false);
      }
    }
  },
  mounted () {
    this.getPendingReturns(this.offset);
  },
  methods: {
    ...mapActions(useTxMonitoringStore, ['setWatchmanLists', 'setPendingReturnChecked', 'setSelectedTransaction', 'setNeedsToReloadList']),
    getReference (reference) {
      return reference.item.reference || [];
    },
    selectedItem (items) {
      this.selectPendingReturnACH = items.map(item => item.id);
      this.disabledNoSelect = !this.selectPendingReturnACH.length;
    },
    updateEntriesPerPage (params) {
      this.itemPerPage = params;
      this.getPendingReturns(this.offset);
    },
    async getPendingReturns (offset, searchId) {
      this.offset = offset;
      const params = {
        sortOrder: 'DESC',
        offset: this.offset,
        limit: this.itemPerPage,
        transferId: searchId
      };

      try {
        const { data: { pageItems: transfers, totalFilteredRecords: totalRows } } = await apiService.transfer.getAllPendingNOC(params);
        this.totalRows = totalRows;
        this.items = transferResponseAdapter(transfers);
      } catch (e) {
        this.$notify.error(e);
      }
    },
    async showDetails (item) {
      this.setSelectedTransaction(item);
      this.setWatchmanLists(await apiService.watchmanLists.getWatchmanDetails(item.watchmanId));
      this.$nextTick(() => this.$bvModal.show('tx-details-modal'));
    },
    showEditTransfer (item) {
      this.setSelectedTransaction(item);
      this.$bvModal.show('tx-edit-transfer-modal');
    },
    clearAllSelect () {
      this.$refs.table.unSaveSelectedOnPageChange();
    },
    search (id) {
      this.getPendingReturns(this.offset, id);
    },
    async cancelPendingNOC (id) {
      this.selectedId = id;
      this.$refs.cancelModalNOC.$children[0].show();
    }
  }
};
</script>
