import { isString, isNumber, isUndefined } from 'underscore';
import { currencies } from '@/utils/dictionaries/currencies.dictionary';

const DECIMAL_SEP = '.';
const ZERO_CHAR = '0';
const MAX_DIGITS = 22;
const DEFAULT_CURRENCY_SYMBOL = '$';
const DEFAULT_FRACTION_SIZE = 2;
const DEFAULT_DECIMAL_SEP = '.';
const DEFAULT_GROUP_SEP = ',';
const DEFAULT_PATTERNS = [
  {
    gSize: 3,
    lgSize: 3,
    maxFrac: 3,
    minFrac: 0,
    minInt: 1,
    negPre: '-',
    negSuf: '',
    posPre: '',
    posSuf: ''
  },
  {
    gSize: 3,
    lgSize: 3,
    maxFrac: 2,
    minFrac: 2,
    minInt: 1,
    negPre: '-',
    negSuf: '',
    posPre: '',
    posSuf: ''
  }
];

export function currencyFormatter (amount, currencySymbol = DEFAULT_CURRENCY_SYMBOL, fractionSize = DEFAULT_FRACTION_SIZE) {
  return !amount ? amount : currencySymbol + formatNumber(amount, DEFAULT_PATTERNS[1], DEFAULT_GROUP_SEP, DEFAULT_DECIMAL_SEP, fractionSize);
}

function formatNumber (number, pattern, groupSep, decimalSep, fractionSize) {
  if (!(isString(number) || isNumber(number)) || isNaN(number)) return '';

  const isInfinity = !isFinite(number);
  let isZero = false;
  const numStr = `${Math.abs(number)}`;
  let formattedText = '';
  // let parsedNumber

  if (isInfinity) {
    formattedText = '\u221e';
  } else {
    const parsedNumber = parse(numStr);
    let { digits, numberOfIntegerDigits: integerLen } = parsedNumber;
    const { exponent } = parsedNumber;
    let decimals = [];

    roundNumber({ digits, integerLen }, fractionSize, pattern.minFrac, pattern.maxFrac);

    isZero = digits.reduce((isZero, d) => isZero && !d, true);

    // pad zeros for small numbers
    while (integerLen < 0) {
      digits.unshift(0);
      integerLen += 1;
    }

    // extract decimals digits
    if (integerLen > 0) {
      decimals = digits.splice(integerLen, digits.length);
    } else {
      decimals = digits;
      digits = [0];
    }

    // format the integer digits with grouping separators
    const groups = [];
    if (digits.length >= pattern.lgSize) {
      groups.unshift(digits.splice(-pattern.lgSize, digits.length).join(''));
    }
    while (digits.length > pattern.gSize) {
      groups.unshift(digits.splice(-pattern.gSize, digits.length).join(''));
    }
    if (digits.length) {
      groups.unshift(digits.join(''));
    }
    formattedText = groups.join(groupSep);

    // append the decimal digits
    if (decimals.length) {
      formattedText += decimalSep + decimals.join('');
    }

    if (exponent) {
      formattedText += `e+${exponent}`;
    }
  }
  if (number < 0 && !isZero) {
    return pattern.negPre + formattedText + pattern.negSuf;
  }

  return pattern.posPre + formattedText + pattern.posSuf;
}

function parse (numStr) {
  let exponent = 0;

  // Decimal point?
  let numberOfIntegerDigits = numStr.indexOf(DECIMAL_SEP);
  if (numberOfIntegerDigits > -1) {
    numStr = numStr.replace(DECIMAL_SEP, '');
  }

  // Exponential form?
  const isExponential = numStr.search(/e/i);
  if (isExponential > 0) {
    // Work out the exponent.
    if (numberOfIntegerDigits < 0) { numberOfIntegerDigits = isExponential; }
    numberOfIntegerDigits += +numStr.slice(isExponential + 1);
    numStr = numStr.substring(0, isExponential);
  } else if (numberOfIntegerDigits < 0) {
    // There was no decimal point or exponent so it is an integer.
    numberOfIntegerDigits = numStr.length;
  }

  // Count the number of leading zeros.
  let leadingZeros = 0;
  for (let i = 0; numStr.charAt(i) === ZERO_CHAR; i += 1) {
    leadingZeros += 1;
  }

  let zeros = numStr.length;
  let digits = [];
  if (isExponential === zeros) {
    // The digits are all zero.
    digits = [0];
    numberOfIntegerDigits = 1;
  } else {
    // Count the number of trailing zeros
    zeros -= 1;
    while (numStr.charAt(zeros) === ZERO_CHAR) zeros -= 1;

    // Trailing zeros are insignificant so ignore them
    numberOfIntegerDigits -= leadingZeros;
    digits = [];
    // Convert string to array of digits without leading/trailing zeros.
    for (let j = 0; leadingZeros <= zeros; leadingZeros += 1, j += 1) {
      digits[j] = +numStr.charAt(leadingZeros);
    }
  }

  // If the number overflows the maximum allowed digits then use an exponent.
  if (numberOfIntegerDigits > MAX_DIGITS) {
    digits = digits.splice(0, MAX_DIGITS - 1);
    exponent = numberOfIntegerDigits - 1;
    numberOfIntegerDigits = 1;
  }

  return { digits, exponent, numberOfIntegerDigits };
}

function roundNumber (parsedNumber, fractionSize, minFrac, maxFrac) {
  const digits = parsedNumber.digits;
  let fractionLen = digits.length - parsedNumber.integerLen;

  // determine fractionSize if it is not specified; `+fractionSize` converts it to a number
  fractionSize = isUndefined(fractionSize) ? Math.min(Math.max(minFrac, fractionLen), maxFrac) : +fractionSize;

  // The index of the digit to where rounding is to occur
  let roundAt = fractionSize + parsedNumber.integerLen;
  const digit = digits[roundAt];

  if (roundAt > 0) {
    // Drop fractional digits beyond `roundAt`
    digits.splice(Math.max(parsedNumber.integerLen, roundAt));

    // Set non-fractional digits beyond `roundAt` to 0
    for (let j = roundAt; j < digits.length; j += 1) {
      digits[j] = 0;
    }
  } else {
    // We rounded to zero so reset the parsedNumber
    fractionLen = Math.max(0, fractionLen);
    parsedNumber.integerLen = 1;
    digits.length = Math.max(1, roundAt = fractionSize + 1);
    digits[0] = 0;
    for (let i = 1; i < roundAt; i += 1) digits[i] = 0;
  }

  if (digit >= 5) {
    if (roundAt - 1 < 0) {
      for (let k = 0; k > roundAt; k -= 1) {
        digits.unshift(0);
        parsedNumber.integerLen += 1;
      }
      digits.unshift(1);
      parsedNumber.integerLen += 1;
    } else {
      digits[roundAt - 1] += 1;
    }
  }

  // Pad out with zeros to get the required fraction length
  for (; fractionLen < Math.max(0, fractionSize); fractionLen += 1) digits.push(0);

  // Do any carrying, e.g. a digit was rounded up to 10
  const carry = digits.reduceRight(function (carry, d, i, digits) {
    d += carry;
    digits[i] = d % 10;
    return Math.floor(d / 10);
  }, 0);
  if (carry) {
    digits.unshift(carry);
    parsedNumber.integerLen += 1;
  }
}

export const currencyIsoCodeToLetterCode = (currencyIsoCode) => {
  const currency = currencies.find(currency => currency.number === String(currencyIsoCode));
  return currency ? currency.code : currencyIsoCode;
};

export const formatExchangeRate = (num) => {
  if (!num) return num;
  const stringNum = String(num);
  if (stringNum.length < 2) return num;
  const decimalDigits = Number(stringNum[0]);
  return Number(stringNum.substring(1)) / (Math.pow(10, decimalDigits));
};
