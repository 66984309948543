// (function (module) {
//   mbanq.directives = _.extend(module, {
//     BinInputDirective () {
//       return {
//         require: 'ngModel',
//         link(scope, element, attr, mCtrl) {
//           element.bind('blur', function () {
//             scope.$apply(function () {
//               if(element.val().length === 6 || element.val().length === 8 || element.val().length === 0){
//                 mCtrl.$setValidity('binLength', true)
//               } else {
//                 mCtrl.$setValidity('binLength', false)
//               }
//             })
//           })
//         }
//       }
//     }
//   })
// }(mbanq.directives || {}))

// mbanq.ng.application.directive('binInput', [mbanq.directives.BinInputDirective]).run(function ($log) {
//   $log.info('BinInputDirective initialized')
// })

export function BinInputDirective () {
  return {
    require: 'ngModel',
    link (scope, element, attr, mCtrl) {
      element.bind('blur', function () {
        scope.$apply(function () {
          if (element.val().length >= 6 || element.val().length <= 8 || element.val().length === 0) {
            mCtrl.$setValidity('binLength', true);
          } else {
            mCtrl.$setValidity('binLength', false);
          }
        });
      });
    }
  };
}
