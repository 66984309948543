export function PaymentController (scope, routeParams, resourceFactory) {
  scope.ruleId = '';
  function loadData () {
    resourceFactory.amlGroupsResource.get({},
      function (response) {
        const ruleGroups = response.pageItems;
        scope.ruleGroup = ruleGroups.find(ruleGroup => ruleGroup.type === 'RESTRICTED_COUNTRY');
        if (scope.ruleGroup) {
          scope.ruleId = scope.ruleGroup.id;
        }
      }
    );
  }
  loadData();
}

PaymentController.$inject = ['$scope', '$routeParams', 'ResourceFactory'];
