export function ViewNameMatchingController (scope, routeParams, resourceFactory, location) {
  const ruleId = routeParams.id;

  function loadRuleGroup () {
    resourceFactory.amlNameMatchsResource.get({ ruleId }, function (res) {
      scope.ruleGroup = res;
    }, handleResponseError);
  }

  loadRuleGroup();

  // Actions on Rule Group
  scope.routeToEditRuleGroup = function () {
    location.path(`/payments/aml/editnamematching/${ruleId}`);
  };

  scope.deleteRule = function () {
    resourceFactory.amlNameMatchsResource.delete({ ruleId }, function () {
      location.path(`/payments/aml/rulegroups`);
    }, handleResponseError);
  };

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
}

ViewNameMatchingController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
