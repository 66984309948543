export function EditStopPaymentController (scope, resourceFactory, routeParams, location, dateFilter) {
  scope.stoppaymentId = routeParams.stoppaymentId;
  scope.savingAccountId = routeParams.id;

  scope.routeTo = function () {
    location.path(`viewsavingaccount/${scope.savingAccountId}`);
  };

  resourceFactory.stopPaymentResourceTemplate.get({ savingAccountId: scope.savingAccountId }, function (data) {
    const { type: stopPaymentType, reason: stopPaymentReason, stopPaymentTill } = data;
    scope.stopPaymentType = stopPaymentType;
    scope.reason = stopPaymentReason;
    scope.stopPaymentTills = stopPaymentTill.map(stopPaymentValue => {
      const stopPayment = {};
      stopPayment[stopPaymentValue] = stopPaymentValue.replace(/_/g, ' ');
      return stopPayment;
    });
  });

  resourceFactory.stopPaymentResource.get({ savingAccountId: scope.savingAccountId, stoppaymentsId: scope.stoppaymentId }, function (data) {
    const {
      checkNumberFrom, checkNumberTo, minAmount, active, maxAmount, payee, type, stopPaymentTill, endDate, reason: { id: reasonId }
    } = data;
    scope.formData = { checkNumberFrom, checkNumberTo, minAmount, maxAmount, payee, type, stopPaymentTill, reasonId, endDate, active };
    scope.formData.endDate = new Date(endDate);
  });

  scope.submit = function () {
    let endDate = '';
    scope.formData.locale = 'en';

    if (scope.formData.minAmount > scope.formData.maxAmount) {
      scope.error = 'Min Amount must lower than Max Amount';
      return;
    }

    if (scope.formData.stopPaymentTill === 'UNTIL_DATE') {
      scope.formData.dateFormat = 'yyyy-MM-dd';
      endDate = dateFilter(scope.formData.endDate, scope.formData.dateFormat);
    } else {
      delete scope.formData.dateFormat;
      delete scope.formData.endDate;
    }
    resourceFactory.stopPaymentResource.update({ savingAccountId: scope.savingAccountId, stoppaymentsId: scope.stoppaymentId }, { ...scope.formData, endDate }, function () {
      location.path(`/viewsavingaccount/${scope.savingAccountId}`);
    });
  };
}

EditStopPaymentController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location', 'dateFilter'];
