export function ViewTransferLimitsController ($scope, resourceFactory, location, $uibModal, route) {
  $scope.transferGlobalLimits = [];
  $scope.transferLimits = [];
  $scope.itemsPerPage = 15;
  $scope.totalTransferLimits = '';
  $scope.totalGlobalTransferLimits = '';
  const currentPage = {
    transferGlobalLimits: 1,
    transferLimits: 1
  };
  $scope.durations = ['DAILY', 'WEEKLY', 'MONTHLY'];
  $scope.transferTypes = ['CREDIT', 'DEBIT'];
  $scope.paymentTypes = [];
  $scope.formData = {};

  resourceFactory.transferLimitsTemplate.get(function (data) {
    $scope.paymentTypes = data.transferPaymentRailsTypes;
    $scope.legalForms = data.clientLegalFormOptions;
    $scope.transferTypes = data.transferTypes;
    $scope.durations = data.durations;
    $scope.classifications = data.clientClassificationOptions;
  });

  $scope.updateLegalForm = function () {
    $scope.classificationId = null;
    $scope.clientClassificationsOptions = $scope.classifications.filter(function (data) {
      return $scope.formData.legalForm === 1
        ? data.codeName === 'ClientClassification-PERSON'
        : data.codeName === 'ClientClassification-ENTITY';
    });
  };

  $scope.clear = function () {
    $scope.formData = {};
    $scope.getGlobalTransferLimits();
    $scope.getTransferLimits();
  };

  $scope.getGlobalTransferLimits = function (pageNumber = 1) {
    resourceFactory.transferLmitsResource.get({
      offset: ((pageNumber - 1) * $scope.itemsPerPage),
      limit: $scope.itemsPerPage,
      isGlobal: true,
      ...$scope.formData
    },
    function (data) {
      $scope.transferGlobalLimits = data.pageItems;
      $scope.totalGlobalTransferLimits = data.totalFilteredRecords;
      currentPage.transferGlobalLimits = pageNumber;
    });
  };

  $scope.getTransferLimits = function (pageNumber = 1) {
    resourceFactory.transferLmitsResource.get({
      offset: ((pageNumber - 1) * $scope.itemsPerPage),
      limit: $scope.itemsPerPage,
      isGlobal: false,
      ...$scope.formData
    },
    function (data) {
      $scope.transferLimits = data.pageItems;
      $scope.totalTransferLimits = data.totalFilteredRecords;
      currentPage.transferLimits = pageNumber;
    });
  };

  $scope.searchTransferLimits = function () {
    $scope.getGlobalTransferLimits();
    $scope.getTransferLimits();
  };

  $scope.getGlobalTransferLimits();
  $scope.getTransferLimits();

  $scope.deleteTransferLimits = function (id) {
    resourceFactory.transferLmitsResource.delete({ transferLimitsId: id }, function () {
      $scope.getTransferLimits(currentPage.transferLimits);
    });
  };

  $scope.updateTransferLimits = function (id) {
    location.path(`/payments/transfer-limits/${id}/edit`);
  };

  $scope.toggleInactiveLimitStatus = function (limit, value) {
    limit.isActive = value;
    resourceFactory.transferLmitsResource.update({ transferLimitsId: limit.id }, { ...limit, locale: 'en' }, function () {
      $scope.getTransferLimits(currentPage.transferLimits);
    }, () => {
      limit.isActive = !value;
    });
  };

  $scope.delete = function (id) {
    $uibModal.open({
      templateUrl: 'delete.html',
      controller: deleteCtrl,
      resolve: {
        id () {
          return id;
        }
      }
    });
  };

  const deleteCtrl = function ($scope, $uibModalInstance, id) {
    $scope.confirm = function () {
      resourceFactory.transferLmitsResource.delete({ transferLimitsId: id }, function () {
        $uibModalInstance.dismiss('close');
        route.reload();
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewTransferLimitsController.$inject = ['$scope', 'ResourceFactory', '$location', '$uibModal', '$route'];
