import { yearDiff } from '../../../../helper/dateHelper';

export function EditFamilyMemberController ($scope, resourceFactory, routeParams, dateFilter, location) {
  const clientId = routeParams.clientId;
  const familyMemberId = routeParams.familyMemberId;

  $scope.formData = {};
  $scope.date = {};
  $scope.minRestricDate = yearDiff(100);

  resourceFactory.familyMemberTemplate.get({ clientId }, function (data) {
    $scope.relationshipIdOptions = data.relationshipIdOptions;
    $scope.genderIdOptions = data.genderIdOptions;
    $scope.maritalStatusIdOptions = data.maritalStatusIdOptions;
    $scope.professionIdOptions = data.professionIdOptions;
  });

  resourceFactory.familyMember.get({ clientId, clientFamilyMemberId: familyMemberId }, function (data) {
    $scope.formData = data;

    if (data.dateOfBirth) {
      const dobDate = dateFilter(data.dateOfBirth, $scope.df);
      $scope.date.dateOfBirth = new Date(dobDate);
    }
  });

  $scope.routeTo = function () {
    location.path(`/viewclient/${clientId}`);
  };

  $scope.updateClientMember = function () {
    delete $scope.formData.maritalStatus;
    delete $scope.formData.gender;
    delete $scope.formData.profession;
    delete $scope.formData.relationship;

    this.formData.locale = $scope.optlang.code;
    this.formData.dateFormat = $scope.df;

    if ($scope.date.dateOfBirth) {
      this.formData.dateOfBirth = dateFilter($scope.date.dateOfBirth, $scope.df);
    }
    resourceFactory.familyMember.put({ clientId, clientFamilyMemberId: familyMemberId }, $scope.formData, function () {
      location.path(`/viewclient/${clientId}`);
    });
  };
}

EditFamilyMemberController.$inject = ['$scope', 'ResourceFactory', '$routeParams', 'dateFilter', '$location'];
