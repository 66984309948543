export function LegalFormIcon () {
  return (type) => {
    switch (type) {
      case 'legalFormType.person':
        return 'fa-user';
      case 'legalFormType.entity':
        return 'fa-suitcase';
      default:
        return '';
    }
  };
}
