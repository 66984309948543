export function CreateReportController (scope, resourceFactory, location) {
  scope.formData = {};
  scope.reportParameters = [];
  scope.flag = false;
  resourceFactory.reportsResource.getReportDetails({ resourceType: 'template' }, function (data) {
    scope.reportdetail = data;
    scope.formData.reportType = data.allowedReportTypes[0];
  });

  scope.parameterSelected = function (allowedParameterId) {
    if (!allowedParameterId) return;

    scope.flag = true;
    for (const i in scope.reportdetail.allowedParameters) {
      if (scope.reportdetail.allowedParameters[i].id === Number(allowedParameterId)) {
        scope.reportParameters.push({
          parameterId: allowedParameterId,
          id: '',
          allowedParameterName: scope.reportdetail.allowedParameters[i].parameterName
        });
      }
    }
    scope.allowedParameterId = '';
  };

  function deepCopy (obj) {
    if (Object.prototype.toString.call(obj) === '[object Array]') {
      const out = []; let i = 0; const len = obj.length;
      for (; i < len; i++) {
        out[i] = deepCopy(obj[i]);
      }
      return out;
    }
    if (typeof obj === 'object') {
      const out = {}; let i;
      for (i in obj) {
        out[i] = deepCopy(obj[i]);
      }
      return out;
    }
    return obj;
  }

  scope.deleteParameter = function (index) {
    scope.reportParameters.splice(index, 1);
  };

  scope.submit = function () {
    scope.temp = deepCopy(scope.reportParameters);
    for (const i in scope.temp) {
      delete scope.temp[i].allowedParameterName;
    }
    this.formData.reportParameters = scope.temp;
    resourceFactory.reportsResource.save(this.formData, function (data) {
      location.path(`/system/viewreport/${data.resourceId}`);
    });
  };
}

CreateReportController.$inject = ['$scope', 'ResourceFactory', '$location'];
