export function ViewSchedulerJobHistoryController (scope, resourceFactory, paginatorService, routeParams, $uibModal) {
  scope.jobhistory = [];
  const fetchFunction = function (offset, limit, callback) {
    resourceFactory.jobsResource.getJobHistory({ id: routeParams.id, resourceType: 'runhistory', offset, limit }, callback);
  };

  scope.jobhistory = paginatorService.paginate(fetchFunction, 14);

  scope.errorLog = function (history) {
    $uibModal.open({
      templateUrl: 'errorlog.html',
      controller: ErrorLogCtrl,
      resolve: {
        history () {
          return history;
        }
      }
    });
  };

  const ErrorLogCtrl = function ($scope, $uibModalInstance, history) {
    $scope.error = history;
    $scope.cancel = function () {
      $uibModalInstance.dismiss('close');
    };
  };
}

ViewSchedulerJobHistoryController.$inject = ['$scope', 'ResourceFactory', 'paginatorService', '$routeParams', '$uibModal'];
