export function ViewHookController (scope, routeParams, route, location, resourceFactory, $uibModal) {
  scope.hook = [];
  scope.formData = {};

  resourceFactory.webhooksResource.get({ eventAction: 'endpoints', eventActionId: routeParams.id }, (response) => {
    scope.hook = response;
  });

  scope.deletehook = function () {
    $uibModal.open({
      templateUrl: 'deletehook.html',
      controller: HookDeleteCtrl
    });
  };

  const HookDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      (function deleteHook () {
        resourceFactory.webhooksResource.delete({ eventAction: 'endpoints', eventActionId: routeParams.id }, () => {
          $uibModalInstance.close('delete');
          location.path('/hooks');
        });
      })();
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewHookController.$inject = ['$scope', '$routeParams', '$route', '$location', 'ResourceFactory', '$uibModal'];
