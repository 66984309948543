import angular from 'angular';
import { HttpServiceProvider } from './HttpServiceProvider';
import { ResourceFactoryProvider } from './ResourceFactoryProvider';

export default angular
  .module('mbanq.providers', [])
  .config(($provide) => {
    $provide.provider('ResourceFactory', ResourceFactoryProvider);
  })
  .config(($provide) => {
    $provide.provider('HttpService', HttpServiceProvider);
  })
  .name;
