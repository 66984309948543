export function PaginatorService () {
  this.paginate = function (fetchFunction, pageSize) {
    const paginator = {
      hasNextVar: false,
      next () {
        if (this.hasNextVar) {
          this.currentOffset += pageSize;
          this.load();
        }
      },
      load () {
        const self = this;
        fetchFunction(this.currentOffset, pageSize, function (items) {
          self.currentPageItems = items.pageItems;
          self.hasNextVar = items.pageItems.length === pageSize;
        });
      },
      hasNext () {
        return this.hasNextVar;
      },
      previous () {
        if (this.hasPrevious()) {
          this.currentOffset -= pageSize;
          this.load();
        }
      },
      hasPrevious () {
        return this.currentOffset !== 0;
      },
      currentPageItems: [],
      currentOffset: 0
    };
    // Load the first page
    paginator.load();
    return paginator;
  };
}

// (function (module) {
//     mbanq.services = _.extend(module, {
//         PaginatorService: function (scope, httpService) {

//             this.paginate = function (fetchFunction, pageSize) {
//                 var paginator = {
//                     hasNextVar: false,
//                     next: function () {
//                         if (this.hasNextVar) {
//                             this.currentOffset += pageSize;
//                             this.load();
//                         }
//                     },
//                     load: function () {
//                         var self = this;
//                         fetchFunction(this.currentOffset, pageSize, function (items) {
//                             self.currentPageItems = items.pageItems;
//                             self.hasNextVar = items.pageItems.length === pageSize;
//                         });
//                     },
//                     hasNext: function () {
//                         return this.hasNextVar;
//                     },
//                     previous: function () {
//                         if (this.hasPrevious()) {
//                             this.currentOffset -= pageSize;
//                             this.load();
//                         }
//                     },
//                     hasPrevious: function () {
//                         return this.currentOffset !== 0;
//                     },
//                     currentPageItems: [],
//                     currentOffset: 0
//                 };
//                 // Load the first page
//                 paginator.load();
//                 return paginator;
//             };

//         }
//     });
//     mbanq.ng.services.service('PaginatorService', ['$rootScope', 'HttpService', mbanq.services.PaginatorService]).run(function ($log) {
//         $log.info("PaginatorService initialized");
//     });
// }(mbanq.services || {}));
