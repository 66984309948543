// (function (module) {
//   mbanq.directives = _.extend(module, {
//     OnBlurDirective ($parse) {
//       return function (scope, elm, attrs) {
//         const onBlurFunction = $parse(attrs.ngOnBlur)
//         elm.bind('blur', function (event) {
//           scope.$apply(function () {
//             onBlurFunction(scope, { $event: event })
//           })
//         })
//       }
//     }
//   })
// }(mbanq.directives || {}))

// mbanq.ng.application.directive('ngOnBlur', ['$parse', mbanq.directives.OnBlurDirective]).run(function ($log) {
//   $log.info('OnBlurDirective initialized')
// })

export function OnBlurDirective ($parse) {
  return function (scope, elm, attrs) {
    const onBlurFunction = $parse(attrs.ngOnBlur);
    elm.bind('blur', function (event) {
      scope.$apply(function () {
        onBlurFunction(scope, { $event: event });
      });
    });
  };
}

OnBlurDirective.$inject = ['$parse'];
