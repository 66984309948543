<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.anchor.paymentsManagement') }}
    </template>

    <div class="container-fluid pe-0">
      <list-view :items-list="itemsList" />
    </div>
  </main-content-layout>
</template>

<script>
import ListView from '@/components/shared/ListView.vue';
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import apiService from '@/services/apiService';
import { mapState } from 'pinia';
import { useApplicationStore } from '@/stores/application';

export default {
  components: {
    ListView,
    MainContentLayout
  },
  data () {
    return {
      restrictedCountryId: null,
      itemsList: []
    };
  },
  computed: {
    ...mapState(useApplicationStore, ['isOpenBankingEnabled'])
  },
  async mounted () {
    const { data: { pageItems: ruleGroups } } = await apiService.amlGroupsResource.getAll();
    this.restrictedCountryId = ruleGroups.find(ruleGroup => ruleGroup.type === 'RESTRICTED_COUNTRY').id;
    this.itemsList = [
      {
        icon: 'fas fa-book',
        title: 'label.anchor.standingInstructionHistory',
        subtitle: 'label.viewLoggedHistoryOfStandingInstructions',
        href: '#/standinginstructions/history',
        permission: 'READ_STANDINGINSTRUCTION'
      },
      {
        icon: 'fas fa-dollar-sign',
        title: 'label.anchor.paymentTypes',
        subtitle: 'label.paymentTypes',
        href: '#/viewpaymenttype',
        permission: 'READ_PAYMENTTYPE'
      },
      {
        icon: 'fas fa-dollar-sign',
        title: 'label.anchor.amlRules',
        subtitle: 'label.manageAnitiMoneyLaunderingRules',
        href: '#/payments/aml/rulegroups',
        permission: 'READ_AML_RULE_GROUP'
      },
      {
        icon: 'fas fa-dollar-sign',
        title: 'label.anchor.restrictedAndProhibitedCountries',
        subtitle: 'label.amlGroupRestrictedAndProhibitedCountriesConfiguration',
        href: `#/payments/aml/restrictedcountryrule/${this.restrictedCountryId}`,
        permission: 'READ_AML_RULE_GROUP'
      },
      {
        icon: 'fas fa-cogs',
        title: 'label.anchor.achProcessing',
        subtitle: 'label.triggerAchAndViewAchProcessingHistory',
        href: '#/ach-processing',
        permission: 'READ_CONFIGURATION'
      },
      {
        icon: 'fas fa-cogs',
        title: 'label.anchor.processSubledgerFiles',
        subtitle: 'label.processSubledgerFiles',
        href: '#/payments/process-subledger-files',
        enabled: this.isOpenBankingEnabled
      },
      {
        icon: 'fas fa-cogs',
        title: 'label.anchor.processSubledgerReturns',
        subtitle: 'label.processSubledgerReturns',
        href: '#/payments/process-subledger-returns',
        enabled: this.isOpenBankingEnabled
      },
      {
        icon: 'fa-money-bill-alt',
        title: 'label.anchor.transferLimits',
        subtitle: 'label.defineMaximunAmountTransfer',
        href: '#/payments/transfer-limits',
        permission: 'READ_TRANSFER_LIMIT'
      },
      {
        icon: 'fas fa-cogs',
        title: 'label.anchor.disablingPaymentRails',
        subtitle: 'label.disablePaymentRailsForWholeOrganization',
        href: '#/payments/disabling-payment-rails',
        permission: 'READ_DISABLING_TRANSFER_OPTION_INSTANCE'
      }
    ];
  }
};
</script>
