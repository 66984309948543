// (function (module) {
//   mbanq.directives = _.extend(module, {
//     TabsPersistenceDirective (localStorageService) {
//       function setSavedTab (tabsetName, tabHeading) {
//         let savedTabs = localStorageService.getFromLocalStorage('tabPersistence')
//         if(savedTabs === null) {
//           savedTabs = {}
//         }
//         savedTabs[tabsetName] = tabHeading
//         localStorageService.addToLocalStorage('tabPersistence', savedTabs)
//       }

//       function getSavedTabHeading (tabsetName) {
//         const savedTabs = localStorageService.getFromLocalStorage('tabPersistence')
//         if(savedTabs === null || typeof savedTabs[tabsetName] === 'undefined') {
//           return null
//         }

//         return savedTabs[tabsetName]
//       }

//       return {
//         restrict: 'A',
//         link (scope, elm, attrs) {
//           const tabScope = elm.isolateScope()
//           // Tab persistence is not needed
//           if(attrs.tabsetName === null) {
//             return
//           }

//           tabScope.tabName = attrs.tabsetName

//           tabScope.$watch('tabset.active', function (data) {
//             setSavedTab(tabScope.tabName, data)
//           })

//           const savedTabIndex = getSavedTabHeading(tabScope.tabName)
//           if(savedTabIndex !== null) {
//             tabScope.tabset.active = savedTabIndex
//           }
//         }
//       }
//     }
//   })
// }(mbanq.directives || {}))

// mbanq.ng.application.directive('persistentTab', ['localStorageService', mbanq.directives.TabsPersistenceDirective]).run(function ($log) {
//   $log.info('tabsPersistenceDirective initialized')
// })

export function TabsPersistenceDirective (localStorageService) {
  function setSavedTab (tabsetName, tabHeading) {
    let savedTabs = localStorageService.getFromLocalStorage('tabPersistence');
    if (savedTabs === null) {
      savedTabs = {};
    }
    savedTabs[tabsetName] = tabHeading;
    localStorageService.addToLocalStorage('tabPersistence', savedTabs);
  }

  function getSavedTabHeading (tabsetName) {
    const savedTabs = localStorageService.getFromLocalStorage('tabPersistence');
    if (savedTabs === null || typeof savedTabs[tabsetName] === 'undefined') {
      return null;
    }

    return savedTabs[tabsetName];
  }

  return {
    restrict: 'A',
    link (scope, elm, attrs) {
      const tabScope = elm.isolateScope();
      // Tab persistence is not needed
      if (attrs.tabsetName === null) {
        return;
      }

      tabScope.tabName = attrs.tabsetName;

      tabScope.$watch('tabset.active', function (data) {
        setSavedTab(tabScope.tabName, data);
      });

      const savedTabIndex = getSavedTabHeading(tabScope.tabName);
      if (savedTabIndex !== null) {
        tabScope.tabset.active = savedTabIndex;
      }
    }
  };
}

TabsPersistenceDirective.$inject = ['localStorageService'];
