export function ViewSavingProductController ($scope, routeParams, location, anchorScroll, resourceFactory) {
  resourceFactory.savingProductResource.get({ savingProductId: routeParams.id, template: 'true' }, function (data) {
    $scope.savingproduct = data;
    $scope.hasAccounting = (data.accountingRule.id === 2 || data.accountingRule.id === 3);
    $scope.isPeriodicAccounting = data.accountingRule.id === 3;
  });

  $scope.scrollto = function (link) {
    location.hash(link);
    anchorScroll();
  };
}

ViewSavingProductController.$inject = ['$scope', '$routeParams', '$location', '$anchorScroll', 'ResourceFactory'];
