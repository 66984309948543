export function NumberFormatDirective ($filter, $locale, $parse) {
  return {
    replace: false,
    require: 'ngModel',
    link (scope, element, attrs, modelCtrl) {
      const filter = $filter('number');

      function number (value, fractionLength) {
        return filter(value, fractionLength);
      }

      function initialNumber () {
        const DECIMAL_SEP = '.';
        const decimalSep = $locale.NUMBER_FORMATS.DECIMAL_SEP;
        const stringValue = `${modelCtrl.$modelValue}`;
        let num = stringValue.toString();

        if (stringValue !== undefined && stringValue.indexOf(decimalSep) > 0 && decimalSep !== DECIMAL_SEP) {
          num = num.replace(decimalSep, DECIMAL_SEP);
        }
        const fractionLength = (num.split(DECIMAL_SEP)[1] || []).length;

        const initialnumber = $filter('number')(num, fractionLength);
        if (stringValue !== undefined && stringValue.indexOf(DECIMAL_SEP) > 0 && decimalSep !== DECIMAL_SEP) {
          num = num.replace(DECIMAL_SEP, decimalSep);
          const modelGetter = $parse(attrs.ngModel);
          // This returns a function that lets us set the value of the ng-model binding expression:
          const modelSetter = modelGetter.assign;
          // This is how you can use it to set the value 'bar' on the given scope.
          modelSetter(scope, num);
        }
        return initialnumber;
      }

      modelCtrl.$formatters.push(initialNumber);

      modelCtrl.$parsers.push(function (stringValue) {
        if (stringValue) {
          const index = stringValue.indexOf($locale.NUMBER_FORMATS.DECIMAL_SEP);
          let decimal;
          let fraction;
          let fractionLength;
          if (index >= 0) {
            decimal = stringValue.substring(0, index);
            fraction = stringValue.substring(index + 1);
            if (index !== stringValue.length - 1) { fractionLength = fraction.length; } else { fractionLength = 0; }
          } else {
            decimal = stringValue;
            fraction = '';
          }
          decimal = decimal.replace(/[^0-9]/g, '');
          fraction = fraction.replace(/[^0-9]/g, '');
          let result = decimal;
          if (fraction.length > 0) {
            result = result + $locale.NUMBER_FORMATS.DECIMAL_SEP + fraction;
          }
          const viewValue = +(`${decimal}.${fraction}`);
          if (result !== modelCtrl.$modelValue) {
            scope.$evalAsync(function () {
              modelCtrl.$viewValue = number(viewValue, fractionLength);
              modelCtrl.$render();
            });
          }
          return result;
        } else {
          return '0';
        }
      });

      scope.$on('$localeChangeSuccess', function () {
        modelCtrl.$viewValue = $filter('number')(modelCtrl.$modelValue);
        modelCtrl.$render();
      });

      element.bind('keypress', function (event) {
        const key = event.which;
        // If the keys include the CTRL, SHIFT, ALT, or META keys, or the arrow keys, do nothing.
        // This will support copy and paste too
        if (
          key === 0 || key === 8 || (key > 15 && key < 19) ||
          (key >= 37 && key <= 40) || key === 46 || key === 190
        ) {
          return;
        }
        // ignore all other keys which we do not need
        if (!(key >= 48 && key <= 57) && String.fromCharCode(key) !== '-') {
          event.preventDefault();
        }
      });
    }
  };
}

NumberFormatDirective.$inject = ['$filter', '$locale', '$parse'];
