<template>
  <form-wrapper @submit="submit">
    <div class="input-group">
      <input
        v-model="searchId"
        type="search"
        class="form-control"
        :placeholder="$t('label.anchor.filterById')"
      >
      <button
        class="btn btn-primary"
        type="submit"
      >
        <span class="fas fa-search" />
      </button>
    </div>
  </form-wrapper>
</template>

<script setup>
import { FormWrapper } from '@/components/shared/form-input/index';
import { ref } from 'vue';
import { get } from '@vueuse/core';
import { emptyStringToUndefined } from '@/helper/stringHelper';

const searchId = ref('');
const emit = defineEmits(['onSearch']);

const submit = () => {
  emit('onSearch', emptyStringToUndefined(get(searchId)));
};
</script>
