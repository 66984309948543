export function DateFormat (dateFilter) {
  return (_input) => {
    if (!_input) { return ''; }

    let tDate;
    let dateFormat = 'dd MMMM yyyy';
    if (Array.isArray(_input)) {
      tDate = new Date(_input[0], _input[1] - 1, _input[2]);
    } else {
      tDate = new Date(_input);
      dateFormat = 'dd MMMM yyyy, HH:mm:ss';
    }

    // return dateFilter(tDate, localStorageService.getFromLocalStorage('dateformat'));
    return dateFilter(tDate, dateFormat);
  };
}

DateFormat.$inject = ['dateFilter'];
