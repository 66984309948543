export function ViewCodeController (scope, routeParams, resourceFactory, location, $uibModal, route) {
  scope.codevalues = [];
  scope.formData = [];
  scope.newcode = {};
  scope.codename = {};
  resourceFactory.codeResources.get({ codeId: routeParams.id }, function (data) {
    scope.code = data;
    scope.codename.name = data.name;
  });
  resourceFactory.codeValueResource.getAllCodeValues({ codeId: routeParams.id }, function (data) {
    scope.codevalues = data;
  });

  scope.delCode = function () {
    $uibModal.open({
      templateUrl: 'deletecode.html',
      controller: CodeDeleteCtrl
    });
  };
  scope.showEdit = function (id, name, description, position, identifier, cv, isActive) {
    scope.formData[id] = {
      name,
      description,
      position,
      identifier,
      isActive
    };
    cv.edit = !cv.edit;
  };
  scope.editCodeValue = function (id, cv) {
    resourceFactory.codeValueResource.update({ codeId: routeParams.id, codevalueId: id }, this.formData[id], function () {
      cv.edit = !cv.edit;
      route.reload();
    });
  };
  scope.showEditCode = function () {
    scope.newcode.edit = !scope.newcode.edit;
    scope.codename.name = scope.code.name;
  };
  scope.updateCode = function () {
    resourceFactory.codeResources.update({ codeId: routeParams.id }, this.codename, function () {
      route.reload();
    });
  };
  const CodeDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.codeResources.delete({ codeId: routeParams.id }, {}, function () {
        $uibModalInstance.close('delete');
        location.path('/codes');
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.deleteCodeValue = function (id) {
    $uibModal.open({
      templateUrl: 'deletecodevalue.html',
      controller: CodeValueDeleteCtrl,
      resolve: {
        cvid () {
          return id;
        }
      }
    });
  };
  const CodeValueDeleteCtrl = function ($scope, $uibModalInstance, cvid) {
    $scope.delete = function () {
      resourceFactory.codeValueResource.delete({ codeId: routeParams.id, codevalueId: cvid }, {}, function () {
        $uibModalInstance.close('delete');
        route.reload();
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewCodeController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location', '$uibModal', '$route'];
