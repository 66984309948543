export function ViewOperationalAccountMappingController ($scope, resourceFactory, routeParams, location, $uibModal) {
  resourceFactory.operationalAccountMappingResource.get({ activityType: routeParams.type }, function (data) {
    $scope.operationalAccountMapping = data;
  });

  $scope.deletemapping = function () {
    $uibModal.open({
      templateUrl: 'deletemapping.html',
      controller: DeleteOperationalAccountMappingController
    });
  };
  const DeleteOperationalAccountMappingController = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.operationalAccountMappingResource.delete({ activityType: routeParams.type }, {}, function () {
        $uibModalInstance.close('delete');
        location.path('/operationalAccountsMapping');
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewOperationalAccountMappingController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location', '$uibModal'];
