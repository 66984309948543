export function CreditAccountDocumentController (scope, location, routeParams, API_VERSION, Upload, $rootScope) {
  scope.cardId = routeParams.cardId;
  scope.onFileSelect = function (files) {
    scope.formData.file = files[0];
  };

  scope.submit = function () {
    Upload.upload({
      url: `${$rootScope.hostUrl + API_VERSION}/creditcards/${scope.cardId}/documents`,
      data: { name: scope.formData.name, description: scope.formData.description, file: scope.formData.file }
    }).then(function () {
      if (!scope.$$phase) {
        scope.$apply();
      }
      location.path(`/viewcreditaccount/${scope.cardId}`);
    });
  };
}

CreditAccountDocumentController.$inject = ['$scope', '$location', '$routeParams', 'API_VERSION', 'Upload', '$rootScope'];
