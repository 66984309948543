import { StorageService } from './storage.service';

const TOKEN_STORAGE_KEY = 'tokendetails';
const TWOFA_STORAGE_KEY = '2FAToken';
const SESSION_DATA_STORAGE_KEY = 'sessionData';
const STORAGE_ENGINE = 'localStorage';

export const TokenService = {

  saveTokenData (data) {
    StorageService.setItem(TOKEN_STORAGE_KEY, data, STORAGE_ENGINE);
  },

  getTokenData () {
    return StorageService.getItem(TOKEN_STORAGE_KEY, STORAGE_ENGINE);
  },

  get2FAToken () {
    return StorageService.getItem(TWOFA_STORAGE_KEY, STORAGE_ENGINE);
  },

  removeTokenData () {
    StorageService.removeItem(TOKEN_STORAGE_KEY, STORAGE_ENGINE);
    StorageService.removeItem(TWOFA_STORAGE_KEY, STORAGE_ENGINE);
    StorageService.removeItem(SESSION_DATA_STORAGE_KEY, STORAGE_ENGINE);
  },

  save2FAToken (data) {
    StorageService.setItem(TWOFA_STORAGE_KEY, data, STORAGE_ENGINE);
  },

  saveSessionData (data) {
    StorageService.setItem(SESSION_DATA_STORAGE_KEY, data, STORAGE_ENGINE);
  },

  getSessionData () {
    return StorageService.getItem(SESSION_DATA_STORAGE_KEY, STORAGE_ENGINE);
  },

  getDecodedToken () {
    let token = this.getTokenData();
    if (!token) {
      return null;
    }
    token = token.access_token;
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  },

  isTokenExpired () {
    const decoded = this.getDecodedToken();
    if (!decoded || !decoded.exp) {
      return true;
    }
    const expiresIn = decoded.exp;
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return currentTimestamp > expiresIn;
  }

};
