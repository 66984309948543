export function FormSubmitValidateDirective ($parse) {
  return {
    restrict: 'A',
    require: ['rcSubmit', '?form'],
    controller: ['$scope', function () {
      this.attempted = false;

      this.setAttempted = function () {
        this.attempted = true;
      };
    }],
    compile () {
      return {
        pre (scope, formElement, attributes, controllers) {
          const submitController = controllers[0];

          scope.rc = scope.rc || {};
          scope.rc[attributes.name] = submitController;
        },
        post (scope, formElement, attributes, controllers, event) {
          const submitController = controllers[0];
          const formController = (controllers.length > 1) ? controllers[1] : null;
          const fn = $parse(attributes.rcSubmit);

          formElement.bind('submit', function () {
            submitController.setAttempted();
            if (!scope.$$phase) scope.$apply();

            if (!formController.$valid) {
              return false;
            }
            scope.$apply(function () {
              fn(scope, { $event: event });
            });
          });
        }
      };
    }
  };
}

FormSubmitValidateDirective.$inject = ['$parse'];
