import { defineStore } from 'pinia';

const defaultState = {
  transaction: {
    transfer: null
  },
  selectedAction: null,
  watchmanLists: [{
    transfer: null
  }],
  needsToReloadList: false,
  pendingReturnChecked: [],
  paymentType: null
};

export const useTxMonitoringStore = defineStore('txmonitoring', {
  state: () => ({ ...defaultState }),
  actions: {
    setSelectedTransaction (value) {
      this.transaction = value;
    },
    setWatchmanLists (value) {
      this.watchmanLists = value;
    },
    setSelectedAction (value) {
      this.selectedAction = value;
    },
    setNeedsToReloadList (value) {
      this.needsToReloadList = value;
    },
    setPendingReturnChecked (value) {
      this.pendingReturnChecked = value;
    },
    setPaymentType (value) {
      this.paymentType = value;
    }
  }
});
