import SavingAccountsActions from '@/pages/clients/saving-accounts/actions-page.vue';
import CreateSavingAccountSubledger from '@/pages/clients/saving-accounts/subledger/create.vue';

export const savingAccountsRoutes = [
  {
    path: '/clients/:clientId/saving-accounts/:id/subledger/create',
    name: 'CreateSavingAccountSubledgerPage',
    component: CreateSavingAccountSubledger
  },
  {
    path: '/clients/:clientId/saving-accounts/:id/:action',
    name: 'SavingAccountsActions',
    component: SavingAccountsActions
  }
];
