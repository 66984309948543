import { mlaStatus } from '../../../../utils/templates.js';

export function EditClientController ($scope, routeParams, resourceFactory, dateFilter, rootScope) {
  $scope.offices = [];
  $scope.date = {};
  $scope.restrictDate = new Date();
  $scope.savingproducts = [];
  $scope.clientId = routeParams.id;
  $scope.showSavingOptions = 'false';
  $scope.opensavingsproduct = 'false';
  $scope.showNonPersonOptions = false;
  $scope.clientPersonId = 1;

  $scope.mlaOptions = mlaStatus;

  resourceFactory.clientTemplateResource.get({}, function (data) {
    data.mobileNumberFormat.forEach((value) => {
      value.name === 'mobile-number-regex' ? $scope.phoneNumberFormat = value : $scope.countryCodeFormat = value;
    });
  });

  $scope.validateOnPhoneNumberChange = function () {
    $scope.phoneNumberFormattedValue = $scope.phoneNumberFormat.enabled ? $scope.phoneNumberFormat.value.replace(/\\/g, '\\') : '';
  };
  // trigger validate only we change value like phone number to avoid future confusion like phone number
  $scope.validateOnCountryCodeChange = function () {
    $scope.countryCodeFormattedValue = $scope.countryCodeFormat.enabled ? $scope.countryCodeFormat.value.replace(/\\/g, '\\') : '';
  };

  resourceFactory.clientResource.get({ clientId: $scope.clientId, template: 'true', staffInSelectedOfficeOnly: true }, function (data) {
    $scope.displayName = data.displayName;
    $scope.offices = data.officeOptions;
    $scope.staffs = data.staffOptions;
    $scope.savingproducts = data.savingProductOptions;
    $scope.genderOptions = data.genderOptions;
    $scope.clienttypeOptions = data.clientTypeOptions;
    $scope.clientClassificationOptions = data.clientClassificationOptions;
    $scope.clientNonPersonConstitutionOptions = data.clientNonPersonConstitutionOptions;
    $scope.clientNonPersonMainBusinessLineOptions = data.clientNonPersonMainBusinessLineOptions;
    $scope.clientLegalFormOptions = data.clientLegalFormOptions;
    // $scope.clientKYCStatusOptions = data.clientKycStatusOptions;
    $scope.officeId = data.officeId;

    $scope.formData = {
      firstname: data.firstname,
      lastname: data.lastname,
      middlename: data.middlename,
      active: data.active,
      emailAddress: data.emailAddress,
      accountNo: data.accountNo,
      staffId: data.staffId,
      externalId: data.externalId,
      isStaff: data.isStaff,
      mobileNo: data.mobileNo?.replace(/\s/g, ''),
      savingsProductId: data.savingsProductId,
      genderId: data.gender.id,
      fullname: data.fullname,
      mobileCountryCode: data.mobileCountryCode,
      clientNonPersonDetails: {
        incorpNumber: data.clientNonPersonDetails.incorpNumber,
        remarks: data.clientNonPersonDetails.remarks
      },
      currentMLAStatus: data.currentMLAStatus,
      isOptedForMLAStatus: data.isOptedForMLAStatus
    };

    if (data.gender) {
      $scope.formData.genderId = data.gender.id;
    }

    if (data.clientTypes?.length) {
      $scope.formData.clientTypes = data.clientTypes.map(clientType => clientType.id);
    }

    if (data.clientClassification) {
      $scope.formData.clientClassificationId = data.clientClassification.id;
    }

    if (data.legalForm) {
      $scope.displayPersonOrNonPersonOptions(data.legalForm.id);
      $scope.formData.legalFormId = data.legalForm.id;
    }

    if (data.clientNonPersonDetails.constitution) {
      $scope.formData.clientNonPersonDetails.constitutionId = data.clientNonPersonDetails.constitution.id;
    }

    if (data.clientNonPersonDetails.mainBusinessLine) {
      $scope.formData.clientNonPersonDetails.mainBusinessLineId = data.clientNonPersonDetails.mainBusinessLine.id;
    }

    if (data.clientNonPersonDetails.website) {
      $scope.formData.clientNonPersonDetails.website = data.clientNonPersonDetails.website;
    }

    if (data.savingsProductId !== null) {
      $scope.opensavingsproduct = 'true';
      $scope.showSavingOptions = 'true';
    } else if (data.savingProductOptions.length > 0) {
      $scope.showSavingOptions = 'true';
    }

    if (data.dateOfBirth) {
      const dobDate = dateFilter(data.dateOfBirth, $scope.df);
      $scope.date.dateOfBirth = new Date(dobDate);
    }

    if (data.clientNonPersonDetails.incorpValidityTillDate) {
      const incorpValidityTillDate = dateFilter(data.clientNonPersonDetails.incorpValidityTillDate, $scope.df);
      $scope.date.incorpValidityTillDate = new Date(incorpValidityTillDate);
    }

    const actDate = dateFilter(data.activationDate, $scope.df);
    $scope.date.activationDate = new Date(actDate);
    if (data.active) {
      $scope.choice = 1;
      $scope.showSavingOptions = 'false';
      $scope.opensavingsproduct = 'false';
    }

    if (data.timeline.submittedOnDate) {
      const submittedOnDate = dateFilter(data.timeline.submittedOnDate, $scope.df);
      $scope.date.submittedOnDate = new Date(submittedOnDate);
    }

    // if(data.clientKycStatus){
    //   $scope.formData.clientKYCStatusId = data.clientKycStatus.id;
    // }
  });

  $scope.displayPersonOrNonPersonOptions = function (legalFormId) {
    if (legalFormId === $scope.clientPersonId || legalFormId === null) {
      $scope.showNonPersonOptions = false;
    } else {
      $scope.showNonPersonOptions = true;
    }
  };

  $scope.submit = function () {
    this.formData.locale = $scope.optlang.code;
    this.formData.dateFormat = $scope.df;
    if ($scope.choice === 1) {
      if ($scope.date.activationDate) {
        this.formData.activationDate = dateFilter($scope.date.activationDate, $scope.df);
      }
    }
    if ($scope.date.dateOfBirth) {
      this.formData.dateOfBirth = dateFilter($scope.date.dateOfBirth, $scope.df);
    }

    if ($scope.date.submittedOnDate) {
      this.formData.submittedOnDate = dateFilter($scope.date.submittedOnDate, $scope.df);
    }

    if ($scope.date.incorpValidityTillDate) {
      this.formData.clientNonPersonDetails.locale = $scope.optlang.code;
      this.formData.clientNonPersonDetails.dateFormat = $scope.df;
      this.formData.clientNonPersonDetails.incorpValidityTillDate = dateFilter($scope.date.incorpValidityTillDate, $scope.df);
    }

    if (this.formData.legalFormId === $scope.clientPersonId || this.formData.legalFormId === null) {
      delete this.formData.fullname;
    } else {
      delete this.formData.firstname;
      delete this.formData.middlename;
      delete this.formData.lastname;
      // delete this.formData.clientKYCStatusId;
    }

    resourceFactory.clientResource.update({ clientId: $scope.clientId }, this.formData, function () {
      $scope.back();
    }, () => {
      if ($scope.showNonPersonOptions && rootScope.errorDetails[0].code === 'validation.msg.client.dateOfBirth.is.greater.than.date') { rootScope.errorDetails[0][0].body.errors[0].defaultUserMessage = `The Incorporation Date must be less than provided date ${$scope.restrictDate}`; }
    });
  };

  $scope.back = () => {
    history.back();
  };
}

EditClientController.$inject = ['$scope', '$routeParams', 'ResourceFactory', 'dateFilter', '$rootScope'];
