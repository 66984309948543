function getTenantIdentifierFromHeader () {
  const header = new Headers();
  const tenantIdentifier = header.get('tenantIdentifier');

  return tenantIdentifier;
}

function getTenantIdentifierFromUrl () {
  const urlParams = new URLSearchParams(window.location.search);
  const tenantIdentifier = urlParams.get('tenantIdentifier');

  return tenantIdentifier;
}

export function getTenantIdentifier () {
  let tenantIdentifier = process.env.VUE_APP_TENANT || '';
  const tenantIdentifierFromHeader = getTenantIdentifierFromHeader();
  const tenantIdentifierFromUrl = getTenantIdentifierFromUrl();

  if (tenantIdentifierFromHeader) { tenantIdentifier = tenantIdentifierFromHeader; }

  if (tenantIdentifierFromUrl) { tenantIdentifier = tenantIdentifierFromUrl; }

  return tenantIdentifier;
}
