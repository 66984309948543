import { defineStore } from 'pinia';

const defaultState = {
  isOpenBankingEnabled: false
};

export const useApplicationStore = defineStore('application', {
  state: () => ({ ...defaultState }),
  persist: true
});
