export function ViewProvisioningJournalEntriesController (scope, routeParams, paginatorService, resourceFactory, location) {
  scope.charge = [];
  scope.choice = 0;
  scope.transactions = [];
  const fetchFunction = function (offset, limit, callback) {
    const params = {};
    params.entryId = routeParams.entryId;
    params.offset = offset;
    params.limit = limit;
    scope.saveSC();
    resourceFactory.provisioningjournals.get(params, callback);
  };

  paginatorService.currentOffset = 0;
  scope.transactions = paginatorService.paginate(fetchFunction, 10);
  scope.displayResults = true;

  scope.submit = function () {
    resourceFactory.provisioningentries.createJournals(
      { entryId: routeParams.entryId },
      this.formData,
      function () {
        location.path('/viewprovisioningentries/');
      }
    );
  };

  scope.routeTo = function (id) {
    location.path(`/viewtransactions/${id}`);
  };
}

ViewProvisioningJournalEntriesController.$inject = ['$scope', '$routeParams', 'paginatorService', 'ResourceFactory', '$location'];
