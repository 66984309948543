export function AddAccountNumberPreferencesController (scope, resourceFactory, location) {
  scope.formData = {};

  resourceFactory.accountNumberTemplateResource.get(function (data) {
    scope.data = data;
    scope.accountTypeOptions = data.accountTypeOptions;
  });

  scope.getPrefixTypeOptions = function (accountType) {
    if (accountType === 1) {
      scope.prefixTypeOptions = scope.data.prefixTypeOptions['accountType.client'];
    }
    if (accountType === 2) {
      scope.prefixTypeOptions = scope.data.prefixTypeOptions['accountType.loan'];
    }
    if (accountType === 3) {
      scope.prefixTypeOptions = scope.data.prefixTypeOptions['accountType.savings'];
    }
    if (accountType === 4) {
      scope.prefixTypeOptions = scope.data.prefixTypeOptions['accountType.center'];
    }
    if (accountType === 5) {
      scope.prefixTypeOptions = scope.data.prefixTypeOptions['accountType.group'];
    }
    if (accountType === 6) {
      scope.prefixTypeOptions = scope.data.prefixTypeOptions['accountType.shares'];
    }
    if (accountType === 7) {
      scope.prefixTypeOptions = scope.data.prefixTypeOptions['accountType.creditAccount'];
    }
  };

  scope.cancel = function () {
    location.path('/accountnumberpreferences');
  };

  scope.submit = function () {
    resourceFactory.accountNumberResources.save(scope.formData, function (data) {
      scope.resourceId = data.resourceId;
      location.path(`/viewaccountnumberpreferences/${scope.resourceId}`);
    });
  };

  scope.handlePrefixTypeChange = function () {
    if (scope.formData.prefixType !== 401) {
      delete scope.formData.customPrefix;
    }
  };
}

AddAccountNumberPreferencesController.$inject = ['$scope', 'ResourceFactory', '$location'];
