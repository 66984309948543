<template>
  <span>
    <button
      :class="`btn me-1 btn-${variantBtn}`"
      :disabled="loading"
      @click="actionPrompt(currentItem)"
    >
      <span :class="`fas fa-${variantIcon}`" />
      {{ $t(`label.button.${buttonText}`) }}
    </button>
    <base-modal
      ref="promptModal"
      modal-size="lg"
      hide-footer
    >
      <template #header>
        <h4>{{ $t('label.heading.pendingTransactions') }}</h4>
      </template>
      <template #body>
        <data-grid
          v-if="clientId && !currentItem?.value"
          :items="pendingTransactions"
          :fields="pendingTransactionsFields"
          hide-entries-per-page
        />
        <div
          v-if="clientId && !currentItem?.value"
          class="text-danger fst-italic"
        >
          {{ $t('text.noteTransactionUnderProcessing') }}
          <span v-if="currentItem?.name === 'isAchDebitOutgoingDisable' || currentItem?.name === 'isAchCreditOutgoingDisable'">
            {{ $t('text.executionScheduledMarkAsCancelled') }}
          </span>
        </div>
        <form-wrapper @submit="enableOrDisabling">
          <input-text
            v-model="comment"
            type="textarea"
            label="Reason"
            vertical
            required
          />
          <div class="pull-right">
            <button
              type="button"
              class="btn btn-default"
              data-bs-dismiss="modal"
            >
              {{ $t('label.button.cancel') }}
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="loading"
            >
              {{ $t('label.button.save') }}
            </button>
          </div>
        </form-wrapper>
      </template>
    </base-modal>
  </span>
</template>
<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import { ref, computed } from 'vue';
import DataGrid from '@/components/shared/data-grid/index.vue';
import i18n from '@/plugins/i18n';
import apiService from '@/services/apiService';
import { $notify } from '@/plugins/prototypes';
import router from '@/router';
import { useSharedStore } from '@/stores/shared';
import { InputText, FormWrapper } from '@/components/shared/form-input';
import { transferTypeHelper } from '@/helper/transferHelper';

const sharedStore = useSharedStore();
const loading = computed(() => sharedStore.loading);
const { clientId } = router.currentRoute.params;

const props = defineProps({
  currentItem: {
    type: Object,
    default: () => ({})
  }
});

const variantBtn = computed(() => props.currentItem?.value ? 'success' : 'danger');
const variantIcon = computed(() => props.currentItem?.value ? 'unlock-alt' : 'lock');
const buttonText = computed(() => props.currentItem?.value ? 'enable' : 'disable');

const comment = ref('');
const promptModal = ref(null);

const pendingTransactions = ref([]);
const pendingTransactionsFields = ref([
  { key: 'id', label: i18n.t('label.heading.id') },
  { key: 'type', label: i18n.t('label.heading.transactionType') },
  { key: 'executedAt', label: i18n.t('label.heading.date') },
  { key: 'amount', label: i18n.t('label.heading.amount') },
  { key: 'status', label: i18n.t('label.heading.status') }
]);
const actionPrompt = async (item) => {
  const paramsByType = transferTypeHelper(item.name);
  promptModal.value.show();

  if (paramsByType && clientId && !item.value) {
    const params = {
      dateFormat: 'yyyy-MM-dd',
      locale: 'en',
      clientId,
      limit: 15,
      offset: '0',
      orderBy: 'id',
      sortOrder: 'DESC',
      status: 'EXECUTION_PROCESSING,EXECUTION_SCHEDULED',
      ...paramsByType
    };

    const { data } = await apiService.transfer.getTransfers(params);
    pendingTransactions.value = data.pageItems;
  }
};

const emit = defineEmits(['on-enabled-disabled']);
const enableOrDisabling = async () => {
  try {
    const payload = {
      [props.currentItem.name]: !props.currentItem.value,
      comment: comment.value
    };

    if (clientId) {
      await apiService.clients.updatePaymentRails(clientId, payload);
    } else {
      await apiService.paymentRails.updateInstanceLevel(payload);
    }

    promptModal.value.hide();
    emit('on-enabled-disabled', props.currentItem);
    comment.value = '';
  } catch (error) {
    $notify.error(error);
  }
};

</script>
