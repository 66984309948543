export function ViewBillingCyclesAndStatementsController (scope, routeParams, resourceFactory, http, $rootScope, $uibModal, $sce) {
  scope.cardId = routeParams.cardId;
  scope.amountOfYears = 6;
  scope.month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  scope.hosturl = $rootScope.hosturl;
  scope.totalBillingCycles = 0;
  scope.billingCyclePerPage = 15;
  scope.billingCycles = [];

  scope.getResultsPage = function (pageNumber) {
    resourceFactory.creditAccountResource.getBillingCycles({
      accountId: routeParams.cardId,
      resourceType: 'billingCycles',
      offset: ((pageNumber - 1) * scope.billingCyclePerPage),
      limit: scope.billingCyclePerPage
    }, function (data) {
      scope.totalBillingCycles = data.totalFilteredRecords;
      scope.billingCycles = data.pageItems;
    });
  };

  scope.searchBillingCycles = function () {
    const tempOffset = 0;
    resourceFactory.creditAccountResource.getBillingCycles({
      accountId: routeParams.cardId,
      resourceType: 'billingCycles',
      offset: tempOffset,
      limit: scope.billingCyclePerPage
    }, function (data) {
      scope.totalBillingCycles = data.totalFilteredRecords;
      scope.billingCycles = data.pageItems;
    });
  };
  scope.searchBillingCycles();

  scope.downloadDocumentXhr = function (savingId, documentId, name) {
    const configObj = {
      method: 'GET',
      url: `${$rootScope.hostUrl}/v1/savings/${savingId}/documents/${documentId}/attachment`,
      responseType: 'arraybuffer'
    };

    http(configObj)
      .then(function onFulfilled (response) {
        const a = document.createElement('a');
        const windowUrl = window.URL || window.webkitURL;
        const blob = new Blob([response.data], { type: 'application/pdf', responseType: 'arraybuffer' });

        const url = windowUrl.createObjectURL(blob);
        a.href = url;
        a.download = name;
        a.click();
        windowUrl.revokeObjectURL(url);
      }).catch(function onRejection (errorResponse) {
        console.error(errorResponse);
      });
  };

  scope.showBillingCycleStatement = function (statement) {
    $uibModal.open({
      templateUrl: 'credit-card-statement.html',
      controller: ViewCreditCardStatementCtrl,
      size: 'xl',
      resolve: {
        items () {
          return {
            xurl: `${$rootScope.hostUrl}/v1/creditcards/${statement.entityId}/documents/${statement.id}/attachment`,
            fileName: statement.filename,
            documentName: statement.name
          };
        }
      }
    });
  };

  const ViewCreditCardStatementCtrl = function ($scope, $uibModalInstance, items) {
    $scope.items = items;

    function previewDocument () {
      http({
        method: 'GET',
        url: items.xurl,
        responseType: 'arraybuffer'
      }).then(function (imageData) {
        if ($scope.fileType.startsWith('image')) {
          let binary = '';
          const bytes = new Uint8Array(imageData.data);
          const len = bytes.byteLength;
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          $scope.fileBase64 = btoa(binary);
        } else {
          const blob = new Blob([imageData.data], { type: $scope.fileType });
          $scope.fileURL = URL.createObjectURL(blob);
          $scope.content = $sce.trustAsResourceUrl($scope.fileURL);
        }
      });

      if (items.fileName.toLowerCase().indexOf('.png') !== -1) {
        $scope.fileType = 'image/png';
        $scope.isImage = true;
      } else if (items.fileName.toLowerCase().indexOf('.jpg') !== -1) {
        $scope.fileType = 'image/jpg';
        $scope.isImage = true;
      } else if (items.fileName.toLowerCase().indexOf('.jpeg') !== -1) {
        $scope.fileType = 'image/jpeg';
        $scope.isImage = true;
      } else if (items.fileName.toLowerCase().indexOf('.pdf') !== -1) {
        $scope.fileType = 'application/pdf';
        $scope.isImage = false;
      } else if (items.fileName.toLowerCase().indexOf('.doc') !== -1) {
        $scope.fileType = 'application/msword';
        $scope.isImage = false;
      } else if (items.fileName.toLowerCase().indexOf('.docx') !== -1) {
        $scope.fileType = 'application/msword';
        $scope.isImage = false;
      }
    }

    previewDocument();

    $scope.downloadDocument = function () {
      const a = document.createElement('a');
      a.href = $scope.fileURL;
      a.download = items.documentName;
      a.click();
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}
ViewBillingCyclesAndStatementsController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$http', '$rootScope', '$uibModal', '$sce'];
