export function ViewPaymentTypeController (scope, resourceFactory, location, $uibModal, route) {
  scope.paymentTypes = [];
  scope.formData = [];
  resourceFactory.paymentTypeResource.getAll(function (data) {
    scope.paymentTypes = data;
  });

  scope.showEdit = function (id) {
    location.path(`/editPaymentType/${id}`);
  };

  const PaymentTypeDeleteCtrl = function ($scope, $uibModalInstance, paymentTypeId) {
    $scope.delete = function () {
      resourceFactory.paymentTypeResource.delete({ paymentTypeId }, {}, function () {
        $uibModalInstance.close('delete');
        route.reload();
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
  scope.deletePaymentType = function (id) {
    $uibModal.open({
      templateUrl: 'deletePaymentType.html',
      controller: PaymentTypeDeleteCtrl,
      resolve: {
        paymentTypeId () {
          return id;
        }
      }
    });
  };
}

ViewPaymentTypeController.$inject = ['$scope', 'ResourceFactory', '$location', '$uibModal', '$route'];
