export function HoldAmountSavingsController ($scope, resourceFactory, location, routeParams) {
  $scope.formData = {};
  $scope.errorMsg = '';
  $scope.cancelRoute = routeParams.id;

  $scope.submit = function () {
    resourceFactory.savingsAmountResource.hold({ savingsAccountId: routeParams.id }, $scope.formData, function () {
      history.back();
    }, handleResponseError);
  };

  function handleResponseError (error) {
    const { data } = error;
    $scope.errorMsg = data.errors[0].defaultUserMessage;
  }

  $scope.closeAlert = function () {
    $scope.errorMsg = '';
  };
}

HoldAmountSavingsController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams'];
