import CreateEmployeeAccount from '@/pages/employee-accounts/create.vue';
import FundTransfer from '@/pages/employee-accounts/sub-accounts/fund-transfer.vue';

export const employeeRoutes = [
  {
    path: '/clients/:clientId/employees/create',
    name: 'CreateEmployeeAccount',
    component: CreateEmployeeAccount
  },
  {
    path: '/clients/:parentClientId/associated-client/:clientId/saving-accounts/:subAccountId/fund-transfer',
    name: 'FundTransfer',
    component: FundTransfer
  }
];
