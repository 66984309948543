export const transferTypeHelper = (value) => {
  const params = {
    isExternalCardDebitDisable: {
      originatedBy: 'us',
      paymentType: 'CARD',
      transferType: 'DEBIT'
    },
    isExternalCardCreditDisable: {
      originatedBy: 'us',
      paymentType: 'CARD',
      transferType: 'CREDIT'
    },
    isAchDebitOutgoingDisable: {
      originatedBy: 'us',
      paymentType: 'ACH',
      transferType: 'DEBIT'
    },
    isAchCreditOutgoingDisable: {
      originatedBy: 'us',
      paymentType: 'ACH',
      transferType: 'CREDIT'
    },
    isAchDebitIncomingDisable: {
      originatedBy: 'external party',
      paymentType: 'ACH',
      transferType: 'DEBIT'
    },
    isAchCreditIncomingDisable: {
      originatedBy: 'external party',
      paymentType: 'ACH',
      transferType: 'CREDIT'
    },
    isInternalCreditDisable: {
      paymentType: 'INTERNAL',
      transferType: 'CREDIT'
    },
    isInternalDebitDisable: {
      paymentType: 'INTERNAL',
      transferType: 'DEBIT'
    },
    isWireCreditOutgoingDisable: {
      originatedBy: 'us',
      paymentType: 'WIRE',
      transferType: 'CREDIT'
    },
    isWireCreditIncomingDisable: {
      originatedBy: 'external party',
      paymentType: 'WIRE',
      transferType: 'CREDIT'
    },
    isSwiftCreditOutgoingDisable: {
      originatedBy: 'us',
      paymentType: 'SWIFT',
      transferType: 'CREDIT'
    },
    isSwiftCreditIncomingDisable: {
      originatedBy: 'external party',
      paymentType: 'SWIFT',
      transferType: 'CREDIT'
    },
    isFxpayCreditOutgoingDisable: {
      originatedBy: 'us',
      paymentType: 'FXPAYMENT',
      transferType: 'CREDIT'
    }
  };
  return params[value];
};

export const cancellableTransfer = (status, type) => {
  const validStatus = ['EXECUTION_SCHEDULED', 'AML_SCREENING'];
  if (type === 'IN') validStatus.push('EXECUTION_SUCCESS');
  return validStatus.includes(status);
};
