export function EditOTPProtectedOperationController (scope, routeParams, resourceFactory, location) {
  scope.formData = {};

  resourceFactory.otpProtectedOperationResource.get(function (data) {
    const { isEnabled, ...formData } = data.pageItems.find(pageItem => pageItem.id.toString() === routeParams.id);
    scope.formData = formData;
    scope.formData.enable = isEnabled;
  });

  scope.submit = function () {
    resourceFactory.otpProtectedOperationResource.update({ id: routeParams.id }, scope.formData, function () {
      location.path(`/otpprotectedoperation`);
    });
  };
}

EditOTPProtectedOperationController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
