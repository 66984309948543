import _ from 'underscore';

export function AddHolController (scope, resourceFactory, location, dateFilter) {
  let parentNode;
  scope.offices = [];
  scope.holidays = [];
  scope.date = {};
  scope.date.first = new Date();
  scope.date.second = new Date();
  scope.date.third = new Date();
  const idToNodeMap = {};
  let holidayOfficeIdArray = [];
  scope.firstError = false;
  scope.secondError = false;
  scope.thirdError = false;
  scope.specificRescheduleType = 2;

  scope.deepCopy = function deepCopy (obj) {
    if (Object.prototype.toString.call(obj) === '[object Array]') {
      const out = []; let i = 0; const len = obj.length;
      for (; i < len; i++) {
        out[i] = deepCopy(obj[i]);
      }
      return out;
    }
    if (typeof obj === 'object') {
      const out = {}; let i;
      for (i in obj) {
        out[i] = deepCopy(obj[i]);
      }
      return out;
    }
    return obj;
  };

  scope.selectRescheduleType = function (data) {
    if (data && data.id === scope.specificRescheduleType) {
      scope.date.third = new Date();
    } else {
      scope.date.third = undefined;
    }
  };

  resourceFactory.officeResource.getAllOffices(function (data) {
    scope.offices = scope.deepCopy(data);
    resourceFactory.holidayTemplateResource.get(function (repaymentSchedulingRulesData) {
      scope.repaymentSchedulingRules = repaymentSchedulingRulesData;
      for (const i in scope.repaymentSchedulingRules) {
        if (scope.repaymentSchedulingRules[i].id === 2) {
          scope.reschedulingType = scope.repaymentSchedulingRules[i];
          scope.date.third = new Date();
        }
      }
    });
    for (let i = 0; i < data.length; i++) {
      data[i].children = [];
      idToNodeMap[data[i].id] = data[i];
    }
    function sortByParentId (a, b) {
      return a.parentId - b.parentId;
    }

    data.sort(sortByParentId);

    const root = [];
    for (let i = 0; i < data.length; i++) {
      const currentObj = data[i];
      if (currentObj.children) {
        currentObj.collapsed = 'true';
      }
      if (typeof currentObj.parentId === 'undefined') {
        root.push(currentObj);
      } else {
        parentNode = idToNodeMap[currentObj.parentId];
        parentNode.children.push(currentObj);
      }
    }
    scope.treedata = root;
  });

  scope.holidayApplyToOffice = function (node) {
    if (node.selectedCheckBox) {
      recurHolidayApplyToOffice(node);
      holidayOfficeIdArray = _.uniq(holidayOfficeIdArray);
    } else {
      node.selectedCheckBox = false;
      recurRemoveHolidayAppliedOOffice(node);
    }
  };

  function recurHolidayApplyToOffice (node) {
    node.selectedCheckBox = true;
    holidayOfficeIdArray.push(node.id);
    if (node.children.length > 0) {
      for (let i = 0; i < node.children.length; i++) {
        node.children[i].selectedCheckBox = true;
        holidayOfficeIdArray.push(node.children[i].id);
        if (node.children[i].children.length > 0) {
          recurHolidayApplyToOffice(node.children[i]);
        }
      }
    }
  }

  function recurRemoveHolidayAppliedOOffice (node) {
    holidayOfficeIdArray = _.without(holidayOfficeIdArray, node.id);
    if (node.children.length > 0) {
      for (let i = 0; i < node.children.length; i++) {
        node.children[i].selectedCheckBox = false;
        holidayOfficeIdArray = _.without(holidayOfficeIdArray, node.children[i].id);
        if (node.children[i].children.length > 0) {
          recurRemoveHolidayAppliedOOffice(node.children[i]);
        }
      }
    }
  }

  scope.minDat = new Date();
  scope.submit = function () {
    const testDate = new Date();
    testDate.setDate(testDate.getDate() - 1);

    if (scope.date.first < testDate || scope.date.second < testDate || (scope.reschedulingType.id === scope.specificRescheduleType && scope.date.third < testDate)) {
      if (scope.date.first < testDate) {
        scope.firstError = true;
      } else {
        scope.firstError = false;
      }
      if (scope.date.second < testDate) {
        scope.secondError = true;
      } else {
        scope.secondError = false;
      }
      if (scope.date.third < testDate) {
        scope.thirdError = true;
      } else {
        scope.thirdError = false;
      }
    } else {
      scope.firstError = false;
      scope.secondError = false;
      scope.thirdError = false;
      const reqFirstDate = dateFilter(scope.date.first, scope.df);
      const reqSecondDate = dateFilter(scope.date.second, scope.df);
      let reqThirdDate;
      const newholiday = {};
      newholiday.locale = scope.optlang.code;
      newholiday.dateFormat = scope.df;
      newholiday.name = this.formData.name;
      newholiday.fromDate = reqFirstDate;
      newholiday.toDate = reqSecondDate;
      newholiday.reschedulingType = scope.reschedulingType.id;
      if (scope.reschedulingType.id === scope.specificRescheduleType) {
        reqThirdDate = dateFilter(scope.date.third, scope.df);
        newholiday.repaymentsRescheduledTo = reqThirdDate;
      }
      newholiday.description = this.formData.description;
      newholiday.offices = [];
      for (const i in holidayOfficeIdArray) {
        const temp = {};
        temp.officeId = holidayOfficeIdArray[i];
        newholiday.offices.push(temp);
      }
      resourceFactory.holValueResource.save(newholiday, function () {
        location.path('/holidays');
      });
    }
  };
}

AddHolController.$inject = ['$scope', 'ResourceFactory', '$location', 'dateFilter'];
