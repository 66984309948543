<template>
  <div>
    <div class="col-md-5 mb-2">
      <TxSearch @onSearch="search" />
    </div>
    <data-grid
      ref="table"
      :items="items"
      :limit="itemPerPage"
      :fields="fields"
      :total-records="totalRows"
      @pagination-update="getPendingReturns"
      @entries-per-page="updateEntriesPerPage"
    >
      <template #originatorRouteCode="data">
        <TxScreeningProfileTd :profile="data.item.originator" />
      </template>
      <template #recipientRouteCode="data">
        <TxScreeningProfileTd :profile="data.item.recipient" />
      </template>
      <template #Direction="data">
        {{ data.item.inOrOut === 'IN' ? 'INCOMING' : 'OUTGOING' }}
      </template>
      <template #Reference="data">
        <TxReferenceTd :reference-items="getReference(data)" />
      </template>
      <template #Actions="data">
        <div class="d-flex gap-2 flex-nowrap align-items-center">
          <button
            v-if="data.item.status === 'EXECUTION_FAILURE'"
            v-has-permission="'MANUAL_ALLOCATE_TRANSFER'"
            class="btn btn-warning d-flex flex-nowrap align-items-center"
            @click="showManualAllocateModal(data.item)"
          >
            <i class="fas fa-dollar-sign" />&nbsp;Manual&nbsp;Allocate
          </button>
          <button
            v-if="data.item.status === 'AML_REJECTED'"
            class="btn btn-info d-flex flex-nowrap align-items-center"
            @click="$emit('getPendingReturnsDetails', data.item)"
          >
            <i class="fa fa-search" />&nbsp;&nbsp;Details
          </button>
        </div>
      </template>
    </data-grid>
    <TxManualAllocateModal />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import apiService from '@/services/apiService';
import TxScreeningProfileTd from '@/components/transaction-monitoring/custom/TxScreeningProfileTd.vue';
import TxReferenceTd from '@/components/transaction-monitoring/custom/TxReferenceTd.vue';
import { transferResponseAdapter } from '@/services/adapters/transaction-monitoring/transferResponseAdapter';
import TxManualAllocateModal from '@/components/transaction-monitoring/modals/TxManualAllocateModal.vue';
import { useTxMonitoringStore } from '@/stores/txmonitoring';
import DataGrid from '@/components/shared/data-grid/index.vue';
import TxSearch from '@/components/transaction-monitoring/custom/TxSearch.vue';

export default {
  name: 'TxPendingReturnSWIFT',
  components: {
    TxScreeningProfileTd,
    DataGrid,
    TxReferenceTd,
    TxManualAllocateModal,
    TxSearch
  },
  data () {
    return {
      itemPerPage: 15,
      currentPage: 1,
      totalRows: 0,
      offset: 0,
      items: [],
      searchText: '',
      fields: [
        { key: 'id', label: 'Transfer ID' },
        { key: 'displayAmount', label: 'Amount' },
        { key: 'originatorRouteCode', label: 'Originator bank routing code / account / name' },
        { key: 'recipientRouteCode', label: 'Recipient bank routing code / account / name' },
        { key: 'Direction', label: 'Direction' },
        { key: 'type', label: 'Type' },
        { key: 'paymentType', label: 'Payment Type' },
        { key: 'status', label: 'Status' },
        { key: 'errorCode', label: 'Error Code' },
        { key: 'errorMessage', label: 'Error Message' },
        { key: 'Reference', label: 'Reference' },
        { key: 'Actions', label: 'Actions' }
      ]
    };
  },
  computed: {
    ...mapState(useTxMonitoringStore, ['needsToReloadList', 'paymentType'])
  },
  watch: {
    currentPage () {
      this.getPendingReturns();
    },
    needsToReloadList (val) {
      if (val) {
        this.getPendingReturns();
        this.setNeedsToReloadList(false);
      }
    }
  },
  mounted () {
    this.setPaymentType('SWIFT');
    this.getPendingReturns();
  },
  methods: {
    ...mapActions(useTxMonitoringStore, ['setPaymentType', 'setWatchmanLists', 'setSelectedTransaction', 'setNeedsToReloadList']),
    getReference (reference) {
      return reference.item.reference || [];
    },
    updateEntriesPerPage (params) {
      this.itemPerPage = params;
      this.getPendingReturns();
    },
    async getPendingReturns (offset, searchId) {
      const params = {
        paymentType: this.paymentType,
        originatedBy: 'external party',
        status: 'AML_REJECTED,EXECUTION_FAILURE,CANCELLED',
        offset: offset || this.offset,
        limit: this.itemPerPage,
        sortOrder: 'DESC',
        transferId: searchId
      };

      try {
        const { data: { pageItems: transfers, totalFilteredRecords: totalRows } } = await apiService.transfer.getTransfers(params);
        this.totalRows = totalRows;
        this.items = transferResponseAdapter(transfers);
      } catch (e) {
        this.$notify.error(e);
      }
    },
    async showDetails (item) {
      this.setSelectedTransaction(item);
      this.setWatchmanLists(await apiService.watchmanLists.getWatchmanDetails(item.watchmanId));
      this.$nextTick(() => this.$bvModal.show('tx-details-modal'));
    },
    showManualAllocateModal (item) {
      this.setSelectedTransaction(item);
      this.$bvModal.show('tx-manual-allocate-modal');
    },
    search (id) {
      this.getPendingReturns(this.offset, id);
    }
  }
};
</script>
