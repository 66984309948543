<template>
  <div>
    <button
      :class="`btn btn-${modalType === 'delete'?'danger':'primary'} me-1`"
      @click="showModal"
    >
      <i :class="`fas fa-${actionIcon}`" />
      <span
        v-if="buttonTitle"
        class="ms-1"
      >{{ buttonTitle }}</span>
    </button>
    <base-modal
      :id="id"
      ref="thisModal"
      @on-submit="submit"
    >
      <template #header>
        <h4>{{ $t(`label.heading.${modalType}Code`) }}</h4>
      </template>
      <template #body>
        <form-wrapper
          v-if="modalType !== 'delete'"
          ref="manageCodeForm"
        >
          <input-text
            v-model="formData.name"
            :label="$t(`label.anchor.codeName`)"
            required
          />
        </form-wrapper>
        <h6 v-if="modalType === 'delete'">
          {{ $t(`label.anchor.areYouSureYouWantToDelete`) +' '+ codeName }}?
        </h6>
      </template>
    </base-modal>
  </div>
</template>

<script setup>
import { InputText, FormWrapper } from '@/components/shared/form-input';
import BaseModal from '@/components/shared/BaseModal.vue';
import { toRefs, ref, computed, reactive } from 'vue';
import apiService from '@/services/apiService';
import { $notify } from '@/plugins/prototypes';
import router from '@/router';

const props = defineProps({
  id: {
    type: String,
    default: 'base-modal'
  },
  item: {
    type: Object,
    default: () => {}
  },
  buttonTitle: {
    type: String,
    default: ''
  },
  modalType: {
    type: String,
    required: true
  }
});
const { item } = toRefs(props);

const codeName = computed(() => {
  return props.item?.name || '';
});
const formData = reactive({
  name: item.value?.name
});

const modalType = computed(() => props.modalType === 'delete-code-value' ? 'delete' : props.modalType);

const actionIcon = computed(() => modalType.value === 'delete' ? 'trash' : modalType.value);

const emit = defineEmits(['on-submitted']);

const manageCodeForm = ref(null);
const submit = async () => {
  if (await manageCodeForm.value?.validate() === 'errors') return;
  const { codeId: parentCodeId } = router.currentRoute.params;
  const codeId = item.value?.id;
  try {
    if (props.modalType === 'delete') {
      await apiService.codeResources.delete(codeId);
      if (parentCodeId) return router.push({ name: 'ListCodeSystem' });
    }
    if (props.modalType === 'edit') {
      await apiService.codeResources.update(codeId, formData);
    }
    if (props.modalType === 'add') {
      await apiService.codeResources.save(formData);
    }
    if (props.modalType === 'delete-code-value') {
      await apiService.codeValueResource.delete(parentCodeId, codeId);
    }
    formData.name = '';
    emit('on-submitted');
  } catch (e) {
    $notify.error(e);
  } finally {
    thisModal.value.hide();
  }
};

// Modal handaler
const thisModal = ref(null);
const showModal = () => {
  formData.name = props.item?.name;
  thisModal.value.show();
};
// end modal
</script>
