import _ from 'underscore';

export function EditSubscriberController (scope, routeParams, resourceFactory, location) {
  let eventsResource = [];
  scope.formData = [];
  scope.headers = [];
  scope.templates = [];
  scope.subscriptions = [];
  scope.inputHeader = { name: '', value: '' };
  scope.subscriberTypes = ['WEBHOOK', 'EMAIL', 'CLIENT_EMAIL', 'PUSH_NOTIFICATION'];
  scope.isRequiredTarget = !!['WEBHOOK', 'EMAIL'].includes(scope.formData.type);
  scope.isRequiredTemplate = false;
  scope.rowSubscriptions = [];
  scope.inputSubscription = { entity: '', action: '', templateId: null, templateName: '', subscriptionId: null };

  resourceFactory.subscriptionResource.listBySubscriberId({ subscriberId: routeParams.id }, function (data) {
    scope.rowSubscriptions = data.pageItems?.map(item => ({
      id: item.id,
      entity: item.entity,
      action: item.action,
      templateId: item?.template?.id,
      templateName: item?.template?.name,
      subscriptionId: item.id
    }));
  });

  resourceFactory.subscriberResource.getSubscriberDetails({ subscriberId: routeParams.id }, function (data) {
    scope.listEventsByType(data.type);
    scope.formData = data;
    scope.headers = data?.headers;

    resourceFactory.templateResource.get(function (response) {
      if (data.type === 'PUSH_NOTIFICATION') {
        response = response.filter(template => template.type === 'Push Notification');
      }
      if (['EMAIL', 'CLIENT_EMAIL'].includes(data.type)) {
        response = response.filter(template => template.type === 'E-Mail');
      }
      scope.templates = response;
    });
  });

  scope.addHeader = function () {
    const hasDuplicateHeader = scope.headers.find(header => header.name === scope.inputHeader.name && header.value === scope.inputHeader.value);
    if (
      scope.inputHeader.name &&
      scope.inputHeader.value &&
      !hasDuplicateHeader
    ) {
      scope.headers.push({ name: scope.inputHeader.name, value: scope.inputHeader.value });
    }

    scope.inputHeader = { name: '', value: '' };
  };
  scope.deleteHeader = function (index) {
    scope.headers.splice(index, 1);
  };
  scope.listEventsByType = function (type) {
    resourceFactory.subscriptionResource.listEvents({ resourceType: 'events', type }, function (response) {
      eventsResource = response;
      scope.eventEntity = eventsResource.map(resource => resource.entity).sort();
    });
  };

  scope.onTemplateChange = function (templateId) {
    const template = scope.templates.find(item => item.id === templateId);
    scope.isRequiredTemplate = false;
    scope.inputSubscription.templateName = template?.name;
  };

  scope.onEntityChange = function (entity) {
    scope.inputSubscription.action = '';
    scope.eventActions = eventsResource.find(resource => resource.entity === entity)?.actions.sort();
  };

  scope.onSubscriberTypeChange = function (type) {
    scope.listEventsByType(type);
  };

  scope.addSubscription = function () {
    scope.isEmailType = ['EMAIL', 'CLIENT_EMAIL', 'PUSH_NOTIFICATION'].includes(scope.formData.type);
    scope.isRequiredTemplate = (
      scope.isEmailType &&
      scope.inputSubscription.entity &&
      scope.inputSubscription.action &&
      !scope.inputSubscription.templateId
    );
    if (scope.isRequiredTemplate) return;

    const hasDuplicateRowSubscriptionWithTemplate = scope.rowSubscriptions.find(row => row.entity === scope.inputSubscription.entity &&
      row.action === scope.inputSubscription.action &&
      row.templateId === scope.inputSubscription.templateId);

    if (
      scope.isEmailType &&
      scope.inputSubscription.entity &&
      scope.inputSubscription.action &&
      scope.inputSubscription.templateId &&
      !hasDuplicateRowSubscriptionWithTemplate
    ) {
      scope.rowSubscriptions.push({ ...scope.inputSubscription, subscriptionId: null });
    }

    const hasDuplicateRowSubscriptionWithoutTemplate = scope.rowSubscriptions.find(row => row.entity === scope.inputSubscription.entity && row.action === scope.inputSubscription.action);
    if (
      !scope.isEmailType &&
      scope.inputSubscription.entity &&
      scope.inputSubscription.action &&
      !hasDuplicateRowSubscriptionWithoutTemplate
    ) {
      scope.rowSubscriptions.push({
        entity: scope.inputSubscription.entity,
        action: scope.inputSubscription.action,
        subscriptionId: null
      });
    }

    scope.eventActions = [];
    scope.inputSubscription = { entity: '', action: '', templateId: null, templateName: '' };
  };

  scope.deleteRowSubscription = function (index) {
    if (scope.rowSubscriptions[index]?.subscriptionId) { scope.deleteSubscription(scope.rowSubscriptions[index].subscriptionId); } else { scope.rowSubscriptions.splice(index, 1); }
  };

  scope.formatData = function (events, subscriberId) {
    const groupedByEntity = _.mapObject(_.groupBy(events, 'entity'), // Group the events by entity
      event => event.map(obj => _.omit(obj, ['entity', 'subscriptionId', 'templateName']))); // Remove unwanted properties from each event
    return { subscriberId, entityMap: groupedByEntity }; // Return the formatted data
  };

  scope.deleteSubscription = function (id) {
    resourceFactory.subscriptionResource.delete({ subscriptionId: id }, {}, (response) => {
      if (response) { scope.rowSubscriptions = scope.rowSubscriptions.filter(item => item.subscriptionId !== response.resourceId); }
    });
  };
  scope.submit = function () {
    scope.formData.headers = scope.headers?.length ? [...scope.headers] : [];
    const subscriberDetails = _.pick(scope.formData, ['name', 'target', 'deliveryRetryLimit', 'active', 'secretKey', 'headers']);
    const newlyAddSubscriptions = scope.rowSubscriptions.filter(item => item.subscriptionId === null);
    const formattedData = scope.formatData(newlyAddSubscriptions, routeParams.id);

    resourceFactory.subscriberResource.update({ subscriberId: routeParams.id }, subscriberDetails);
    resourceFactory.subscriptionResource.save(formattedData, function () {
      location.path(`/viewsubscriber/${routeParams.id}`);
    });
  };
}

EditSubscriberController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
