export const watchmanDetailsRequest = (id) => {
  return `{
      AMLScreeningResults(where: {details: {id: {EQ: ${id}}}}) {
        select {
          id(orderBy: DESC)
          details{
            id
          }
          flagged
          transfer {
            currency {
              symbol
            }
            valueDate
            status
            id
            amount
            type
            inOut
            creditor {
              name
              address
              country
              stateOrProvince
              postalCode
              city
              agent{
                name
                address
              }
            }
            debtor {
              name
              address
              country
              stateOrProvince
              postalCode
              city
              agent{
                name
                address
              }
            }
            executedAt
            paymentSubType
          }
        }
      }
    }`;
};
