export function CreateExternalWireTransferController ($scope, $rootScope, resourceFactory, $uibModal, location, routeParams) {
  $scope.fromAccountId = routeParams.accountId;

  $scope.formData = {};

  $scope.isIntermediaryFiFieldsRequired = false;
  $scope.isIntermediaryFiIdentifierAndBeneficiaryFiRequired = false;
  $scope.formErrors = {};
  $scope.countryOptions = [];
  $scope.stateOptions = [];
  let stateProvinceIdOptions = [];
  $scope.zipMaxLen = 5;

  resourceFactory.clientaddressFields.get(function (data) {
    $scope.addressTypes = data.addressTypeIdOptions;
    $scope.countryOptions = sortedCountry(data.countryIdOptions);
    stateProvinceIdOptions = data.stateProvinceIdOptions;
  });

  resourceFactory.clientAddress.query({ clientId: routeParams.clientId }, function (data) {
    $scope.originatorAddress = data;
    const primaryAddress = $scope.originatorAddress.find(a => a.addressType === 'PRIMARY');
    if (primaryAddress.minifiedAddress) {
      $scope.formData.originatorAddressLineOne = primaryAddress.minifiedAddress[0];
      $scope.formData.originatorAddressLineTwo = primaryAddress.minifiedAddress[1];
      $scope.formData.originatorAddressLineThree = primaryAddress.minifiedAddress[2];
    }
  });

  $scope.businessFuncCodes = [
    { code: 'CTR', label: 'CTR - Customer Transfer(Beneficiary is not bank)' }
    // { code :'BTR', label: 'BTR - Bank Transfer(Beneficiary is a bank)' }, // might require in future . Don't delete immediately
  ];

  $scope.formData.businessFunctionCode = $scope.businessFuncCodes[0].code;
  $scope.formData.transactionType = '';

  $scope.wireTypes = [
    { typeSubTypeCode: '1000', typeSubTypeLabel: '1000 - Fund Transfer/ Basic Fund Transfer' }
    // { typeSubTypeCode : '1001', typeSubTypeLabel : '1001 - Fund Transfer/ Request for Reversal' }, // might require in future . Don't delete immediately
  ];

  $scope.formData.wireType = $scope.wireTypes[0].typeSubTypeCode;
  $scope.textAreaLineParamNames = ['addressLineOne', 'addressLineTwo', 'addressLineThree', 'addressLineFour', 'addressLineFive', 'addressLineSix'];
  $scope.lineParamNames = ['lineOne', 'lineTwo', 'lineThree', 'lineFour', 'lineFive', 'lineSix'];
  // before submitting the form call the input string with required aramet it will generate object then assign that object wherever you want
  $scope.textSplitMethod = function (paramNames, firstLineNumberOfChar, otherLinesNumberOfChar, value, totalNumberOfLine) {
    let stringLength = value.length;
    const data = {};
    let startIndex = 0;
    let endIndex = firstLineNumberOfChar;
    for (let i = 0; i < totalNumberOfLine && stringLength > 0; i++) {
      const lineLength = (i === 0) ? firstLineNumberOfChar : otherLinesNumberOfChar;
      if (stringLength <= lineLength) {
        data[paramNames[i]] = value.substring(startIndex, value.length);
        stringLength = 0;
      } else {
        data[paramNames[i]] = value.substring(startIndex, endIndex);
        stringLength -= lineLength;
        startIndex = endIndex;
        endIndex = otherLinesNumberOfChar + endIndex;
      }
    }
    return data;
  };

  $scope.textSplitAsArray = function (firstLineNumberOfChar, otherLinesNumberOfChar, value, totalNumberOfLine) {
    const data = [];
    if (value !== undefined && value.length > 0) {
      value = value.replace(/(\r\n|\n|\r)/gm, ' ');
      let length = value.length;
      let startIndex = 0;
      let endIndex = firstLineNumberOfChar;
      for (let i = 0; i < totalNumberOfLine && length > 0; i++) {
        const lineLength = (i === 0) ? firstLineNumberOfChar : otherLinesNumberOfChar;
        if (length <= lineLength) {
          data.push(value.substring(startIndex, value.length));
          length = 0;
        } else {
          data.push(value.substring(startIndex, endIndex));
          length -= lineLength;
          startIndex = endIndex;
          endIndex = otherLinesNumberOfChar + endIndex;
        }
      }
    }
    return data;
  };

  $scope.currencyCodes = [
    { code: 'USD', label: 'USD' },
    { code: 'GBP', label: 'GBP' },
    { code: 'INR', label: 'INR' }
  ];

  $scope.IDCodes5000and4200 = [
    { code: 'B', label: 'B - SWIFT Bank Identifier Code(BIC)' },
    { code: 'C', label: 'C - CHIPS Participant' },
    { code: 'D', label: 'D - Demand Deposit Account(DDA) Number ' },
    { code: 'F', label: 'F - Fed Routing Number' },
    { code: 'T', label: 'T - SWIFT BIC or Bank Entity Identifier(BEI) and Account Number' },
    { code: 'U', label: 'U - CHIPS Identifier' },
    { code: '1', label: '1 - Passport Number' },
    { code: '2', label: '2 - Tax Identification Number' },
    { code: '3', label: '3 - Driver\'s License Number' },
    { code: '4', label: '4 - Alien Registration Number' },
    { code: '5', label: '5 - Corporate Identification' },
    { code: '9', label: '9 - Other Identification' }
  ];

  $scope.formData.originatorIdCode = $scope.IDCodes5000and4200[2].code;
  $scope.formData.beneficiaryIdCode = $scope.IDCodes5000and4200[2].code;

  $scope.IDCodes5100and5200 = [
    { code: 'B', label: 'B - SWIFT Bank Identifier Code(BIC)' },
    { code: 'C', label: 'C - CHIPS Participant' },
    { code: 'D', label: 'D - Demand Deposit Account(DDA) Number ' },
    { code: 'F', label: 'F - Fed Routing Number' },
    { code: 'U', label: 'U - CHIPS Identifier' }
  ];

  $scope.intermediaryFiCodes = [
    { code: 'B', label: 'B - SWIFT Bank Identifier Code(BIC)' },
    { code: 'C', label: 'C - CHIPS Participant' },
    { code: 'D', label: 'D - Demand Deposit Account(DDA) Number ' },
    { code: 'F', label: 'F - Fed Routing Number' },
    { code: 'U', label: 'U - CHIPS Identifier' }
  ];

  $scope.detailsOfCharges = [
    { code: 'B', label: 'B - Beneficiary' },
    { code: 'S', label: 'S - Shared' }
  ];

  $scope.adviceCodes = [
    { code: 'LTR', label: 'LTR - Letter' },
    { code: 'PHN', label: 'PHN - Phone' },
    { code: 'TLX', label: 'TLX - Telex' },
    { code: 'WRE', label: 'WRE - Wire' },
    { code: 'HLD', label: 'HLD - Hold' }
  ];

  $scope.methodOfPayments = [
    { code: 'CHECK', label: 'CHECK' }
  ];

  // $scope.formData.paymentMethod = $scope.methodOfPayments[0].code

  // Custom names for payment types
  $scope.paymentTypeLabels = { WIRE: 'Domestic Wire', SWIFT: 'International Wire [SWIFT]' };

  resourceFactory.savingsResource.get({ accountId: routeParams.accountId }, function (accountData) {
    $scope.formData.originatorIdentifier = accountData.accountNo;
    $scope.formData.originatorName = accountData.clientName;
  });

  $scope.onIntermediaryFiCodeSelect = function () {
    if ($scope.formData.intermediaryFiCode) {
      $scope.isIntermediaryFiFieldsRequired = true;
    } else {
      $scope.isIntermediaryFiFieldsRequired = false;
    }
  };

  $scope.onEnteringIntermediaryFiIdentifier = function () {
    if ($scope.formData.intermediaryFiIdentifier) {
      $scope.isIntermediaryFiIdentifierAndBeneficiaryFiRequired = true;
    } else {
      $scope.isIntermediaryFiIdentifierAndBeneficiaryFiRequired = false;
    }
  };

  $scope.onBeneficiaryFiCodeSelect = function () {
    $scope.isBeneficiaryFiIdentifierRequired = !!$scope.formData.beneficiaryFiIdCode;
  };

  $scope.onEnteringBeneficiaryFiIdentifier = function () {
    $scope.isBeneficiaryFiIdCodeRequired = !!$scope.formData.beneficiaryFiIdentifier;
  };

  // $scope.onPaymentTypeChange = function(){
  //   if($scope.formData.paymentType === 'SWIFT'){
  //     $scope.intermediaryFiCodes = [
  //       { code : 'B', label: 'B - SWIFT Bank Identifier Code(BIC)' }
  //     ]
  //   }else if($scope.formData.paymentType === 'WIRE'){
  //     $scope.intermediaryFiCodes = [
  //       { code : 'D', label: 'D - Demand Deposit Account(DDA) Number ' }
  //     ]
  //   }
  // }

  function getWireData () {
    // Check if both identifier and name are not present remove it from sending to CORE
    // We do this because for domesitc wire identifer and name are not mandatory
    // If we were to send an `agent` object with undefined identifier and name CORE will return an error

    const agent = !$scope.formData.beneficiaryFiIdentifier && !$scope.formData.beneficiaryFiName
      ? undefined
      : {
          identifier: $scope.formData.beneficiaryFiIdentifier,
          name: $scope.formData.beneficiaryFiName
        };

    const data = {
      type: 'CREDIT',
      dateFormat: $scope.df,
      paymentType: $scope.formData.paymentType,
      amount: $scope.formData.amount,
      reference: [],
      debtor: {
        identifier: `ACCOUNT:${$scope.formData.originatorIdentifier}`,
        name: $scope.formData.originatorName
      },
      creditor: {
        name: $scope.formData.beneficiaryName,
        agent,
        address: [] // Populate address details later
      },
      paymentRailMetaData: {
        businessFunctionCode: {
          businessFunctionCode: $scope.formData.businessFunctionCode,
          transactionTypeCode: $scope.formData.transactionType
        },
        receiverDepositoryInstitution: {
          receiverABANumber: $scope.formData.receiverAbaNum,
          receiverShortName: $scope.formData.receiverName
        },
        originator: {
          personal: {
            identificationCode: $scope.formData.originatorIdCode
          }
        },
        beneficiary: {
          personal: {
            identificationCode: $scope.formData.beneficiaryIdCode
          }
        },
        beneficiaryFI: {
          financialInstitution: {
            identificationCode: $scope.formData.beneficiaryFiIdCode
          }
        },
        beneficiaryIntermediaryFI: {
          financialInstitution: {}
        }
      }
    };

    if ($scope.formData.beneficiaryFiIdentifier) {
      data.creditor.identifier = `${$scope.formData.paymentType}://${$scope.formData.beneficiaryFiIdentifier}/${$scope.formData.beneficiaryIdentifier}`;
    } else {
      data.creditor.identifier = `${$scope.formData.paymentType}://${$scope.formData.receiverAbaNum}/${$scope.formData.beneficiaryIdentifier}`;
    }

    if ($scope.formData.OriginatorToBeneficiaryReference) {
      data.reference = $scope.textSplitAsArray(35, 35, $scope.formData.OriginatorToBeneficiaryReference, 4);
    }
    if ($scope.formData.fiToFiInformationReference) {
      data.paymentRailMetaData.fiAdditionalFiToFi = {};
      data.paymentRailMetaData.fiAdditionalFiToFi.additionalFiToFi = {};
      data.paymentRailMetaData.fiAdditionalFiToFi.additionalFiToFi = $scope.textSplitMethod($scope.lineParamNames, 35, 35, $scope.formData.fiToFiInformationReference, 6);
    }
    if ($scope.formData.beneficiaryAddressLineOne) {
      data.creditor.address.push($scope.formData.beneficiaryAddressLineOne);
    }
    if ($scope.formData.beneficiaryAddressLineTwo) {
      data.creditor.address.push($scope.formData.beneficiaryAddressLineTwo);
    }
    const beneficiaryAddressLineThree = constructAddressLine($scope.formData.beneficiaryCity, $scope.formData.beneficiaryCountry, $scope.formData.beneficiaryState, $scope.formData.beneficiaryPostalCode);
    data.creditor.address.push(beneficiaryAddressLineThree);
    if ($scope.formData.beneficiaryCountry) {
      data.creditor.country = $scope.countryOptions.find(country => country.id === $scope.formData.beneficiaryCountry).identifier;
    }
    if ($scope.formData.beneficiaryState) {
      data.creditor.stateOrProvince = stateProvinceIdOptions.find(state => state.id === $scope.formData.beneficiaryState).name;
    }
    data.creditor.city = $scope.formData.beneficiaryCity;
    data.creditor.postalCode = $scope.formData.beneficiaryPostalCode;
    if ($scope.formData.beneficiaryFiAddressLineOne) {
      data.creditor.agent.address = [];
      data.creditor.agent.address[0] = $scope.formData.beneficiaryFiAddressLineOne;
      if ($scope.formData.beneficiaryFiAddressLineTwo) {
        data.creditor.agent.address[1] = $scope.formData.beneficiaryFiAddressLineTwo;
      }
      const beneficiaryFiAddressLineThree = constructAddressLine($scope.formData.beneficiaryFiCity, $scope.formData.beneficiaryFiCountry, $scope.formData.beneficiaryFiState, $scope.formData.beneficiaryFiPostalCode);
      data.creditor.agent.address[2] = beneficiaryFiAddressLineThree;
      if ($scope.formData.beneficiaryFiCountry) {
        data.creditor.agent.country = $scope.countryOptions.find(country => country.id === $scope.formData.beneficiaryFiCountry).identifier;
      }
    }

    if ($scope.formData.wireType) {
      const typeSubType = $scope.formData.wireType.match(/.{1,2}/g);
      data.paymentRailMetaData.typeSubType = {
        typeCode: typeSubType[0],
        subTypeCode: typeSubType[1]
      };
    }

    if ($scope.formData.instructedAmountCurrencyCode && $scope.formData.instructedAmount) {
      data.paymentRailMetaData.instructedAmount = {
        currencyCode: $scope.formData.instructedAmountCurrencyCode,
        amount: $scope.formData.instructedAmount
      };
    }

    if ($scope.formData.exchangeRate) {
      data.paymentRailMetaData.exchangeRate = { exchangeRate: $scope.formData.exchangeRate };
    }

    if ($scope.formData.beneficiaryReference) {
      data.paymentRailMetaData.beneficiaryReference = { beneficiaryReference: $scope.formData.beneficiaryReference };
    }

    if ($scope.formData.senderReference) {
      data.paymentRailMetaData.senderReference = { senderReference: $scope.formData.senderReference };
    }

    if ($scope.formData.beneficiaryInformationReference) {
      data.creditor.forFurtherCredit = $scope.formData.beneficiaryInformationReference.replace(/(\r\n|\n|\r)/gm, ' ');
    }

    data.paymentRailMetaData.fiPaymentMethodToBeneficiary = {
      paymentMethod: $scope.formData.paymentMethod
    };

    if ($scope.formData.paymentMethodAdditionalInfomation) {
      data.paymentRailMetaData.fiPaymentMethodToBeneficiary.AdditionalInformation = $scope.formData.paymentMethodAdditionalInfomation;
    }

    if ($scope.formData.intermediaryFiCode) {
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.identificationCode = $scope.formData.intermediaryFiCode;
    }
    if ($scope.formData.intermediaryFiIdentifier) {
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.identifier = $scope.formData.intermediaryFiIdentifier;
    }
    if ($scope.formData.intermediaryFiIdentifier) {
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.name = $scope.formData.intermediaryFiName;
    }
    if ($scope.formData.intermediaryFiAddressLineOne) {
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.address = {};
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.address.addressLineOne = $scope.formData.intermediaryFiAddressLineOne;
    }
    if ($scope.formData.intermediaryFiAddressLineTwo) {
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.address.addressLineTwo = $scope.formData.intermediaryFiAddressLineTwo;
    }
    const intermediaryFiAddressLineThree = constructAddressLine($scope.formData.intermediaryFiCity, $scope.formData.intermediaryFiCountry, $scope.formData.intermediaryFiState, $scope.formData.intermediaryFiPostalCode);
    if (intermediaryFiAddressLineThree) {
      data.paymentRailMetaData.beneficiaryIntermediaryFI.financialInstitution.address.addressLineThree = intermediaryFiAddressLineThree;
    }
    return data;
  }

  resourceFactory.paymentTypeResource.getAll(function (dataPaymentTypes) {
    $scope.paymentTypes = dataPaymentTypes.filter(function (dataPaymentType) {
      return ['WIRE', 'SWIFT'].includes(dataPaymentType.name) && dataPaymentType.isSystemDefined;
    });
    // Check if we have custom name for the payment type or not.
    $scope.paymentTypes.forEach(paymentType => {
      paymentType.label = $scope.paymentTypeLabels[paymentType.name] || paymentType.name;
    }
    );
  });

  const successModalCtrl = function ($scope, $uibModalInstance, accountId) {
    $scope.confirm = function () {
      $uibModalInstance.close(location.path(`/viewsavingaccount/${accountId}`));
    };
  };

  $scope.resetInputErrorMessage = inputErrorKey => {
    $scope.formErrors[inputErrorKey] = '';
  };

  const formErrorHandler = apiValidationErrors => {
    $scope.formErrors = {};
    const { data: { errors } } = apiValidationErrors;

    const isRoutingNumberError = displayMessage => {
      const routingNumberErrorDisplayMessage = 'invalid routing number';
      return displayMessage === routingNumberErrorDisplayMessage || $scope.formData.paymentType === 'SWIFT';
    };

    errors.forEach(
      ({ parameterName, developerMessage }) => {
        // CORE returns the same parameter for ( creditor.identifier ) for identifier and routing number
        // On core side both both errors are creditor.identifier error
        // Implement a check on developer message to differentiate them.

        if (parameterName === 'creditor.identifier') { parameterName = isRoutingNumberError(developerMessage) ? 'creditor.identifierRoutingNumber' : parameterName; }

        $scope.formErrors = { ...$scope.formErrors, [parameterName]: developerMessage };
      }
    );
  };

  $scope.handleCountryDropDown = function () {
    $scope.formData.beneficiaryCountry = ``; // re-set
    $scope.formData.beneficiaryFiCountry = ``;
    $scope.formData.intermediaryFiCountry = ``;
    if (['WIRE'].includes($scope.formData.paymentType)) {
      $scope.countries = [$scope.countryOptions.find(country => country.name === `United States`)];
      $scope.zipMaxLen = 5;
      $scope.stateOptions = sortedStateByCountry();
    } else {
      $scope.countries = [...$scope.countryOptions];
      $scope.zipMaxLen = 10;
    }
  };

  $scope.onCountryChange = function (countryId) {
    $scope.stateOptions = sortedStateByCountry(countryId);
  };

  function sortedCountry (_countries) {
    return _countries.sort(function (a, b) {
      return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
    });
  }

  function sortedStateByCountry (countryId) {
    const states = stateProvinceIdOptions.filter(function (_option) {
      return _option.parentId === countryId;
    });

    return states.sort(function (a, b) {
      return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
    });
  }

  function constructAddressLine (city, countryCodeId, stateCodeId, postalCode) {
    let addressLine = ``;
    if (city) {
      addressLine += ` ${city}`;
    }
    if (stateCodeId) {
      const selectedState = stateProvinceIdOptions.find(state => state.id === stateCodeId);
      addressLine += ` ${selectedState.identifier}`;
    }
    if (postalCode) {
      addressLine += ` ${postalCode}`;
    }
    if (countryCodeId) {
      const selectedCountry = $scope.countryOptions.find(country => country.id === countryCodeId);
      addressLine += ` ${selectedCountry.identifier}`;
    }
    return addressLine.trim();
  }

  $scope.submitForApproval = function () {
    const submitData = getWireData();

    resourceFactory.transferResource.save({ command: 'create' }, submitData,
      () => {
        $uibModal.open({
          backdrop: 'static',
          keyboard: 'false',
          templateUrl: 'ConfirmationModal.html',
          controller: successModalCtrl,
          resolve: {
            accountId: () => routeParams.accountId
          }
        });

        location.path(`/viewsavingaccount/${routeParams.accountId}`);
      },
      errorData => formErrorHandler(errorData)
    );
  };

  $scope.cancel = function () {
    window.history.back();
  };
}

CreateExternalWireTransferController.$inject = ['$scope', '$rootScope', 'ResourceFactory', '$uibModal', '$location', '$routeParams'];
