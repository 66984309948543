<template>
  <div>
    <button
      class="btn btn-primary"
      @click="openCreateOrEditModal"
    >
      <i :class="generate.icon" />
      {{ generate.btnTitle }}
    </button>
    <base-modal
      ref="createOrEditChargeClassification"
      modal-size="lg"
      hide-footer
    >
      <template #header>
        <h4>{{ generate.title }}</h4>
      </template>
      <template #body>
        <form-wrapper @submit="onSubmit">
          <input-select
            id="charge"
            v-model="formData.chargeId"
            :options="template.chargeList"
            :label="$t('label.anchor.charge')"
            required
            value-field="id"
            :placeholder="$t('label.anchor.selectAnOption')"
          />
          <input-select
            id="classfication"
            v-model="formData.classificationId"
            :label="$t('label.anchor.classification')"
            :options="template.classificationList"
            required
            value-field="id"
            :placeholder="$t('label.anchor.selectAnOption')"
          />
          <input-text
            id="number-of-exempted-fee"
            v-model="formData.numberOfFeeExemptedCharge"
            required
            :label="$t('label.anchor.numberOfExemptedFee')"
          />
          <input-text
            id="exempted-fee-amount"
            v-model="formData.exemptedFeeAmount"
            required
            :label="$t('label.anchor.exemptedFeeAmount')"
          />
          <div class="text-end mt-3">
            <button
              class="btn btn-default me-2"
              @click.prevent="cancelModal"
            >
              {{ $t('label.button.cancel') }}
            </button>
            <button
              class="btn btn-primary"
              type="submit"
            >
              {{ $t('label.button.submit') }}
            </button>
          </div>
        </form-wrapper>
      </template>
    </base-modal>
  </div>
</template>

<script setup>
import { InputText, InputSelect, FormWrapper } from '@/components/shared/form-input';
import { reactive, ref, computed } from 'vue';
import { $notify } from '@/plugins/prototypes';
import { get } from '@vueuse/core';
import BaseModal from '@/components/shared/BaseModal.vue';
import apiService from '@/services/apiService';
import i18n from '@/plugins/i18n';

const createOrEditChargeClassification = ref('');
const formData = reactive({
  chargeId: null,
  classificationId: null,
  exemptedFeeAmount: null,
  numberOfFeeExemptedCharge: null,
  locale: 'en'
});
const template = reactive({
  chargeList: [],
  classificationList: []
});
const props = defineProps({
  savingChargeClassificationId: {
    type: [String, Number],
    default: ''
  }
});

const emits = defineEmits(['on-success']);
const generate = computed(() => {
  const createText = { icon: 'fas fa-plus', title: i18n.t('label.heading.createSavingChargeClassification'), btnTitle: i18n.t('label.button.create') };
  const editText = { icon: 'fa fa-edit', title: i18n.t('label.heading.editSavingChargeClassification'), btnTitle: '' };
  return props.savingChargeClassificationId ? editText : createText;
});

const openCreateOrEditModal = async () => {
  await getTemplate();
  if (props.savingChargeClassificationId) await getSavingChargeClassification();
  get(createOrEditChargeClassification).show();
};

const cancelModal = () => {
  const defaultFormData = {
    chargeId: null,
    classificationId: null,
    exemptedFeeAmount: null,
    numberOfFeeExemptedCharge: null,
    locale: 'en'
  };
  Object.assign(formData, defaultFormData);
  get(createOrEditChargeClassification).hide();
};

const getTemplate = async () => {
  try {
    const { data: { chargeList, classificationList } } = await apiService.savingChargeClassification.template();
    const tempClassificationList = classificationList.map(classification => ({
      ...classification,
      name: `${classification.codeName.split('-')[1]} | ${classification.name}`
    }));
    Object.assign(template, { chargeList, classificationList: tempClassificationList });
  } catch (error) {
    $notify(error);
  }
};

const getSavingChargeClassification = async () => {
  try {
    const { data: { chargeId, numberOfFeeExemptedCharge, exemptedFeeAmount, classification: { id: classificationId } } } = await apiService.savingChargeClassification.get(props.savingChargeClassificationId);
    Object.assign(formData, { chargeId, classificationId, exemptedFeeAmount, numberOfFeeExemptedCharge });
  } catch (error) {
    $notify(error);
  }
};

const onSubmit = async () => {
  const keyProvider = props.savingChargeClassificationId ? 'edit' : 'create';
  try {
    await apiService.savingChargeClassification[keyProvider](formData, props.savingChargeClassificationId);
    $notify.success(i18n.t(`label.${keyProvider}ChargeSuccess`));
    emits('on-success');
    cancelModal();
  } catch (error) {
    $notify.error(error);
  }
};
</script>
