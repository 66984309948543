import { defineStore } from 'pinia';
import { config } from '@/config/general.config';
import apiService, { setAxiosBearerHeader, clearAxios2faHeader, clearAxiosBearerHeader } from '@/services/apiService';
import { TokenService } from '@/services/token.service';
import { $notify } from '@/plugins/prototypes';
import { useApplicationStore } from './application';
import { getAngularRootScope } from '@/helper/angularRootScope';

const defaultState = {
  locale: config.INITIAL_LOCALE,
  userDetails: null
};

export const useUserStore = defineStore('user', {
  state: () => ({ ...defaultState }),
  actions: {
    setUserDetails (value) {
      this.userDetails = value;
    },
    resetSession () {
      this.resetTokenAndBearerHeader();
      this.resetPersistStorage();
    },
    resetTokenAndBearerHeader () {
      const has2FaToken = TokenService.get2FAToken;

      TokenService.removeTokenData();
      clearAxiosBearerHeader();
      if (has2FaToken) clearAxios2faHeader();
    },
    resetPersistStorage () {
      localStorage.clear();
      useUserStore().$reset();
      useApplicationStore().$reset();
    },
    async updateUserDetails () {
      const { redirect } = this.router.currentRoute.query;
      const { data } = await apiService.userDetails.get();
      const { data: { isOpenBankingEnabled } } = await apiService.organization.get();
      this.setUserDetails(data);
      TokenService.saveTokenData(this.userDetails);
      TokenService.saveSessionData({
        authenticationKey: this.userDetails.accessToken,
        userPermissions: this.userDetails.permissions,
        userId: this.userDetails.userId
      });
      const $rootScope = getAngularRootScope();
      $rootScope.setPermissions(this.userDetails.permissions);
      useApplicationStore().isOpenBankingEnabled = isOpenBankingEnabled;
      this.router.push({ path: redirect || '/home' });
    },
    async getAuthenticationDetails () {
      const { data } = await apiService.authentication.detail();
      const { isPasswordExpired, isMFARequired, mfaDeliveryMethods } = data;

      if (isMFARequired) {
        if (mfaDeliveryMethods.includes('TOTP')) return this.router.push({ name: 'Totp' });
        return this.router.push({ name: 'RequestOtp' });
      }
      if (isPasswordExpired) return this.router.push({ name: 'RequestResetPassword', query: { isPasswordExpired: true } });
      if (!(isPasswordExpired || isMFARequired)) await this.updateUserDetails();
    },
    async login (credential) {
      try {
        const { data } = await apiService.authentication.login(credential);
        const requiredPasswordReset = data.required_password_reset;
        const bypassResetPasswordEmail = data.should_bypass_reset_password_email;

        setAxiosBearerHeader(data.access_token);
        if (requiredPasswordReset) {
          if (bypassResetPasswordEmail) {
            const { data } = await apiService.authentication.resetPasswordTokenGenerate();
            clearAxiosBearerHeader();
            return this.router.push({ name: 'ResetPasswordPage', params: { token: data?.token } });
          } else {
            return this.router.push({ name: 'RequestResetPassword' });
          }
        }
        await this.getAuthenticationDetails();
      } catch (error) {
        if (error.response.status === 403 && error.response.data.error === 'FORBIDDEN') return $notify.error('Self service user are not allowed to access');
        if (error.response.data.error_description) return $notify.error(error.response.data.error_description);
        return $notify.error(error.response.data.errors[0].defaultUserMessage);
      }
    },
    async ssoLogin (codeToken) {
      const requestParam = {
        client_id: 'backoffice',
        grant_type: 'refresh_token',
        client_secret: 123,
        refresh_token: codeToken
      };
      try {
        const { data } = await apiService.authentication.ssoLogin(requestParam);
        setAxiosBearerHeader(data.access_token);
        await this.getAuthenticationDetails();
      } catch (error) {
        $notify.error(error.response.data.error_description);
      }
    },
    async logout () {
      try {
        await apiService.authentication.logout();
        this.resetSession();
        return this.router.push({ name: 'Login' });
      } catch (error) {
        $notify.error(error.response.data.error_description);
      }
    }
  },
  persist: true
});
