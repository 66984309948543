import { Langs } from '../../models/Langs';

export function UserSettingController (scope, rootScope) {
  scope.dates = [
    'dd MMMM yyyy',
    'dd/MMMM/yyyy',
    'dd-MMMM-yyyy',
    'dd-MM-yy',
    'MMMM-dd-yyyy',
    'MMMM dd yyyy',
    'MMMM/dd/yyyy',
    'MM-dd-yy',
    'yyyy-MM-dd'
  ];

  scope.langs = Langs;

  scope.$watch(function () {
    return scope.df;
  }, function () {
    rootScope.updateDf(scope.df);
  });

  // scope.$watch(function () {
  //   return scope.optlang
  // }, function () {
  //   scope.changeLang(scope.optlang)
  // })
}

UserSettingController.$inject = ['$scope', '$rootScope'];
