export function ViewChargeController ($scope, routeParams, resourceFactory, dateFilter, location, $uibModal) {
  $scope.charge = [];
  $scope.choice = 0;
  $scope.isTransfer = false;
  resourceFactory.chargeResource.get({ chargeId: routeParams.id }, function (data) {
    const day = data.feeOnMonthDay?.day;
    const affix = findDateAffix(day);
    $scope.isTransfer = (data.chargeAppliesTo.id === 5);
    if (data.feeOnMonthDay) {
      data.feeOnMonthDay = `${day + affix} ${dateFilter(new Date(Object.values(data.feeOnMonthDay)), 'MMMM')}`;
    }
    if (data.feeOnMonthDay && data.chargeTimeType.value === 'Monthly Fee') {
      data.feeOnMonthDay = day + affix;
    }
    $scope.charge = data;

    // Formatting the payment name
    if ($scope.charge.paymentRail?.name) {
      const paymentTypeLabels = { WIRE: 'Domestic Wire', SWIFT: 'International Wire' };
      $scope.charge.paymentRail.name = paymentTypeLabels[$scope.charge.paymentRail.name] || $scope.charge.paymentRail.name;
    }
  });

  const findDateAffix = function (day) {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  $scope.deleteCharge = function () {
    $uibModal.open({
      templateUrl: 'deletech.html',
      controller: ChDeleteCtrl
    });
  };
  const ChDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.chargeResource.delete({ chargeId: routeParams.id }, {}, function () {
        $uibModalInstance.close('delete');
        location.path('/charges');
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewChargeController.$inject = ['$scope', '$routeParams', 'ResourceFactory', 'dateFilter', '$location', '$uibModal'];
