export function TellersController ($scope, resourceFactory, location) {
  $scope.tellers = [];

  $scope.TellersPerPage = 15;
  resourceFactory.tellerResource.getAllTellers(function (data) {
    $scope.tellers = data;
  });

  $scope.routeTo = function (id) {
    location.path(`/viewtellers/${id}`);
  };

  $scope.routeToEdit = function (id) {
    location.path(`/viewtellers/${id}`);
  };

  $scope.routeToCashiers = function (id) {
    location.path(`/tellers/${id}/cashiers/`);
  };

  $scope.routeToDelete = function (id) {
    resourceFactory.tellerResource.delete({
      tellerId: id
    }, function () {
      resourceFactory.tellerResource.getAllTellers(function (data) {
        $scope.tellers = data;
      });
      location.path('/tellers');
    });
  };

  $scope.deepCopy = function deepCopy (obj) {
    if (Object.prototype.toString.call(obj) === '[object Array]') {
      const out = []; let i = 0; const len = obj.length;
      for (; i < len; i++) {
        out[i] = deepCopy(obj[i]);
      }
      return out;
    }
    if (typeof obj === 'object') {
      const out = {}; let i;
      for (i in obj) {
        out[i] = deepCopy(obj[i]);
      }
      return out;
    }
    return obj;
  };
}

TellersController.$inject = ['$scope', 'ResourceFactory', '$location'];
