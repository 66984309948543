export function EditDataTableWorkflowController (scope, routeParams, resourceFactory, location) {
  scope.labelerror = 'requiredfield';
  scope.datatableSelect = [];
  scope.entityWorkflows = [];
  scope.entityWorkflowTemplate = {};
  scope.formData = {};
  scope.errorDetails = [];
  scope.dropSteps = [];

  resourceFactory.DataTablesResource.getAllDataTables(function (data) {
    for (const i in data) {
      if (data[i].registeredTableName !== undefined) { scope.datatableSelect.push({ name: data[i].datatableTitle, value: data[i].registeredTableName }); }
    }
    scope.datatableSelect.push({ name: 'Client', value: 'm_client' });
    scope.datatableSelect.push({ name: 'Loan', value: 'm_loan' });
    scope.datatableSelect.push({ name: 'Office', value: 'm_office' });
    scope.datatableSelect.push({ name: 'Saving Account', value: 'm_savings_account' });
  });
  resourceFactory.DataTablesWorkflowResource.getWorkflowDetails({ entityName: routeParams.entityName }, function (data) {
    if (data.entityType) {
      scope.formData.entityType = data.entityType;
      if (data.entityWorkflows !== undefined && data.entityWorkflows.length > 0) {
        for (const i in data.entityWorkflows) {
          scope.entityWorkflows.push({
            datatableName: data.entityWorkflows[i].datatableName,
            isDatatable: data.entityWorkflows[i].isDatatable,
            id: data.entityWorkflows[i].id,
            position: data.entityWorkflows[i].position,
            resourceIdentifier: data.entityWorkflows[i].resourceIdentifier,
            groupName: data.entityWorkflows[i].groupName
          });
        }
      }
    }
  });

  scope.addWorkflowStep = function () {
    scope.entityWorkflows.push({ datatableName: undefined, isDatatable: false, position: undefined, resourceIdentifier: undefined, groupName: undefined });
  };
  scope.removeWorkflow = function (index) {
    if (scope.entityWorkflows[index].id !== undefined) {
      scope.dropSteps.push({ id: scope.entityWorkflows[index].id });
    }
    scope.entityWorkflows.splice(index, 1);
  };
  scope.submit = function () {
    scope.errorDetails = [];
    scope.addSteps = [];
    scope.updateSteps = [];

    if (scope.dropSteps !== undefined && scope.dropSteps.length > 0) { scope.formData.dropSteps = scope.dropSteps; }
    const positionArr = [];
    for (const i in scope.entityWorkflows) {
      if (!scope.entityWorkflows[i].datatableName) {
        const errorObj = {};
        errorObj.args = {
          params: []
        };
        errorObj.args.params.push({ value: 'validation.msg.datatableworkflow.datatableName.cannot.be.blank' });
        scope.errorDetails.push(errorObj);
      }
      if (scope.entityWorkflows[i].position === undefined) {
        const errorObj = {};
        errorObj.args = {
          params: []
        };
        errorObj.args.params.push({ value: 'validation.msg.datatableworkflow.position.cannot.be.blank"' });
        scope.errorDetails.push(errorObj);
      }
      if (scope.entityWorkflows[i].id === undefined) {
        scope.addSteps.push(scope.entityWorkflows[i]);
      } else {
        scope.updateSteps.push(scope.entityWorkflows[i]);
      }
      positionArr.push(Number(scope.entityWorkflows[i].position));
    }
    const unique = positionArr.filter((v, i, a) => a.indexOf(v) === i);
    if (positionArr.length !== unique.length) {
      const errorObj = {};
      errorObj.args = {
        params: []
      };
      errorObj.args.params.push({ value: 'error.msg.workflow.duplicate.position' });
      scope.errorDetails.push(errorObj);
    }
    const start = 1;
    const expectedSeqRange = Array(scope.entityWorkflows.length - start + 1).fill().map((_, idx) => start + idx);
    for (const i in positionArr) {
      if (!(expectedSeqRange.indexOf(positionArr[i]) >= 0)) {
        const errorObj = {};
        errorObj.args = {
          params: []
        };
        errorObj.args.params.push({ value: 'error.msg.workflow.position.out.of.range' });
        scope.errorDetails.push(errorObj);
      }
    }
    if (scope.addSteps !== undefined && scope.addSteps.length > 0) { scope.formData.addSteps = scope.addSteps; }
    if (scope.updateSteps !== undefined && scope.updateSteps.length > 0) { scope.formData.updateSteps = scope.updateSteps; }

    if (scope.errorDetails.length === 0) {
      delete scope.addSteps;
      delete scope.dropSteps;
      delete scope.updateSteps;
      resourceFactory.DataTablesWorkflowResource.update({ entityName: routeParams.entityName }, this.formData, function (data) {
        location.path(`/viewdatatableworkflow/${data.resourceIdentifier}`);
      });
    } else {
      scope.formData.addSteps = [];
      scope.formData.dropSteps = [];
      scope.formData.updateSteps = [];
    }
  };
}

EditDataTableWorkflowController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
