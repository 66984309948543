export function ViewAllProvisoningEntriesController (scope, routeParams, paginatorService, resourceFactory, location) {
  scope.routeTo = function (id) {
    location.path(`/viewprovisioningentry/${id}`);
  };

  scope.viewJournals = function (id) {
    location.path(`/viewprovisioningjournalentry/${id}`);
  };

  scope.recreate = function (id) {
    resourceFactory.provisioningentries.reCreateProvisioningEntries({ entryId: id }, this.formData, function () {
      location.path(`/viewprovisioningentry/${id}`);
    });
  };

  scope.searchTransaction = function () {
    scope.entries = paginatorService.paginate(fetchFunction, 10);
  };

  const fetchFunction = function (offset, limit, callback) {
    const params = {};
    params.offset = offset;
    params.limit = limit;
    params.locale = scope.optlang.code;
    params.dateFormat = scope.df;
    scope.saveSC();
    resourceFactory.provisioningentries.getAll(params, callback);
  };

  paginatorService.currentOffset = 0;
  scope.entries = paginatorService.paginate(fetchFunction, 10);
}

ViewAllProvisoningEntriesController.$inject = ['$scope', '$routeParams', 'paginatorService', 'ResourceFactory', '$location'];
