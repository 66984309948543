<template>
  <div v-if="prompt.state.active">
    <div
      class="modal show d-block"
      tabindex="-1"
      role="dialog"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div
            v-if="prompt.state.title"
            class="modal-header"
          >
            <h1
              class="modal-title fs-5"
            >
              {{ prompt.state.title }}
            </h1>
          </div>
          <div
            v-if="prompt.state.message"
            class="modal-body"
          >
            <p class="text-dark">
              {{ prompt.state.message }}
            </p>
          </div>
          <div class="modal-footer">
            <button
              v-if="prompt.state.type !== 'alert'"
              class="btn btn-default"
              @click="prompt.cancel()"
            >
              {{ prompt.state.cancelText }}
            </button>
            <button
              class="btn btn-primary"
              @click="prompt.ok(userInput)"
            >
              {{ prompt.state.okText }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop" />
  </div>
</template>

<script>
import prompt from '@/plugins/prompt.js';

export default {
  data () {
    return {
      userInput: '',
      prompt
    };
  }
};
</script>
