export function LocalStorageService ($rootScope, prefix, cookie, notify) {
  // If there is a prefix set in the config lets use that with an appended period for readability
  // var prefix = angularLocalStorage.constant;
  if (prefix.substr(-1) !== '.') { prefix = prefix ? `${prefix}.` : ''; }

  return {
    // Checks the browser to see if local storage is supported
    browserSupportsLocalStorage () {
      try {
        return ('localStorage' in window && window.localStorage !== null);
      } catch (e) {
        $rootScope.$broadcast('LocalStorageModule.notification.error', e.message);
        return false;
      }
    },

    // Directly adds a value to local storage
    // If local storage is not available in the browser use cookies
    // Example use: localStorageService.addToLocalStorage('library','angular');
    addToLocalStorage (key, value) {
      // If this browser does not support local storage use cookies
      if (!this.browserSupportsLocalStorage()) {
        $rootScope.$broadcast('LocalStorageModule.notification.warning', 'LOCAL_STORAGE_NOT_SUPPORTED');
        if (notify.setItem) {
          $rootScope.$broadcast('LocalStorageModule.notification.setitem', {
            key,
            newvalue: value,
            storageType: 'cookie'
          });
        }
        return this.addToCookies(key, value);
      }

      // Let's convert undefined values to null to get the value consistent
      if (typeof value === 'undefined') {
        value = null;
      }

      try {
        if (angular.isObject(value) || angular.isArray(value)) { value = angular.toJson(value); }
        localStorage.setItem(prefix + key, value);
        if (notify.setItem) {
          $rootScope.$broadcast('LocalStorageModule.notification.setitem', {
            key,
            newvalue: value,
            storageType: 'localStorage'
          });
        }
      } catch (e) {
        $rootScope.$broadcast('LocalStorageModule.notification.error', e.message);
        return this.addToCookies(key, value);
      }
      return true;
    },

    // Directly get a value from local storage
    // Example use: localStorageService.getFromLocalStorage('library'); returns 'angular'
    getFromLocalStorage (key) {
      if (!this.browserSupportsLocalStorage()) {
        $rootScope.$broadcast('LocalStorageModule.notification.warning', 'LOCAL_STORAGE_NOT_SUPPORTED');
        return this.getFromCookies(key);
      }

      const item = localStorage.getItem(prefix + key);
      // angular.toJson will convert null to 'null', so a proper conversion is needed
      // FIXME not a perfect solution, since a valid 'null' string can't be stored
      if (!item || item === 'null') return null;

      if (item.charAt(0) === '{' || item.charAt(0) === '[') {
        return angular.fromJson(item);
      }
      return item;
    },

    // Remove an item from local storage
    // Example use: localStorageService.removeFromLocalStorage('library'); removes the key/value pair of library='angular'
    removeFromLocalStorage (key) {
      if (!this.browserSupportsLocalStorage()) {
        $rootScope.$broadcast('LocalStorageModule.notification.warning', 'LOCAL_STORAGE_NOT_SUPPORTED');
        if (notify.removeItem) {
          $rootScope.$broadcast('LocalStorageModule.notification.removeitem', {
            key,
            storageType: 'cookie'
          });
        }
        return this.removeFromCookies(key);
      }

      try {
        localStorage.removeItem(prefix + key);
        if (notify.removeItem) {
          $rootScope.$broadcast('LocalStorageModule.notification.removeitem', {
            key,
            storageType: 'localStorage'
          });
        }
      } catch (e) {
        $rootScope.$broadcast('LocalStorageModule.notification.error', e.message);
        return this.removeFromCookies(key);
      }
      return true;
    },

    // Return array of keys for local storage
    // Example use: var keys = localStorageService.getKeysForLocalStorage()
    getKeysFromLocalStorage () {
      if (!this.browserSupportsLocalStorage()) {
        $rootScope.$broadcast('LocalStorageModule.notification.warning', 'LOCAL_STORAGE_NOT_SUPPORTED');
        return false;
      }

      const prefixLength = prefix.length;
      const keys = [];
      for (const key in localStorage) {
        // Only return keys that are for this app
        if (key.substr(0, prefixLength) === prefix) {
          try {
            keys.push(key.substr(prefixLength));
          } catch (e) {
            $rootScope.$broadcast('LocalStorageModule.notification.error', e.Description);
            return [];
          }
        }
      }
      return keys;
    },

    // Remove all data for this app from local storage
    // Example use: localStorageService.clearAllFromLocalStorage();
    // Should be used mostly for development purposes
    clearAllFromLocalStorage () {
      if (!this.browserSupportsLocalStorage()) {
        $rootScope.$broadcast('LocalStorageModule.notification.warning', 'LOCAL_STORAGE_NOT_SUPPORTED');
        return this.clearAllFromCookies();
      }

      const prefixLength = prefix.length;

      for (const key in localStorage) {
        // Only remove items that are for this app
        if (key.substr(0, prefixLength) === prefix) {
          try {
            this.removeFromLocalStorage(key.substr(prefixLength));
          } catch (e) {
            $rootScope.$broadcast('LocalStorageModule.notification.error', e.message);
            return this.clearAllFromCookies();
          }
        }
      }
      return true;
    },

    // Checks the browser to see if cookies are supported
    browserSupportsCookies () {
      try {
        return navigator.cookieEnabled ||
                    ('cookie' in document && (document.cookie.length > 0 ||
                        (document.cookie = 'test').indexOf.call(document.cookie, 'test') > -1));
      } catch (e) {
        $rootScope.$broadcast('LocalStorageModule.notification.error', e.message);
        return false;
      }
    },

    // Directly adds a value to cookies
    // Typically used as a fallback is local storage is not available in the browser
    // Example use: localStorageService.addToCookies('library','angular');
    addToCookies (key, value) {
      if (typeof value === 'undefined') { return false; }

      if (!this.browserSupportsCookies()) {
        $rootScope.$broadcast('LocalStorageModule.notification.error', 'COOKIES_NOT_SUPPORTED');
        return false;
      }

      try {
        let expiry = ''; const expiryDate = new Date();
        if (value === null) {
          // Mark that the cookie has expired one day ago
          expiryDate.setTime(expiryDate.getTime() + (-1 * 24 * 60 * 60 * 1000));
          expiry = `; expires=${expiryDate.toGMTString()}`;
          value = '';
        } else if (cookie.expiry !== 0) {
          expiryDate.setTime(expiryDate.getTime() + (cookie.expiry * 24 * 60 * 60 * 1000));
          expiry = `; expires=${expiryDate.toGMTString()}`;
        }
        if (key) { document.cookie = `${prefix + key}=${encodeURIComponent(value)}${expiry}; path=${cookie.path}`; }
      } catch (e) {
        $rootScope.$broadcast('LocalStorageModule.notification.error', e.message);
        return false;
      }
      return true;
    },

    // Directly get a value from a cookie
    // Example use: localStorageService.getFromCookies('library'); returns 'angular'
    getFromCookies (key) {
      if (!this.browserSupportsCookies()) {
        $rootScope.$broadcast('LocalStorageModule.notification.error', 'COOKIES_NOT_SUPPORTED');
        return false;
      }

      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        let thisCookie = cookies[i];
        while (thisCookie.charAt(0) === ' ') { thisCookie = thisCookie.substring(1, thisCookie.length); }
        if (thisCookie.indexOf(`${prefix + key}=`) === 0) { return decodeURIComponent(thisCookie.substring(prefix.length + key.length + 1, thisCookie.length)); }
      }
      return null;
    },

    removeFromCookies (key) {
      this.addToCookies(key, null);
    },

    clearAllFromCookies () {
      let thisCookie = null;
      const prefixLength = prefix.length;
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        thisCookie = cookies[i];
        while (thisCookie.charAt(0) === ' ') { thisCookie = thisCookie.substring(1, thisCookie.length); }

        const thisKey = thisCookie.substring(prefixLength, thisCookie.indexOf('='));
        this.removeFromCookies(thisKey);
      }
    }
  };
}

LocalStorageService.$inject = ['$rootScope', 'prefix', 'cookie', 'notify'];
