// (function (module) {
//   mbanq.directives = _.extend(module, {
//     dialogDirective () {
//       return function (scope, element, attrs) {
//         const options = scope.$eval(attrs.mfDialogOptions) || {}
//         const eventsMap = scope.$eval(attrs.mfDialog)

//         $(element).dialog(_.defaults(options, {
//           autoOpen: false,
//           draggable: false,
//           resizable: false
//         }))

//         const actionsMap = {
//           show (event, data) {
//             $(element).dialog('option', 'title', data.title)
//             $(element).dialog('open')
//           },
//           hide (event, data) {
//             $(element).dialog('close')
//           }
//         }

//         _.each(_.keys(actionsMap), function (actionName) {
//           const events = eventsMap[actionName].split(',')
//           _.each(events, function (eventName) {
//             scope.$on(eventName, actionsMap[actionName])
//           })
//         })
//       }
//     }
//   })
// }(mbanq.directives || {}))

// mbanq.ng.application.directive('mfDialog', [mbanq.directives.dialogDirective]).run(function ($log) {
//   $log.info('dialogDirective initialized')
// })

import _ from 'underscore';

export function dialogDirective () {
  return function (scope, element, attrs) {
    const options = scope.$eval(attrs.mfDialogOptions) || {};
    const eventsMap = scope.$eval(attrs.mfDialog);

    $(element).dialog(_.defaults(options, {
      autoOpen: false,
      draggable: false,
      resizable: false
    }));

    const actionsMap = {
      show (_, data) {
        $(element).dialog('option', 'title', data.title);
        $(element).dialog('open');
      },
      hide () {
        $(element).dialog('close');
      }
    };

    _.each(_.keys(actionsMap), function (actionName) {
      const events = eventsMap[actionName].split(',');
      _.each(events, function (eventName) {
        scope.$on(eventName, actionsMap[actionName]);
      });
    });
  };
}
