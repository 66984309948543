import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/lang/en';
import { config } from '@/config/general.config';

Vue.use(VueI18n);
const messages = {
  en
};

const numberFormats = {
  en: {
    currency: {
      style: 'currency', currency: 'USD', currencyDisplay: 'symbol'
    }
  }
};

const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    monthYear: {
      year: 'numeric',
      month: 'short'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'long'
    },
    digit: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }
  }
};

// Create VueI18n instance with options
const i18n = new VueI18n({
  fallbackLocale: 'en',
  locale: config.INITIAL_LOCALE,
  messages,
  numberFormats,
  dateTimeFormats
});

export default i18n;
