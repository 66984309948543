export function ViewShareProductController ($scope, routeParams, location, anchorScroll, resourceFactory) {
  resourceFactory.shareProduct.get({ shareProductId: routeParams.id }, function (data) {
    $scope.shareproduct = data;
    $scope.hasAccounting = data.accountingRule.id === 2;
  });

  $scope.scrollto = function (link) {
    location.hash(link);
    anchorScroll();
  };
}

ViewShareProductController.$inject = ['$scope', '$routeParams', '$location', '$anchorScroll', 'ResourceFactory'];
