import PaymentList from '@/pages/payment/index.vue';
import DisablingPaymentRails from '@/pages/payment/disabling-payment-rails.vue';

export const paymentsRoutes = [
  {
    path: '/payments/process-subledger-files',
    name: 'PaymentProcessSubledgerFilesPage',
    component: () => import('@/pages/payment/process-subledger-files/index.vue')
  },
  {
    path: '/payments/process-subledger-returns',
    name: 'PaymentProcessSubledgerReturnsPage',
    component: () => import('@/pages/payment/process-subledger-returns/index.vue')
  },
  {
    path: '/payments',
    name: 'PaymentListPage',
    component: PaymentList
  },
  {
    path: '/payments/disabling-payment-rails',
    name: 'DisablingPaymentRails',
    component: DisablingPaymentRails
  }
];
