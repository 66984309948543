export function ViewCashiersForTellerController ($scope, routeParams, location, resourceFactory) {
  const requestParams = {};

  if (routeParams.tellerId) {
    requestParams.tellerId = routeParams.tellerId;
  }

  resourceFactory.tellerCashierResource.getAllCashiersForTeller({ tellerId: routeParams.tellerId }, function (data) {
    $scope.cashiersForTeller = data;
  });

  resourceFactory.currenciesResource.get({ fields: 'selectedCurrencyOptions' }, function (data) {
    $scope.currencyCode = data.selectedCurrencyOptions[0].code;
  });

  $scope.routeTo = function (tellerId, cashierId) {
    location.path(`/tellers/${tellerId}/cashiers/${cashierId}`);
  };

  $scope.delete = function (tellerId, cashierId) {
    resourceFactory.tellerCashierResource.delete({ tellerId, cashierId }, function () {
      location.path(`/tellers/${tellerId}/cashiers/`);
    });
  };

  $scope.routeToView = function (tellerId, cashierId) {
    location.path(`/tellers/${tellerId}/cashiers/${cashierId}`);
  };

  $scope.allocate = function (tellerId, cashierId) {
    location.path(`/tellers/${tellerId}/cashiers/${cashierId}/actions/allocate/`);
  };
  $scope.settle = function (tellerId, cashierId) {
    location.path(`/tellers/${tellerId}/cashiers/${cashierId}/actions/settle/`);
  };
  $scope.transactions = function (tellerId, cashierId) {
    location.path(`/tellers/${tellerId}/cashiers/${cashierId}/txns/${$scope.currencyCode}`);
  };

  $scope.compare = function (x, y) {
    let objectsAreSame = true;
    for (const propertyName in x) {
      if (x[propertyName] !== y[propertyName]) {
        objectsAreSame = false;
        break;
      }
    }
    return objectsAreSame;
  };

  $scope.deepCopy = function deepCopy (obj) {
    if (Object.prototype.toString.call(obj) === '[object Array]') {
      const out = []; let i = 0; const len = obj.length;
      for (; i < len; i++) {
        out[i] = deepCopy(obj[i]);
      }
      return out;
    }
    if (typeof obj === 'object') {
      const out = {}; let i;
      for (i in obj) {
        out[i] = deepCopy(obj[i]);
      }
      return out;
    }
    return obj;
  };
}

ViewCashiersForTellerController.$inject = ['$scope', '$routeParams', '$location', 'ResourceFactory'];
