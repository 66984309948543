import angular from 'angular';
import { LocalStorageService } from './LocalStorageService';
import { PaginatorService } from './PaginatorService';
import { SessionManager } from './SessionManager';
import { UIConfigService } from './UIConfigService';
import { config } from './../../../config/general.config';

export default angular
  .module('mbanq.services', [])
  .service('localStorageService', LocalStorageService)
  .service('paginatorService', PaginatorService)
  .service('SessionManager', SessionManager)
  .service('UIConfigService', UIConfigService)
  .value('prefix', config.PREFIX)
  .constant('cookie', { expiry: 30, path: '/' })
  .constant('notify', { setItem: true, removeItem: false })
  .name;
