<template>
  <div class="flex-fill">
    <div
      v-if="isEditing"
      class="col-sm-9 col-md-4"
    >
      <input-text
        id="name-field"
        v-model="roleDetails.name"
        :label="$t('label.input.name')"
        vertical
        required
      />
      <input-text
        id="description-field"
        v-model="roleDetails.description"
        type="textarea"
        :label="$t('label.input.description')"
        vertical
        required
      />
      <input-check-box
        id="selfservice-field"
        v-model="roleDetails.isSelfService"
        :label="$t('label.heading.isSelfService')"
        col-auto
        disabled
      />
    </div>
    <div v-else>
      <PageTitle>{{ roleDetails.name }}</PageTitle>
      <div class="mb-3">
        <i :class="`fa-regular fa-circle-${roleDetails.isSelfService ? 'check text-primary ' : 'xmark text-danger'}`" />
        &nbsp;{{ $t('label.heading.isSelfService') }}
      </div>
      <label class="text-muted">Description</label>
      <div class="col-sm-4">
        {{ roleDetails.description }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { useVModel } from '@vueuse/core';
import { InputText, InputCheckBox } from '@/components/shared/form-input/index';
import PageTitle from '@/components/shared/PageTitle.vue';

const emits = defineEmits(['update:value']);
const props = defineProps({
  value: {
    type: Object,
    default: () => ({
      name: '',
      description: '',
      isSelfService: false
    })
  },
  isEditing: Boolean
});

const roleDetails = useVModel(props, 'value', emits);
</script>
