<template>
  <form-wrapper
    @submit="submitForm()"
  >
    <div class="col-12 col-lg-6">
      <input-select
        v-model="selectedLegalForm"
        :label="$t('label.input.legalForm')"
        :options="legalFormOptions"
        text-field="value"
        value-field="code"
        :disabled="isEdit"
        required
        @input="onUpdateLegalForm"
      />
      <input-select
        v-model="formData.clientClassificationId"
        :label="$t('label.input.clientClassification')"
        :options="clientClassificationsOptions"
        :disabled="!selectedLegalForm || isEdit"
        value-field="id"
        required
      />
      <dynamic-fields
        :items="cards"
        @on-add-fields="addFields"
        @on-remove-fields="removeFields"
      >
        <template #content="{item}">
          <div class="col">
            <input-select
              v-model="item.productId"
              class="col-6"
              :label="$t('label.input.cardProduct')"
              :options="cardProductsOptions"
              value-field="id"
              :disabled="item.isCardProductAlreadyCreated"
              required
            />
          </div>
          <div class="h5">
            {{ $t('label.heading.velocityRules') }}
          </div>

          <div>{{ $t('label.heading.atm') }}</div>
          <hr>
          <div>{{ $t('label.heading.amount') }}</div>
          <div class="row">
            <div class="col-6">
              <input-text
                v-model="item.WITHDRAWAL.SECONDS"
                type="number"
                :label="$t('label.input.single')"
              />
              <input-text
                v-model="item.WITHDRAWAL.WEEKS"
                type="number"
                :label="$t('label.input.weekly')"
              />
            </div>
            <div class="col-6">
              <input-text
                v-model="item.WITHDRAWAL.DAYS"
                type="number"
                :label="$t('label.input.daily')"
              />
              <input-text
                v-model="item.WITHDRAWAL.MONTHS"
                type="number"
                :label="$t('label.input.monthly')"
              />
            </div>
          </div>

          <div>{{ $t('label.heading.pos') }}</div>
          <hr>
          <div>{{ $t('label.heading.amount') }}</div>
          <div class="row">
            <div class="col-6">
              <input-text
                v-model="item.PURCHASE.SECONDS"
                type="number"
                :label="$t('label.input.single')"
              />
              <input-text
                v-model="item.PURCHASE.WEEKS"
                type="number"
                :label="$t('label.input.weekly')"
              />
            </div>
            <div class="col-6">
              <input-text
                v-model="item.PURCHASE.DAYS"
                type="number"
                :label="$t('label.input.daily')"
              />
              <input-text
                v-model="item.PURCHASE.MONTHS"
                type="number"
                :label="$t('label.input.monthly')"
              />
            </div>
          </div>
        </template>
      </dynamic-fields>

      <div class="offset-md-5 mt-4">
        <button
          class="btn btn-default"
          @click.prevent="$router.push('/products/client-classification-limits')"
        >
          {{ $t('label.button.cancel') }}
        </button>
        <button
          class="btn btn-primary"
        >
          {{ $t('label.button.save') }}
        </button>
      </div>
    </div>
  </form-wrapper>
</template>

<script setup>
import { InputText, InputSelect, FormWrapper } from '@/components/shared/form-input/index.js';
import DynamicFields from '@/components/shared/DynamicFields.vue';
import { ref, onMounted, reactive } from 'vue';
import router from '@/router';
import apiService from '@/services/apiService';

const selectedLegalForm = ref();
const clientClassificationsOptions = ref([]);
const reserveClassificationOptions = ref([]);
const legalFormOptions = ref([]);
const cardProductsOptions = ref([]);
const reserveCardProducts = ref([]);
const cards = ref([
  {
    productId: '',
    WITHDRAWAL: {
      SECONDS: '',
      DAYS: '',
      WEEKS: '',
      MONTHS: ''
    },
    PURCHASE: {
      SECONDS: '',
      DAYS: '',
      WEEKS: '',
      MONTHS: ''
    }
  }
]);
const formData = reactive({
  clientClassificationId: null
});

const addFields = () => {
  cards.value.push({
    productId: '',
    WITHDRAWAL: {
      SECONDS: '',
      DAYS: '',
      WEEKS: '',
      MONTHS: ''
    },
    PURCHASE: {
      SECONDS: '',
      DAYS: '',
      WEEKS: '',
      MONTHS: ''
    }
  });
};
const isEdit = ref(false);

onMounted(async () => {
  const { id } = router.currentRoute.params;
  const { data: { clientLegalFormOptions, clientClassificationOptions, cardProductOptions } } = await apiService.clientClassification.template();
  legalFormOptions.value = clientLegalFormOptions;
  reserveClassificationOptions.value = clientClassificationOptions;
  cardProductsOptions.value = cardProductOptions;
  if (id) {
    isEdit.value = true;
    const { data } = await apiService.clientClassification.get(id);
    selectedLegalForm.value = data[0].clientClassification.codeName === 'ClientClassification-PERSON' ? 'legalFormType.person' : 'legalFormType.entity';
    onUpdateLegalForm();
    formData.clientClassificationId = Number(data[0].clientClassification.id);
    cards.value = formFormatted(data);
    reserveCardProducts.value = formFormatted(data);
  }
});

const formFormatted = (input) => {
  return input.map((item) => {
    const result = {
      productId: item.cardProduct.id,
      WITHDRAWAL: {
        SECONDS: '',
        DAYS: '',
        WEEKS: '',
        MONTHS: ''
      },
      PURCHASE: {
        SECONDS: '',
        DAYS: '',
        WEEKS: '',
        MONTHS: ''
      },
      isCardProductAlreadyCreated: true
    };

    item.velocityRules.forEach((rule) => {
      const control = rule.controls[0];
      const timeUnit = rule.timeUnit;
      const value = rule.value;

      result[control][timeUnit] = value;
    });

    return result;
  });
};

const onUpdateLegalForm = () => {
  formData.clientClassificationId = null;

  clientClassificationsOptions.value = reserveClassificationOptions.value.filter(data =>
    selectedLegalForm.value === 'legalFormType.person'
      ? data.codeName === 'ClientClassification-PERSON'
      : data.codeName === 'ClientClassification-ENTITY'
  );
};

const formatToCoreFormat = (input) => {
  const velocityRules = [
    { controls: ['PURCHASE'], timePeriod: 1, timeUnit: 'SECONDS', type: 'AMOUNT' },
    { controls: ['PURCHASE'], timePeriod: 1, timeUnit: 'DAYS', type: 'AMOUNT' },
    { controls: ['PURCHASE'], timePeriod: 1, timeUnit: 'WEEKS', type: 'AMOUNT' },
    { controls: ['PURCHASE'], timePeriod: 1, timeUnit: 'MONTHS', type: 'AMOUNT' },
    { controls: ['WITHDRAWAL'], timePeriod: 1, timeUnit: 'SECONDS', type: 'AMOUNT' },
    { controls: ['WITHDRAWAL'], timePeriod: 1, timeUnit: 'DAYS', type: 'AMOUNT' },
    { controls: ['WITHDRAWAL'], timePeriod: 1, timeUnit: 'WEEKS', type: 'AMOUNT' },
    { controls: ['WITHDRAWAL'], timePeriod: 1, timeUnit: 'MONTHS', type: 'AMOUNT' }
  ];

  const output = input.map(item => ({
    productId: item.productId,
    velocityRules: velocityRules.reduce((rules, rule) => {
      const value = item[rule.controls[0]][rule.timeUnit];
      if (value) {
        rules.push({ ...rule, value });
      }
      return rules;
    }, [])
  }));

  return output;
};

const emit = defineEmits(['on-submit']);
const submitForm = async () => {
  const formatted = formatToCoreFormat(cards.value);
  emit('on-submit', {
    classificationId: formData.clientClassificationId,
    formData: { ...formData, productVelocityRules: formatted },
    reserveCards: reserveCardProducts.value
  });
};

const removeFields = (index) => {
  cards.value.splice(index, 1);
};
</script>
