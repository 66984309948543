export function BulkImportOfficesController (http, scope, resourceFactory, API_VERSION, $rootScope, Upload) {
  scope.first = {
    templateUrl: `${API_VERSION}/offices/downloadtemplate?tenantIdentifier=${$rootScope.tenantIdentifier}&locale=${scope.optlang.code}&dateFormat=${scope.df}`
  };

  scope.formData = {};
  scope.onFileSelect = function (files) {
    scope.formData.file = files[0];
  };

  scope.downloadTemplate = function () {
    const configObj = {
      method: 'GET',
      url: $rootScope.hostUrl + scope.first.templateUrl,
      responseType: 'arraybuffer'
    };
    http(configObj)
      .then(function onFulfilled (response) {
        const el = document.createElement('a');
        const windowUrl = window.URL || window.webkitURL;
        const blob = new Blob([response.data], { responseType: 'arraybuffer' });
        const url = windowUrl.createObjectURL(blob);
        el.href = url;
        el.download = 'OfficeTemplate.xls';
        el.click();
        windowUrl.revokeObjectURL(url);
      }).catch(function onRejection (errorResponse) {
        console.error(errorResponse.status);
      });
  };

  scope.downloadDocumentXhr = function (xurl, fileName) {
    const configObj = {
      method: 'GET',
      url: xurl,
      responseType: 'arraybuffer'
    };

    http(configObj)
      .then(function onFulfilled (response) {
        const a = document.createElement('a');
        const blob = new Blob([response.data], { responseType: 'arraybuffer' });

        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch(function onRejection (errorResponse) {
        console.error(errorResponse);
      });
  };

  scope.refreshImportTable = function () {
    resourceFactory.importResource.getImports({ entityType: 'offices' }, function (data) {
      scope.imports = data.map((document) => ({
        ...document,
        docUrl: `${$rootScope.hostUrl + API_VERSION}/imports/downloadOutputTemplate?importDocumentId=${document.importId}&tenantIdentifier=${$rootScope.tenantIdentifier}`
      }));
    });
  };

  scope.upload = function () {
    Upload.upload({
      url: `${$rootScope.hostUrl + API_VERSION}/offices/uploadtemplate`,
      data: { file: scope.formData.file, locale: scope.optlang.code, dateFormat: scope.df }
    }).then(function () {
      // to fix IE not refreshing the model
      if (!scope.$$phase) {
        scope.$apply();
      }
    });
  };
}

BulkImportOfficesController.$inject = ['$http', '$scope', 'ResourceFactory', 'API_VERSION', '$rootScope', 'Upload'];
