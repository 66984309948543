import appModule from './app.modules';

import localeEn from './global-translations/locale-en.json';

appModule.config(['$translateProvider', ($translateProvider) => {
  $translateProvider.useSanitizeValueStrategy('escaped');

  $translateProvider.translations('en', localeEn);

  $translateProvider.preferredLanguage('en');
  $translateProvider.fallbackLanguage('en');
}]);
