export function EditCardLimitController (scope, routeParams, resourceFactory, location) {
  scope.cardInfo = {};
  scope.error = '';
  scope.clientLimit = {
    WITHDRAWAL: { SECONDS: {}, DAYS: {}, WEEKS: {}, MONTHS: {} },
    PURCHASE: { SECONDS: {}, DAYS: {}, WEEKS: {}, MONTHS: {} }
  };
  scope.primaryAccountId = routeParams.primaryAccountId;
  scope.cardTypeRoute = location.search().cardType;

  scope.limitFormFields = [
    {
      label: 'pos',
      id: 'PURCHASE',
      limits: [
        { id: 'SECONDS', label: 'single' },
        { id: 'DAYS', label: 'daily' },
        { id: 'WEEKS', label: 'weekly' },
        { id: 'MONTHS', label: 'monthly' }
      ]
    },
    {
      label: 'atm',
      id: 'WITHDRAWAL',
      limits: [
        { id: 'SECONDS', label: 'single' },
        { id: 'DAYS', label: 'daily' },
        { id: 'WEEKS', label: 'weekly' },
        { id: 'MONTHS', label: 'monthly' }
      ]
    }
  ];
  scope.velocityRuleIds = [];
  scope.minDate = new Date();

  scope.validateMessage = function (form, { catgory, id }) {
    const formInput = form[`limit_value_${catgory}_${id}`];

    if (formInput.$viewValue === undefined) return;
    if (formInput.$error?.step) return 'Amount must only two digits after the decimal point';
    if (formInput.$error?.min) return 'Amount must be greater than 0';
    if (formInput.$error?.required) return 'Field is required';
  };

  scope.updatecard = function () {
    const controls = Object.keys(scope.clientLimit);

    const velocityRules = controls.map((control) => {
      const timeUnits = Object.keys(scope.clientLimit[control]);

      return timeUnits.map((timeUnit) => {
        const value = scope.clientLimit[control][timeUnit].value;
        let expiryDate = scope.clientLimit[control][timeUnit].expiryDate || null;
        if (expiryDate) {
          expiryDate = `${expiryDate.toISOString().slice(0, 10)} 23:59:59`;
        }

        return {
          controls: [control.toUpperCase()],
          type: 'AMOUNT',
          timePeriod: 1,
          timeUnit: timeUnit.toUpperCase(),
          value,
          expiryDate
        };
      });
    }).flat().filter(velocityRule => !!velocityRule?.value && velocityRule?.value > 0);

    resourceFactory.cardsResource.update({ cardId: routeParams.cardId, command: 'update_limits' }, { velocityRules }, () => {
      scope.cancel();
    }, (error) => {
      scope.error = error.data.errors[0].defaultUserMessage;
    });
  };

  resourceFactory.cardsResource.get({ cardId: routeParams.cardId }, function (card) {
    function setLimitValue (target, apiResponse, limitType) {
      for (let i = 0; i < apiResponse.length; i++) {
        if (apiResponse[i].controls.indexOf(limitType) > -1) {
          if (apiResponse[i].expiryDate) {
            apiResponse[i].expiryDate = new Date(apiResponse[i].expiryDate.date);
          }
          target[apiResponse[i].timeUnit] = apiResponse[i];
        }
      }
    }
    if (card.cardType === 'DEBIT') {
      resourceFactory.savingsAccounts.get({ accountId: card.accountId, fields: 'clientName,clientId' }, ({ clientName, clientId }) => {
        resourceFactory.cardProductsResource.get({ cardProductId: card.productId, fields: 'name' }, ({ name }) => {
          scope.cardInfo = { ...card, account: { clientName, clientId }, product: { name } };

          setLimitValue(scope.clientLimit.PURCHASE, scope.cardInfo.velocityRules, 'PURCHASE');
          setLimitValue(scope.clientLimit.WITHDRAWAL, scope.cardInfo.velocityRules, 'WITHDRAWAL');
        });
      });
    } else {
      resourceFactory.creditAccountResource.get({ accountId: routeParams.cardId, associations: 'account' }, function (data) {
        const { creditAccountData: { creditProductName, clientId, clientName } } = data;
        scope.cardInfo = { ...card, account: { clientName, clientId }, product: { creditProductName } };

        setLimitValue(scope.clientLimit.PURCHASE, scope.cardInfo.velocityRules, 'PURCHASE');
        setLimitValue(scope.clientLimit.WITHDRAWAL, scope.cardInfo.velocityRules, 'WITHDRAWAL');
      });
    }
  });

  scope.cancel = function () {
    if (scope.cardInfo.cardType === 'DEBIT') {
      history.back();
    } else {
      location.path(`/viewcreditaccount/${scope.cardInfo.id}`);
    }
  };
  scope.closeAlert = function () {
    scope.error = '';
  };
}

EditCardLimitController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
