export function ListTransactionsController ($scope, resourceFactory, paginatorService, routeParams) {
  $scope.standingInstructionId = routeParams.instructionId;
  $scope.details = {};
  $scope.transactions = {};
  const fetchFunction = function (offset, limit, callback) {
    const params = {};
    params.offset = offset;
    params.limit = limit;
    params.locale = $scope.optlang.code;
    params.dateFormat = $scope.df;
    params.standingInstructionId = $scope.standingInstructionId;

    resourceFactory.standingInstructionResource.getTransactions(params, function (data) {
      $scope.details.fromAccount = data.fromAccount;
      $scope.details.toAccount = data.toAccount;
      $scope.details.fromAccountType = data.fromAccountType;
      $scope.details.toAccountType = data.toAccountType;
      $scope.details.toClient = data.toClient;
      $scope.details.name = data.name;
      $scope.details.id = data.id;
      callback(data.transactions);
    });
  };
  $scope.transactions = paginatorService.paginate(fetchFunction, 14);
}

ListTransactionsController.$inject = ['$scope', 'ResourceFactory', 'paginatorService', '$routeParams'];
