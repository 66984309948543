/**
  * Reference from JSfiddle : https://jsfiddle.net/sonicblis/2afea34h/9/
  */

export function ScrollableDirective ($document, $interval, $timeout, $window) {
  return {
    restrict: 'A',
    link ($scope, wrappedElement) {
      const element = wrappedElement[0];
      let navTabsElement;
      let scrollTimer;
      const dragInfo = {};

      init();
      function scrollOnWheel ($event) {
        const delta = Math.max(-1, Math.min(1, ($event.originalEvent.wheelDelta || -$event.originalEvent.detail)));
        navTabsElement.scrollLeft -= 15 * delta;
        $event.preventDefault();
      }
      function startScroll ($event) {
        if ($event.target === element) {
          scrollTimer = $interval(function () {
            navTabsElement.scrollLeft += ($event.clientX > 260) ? 10 : -10;
          }, 1000 / 60);
        }
      }

      function stopScroll () {
        $interval.cancel(scrollTimer);
      }

      function onDocumentMouseMove ($event) {
        const differenceX = $event.pageX - dragInfo.lastPageX;

        dragInfo.lastPageX = $event.pageX;
        dragInfo.moved = true;

        navTabsElement.scrollLeft -= differenceX;
      }

      function onDocumentMouseUp () {
        // $event.preventDefault();
        // $event.stopPropagation();
        // $event.cancelBubble = true;

        $document.off('mousemove', onDocumentMouseMove);
        $document.off('mouseup', onDocumentMouseUp);

        if (dragInfo.moved === true) {
          [].forEach.call(navTabsElement.querySelectorAll('li a'), function (anchor) {
            const anchorScope = angular.element(anchor).scope();
            anchorScope.oldDisabled = anchorScope.disabled;
            anchorScope.disabled = true;
          });
          $timeout(function () {
            [].forEach.call(navTabsElement.querySelectorAll('li a'), function (anchor) {
              const anchorScope = angular.element(anchor).scope();
              anchorScope.disabled = anchorScope.oldDisabled;
              delete anchorScope.oldDisabled;
            });
          });
        }
      }

      function onNavTabsMouseDown ($event) {
        const currentlyScrollable = element.classList.contains('scrollable');

        if (currentlyScrollable === true) {
          $event.preventDefault();

          dragInfo.lastPageX = $event.pageX;
          dragInfo.moved = false;

          $document.on('mousemove', onDocumentMouseMove);
          $document.on('mouseup', onDocumentMouseUp);
        }
      }

      function onWindowResize () {
        checkForScroll();
      }

      function checkForScroll () {
        const currentlyScrollable = element.classList.contains('scrollable');
        let difference = 1;

        // determine whether or not it should actually be scrollable
        // the logic is different if the tabs are currently tagged as scrollable

        if (currentlyScrollable === true) {
          difference = navTabsElement.scrollWidth - navTabsElement.clientWidth;
        } else {
          difference = navTabsElement.clientHeight - navTabsElement.querySelector('.nav-tabs > li').clientHeight;
        }

        if (difference > 2) {
          element.classList.add('scrollable');
        }
      }

      function bindEventListeners () {
        wrappedElement.on('mousedown', function ($event) {
          startScroll($event);
        });

        wrappedElement.on('mouseup mouseleave', function ($event) {
          stopScroll($event);
        });

        angular.element(navTabsElement).on('mousedown', onNavTabsMouseDown);
        angular.element(navTabsElement).on('DOMMouseScroll', scrollOnWheel);
        angular.element(navTabsElement).on('mousewheel', scrollOnWheel);

        $window.addEventListener('resize', onWindowResize);

        $scope.$on('$destroy', function () {
          wrappedElement.off('mousedown mouseup mouseleave');
          angular.element(navTabsElement).off('mousedown', onNavTabsMouseDown);
          $window.removeEventListener('resize', onWindowResize);
        });
      }

      $scope.$on('checkTabs', function () {
        $timeout(checkForScroll, 10);
      });

      function init () {
        $timeout(function () {
          navTabsElement = element.querySelector('.nav-tabs');

          bindEventListeners();
          onWindowResize();
        });
      }
    }
  };
}

ScrollableDirective.$inject = ['$document', '$interval', '$timeout', '$window'];
