<template>
  <b-modal
    id="tx-confirm-pending-return-modal"
    hide-header-close
    title="Pending Return Transaction Confirmation"
  >
    <div v-if="pendingReturnChecked && pendingReturnChecked?.length">
      You have selected the following transactions: {{ readableArray }}
    </div>

    <template #modal-footer="{ cancel }">
      <button
        class="btn btn-default"
        @click="cancel"
      >
        {{ $t('label.button.cancel') }}
      </button>
      <button
        class="btn btn-primary"
        @click="handlePendingReturnsSubmit"
      >
        {{ $t('label.button.confirm') }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import apiService from '@/services/apiService';
import { arrayToTextWithAnd } from '@/helper/stringHelper';
import { useTxMonitoringStore } from '@/stores/txmonitoring';

export default {
  name: 'TxConfirmPendingReturnModal',
  computed: {
    ...mapState(useTxMonitoringStore, ['pendingReturnChecked', 'paymentType']),
    readableArray () {
      return arrayToTextWithAnd(this.pendingReturnChecked);
    }
  },
  methods: {
    ...mapActions(useTxMonitoringStore, ['setPendingReturnChecked', 'setNeedsToReloadList']),
    async handlePendingReturnsSubmit () {
      const ids = this.pendingReturnChecked;
      const paymentType = this.paymentType;
      if (!ids.length || !paymentType) return;
      try {
        await apiService.transfer.returnTransfers({ ids, paymentType });
        this.setPendingReturnChecked([]);
        this.$bvModal.hide('tx-confirm-pending-return-modal');
        this.setNeedsToReloadList(true);
      } catch (e) {
        this.$notify.error(e);
      }
    }
  }
};
</script>
