<template>
  <div class="btn-group">
    <base-modal
      ref="editLimitModal"
      on-submit="onSubmit"
      modal-size="xl"
      scrollable
      hide-footer
    >
      <template #header>
        <div>
          <h4>{{ $t('label.heading.editLimit') }}</h4>
        </div>
      </template>
      <template #body>
        <form-wrapper @submit="onSubmit">
          <div
            v-for="(limitValue, limitKey) in clientLimits"
            :key="limitKey"
            :class="{'mb-4': limitKey === 0}"
          >
            <h4> {{ limitKey === 'WITHDRAWAL' ? $t('label.heading.atm') : $t('label.heading.pos') }} </h4>
            <hr>
            <div
              v-for="(value, key, index) in limitValue"
              :key="key"
              class="row m-0"
            >
              <div
                class="col-sm-2 p-0"
                :class="{ 'mt-4': index === 0 }"
              >
                <h6 class="mt-2">
                  {{ key }}
                </h6>
              </div>

              <div class="col-md-4 col-sm-5">
                <input-amount
                  v-model="clientLimits[limitKey][key].value"
                  vertical
                  required
                  :rules="validateAmount"
                  :label="index === 0 ? 'Amount' : ''"
                />
              </div>

              <div class="col-md-4 col-sm-5">
                <input-date-picker
                  v-model="clientLimits[limitKey][key].expiryDate"
                  vertical
                  :min-date="minDate"
                  :label="index === 0 ? 'Date' : ''"
                />
              </div>
            </div>
          </div>
          <div class="pull-right">
            <button
              type="button"
              class="btn btn-default"
              @click.prevent="cancelModal"
            >
              {{ $t('label.button.cancel') }}
            </button>
            <button
              type="submit"
              class="btn btn-primary"
            >
              {{ $t('label.button.save') }}
            </button>
          </div>
        </form-wrapper>
      </template>
    </base-modal>
    <button
      v-if="validEditStatus.includes(cardItem?.status.toLowerCase())"
      class="btn btn-primary"
      has-permission="UPDATE_LIMITS_CARD"
      @click="editLimit"
    >
      <i
        class="fas fa-pencil-alt"
      />
      {{ $t('label.button.editLimit') }}
    </button>
  </div>
</template>

<script setup>
import { validEditStatus } from '@/utils/dictionaries/cardButtonStatuses.dictionary';
import { InputAmount, InputDatePicker, FormWrapper } from '@/components/shared/form-input/index';
import BaseModal from '@/components/shared/BaseModal.vue';
import { ref, onMounted, watch, computed } from 'vue';
import { dateStringToFormat } from '@/helper/dateHelper';
import { get } from '@vueuse/core';
import apiService from '@/services/apiService';
import { $notify } from '@/plugins/prototypes';
import { verlocity } from '@/composable/verlocityLimit';
import i18n from '@/plugins/i18n';

const editLimitModal = ref(null);
const emit = defineEmits(['onStatusChanged']);
const { clientLimits, setInitialLimits, resetLimits } = verlocity();
const today = new Date();
const minDate = new Date(today);
minDate.setDate(today.getDate() - 1);

const props = defineProps({
  cardItem: {
    type: Object,
    default: () => ({})
  },
  cardType: {
    type: String,
    default: ''
  }
});
const validateAmount = computed(() => [v => v > 0 || i18n.t('messages.rules.amountMustBeGreaterThan')]);

watch(() => props.cardItem, () => {
  setInitialLimits(props.cardItem.velocityRules.filter(item => item.type === 'AMOUNT'));
});

onMounted(() => {
  setInitialLimits(props.cardItem.velocityRules.filter(item => item.type === 'AMOUNT'));
});

const editLimit = () => {
  get(editLimitModal).show();
};

const cancelModal = () => {
  resetLimits();
  get(editLimitModal).hide();
};

const onSubmit = async () => {
  const controls = Object.keys(get(clientLimits));
  const velocityRules = controls.map(control => {
    const timeUnits = Object.keys(get(clientLimits)[control]);
    return timeUnits.map(timeUnit => {
      let expiryDate = get(clientLimits)[control][timeUnit]?.expiryDate || null;
      if (expiryDate) expiryDate = `${dateStringToFormat(expiryDate, 'YYYY-MM-DD')} 23:59:59`;

      return {
        controls: [control],
        type: 'AMOUNT',
        timePeriod: 1,
        timeUnit,
        value: get(clientLimits)[control][timeUnit].value,
        expiryDate
      };
    });
  }).flat(Infinity);

  try {
    await apiService.cardsResource.update(props.cardItem.id, { velocityRules }, { command: 'update_limits' });
    $notify.success(i18n.t('label.updateLimitSuccessfully'));
    emit('onStatusChanged');
    cancelModal();
  } catch (error) {
    $notify.error(error);
  }
};
</script>
