import router from '@/router/index';

export function UserDocumentController ($scope, location, routeParams, API_VERSION, Upload, $rootScope) {
  $scope.userId = routeParams.userId;
  $scope.onFileSelect = function (files) {
    $scope.formData.file = files[0];
  };

  $scope.loading = false;
  $scope.submit = function () {
    $scope.loading = true;
    Upload.upload({
      url: `${$rootScope.hostUrl + API_VERSION}/users/${$scope.userId}/documents`,
      data: { name: $scope.formData.name, description: $scope.formData.description, file: $scope.formData.file, type: $scope.formData.type }
    }).then(function () {
      // to fix IE not refreshing the model
      if (!$scope.$$phase) {
        $scope.$apply();
      }
      router.push(`/users/${$scope.userId}`);
    }).finally(() => {
      $scope.loading = false;
    });
  };
}

UserDocumentController.$inject = ['$scope', '$location', '$routeParams', 'API_VERSION', 'Upload', '$rootScope'];
