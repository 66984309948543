<template>
  <section>
    <form-wrapper @submit="handleShowCloseCreditAccountConfirmation">
      <input-date-picker
        id="payoff-date"
        disabled
        :value="today"
        :label="$t(`label.input.payoffDate`)"
        required
      />

      <input-amount
        id="current-outstanding-balance"
        disabled
        :value="formDataDisplay.dueBalance"
        :label="$t('label.input.currentOutstandingBalance')"
        :config="numberConfig"
      />

      <input-amount
        id="accured-interest"
        disabled
        :value="formDataDisplay.accruedInterest"
        :label="$t('label.input.accruedInterest')"
        :config="numberConfig"
      />

      <input-amount
        id="charge"
        disabled
        :value="formDataDisplay.charge"
        :label="$t('label.input.charge')"
        :config="numberConfig"
      />

      <input-amount
        id="total-payoff-amount"
        disabled
        :value="formDataDisplay.totatDueBalance"
        :label="$t('label.input.totalPayoffAmount')"
        :config="numberConfig"
      />

      <input-select
        id="saving-accounts"
        v-model="formData.savingAccount"
        :label="$t('label.input.savingAccounts')"
        :options="formDataDisplay.savingAccounts"
        value-field="id"
        required
      />

      <input-select
        id="closure-reasons"
        v-model="formData.closureReason"
        :label="$t('label.input.closureReasons')"
        :options="formDataDisplay.closureReasons"
        value-field="id"
        required
      />

      <input-text
        id="note"
        v-model="formData.note"
        type="textarea"
        :label="$t('label.input.note')"
      />

      <div class="offset-md-5 mt-4">
        <a
          :href="`#/viewcreditaccount/${creditAccountId}`"
          class="btn btn-default"
        >
          {{ $t('label.button.cancel') }}
        </a>

        <button
          type="submit"
          class="btn btn-primary"
        >
          {{ $t('label.button.submit') }}
        </button>
      </div>
    </form-wrapper>

    <modal-confirm-close
      ref="confirmClose"
      @submit="handleCloseCreditAccount"
    />
  </section>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { get, set } from '@vueuse/core';
import apiService from '@/services/apiService';
import { $notify } from '@/plugins/prototypes';
import { dateStringToFormat } from '@/helper/dateHelper';
import router from '@/router';
import { InputDatePicker, FormWrapper, InputText, InputAmount, InputSelect } from '@/components/shared/form-input';
import ModalConfirmClose from '@/components/clients/credit-accounts/ModalConfirmClose.vue';

const { creditAccountId } = router.currentRoute.params;
const defaultData = {
  dueBalance: 0,
  accruedInterest: 0,
  charge: 0,
  totatDueBalance: 0,
  closureReasons: [],
  savingAccounts: []
};
const today = dateStringToFormat(new Date());
const numberConfig = { prefix: '$ ' };

const confirmClose = ref(null);
const formDataDisplay = ref({ ...defaultData });
const formData = ref({
  savingAccount: null,
  closureReason: null,
  note: null
});

function handleShowCloseCreditAccountConfirmation () {
  get(confirmClose).$children[0].show();
}

async function handleCloseCreditAccount () {
  try {
    await apiService.creditAccountResource.save(creditAccountId, get(formData), { command: 'close' });
    set(formDataDisplay, defaultData);
    $notify.success('Close credit account success!!!');
    location.assign(`#/viewcreditaccount/${creditAccountId}`);
  } catch (error) {
    $notify.error(error);
  } finally {
    get(confirmClose).$children[0].hide();
  }
}

onMounted(async () => {
  try {
    const { data } = await apiService.creditAccountResource.closureTemplate(creditAccountId);
    set(formDataDisplay, {
      ...data,
      savingAccounts: data.savingAccounts.map(savingAccount => ({ id: savingAccount.id, name: savingAccount.accountNo }))
    });
  } catch (error) {
    $notify.error(error);
  }
});
</script>
