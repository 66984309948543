<template>
  <div>
    <div v-if="homePageIdentifier !== 'noAuth'">
      <main-content-layout>
        <template #title>
          <b>{{ pageTitle }}</b>
        </template>
        <template #actions>
          <div
            v-if="homePageIdentifier !== 'default'"
            class="btn-group"
          >
            <button class="btn btn-primary">
              <i class="fas fa-home" />
              {{ $t('label.button.home') }}
            </button>
            <button
              class="btn btn-primary"
              @click="routeToRichDashboard"
            >
              <i class="fas fa-tachometer-alt" />
              {{ $t('label.button.dashboard') }}
            </button>
          </div>
        </template>
        <hr>
        <input-text
          id="expert"
          :placeholder="$t('label.input.searchActivities')"
        />
      </main-content-layout>
    </div>

    <div v-else>
      <p>{{ $t('label.loginForbidden') }}</p>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useUserStore } from '@/stores/user';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import { InputText } from '@/components/shared/form-input/index';
import i18n from '@/plugins/i18n';

const userStore = useUserStore();
const breadcrumb = useBreadcrumbsStore();
const userDetails = userStore.userDetails;

const pageTitle = computed(() => `${i18n.t('label.heading.welcome')}, ${userDetails.username}`);
const homePageIdentifier = computed(() => {
  if (userDetails) {
    const { roles } = userDetails;
    const userRoleId = roles[0].id;
    const roleMap = { 1: 'superuser' };
    if (userRoleId in roleMap) return roleMap[userRoleId];
    return 'default';
  }
  return 'noAuth';
});
const routeToRichDashboard = () => location.assign('#/richdashboard');
onMounted(() => {
  breadcrumb.removeBreadcrumbs();
});
</script>
