export function ViewSavingChargeController (scope, resourceFactory, routeParams, location, $uibModal) {
  scope.savingId = routeParams.savingId;
  scope.chargeId = routeParams.id;

  resourceFactory.savingsResource.get({ resourceType: 'charges', accountId: scope.savingId, chargeId: scope.chargeId }, function (data) {
    scope.charge = data;
    if (routeParams.status === 'Submitted and pending approval') {
      scope.showEditButtons = true;
    }
    if (routeParams.status === 'Active') {
      scope.showWaiveButton = true;
      if (scope.charge.amountOutstanding === 0) {
        scope.showWaiveButton = false;
      }
    }
    if (scope.showWaiveButton) {
      scope.isRecurringCharge = scope.charge.chargeTimeType.value === 'Monthly Fee' ||
        scope.charge.chargeTimeType.value === 'Annual Fee' ||
        scope.charge.chargeTimeType.value === 'Weekly Fee';
    }
  });
  scope.deleteCharge = function () {
    $uibModal.open({
      templateUrl: 'deletecharge.html',
      controller: ChargeDeleteCtrl
    });
  };
  const ChargeDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.savingsResource.delete({ resourceType: 'charges', accountId: scope.savingId, chargeId: scope.chargeId }, {}, function () {
        $uibModalInstance.close('delete');
        location.path(`/viewsavingaccount/${scope.savingId}`);
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
  scope.waiveCharge = function () {
    resourceFactory.savingsResource.save({ resourceType: 'charges', accountId: scope.savingId, chargeId: scope.chargeId }, {}, function () {
      location.path(`/viewsavingaccount/${scope.savingId}`);
    });
  };
}

ViewSavingChargeController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location', '$uibModal'];
