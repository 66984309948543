export function RevolvingLoanController (scope, resourceFactory, location) {
  scope.products = [];

  scope.routeTo = function (id) {
    location.path(`/viewrevolvingloanproduct/${id}`);
  };

  if (!scope.searchCriteria.loanP) {
    scope.searchCriteria.loanP = null;
    scope.saveSC();
  }
  scope.filterText = scope.searchCriteria.loanP || '';

  scope.onFilter = function () {
    scope.searchCriteria.loanP = scope.filterText;
    scope.saveSC();
  };

  scope.LoanProductsPerPage = 15;
  scope.$broadcast('LoanProductDataLoadingStartEvent');
  resourceFactory.loanProductResource.getAllLoanProducts({ loanType: 'revolving' }, function (data) {
    scope.loanproducts = data;
    scope.$broadcast('LoanProductDataLoadingCompleteEvent');
  });
}

RevolvingLoanController.$inject = ['$scope', 'ResourceFactory', '$location'];
