import Vue from 'vue';
import { useSharedStore } from '@/stores/shared';

const setNotify = (type, message) => {
  const sharedStore = useSharedStore();
  sharedStore.setNotifyMessage({
    type,
    message
  });
};

export const $notify = {
  error (error) {
    const errorMessages = [];
    if (error.response?.data?.errors?.length) {
      errorMessages.push(...error.response.data.errors);
    } else if (typeof error === 'string') {
      errorMessages.push({ defaultUserMessage: error });
    } else if (error.response?.data instanceof ArrayBuffer) {
      const binaryArrayBuffer = new Uint8Array(error.response?.data);
      const bufferDecoder = new TextDecoder();
      const jsonDataResponse = bufferDecoder.decode(binaryArrayBuffer);
      const object = JSON.parse(jsonDataResponse);
      errorMessages.push(...object.errors);
    } else {
      console.warn('Error Type Is Invalid');
    }
    setNotify('error', errorMessages);
    return errorMessages;
  },
  success (success) {
    const successMessage = success;
    setNotify('success', successMessage);
    return successMessage;
  }
};

Vue.prototype.$notify = $notify;
