import _ from 'underscore';
import { mlaStatus } from '../../../../utils/templates.js';

export function CreateClientController (scope, resourceFactory, location, dateFilter, routeParams) {
  scope.offices = [];
  scope.staffs = [];
  scope.savingproducts = [];
  scope.first = {};
  scope.first.date = new Date();
  scope.first.submitondate = new Date();
  scope.formData = {};
  scope.formDat = {};
  scope.clientNonPersonDetails = {};
  scope.restrictDate = new Date();
  scope.showSavingOptions = false;
  scope.savings = {};
  scope.savings.opensavingsproduct = false;
  scope.forceOffice = null;
  scope.showNonPersonOptions = false;
  scope.clientPersonId = 1;
  scope.mlaOptions = mlaStatus;
  // address
  scope.addressTypes = [];
  scope.countryOptions = [];
  scope.stateOptions = [];
  scope.addressTypeId = {};
  scope.addressArray = [];
  scope.formData.address = [];
  // familymembers
  scope.formData.familyMembers = [];
  scope.familyArray = [];
  scope.datatables = [];
  scope.noOfTabs = 1;
  scope.step = '-';
  scope.formData.datatables = [];
  scope.formDat.datatables = [];
  scope.tf = 'HH:mm';
  scope.clientId = routeParams.clientId;
  // scope.linkedUsersList = [];

  resourceFactory.clientResource.get({ clientId: scope.clientId }, function (data) {
    scope.displaySubAccountClientName = data.displayName;
  });

  scope.primaryAccountId = routeParams.primaryAccountId;

  const requestParams = { staffInSelectedOfficeOnly: true };
  if (routeParams.groupId) {
    requestParams.groupId = routeParams.groupId;
  }
  if (routeParams.officeId) {
    requestParams.officeId = routeParams.officeId;
  }

  scope.back = () => {
    history.back();
  };

  // resourceFactory.configurationByNameResource.get({}, function () {
  //   scope.findAssociatedUsers(scope.clientId);
  // });
  // scope.findAssociatedUsers = function (clientId) {
  //   let query = `${'{ "query":"{AppUsers ('} `
  //     + 'where: { '
  //     + `AND: { selfServiceUser: {EQ: true} enabled: {EQ: true} deleted: {EQ: false} ${$rootScope.authorizedUsersTab?.showAll !== false ? ' ' : `authorizations: {client: {id: {EQ: ${clientId}}}} `
  //     }} ${$rootScope.authorizedUsersTab?.searchText.length === 0 ?
  //       '' :
  //       `OR: { username: {LIKE: \\"%${$rootScope.authorizedUsersTab?.searchText}%\\"} email: {LIKE: \\"%${$rootScope.authorizedUsersTab?.searchText}%\\"} firstName: {LIKE: \\"%${$rootScope.authorizedUsersTab?.searchText}%\\"} lastName: {LIKE: \\"%${$rootScope.authorizedUsersTab?.searchText}%\\"}} `
  //     }}) `
  //     + `{select {id username firstName lastName email authorizations {client (optional: true) {id}}} total}}"}`;

  //   resourceFactory.gqlResource.query(query, function (response) {
  //     scope.linkedUsersList = response.data.AppUsers.select;
  //     scope.linkedUsersList.unshift({ id: '', firstName: 'Select User', lastName: '' });
  //     scope.userId = scope.linkedUsersList[0].id;
  //   });
  // };

  resourceFactory.clientTemplateResource.get(requestParams, function (data) {
    scope.offices = data.officeOptions;
    scope.staffs = data.staffOptions;
    scope.formData.officeId = scope.offices[0].id;
    scope.savingproducts = data.savingProductOptions;
    scope.genderOptions = data.genderOptions;
    scope.clienttypeOptions = data.clientTypeOptions;
    scope.classifications = data.clientClassificationOptions;
    data.mobileNumberFormat.forEach((value) => {
      value.name === 'mobile-number-regex' ? scope.phoneNumberFormat = value : scope.countryCodeFormat = value;
    });
    scope.phoneNumberFormattedValue = scope.phoneNumberFormat.enabled ? scope.phoneNumberFormat.value.replace(/\\/g, '\\') : '';
    scope.countryCodeFormattedValue = scope.countryCodeFormat.enabled ? scope.countryCodeFormat.value.replace(/\\/g, '\\') : '';

    scope.clientClassificationOptions = data.clientClassificationOptions;
    scope.clientNonPersonConstitutionOptions = data.clientNonPersonConstitutionOptions;
    scope.clientNonPersonMainBusinessLineOptions = data.clientNonPersonMainBusinessLineOptions;
    scope.clientLegalFormOptions = data.clientLegalFormOptions;
    // scope.clientKYCStatusOptions = data.clientKycStatusOptions;
    // scope.formData.clientKYCStatusId = data.clientKycStatusOptions?.[0]?.id ?? null;
    scope.datatables = data.datatables;
    if (!_.isUndefined(scope.datatables) && scope.datatables.length > 0) {
      scope.noOfTabs = scope.datatables.length + 1;
      angular.forEach(scope.datatables, function (datatable, index) {
        scope.updateColumnHeaders(datatable.columnHeaderData);
        angular.forEach(datatable.columnHeaderData, function (colHeader, i) {
          if (_.isEmpty(scope.formDat.datatables[index])) {
            scope.formDat.datatables[index] = { data: {} };
          }

          if (_.isEmpty(scope.formData.datatables[index])) {
            scope.formData.datatables[index] = {
              registeredTableName: datatable.registeredTableName,
              data: { locale: scope.optlang.code }
            };
          }

          if (datatable.columnHeaderData[i].columnDisplayType === 'DATETIME') {
            scope.formDat.datatables[index].data[datatable.columnHeaderData[i].columnName] = {};
          }
        });
      });
    }

    if (data.savingProductOptions.length > 0) {
      scope.showSavingOptions = true;
    }
    if (routeParams.officeId) {
      scope.formData.officeId = routeParams.officeId;
      for (const i in data.officeOptions) {
        if (data.officeOptions[i].id === routeParams.officeId) {
          scope.forceOffice = data.officeOptions[i];
          break;
        }
      }
    }
    if (routeParams.groupId) {
      if (typeof data.staffId !== 'undefined') {
        scope.formData.staffId = data.staffId;
      }
    }

    scope.enableAddress = data.isAddressEnabled;

    if (scope.enableAddress === true) {
      scope.addressTypes = data.address.addressTypeIdOptions;
      scope.countryOptions = data.address.countryIdOptions;
      scope.stateOptions = data.address.stateProvinceIdOptions;

      resourceFactory.addressFieldConfiguration.get({ entity: 'ADDRESS' }, function (response) {
        const { pageItems } = response;

        pageItems.forEach(({ fieldValidations }) => {
          fieldValidations.forEach(fieldValidation => {
            scope[fieldValidation.field] = true;
          });
        });
      });
    }

    scope.relationshipIdOptions = data.familyMemberOptions.relationshipIdOptions;
    scope.genderIdOptions = data.familyMemberOptions.genderIdOptions;
    scope.maritalStatusIdOptions = data.familyMemberOptions.maritalStatusIdOptions;
    scope.professionIdOptions = data.familyMemberOptions.professionIdOptions;
  });

  scope.updateColumnHeaders = function (columnHeaderData) {
    let colName = columnHeaderData[0].columnName;
    if (colName === 'id') {
      columnHeaderData.splice(0, 1);
    }

    colName = columnHeaderData[0].columnName;
    if (colName === 'client_id' || colName === 'office_id' || colName === 'group_id' || colName === 'center_id' || colName === 'loan_id' || colName === 'savings_account_id' || colName === 'credit_account_id') {
      columnHeaderData.splice(0, 1);
    }
  };

  // address

  scope.addAddress = function () {
    scope.addressArray.push({});
  };

  scope.removeAddress = function (index) {
    scope.addressArray.splice(index, 1);
  };

  // end of address

  // family members

  scope.addFamilyMember = function () {
    scope.familyArray.push({});
  };

  scope.removeFamilyMember = function (index) {
    scope.familyArray.splice(index, 1);
  };

  // end of family members

  scope.displayPersonOrNonPersonOptions = function (legalFormId) {
    scope.showNonPersonOptions = legalFormId !== scope.clientPersonId && legalFormId !== null;

    const selectedLegalForm = scope.clientLegalFormOptions.find(legalForm => legalForm.id === legalFormId);
    if (!selectedLegalForm) {
      scope.formData.clientClassificationId = null;
    }

    scope.clientClassificationOptions = scope.classifications.filter(data =>
      selectedLegalForm?.code === 'legalFormType.person'
        ? data.codeName === 'ClientClassification-PERSON'
        : data.codeName === 'ClientClassification-ENTITY'
    );
  };

  scope.changeOffice = function (officeId) {
    resourceFactory.clientTemplateResource.get({
      staffInSelectedOfficeOnly: true, officeId
    }, function (data) {
      scope.staffs = data.staffOptions;
      scope.savingproducts = data.savingProductOptions;
    });
  };

  scope.setChoice = function () {
    if (this.formData.active) {
      scope.choice = 1;
    } else if (!this.formData.active) {
      scope.choice = 0;
    }
  };

  scope.setIncorpValidityTillDate = function () {
    if (this.incorporateValidityDateActive) {
      scope.incorporateValidityTill = 1;
    } else if (!this.incorporateValidityDateActive) {
      scope.incorporateValidityTill = 0;
    }
  };

  if (routeParams.groupId) {
    scope.cancel = `#/viewgroup/${routeParams.groupId}`;
    scope.groupid = routeParams.groupId;
  } else {
    scope.cancel = '#/clients';
  }

  // return input type
  // return input type
  scope.fieldType = function (type) {
    let fieldType = '';
    if (type) {
      if (type === 'CODELOOKUP' || type === 'CODEVALUE') {
        fieldType = 'SELECT';
      } else if (type === 'DATE') {
        fieldType = 'DATE';
      } else if (type === 'DATETIME') {
        fieldType = 'DATETIME';
      } else if (type === 'BOOLEAN') {
        fieldType = 'BOOLEAN';
      } else {
        fieldType = 'TEXT';
      }
    }
    return fieldType;
  };

  scope.dateTimeFormat = function (colHeaders) {
    angular.forEach(colHeaders, function (colHeader, i) {
      if (colHeaders[i].columnDisplayType === 'DATETIME') {
        return `${scope.df} ${scope.tf}`;
      }
    });
    return scope.df;
  };

  scope.$watch('formData.mobileCountryCode', () => {
    scope.isMissingCountryCode = false;
  });
  scope.$watch('formData.mobileNo', () => {
    scope.isMissingMobileNumber = false;
  });

  scope.submit = function () {
    let reqDate = dateFilter(scope.first.date, scope.df);

    this.formData.locale = scope.optlang.code;
    this.formData.active = this.formData.active || false;
    this.formData.dateFormat = scope.df;
    this.formData.activationDate = reqDate;

    if (scope.formData.mobileNo && !scope.formData.mobileCountryCode) {
      scope.isMissingCountryCode = true;
    }

    if (scope.formData.mobileCountryCode && !scope.formData.mobileNo) {
      scope.isMissingMobileNumber = true;
    }

    if (!_.isUndefined(scope.datatables) && scope.datatables.length > 0) {
      angular.forEach(scope.datatables, function (datatable, index) {
        scope.columnHeaders = datatable.columnHeaderData;
        angular.forEach(scope.columnHeaders, function (colHeader, i) {
          scope.dateFormat = `${scope.df} ${scope.tf}`;
          if (scope.columnHeaders[i].columnDisplayType === 'DATE') {
            if (!_.isUndefined(scope.formDat.datatables[index].data[scope.columnHeaders[i].columnName])) {
              scope.formData.datatables[index].data[scope.columnHeaders[i].columnName] = dateFilter(scope.formDat.datatables[index].data[scope.columnHeaders[i].columnName],
                scope.dateFormat);
              scope.formData.datatables[index].data.dateFormat = scope.dateFormat;
            }
          } else if (scope.columnHeaders[i].columnDisplayType === 'DATETIME') {
            if (!_.isUndefined(scope.formDat.datatables[index].data[scope.columnHeaders[i].columnName].date) && !_.isUndefined(scope.formDat.datatables[index].data[scope.columnHeaders[i].columnName].time)) {
              scope.formData.datatables[index].data[scope.columnHeaders[i].columnName] = `${dateFilter(scope.formDat.datatables[index].data[scope.columnHeaders[i].columnName].date, scope.df)
              } ${dateFilter(scope.formDat.datatables[index].data[scope.columnHeaders[i].columnName].time, scope.tf)}`;
              scope.formData.datatables[index].data.dateFormat = scope.dateFormat;
            }
          }
        });
      });
    } else {
      delete scope.formData.datatables;
    }

    if (routeParams.groupId) {
      this.formData.groupId = routeParams.groupId;
    }

    if (routeParams.officeId) {
      this.formData.officeId = routeParams.officeId;
    }

    if (scope.first.submitondate) {
      reqDate = dateFilter(scope.first.submitondate, scope.df);
      this.formData.submittedOnDate = reqDate;
    }

    if (scope.first.dateOfBirth) {
      this.formData.dateOfBirth = dateFilter(scope.first.dateOfBirth, scope.df);
    }

    if (this.formData.legalFormId === scope.clientPersonId || this.formData.legalFormId === null) {
      delete this.formData.fullname;
    } else {
      delete this.formData.firstname;
      delete this.formData.middlename;
      delete this.formData.lastname;
      delete this.formData.emailAddress;
      // delete this.formData.clientKYCStatusId;
    }

    if (scope.first.incorpValidityTillDate) {
      this.formData.clientNonPersonDetails.locale = scope.optlang.code;
      this.formData.clientNonPersonDetails.dateFormat = scope.df;
      this.formData.clientNonPersonDetails.incorpValidityTillDate = dateFilter(scope.first.incorpValidityTillDate, scope.df);
    }

    if (!scope.savings.opensavingsproduct) {
      this.formData.savingsProductId = null;
    }

    if (scope.enableAddress === true) {
      scope.formData.address = [];
      for (let i = 0; i < scope.addressArray.length; i++) {
        const temp = {};
        if (scope.addressArray[i].addressTypeId) {
          temp.addressTypeId = scope.addressArray[i].addressTypeId;
        }
        if (scope.addressArray[i].addressLine1) {
          temp.addressLine1 = scope.addressArray[i].addressLine1;
        }
        if (scope.addressArray[i].addressLine2) {
          temp.addressLine2 = scope.addressArray[i].addressLine2;
        }
        if (scope.addressArray[i].addressLine3) {
          temp.addressLine3 = scope.addressArray[i].addressLine3;
        }
        if (scope.addressArray[i].townVillage) {
          temp.townVlage = scope.addressArray[i].townVillage;
        }
        if (scope.addressArray[i].city) {
          temp.city = scope.addressArray[i].city;
        }
        if (scope.addressArray[i].countyDistrict) {
          temp.countyDistrict = scope.addressArray[i].countyDistrict;
        }
        if (scope.addressArray[i].countryId) {
          temp.countryId = scope.addressArray[i].countryId;
        }
        if (scope.addressArray[i].stateProvinceId) {
          temp.stateProvinceId = scope.addressArray[i].stateProvinceId;
        }
        if (scope.addressArray[i].postalCode) {
          temp.postalCode = scope.addressArray[i].postalCode;
        }
        if (scope.addressArray[i].latitude) {
          temp.latitude = scope.addressArray[i].latitude;
        }
        if (scope.addressArray[i].longitude) {
          temp.longitude = scope.addressArray[i].longitude;
        }
        if (scope.addressArray[i].isActive) {
          temp.isActive = scope.addressArray[i].isActive;
        }
        scope.formData.address.push(temp);
      }
    }

    // family array
    scope.formData.familyMembers = scope.familyArray.map(member => {
      const temp = {};
      if (member.relationshipId) {
        temp.relationshipId = member.relationshipId;
      }
      if (member.firstName) {
        temp.firstName = member.firstName;
      }
      if (member.middleName) {
        temp.middleName = member.middleName;
      }
      if (member.lastName) {
        temp.lastName = member.lastName;
      }
      if (member.qualification) {
        temp.qualification = member.qualification;
      }
      if (member.mobileNumber) {
        temp.mobileNumber = member.mobileNumber;
      }
      if (member.age) {
        temp.age = member.age;
      }
      if (member.isDependent) {
        temp.isDependent = member.isDependent;
      }
      if (member.genderId) {
        temp.genderId = member.genderId;
      }
      if (member.professionId) {
        temp.professionId = member.professionId;
      }
      if (member.maritalStatusId) {
        temp.maritalStatusId = member.maritalStatusId;
      }
      if (member.dateOfBirth) {
        temp.dateOfBirth = dateFilter(member.dateOfBirth, scope.df);
      }
      temp.locale = scope.optlang.code;
      temp.dateFormat = scope.df;
      return temp;
    });

    if (scope.showNonPersonOptions) {
      delete scope.formData.currentMLAStatus;
      delete scope.formData.isOptedForMLAStatus;
    }

    resourceFactory.clientResource.save(this.formData, function (data) {
      location.path(`/viewclient/${data.clientId}`);
    });
  };
}

CreateClientController.$inject = ['$scope', 'ResourceFactory', '$location', 'dateFilter', '$routeParams', '$rootScope'];
