export function CreateAdHocQueryController ($scope, location, resourceFactory) {
  $scope.formData = {};
  $scope.template = {};
  $scope.customReportRunFrequencyFieldShow = false;

  resourceFactory.adHocQueryTemplateResource.get(function (data) {
    $scope.template = data;
  });

  $scope.reportRunFrequencySelected = function (id) {
    $scope.customReportRunFrequencyFieldShow = id === 5;
  };

  $scope.submit = function () {
    resourceFactory.adHocQueryResource.save(this.formData, function (data) {
      location.path(`/viewadhocquery/${data.resourceId}`);
    });
  };
}

CreateAdHocQueryController.$inject = ['$scope', '$location', 'ResourceFactory'];
