export function CreateEmployeeController (scope, resourceFactory, location, dateFilter) {
  scope.offices = [];
  scope.restrictDate = new Date();
  resourceFactory.officeResource.getAllOfficesInAlphabeticalOrder(function (data) {
    scope.offices = data;
    scope.formData = {
      isLoanOfficer: true,
      officeId: scope.offices[0].id
    };
  });

  scope.minDat = function () {
    for (let i = 0; i < scope.offices.length; i++) {
      if ((scope.offices[i].id) === (scope.formData.officeId)) {
        return scope.offices[i].openingDate;
      }
    }
  };

  scope.submit = function () {
    this.formData.locale = scope.optlang.code;
    const joiningDate = dateFilter(scope.formData.joiningDate, scope.df);
    this.formData.dateFormat = scope.df;
    this.formData.joiningDate = joiningDate;
    resourceFactory.employeeResource.save(this.formData, function (data) {
      location.path(`/viewemployee/${data.resourceId}`);
    });
  };
}

CreateEmployeeController.$inject = ['$scope', 'ResourceFactory', '$location', 'dateFilter'];
