import _ from 'underscore';

export function InterestRateChartController ($scope, routeParams, resourceFactory, location, $uibModal) {
  $scope.edit = function (id) {
    location.path(`/editinterestratechart/${id}`);
  };
  $scope.productName = routeParams.productName;
  $scope.productId = routeParams.productId;
  $scope.productsLink = '';
  $scope.viewProductLink = '';
  $scope.productType = routeParams.productType;
  if (routeParams.productType === 'fixeddepositproduct') {
    $scope.productsLink = 'fixeddepositproducts';
    $scope.viewProductLink = 'viewfixeddepositproduct';
  } else if (routeParams.productType === 'recurringdepositproduct') {
    $scope.productsLink = 'recurringdepositproducts';
    $scope.viewProductLink = 'viewrecurringdepositproduct';
  }

  resourceFactory.interestRateChartResource.getAllInterestRateCharts({ productId: routeParams.productId }, function (data) {
    $scope.charts = data;
    _.each($scope.charts, function (chart) {
      $scope.chartSlabs = chart.chartSlabs;
    });
  });

  $scope.incentives = function (index, parent) {
    $uibModal.open({
      templateUrl: 'incentive.html',
      controller: IncentiveCtrl,
      resolve: {
        chartSlab () {
          return $scope.chartSlabs[index];
        }
      }
    });
  };

  const IncentiveCtrl = function ($scope, $uibModalInstance, chartSlab) {
    $scope.chartSlab = chartSlab;
    _.each($scope.chartSlab.incentives, function (incentive) {
      if (!incentive.attributeValueDesc) {
        incentive.attributeValueDesc = incentive.attributeValue;
      }
    });
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

InterestRateChartController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location', '$uibModal'];
