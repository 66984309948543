import DisputeManagement from '@/pages/dispute-management/index.vue';
import ViewDispute from '@/pages/dispute-management/view.vue';

export const disputeManagementRoutes = [
  {
    path: '/dispute-management',
    name: 'DisputeManagement',
    component: DisputeManagement
  },
  {
    path: '/dispute-management/:disputeId',
    name: 'ViewDispute',
    component: ViewDispute
  }
];
