export function CreateOTPProtectedOperationController (scope, resourceFactory, location) {
  scope.formData = {};

  scope.submit = function () {
    resourceFactory.otpProtectedOperationResource.save(scope.formData, function () {
      location.path(`/otpprotectedoperation`);
    });
  };
}

CreateOTPProtectedOperationController.$inject = ['$scope', 'ResourceFactory', '$location'];
