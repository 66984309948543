<template>
  <container-login>
    <form-wrapper @submit="resetPassword">
      <input-text
        id="password"
        v-model="formData.password"
        :label="$t('label.input.password')"
        type="password"
        required
        vertical
      />
      <input-text
        id="reset-password"
        v-model="formData.repeatPassword"
        :label="$t('label.input.repeatPassword')"
        type="password"
        required
        vertical
      />
      <button
        type="submit"
        class="btn btn-primary w-100 d-flex justify-content-center"
      >
        {{ $t('label.button.requestResetPassword') }}
      </button>
    </form-wrapper>
  </container-login>
</template>

<script setup>
import { FormWrapper, InputText } from '@/components/shared/form-input/index';
import { $notify } from '@/plugins/prototypes';
import { reactive } from 'vue';
import ContainerLogin from '@/components/login/Container.vue';
import apiService from '@/services/apiService';
import router from '@/router';
import i18n from '@/plugins/i18n';

const currentRoute = router.currentRoute;
const formData = reactive({
  password: '',
  repeatPassword: '',
  token: currentRoute.params.token
});

const resetPassword = async () => {
  const { repeatPassword, ...payload } = formData;
  if (repeatPassword !== payload.password) return $notify.error('Password is not matched');
  try {
    await apiService.authentication.resetPassword(payload);
    router.push({ name: 'Login' });
    $notify.success(i18n.t('messages.success.passwordHasBeenReset'));
  } catch (error) {
    $notify.error(error);
  }
};
</script>
