import _ from 'underscore';
import router from '@/router/index';

export function CreateRevolvingLoanProductController (scope, $rootScope, resourceFactory, location, dateFilter, WizardHandler) {
  scope.restrictDate = new Date();
  scope.formData = {};
  scope.loanproduct = {};
  scope.charges = [];
  scope.accountingOptions = ['None', 'Accrual(Periodic)'];
  scope.showOrHideValue = 'show';
  scope.configureFundOptions = [];
  scope.specificIncomeAccountMapping = [];
  scope.penaltySpecificIncomeaccounts = [];
  scope.configureFundOption = {};
  scope.date = {};
  scope.chargeFlag = false;
  scope.penaltyFlag = false;
  scope.isGenerateRepaymentSchedule = true;

  scope.amortization = true;
  scope.arrearsTolerance = true;
  scope.graceOnArrearsAging = true;
  scope.interestCalcPeriod = true;
  scope.interestMethod = true;
  scope.graceOnPrincipalAndInterest = true;
  scope.repaymentFrequency = true;
  scope.transactionProcessingStrategy = true;
  scope.allowAttributeConfiguration = true;
  scope.interestRecalculationOnDayTypeOptions = [];

  resourceFactory.loanProductResource.get({ resourceType: 'template', loanType: 'revolving' }, function (data) {
    scope.product = data;
    scope.assetAccountOptions = scope.product.accountingMappingOptions.assetAccountOptions || [];
    scope.incomeAccountOptions = scope.product.accountingMappingOptions.incomeAccountOptions || [];
    scope.expenseAccountOptions = scope.product.accountingMappingOptions.expenseAccountOptions || [];
    scope.liabilityAccountOptions = scope.product.accountingMappingOptions.liabilityAccountOptions || [];
    scope.incomeAndLiabilityAccountOptions = scope.incomeAccountOptions.concat(scope.liabilityAccountOptions);
    scope.penaltyOptions = scope.product.penaltyOptions || [];
    scope.chargeOptions = scope.product.chargeOptions || [];
    scope.overduecharges = [];
    for (const i in scope.penaltyOptions) {
      if (scope.penaltyOptions[i].chargeTimeType.code === 'chargeTimeType.overdueInstallment') {
        scope.overduecharges.push(scope.penaltyOptions[i]);
      }
    }
    scope.formData.currencyCode = scope.product.currencyOptions[0].code;

    scope.formData.digitsAfterDecimal = '2';
    scope.formData.inMultiplesOf = '0';
    scope.formData.repaymentFrequencyType = scope.product.repaymentFrequencyType.id;
    scope.formData.interestRateFrequencyType = scope.product.interestRateFrequencyType.id;
    scope.formData.amortizationType = scope.product.amortizationType.id;
    scope.formData.interestType = scope.product.interestType.id;
    scope.formData.interestCalculationPeriodType = scope.product.interestCalculationPeriodTypeOptions[0].id;
    scope.formData.transactionProcessingStrategyId = scope.product.transactionProcessingStrategyOptions[0].id;

    scope.formData.accountingRule = '1';
    scope.formData.daysInYearType = scope.product.daysInYearType.id;
    scope.formData.daysInMonthType = scope.product.daysInMonthType.id;

    scope.formData.preClosureInterestCalculationStrategy = scope.product.interestRecalculationData.preClosureInterestCalculationStrategy.id;
    if (scope.product.interestRecalculationData.recalculationRestFrequencyType) {
      scope.formData.recalculationRestFrequencyType = scope.product.interestRecalculationData.recalculationRestFrequencyType.id;
    }
    scope.loanproduct = angular.copy(scope.formData);
    scope.isClicked = false;
  });

  scope.$watch('formData', function (newVal) {
    scope.loanproduct = angular.extend(scope.loanproduct, newVal);
  }, true);

  $rootScope.formValue = function (array, model, findattr, retAttr) {
    findattr = findattr || 'id';
    retAttr = retAttr || 'value';
    return _.find(array, function (obj) {
      return obj[findattr] === model;
    })[retAttr];
  };

  scope.goNext = function (form) {
    WizardHandler.wizard().checkValid(form);
    scope.isClicked = true;
  };

  scope.chargeSelected = function (chargeId) {
    if (chargeId) {
      resourceFactory.chargeResource.get({ chargeId, template: 'true' }, this.formData, function (data) {
        data.chargeId = data.id;
        scope.charges.push(data);
        // to charge select box empty

        if (data.penalty) {
          scope.penaltyFlag = true;
          scope.penaltyId = '';
        } else {
          scope.chargeFlag = true;
          scope.chargeId = '';
        }
      });
    }
  };

  scope.deleteCharge = function (index) {
    scope.charges.splice(index, 1);
  };

  // advanced accounting rule
  scope.toggleAdvancedRules = function (showOrHideValue) {
    if (showOrHideValue === 'show') {
      scope.showOrHideValue = 'hide';
    }

    if (showOrHideValue === 'hide') {
      scope.showOrHideValue = 'show';
    }
  };

  scope.addConfigureFundSource = function () {
    scope.frFlag = true;
    scope.configureFundOptions.push({
      paymentTypeId: scope.product.paymentTypeOptions.length > 0 ? scope.product.paymentTypeOptions[0].id : '',
      fundSourceAccountId: scope.assetAccountOptions.length > 0 ? scope.assetAccountOptions[0].id : '',
      paymentTypeOptions: scope.product.paymentTypeOptions.length > 0 ? scope.product.paymentTypeOptions : [],
      assetAccountOptions: scope.assetAccountOptions.length > 0 ? scope.assetAccountOptions : []
    });
  };

  scope.mapFees = function () {
    scope.showFeeMapping = true;
    scope.specificIncomeAccountMapping.push({
      chargeId: scope.chargeOptions.length > 0 ? scope.chargeOptions[0].id : '',
      incomeAccountId: scope.incomeAndLiabilityAccountOptions.length > 0 ? scope.incomeAndLiabilityAccountOptions[0].id : ''
    });
  };

  scope.mapPenalty = function () {
    scope.piFlag = true;
    scope.penaltySpecificIncomeaccounts.push({
      chargeId: scope.penaltyOptions.length > 0 ? scope.penaltyOptions[0].id : '',
      incomeAccountId: scope.incomeAccountOptions.length > 0 ? scope.incomeAccountOptions[0].id : ''
    });
  };

  scope.deleteFund = function (index) {
    scope.configureFundOptions.splice(index, 1);
  };

  scope.deleteFee = function (index) {
    scope.specificIncomeAccountMapping.splice(index, 1);
  };

  scope.deletePenalty = function (index) {
    scope.penaltySpecificIncomeaccounts.splice(index, 1);
  };

  scope.deletePrincipalVariation = function (index) {
    scope.formData.principalVariationsForBorrowerCycle.splice(index, 1);
  };

  scope.deleteInterestRateVariation = function (index) {
    scope.formData.interestRateVariationsForBorrowerCycle.splice(index, 1);
  };

  scope.deleterepaymentVariation = function (index) {
    scope.formData.numberOfRepaymentVariationsForBorrowerCycle.splice(index, 1);
  };

  scope.cancel = function () {
    router.push({ name: 'LoanProducts' });
  };

  scope.isAccrualAccountingEnabled = function () {
    if (scope.formData.accountingRule === 3) {
      return true;
    }
    return false;
  };
  scope.setAttributeValues = function () {
    if (scope.allowAttributeConfiguration === false) {
      scope.amortization = false;
      scope.arrearsTolerance = false;
      scope.graceOnArrearsAging = false;
      scope.interestCalcPeriod = false;
      scope.interestMethod = false;
      scope.graceOnPrincipalAndInterest = false;
      scope.repaymentFrequency = false;
      scope.transactionProcessingStrategy = false;
      scope.isGenerateRepaymentSchedule = false;
    }
  };

  scope.filterCharges = function (currencyCode, multiDisburseLoan) {
    return function (item) {
      if ((multiDisburseLoan !== true) && item.chargeTimeType.id === 12) {
        return false;
      }
      if (item.currency.code !== currencyCode) {
        return false;
      }
      return true;
    };
  };

  scope.submit = function () {
    const reqFirstDate = dateFilter(scope.date.first, scope.df);
    const reqSecondDate = dateFilter(scope.date.second, scope.df);
    scope.paymentChannelToFundSourceMappings = [];
    scope.feeToIncomeAccountMappings = [];
    scope.penaltyToIncomeAccountMappings = [];
    scope.chargesSelected = [];
    scope.selectedConfigurableAttributes = [];

    let temp = '';

    // configure fund sources for payment channels
    for (const i in scope.configureFundOptions) {
      temp = {
        paymentTypeId: scope.configureFundOptions[i].paymentTypeId,
        fundSourceAccountId: scope.configureFundOptions[i].fundSourceAccountId
      };
      scope.paymentChannelToFundSourceMappings.push(temp);
    }

    // map fees to specific income accounts
    for (const i in scope.specificIncomeAccountMapping) {
      temp = {
        chargeId: scope.specificIncomeAccountMapping[i].chargeId,
        incomeAccountId: scope.specificIncomeAccountMapping[i].incomeAccountId
      };
      scope.feeToIncomeAccountMappings.push(temp);
    }

    // map penalties to specific income accounts
    for (const i in scope.penaltySpecificIncomeaccounts) {
      temp = {
        chargeId: scope.penaltySpecificIncomeaccounts[i].chargeId,
        incomeAccountId: scope.penaltySpecificIncomeaccounts[i].incomeAccountId
      };
      scope.penaltyToIncomeAccountMappings.push(temp);
    }

    for (const i in scope.charges) {
      temp = {
        id: scope.charges[i].id
      };
      scope.chargesSelected.push(temp);
    }

    if (scope.allowAttributeConfiguration === false) {
      scope.amortization = false;
      scope.arrearsTolerance = false;
      scope.graceOnArrearsAging = false;
      scope.interestCalcPeriod = false;
      scope.interestMethod = false;
      scope.graceOnPrincipalAndInterest = false;
      scope.repaymentFrequency = false;
      scope.transactionProcessingStrategy = false;
      scope.isGenerateRepaymentSchedule = false;
    }

    scope.selectedConfigurableAttributes = {
      amortizationType: scope.amortization,
      interestType: scope.interestMethod,
      transactionProcessingStrategyId: scope.transactionProcessingStrategy,
      interestCalculationPeriodType: scope.interestCalcPeriod,
      inArrearsTolerance: scope.arrearsTolerance,
      repaymentEvery: scope.repaymentFrequency,
      graceOnPrincipalAndInterestPayment: scope.graceOnPrincipalAndInterest,
      graceOnArrearsAgeing: scope.graceOnArrearsAging
    };

    this.formData.paymentChannelToFundSourceMappings = scope.paymentChannelToFundSourceMappings;
    this.formData.feeToIncomeAccountMappings = scope.feeToIncomeAccountMappings;
    this.formData.penaltyToIncomeAccountMappings = scope.penaltyToIncomeAccountMappings;
    this.formData.charges = scope.chargesSelected;
    this.formData.allowAttributeOverrides = scope.selectedConfigurableAttributes;
    this.formData.locale = scope.optlang.code;
    this.formData.dateFormat = scope.df;
    this.formData.startDate = reqFirstDate;
    this.formData.closeDate = reqSecondDate;
    this.formData.isRevolvingLoanProduct = true;
    // Interest recalculation data
    if (this.formData.recalculationCompoundingFrequencyType === 4) {
      if (this.formData.recalculationCompoundingFrequencyNthDayType === -2) {
        delete this.formData.recalculationCompoundingFrequencyNthDayType;
        delete this.formData.recalculationCompoundingFrequencyDayOfWeekType;
      } else {
        delete this.formData.recalculationCompoundingFrequencyOnDayType;
      }
    } else if (this.formData.recalculationCompoundingFrequencyType === 3) {
      delete this.formData.recalculationCompoundingFrequencyOnDayType;
      delete this.formData.recalculationCompoundingFrequencyNthDayType;
    }

    if (this.formData.recalculationRestFrequencyType === 4) {
      if (this.formData.recalculationRestFrequencyNthDayType === -2) {
        delete this.formData.recalculationRestFrequencyNthDayType;
        delete this.formData.recalculationRestFrequencyDayOfWeekType;
      } else {
        delete this.formData.recalculationRestFrequencyOnDayType;
      }
    } else if (this.formData.recalculationRestFrequencyType === 3) {
      delete this.formData.recalculationRestFrequencyOnDayType;
      delete this.formData.recalculationRestFrequencyNthDayType;
    }
    resourceFactory.loanProductResource.save(this.formData, function (data) {
      location.path(`/viewrevolvingloanproduct/${data.resourceId}`);
    });
  };
}

CreateRevolvingLoanProductController.$inject = ['$scope', '$rootScope', 'ResourceFactory', '$location', 'dateFilter', 'WizardHandler'];
