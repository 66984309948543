<template>
  <div
    class="form-group pb-3 align-items-center"
    :class="{ row: !vertical }"
  >
    <label
      v-if="label"
      class="control-label"
      :class="vertical ? 'mb-1' : 'text-end col-sm-4'"
      :for="id"
    >
      {{ label }}
      <span
        v-if="required"
        class="required"
      >*</span>
    </label>

    <div class="col">
      <date-picker
        :type="type"
        :value-type="valueType"
        :value="value"
        :input-class="['form-control', {'is-invalid': errorMessage}]"
        :input-attr="{ id }"
        :format="format"
        :editable="editable"
        :clearable="clearable"
        :disabled-date="disabledDate"
        :disabled="disabled"
        :placeholder="placeholder"
        :range="range"
        @input="updateInput"
      />
    </div>
    <div
      v-if="errorMessage"
      class="offset-sm-4 text-danger"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import formWrapperRegister from '@/components/shared/form-input/mixin.js';

export default {
  components: { DatePicker },
  mixins: [formWrapperRegister],
  props: {
    value: {
      type: [String, Array],
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    valueType: {
      type: String,
      default: 'format'
    },
    format: {
      type: String,
      default: 'DD MMMM YYYY'
    },
    type: {
      type: String,
      default: 'date'
    },
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    range: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    vertical: {
      type: Boolean,
      defualt: false
    }
  },
  methods: {
    updateInput (value) {
      this.validatorRunner();
      this.$emit('input', value);
    },
    disabledDate (date) {
      if (this.maxDate && this.minDate) return date < this.minDate || date > this.maxDate;
      if (this.minDate) return date < this.minDate;
      if (this.maxDate) return date > this.maxDate;
    }
  }
};
</script>
