<template>
  <div class="toast-container position-fixed top-0 end-0 p-3">
    <div
      v-for="(notify, index) in notifyMessage.message"
      :id="`liveToast-${index}`"
      :key="index"
      class="toast align-items-center border-0"
      :class="notifyMessage.type === 'success' ? 'toast-success' : 'toast-error'"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="d-flex">
        <div class="toast-body">
          {{ notify }}
        </div>
        <button
          type="button"
          class="btn-close me-3 m-auto"
          @click="removeMessage(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useSharedStore } from '@/stores/shared';
export default {
  name: 'NotificationToast',
  computed: {
    ...mapState(useSharedStore, ['notifyMessage'])
  },
  watch: {
    async 'notifyMessage.message' (notifyMessage) {
      await this.$nextTick();
      notifyMessage.forEach((_, index) => {
        const toastElement = document.getElementById(`liveToast-${index}`);
        const toast = new bootstrap.Toast(toastElement, { delay: 8000 });
        toast.show();
        toastElement.addEventListener('hidden.bs.toast', () => {
          this.resetNotificationMessage();
        });
      });
    }
  },
  methods: {
    ...mapActions(useSharedStore, ['setNotifyMessage', 'resetNotificationMessage', 'removeMessage'])
  }
};
</script>

<style scoped>
.toast-container >>> .toast-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.toast-container >>> .toast-error {
  background-color: #fbeded;
  color: #842029;
}
</style>
