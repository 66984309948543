import TemplatePage from '@/pages/template/index.vue';
import CreateTemplate from '@/pages/template/create.vue';
import ViewTemplate from '@/pages/template/view.vue';
import EditTemplate from '@/pages/template/edit.vue';

export const templateRoutes = [
  {
    path: '/templates',
    name: 'ListTemplates',
    component: TemplatePage
  },
  {
    path: '/templates/create',
    name: 'CreateTemplate',
    component: CreateTemplate
  },
  {
    path: '/templates/:id',
    name: 'ViewTemplate',
    component: ViewTemplate
  },
  {
    path: '/templates/:id/edit',
    name: 'EditTemplate',
    component: EditTemplate
  }
];
