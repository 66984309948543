export function ViewAMLRuleGroupListController (scope, resourceFactory, location) {
  scope.ruleGroups = [];
  scope.hasNameMatchingRule = true;
  scope.hasWatchmanRule = true;

  scope.ncRulesEnabled = !process.env.VUE_APP_AML_GROUP_NC_RULES;

  scope.routeTo = function (ruleGroup) {
    if (ruleGroup.type === 'AML_RULE') {
      location.path(`/payments/aml/rulegroups/${ruleGroup.id}`);
      return;
    }
    location.path(`/payments/aml/viewrulegroup/${ruleGroup.id}`);
  };

  scope.ruleGroups = [];
  scope.getRuleGroupList = function () {
    resourceFactory.newAmlGroupsResource.get(function (data) {
      scope.ruleGroups = data;
    });
  };

  scope.getRuleGroupList();
}

ViewAMLRuleGroupListController.$inject = ['$scope', 'ResourceFactory', '$location'];
