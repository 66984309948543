<template>
  <span>
    <a
      v-if="isImage(fileType) || isPdf(fileType)"
      v-has-permission="'READ_MEDIA'"
      @click="viewFile"
    >
      <i class="fas fa-eye" />
    </a>
    <base-modal
      ref="viewDocumentModal"
      modal-size="xl"
      show-button-close
      hide-footer
    >
      <template #header>
        {{ $t('label.heading.viewFile') }}
      </template>
      <template #body>
        <div
          v-if="loading"
          class="text-center"
        >
          {{ $t('label.head.loading') }}
        </div>
        <div v-if="isImage(fileType)">
          <img
            :src="src"
            alt="file-image"
            class="img-fluid"
          >
        </div>
        <iframe
          v-if="isPdf(fileType)"
          frameborder="0"
          width="100%"
          height="800px"
          :src="src"
        />
      </template>
    </base-modal>
  </span>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import { ref, computed } from 'vue';
import { viewHelper, isImage, isPdf } from '@/helper/downloadHelper';
import apiService from '@/services/apiService';
import { useSharedStore } from '@/stores/shared';

const sharedStore = useSharedStore();
const loading = computed(() => sharedStore.loading);

const props = defineProps({
  doc: {
    type: Object,
    default: () => ({})
  }
});

const viewDocumentModal = ref(null);
const src = ref(null);

const fileType = computed(() => props.doc.mimeType);

const viewFile = async () => {
  viewDocumentModal.value.show();
  if (src.value) return;
  const { data } = await apiService.clientResource.getIdentifierDocument(props.doc.parentEntityId, props.doc.id);
  src.value = await viewHelper(data);
};

</script>
