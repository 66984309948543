// (function(module) {
//   mbanq.directives = _.extend(module, {
//     DecimalDirective($compile) {
//       const linker = function(scope, element, attrs, ngModel) {

//         // Runs when the model is first rendered and when the model is changed from code
//         ngModel.$render = function() {
//           if (ngModel.$modelValue !== null && ngModel.$modelValue >= 0) {
//             element.val(ngModel.$modelValue.toString())
//           }
//         }

//         // Runs when the view value changes - after each keypress
//         ngModel.$parsers.unshift(function(newValue) {
//           const floatValue = parseFloat(newValue)
//           if (isNaN(parseFloat(newValue)))
//             element.val(null)
//           else return floatValue
//         })

//         // Formats the displayed value when the input field loses focus
//         element.on('change', function(e) {
//           const floatValue = parseFloat(element.val())
//           if (isNaN(floatValue)) {
//             element.val(null)
//           } else {
//             element.val(floatValue)
//           }

//         })
//       }
//       return {
//         restrict: 'A',
//         require: 'ngModel',
//         link: linker
//       }
//     }
//   })
// }(mbanq.directives || {}))

// mbanq.ng.application.directive('decimals', ['$compile', mbanq.directives.DecimalDirective]).run(function($log) {
//   $log.info('DecimalDirective initialized')
// })

export function DecimalDirective () {
  const linker = function (scope, element, attrs, ngModel) {
    // Runs when the model is first rendered and when the model is changed from code
    ngModel.$render = function () {
      if (ngModel.$modelValue !== null && ngModel.$modelValue >= 0) {
        element.val(ngModel.$modelValue.toString());
      }
    };

    // Runs when the view value changes - after each keypress
    ngModel.$parsers.unshift(function (newValue) {
      const floatValue = parseFloat(newValue);
      if (isNaN(parseFloat(newValue))) { element.val(null); } else return floatValue;
    });

    // Formats the displayed value when the input field loses focus
    element.on('change', function () {
      const floatValue = parseFloat(element.val());
      if (isNaN(floatValue)) {
        element.val(null);
      } else {
        element.val(floatValue);
      }
    });
  };
  return {
    restrict: 'A',
    require: 'ngModel',
    link: linker
  };
}
