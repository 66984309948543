export const mlaStatus = [
  { text: 'Y: Yes, on active duty as of the search date or is a dependent of a servicemember on active duty as of the search date.' },
  { text: 'N: No, not on active duty as of the search date and is not a dependent of a Servicemember on Active Duty as of the search date.' },
  { text: 'Z: No active duty uniformed service affiliation or an issue with the data input (refer to the Match Result Code section for further information).' }
];

export const disputeClosureTypes = [
  { name: 'Accepted', value: 'ACCEPTED' },
  { name: 'Denied', value: 'DENIED' }
];
