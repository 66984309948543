export function ViewFinancialActivityController (scope, resourceFactory, routeParams, location, $uibModal) {
  resourceFactory.officeToGLAccountMappingResource.get({ mappingId: routeParams.mappingId }, function (data) {
    scope.mapping = data;
  });

  scope.deletemapping = function () {
    $uibModal.open({
      templateUrl: 'deletemapping.html',
      controller: AccRuleDeleteCtrl
    });
  };
  const AccRuleDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.officeToGLAccountMappingResource.delete({ mappingId: routeParams.mappingId }, {}, function () {
        $uibModalInstance.close('delete');
        location.path('/financialactivityaccountmappings');
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewFinancialActivityController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location', '$uibModal'];
