export function ViewCardAuthorizationController ($scope, resourceFactory, routeParams) {
  $scope.cardAuthorization = {};
  $scope.token = routeParams.cardToken;
  $scope.getYesOrNo = function (value) {
    if (value === 'Y') {
      return 'Yes';
    } else if (value === 'N') {
      return 'No';
    }
  };
  $scope.getValidationResultValue = function (value) {
    if (value === 'Y') {
      return 'Validated';
    } else if (value === 'N') {
      return 'Not Present';
    } else if (value === 'F') {
      return 'Failed';
    }
  };

  $scope.getPin = function (value) {
    if (value === 'Y') {
      return 'Verified';
    } else if (value === 'N') {
      return 'No PIN passed';
    } else if (value === 'F') {
      return 'Failed';
    } else if (value === 'L') {
      return 'Locked';
    } else if (value === 'M') {
      return 'PIN not set';
    } else if (value === 'B') {
      return 'Blocked';
    }
  };

  $scope.getAvsResult = function (value) {
    if (value === 'Y') {
      return 'Address and Zip valid';
    } else if (value === 'A') {
      return 'Address only';
    } else if (value === 'Z') {
      return 'Zip only';
    } else if (value === 'N') {
      return 'None';
    } else if (value === 'M') {
      return 'Gift';
    } else if (value === 'U') {
      return 'Unsupported';
    } else if (value === 'B') {
      return 'AVS unused';
    } else if (value === 'C') {
      return 'Unrecognized AVS return value';
    } else if (value === 'D') {
      return 'No data';
    } else if (value === 'R') {
      return 'Retry AVS';
    }
  };

  $scope.getOfflinePin = function (value) {
    if (value === 'Y') {
      return 'Validated';
    } else if (value === 'N') {
      return 'Not Present';
    } else if (value === 'F') {
      return 'Failed';
    } else if (value === 'E') {
      return 'Offline PIN attempts exceeded';
    } else if (value === 'P') {
      return 'Ignored due to risk params';
    }
  };

  $scope.back = () => {
    history.back();
  };

  resourceFactory.cardAuthorizationsByCardResource.get({ token: $scope.token, authorizationId: routeParams.id }, function (data) {
    $scope.cardAuthorization = data;
  });
}

ViewCardAuthorizationController.$inject = ['$scope', 'ResourceFactory', '$routeParams'];
