export function UploadClientIdentifierDocumentController ($scope, location, routeParams, API_VERSION, Upload, $rootScope) {
  $scope.clientId = routeParams.clientId;
  $scope.resourceId = routeParams.resourceId;
  $scope.documentPageOptions = [
    {
      name: 'Default',
      value: 'DEFAULT'
    },
    {
      name: 'Front',
      value: 'FRONT'
    },
    {
      name: 'Back',
      value: 'BACK'
    }
  ];

  $scope.uploadPic = function (file) {
    Upload.upload({
      url: `${$rootScope.hostUrl + API_VERSION}/client_identifiers/${$scope.resourceId}/documents`,
      data: $scope.formData,
      file
    }).then(function () {
      // to fix IE not refreshing the model
      if (!$scope.$$phase) {
        $scope.$apply();
      }
      location.path(`/viewclient/${$scope.clientId}`);
    });
  };
}

UploadClientIdentifierDocumentController.$inject = ['$scope', '$location', '$routeParams', 'API_VERSION', 'Upload', '$rootScope'];
