export function ClientDocumentController ($scope, routeParams, resourceFactory, API_VERSION, Upload, $rootScope) {
  $scope.clientId = routeParams.clientId;

  resourceFactory.clientAuthorizations.get({ clientId: $scope.clientId }, function (response) {
    $scope.linkedUsersList = response.pageItems;
  });

  $scope.onFileSelect = function (files) {
    $scope.formData.file = files[0];
  };

  $scope.primaryAccountId = routeParams.primaryAccountId;

  resourceFactory.clientResource.get({ clientId: $scope.clientId }, function (data) {
    $scope.displaySubAccountClientName = data.displayName;
  });

  $scope.submit = function () {
    const maxSize = 10 * 1024 * 1024; // 10MB
    const file = $scope.formData.file;
    const isFileMaxSize = file.size > maxSize;
    const params = isFileMaxSize ? '?command=largeFile' : '';

    const data = {
      name: $scope.formData.name,
      description: $scope.formData.description,
      type: $scope.formData.type,
      userId: $scope.formData.userId,
      ...isFileMaxSize ? { fileName: file.name.replace(/\s/g, '') } : { file }
    };

    Upload.upload({
      url: `${$rootScope.hostUrl + API_VERSION}/clients/${$scope.clientId}/documents${params}`,
      data
    })
      .then(async function (response) {
        if (isFileMaxSize) {
          $rootScope.blockUI = true;
          const { data: { data } } = response;
          const url = data.url.toString();

          const xhr = new XMLHttpRequest();
          xhr.onload = () => {
            if (xhr.status === 200) {
              $rootScope.blockUI = false;
              $scope.back();
            }
          };
          xhr.addEventListener('error', () => {
            $rootScope.blockUI = false;
            $scope.$apply();
          });
          xhr.open('PUT', url);
          xhr.send(file);
        } else {
        // to fix IE not refreshing the model
          if (!$scope.$$phase) {
            $scope.$apply();
          }
          $scope.back();
        }
      });
  };

  $scope.back = () => {
    history.back();
  };
}

ClientDocumentController.$inject = ['$scope', '$routeParams', 'ResourceFactory', 'API_VERSION', 'Upload', '$rootScope'];
