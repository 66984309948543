import angular from 'angular';

import 'angular-route';
import 'angular-ui-bootstrap';
import modal from 'angular-ui-bootstrap/src/modal';
import tabs from 'angular-ui-bootstrap/src/tabs';

import 'angular-translate';
import 'angular-sanitize';
import 'angular-utils-pagination';
import 'angular-resource';
import 'ngVue';
import 'ngVue/build/plugins';
import 'ng-file-upload';
import 'ng-idle';

import './scripts/modules/angular-wizard';
import './scripts/modules/datepicker';
import './scripts/modules/notificationWidget';
import './scripts/modules/keyboardManager';
import './scripts/modules/csv';

import controllers from './scripts/controllers';
import services from './scripts/services';
import filters from './scripts/filters';
import providers from './scripts/providers';
import directives from './scripts/directives';

import vueComponents from '../vue-components';

// combine all modules to create app module
const appModules = angular.module('backoffice', [
  'ngRoute',
  'ngResource',
  'ngSanitize',
  'ngVue',
  'ngVue.plugins',
  // 'ui.bootstrap',
  modal,
  tabs,
  'pascalprecht.translate',
  'mgo-angular-wizard',
  'modified.datepicker',
  'angularUtils.directives.dirPagination',
  'ngIdle',
  'ngFileUpload',
  'notificationWidget',
  'frAngular',
  'ngCsv',
  filters,
  services,
  providers,
  controllers,
  vueComponents,
  directives
]);

// configuration
appModules.constant('API_VERSION', '/v1')
  .constant('IDLE_DURATION', 15 * 60)
  .constant('WARN_DURATION', 10)
  .constant('KEEPALIVE_INTERVAL', 60)
  .constant('SECURITY', 'oauth');

export default appModules;
