import SystemList from '@/pages/system/index.vue';
import ListCodeSystem from '@/pages/system/codes/index.vue';
import ViewCodeSystem from '@/pages/system/codes/view.vue';
import CreateCodeValueSystem from '@/pages/system/codes/code-values/create';
import EditCodeValueSystem from '@/pages/system/codes/code-values/edit';
import ViewRole from '@/pages/system/manage-roles/view.vue';
import CreateRole from '@/pages/system/manage-roles/create.vue';
import ManageRoles from '@/pages/system/manage-roles/index.vue';

export const systemRoutes = [
  {
    path: '/system',
    name: 'SystemList',
    component: SystemList
  },
  {
    path: '/system/code',
    name: 'ListCodeSystem',
    component: ListCodeSystem
  },
  {
    path: '/system/code/:codeId/view',
    name: 'ViewCodeSystem',
    component: ViewCodeSystem
  },
  {
    path: '/system/code/:codeId/code-values/add',
    name: 'CreateCodeValueSystem',
    component: CreateCodeValueSystem
  },
  {
    path: '/system/code/:codeId/code-values/:codeValueId/edit',
    name: 'EditCodeValueSystem',
    component: EditCodeValueSystem
  },
  {
    path: '/system/manage-roles/:id(\\d+)',
    name: 'ViewRole',
    component: ViewRole
  },
  {
    path: '/system/manage-roles/create',
    name: 'CreateRole',
    component: CreateRole
  },
  {
    path: '/system/manage-roles',
    name: 'ManageRoles',
    component: ManageRoles
  }
];
