<template>
  <div>
    <data-grid
      ref="clientTable"
      :limit="limit"
      :fields="fields"
      :total-records="totalRecords"
      @pagination-update="paginationUpdate"
      @entries-per-page="updateEntriesPerPage"
    >
      <template #row>
        <tr v-if="!recordItems.length">
          <td
            class="text-center"
            :colspan="fields.length"
          >
            {{ $t('label.noData') }}
          </td>
        </tr>
        <template v-for="record in recordItems">
          <tr
            v-for="(item, index) in record.records"
            :key="item.id"
            class="pointer"
            @click="$emit('row-click', record.transactionId)"
          >
            <td
              v-if="index === 0"
              :rowspan="record.records.length"
            >
              {{ record.transactionId }}
            </td>
            <td
              v-if="index === 0"
              :rowspan="record.records.length"
            >
              {{ dateStringToFormat(record.transactionDate) }}
            </td>
            <td
              v-if="index === 0"
              :rowspan="record.records.length"
            >
              {{ dateStringToFormat(record.valueDate) }}
            </td>
            <td
              v-if="index === 0"
              :rowspan="record.records.length"
            >
              {{ record.officeName }}
            </td>
            <td
              v-if="index === 0"
              :rowspan="record.records.length"
            >
              {{ record.createdByUserName }}
            </td>
            <td>
              {{ item.glAccount.type.value }}
            </td>
            <td>
              {{ item.glAccount.name }} ({{ item.glAccount.glCode }})
            </td>
            <td>
              <div v-if="item.type === 'DEBIT'">
                {{ record.currency.displaySymbol }}
                {{ item.amount }}
              </div>
            </td>
            <td>
              <div v-if="item.type === 'CREDIT'">
                {{ record.currency.displaySymbol }}
                {{ item.amount }}
              </div>
            </td>
          </tr>
        </template>
      </template>
    </data-grid>
  </div>
</template>

<script setup>
import DataGrid from '@/components/shared/data-grid/index.vue';
import { ref, computed } from 'vue';
import i18n from '@/plugins/i18n';
import { dateStringToFormat } from '@/helper/dateHelper';

const props = defineProps({
  transactions: {
    type: Array,
    default: () => []
  },
  totalRecords: {
    type: Number,
    default: 0
  },
  limit: {
    type: String,
    default: '15'
  }
});

const recordItems = computed(() => {
  return props.transactions?.map((item) => ({
    ...item,
    records: [...item.debits, ...item.credits]
  }));
});

const fields = ref([
  { key: 'transactionId', label: i18n.t('label.heading.transactionId') },
  { key: 'transactionDate', label: i18n.t('label.heading.transactionDate') },
  { key: 'valueDate', label: i18n.t('label.heading.valueDate') },
  { key: 'officeName', label: i18n.t('label.heading.officeName') },
  { key: 'createdByUserName', label: i18n.t('label.heading.createdBy') },
  { key: 'type', label: i18n.t('label.heading.type') },
  { key: 'account', label: i18n.t('label.heading.account') },
  { key: 'debit', label: i18n.t('label.heading.debit') },
  { key: 'credit', label: i18n.t('label.heading.credit') }
]);

const emit = defineEmits(['pagination-update', 'entries-per-page', 'click-row']);
const paginationUpdate = (offset) => {
  emit('pagination-update', offset);
};

const updateEntriesPerPage = (limit) => {
  emit('entries-per-page', limit);
};

</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
