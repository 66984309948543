<template>
  <div>
    <div
      v-for="(item, index) in items"
      :key="index"
    >
      <hr>
      <div class="mt-4">
        <button
          type="button"
          class="btn btn-danger pull-right"
          @click="remove(index)"
        >
          {{ $t('label.button.remove') }}
        </button>
        <slot
          name="content"
          :item="item"
        />
      </div>
    </div>
    <div class="d-flex">
      <button
        type="button"
        class="btn btn-primary ms-auto"
        @click="addMore()"
      >
        {{ $t('label.button.add') }}
      </button>
    </div>
  </div>
</template>

<script setup>

defineProps({
  items: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(['on-add-fields', 'on-remove-fields']);
const addMore = () => {
  emit('on-add-fields');
};
const remove = (index) => {
  emit('on-remove-fields', index);
};
</script>
