<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <button
        class="btn btn-primary"
        :disabled="!selected.length"
        @click="submit(selected)"
      >
        <i class="pe-2 fas fa-redo" />
        {{ $t('label.button.retry') }}
      </button>
    </div>
    <section class="card card-body">
      <div>
        <data-grid
          ref="table"
          :items="failNotifications"
          :limit="limit"
          :fields="fields"
          multiselect
          :total-records="totalRecords"
          @pagination-update="getAllNotifications"
          @entries-per-page="updateEntriesPerPage"
          @row-selected="selectedItem"
        >
          <template #subscriberId="{item}">
            <a :href="`#/viewsubscriber/${item.subscriberId}`">
              {{ item.subscriberId }}
            </a>
          </template>
          <template #action="{item}">
            <div>
              <button
                class="btn btn-primary me-1"
                @click="openviewDetailModal(item)"
              >
                {{ $t('label.button.viewDetail') }}
              </button>
            </div>
          </template>
        </data-grid>
      </div>
    </section>
    <base-modal
      ref="viewDetailModal"
      size="lg"
      hide-footer
    >
      <template #header>
        <h3>{{ $t('label.head.viewDetail') }}</h3>
      </template>
      <template #body>
        <div
          v-for="(detailItem, key) in detailItems"
          :key="key"
          class="mb-1"
        >
          <b class="text-uppercase">{{ key }}: </b>
          {{ detailItem }}
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import apiService from '@/services/apiService';
import DataGrid from '@/components/shared/data-grid/index.vue';
import i18n from '@/plugins/i18n';
import BaseModal from '@/components/shared/BaseModal.vue';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { get, set } from '@vueuse/core';
import { $notify } from '@/plugins/prototypes';

const limit = ref(15);
const offset = ref(0);
const totalRecords = ref(0);
const table = ref(false);
const viewDetailModal = ref(null);
const detailItems = ref(null);
const breadcrumbsStore = useBreadcrumbsStore();
const failNotifications = ref([]);
const selected = ref([]);
const retryItems = ref([]);
const fields = ref([
  { key: 'id', label: i18n.t('label.head.id') },
  { key: 'type', label: i18n.t('label.head.type') },
  { key: 'subscriberId', label: i18n.t('label.head.subscriberId') },
  { key: 'entity', label: i18n.t('label.head.entity') },
  { key: 'activity', label: i18n.t('label.head.activity') },
  { key: 'createdAt', label: i18n.t('label.head.createdAt') },
  { key: 'action', label: i18n.t('label.head.action') }
]);

onMounted(async () => {
  getAllNotifications();
  breadcrumbsStore.setBreadcrumbs([
    { text: i18n.t('label.anchor.subscribers'), href: `#/subscribers` },
    { text: i18n.t('label.anchor.manageFailNotification') }
  ]);
});

const getAllNotifications = async (offsetValue) => {
  try {
    const params = {
      limit: get(limit),
      offset: offsetValue || get(offset)
    };
    get(table).toggleLoading(true);
    const { data } = await apiService.notifications.getAllNotifications(params);
    set(retryItems, data);
    set(failNotifications, data.pageItems.map((failNotification) => {
      return {
        id: failNotification.id,
        subscriberId: failNotification.message.subscriberId,
        type: failNotification.type,
        entity: failNotification.entity,
        activity: failNotification.action,
        createdAt: failNotification.createdAt
      };
    }));
    set(totalRecords, data.totalFilteredRecords);
    get(table).toggleLoading(false);
  } catch (err) {
    get(table).toggleLoading(false);
    $notify.error(err);
  }
};

const updateEntriesPerPage = async (params) => {
  set(limit, params);
  await getAllNotifications();
};

const selectedItem = (items) => set(selected, items.map(item => item.id));
const viewDetail = (retryId) => set(detailItems, get(retryItems).pageItems.find(({ id }) => id === retryId));
const openviewDetailModal = (item) => {
  get(viewDetailModal).show();
  viewDetail(item.id);
};

const submit = async (Id) => {
  try {
    await apiService.notifications.retryFailsNotification({ ids: Id });
    await getAllNotifications();
    $notify.success(i18n.t('label.retrySuccess'));
  } catch (err) {
    $notify.error(err);
  } finally {
    set(selected, []);
    get(table).unSaveSelectedOnPageChange();
  }
};
</script>
