import { render, staticRenderFns } from "./JournalEntriesTable.vue?vue&type=template&id=8724e53e&scoped=true"
import script from "./JournalEntriesTable.vue?vue&type=script&setup=true&lang=js"
export * from "./JournalEntriesTable.vue?vue&type=script&setup=true&lang=js"
import style0 from "./JournalEntriesTable.vue?vue&type=style&index=0&id=8724e53e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8724e53e",
  null
  
)

export default component.exports