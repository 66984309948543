import _ from 'underscore';

export function EditCreditAccountController (scope, resourceFactory, location, routeParams, dateFilter) {
  scope.products = [];
  scope.formData = {};
  scope.accountId = routeParams.id;
  scope.charges = [];
  scope.accountOptions = [];
  scope.restrictDate = new Date();
  scope.cardProducts = [];
  scope.formValue = function (array, model, findattr, retAttr) {
    findattr = findattr || 'id';
    retAttr = retAttr || 'value';
    return _.find(array, function (obj) {
      return obj[findattr] === model;
    })[retAttr];
  };

  scope.changeAccount = function (account) {
    scope.accountNo = account.accountNo;
    scope.formData.currentAccountId = account.id;
  };
  resourceFactory.creditAccountResource.get({ accountId: scope.accountId, template: 'true', associations: 'account' }, function (data) {
    scope.data = data.creditAccountData;

    if (data.clientId) {
      scope.formData.clientId = data.creditAccountData.clientId;
      scope.clientName = data.creditAccountData.clientName;
    }

    scope.formData.cardProductId = data.creditAccountData.cardProductId;
    scope.cardProducts = data.creditAccountData.cardProductOptions;

    if (data.creditAccountData.timeline) {
      const submittedOnDate = dateFilter(data.creditAccountData.timeline.submittedOnDate, scope.df);
      scope.formData.submittedOnDate = new Date(submittedOnDate);
    }
    scope.formData.externalId = data.creditAccountData.externalId;
    scope.formData.nominalAnnualInterestRate = data.creditAccountData.nominalAnnualInterestRate;
    scope.formData.nominalAnnualInterestRateOverdraft = data.creditAccountData.nominalAnnualInterestRateOverdraft;
    scope.formData.minOverdraftForInterestCalculation = data.creditAccountData.minOverdraftForInterestCalculation;
    scope.formData.enforceMinRequiredBalance = data.creditAccountData.enforceMinRequiredBalance;
    scope.formData.minRequiredBalance = data.creditAccountData.minRequiredBalance;
    scope.formData.withHoldTax = data.creditAccountData.withHoldTax;
    scope.formData.nominalCashAdvanceInterestRate = data.creditAccountData.nominalCashAdvanceInterestRate;
    scope.formData.nominalAnnualPenaltyInterestRate = data.creditAccountData.nominalAnnualPenaltyInterestRate;
    scope.formData.isSecuredCreditProduct = data.creditAccountData.isSecuredCreditProduct;
    scope.formData.creditLimit = data.creditAccountData.creditLimit;
    scope.formData.cashLimit = data.creditAccountData.cashLimit;

    scope.formData.currentAccountId = data.creditAccountData.currentAccountId;
    scope.accountOptions = data.creditAccountData.accountOptions;

    if (data.creditAccountData.isLinkedToFloatingInterestRates) {
      scope.formData.interestRateDifferential = data.creditAccountData.interestRateDifferential;
      scope.formData.overdraftInterestRateDifferential = data.creditAccountData.overdraftInterestRateDifferential;
    }
    if (data.creditAccountData.interestCompoundingPeriodType) scope.formData.interestCompoundingPeriodType = data.creditAccountData.interestCompoundingPeriodType.id;
    if (data.creditAccountData.interestPostingPeriodType) scope.formData.interestPostingPeriodType = data.creditAccountData.interestPostingPeriodType.id;
    if (data.creditAccountData.interestCalculationType) scope.formData.interestCalculationType = data.creditAccountData.interestCalculationType.id;
    if (data.creditAccountData.interestCalculationDaysInYearType) scope.formData.interestCalculationDaysInYearType = data.creditAccountData.interestCalculationDaysInYearType.id;
    if (data.creditAccountData.lockinPeriodFrequencyType) scope.formData.lockinPeriodFrequencyType = data.creditAccountData.lockinPeriodFrequencyType.id;
    if (data.creditAccountData.withdrawalFeeType) scope.formData.withdrawalFeeType = data.creditAccountData.withdrawalFeeType.id;
  });

  scope.changeProduct = function () {
    const inparams = { productId: scope.formData.cardProductId };
    if (scope.formData.clientId) inparams.clientId = scope.formData.clientId;
    resourceFactory.creditAccountTemplateResource.get(inparams, function (data) {
      scope.data = data;

      scope.formData.nominalAnnualInterestRate = data.nominalAnnualInterestRate;
      scope.formData.nominalCashAdvanceInterestRate = data.nominalCashAdvanceInterestRate;
      scope.formData.nominalAnnualPenaltyInterestRate = data.nominalAnnualPenaltyInterestRate;
      scope.formData.lockinPeriodFrequency = data.lockinPeriodFrequency;
      scope.formData.withHoldTax = data.withHoldTax;

      if (data.interestCompoundingPeriodType) scope.formData.interestCompoundingPeriodType = data.interestCompoundingPeriodType.id;
      if (data.interestPostingPeriodType) scope.formData.interestPostingPeriodType = data.interestPostingPeriodType.id;
      if (data.interestCalculationType) scope.formData.interestCalculationType = data.interestCalculationType.id;
      if (data.interestCalculationDaysInYearType) scope.formData.interestCalculationDaysInYearType = data.interestCalculationDaysInYearType.id;
      if (data.lockinPeriodFrequencyType) scope.formData.lockinPeriodFrequencyType = data.lockinPeriodFrequencyType.id;
      if (data.withdrawalFeeType) scope.formData.withdrawalFeeType = data.withdrawalFeeType.id;
    });
  };

  scope.cancel = function () {
    location.path(`/viewcreditaccount/${scope.accountId}`);
  };

  scope.submit = function () {
    if (this.formData.submittedOnDate) this.formData.submittedOnDate = dateFilter(this.formData.submittedOnDate, scope.df);
    this.formData.locale = scope.optlang.code;
    this.formData.dateFormat = scope.df;

    resourceFactory.creditAccountResource.update({ accountId: scope.accountId }, this.formData, function (data) {
      location.path(`/viewcreditaccount/${data.resourceId}`);
    });
  };
}

EditCreditAccountController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams', 'dateFilter'];
