export function ViewSubscriberController (scope, routeParams, resourceFactory, location, $uibModal, $sce) {
  resourceFactory.subscriberResource.getSubscriberDetails({ subscriberId: routeParams.id }, function (data) {
    scope.subscriber = data;
    scope.subscriberText = $sce.trustAsHtml(data.text);
    resourceFactory.subscriptionResource.listBySubscriberId({ subscriberId: data.id }, function (subscriptionsData) {
      scope.subscriptions = subscriptionsData.pageItems;
    });
  });

  if (!scope.searchCriteria.subscriptions) {
    scope.searchCriteria.subscriptions = null;
    scope.saveSC();
  }
  scope.filterText = scope.searchCriteria.subscriptions || '';

  scope.onFilter = function () {
    scope.searchCriteria.subscriptions = scope.filterText;
    scope.saveSC();
  };
  scope.deleteSubscriber = function () {
    $uibModal.open({
      templateUrl: 'deletesubscriber.html',
      controller: SubscriberDeleteCtrl
    });
  };
  const SubscriberDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.subscriberResource.delete({ subscriberId: routeParams.id }, {}, function () {
        $uibModalInstance.close('delete');
        location.path('/subscribers');
        // added dummy request param because Content-Type header gets removed
        // if the request does not contain any data (a request body)
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewSubscriberController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location', '$uibModal', '$sce'];
