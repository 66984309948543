<template>
  <div class="row">
    <div class="col-sm">
      <form
        class="form-horizontal"
        @submit.prevent="searchSuspiciousClients(0)"
      >
        <div class="form-group mb-3 row align-items-center">
          <div class="col-md-6">
            <div class="input-group">
              <input
                id="search-client"
                v-model="searchId"
                :placeholder="$t('label.anchor.filterByClientId')"
                class="form-control"
                type="search"
              >
              <div class="input-group-append">
                <button
                  class="btn btn-primary"
                  type="submit"
                >
                  <span class="fas fa-search" />
                </button>
              </div>
              <div class="input-group-append d-flex">
                <div class="d-flex align-items-center">
                  <div class="px-3">
                    <label for="status">{{ $t('label.input.status') }}</label>
                  </div>
                  <b-form-select
                    id="statusFilter"
                    v-model="clientStatusCode"
                    class="form-control form-select"
                    text-field="label"
                    value-field="code"
                    :options="clientStatusOption"
                    @change="searchSuspiciousClients(0)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-12">
      <data-grid
        ref="tableMonitoring"
        :items="items"
        :fields="fields"
        :limit="limitPerPage"
        use-arrow-paginator
        @pagination-update="pageChanged"
        @entries-per-page="updateEntriesPerPage"
      >
        <template #screenedOn="{ item }">
          {{ dateStringToFormat(item.screenedOn) }}
        </template>
        <template #actions="{item}">
          <button
            class="btn btn-primary"
            @click="toggleDetail(item)"
          >
            Details
          </button>
        </template>
      </data-grid>
    </div>

    <screening-details-modal
      ref="ClientMonitoringDetailsModal"
      :detail-item="detailItem"
      :sdn-items="sdnItems"
      :alt-names="altNames"
      :addresses="addresses"
      :denied-persons="deniedPersons"
      @on-submitted="getSuspiciousClients"
    />
  </div>
</template>

<script setup>
import DataGrid from '@/components/shared/data-grid/index.vue';
import { dateStringToFormat } from '@/helper/dateHelper';
import ScreeningDetailsModal from './modal/ScreeningDetails.vue';
import { get, set } from '@vueuse/core';
import { ref, onMounted } from 'vue';
import apiService from '@/services/apiService.js';
import { $notify } from '@/plugins/prototypes';

const fields = [
  { label: 'ID', key: 'id' },
  { label: 'Screened date', key: 'screenedOn' },
  { label: 'Client ID', key: 'clientId' },
  { label: 'Client Name', key: 'clientName' },
  { label: 'Address', key: 'address' },
  { label: 'Status', key: 'status.value' },
  { label: 'Actions', key: 'actions' }
];
const ClientMonitoringDetailsModal = ref(null);
const tableMonitoring = ref(null);
const detailItem = ref({});
const sdnItems = ref([]);
const items = ref([]);
const searchId = ref('');
const clientStatusCode = ref(100);
const offset = ref(0);
const limitPerPage = ref(15);
const clientStatusOption = ref([
  { label: 'All', code: 0 },
  { label: 'Pending', code: 100 },
  { label: 'Approved', code: 300 },
  { label: 'Blocked', code: 600 }
]);
const altNames = ref([]);
const addresses = ref([]);
const deniedPersons = ref([]);

const getSuspiciousClients = async () => {
  try {
    const requestParams = {
      limit: get(limitPerPage),
      offset: get(offset)
    };
    const search = get(searchId);
    const status = get(clientStatusCode);
    if (search) requestParams.clientId = search;
    if (status) requestParams.status = status;
    const { data: { pageItems } } = await apiService.suspiciousclients.get(requestParams);
    set(items, pageItems);
  } catch (error) {
    $notify.error(error);
  }
};

const searchSuspiciousClients = async (searchOffset) => {
  get(tableMonitoring).currentPage = 1;
  set(offset, searchOffset);
  await getSuspiciousClients();
};

const toggleDetail = (item) => {
  const { clientId, address, clientName, screenedOn, threshold, responseBody, id, status } = item;
  const modalDetail = {
    id,
    screenedOn,
    clientId,
    clientName,
    address,
    threshold,
    status
  };
  set(detailItem, modalDetail);
  set(sdnItems, responseBody?.SDNs);
  set(altNames, responseBody?.altNames);
  set(addresses, responseBody?.addresses);
  set(deniedPersons, responseBody?.deniedPersons);
  get(ClientMonitoringDetailsModal).$children[0].show();
};

onMounted(async () => await getSuspiciousClients());

const updateEntriesPerPage = async (params) => {
  set(limitPerPage, params);
  await getSuspiciousClients();
};

const pageChanged = async (offsetValue) => {
  set(offset, offsetValue);
  await getSuspiciousClients();
};
</script>
