import _ from 'underscore';

export function ViewAuditController ($scope, resourceFactory, routeParams, location) {
  $scope.details = {};
  $scope.isFromClientAudit = false;
  if (location.search().clientId) {
    $scope.isFromClientAudit = true;
    $scope.clientId = location.search().clientId;
  }
  resourceFactory.auditResource.get({ templateResource: routeParams.id }, function (data) {
    $scope.details = data;
    $scope.commandAsJson = data.commandAsJson;
    const obj = JSON.parse($scope.commandAsJson);
    $scope.jsondata = [];
    _.each(obj, function (value, key) {
      $scope.jsondata.push({ name: key, property: value });
    });
  });
}

ViewAuditController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location'];
