<template>
  <div>
    <form
      class="form-horizontal"
      @submit.prevent="searchUser()"
    >
      <div class="form-group mb-3 row align-items-center">
        <div class="col-12 col-md-8">
          <div class="input-group">
            <input
              id="search_attach_users"
              v-model="searchText"
              :placeholder="$t('label.input.searchByNameUserEmailToAttach')"
              class="form-control"
              type="search"
              minlength="2"
            >
            <div class="input-group-append">
              <button
                class="btn btn-primary"
                type="submit"
              >
                <span class="fas fa-search" />
              </button>
            </div>
            <button
              v-if="showAttachedUserBtn"
              class="btn btn-link"
              @click="clearSearch"
            >
              {{ $t('label.button.showAttachedUser') }}
            </button>
          </div>
        </div>
      </div>
    </form>
    <data-grid
      ref="usersAttachList"
      :items="usersItems"
      :limit="limit"
      :total-records="totalRecords"
      :fields="fields"
      @pagination-update="paginationUpdate"
      @entries-per-page="updateEntriesPerPage"
    >
      <template #username="{ item }">
        {{ item.user.username }}
      </template>
      <template #firstname="{ item }">
        {{ item.user.firstname }}
      </template>
      <template #lastname="{ item }">
        {{ item.user.lastname }}
      </template>
      <template #email="{ item }">
        {{ item.user.email }}
      </template>
      <template #actions="{ item }">
        <button
          v-has-permission="isUserAttachedToClient(item.client.id)? 'UPDATE_CLIENT' : 'CREATE_CLIENT'"
          :disabled="loading"
          class="btn btn-light"
          @click="addOrRemoveUser(item)"
        >
          <span :class="`fa ${isUserAttachedToClient(item.client.id)? 'fa-times' : 'fa-add'}`" />
        </button>
      </template>
    </data-grid>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import i18n from '@/plugins/i18n';
import apiService from '@/services/apiService';
import DataGrid from '@/components/shared/data-grid/index.vue';
import { get, set } from '@vueuse/core';
import { getAngularRootScope } from '@/helper/angularRootScope';

const props = defineProps({
  clientId: {
    type: Number,
    required: true
  }
});
const searchText = ref('');
const limit = ref(15);
const usersItems = ref([]);
const totalRecords = ref(0);
const isAddUser = ref(false);

const fields = ref([
  { key: 'username', label: i18n.t('label.heading.username') },
  { key: 'firstname', label: i18n.t('label.heading.firstname') },
  { key: 'lastname', label: i18n.t('label.heading.lastname') },
  { key: 'email', label: i18n.t('label.heading.email') },
  { key: 'actions', label: i18n.t('label.heading.actions') }
]);

const usersAttachList = ref(null);
const loading = ref(false);

const $rootScope = getAngularRootScope();
const showAttachedUserBtn = ref(false);

const searchUser = async (offset) => {
  get(usersAttachList)?.toggleLoading(true);
  set(showAttachedUserBtn, !!searchText.value);
  try {
    const searchTextValue = get(searchText);
    set(isAddUser, !!searchTextValue);

    const { data: { pageItems, totalFilteredRecords } } = await apiService.clientResource.getAuthorizations({
      limit: get(limit),
      name: searchTextValue,
      offset: offset || 0,
      clientId: searchTextValue ? '' : props.clientId
    });

    set(usersItems, pageItems);
    set(totalRecords, totalFilteredRecords);
  } catch (error) {
    $rootScope.setApiValidationError(error);
  } finally {
    get(usersAttachList)?.toggleLoading(false);
  }
};

onMounted(() => {
  searchUser();
});

const clearSearch = () => {
  set(showAttachedUserBtn, false);
  set(searchText, '');
  searchUser();
};

const isUserAttachedToClient = (itemId) => itemId === props.clientId;

const addOrRemoveUser = async (item) => {
  try {
    set(loading, true);
    const { data: client } = await apiService.clientResource.get(props.clientId);
    let authorizations = [];
    if (client.authorizations) {
      authorizations = [...client.authorizations];
    }
    // unAttach User from client
    if (isUserAttachedToClient(item.client.id)) {
      authorizations = authorizations.filter(id => id !== item.user.id);
    }
    // Attach User to client
    if (!isUserAttachedToClient(item.client.id)) {
      authorizations.push(item.user.id);
    }
    await apiService.clientResource.update(props.clientId, { authorizations });
    set(searchText, '');
    searchUser();
  } catch (error) {
    $rootScope.setApiValidationError({ message: error.response.data?.errors[0].defaultUserMessage });
  } finally {
    set(loading, false);
  }
};

const paginationUpdate = (offset) => {
  searchUser(offset);
};

const updateEntriesPerPage = (limitChange) => {
  set(limit, limitChange);
};
</script>
