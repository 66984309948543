export function EditExternalServicesConfigurationController ($scope, resourceFactory, $routeParams, location) {
  $scope.formData = [];
  $scope.names = [];
  $scope.externalServicesType = $routeParams.externalServicesType;
  // $scope.name = $routeParams.name;
  const nvObject = {};
  resourceFactory.externalServicesResource.get({ id: $scope.externalServicesType }, function (data) {
    for (const i in data) {
      if (data[i] !== null && data[i].name !== null) {
        data[i].name.replace(/ /g, '');
        if (!angular.equals(data[i].name, '')) {
          nvObject[data[i].name] = data[i].value;
          $scope.names.push(data[i].name);
        }
      }
    }
    $scope.formData = nvObject;
  });

  $scope.cancel = function () {
    location.path(`/externalservices/${$scope.externalServicesType}`);
  };

  $scope.submit = function () {
    resourceFactory.externalServicesResource.put({ id: $scope.externalServicesType }, this.formData, function () {
      location.path('/externalservices');
    });
  };
}

EditExternalServicesConfigurationController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location'];
