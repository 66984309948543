export function ViewAccountingClosureController (scope, resourceFactory, location, routeParams, $uibModal) {
  scope.accountClosure = {};
  scope.choice = 0;
  resourceFactory.accountingClosureResource.getView({ accId: routeParams.id }, function (data) {
    scope.accountClosure = data;
  });
  scope.deleteAcc = function () {
    $uibModal.open({
      templateUrl: 'deleteacc.html',
      controller: AccDeleteCtrl
    });
  };
  const AccDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.accountingClosureResource.delete({ accId: routeParams.id }, {}, function () {
        $uibModalInstance.close('delete');
        location.path('/accounts_closure');
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewAccountingClosureController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams', '$uibModal'];
