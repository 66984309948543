<template>
  <base-modal
    ref="modal"
    modal-size="lg"
    show-button-close
    hide-footer
    scrollable
  >
    <template #header>
      <h5
        v-if="!isPreviewingAttachment"
        class="fw-bold link-primary"
      >
        {{ $t('label.associatedClient.summaryPage.title') }}
      </h5>
      <div
        v-else
        class="d-flex"
      >
        <div
          class="btn"
          @click="closePreview"
        >
          <i class="fas fa-arrow-left me-2" />
          <span class="fw-bold">
            {{ $t('label.associatedClient.summaryPage.back') }}
          </span>
        </div>
      </div>
    </template>

    <template #body>
      <div class="row">
        <div class="col-12">
          <div class="card mt-2">
            <div
              v-show="!isPreviewingAttachment"
              class="card-body"
            >
              <span>
                {{ $t('label.associatedClient.summaryPage.description') }}
              </span>

              <div
                v-for="(step, stepIndex) in summaryData"
                :key="`step-${stepIndex}`"
                class="mt-3"
              >
                <h5 class="fw-bold">
                  {{ step.title }}
                </h5>

                <div
                  v-for="(rowName, rowIndex) in step.data"
                  :key="`step-${stepIndex}-row-${rowIndex}`"
                >
                  <div
                    v-for="(fieldName, fieldIndex) in step.columnHeaders"
                    :key="`step-${stepIndex}-row-${rowIndex}-field-${fieldIndex}`"
                    class="mb-2"
                  >
                    <div v-if="fieldName.columnName !== 'id' && fieldName.columnName !== 'client_id'">
                      <div class="row">
                        <div class="col-6 text-end">
                          {{ fieldName.columnDisplayName }}
                        </div>
                        <div
                          v-if="fieldName.columnCode !== 'FILE_UPLOAD'"
                          class="col-6 fw-bold"
                        >
                          {{ step.data[rowIndex].row[fieldIndex] }}
                        </div>
                        <div
                          v-else-if="step.data[rowIndex].row[fieldIndex] === null"
                          class="col-6 text-secondary"
                        >
                          {{ $t('label.associatedClient.summaryPage.noFileUploaded') }}
                        </div>
                        <div
                          v-else
                          class="col-6 fw-bold d-flex justify-content-betweeen"
                        >
                          <div class="text-truncate flex-grow-1 me-2">
                            {{ step.data[rowIndex].row[fieldIndex]?.name }}
                          </div>
                          <div class="d-flex">
                            <button
                              class="btn btn-light border btn-sm"
                              @click="downloadAttachment(step.data[rowIndex].row[fieldIndex])"
                            >
                              <i class="fas fa-cloud-download-alt" />
                            </button>
                            <button
                              class="ms-2 btn btn-light border btn-sm"
                              @click="showAttachment(step.data[rowIndex].row[fieldIndex])"
                            >
                              <i class="fas fa-eye" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="isPreviewingAttachment"
              class="card-body"
            >
              <div class="d-flex mb-3 justify-content-between align-items-center">
                <div class="px-2 py-1 fw-semibold text-success-emphasis bg-success-subtle border border-success-subtle rounded-2">
                  {{ attachment.details.name }}
                </div>
                <button
                  class="btn btn-secondary"
                  @click="downloadAttachment(attachment.details)"
                >
                  Download
                </button>
              </div>
              <div
                class="w-100 text-center wrapper"
                style="height: 50vh;"
              >
                <img
                  :src="attachment.url"
                  alt="preview"
                  class="w-100 h-100 object-fit-contain"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import { ref, onMounted } from 'vue';
import apiService from '@/services/apiService';
import { set, get } from '@vueuse/core';
import { $notify } from '@/plugins/prototypes';
import { downloadHelper } from '@/helper/downloadHelper';

const props = defineProps({
  clientId: {
    type: [String, Number],
    required: true
  }
});

const summaryData = ref([]);
const caches = ref([]);
const isPreviewingAttachment = ref(false);
const attachment = ref({});

const windowUrl = window.URL || window.webkitURL;

onMounted(async () => {
  await getData();
});

const getAttachmentApi = async (attachmentDetail) => {
  const attachment = get(caches).find(item => item.id === attachmentDetail.id) || false;
  if (attachment) return attachment.data;

  try {
    const { data } = await apiService.documents.downloadRegistrationAttachment(props.clientId, attachmentDetail.registeredDataTable, attachmentDetail.id, 'arraybuffer');
    get(caches).push({ data, ...attachmentDetail });
    return data;
  } catch (e) {
    $notify.error(e);
  }
};

const downloadAttachment = async (attachmentDetail) => {
  const attachmentImage = await getAttachmentApi(attachmentDetail);
  downloadHelper(attachmentImage, attachmentDetail.name, attachmentDetail.mimeType);
};

const showAttachment = async (attachmentDetail) => {
  const blob = new Blob([await getAttachmentApi(attachmentDetail)], { type: attachmentDetail.mimeType, responseType: 'arraybuffer' });
  const url = windowUrl.createObjectURL(blob);
  set(attachment, { url, details: attachmentDetail });
  set(isPreviewingAttachment, true);
};

const closePreview = () => {
  windowUrl.revokeObjectURL(get(attachment).url);
  set(isPreviewingAttachment, false);
  set(attachment, {});
};

const getData = async () => {
  const { data } = await apiService.watchmanLists.registrationSummary(props.clientId);
  const temp = [];

  for (const step of data.filter(step => step.isDataTable && step.data?.length)) {
    const tempStepDetails = { ...step };

    for (const [rowIndex, rowData] of step.data.entries()) {
      await Promise.all(step.columnHeaders.map(async (column, columnIndex) => {
        const isMultipleAnswers = (column.columnType === 'dropdown' && column.columnCode !== 'YesNo') || column.columnType === 'multiselect';
        const isYesNoAnswer = column.columnDisplayType === 'CODELOOKUP' && column.columnCode === 'YesNo';
        const isBoolean = column.columnDisplayType === 'BOOLEAN';
        const isFileUpload = column.columnCode === 'FILE_UPLOAD' && rowData.row[columnIndex];

        if (isMultipleAnswers) {
          // Multi-Selection data is recorded in a form of concated string of IDs. For example, "23,43,901".
          // Therefore, the data need to be split to map its value.
          const dropdownArrs = rowData.row[columnIndex]?.split(',');
          const dropdownResults = [];

          if (dropdownArrs) {
            dropdownArrs.forEach(item => {
              const result = column.columnValues.find(v => v.id.toString() === item);
              if (result) dropdownResults.push(` ${result.value}`);
            });
          }
          tempStepDetails.data[rowIndex].row[columnIndex] = dropdownResults?.toString();
        }

        if (isYesNoAnswer) {
          // Yes/No answers data is stored in form of ID for lookup in an array, for example, the stored data would be 607.
          // To get the actual value, we need to use the ID to find the value object inside columnValues.
          const yesNoId = rowData.row[columnIndex];
          const yesNo = step.columnHeaders[columnIndex].columnValues.find(item => item.id.toString() === yesNoId);

          tempStepDetails.data[rowIndex].row[columnIndex] = yesNo?.value;
        }

        if (isBoolean) {
          // In this case the question was in a form of checkboxes/multiple choices.
          // If the answer was checked, the data would be stored as "true", otherwise, null or "false".
          tempStepDetails.data[rowIndex].row[columnIndex] = rowData.row[columnIndex] === 'true' ? 'Yes' : 'No';
        }

        if (isFileUpload) {
          const fileId = rowData.row[columnIndex];

          if (!fileId) return;
          // File upload is stored in form of attachmentID.
          // To get the attachment details, we also need registeredDataTable (which is placed in sections[0].registeredDataTable)
          // This details will later used for getting the actual attachment for display or download.
          const { data: attachment } = await apiService.documents.getRegistrationAttachmentDetail(props.clientId, step.sections[0].registeredDataTable, rowData.row[columnIndex]);
          tempStepDetails.data[rowIndex].row[columnIndex] = attachment;
        }
      }));
    }

    temp.push(tempStepDetails);
  }

  set(summaryData, temp);
};
</script>

<style scoped>
.wrapper img {
  filter: drop-shadow(0px 0px 1px rgba(0,0,0,.3)) drop-shadow(0px 0px 10px rgba(0,0,0,.3));
}
</style>
