import { defineStore } from 'pinia';

const defaultState = {
  isVueRoute: false,
  sessionProcessed: false
};

export const useVueTransitionStore = defineStore('vuetransition', {
  state: () => ({ ...defaultState })
});
