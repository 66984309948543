<template>
  <span>
    <button
      :disabled="loading"
      class="btn btn-light me-1"
      @click="viewFile()"
    >
      <i
        v-tooltips="$t('label.button.view')"
        class="fas fa-eye"
      />
    </button>
    <base-modal
      id="viewFileModal"
      ref="viewFileModal"
      modal-size="xl"
      hide-footer
      show-button-close
    >
      <template #header>
        {{ $t('label.heading.viewFile') }}
      </template>
      <template #body>
        <img
          v-if="isFileImage"
          class="img-fluid"
          :src="fileSrc"
          alt="img"
        >
        <iframe
          v-if="isFilePdf"
          frameborder="0"
          width="100%"
          height="800px"
          :src="fileSrc"
        />
      </template>
    </base-modal>
  </span>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useSharedStore } from '@/stores/shared';
import BaseModal from '@/components/shared/BaseModal.vue';
import { viewHelper, isImage, isPdf } from '@/helper/downloadHelper';
import apiService from '@/services/apiService';
import router from '@/router/index';
import { $notify } from '@/plugins/prototypes';

const { userId } = router.currentRoute.params;

const props = defineProps({
  doc: {
    type: Object,
    default: null
  }
});

const isFileImage = computed(() => isImage(props.doc?.mimeType));
const isFilePdf = computed(() => isPdf(props.doc?.mimeType));

const sharedStore = useSharedStore();
const loading = computed(() => sharedStore.loading);

const viewFileModal = ref(null);
const fileSrc = ref('');

const emit = defineEmits(['on-view-file']);

const viewFile = () => {
  emit('on-view-file');
  showUserDocument();
  viewFileModal.value.show();
};

const showUserDocument = async () => {
  try {
    const { data } = await apiService.userResource.getAttachment(userId, props.doc?.id);
    fileSrc.value = await viewHelper(data);
  } catch (error) {
    $notify.error(error);
  }
};

</script>
