// (function (module) {
//   mbanq.directives = _.extend(module, {
//     SummaryDirective () {
//       return {
//         restrict: 'E',
//         transclude: true,
//         scope: {
//           title: '@'
//         },

//         template: '<div class=\'summary\'>' +
//                     '<div class=\'summary-header\'>' +
//                     '<div class=\'summary-header-text\'>{{title}}</div></div>' +
//                     '<div ng-transclude></div></div>'
//       }

//     }
//   })
// }(mbanq.directives || {}))

// mbanq.ng.application.directive('ngSummary', [mbanq.directives.SummaryDirective]).run(function ($log) {
//   $log.info('SummaryDirective initialized')
// })

export function SummaryDirective () {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      title: '@'
    },

    template: '<div class=\'summary\'>' +
            '<div class=\'summary-header\'>' +
            '<div class=\'summary-header-text\'>{{title}}</div></div>' +
            '<div ng-transclude></div></div>'
  };
}
