export function ViewWireTransferController ($scope, resourceFactory, $uibModal, location, routeParams) {
  $scope.transferDetails = {};
  $scope.formData = {};
  $scope.formData.creditor = {};
  $scope.formData.debtor = {};
  $scope.formData.paymentRailMetaData = {};
  $scope.paymentRailMetaData = {};
  $scope.textAreaLineParamNames = ['addressLineOne', 'addressLineTwo', 'addressLineThree', 'addressLineFour', 'addressLineFive', 'addressLineSix'];
  $scope.lineParamNames = ['lineOne', 'lineTwo', 'lineThree', 'lineFour', 'lineFive', 'lineSix'];
  $scope.businessFuncCodes = [
    { code: 'BTR', label: 'BTR - Bank Transfer(Beneficiary is a bank)' },
    { code: 'CTR', label: 'CTR - Customer Transfer(Beneficiary is not bank)' },
    { code: 'CKS', label: 'CKS - Check Same Day Settlements' },
    { code: 'CTP', label: 'CTP - Customer Transfer Plus' },
    { code: 'DEP', label: `DEP - Deposit to Sender's Account` },
    { code: 'DRB', label: 'DRB - Bank to Bank Drawdown Request' },
    { code: 'DRC', label: 'DRC - Customer or Corporate Drawdown Request' },
    { code: 'DRW', label: 'DRW - Drawdown Payment' },
    { code: 'FFR', label: 'FFR - Fed Funds Returned' },
    { code: 'FFS', label: 'FFS - Fed Funds Sold' },
    { code: 'SVC', label: 'SVC - Service Message' }
  ];

  $scope.wireTypes = [
    { typeSubTypeCode: '1000', typeSubTypeLabel: '1000 - Fund Transfer/ Basic Fund Transfer' },
    { typeSubTypeCode: '1001', typeSubTypeLabel: '1001 - Fund Transfer/ Request for Reversal' },
    { typeSubTypeCode: '1002', typeSubTypeLabel: '1002 - Fund Transfer/ Reversal of Transfer' },
    { typeSubTypeCode: '1007', typeSubTypeLabel: '1007 - Fund Transfer/ Request for Reversal of a Prior day Transfer' },
    { typeSubTypeCode: '1008', typeSubTypeLabel: '1008 - Fund Transfer/ Reversal of a Prior day Transfer' },
    { typeSubTypeCode: '1031', typeSubTypeLabel: '1031 - Fund Transfer/ Request for Credit(Drawdown)' },
    {
      typeSubTypeCode: '1032',
      typeSubTypeLabel: '1032 - Fund Transfer/ Funds Transfer Honoring a Request for Credit(Drawdown)'
    },
    {
      typeSubTypeCode: '1033',
      typeSubTypeLabel: '1033 - Fund Transfer/ Refusal to Honor a Request for Credit(Drawdown)'
    },
    { typeSubTypeCode: '1090', typeSubTypeLabel: '1090 - Fund Transfer/ Service Message' },

    { typeSubTypeCode: '1500', typeSubTypeLabel: '1500 - Foreign Transfer/ Basic Fund Transfer' },
    { typeSubTypeCode: '1501', typeSubTypeLabel: '1501 - Foreign Transfer/ Request for Reversal' },
    { typeSubTypeCode: '1502', typeSubTypeLabel: '1502 - Foreign Transfer/ Reversal of Transfer' },
    {
      typeSubTypeCode: '1507',
      typeSubTypeLabel: '1507 - Foreign Transfer/ Request for Reversal of a Prior day Transfer'
    },
    { typeSubTypeCode: '1508', typeSubTypeLabel: '1508 - Foreign Transfer/ Reversal of a Prior day Transfer' },
    { typeSubTypeCode: '1531', typeSubTypeLabel: '1531 - Foreign Transfer/ Request for Credit(Drawdown)' },
    {
      typeSubTypeCode: '1532',
      typeSubTypeLabel: '1532 - Foreign Transfer/ Funds Transfer Honoring a Request for Credit(Drawdown)'
    },
    {
      typeSubTypeCode: '1533',
      typeSubTypeLabel: '1533 - Foreign Transfer/ Refusal to Honor a Request for Credit(Drawdown)'
    },
    { typeSubTypeCode: '1590', typeSubTypeLabel: '1590 - Foreign Transfer/ Service Message' },

    { typeSubTypeCode: '1600', typeSubTypeLabel: '1600 - Settlement Transfer/ Basic Fund Transfer' },
    { typeSubTypeCode: '1601', typeSubTypeLabel: '1601 - Settlement Transfer/ Request for Reversal' },
    { typeSubTypeCode: '1602', typeSubTypeLabel: '1602 - Settlement Transfer/ Reversal of Transfer' },
    {
      typeSubTypeCode: '1607',
      typeSubTypeLabel: '1607 - Settlement Transfer/ Request for Reversal of a Prior day Transfer'
    },
    { typeSubTypeCode: '1608', typeSubTypeLabel: '1608 - Settlement Transfer/ Reversal of a Prior day Transfer' },
    { typeSubTypeCode: '1631', typeSubTypeLabel: '1631 - Settlement Transfer/ Request for Credit(Drawdown)' },
    {
      typeSubTypeCode: '1632',
      typeSubTypeLabel: '1632 - Settlement Transfer/ Funds Transfer Honoring a Request for Credit(Drawdown)'
    },
    {
      typeSubTypeCode: '1633',
      typeSubTypeLabel: '1633 - Settlement Transfer/ Refusal to Honor a Request for Credit(Drawdown)'
    },
    { typeSubTypeCode: '1690', typeSubTypeLabel: '1690 - Settlement Transfer/ Service Message' }

  ];

  $scope.currencyCodes = [
    { code: 'USD', label: 'USD' },
    { code: 'GBP', label: 'GBP' },
    { code: 'INR', label: 'INR' }
  ];

  $scope.IDCodes5000and4200 = [
    { code: 'B', label: 'B - SWIFT Bank Identifier Code(BIC)' },
    { code: 'C', label: 'C - CHIPS Participant' },
    { code: 'D', label: 'D - Demand Deposit Account(DDA) Number ' },
    { code: 'F', label: 'F - Fed Routing Number' },
    { code: 'T', label: 'T - SWIFT BIC or Bank Entity Identifier(BEI) and Account Number' },
    { code: 'U', label: 'U - CHIPS Identifier' },
    { code: '1', label: '1 - Passport Number' },
    { code: '2', label: '2 - Tax Identification Number' },
    { code: '3', label: `3 - Driver's License Number` },
    { code: '4', label: '4 - Alien Registration Number' },
    { code: '5', label: '5 - Corporate Identification' },
    { code: '9', label: '9 - Other Identification' }
  ];

  $scope.IDCodes5100and5200 = [
    { code: 'B', label: 'B - SWIFT Bank Identifier Code(BIC)' },
    { code: 'C', label: 'C - CHIPS Participant' },
    { code: 'D', label: 'D - Demand Deposit Account(DDA) Number ' },
    { code: 'F', label: 'F - Fed Routing Number' },
    { code: 'U', label: 'U - CHIPS Identifier' }
  ];

  $scope.detailsOfCharges = [
    { code: 'B', label: 'B - Beneficiary' },
    { code: 'S', label: 'S - Shared' }
  ];

  $scope.adviceCodes = [
    { code: 'LTR', label: 'LTR - Letter' },
    { code: 'PHN', label: 'PHN - Phone' },
    { code: 'TLX', label: 'TLX - Telex' },
    { code: 'WRE', label: 'WRE - Wire' },
    { code: 'HLD', label: 'HLD - Hold' }
  ];

  $scope.methodOfPayments = [
    { code: 'CHECK', label: 'CHECK' }
  ];

  // Custom names for payment types
  $scope.paymentTypeLabels = { WIRE: 'Domestic Wire', SWIFT: 'International Wire [SWIFT]' };

  $scope.textMergeMethod = function (paramNames, value) {
    let data = '';
    if (value !== undefined) {
      for (let i = 0; i < paramNames.length; i++) {
        if (value[paramNames[i]] !== undefined) {
          data = `${data + value[paramNames[i]]} `;
        } else {
          return data;
        }
      }
    }
    return data;
  };
  $scope.isDraft = false;
  resourceFactory.transferResource.get({ id: routeParams.transferId }, function (data) {
    $scope.transferDetails = data;

    $scope.isIncomingPostedFromBO = (data.inOrOut === 'IN' && !data.rawPaymentDetails);

    if (data.status === 'DRAFT') {
      $scope.isDraft = true;
      $scope.paymentRailMetaData = data.paymentRailMetaData;
    } else {
      $scope.paymentRailMetaData = (data.inOrOut === `IN`) ? data.paymentRailMetaData : data?.rawPaymentDetails?.fedWireMessage;
    }

    $scope.formData.paymentType = $scope.transferDetails.paymentType;
    if (data.inOrOut === 'OUT') {
      $scope.accountId = data.debtorAccountId;
    } else {
      $scope.accountId = data.creditorAccountId;
    }
    $scope.getBasicDetails($scope.paymentRailMetaData);
    $scope.getOriginatorDetails();
    $scope.getBeneficiaryDetails();
    if ($scope.paymentRailMetaData.charges) {
      $scope.getCharges3700TagDetails($scope.paymentRailMetaData.charges);
    }
    $scope.getFinancialInstitutionToFinancialInstitutionInformation($scope.paymentRailMetaData);
    $scope.getPaymentNotification3620TagDetails($scope.paymentRailMetaData.paymentNotification);
  });

  $scope.getBasicDetails = function (paymentRailMetaData) {
    $scope.formData.amount = $scope.transferDetails.amount;
    if (paymentRailMetaData) {
      $scope.getBusinessCode3600TagDetails(paymentRailMetaData.businessFunctionCode);
      $scope.getWireType1510TagDetails(paymentRailMetaData.typeSubType);
      $scope.getReceiverDepositoryInformation3400TagDetails(paymentRailMetaData.receiverDepositoryInstitution);
      // 3320 tag details
      if (paymentRailMetaData.senderReference && paymentRailMetaData.senderReference.senderReference) {
        $scope.formData.senderReference = paymentRailMetaData.senderReference.senderReference;
      }
      $scope.getInstructedAmount3710TagDetails(paymentRailMetaData.instructedAmount);
      // 3720 tag details
      $scope.formData.exchangeRate = !$scope.isIncomingPostedFromBO ? paymentRailMetaData.exchangeRate?.exchangeRate?.replace(',', '.') : paymentRailMetaData?.exchangeRate?.exchangeRate;
      $scope.getSenderDepositoryInstitution3100TagDetails(paymentRailMetaData.senderDepositoryInstitution);
      $scope.getImadAndOmadDetails(paymentRailMetaData);
    }
  };

  $scope.getSenderDepositoryInstitution3100TagDetails = function (senderDepositoryInstitution) {
    if (senderDepositoryInstitution) {
      $scope.formData.senderAbaAndName = '';
      if (senderDepositoryInstitution.senderABANumber && senderDepositoryInstitution.senderABANumber.length > 0) {
        $scope.formData.senderAbaAndName = senderDepositoryInstitution.senderABANumber;
      }
      if (senderDepositoryInstitution.senderShortName && senderDepositoryInstitution.senderShortName.length > 0) {
        $scope.formData.senderAbaAndName = `${$scope.formData.senderAbaAndName}(${senderDepositoryInstitution.senderShortName})`;
      }
    }
  };

  $scope.getPaymentNotification3620TagDetails = function (paymentNotification) {
    if (paymentNotification) {
      if (paymentNotification.paymentNotificationIndicator) {
        $scope.isPaymentNotification = true;
        $scope.formData.paymentNotificationIndicator = paymentNotification.paymentNotificationIndicator;
      }
      if (paymentNotification.contactNotificationElectronicAddress) {
        $scope.formData.electronicAddress = paymentNotification.contactNotificationElectronicAddress;
      }
      if (paymentNotification.contactName) {
        $scope.formData.contactName = paymentNotification.contactName;
      }
      if (paymentNotification.contactPhoneNumber) {
        $scope.formData.contactPhoneNumber = paymentNotification.contactPhoneNumber;
      }
      if (paymentNotification.contactMobileNumber) {
        $scope.formData.contactMobileNumber = paymentNotification.contactMobileNumber;
      }
      if (paymentNotification.faxNumber) {
        $scope.formData.faxNumber = paymentNotification.faxNumber;
      }
      if (paymentNotification.endToEndIdentification) {
        $scope.formData.endToEndIdentification = paymentNotification.endToEndIdentification;
      }
    } else {
      $scope.isPaymentNotification = false;
    }
  };

  $scope.getBusinessCode3600TagDetails = function (businessFunctionCode) {
    if (businessFunctionCode) {
      // 3600 tag
      if (businessFunctionCode.businessFunctionCode) {
        $scope.formData.businessFunctionCode = businessFunctionCode.businessFunctionCode;
      }
      // 3600 tag
      if (businessFunctionCode.transactionTypeCode) {
        $scope.formData.transactionType = businessFunctionCode.transactionTypeCode;
      }
    }
  };

  $scope.getWireType1510TagDetails = function (typeSubType) {
    if (typeSubType) {
      if (typeSubType.typeCode && typeSubType.subTypeCode) {
        const typeCode = typeSubType.typeCode;
        const subTypeCode = typeSubType.subTypeCode;
        $scope.formData.wireType = typeCode + subTypeCode;
      }
    }
  };

  $scope.getReceiverDepositoryInformation3400TagDetails = function (receiverDepositoryInstitution) {
    if (receiverDepositoryInstitution) {
      if (receiverDepositoryInstitution.receiverABANumber) {
        $scope.formData.receiverABANumber = receiverDepositoryInstitution.receiverABANumber;
      }
      if (receiverDepositoryInstitution.receiverShortName) {
        $scope.formData.receiverName = receiverDepositoryInstitution.receiverShortName;
      }
    }
  };

  $scope.getInstructedAmount3710TagDetails = function (instructedAmount) {
    if (instructedAmount) {
      if (instructedAmount.currencyCode) {
        $scope.formData.instructedAmountCurrencyCode = instructedAmount.currencyCode;
      }
      if (instructedAmount.amount) {
        $scope.formData.instructedAmount = instructedAmount.amount;
      }
    }
  };

  $scope.getFinancialInstitutionToFinancialInstitutionInformation = function (paymentRailMetaData) {
    if (paymentRailMetaData) {
      if (paymentRailMetaData.fiIntermediaryFI) {
        $scope.intermediaryFIInformation6200TagData(paymentRailMetaData.fiIntermediaryFI.fiToFI);
      }
      if (paymentRailMetaData.fiIntermediaryFIAdvice) {
        $scope.intermediaryFIAdvice6210TagData(paymentRailMetaData.fiIntermediaryFIAdvice);
      }
      if (paymentRailMetaData.fiBeneficiaryFI) {
        $scope.beneficiaryFIInformation6300TagData(paymentRailMetaData.fiBeneficiaryFI.fiToFI);
      }
      if (paymentRailMetaData.fiIntermediaryFIAdvice) {
        $scope.beneficiaryFIAdvice6310TagData(paymentRailMetaData.fiBeneficiaryFIAdvice);
      }
      if (paymentRailMetaData.fiBeneficiaryAdvice) {
        $scope.beneficiaryAdvice6410TagData(paymentRailMetaData.fiBeneficiaryAdvice);
      }
      if (paymentRailMetaData.fiReceiverFI) {
        $scope.receiverFIInformation6100TagData(paymentRailMetaData.fiReceiverFI.fiToFI);
      }
      if (paymentRailMetaData.fiPaymentMethodToBeneficiary) {
        $scope.methodOfPaymentToBeneficiary6420TagData(paymentRailMetaData.fiPaymentMethodToBeneficiary);
      }
    }
    // 6400 tag
    if ($scope.transferDetails.creditor.forFurtherCredit) {
      $scope.formData.beneficiaryInformationReference = $scope.transferDetails.creditor.forFurtherCredit;
    }
    if ($scope.transferDetails.debtor.forFurtherCredit) {
      $scope.formData.beneficiaryInformationReference = $scope.transferDetails.debtor.forFurtherCredit;
    }
  };

  $scope.intermediaryFIInformation6200TagData = function (fiToFI) {
    if (fiToFI) {
      $scope.formData.intermediaryFiInformationReference = $scope.textMergeMethod($scope.lineParamNames, fiToFI);
    }
  };

  $scope.methodOfPaymentToBeneficiary6420TagData = function (fiPaymentMethodToBeneficiary) {
    if (fiPaymentMethodToBeneficiary) {
      if (fiPaymentMethodToBeneficiary.paymentMethod && fiPaymentMethodToBeneficiary.paymentMethod.toUpperCase() === 'CHECK') { $scope.formData.paymentMethod = $scope.methodOfPayments[0].label; }

      $scope.formData.paymentMethodAdditionalInfomation = fiPaymentMethodToBeneficiary.Additional;
    }
  };

  $scope.receiverFIInformation6100TagData = function (fiToFI) {
    if (fiToFI) {
      $scope.formData.receiverFiInformation = $scope.textMergeMethod($scope.lineParamNames, fiToFI);
    }
  };

  $scope.intermediaryFIAdvice6210TagData = function (fiIntermediaryFIAdvice) {
    if (fiIntermediaryFIAdvice.advice) {
      if (fiIntermediaryFIAdvice.advice.adviceCode) {
        $scope.formData.intermediaryFiAdviceCode = fiIntermediaryFIAdvice.advice.adviceCode;
      }
      $scope.formData.intermediaryFiAdviceReference = $scope.textMergeMethod($scope.lineParamNames, fiIntermediaryFIAdvice.advice);
    }
  };

  $scope.beneficiaryFIInformation6300TagData = function (fiToFI) {
    if (fiToFI) {
      $scope.formData.beneficiaryFiInformationReference = $scope.textMergeMethod($scope.lineParamNames, fiToFI);
    }
  };

  $scope.beneficiaryFIAdvice6310TagData = function (fiBeneficiaryFIAdvice) {
    if (fiBeneficiaryFIAdvice.advice) {
      if (fiBeneficiaryFIAdvice.advice.adviceCode) {
        $scope.formData.beneficiaryFiAdviceCode = fiBeneficiaryFIAdvice.advice.adviceCode;
      }
      $scope.formData.beneficiaryFIAdvice = $scope.textMergeMethod($scope.lineParamNames, fiBeneficiaryFIAdvice.advice);
    }
  };

  $scope.beneficiaryAdvice6410TagData = function (fiBeneficiaryAdvice) {
    if (fiBeneficiaryAdvice.advice) {
      if (fiBeneficiaryAdvice.advice.adviceCode) {
        $scope.formData.beneficiaryAdviceCode = fiBeneficiaryAdvice.advice.adviceCode;
      }
      $scope.formData.beneficiaryAdviceCodeReference = $scope.textMergeMethod($scope.lineParamNames, fiBeneficiaryAdvice.advice);
    }
  };

  $scope.getCharges3700TagDetails = function (charges) {
    $scope.formData.detailsOfCharges = charges.chargeDetails;

    if (charges.sendersChargesOne) {
      $scope.formData.senderChargeOneCurrencyCode = charges.sendersChargesOne.substr(0, 3);
      $scope.formData.sendersChargeOneAmount = charges.sendersChargesOne.substr(3).replace(',', '.');
    }
    if (charges.sendersChargesTwo) {
      $scope.formData.senderChargeTwoCurrencyCode = charges.sendersChargesTwo.substr(0, 3);
      $scope.formData.sendersChargeTwoAmount = charges.sendersChargesTwo.substr(3).replace(',', '.');
    }
    if (charges.sendersChargesThree) {
      $scope.formData.senderChargeThreeCurrencyCode = charges.sendersChargesThree.substr(0, 3);
      $scope.formData.sendersChargeThreeAmount = charges.sendersChargesThree.substr(3).replace(',', '.');
    }
    if (charges.sendersChargesFour) {
      $scope.formData.senderChargeFourCurrencyCode = charges.sendersChargesFour.substr(0, 3);
      $scope.formData.sendersChargeFourAmount = charges.sendersChargesFour.substr(3).replace(',', '.');
    }
  };

  $scope.getBeneficiaryDetails = function () {
    $scope.beneficiary4200TagData($scope.transferDetails.creditor, $scope.transferDetails.rawPaymentDetails);
    $scope.beneficiaryFI4100TagData($scope.transferDetails.creditor, $scope.paymentRailMetaData.beneficiaryFI);

    if ($scope.paymentRailMetaData) {
      $scope.intermediaryFI4000TagData($scope.paymentRailMetaData.beneficiaryIntermediaryFI);
      $scope.fItoFIInformation6500TagData($scope.paymentRailMetaData.fiAdditionalFiToFi);
      if ($scope.paymentRailMetaData.beneficiaryReference && $scope.paymentRailMetaData.beneficiaryReference.beneficiaryReference) {
        $scope.formData.beneficiaryReference = $scope.paymentRailMetaData.beneficiaryReference.beneficiaryReference;
      }
    }
  };

  $scope.beneficiary4200TagData = function (creditor, rawPaymentDetails) {
    if ($scope.paymentRailMetaData.beneficiary && $scope.paymentRailMetaData.beneficiary.personal) {
      $scope.formData.beneficiaryIdCode = $scope.paymentRailMetaData.beneficiary.personal.identificationCode;
    }

    if ($scope.transferDetails.inOrOut === 'IN' && rawPaymentDetails) {
      const beneficiaryDetails = rawPaymentDetails.fedWireMessage.beneficiary.personal;
      const beneficiaryAddressDetails = beneficiaryDetails.address;
      $scope.formData = {
        ...$scope.formData,
        beneficiaryIdentifier: beneficiaryDetails.identifier,
        beneficiaryName: beneficiaryDetails.name,
        beneficiaryAddressLineOne: beneficiaryAddressDetails?.addressLineOne,
        beneficiaryAddressLineTwo: beneficiaryAddressDetails?.addressLineTwo,
        beneficiaryAddressLineThree: beneficiaryAddressDetails?.addressLineThree
      };
    } else {
      if (creditor.identifier) {
        const accountNumber = creditor.identifier.split('/').pop();
        $scope.formData.beneficiaryIdentifier = accountNumber;
      }
      if (creditor.name) {
        $scope.formData.beneficiaryName = creditor.name;
      }
      if (creditor.address) {
        if (creditor.address[0]) {
          $scope.formData.beneficiaryAddressLineOne = creditor.address[0];
        }
        if ($scope.transferDetails.creditor.address[1]) {
          $scope.formData.beneficiaryAddressLineTwo = creditor.address[1];
        }
        if (creditor.address[2]) {
          $scope.formData.beneficiaryAddressLineThree = creditor.address[2];
        }
      }
    }
  };

  $scope.beneficiaryFI4100TagData = function (creditor, beneficiaryFI) {
    if (beneficiaryFI) {
      if (beneficiaryFI.financialInstitution) {
        $scope.formData.beneficiaryFiIdCode = beneficiaryFI.financialInstitution.identificationCode;
      }
    }
    if (creditor.agent) {
      if (creditor.agent.identifier) {
        $scope.formData.beneficiaryFiIdentifier = creditor.agent.identifier;
      }
      if (creditor.agent.name) {
        $scope.formData.beneficiaryFiName = creditor.agent.name;
      }

      if (creditor.agent.address) {
        if (creditor.agent.address[0]) {
          $scope.formData.beneficiaryFiAddressLineOne = creditor.agent.address[0];
        }
        if (creditor.agent.address[1]) {
          $scope.formData.beneficiaryFiAddressLineTwo = creditor.agent.address[1];
        }
        if (creditor.agent.address[2]) {
          $scope.formData.beneficiaryFiAddressLineThree = creditor.agent.address[2];
        }
      }
    }
  };

  $scope.intermediaryFI4000TagData = function (beneficiaryIntermediaryFI) {
    if (beneficiaryIntermediaryFI && beneficiaryIntermediaryFI.financialInstitution) {
      if (beneficiaryIntermediaryFI.financialInstitution.identificationCode) {
        $scope.formData.intermediaryFiCode = beneficiaryIntermediaryFI.financialInstitution.identificationCode;
      }
      if (beneficiaryIntermediaryFI.financialInstitution.identifier) {
        $scope.formData.intermediaryFiIdentifier = beneficiaryIntermediaryFI.financialInstitution.identifier;
      }
      if (beneficiaryIntermediaryFI.financialInstitution.name) {
        $scope.formData.intermediaryFiName = beneficiaryIntermediaryFI.financialInstitution.name;
      }
      if (beneficiaryIntermediaryFI.financialInstitution.address) {
        if (beneficiaryIntermediaryFI.financialInstitution.address.addressLineOne) {
          $scope.formData.intermediaryFiAddressLineOne = beneficiaryIntermediaryFI.financialInstitution.address.addressLineOne;
        }
        if (beneficiaryIntermediaryFI.financialInstitution.address.addressLineTwo) {
          $scope.formData.intermediaryFiAddressLineTwo = beneficiaryIntermediaryFI.financialInstitution.address.addressLineTwo;
        }
        if (beneficiaryIntermediaryFI.financialInstitution.address.addressLineThree) {
          $scope.formData.intermediaryFiAddressLineThree = beneficiaryIntermediaryFI.financialInstitution.address.addressLineThree;
        }
      }
    }
  };

  $scope.fItoFIInformation6500TagData = function (fiAdditionalFIToFI) {
    if (fiAdditionalFIToFI && fiAdditionalFIToFI.additionalFiToFi) {
      $scope.formData.fiToFiInformationReference = $scope.textMergeMethod($scope.lineParamNames, fiAdditionalFIToFI.additionalFiToFi);
    }
  };

  $scope.getOriginatorDetails = function () {
    $scope.originator5000TagData($scope.transferDetails.debtor, $scope.paymentRailMetaData);
    if ($scope.paymentRailMetaData) {
      if ($scope.paymentRailMetaData.originatorFI) {
        $scope.originatorFi5100TagData($scope.paymentRailMetaData.originatorFI);
      }
    }
    if ($scope.paymentRailMetaData) {
      if ($scope.paymentRailMetaData.instructingFI) {
        $scope.instructingFi5200TagData($scope.paymentRailMetaData.instructingFI);
      }
    }

    // tag 6000 data
    if ($scope.transferDetails.reference) {
      $scope.formData.OriginatorToBeneficiaryReference = $scope.transferDetails.reference.join('');
    }
  };

  $scope.originatorFi5100TagData = function (originatorFI) {
    if (originatorFI.financialInstitution) {
      if (originatorFI.financialInstitution.identificationCode) {
        $scope.formData.originatorFiIdCode = originatorFI.financialInstitution.identificationCode;
      }
      if (originatorFI.financialInstitution.identifier) {
        $scope.formData.originatorFiIdentifier = originatorFI.financialInstitution.identifier;
      }
      if (originatorFI.financialInstitution.name) {
        $scope.formData.originatorFiName = originatorFI.financialInstitution.name;
      }
      if (originatorFI.financialInstitution.address) {
        if (originatorFI.financialInstitution.address.addressLineOne) {
          $scope.formData.originatorFiAddressLineOne = originatorFI.financialInstitution.address.addressLineOne;
        }
        if (originatorFI.financialInstitution.address.addressLineTwo) {
          $scope.formData.originatorFiAddressLineTwo = originatorFI.financialInstitution.address.addressLineTwo;
        }
        if (originatorFI.financialInstitution.address.addressLineThree) {
          $scope.formData.originatorFiAddressLineThree = originatorFI.financialInstitution.address.addressLineThree;
        }
      }
    }
  };

  $scope.instructingFi5200TagData = function (instructingFI) {
    if (instructingFI.financialInstitution) {
      if (instructingFI.financialInstitution.identificationCode) {
        $scope.formData.originatorInstructingFiCode = instructingFI.financialInstitution.identificationCode;
      }
      if (instructingFI.financialInstitution.identifier) {
        $scope.formData.originatorInstructingFiIdentifier = instructingFI.financialInstitution.identifier;
      }
      if (instructingFI.financialInstitution.name) {
        $scope.formData.originatorInstructingFiName = instructingFI.financialInstitution.name;
      }
      if (instructingFI.financialInstitution.address) {
        if (instructingFI.financialInstitution.address.addressLineOne) {
          $scope.formData.originatorInstructingFiAddressLineOne = instructingFI.financialInstitution.address.addressLineOne;
        }
        if (instructingFI.financialInstitution.address.addressLineTwo) {
          $scope.formData.originatorInstructingFiAddressLineTwo = instructingFI.financialInstitution.address.addressLineTwo;
        }
        if (instructingFI.financialInstitution.address.addressLineThree) {
          $scope.formData.originatorInstructingFiAddressLineThree = instructingFI.financialInstitution.address.addressLineThree;
        }
      }
    }
  };

  $scope.originator5000TagData = function (debtor, paymentRailData) {
    if (debtor.address) {
      if (debtor.address[0]) {
        $scope.formData.originatorAddressLineOne = debtor.address[0];
      }
      if (debtor.address[1]) {
        $scope.formData.originatorAddressLineTwo = debtor.address[1];
      }
      if (debtor.address[2]) {
        $scope.formData.originatorAddressLineThree = debtor.address[2];
      }
    }

    if (paymentRailData.originator) {
      if (paymentRailData.originator.personal) {
        $scope.formData.originatorIdCode = paymentRailData.originator.personal.identificationCode;
      }
    }

    if (debtor.identifier) {
      if ($scope.transferDetails.inOrOut === 'OUT') {
        $scope.formData.originatorIdentifier = debtor.identifier.split(':').pop();
      } else {
        $scope.formData.originatorIdentifier = debtor.identifier.split('/').pop();
      }
    }
    if (debtor.name) {
      $scope.formData.originatorName = $scope.transferDetails.debtor.name;
    }
  };

  $scope.getImadAndOmadDetails = function (paymentRailMetaData) {
    if ($scope.isIncomingPostedFromBO) {
      $scope.formData.imad = paymentRailMetaData?.inputMessageAccountabilityData;
      $scope.formData.omad = paymentRailMetaData?.outputMessageAccountabilityData;
    }
  };

  resourceFactory.paymentTypeResource.getAll(function (dataPaymentTypes) {
    $scope.paymentTypes = dataPaymentTypes.filter(function (dataPaymentType) {
      return ['WIRE', 'SWIFT'].includes(dataPaymentType.name) && dataPaymentType.isSystemDefined;
    });
    // Check if we have custom name for the payment type or not.
    $scope.paymentTypes.forEach(paymentType => {
      paymentType.label = $scope.paymentTypeLabels[paymentType.name] || paymentType.name;
    }
    );
  });

  $scope.onExecuteTransferClick = function () {
    $uibModal.open({
      backdrop: 'static',
      templateUrl: 'execute-transfer.html',
      controller: executeTransferCtrl,
      size: 'lg',
      resolve: {
        id () {
          return routeParams.transferId;
        }
      }
    });
  };

  const executeTransferCtrl = function ($scope, $uibModalInstance, id) {
    $scope.onCloseClick = function () {
      $uibModalInstance.dismiss();
    };

    $scope.confirmExecuteTransfer = function () {
      resourceFactory.transferResource.save({ id, command: 'execute' }, {}, function () {
        location.path(`/transfers`);
      }, handleResponseError);
      $scope.onCloseClick();
    };
  };

  function handleResponseError (error) {
    const { data } = error;
    $scope.errorMsg = data.message || data.errors[0].defaultUserMessage;
  }

  $scope.onDeleteTransferClick = function () {
    $uibModal.open({
      backdrop: 'static',
      templateUrl: 'delete-transfer.html',
      controller: deleteTransferCtrl,
      size: 'lg',
      resolve: {
        id () {
          return routeParams.transferId;
        }
      }
    });
  };

  const deleteTransferCtrl = function ($scope, $uibModalInstance, id) {
    $scope.onCloseClick = function () {
      $uibModalInstance.dismiss();
    };

    $scope.confirmDeleteTransfer = function () {
      resourceFactory.transferResource.save({ id, command: 'delete' }, {}, function () {
        location.path(`/transfers`);
      }, handleResponseError);
      $scope.onCloseClick();
    };
  };

  $scope.closeAlert = function () {
    $scope.errorMsg = '';
  };

  $scope.cancel = function () {
    window.history.back();
  };
}

ViewWireTransferController.$inject = ['$scope', 'ResourceFactory', '$uibModal', '$location', '$routeParams'];
