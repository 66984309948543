export function EditOfficeController (scope, routeParams, resourceFactory, location, dateFilter) {
  scope.formData = {};
  scope.first = {};
  scope.restrictDate = new Date();
  resourceFactory.officeResource.getAllOffices(function (data) {
    scope.parentId = data[0].id;
  });
  resourceFactory.officeResource.get({ officeId: routeParams.id, template: 'true' }, function (data) {
    scope.offices = data.allowedParents;
    scope.id = data.id;
    if (data.openingDate) {
      const editDate = dateFilter(data.openingDate, scope.df);
      scope.first.date = new Date(editDate);
    }
    scope.formData =
                {
                  name: data.name,
                  externalId: data.externalId,
                  parentId: data.parentId
                };
  });

  scope.submit = function () {
    const reqDate = dateFilter(scope.first.date, scope.df);
    this.formData.locale = scope.optlang.code;
    this.formData.dateFormat = scope.df;
    this.formData.openingDate = reqDate;
    resourceFactory.officeResource.update({ officeId: routeParams.id }, this.formData, function (data) {
      location.path(`/viewoffice/${data.resourceId}`);
    });
  };
}

EditOfficeController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location', 'dateFilter'];
