<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.anchor.organization') }}
    </template>
    <div class="container-fluid pe-0">
      <list-view :items-list="itemsList" />
    </div>
  </main-content-layout>
</template>

<script>
import ListView from '@/components/shared/ListView.vue';
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';

export default {
  components: {
    ListView,
    MainContentLayout
  },
  data () {
    return {
      itemsList: [
        {
          icon: 'fa-building',
          title: 'label.anchor.manageorganization',
          subtitle: 'label.manageOrganizationDescription',
          href: '#/organization/manage-organization',
          permission: 'READ_OFFICE'
        },
        {
          icon: 'fa-cogs',
          title: 'label.anchor.currencyconfig',
          subtitle: 'label.currenciesavailableacrossorganizationfordifferentproducts',
          href: '#/currconfig',
          permission: 'READ_CURRENCY'
        },
        {
          icon: 'fa-building',
          title: 'label.anchor.manageoffices',
          subtitle: 'label.addnewofficeormodifyordeactivateofficeormodifyofficeheirarchy',
          href: '#/offices',
          permission: 'READ_OFFICE'
        },
        {
          icon: 'fa-money-bill-alt',
          title: 'label.anchor.managefunds',
          subtitle: 'label.fundsareassociatedwithloans',
          href: '#/managefunds',
          permission: 'READ_FUND'
        },
        {
          icon: 'fa-calendar',
          title: 'label.anchor.manageholidays',
          subtitle: 'label.defineholidaysforoffices',
          href: '#/holidays',
          permission: 'READ_HOLIDAY'
        },
        {
          icon: 'fa-money-bill-alt',
          title: 'label.anchor.bulkloanreassignment',
          subtitle: 'label.easywaytoreassignalltheloanfromonelotoanotherlo',
          href: '#/bulkloan',
          permission: 'BULKREASSIGN_LOAN'
        },
        {
          icon: 'fa-user',
          title: 'label.anchor.manageemployees',
          subtitle: 'label.aemployeerepresentsloanofficerswithnoaccesstosystem',
          href: '#/employees',
          permission: 'READ_STAFF'
        },
        {
          icon: 'fa-money-bill-alt',
          title: 'label.anchor.tellercashmanagement',
          subtitle: 'label.tellercashmanagement',
          href: '#/tellers',
          permission: 'READ_TELLER'
        },
        {
          icon: 'fa-lock',
          title: 'label.anchor.passwordPreferences',
          subtitle: 'label.passwordPreferences',
          href: '#/passwordpreferences',
          permission: 'READ_PASSWORD_VALIDATION_POLICY'
        },
        {
          icon: 'fa-calendar',
          title: 'label.anchor.workingDays',
          subtitle: 'label.workingDays',
          href: '#/workingdays',
          permission: 'READ_WORKINGDAYS'
        },
        {
          icon: 'fa-pencil-alt',
          title: 'label.anchor.createprovisioningcriteria',
          subtitle: 'label.createprovisioningcriteria',
          href: '#/viewallprovisionings',
          permission: 'VIEW_PROVISIONS'
        },
        {
          icon: 'fa-upload',
          title: 'label.anchor.bulkimport',
          subtitle: 'label.bulkimport',
          href: '#/organization/bulk-import',
          permission: 'VIEW_BULKIMPORT'
        },
        {
          icon: 'fa-check',
          title: 'label.anchor.entitydatatablechecks',
          subtitle: 'label.entitydatatablechecks',
          href: '#/entitydatatablechecks',
          permission: 'READ_ENTITY_DATATABLE_CHECK'
        },
        {
          icon: 'fa-angle-double-right',
          title: 'label.anchor.adhoc',
          subtitle: 'label.adhocs',
          href: '#/adhocquery',
          permission: 'VIEW_ADHOC'
        }
      ]
    };
  }

};
</script>
