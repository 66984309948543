export function BulkImportRecurringDepositController (scope, resourceFactory, API_VERSION, $rootScope, Upload) {
  scope.first = {};
  scope.first.templateUrl = `${API_VERSION}/recurringdepositaccounts/downloadtemplate?tenantIdentifier=${$rootScope.tenantIdentifier}&locale=${scope.optlang.code}&dateFormat=${scope.df}`;
  scope.formData = {};
  const requestParams = { staffInSelectedOfficeOnly: true };

  resourceFactory.clientTemplateResource.get(requestParams, function (data) {
    scope.offices = data.officeOptions;
    scope.staffs = data.staffOptions;
  });

  scope.first.queryParams = '&';
  scope.changeOffice = function () {
    if (scope.formData.officeId) {
      if (scope.first.queryParams.indexOf('officeId') === -1) {
        resourceFactory.clientTemplateResource.get({
          staffInSelectedOfficeOnly: true, officeId: scope.formData.officeId
        }, function (data) {
          scope.staffs = data.staffOptions;
        });
        scope.first.queryParams += `&officeId=${scope.formData.officeId}`;
      } else {
        resourceFactory.clientTemplateResource.get({
          staffInSelectedOfficeOnly: true, officeId: scope.formData.officeId
        }, function (data) {
          scope.staffs = data.staffOptions;
        });
        scope.first.queryParams = scope.first.queryParams.replace(/&officeId=\d+/i, `&officeId=${scope.formData.officeId}`);
      }
    } else {
      scope.first.queryParams = '&';
    }
  };

  scope.changeStaff = function () {
    if (scope.formData.staffId) {
      if (scope.first.queryParams.indexOf('staffId') === -1) {
        scope.first.queryParams = `${scope.first.queryParams}&staffId=${scope.formData.staffId}`;
      } else {
        scope.first.queryParams = scope.first.queryParams.replace(/&staffId=\d+/i, `&staffId=${scope.formData.staffId}`);
      }
    } else if (scope.formData.officeId) { scope.first.queryParams = `${'&' + 'officeId='}${scope.formData.officeId}`; } else { scope.first.queryParams = '&'; }
  };
  scope.onFileSelect = function (files) {
    scope.formData.file = files[0];
  };

  scope.refreshImportTable = function () {
    resourceFactory.importResource.getImports({ entityType: 'recurringdeposits' }, function (data) {
      for (const l in data) {
        let importdocs = {};
        importdocs = `${API_VERSION}/imports/downloadOutputTemplate?importDocumentId=${data[l].importId}&tenantIdentifier=${$rootScope.tenantIdentifier}`;
        data[l].docUrl = importdocs;
      }
      scope.imports = data;
    });
  };
  scope.upload = function () {
    Upload.upload({
      url: `${$rootScope.hostUrl + API_VERSION}/recurringdepositaccounts/uploadtemplate`,
      data: { file: scope.formData.file, locale: scope.optlang.code, dateFormat: scope.df }
    }).then(function () {
      // to fix IE not refreshing the model
      if (!scope.$$phase) {
        scope.$apply();
      }
    });
  };
}

BulkImportRecurringDepositController.$inject = ['$scope', 'ResourceFactory', 'API_VERSION', '$rootScope', 'Upload'];
