<template>
  <div>
    <button
      class="btn btn-primary"
      @click="showModal"
    >
      <i class="fas fa-plus" />
      <span
        class="ms-1"
      >{{ $t('label.input.linkExistingClient') }}</span>
    </button>
    <base-modal
      id="link-existing-client"
      ref="thisModal"
      @on-submit="submit"
    >
      <template #header>
        <h4>{{ $t('label.heading.linkExistingClient') }}</h4>
      </template>
      <template #body>
        <input-select
          v-model="formData.selectedClient"
          vertical
          :placeholder="$t('label.placeholder.searchLinkClient')"
          :options="clientsList"
          :label="$t(`label.input.searchClient`)"
          required
          text-field="displayName"
          value-field="id"
          @search="search"
        />
      </template>
    </base-modal>
  </div>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import { ref, reactive } from 'vue';
import { InputSelect } from '@/components/shared/form-input';
import apiService from '@/services/apiService';
import router from '@/router';
import _ from 'underscore';
import { $notify } from '@/plugins/prototypes';

const formData = reactive({});
const thisModal = ref(null);
const clientsList = ref([]);

const search = _.debounce((value, loading) => {
  if (value.length < 3) return;
  const searchText = value.replace(/(^"|"$)/g, '');
  getClients({
    offset: 0,
    limit: 15,
    orderBy: 'id',
    sortOrder: 'DESC',
    sqlSearch: searchText,
    clientStatus: 'ALL'
  }, loading);
}, 500);

const getClients = async (param, loading) => {
  loading(true);
  try {
    const { data } = await apiService.clients.getAll(param);
    const { pageItems } = data;
    clientsList.value = pageItems.filter((client) => client.legalForm?.code === 'legalFormType.person');
  } catch (e) {
    $notify.error(e);
  } finally {
    loading(false);
  }
};

const emit = defineEmits(['on-submitted']);
const submit = async () => {
  if (!formData.selectedClient) return;
  try {
    const { clientId } = router.currentRoute.params;
    await apiService.clients.linkedClient(clientId, { clientId: formData.selectedClient });
    thisModal.value.hide();
    formData.selectedClient = null;
    clientsList.value = [];
    emit('on-submitted');
  } catch (e) {
    $notify.error(e);
  }
};
const showModal = () => {
  thisModal.value.show();
};
</script>
