export function ViewPeriodLimitRuleController (scope, routeParams, resourceFactory, location) {
  const { ruleGroupId: ruleId, id: periodLimitRuleId } = routeParams;
  scope.periodLimitRule = {};
  scope.ruleGroup = {};
  scope.transferDirections = { RECEIVED: 'INCOMING', ORIGINATED: 'OUTGOING' };

  function loadData () {
    const params = {
      amlRule: { ruleId, fields: 'id,name' },
      limitRule: { ruleId, action: 'periodlimitrule', actionId: periodLimitRuleId }
    };

    resourceFactory.amlRulesResource.get(
      params.amlRule,
      function (resAmlRule) {
        resourceFactory.amlRulesResource.get(
          params.limitRule,
          function (resLimitRule) {
            scope.ruleGroup = resAmlRule;
            scope.periodLimitRule = { ...resLimitRule };
            if (scope.periodLimitRule.paymentTypeId) {
              resourceFactory.paymentTypeResource.get({ paymentTypeId: scope.periodLimitRule.paymentTypeId },
                function (data) { scope.periodLimitRule.paymentTypeId = data.name; }
                , handleResponseError);
            } else scope.periodLimitRule.paymentTypeId = 'ALL';

            if (scope.periodLimitRule.clientClassificationId) {
              const classificaiton = resLimitRule.legalForm === 1 ? 'ClientClassification-PERSON' : 'ClientClassification-ENTITY';
              resourceFactory.codeResources.getAllCodes(function (data) {
                // Get Client Classification Code ID
                const codes = data;
                const clientClassificationCodeId = codes.find(code => code.name === classificaiton).id;

                // Get Client Classification Codes
                resourceFactory.codeValueResource.getAllCodeValues({ codeId: clientClassificationCodeId }, function (data) {
                  // Store All Code Values For CLassification
                  const codeValues = data;
                  scope.periodLimitRule.clientClassification = codeValues.find(codeValue => codeValue.id === scope.periodLimitRule.clientClassificationId).name;
                }, handleResponseError);
              }, handleResponseError);
            } else scope.periodLimitRule.clientClassification = 'ALL';
          }
          ,
          handleResponseError
        );
      },
      handleResponseError
    );
  }

  loadData();

  // Actions on Rule Group
  scope.routeToEditPeriodLimitRule = function () {
    location.path(`/payments/aml/rulegroups/${ruleId}/editperiodlimitrule/${periodLimitRuleId}`);
  };

  scope.activatePeriodLimitRule = function () {
    const params = { ruleId, action: 'periodlimitrule', actionId: periodLimitRuleId };

    resourceFactory.amlRulesResource.update(
      params, { active: true },
      function () {
        scope.periodLimitRule = { ...scope.periodLimitRule, active: true };
      },
      handleResponseError
    );
  };

  scope.deactivatePeriodLimitRule = function () {
    const params = { ruleId, action: 'periodlimitrule', actionId: periodLimitRuleId };

    resourceFactory.amlRulesResource.update(
      params, { active: false },
      function () {
        scope.periodLimitRule = { ...scope.periodLimitRule, active: false };
      },
      handleResponseError
    );
  };

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }
}

ViewPeriodLimitRuleController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
