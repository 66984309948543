export function EditNCLimitRuleController (scope, routeParams, resourceFactory, location) {
  const { ruleGroupId: ruleId, id: limitRuleId } = routeParams;
  scope.limitRule = { id: limitRuleId, ruleGroup: { id: ruleId } };
  scope.ruleGroup = {};
  scope.formData = {};

  function loadData () {
    const params = {
      amlNameMatchsRule: { ruleId, fields: 'id,name' },
      limitRule: { ruleId, action: 'limitrule', actionId: limitRuleId }
    };

    resourceFactory.amlNetworkConnectionsResource.get(
      params.amlNameMatchsRule,
      function (resAmlRule) {
        resourceFactory.amlNetworkConnectionsResource.get(
          params.limitRule,
          function (resLimitRule) {
            scope.ruleGroup = resAmlRule;
            delete resLimitRule.$promise;
            delete resLimitRule.$resolved;
            delete resLimitRule.id;
            delete resLimitRule.ruleGroupId;
            scope.formData = { ...resLimitRule };
          },
          handleResponseError
        );
      },
      handleResponseError
    );
  }

  loadData();

  scope.onTransactionalRelationshipChange = function () {
    if (scope.formData.filterByTransactionalRelationship === 'SEEN') {
      scope.formData.filterByCounterpartyStatus = 'KNOWN';
    }
  };

  scope.submit = function () {
    // let validFrom = null
    // let validTo = null
    // if (scope.formData.validFrom) {
    //   const validFromDate = new Date(scope.formData.validFrom)
    //   validFrom = validFromDate.toISOString()
    // }
    // if (scope.formData.validTo) {
    //   const validToDate = new Date(scope.formData.validTo)
    //   validTo = validToDate.toISOString()
    // }
    const params = { ruleId, action: 'limitrule', actionId: limitRuleId };

    resourceFactory.amlNetworkConnectionsResource.update(
      params, scope.formData,
      function (res) {
        const { resourceId } = res;
        location.path(`/payments/aml/rulegroups/${ruleId}/nclimitrules/${resourceId}`);
      },
      handleResponseError
    );
  };

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
}

EditNCLimitRuleController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
