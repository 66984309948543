<template>
  <main-content-layout>
    <pending-table
      type="achProcessing"
      :latest-history="achProcessingLatestHistory"
      :cut-off-time="cutOffTime"
      :ach-processing-pending-table-data="achProcessingPendingTableData"
      :is-submit-enable="isAbleToTriggerAch"
      @on-submit="handleSubmitTriggerACH"
    />
    <pending-table
      type="sameDayAchProcessing"
      :latest-history="sameDayAchProcessingLatestHistory"
      :cut-off-time="cutOffTime"
      :ach-processing-pending-table-data="sameDayAchProcessingPendingTableData"
      :is-submit-enable="isAbleToTriggerSameDayAch"
      @on-submit="handleSubmitTriggerACH('SAMEDAYACH')"
    />
  </main-content-layout>
</template>

<script>
import apiService from '@/services/apiService';
import mixin from './mixin';
import PendingTable from '@/components/system/ach-processing/PendingTable.vue';
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';

export default {
  name: 'AchProcessing',
  components: {
    PendingTable,
    MainContentLayout
  },
  mixins: [mixin],
  data () {
    return {
      isAbleToTriggerAch: false,
      isAbleToTriggerSameDayAch: false,
      cutOffTime: '',
      achProcessingPendingTableData: [],
      sameDayAchProcessingPendingTableData: []
    };
  },
  created () {
    this.lastProcessingDay = 5;
  },
  beforeMount () {
    this.getAllAchProcessingHistory();
    this.getSameDayAchProcessingHistory();
    this.getAllAchProcessingPending();
    this.getOrganizationTimezone();
  },
  methods: {
    async handleSubmitTriggerACH (paymentType) {
      if (confirm(this.$t('text.confirmSubmitTriggerACH'))) {
        try {
          await apiService.transfer.triggerAchProcessing({ paymentType });
          this.getAllAchProcessingPending();
          this.getAllAchProcessingHistory();
          this.getSameDayAchProcessingHistory();
        } catch (error) {
          this.$notify.error(error);
        }
      }
    },
    async getAllAchProcessingPending () {
      try {
        const { data: achProcessingPendingList } = await apiService.transfer.getAllAchProcessingPending();
        const { data: sameDayAchProcessingPendingList } = await apiService.transfer.getAllAchProcessingPending({ paymentType: 'SAMEDAYACH' });
        this.isAbleToTriggerAch = !!achProcessingPendingList.length;
        this.isAbleToTriggerSameDayAch = !!sameDayAchProcessingPendingList.length;
        const tempTableDataAch = this.getAchProcessingPendingTableData(achProcessingPendingList);
        const tempTableDataSameDayAch = this.getAchProcessingPendingTableData(sameDayAchProcessingPendingList);
        this.achProcessingPendingTableData = [tempTableDataAch];
        this.sameDayAchProcessingPendingTableData = [tempTableDataSameDayAch];
      } catch (error) {
        this.$notify.error(error);
      }
    },
    async getOrganizationTimezone () {
      try {
        const { data: { serverTime } } = await apiService.organizationTimezone.get();

        this.cutOffTime = serverTime;
      } catch (error) {
        this.$notify.error(error);
      }
    },
    getAchProcessingPendingTableData (achProcessingPendingList = []) {
      let tempDataTable = {
        totalCreditCount: 0,
        totalCreditAmount: 0,
        totalDebitCount: 0,
        totalDebitAmount: 0
      };

      if (!achProcessingPendingList.length) return tempDataTable;

      achProcessingPendingList.forEach(item => {
        if (item.type === 'CREDIT') {
          tempDataTable = {
            ...tempDataTable,
            totalCreditCount: item.totalCount,
            totalCreditAmount: this.$n(item.totalAmount, { key: 'currency' })
          };
        } else {
          tempDataTable = {
            ...tempDataTable,
            totalDebitCount: item.totalCount,
            totalDebitAmount: this.$n(item.totalAmount, { key: 'currency' })
          };
        }
      });

      return tempDataTable;
    }
  }
};
</script>
