<template>
  <form-wrapper
    class="row justify-content-start"
    @submit="submit"
  >
    <div class="col-5">
      <input-date-picker
        v-if="hasDatePickerInput"
        :id="action"
        v-model="formData.date"
        :label="$t(`label.input.${clientAction}Date`)"
        :required="required"
        :max-date="today"
        :min-date="activationDate"
      />
      <input-select
        v-if="hasSelectedInput"
        :id="`${clientAction}-select`"
        v-model="formData.selectReason"
        :label="$t(`label.input.${clientAction}Reason`)"
        :options="clientActionsReasonOptions"
        value-field="id"
        :text-field="textField"
        required
      />
      <div class="w-100 mt-3 d-flex justify-content-end">
        <button
          class="btn btn-danger mx-1"
          @click="returnToClientPage"
        >
          {{ $t('label.button.cancel') }}
        </button>
        <button
          type="submit"
          class="btn btn-primary mx-1"
        >
          {{ $t('label.button.save') }}
        </button>
      </div>
    </div>
  </form-wrapper>
</template>

<script setup>
import { InputDatePicker, InputSelect, FormWrapper } from '@/components/shared/form-input';
import router from '@/router';
import apiService from '@/services/apiService';
import { reactive, ref, onMounted, computed } from 'vue';
import { $notify } from '@/plugins/prototypes';
import { set, get } from '@vueuse/core';
import { dateStringToFormat } from '@/helper/dateHelper';
import { useSharedStore } from '@/stores/shared';

const userSharedStore = useSharedStore();
const textField = ref('name');
const required = ref(true);
const today = ref(new Date());
const activationDate = ref(null);
const clientActionsReasonOptions = ref([]);
const formData = reactive({
  date: dateStringToFormat(new Date(), userSharedStore.dateFormat),
  selectReason: null
});
const { action, id: clientId } = router.currentRoute.params;
const { primaryAccountId } = router.currentRoute.query;

const clientAction = computed(() => {
  return action.replace(/-action/, '');
});

const hasSelectedInput = computed(() => commandMap[get(clientAction)].actions.includes('select'));
const hasDatePickerInput = computed(() => commandMap[get(clientAction)].actions.includes('datepicker'));

const commandMap = reactive({
  activate: {
    dateKey: 'activationDate',
    command: 'activate',
    actions: ['datepicker']
  },
  reactivate: {
    dateKey: 'reactivationDate',
    command: 'reactivate',
    actions: ['datepicker']
  },
  close: {
    dateKey: 'closureDate',
    reasonKey: 'closureReasonId',
    command: 'close',
    actions: ['datepicker', 'select']
  },
  reject: {
    dateKey: 'rejectionDate',
    reasonKey: 'rejectionReasonId',
    command: 'reject',
    actions: ['datepicker', 'select']
  },
  withdraw: {
    dateKey: 'withdrawalDate',
    reasonKey: 'withdrawalReasonId',
    command: 'withdraw',
    actions: ['datepicker', 'select']
  },
  undoreject: {
    dateKey: 'reopenedDate',
    command: 'undoRejection',
    actions: ['datepicker']
  },
  undowithdrawn: {
    dateKey: 'reopenedDate',
    command: 'undoWithdrawal',
    actions: ['datepicker']
  },
  assignStaff: {
    reasonKey: 'staffId',
    command: 'assignStaff',
    actions: ['select']
  },
  switchClassification: {
    dateKey: 'expectedApplicableDate',
    reasonKey: 'classificationId',
    command: 'switchClassification',
    actions: ['datepicker', 'select']
  },
  updateDefaultAccount: {
    reasonKey: 'savingsAccountId',
    command: 'updateSavingsAccount',
    actions: ['select']
  }
});

onMounted(async () => {
  const clientActionValue = get(clientAction);

  if (clientActionValue === 'assignStaff') {
    const requestParams = { template: 'true', staffInSelectedOfficeOnly: true };
    const { data: { staffOptions } } = await apiService.clients.get(clientId, requestParams);
    formData.selectReason = staffOptions[0].id;
    set(clientActionsReasonOptions, staffOptions);
    set(textField, 'displayName');
  } else if (clientActionValue === 'updateDefaultAccount') {
    const requestParams = { template: 'true' };
    const { data: { savingAccountOptions, savingsAccountId } } = await apiService.clients.get(clientId, requestParams);
    const savingAccountNumberAndProductName = savingAccountOptions.map((item) => ({
      savingAccountNumberAndProductName: `${item.accountNo}-${item.savingsProductName}`,
      ...item
    }));
    formData.selectReason = savingsAccountId;
    set(clientActionsReasonOptions, savingAccountNumberAndProductName);
    set(textField, 'savingAccountNumberAndProductName');
  } else if (clientActionValue === 'switchClassification') {
    const minTodayDate = new Date();
    const requestParams = { template: 'true', staffInSelectedOfficeOnly: true };
    const { data: { clientClassificationOptions, clientClassification } } = await apiService.clients.get(clientId, requestParams);
    set(clientActionsReasonOptions, clientClassificationOptions);
    set(activationDate, new Date(minTodayDate.setDate(minTodayDate.getDate() - 1)));
    set(today, null);
    set(required, false);
    formData.selectReason = clientClassification.id;
    formData.date = null;
  } else {
    const { data: { timeline } } = await apiService.clients.get(clientId);
    const { data: { narrations } } = await apiService.clients.template({ commandParam: clientActionValue });
    set(clientActionsReasonOptions, narrations);
    set(activationDate, new Date(timeline.submittedOnDate));
  }
});

const returnToClientPage = () => {
  if (primaryAccountId) {
    return location.assign(`#/saving-accounts/${primaryAccountId}/employee-accounts/client/${clientId}`);
  }
  location.assign(`#/viewclient/${clientId}`);
};

const submit = async () => {
  let actionFormData = {};

  const commandConfig = commandMap[clientAction.value];

  if (!commandConfig) {
    console.warn('no action match');
    return;
  }

  const { dateKey, reasonKey, command } = commandConfig;

  if (reasonKey && formData.selectReason) {
    actionFormData[reasonKey] = formData.selectReason;
  }

  if (dateKey && formData.date) {
    actionFormData = {
      ...actionFormData,
      [dateKey]: formData.date,
      dateFormat: 'dd MMMM yyyy',
      locale: 'en'
    };
  }

  try {
    await apiService.clients.save(clientId, { command }, actionFormData);
    returnToClientPage();
  } catch (e) {
    $notify.error(e);
  }
};
</script>
