<template>
  <base-modal
    ref="ClientMonitoringDetailsModal"
    modal-size="xl"
    hide-footer
    @hidden="clearData"
  >
    <template #header>
      <h4>Client Details</h4>
    </template>
    <template #body>
      <div
        class="row gap-1"
      >
        <div
          v-for="(value, key) in detailItem"
          :key="key"
          class="col-4"
        >
          <span v-if="key === 'id'">
            <b style="opacity: 0.5;">{{ $t(`label.${key}`) }}</b>&nbsp;{{ value }}
          </span>
          <span v-else-if="key === 'status'">
            <b style="opacity: 0.5;">{{ $t(`label.${key}`) }}</b>&nbsp;{{ value.value }}
          </span>
          <span v-else-if="key === 'threshold'">
            <b style="opacity: 0.5;">{{ $t(`label.${key}`) }}:</b>&nbsp;<span>{{ $n(value / 100, { style: 'percent', minimumFractionDigits: 2 }) }}</span>
          </span>
          <span v-else>
            <b style="opacity: 0.5;">{{ $t(`label.${key}`) }}:</b>&nbsp;{{ value }}
          </span>
        </div>

        <div v-if="sdnItems.length">
          <h4 class="mt-3">
            {{ $t('label.heading.speciallyDesginatedNationals') }}
          </h4>

          <data-grid
            id="data-grid-sdn"
            :items="sdnItems"
            :fields="sdnFields"
            hide-entries-per-page
          >
            <template #match="{ item }">
              <span>{{ $n(item.match, { style: 'percent', minimumFractionDigits: 2 }) }}</span>
            </template>

            <template #remarks="{ item }">
              <div class="remarks">
                {{ item.remarks }}
              </div>
            </template>
          </data-grid>
        </div>

        <div v-if="addresses.length">
          <h4 class="mt-3">
            {{ $t('label.heading.addresses') }}
          </h4>

          <data-grid
            :items="addresses"
            :fields="addressesFields"
            hide-entries-per-page
          >
            <template #match="{ item }">
              <span>{{ $n(item.match, { style: 'percent', minimumFractionDigits: 2 }) }}</span>
            </template>
          </data-grid>
        </div>

        <div v-if="altNames.length">
          <h4 class="mt-3">
            {{ $t('label.heading.altNames') }}
          </h4>

          <data-grid
            :items="altNames"
            :fields="altNamesFields"
            hide-entries-per-page
          >
            <template #match="{ item }">
              <span>{{ $n(item.match, { style: 'percent', minimumFractionDigits: 2 }) }}</span>
            </template>
          </data-grid>
        </div>

        <div v-if="deniedPersons.length">
          <h4 class="mt-3">
            {{ $t('label.heading.deniedPersons') }}
          </h4>

          <data-grid
            :items="deniedPersons"
            :fields="deniedPersonsFields"
            hide-entries-per-page
          >
            <template #match="{ item }">
              <span>{{ $n(item.match, { style: 'percent', minimumFractionDigits: 2 }) }}</span>
            </template>
          </data-grid>
        </div>

        <div v-if="detailItem.status?.id === 100">
          <input-text
            v-model="note"
            vertical
            :label="$t('label.input.note')"
          />
        </div>

        <div class="d-flex mt-4">
          <div v-if="detailItem.status?.id === 100">
            <button
              :disabled="isLoading"
              class="btn btn-success"
              @click="handleAction('APPROVE')"
            >
              {{ $t('label.button.falsePositive') }}
            </button>
            <button
              :disabled="isLoading"
              class="btn btn-danger ms-2"
              @click="handleAction('BLOCK')"
            >
              {{ $t('label.button.potentialMatch') }}
            </button>
          </div>
          <button
            class="btn btn-secondary ms-auto"
            :disabled="isLoading"
            @click="ClientMonitoringDetailsModal.hide()"
          >
            Close
          </button>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import DataGrid from '@/components/shared/data-grid/index.vue';
import apiService from '@/services/apiService';
import { ref } from 'vue';
import { get } from '@vueuse/core';
import { $notify } from '@/plugins/prototypes';
import i18n from '@/plugins/i18n';
import { InputText } from '@/components/shared/form-input/index.js';

const emits = defineEmits(['on-submitted']);
const props = defineProps({
  detailItem: {
    type: Object,
    default: () => ({})
  },
  sdnItems: {
    type: Array,
    default: () => []
  },
  altNames: {
    type: Array,
    default: () => []
  },
  addresses: {
    type: Array,
    default: () => []
  },
  deniedPersons: {
    type: Array,
    default: () => []
  }
});

const isLoading = ref(false);
const ClientMonitoringDetailsModal = ref(null);
const note = ref('');

const sdnFields = [
  { label: i18n.t('label.head.id'), key: 'entityID' },
  { label: i18n.t('label.head.name'), key: 'sdnName' },
  { label: i18n.t('label.head.type'), key: 'sdnType' },
  { label: i18n.t('label.head.program'), key: 'program' },
  { label: i18n.t('label.head.match'), key: 'match' },
  { label: i18n.t('label.head.remarks'), key: 'remarks' }
];

const addressesFields = [
  { label: i18n.t('label.head.id'), key: 'entityID' },
  { label: i18n.t('label.head.address'), key: 'address' },
  { label: i18n.t('label.head.cityStateProvincePostalCode'), key: 'cityStateProvincePostalCode' },
  { label: i18n.t('label.head.country'), key: 'country' },
  { label: i18n.t('label.head.match'), key: 'match' }
];

const altNamesFields = [
  { label: i18n.t('label.head.id'), key: 'entityID' },
  { label: i18n.t('label.head.alternateName'), key: 'alternateName' },
  { label: i18n.t('label.head.type'), key: 'alternateType' },
  { label: i18n.t('label.head.match'), key: 'match' }
];

const deniedPersonsFields = [
  { label: i18n.t('label.head.name'), key: 'name' },
  { label: i18n.t('label.head.streetAddress'), key: 'streetAddress' },
  { label: i18n.t('label.head.action'), key: 'action' },
  { label: i18n.t('label.head.effectiveDate'), key: 'effectiveDate' },
  { label: i18n.t('label.head.expirationDate'), key: 'expirationDate' },
  { label: i18n.t('label.head.match'), key: 'match' }
];

const clearData = () => {
  note.value = '';
};

async function handleAction (actionType) {
  try {
    await apiService.suspiciousclients.save(props.detailItem.id, {}, { command: actionType });
    if (note.value) {
      await apiService.clientOfacNote.create(props.detailItem.clientId, { note: note.value });
    }
    get(ClientMonitoringDetailsModal).hide();
    emits('on-submitted');
  } catch (e) {
    $notify.error(e);
  }
}
</script>

<style scoped>
#data-grid-sdn ::v-deep #base-table td:has(div.remarks) {
  max-width: 250px;
}
</style>
