<template>
  <div
    v-if="loading && userDetails"
    class="loading-widget-div"
  >
    <span class="centerloader">
      <div
        class="spinner-border text-primary"
        style="width: 5rem; height: 5rem;"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </span>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useSharedStore } from '@/stores/shared';
import { useUserStore } from '@/stores/user';

export default {
  name: 'LoaderComponent',
  computed: {
    ...mapState(useSharedStore, ['loading']),
    ...mapState(useUserStore, ['userDetails'])
  }
};
</script>
