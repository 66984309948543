export function ProductMixController ($scope, resourceFactory, location) {
  $scope.productmixes = [];
  $scope.routeTo = function (id) {
    location.path(`/viewproductmix/${id}`);
  };
  resourceFactory.loanProductResource.getAllLoanProducts({ associations: 'productMixes' }, function (data) {
    $scope.productmixes = data;
  });
}

ProductMixController.$inject = ['$scope', 'ResourceFactory', '$location'];
