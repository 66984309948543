/**
 * @param { 'true' | 'false' } value - string of 'true' and 'false' that can be convert into boolean
 */

export function Booleanish (value) {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
  }
}
